import HomePage from "../../container/homePage/homePage";

const HomePageWrapper = () => {
    return (
        <>
            <HomePage />
        </>
    );
};

export default HomePageWrapper;