export const cars = {
"Abarth": {
    "124 Spider": {
        "2016 ->": {
            "1.4 MultiAir Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF & Marelli MM8GMK",
                "Engine_code": "55253268",
            },
            "1.4 MultiAir Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "",
                "Engine_code": "55253268",
            },
        },
    },
    "500 / 595": {
        "2008 - >": {
            "1.4 T-jet 135hp": {
                "std_power": 135,
                "tuned_power": 170,
                "std_torque": 206,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "312A1000",
            },
            "1.4 T-jet 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 206,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "312A1000",
            },
            "1.4 T-jet 160hp": {
                "std_power": 160,
                "tuned_power": 170,
                "std_torque": 234,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Marelli MM8GMF",
                "Engine_code": "312A1000",
            },
            "1.4 T-jet 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "312A3000",
            },
            "1.4 T-jet 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "312A3000",
            },
        },
    },
    "Punto": {
        "2008 - >": {
            "1.4 T-jet 155hp": {
                "std_power": 155,
                "tuned_power": 180,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "199.A.8000",
            },
            "1.4 T-jet 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "199.A.8000",
            },
            "1.4 T-jet 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "199.A.8000",
            },
        },
    },
    "Punto EVO": {
        "2010 ->": {
            "1.4 T-jet EsseEsse 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "955A8000",
            },
            "1.4 T-jet Supersport 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "955A8000",
            },
            "1.4 T-jet 165hp": {
                "std_power": 165,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "955A8000",
            },
        },
    },
},
"Alfa Romeo": {
    "147": {
        "2001-2005": {
            "1.9 JTD 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "182B9000",
            },
            "1.9 JTD 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 275,
                "tuned_torque": 330,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "937A2000",
            },
            "1.9 JTD 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "192A5000",
            },
            "1.9 JTD 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "192B1000",
            },
            "1.6 T-Spark 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 146,
                "tuned_torque": 161,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "AR32104",
            },
            "1.6 T-Spark 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 140,
                "tuned_torque": 155,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "AR32104",
            },
            "2.0 T-Spark 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 172,
                "tuned_torque": 192,
                "cc": 1970,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "AR32310",
            },
            "3.2 V6 GTA 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 3179,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "932A000",
            },
        },
        "2005 ->": {
            "1.9 JTD 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 285,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "937A2000",
            },
            "1.9 JTD 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "182B9000",
            },
            "1.9 JTD 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 275,
                "tuned_torque": 350,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7 & Bosch EDC16C39",
                "Engine_code": "937A2000",
            },
            "1.9 JTD 126hp": {
                "std_power": 126,
                "tuned_power": 175,
                "std_torque": 330,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "937A4000",
            },
            "1.9 JTD 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "192A5000",
            },
            "1.9 JTD 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "192A5000",
            },
            "1.9 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C8",
                "Engine_code": "937A5000",
            },
            "1.9 JTD 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 390,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "937A6000",
            },
            "1.6 T-Spark 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 140,
                "tuned_torque": 155,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "AR32104",
            },
            "1.6 T-Spark 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 146,
                "tuned_torque": 161,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "AR32104",
            },
            "2.0 T-Spark 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 180,
                "tuned_torque": 200,
                "cc": 1970,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "AR32310",
            },
            "3.2 V6 GTA 250hp": {
                "std_power": 250,
                "tuned_power": 275,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 3179,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "936A000",
            },
        },
    },
    "156": {
        "1997 - 2003": {
            "1.9 JTD 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 255,
                "tuned_torque": 315,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C5",
                "Engine_code": "AR32302",
            },
            "1.9 JTD 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 275,
                "tuned_torque": 330,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "937A2000",
            },
            "1.9 JTD 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 275,
                "tuned_torque": 325,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "937A2000",
            },
            "1.9 JTD 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "192A5000",
            },
            "1.9 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "937A5000",
            },
            "1.9 JTD 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "192A5000",
            },
            "2.4 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 304,
                "tuned_torque": 385,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "841C000",
            },
            "2.4 JTD 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C5 & Bosch EDC15C7",
                "Engine_code": "841C000",
            },
            "1.6i 120hp": {
                "std_power": 120,
                "tuned_power": 133,
                "std_torque": 146,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Bosch M1.5.5 & Bosch ME7.3.1",
                "Engine_code": "AR32104",
            },
            "2.5 V6 190hp": {
                "std_power": 190,
                "tuned_power": 200,
                "std_torque": 220,
                "tuned_torque": 240,
                "cc": 2492,
                "Type_ecu": "Bosch ME3.1",
                "Engine_code": "AR32401",
            },
            "3.2 V6 GTA 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 3179,
                "Type_ecu": "Bosch ME7.3.1 & Bosch ME3.1",
                "Engine_code": "932A000",
            },
        },
        "2002 - 2005": {
            "1.9 JTD 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 275,
                "tuned_torque": 330,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "937A2000",
            },
            "1.9 JTD 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "192A5000",
            },
            "1.9 JTD 126hp": {
                "std_power": 126,
                "tuned_power": 175,
                "std_torque": 330,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "937A2000",
            },
            "1.9 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "937A5000",
            },
            "1.9 JTD 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "192A5000",
            },
            "2.4 JTD 20V 175hp": {
                "std_power": 175,
                "tuned_power": 220,
                "std_torque": 385,
                "tuned_torque": 480,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "841G000",
            },
            "2.4 JTD 136hp": {
                "std_power": 136,
                "tuned_power": 168,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C5",
                "Engine_code": "841C000",
            },
            "2.4 JTD 140hp": {
                "std_power": 140,
                "tuned_power": 173,
                "std_torque": 304,
                "tuned_torque": 382,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C5",
                "Engine_code": "841C000",
            },
            "2.4 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 304,
                "tuned_torque": 370,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "841C000",
            },
        },
    },
    "159": {
        "2005 ->": {
            "1.9 JTDm 16V 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C8",
                "Engine_code": "939A2000",
            },
            "1.9 JTDm 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 275,
                "tuned_torque": 350,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16CP39 & Bosch EDC16C39",
                "Engine_code": "939A7000",
            },
            "1.9 JTDm 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A1000",
            },
            "1.9 JTDm 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8 & Bosch EDC16C39",
                "Engine_code": "939A8000",
            },
            "2.0 JTDm 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939B3000",
            },
            "2.0 JTDm 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939B4000",
            },
            "2.4 JTD 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A3000",
            },
            "2.4 JTDm 20v Q4 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "841H000",
            },
            "1.750 TBI 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 320,
                "tuned_torque": 385,
                "cc": 1742,
                "Type_ecu": "Bosch MED17.3.1",
                "Engine_code": "939B1000",
            },
            "1.8 MPI 140hp": {
                "std_power": 140,
                "tuned_power": 154,
                "std_torque": 163,
                "tuned_torque": 184,
                "cc": 1796,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "939B1000",
            },
            "1.9 JTS 160hp": {
                "std_power": 160,
                "tuned_power": 170,
                "std_torque": 190,
                "tuned_torque": 205,
                "cc": 1910,
                "Type_ecu": "Bosch MED7.6.1",
                "Engine_code": "Z19XHR",
            },
            "2.2 JTS 185hp": {
                "std_power": 185,
                "tuned_power": 195,
                "std_torque": 230,
                "tuned_torque": 245,
                "cc": 2198,
                "Type_ecu": "Bosch MED7.6.1",
                "Engine_code": "939A5000",
            },
            "3.2 JTS V6 239hp": {
                "std_power": 239,
                "tuned_power": 260,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 3195,
                "Type_ecu": "Bosch MED7.6.2 & Bosch ME7.8.2",
                "Engine_code": "939A000",
            },
        },
    },
    "166": {
        "1999 - 2002": {
            "2.4 JTD 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C5",
                "Engine_code": "936A000",
            },
            "2.4 JTD 140hp": {
                "std_power": 140,
                "tuned_power": 173,
                "std_torque": 304,
                "tuned_torque": 382,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C5",
                "Engine_code": "839A6000",
            },
            "2.4 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 305,
                "tuned_torque": 385,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "841C000",
            },
        },
        "2003 - 2010": {
            "2.4 JTD 175hp": {
                "std_power": 175,
                "tuned_power": 220,
                "std_torque": 385,
                "tuned_torque": 450,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "841H000",
            },
            "2.4 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 305,
                "tuned_torque": 385,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "841C000",
            },
            "2.4 JTD 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 385,
                "tuned_torque": 440,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C8 & Bosch EDC16C39",
                "Engine_code": "841G000",
            },
        },
    },
    "4C": {
        "2013 ->": {
            "1750 TBI 240hp": {
                "std_power": 240,
                "tuned_power": 255,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1742,
                "Type_ecu": "Bosch MED17.3.3 & Marelli C635",
                "Engine_code": "960A1000",
            },
        },
    },
    "Brera": {
        "2006 - 2008": {
            "2.4 JTDm 20V 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A3000",
            },
            "2.2 JTS 185hp": {
                "std_power": 185,
                "tuned_power": 202,
                "std_torque": 230,
                "tuned_torque": 258,
                "cc": 2198,
                "Type_ecu": "Bosch MED7.6.1",
                "Engine_code": "939A5000",
            },
            "3.2 JTS V6 260hp": {
                "std_power": 260,
                "tuned_power": 270,
                "std_torque": 322,
                "tuned_torque": 340,
                "cc": 3195,
                "Type_ecu": "Bosch MED7.6.2",
                "Engine_code": "939A000",
            },
        },
        "2008 - 2011": {
            "2.0 JTDm 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939B3000",
            },
            "2.0 JTDm 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "844A2000",
            },
            "2.4 JTDm 20V 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A3000",
            },
            "2.4 JTDm 20V 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A9000",
            },
            "1750 TBI 200hp": {
                "std_power": 200,
                "tuned_power": 255,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1742,
                "Type_ecu": "Bosch MED17.3.1",
                "Engine_code": "939B1000",
            },
            "2.2 JTS 185hp": {
                "std_power": 185,
                "tuned_power": 202,
                "std_torque": 230,
                "tuned_torque": 258,
                "cc": 2198,
                "Type_ecu": "Bosch MED7.6.1",
                "Engine_code": "939A5000",
            },
            "3.2 JTS V6 260hp": {
                "std_power": 260,
                "tuned_power": 270,
                "std_torque": 322,
                "tuned_torque": 340,
                "cc": 3195,
                "Type_ecu": "Bosch MED7.6.2",
                "Engine_code": "939A000",
            },
        },
    },
    "Crosswagon": {
        "2005 - 2007": {
            "1.9JTD 150hp": {
                "std_power": 150,
                "tuned_power": 182,
                "std_torque": 305,
                "tuned_torque": 385,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "AR37101",
            },
        },
    },
    "GT": {
        "All": {
            "1.9 JTD 16V 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "937A6000",
            },
            "1.9 JTD 16v 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8 & Bosch EDC16C39",
                "Engine_code": "937A5000",
            },
            "3.2 V6 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 3179,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "936A000",
            },
        },
    },
    "Giulia": {
        "2016 ->": {
            "2.2 JTD 136hp": {
                "std_power": 136,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 530,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55266388",
            },
            "2.2 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 530,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55266388",
            },
            "2.2 JTD 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 450,
                "tuned_torque": 530,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69 & ZF 8HP",
                "Engine_code": "55266388",
            },
            "2.2 JTD 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 470,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55268818",
            },
            "2.2 JTD 160hp": {
                "std_power": 160,
                "tuned_power": 220,
                "std_torque": 450,
                "tuned_torque": 530,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55266388",
            },
            "2.2 JTD 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 450,
                "tuned_torque": 530,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "",
            },
            "2.0T Veloce 280hp": {
                "std_power": 280,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1995,
                "Type_ecu": "Bosch MED17 & Marelli M10JA",
                "Engine_code": "55273835",
            },
            "2.0T 200hp": {
                "std_power": 200,
                "tuned_power": 310,
                "std_torque": 330,
                "tuned_torque": 460,
                "cc": 1995,
                "Type_ecu": "Bosch MED17 & Marelli M10JA & ZF 8HP50",
                "Engine_code": "55273835",
            },
            "2.9 V6 GTA 540hp": {
                "std_power": 540,
                "tuned_power": 545,
                "std_torque": 600,
                "tuned_torque": 710,
                "cc": 2891,
                "Type_ecu": "Bosch MED17.6.9 & ZF 8HP50",
                "Engine_code": "",
            },
            "2.9 V6 Quadrifoglio 510hp": {
                "std_power": 510,
                "tuned_power": 545,
                "std_torque": 600,
                "tuned_torque": 710,
                "cc": 2891,
                "Type_ecu": "Bosch MED17.3.5",
                "Engine_code": "670050436",
            },
        },
    },
    "Giulietta": {
        "2010 - 2014": {
            "1.6 JTDm 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 320,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "940A3000",
            },
            "2.0 JTDm 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "940A4000",
            },
            "2.0 JTDm 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "940A5000",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 206,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch ME17.3.0",
                "Engine_code": "198A4000",
            },
            "1.4 Turbo 105hp": {
                "std_power": 105,
                "tuned_power": 150,
                "std_torque": 206,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "940B1000",
            },
            "1.750 TBi Quadrifogilio Verde": {
                "std_power": 235,
                "tuned_power": 265,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1742,
                "Type_ecu": "Bosch MED17.3.1",
                "Engine_code": "940A1000",
            },
        },
        "2014 - 2016": {
            "1.6 JTDm 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 320,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17C69",
                "Engine_code": "940A3000",
            },
            "2.0 JTDm 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "940A5000",
            },
            "2.0 JTDm 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17C69",
                "Engine_code": "940A8000",
            },
            "1.4 Turbo Multiair 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF & Marelli 8GMK",
                "Engine_code": "940A2000",
            },
            "1.4 Turbo Multiair 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF & Marelli MM8GMK & Marelli 9GF",
                "Engine_code": "940A2000",
            },
            "1.4 Turbo 105hp": {
                "std_power": 105,
                "tuned_power": 150,
                "std_torque": 206,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch ME17.3.0",
                "Engine_code": "940B1000",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 206,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "198A4000",
            },
            "1.750 TBi Quadrifogilio Verde": {
                "std_power": 240,
                "tuned_power": 255,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1742,
                "Type_ecu": "Bosch MED17.3.3",
                "Engine_code": "940B2000",
            },
        },
        "2016 ->": {
            "1.6 JTDm 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "940C1000",
            },
            "2.0 JTDm 150hp": {
                "std_power": 150,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17C69",
                "Engine_code": "940A5000",
            },
            "2.0 JTDm 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "940A8000",
            },
            "1.4 Turbo Multiair 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 230,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF & Bosch ME17.3.0 & Marelli MM8GMK",
                "Engine_code": "940A2000",
            },
            "1.4 Turbo Multiair 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF & Marelli MM8GMK",
                "Engine_code": "940A2000",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 215,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0",
                "Engine_code": "198A4000",
            },
            "1.750 TBi 240hp": {
                "std_power": 240,
                "tuned_power": 255,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1742,
                "Type_ecu": "Bosch MED17.3.3",
                "Engine_code": "940B2000",
            },
        },
    },
    "MiTo": {
        "2008 - 2013": {
            "1.3 JTDM S&S 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF",
                "Engine_code": "199B1000",
            },
            "1.3 JTDM 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3",
                "Engine_code": "199A3000",
            },
            "1.3 JTDM 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF",
                "Engine_code": "199B4000",
            },
            "1.3 JTDM 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF",
                "Engine_code": "199B1000",
            },
            "1.6 JTDM 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C49",
                "Engine_code": "955A3000",
            },
            "1.6 JTDM 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "955A3000",
            },
            "0.9 Twinair 85hp": {
                "std_power": 85,
                "tuned_power": 100,
                "std_torque": 145,
                "tuned_torque": 185,
                "cc": 875,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "312A2000",
            },
            "1.4 Multiair 135hp": {
                "std_power": 135,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "955A2000",
            },
            "1.4 Turbo 155hp": {
                "std_power": 155,
                "tuned_power": 175,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "199A8000",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch MED17.3.0",
                "Engine_code": "198A4000",
            },
            "1.4 Turbo 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "955A3000",
            },
            "1.4 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "940A2000",
            },
            "1.4 Turbo 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "955A8000",
            },
            "1.4i 16v 78hp": {
                "std_power": 78,
                "tuned_power": 100,
                "std_torque": 120,
                "tuned_torque": 130,
                "cc": 1368,
                "Type_ecu": "Marelli IAW5SF & Bosch ME7.9.10 & Marelli MM8GMF",
                "Engine_code": "350A1000",
            },
            "1.4i 16v 95hp": {
                "std_power": 95,
                "tuned_power": 100,
                "std_torque": 120,
                "tuned_torque": 130,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Marelli MM8GMF",
                "Engine_code": "199A6000",
            },
            "GTA 235": {
                "std_power": 235,
                "tuned_power": 265,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 0,
                "Type_ecu": "Bosch MED17.3.1",
                "Engine_code": "",
            },
        },
        "2013 - 2016": {
            "1.3 JTDm 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3 & Marelli MJ8DF",
                "Engine_code": "199B8000",
            },
            "0.9 TwinAir 105hp": {
                "std_power": 105,
                "tuned_power": 110,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli 8GSW",
                "Engine_code": "199B6000",
            },
            "1.4 MultiAir 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 230,
                "tuned_torque": 285,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF & Marelli MM8GMK",
                "Engine_code": "955B1000",
            },
            "1.4 MultiAir 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "940A2000",
            },
            "1.4 TwinAir 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 206,
                "tuned_torque": 250,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch MED17.3.0",
                "Engine_code": "198A4000",
            },
        },
        "2016 ->": {
            "1.3 JTDm 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF & Marelli MJD9DF",
                "Engine_code": "199B8000",
            },
            "1.3 JTDm 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF & Marelli MJ9DF",
                "Engine_code": "199B1000",
            },
            "0.9 TwinAir 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli 8GSF",
                "Engine_code": "199B7000",
            },
            "0.9 TwinAir 105hp": {
                "std_power": 105,
                "tuned_power": 110,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli 8GSW",
                "Engine_code": "199B6000",
            },
            "1.4 Multiair 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 230,
                "tuned_torque": 285,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF & Marelli 8GMK",
                "Engine_code": "955B1000",
            },
            "1.4 Multiair 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMF & Marelli MM8GMK",
                "Engine_code": "940A2000",
            },
        },
    },
    "Spider": {
        "All": {
            "2.0 JTD 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939B3000",
            },
            "2.0 JTD 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "844A2000",
            },
            "2.4 JTDm 20V 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A3000",
            },
            "2.4 JTDm 20V 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A3000",
            },
            "1750 Turbo": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1742,
                "Type_ecu": "Bosch MED17.3.1",
                "Engine_code": "940A1000",
            },
            "2.2 JTS 185hp": {
                "std_power": 185,
                "tuned_power": 195,
                "std_torque": 230,
                "tuned_torque": 240,
                "cc": 2198,
                "Type_ecu": "Bosch MED7.6.1",
                "Engine_code": "939A5000",
            },
            "3.2 V6 JTS 260hp": {
                "std_power": 260,
                "tuned_power": 275,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 3195,
                "Type_ecu": "Bosch MED7.6.2",
                "Engine_code": "939A000",
            },
        },
    },
    "Stelvio": {
        "2017 - 2021": {
            "2.2 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 530,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55275156",
            },
            "2.2 JTD 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 450,
                "tuned_torque": 530,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55275156",
            },
            "2.2 JTD 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 470,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55271838",
            },
            "2.2 JTD 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 450,
                "tuned_torque": 530,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "46335692",
            },
            "2.2 JTDm 160hp": {
                "std_power": 160,
                "tuned_power": 220,
                "std_torque": 450,
                "tuned_torque": 530,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "",
            },
            "2.0T 200hp": {
                "std_power": 200,
                "tuned_power": 310,
                "std_torque": 330,
                "tuned_torque": 460,
                "cc": 1995,
                "Type_ecu": "Marelli M10JA",
                "Engine_code": "55273835",
            },
            "2.0T 280hp": {
                "std_power": 280,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1995,
                "Type_ecu": "Marelli M10JA & ZF 8HP45",
                "Engine_code": "55273835",
            },
            "2.9 V6 Turbo 510hp": {
                "std_power": 510,
                "tuned_power": 580,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 2891,
                "Type_ecu": "Bosch MED17.3.5",
                "Engine_code": "670050436",
            },
        },
        "2022 -> ...": {
            "2.0T 280hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.2 JTD 210hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.9 V6 Turbo 520hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Tonale": {
        "2022 -> ...": {
            "1.3T Multiair eQ4 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 430,
                "tuned_torque": 460,
                "cc": 1332,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5T 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1469,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5T 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1469,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 VGT-D 130hp": {
                "std_power": 130,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
},
"Alpina": {
    "B3": {
        "All": {
            "3.5i 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2 & Siemens/Continental MSD80 & Siemens/Continental MSD81.2",
                "Engine_code": "N54B30A",
            },
            "Bi-Turbo 410hp": {
                "std_power": 410,
                "tuned_power": 450,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MEVD17.2.G",
                "Engine_code": "N55B30A",
            },
            "Bi-Turbo 462hp": {
                "std_power": 462,
                "tuned_power": 490,
                "std_torque": 700,
                "tuned_torque": 760,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "B3 S": {
        "All": {
            "3.0T 440hp": {
                "std_power": 440,
                "tuned_power": 480,
                "std_torque": 660,
                "tuned_torque": 700,
                "cc": 2979,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5i 400hp": {
                "std_power": 400,
                "tuned_power": 450,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2",
                "Engine_code": "N54B30A",
            },
        },
    },
    "B4": {
        "2014 - 2017": {
            "B4 Bi-Turbo 410hp": {
                "std_power": 410,
                "tuned_power": 450,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2979,
                "Type_ecu": "ZF 8HP & Bosch MEVD17.2",
                "Engine_code": "N55B30A",
            },
        },
        "2017 -> ...": {
            "S 3.0T 440hp": {
                "std_power": 440,
                "tuned_power": 480,
                "std_torque": 660,
                "tuned_torque": 700,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "",
            },
        },
    },
    "B5": {
        "E60/E61 - 2005 - 2010": {
            "4.4 V8 Bi-Turbo 507hp": {
                "std_power": 507,
                "tuned_power": 600,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 4398,
                "Type_ecu": "Bosch ME9.2",
                "Engine_code": "N62B44A19",
            },
        },
        "F10/11 - 2010 - 2013": {
            "B5 Bi-Turbo 540hp": {
                "std_power": 540,
                "tuned_power": 575,
                "std_torque": 730,
                "tuned_torque": 800,
                "cc": 4398,
                "Type_ecu": "Bosch MEVD17.2.8 & Siemens/Continental MSD85",
                "Engine_code": "N63B44A",
            },
        },
        "F10/11 LCI - 2013 - 2017": {
            "4.4 V8 Bi-Turbo 600hp": {
                "std_power": 600,
                "tuned_power": 680,
                "std_torque": 800,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "",
            },
        },
        "F90 - 2020 ->": {
            "4.4 V8 Bi-Turbo 621hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "B6": {
        "All": {
            "4.4 V8 Bi-Turbo 500hp": {
                "std_power": 500,
                "tuned_power": 600,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 4398,
                "Type_ecu": "Bosch MEV946",
                "Engine_code": "N62B44A",
            },
            "4.4 V8 Bi-Turbo 600hp": {
                "std_power": 600,
                "tuned_power": 680,
                "std_torque": 800,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEV946 & Bosch MEVD17.2.8 & Bosch MEVD17.2.H",
                "Engine_code": "N63B44B",
            },
            "B6 Bi-Turbo 540hp": {
                "std_power": 540,
                "tuned_power": 575,
                "std_torque": 730,
                "tuned_torque": 800,
                "cc": 4398,
                "Type_ecu": "Bosch MEV946 & Bosch MEVD17.2.8",
                "Engine_code": "N63B44A",
            },
        },
    },
    "B6 S": {
        "All": {
            "4.4 V8 Bi-Turbo 530hp": {
                "std_power": 530,
                "tuned_power": 600,
                "std_torque": 725,
                "tuned_torque": 800,
                "cc": 4398,
                "Type_ecu": "Bosch MEV946",
                "Engine_code": "N62B44A",
            },
        },
    },
    "B7": {
        "F01 - 2011 - 2016": {
            "4.4 V8 Bi-Turbo 507hp": {
                "std_power": 507,
                "tuned_power": 600,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 4398,
                "Type_ecu": "Bosch ME9.2 & Siemens/Continental MSD85",
                "Engine_code": "N63M10A",
            },
            "B7 Bi-Turbo 540hp": {
                "std_power": 540,
                "tuned_power": 575,
                "std_torque": 730,
                "tuned_torque": 800,
                "cc": 4398,
                "Type_ecu": "Bosch MEV946 & Bosch MEVD17.2.8",
                "Engine_code": "N63M10A",
            },
        },
        "G12 - 2017 -> ...": {
            "4.4 V8 Bi-Turbo 608hp": {
                "std_power": 608,
                "tuned_power": 680,
                "std_torque": 800,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch ME9.2 & Bosch MG1CS002 & Bosch MG1CS003",
                "Engine_code": "",
            },
        },
    },
    "B8": {
        "2021 -> ...": {
            "4.4 Bi-Turbo V8 621hp": {
                "std_power": 621,
                "tuned_power": 680,
                "std_torque": 800,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "",
                "Engine_code": "N63B44T3",
            },
        },
    },
    "D3": {
        "E90/91 - 2005 - 2013": {
            "2.0D 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 410,
                "tuned_torque": 460,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C76 & Bosch EDC16",
                "Engine_code": "M47D22",
            },
            "2.0D 214hp": {
                "std_power": 214,
                "tuned_power": 265,
                "std_torque": 450,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C76 & Bosch EDC17CP02",
                "Engine_code": "N47D20D",
            },
            "D3 Bi-Turbo 350hp": {
                "std_power": 350,
                "tuned_power": 395,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30",
            },
        },
        "F30/31 - 2013 - 2015": {
            "D3 Bi-Turbo 350hp": {
                "std_power": 350,
                "tuned_power": 395,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30",
            },
        },
        "F30/31 - LCI - 2015 ->": {
            "D3 Bi-Turbo 350hp": {
                "std_power": 350,
                "tuned_power": 395,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30",
            },
        },
        "G20 - 2020 -> ...": {
            "S 3.0 355hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "D4": {
        "All": {
            "D4 Bi-Turbo 350hp": {
                "std_power": 350,
                "tuned_power": 390,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30",
            },
        },
    },
    "D5": {
        "F10/11 - 2010 - 2016": {
            "D5 Bi-Turbo 350hp": {
                "std_power": 350,
                "tuned_power": 390,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30",
            },
        },
        "G30/31 LCI - 2020 ->": {
            "3.0 Bi-Turbo 408hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "G30/31- 2017 - 2019": {
            "S 3.0 Bi-Turbo 388hp": {
                "std_power": 388,
                "tuned_power": 450,
                "std_torque": 800,
                "tuned_torque": 850,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "XD3": {
        "2014 - 2017": {
            "XD3 Bi-Turbo 350hp": {
                "std_power": 350,
                "tuned_power": 395,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30B",
            },
        },
        "2018 - 2020": {
            "3.0 Quad-Turbo 388hp": {
                "std_power": 388,
                "tuned_power": 450,
                "std_torque": 770,
                "tuned_torque": 850,
                "cc": 2993,
                "Type_ecu": "Bosch MD1CP002",
                "Engine_code": "",
            },
        },
        "2021 -> ...": {
            "3.0D 394hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "XD4": {
        "2018 - 2020": {
            "3.0 Quad-Turbo 388hp": {
                "std_power": 388,
                "tuned_power": 450,
                "std_torque": 770,
                "tuned_torque": 850,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2021 -> ...": {
            "3.0D 394hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
},
"Alpine": {
    "A110": {
        "2017 ->": {
            "1.8 Turbo 252hp": {
                "std_power": 252,
                "tuned_power": 285,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1798,
                "Type_ecu": "Hitachi BED436 SH72533 & Hitachi BED501",
                "Engine_code": "M5P P4",
            },
            "S 1.8 Turbo 292hp": {
                "std_power": 292,
                "tuned_power": 310,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1798,
                "Type_ecu": "Hitachi BED501 & Hitachi BED501",
                "Engine_code": "",
            },
        },
    },
},
"Aston Martin": {
    "DB11": {
        "2016 ->": {
            "4.0 V8 510hp": {
                "std_power": 510,
                "tuned_power": 560,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "",
            },
            "4.0 V8 535hp": {
                "std_power": 535,
                "tuned_power": 570,
                "std_torque": 650,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "5.2 V12 Twinturbo 608hp": {
                "std_power": 608,
                "tuned_power": 700,
                "std_torque": 700,
                "tuned_torque": 900,
                "cc": 5204,
                "Type_ecu": "Bosch ME17.8.31 & ZF 8HP70",
                "Engine_code": "",
            },
            "AMR 5.2 V12 Twinturbo 639hp": {
                "std_power": 639,
                "tuned_power": 740,
                "std_torque": 700,
                "tuned_torque": 1050,
                "cc": 5204,
                "Type_ecu": "Bosch MED17.8.31",
                "Engine_code": "AE31",
            },
        },
    },
    "DB9": {
        "All": {
            "5.9 V12 455hp": {
                "std_power": 455,
                "tuned_power": 480,
                "std_torque": 570,
                "tuned_torque": 600,
                "cc": 5935,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "AM04",
            },
            "6.0 V12 470hp": {
                "std_power": 470,
                "tuned_power": 500,
                "std_torque": 600,
                "tuned_torque": 620,
                "cc": 5935,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "AM09",
            },
        },
    },
    "DBS": {
        "All": {
            "5.2 V8 Twinturbo Superleggera 725hp": {
                "std_power": 725,
                "tuned_power": 760,
                "std_torque": 900,
                "tuned_torque": 990,
                "cc": 5204,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "5.9 V12 517hp": {
                "std_power": 517,
                "tuned_power": 540,
                "std_torque": 570,
                "tuned_torque": 590,
                "cc": 5935,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "AM11C",
            },
        },
    },
    "DBX": {
        "2020 ->": {
            "4.0 V8 Bi-Turbo 550hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "4.0 V8 Bi-Turbo 707hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Rapide": {
        "All": {
            "6.0 V12 476hp": {
                "std_power": 476,
                "tuned_power": 500,
                "std_torque": 600,
                "tuned_torque": 630,
                "cc": 5935,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "AM16",
            },
            "6.0 V12 560hp": {
                "std_power": 560,
                "tuned_power": 575,
                "std_torque": 630,
                "tuned_torque": 650,
                "cc": 5935,
                "Type_ecu": "Bosch ME17.8.31",
                "Engine_code": "",
            },
        },
    },
    "Vantage": {
        "... -> 2018": {
            "4.3 V8 380hp": {
                "std_power": 380,
                "tuned_power": 410,
                "std_torque": 410,
                "tuned_torque": 435,
                "cc": 4280,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "AM05",
            },
            "4.3 V8 400hp": {
                "std_power": 400,
                "tuned_power": 425,
                "std_torque": 418,
                "tuned_torque": 440,
                "cc": 4280,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "GAM05",
            },
            "4.7 V8 S 436hp": {
                "std_power": 436,
                "tuned_power": 460,
                "std_torque": 490,
                "tuned_torque": 515,
                "cc": 4735,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "AM15",
            },
            "4.7 V8 426hp": {
                "std_power": 426,
                "tuned_power": 450,
                "std_torque": 470,
                "tuned_torque": 500,
                "cc": 4735,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "AM15",
            },
            "6.0 V12 517hp": {
                "std_power": 517,
                "tuned_power": 542,
                "std_torque": 570,
                "tuned_torque": 600,
                "cc": 5935,
                "Type_ecu": "Ford EEC-VI & Bosch MED17.8.31",
                "Engine_code": "AM10",
            },
        },
        "2018 -> ...": {
            "4.0 V8 Bi-Turbo 510hp": {
                "std_power": 510,
                "tuned_power": 570,
                "std_torque": 685,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "",
            },
            "4.0 V8 Bi-Turbo 535hp": {
                "std_power": 535,
                "tuned_power": 570,
                "std_torque": 685,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "",
            },
        },
    },
    "Virage": {
        "All": {
            "6.0 liter V12 490hp": {
                "std_power": 490,
                "tuned_power": 510,
                "std_torque": 570,
                "tuned_torque": 600,
                "cc": 5935,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "AM25",
            },
        },
    },
},
"Audi": {
    "A1": {
        "8X - 2010 - 2014": {
            "1.6 TDI CR 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYC",
            },
            "1.6 TDI CR 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Temic DQ200",
                "Engine_code": "CAYB",
            },
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFHD",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFHB",
            },
            "1.2 TFSI 86hp": {
                "std_power": 86,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10",
                "Engine_code": "CBZA",
            },
            "1.2 TFSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10",
                "Engine_code": "CBZB",
            },
            "1.4 TFSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Temic DQ200 & Bosch ME17.5.20",
                "Engine_code": "CNVA",
            },
            "1.4 TFSI 185hp": {
                "std_power": 185,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Temic DQ200",
                "Engine_code": "CTHG",
            },
            "1.4 TFSI 140hp (CPTA)": {
                "std_power": 140,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CPTA",
            },
            "2.0 TFSI 256hp": {
                "std_power": 256,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "CDLH",
            },
            "S1 2.0 TFSI 231hp": {
                "std_power": 231,
                "tuned_power": 300,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18",
                "Engine_code": "CWZA",
            },
        },
        "8X - 2015 - 2017": {
            "1.4 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CUSB",
            },
            "1.6 TDI 116hp": {
                "std_power": 116,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2",
                "Engine_code": "CXMA",
            },
            "1.0 TFSI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200 & Bosch MED17.5.25",
                "Engine_code": "CHZB",
            },
            "1.0 TFSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "",
            },
            "1.0 TFSI 82hp": {
                "std_power": 82,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHZE",
            },
            "1.4 TFSI (COD) 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1395,
                "Type_ecu": "Bosch MED17 & Temic DQ200",
                "Engine_code": "COD",
            },
            "1.4 TFSI 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Temic DQ200 & Bosch MED17.5.25 & Bosch MED17.5.21",
                "Engine_code": "CZCA",
            },
            "1.8 TFSI 192hp": {
                "std_power": 192,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Bosch MED17 & Siemens/Continental Simos 18.1 & Temic DQ200",
                "Engine_code": "DAJB",
            },
            "S1 2.0 TFSI 231hp": {
                "std_power": 231,
                "tuned_power": 300,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18",
                "Engine_code": "CWZA",
            },
        },
        "GB - 2018 ->": {
            "25 TFSi (1.0T) 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.27 & Bosch MED17.1.27",
                "Engine_code": "CHZL / DKLA",
            },
            "30 TFSi (1.0T) 116hp": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Temic DQ200 Gen2",
                "Engine_code": "DKJA / DKRF",
            },
            "35 TFSI (1.5T) 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1498,
                "Type_ecu": "Bosch MED17.5.27 & Bosch MG1CS011",
                "Engine_code": "DADA / DPCA",
            },
            "40 TFSI (2.0T) 200hp": {
                "std_power": 200,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS111 & Bosch MG1CS001",
                "Engine_code": "CZPC / DKZC",
            },
        },
    },
    "A3": {
        "8L - 1996 - 2003": {
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 310,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AHF",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 265,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V & Bosch MSA15",
                "Engine_code": "AGR",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ATD",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 310,
                "tuned_torque": 385,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "1.6i 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 163,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 3 & Siemens/Continental Simos 7.1",
                "Engine_code": "AVU",
            },
            "1.8T 20V 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch M3.8.3 & Bosch ME7.5",
                "Engine_code": "AGU",
            },
            "1.8T 20V 180hp": {
                "std_power": 180,
                "tuned_power": 192,
                "std_torque": 235,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5 & Temic DQ250-6A/6F/6V-MQB",
                "Engine_code": "AUQ",
            },
            "1.8i 20v 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 170,
                "tuned_torque": 190,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "APG",
            },
        },
        "8P - 2003 - 2008": {
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U31 & Bosch EDC16U34",
                "Engine_code": "BKC",
            },
            "2.0 TDI DPF 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2 & Bosch EDC17CP14",
                "Engine_code": "BMM",
            },
            "2.0 TDI DPF 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U & Bosch EDC17C46",
                "Engine_code": "AZV",
            },
            "2.0 TDI DPF 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC17CP14 & Bosch EDC17C46 & Bosch EDC16U1",
                "Engine_code": "BMM",
            },
            "2.0 TDI DPF 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Siemens/Continental PPD1.x",
                "Engine_code": "BUY",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U & Bosch EDC16U34 & Bosch EDC17CP14",
                "Engine_code": "BKD",
            },
            "2.0 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U & Bosch EDC17C46 & Bosch EDC16U34",
                "Engine_code": "BMM",
            },
            "1.6 FSI 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLF",
            },
            "1.6i 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 163,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 7.1",
                "Engine_code": "BSE",
            },
            "1.8 TFSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5 & Temic DQ250",
                "Engine_code": "BZB",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLX",
            },
            "2.0 TFSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5 & Bosch MED17.5.2 & Bosch MED9.1 & Temic DQ250",
                "Engine_code": "BWA",
            },
            "3.2 V6 250hp": {
                "std_power": 250,
                "tuned_power": 265,
                "std_torque": 320,
                "tuned_torque": 340,
                "cc": 3189,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BMJ",
            },
        },
        "8P - 2008 - 2012": {
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Bosch EDC17C64 & Temic DQ200 Gen2",
                "Engine_code": "CAY",
            },
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYB",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34",
                "Engine_code": "BLS",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14",
                "Engine_code": "CFF",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Temic DQ250 & Bosch EDC17CP20",
                "Engine_code": "CFF",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14",
                "Engine_code": "BUY",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46 & Temic DQ250-6A/6F/6V-MQB",
                "Engine_code": "CFG",
            },
            "1.2 TFSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Siemens/Continental Simos 10.13",
                "Engine_code": "CBZ",
            },
            "1.4 TFSI 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.20 & Bosch MED17.5.5 & Temic DQ200 & Bosch MED17.5.25",
                "Engine_code": "CAX",
            },
            "1.4 TFSI 122hp": {
                "std_power": 122,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.21 & Temic DQ200 & Bosch MED17.5.20",
                "Engine_code": "CMB",
            },
            "1.8 TFSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5 & Temic DQ200 & Temic DQ250Fxx",
                "Engine_code": "BZB",
            },
            "2.0 TFSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5 & Bosch MED17.5.2 & Temic DQ250 & Bosch MED9.1 & Bosch MED17.1",
                "Engine_code": "CCZ",
            },
            "3.2 V6 250hp": {
                "std_power": 250,
                "tuned_power": 265,
                "std_torque": 320,
                "tuned_torque": 340,
                "cc": 3198,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BMJ",
            },
        },
        "8V - Mk1 - 2012 - 2016": {
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Siemens/Continental PCR 2.1 & Siemens/Continental DQ200 Gen2 & Temic DQ200-MQB",
                "Engine_code": "CLH",
            },
            "1.6 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2 & Bosch EDC17C74 & Temic DQ200",
                "Engine_code": "CLH",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250",
                "Engine_code": "CRB",
            },
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17CP14",
                "Engine_code": "CRFC",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250 & Temic DQ381",
                "Engine_code": "CUN",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CRFA",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74",
                "Engine_code": "CFF",
            },
            "1.2 TFSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200 Gen2",
                "Engine_code": "CYV",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Siemens/Continental Simos 10",
                "Engine_code": "CJZ",
            },
            "1.4 TFSI 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200-MQB & Bosch MED17.1.21 & Siemens/Continental DQ200 Gen2",
                "Engine_code": "CZE",
            },
            "1.4 TFSI 140hp (CHPA-CPTA)": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200-MQB",
                "Engine_code": "CPTA",
            },
            "1.4 TFSI 122hp (CMBA-CPVA)": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200",
                "Engine_code": "CXS",
            },
            "1.4 TFSI 125hp (CMBA-CPVA)": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200 Gen2",
                "Engine_code": "CMB",
            },
            "1.4 TFSI 125hp (CZCA)": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200-MQB",
                "Engine_code": "CZC",
            },
            "1.4 TGI G-Tron 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.25 & Temic DQ200-MQB",
                "Engine_code": "CPWA",
            },
            "1.8 TFSI 180hp": {
                "std_power": 180,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 370,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 12 & Temic DQ200 & Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CJS",
            },
            "2.0 TFSI 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 258,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 12 & Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CZPB",
            },
            "E-Tron 204hp": {
                "std_power": 204,
                "tuned_power": 285,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.21 & Temic DQ400E",
                "Engine_code": "CUK",
            },
            "RS3 2.5 TFSI 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 465,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CZG",
            },
            "S3 2.0 TFSI 300hp": {
                "std_power": 300,
                "tuned_power": 360,
                "std_torque": 380,
                "tuned_torque": 480,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 12 & Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CJX",
            },
        },
        "8V - Mk2 - 2016 - 2019": {
            "1.6 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C74 & Siemens/Continental DQ200-G2_PQ & Temic DQ200 Gen2",
                "Engine_code": "CXX",
            },
            "1.6 TDI 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2 & Temic DQ200-MQB & Temic DQ200 Gen2",
                "Engine_code": "DDYA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250-6A/6F/6V-MQB & Temic DQ381",
                "Engine_code": "CRL",
            },
            "2.0 TDI 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250 & Temic DQ381",
                "Engine_code": "CUN",
            },
            "1.0 TFSI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.27 & Temic DQ200 & Bosch MED17.1.27",
                "Engine_code": "CHZ",
            },
            "1.4 TFSI (COD) 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Bosch MED17.1.27 & Temic DQ250 & Bosch MED17.1.21 & Temic DQ200 Gen2",
                "Engine_code": "CUK",
            },
            "1.5 TFSI COD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200 Gen2",
                "Engine_code": "DADA",
            },
            "2.0 TFSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Temic DQ380 & Bosch MG1CS111 & Temic DQ381",
                "Engine_code": "CZPB",
            },
            "E-Tron 204hp": {
                "std_power": 204,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.21 & Temic DQ400E",
                "Engine_code": "CUK",
            },
        },
        "8Y - 2020 ->": {
            "30 TDI (2.0D) 116hp": {
                "std_power": 116,
                "tuned_power": 195,
                "std_torque": 300,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DSUD",
            },
            "35 TDI (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1 & Temic DQ200-MQB & Bosch EDC17C74",
                "Engine_code": "DSRB",
            },
            "30 TFSI (1.0T) 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DLAA",
            },
            "30 TFSI (1.0T) 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DLAA",
            },
            "35 TFSI (1.5T) 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200 Gen2",
                "Engine_code": "DPCA",
            },
            "35 TFSI (1.5T) 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1498,
                "Type_ecu": "",
                "Engine_code": "DPCA",
            },
            "40 TFSI-e 204hp": {
                "std_power": 204,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.28",
                "Engine_code": "DGEA",
            },
            "45 TFSI-e 245hp": {
                "std_power": 245,
                "tuned_power": 285,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "",
                "Engine_code": "DGEA",
            },
        },
    },
    "A4": {
        "B5 - 1995 - 2001": {
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 143,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AFN",
            },
            "1.9 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 90,
                "std_torque": 150,
                "tuned_torque": 225,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AFF",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 202,
                "tuned_torque": 270,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V & Bosch MSA15.5",
                "Engine_code": "1Z",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 285,
                "tuned_torque": 360,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AFY",
            },
            "1.8 T 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5 & Bosch M3.8.2 & Bosch ME3.2",
                "Engine_code": "AEB",
            },
            "1.8 T 180hp": {
                "std_power": 180,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch M3.8.2",
                "Engine_code": "",
            },
            "1.8i 20v 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "ADR",
            },
        },
        "B6 - 2001 - 2004": {
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AVB",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+ & Bosch EDC16",
                "Engine_code": "BKE",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AWX",
            },
            "2.5 TDI 155hp": {
                "std_power": 155,
                "tuned_power": 190,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AYM",
            },
            "2.5 TDI 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "BAU",
            },
            "2.5 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 390,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "BDG",
            },
            "1.6i 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 163,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 7.1 & Siemens/Continental Simos 3",
                "Engine_code": "ARM",
            },
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "AVJ",
            },
            "1.8T 20v 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 225,
                "tuned_torque": 320,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "BFB",
            },
            "1.8T 20v 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "BEX",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10 & Bosch MED7.1.1",
                "Engine_code": "AWA",
            },
            "2.0i 130hp": {
                "std_power": 130,
                "tuned_power": 140,
                "std_torque": 195,
                "tuned_torque": 210,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "ALT",
            },
            "3.0 V6 220hp": {
                "std_power": 220,
                "tuned_power": 235,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 2976,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "ASN",
            },
        },
        "B7 - 2004 - 2008": {
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 285,
                "tuned_torque": 340,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16U34 & Bosch EDC16U1",
                "Engine_code": "BKE",
            },
            "2.0 TDI DPF 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2 & Siemens/Continental PPD1.3",
                "Engine_code": "BRD",
            },
            "2.0 TDI DPF 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BRC",
            },
            "2.0 TDI DPF 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16U34",
                "Engine_code": "BPW",
            },
            "2.0 TDI DPF 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BRE",
            },
            "2.0 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16U34",
                "Engine_code": "BNA",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16U34 & Bosch EDC16U1 & Temic VL381",
                "Engine_code": "BLB",
            },
            "2.5 V6 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AKN",
            },
            "2.7 V6 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2698,
                "Type_ecu": "Bosch EDC16CP34",
                "Engine_code": "BSG",
            },
            "2.7 V6 TDI 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2698,
                "Type_ecu": "Bosch EDC16CP34",
                "Engine_code": "BPP",
            },
            "3.0 V6 TDI 204hp": {
                "std_power": 204,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34",
                "Engine_code": "BKN",
            },
            "3.0 V6 TDI 233hp": {
                "std_power": 233,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34",
                "Engine_code": "ASB",
            },
            "1.6i 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 163,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental Simos 7.1 & Siemens/Continental Simos 3.4",
                "Engine_code": "ALZ",
            },
            "1.8 T 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 225,
                "tuned_torque": 320,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5 & Bosch MED17.5",
                "Engine_code": "BFB",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "AWA",
            },
            "2.0 TFSI DTM 220hp": {
                "std_power": 220,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1.5",
                "Engine_code": "BUL",
            },
            "2.0 TFSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Bosch GS19",
                "Engine_code": "BPG",
            },
            "2.0 TFSI 170hp": {
                "std_power": 170,
                "tuned_power": 240,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Siemens/Continental PPD1.3",
                "Engine_code": "",
            },
            "2.0i 130hp": {
                "std_power": 130,
                "tuned_power": 140,
                "std_torque": 195,
                "tuned_torque": 205,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.1 & Bosch ME7.5",
                "Engine_code": "ALT",
            },
            "3.2 FSI 256hp": {
                "std_power": 256,
                "tuned_power": 275,
                "std_torque": 330,
                "tuned_torque": 350,
                "cc": 3123,
                "Type_ecu": "Siemens/Continental Simos 6.2",
                "Engine_code": "BKH",
            },
        },
        "B8 - 2008 - 2011": {
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17CP14 & Bosch EDC17CP20 & Bosch EDC17C46 & Temic VL381",
                "Engine_code": "CAGA",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Bosch EDC17CP20 & Bosch EDC16U31",
                "Engine_code": "CAHB",
            },
            "2.0 TDI CR 120hp": {
                "std_power": 120,
                "tuned_power": 185,
                "std_torque": 290,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Bosch EDC17CP20",
                "Engine_code": "CAGC",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Bosch EDC17CP20 & Bosch EDC17C64",
                "Engine_code": "CAGB",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Bosch EDC17CP14",
                "Engine_code": "CAHA",
            },
            "2.7 V6 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2698,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17CP04",
                "Engine_code": "CAMB",
            },
            "2.7 V6 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2698,
                "Type_ecu": "Bosch EDC17CP14 & Temic VL381 & Bosch EDC17CP04",
                "Engine_code": "CAMA",
            },
            "3.0 V6 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 290,
                "std_torque": 500,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP04 & Bosch EDC17CP24 & Bosch EDC17CP14 & Temic DL501",
                "Engine_code": "CAPA",
            },
            "3.0 V6 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 290,
                "std_torque": 500,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP14 & ZF DL501",
                "Engine_code": "CCWB",
            },
            "1.8 TFSI 120hp": {
                "std_power": 120,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 310,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5 & Temic VL381",
                "Engine_code": "CDHA",
            },
            "1.8 TFSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5 & Temic VL381",
                "Engine_code": "CCUA",
            },
            "2.0 TFSI Multitronic 180hp": {
                "std_power": 180,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17 & Temic VL381",
                "Engine_code": "CAEA",
            },
            "2.0 TFSI 180hp": {
                "std_power": 180,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Temic VL381 & Bosch MED17.5 & Siemens/Continental Simos 12.1",
                "Engine_code": "CAEA",
            },
            "2.0 TFSI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Siemens/Continental Simos 18 & ZF DL501",
                "Engine_code": "CADA",
            },
            "3.0 TFSI 290hp": {
                "std_power": 290,
                "tuned_power": 400,
                "std_torque": 420,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 16 & Siemens/Continental Simos 8.5 & Siemens/Continental Simos 8.4",
                "Engine_code": "CRED",
            },
            "3.0 TFSI 272hp": {
                "std_power": 272,
                "tuned_power": 400,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & Siemens/Continental Simos 16",
                "Engine_code": "CGWC",
            },
            "3.2 FSI 265hp": {
                "std_power": 265,
                "tuned_power": 285,
                "std_torque": 330,
                "tuned_torque": 350,
                "cc": 3197,
                "Type_ecu": "Bosch MED9.1 & Siemens/Continental Simos 6.2 & Siemens/Continental Simos 8.2",
                "Engine_code": "CALA",
            },
        },
        "B8 - 2012 - 2015": {
            "2.0 TDI CR 120hp": {
                "std_power": 120,
                "tuned_power": 185,
                "std_torque": 290,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP20",
                "Engine_code": "CJCC",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C46 & Bosch EDC17C74 & Temic VL381",
                "Engine_code": "CJCB",
            },
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C46 & Temic VL381 & Bosch EDC17C14",
                "Engine_code": "CJCA",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17C64 & Bosch MD1CS004",
                "Engine_code": "CGLD",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C46 & Bosch EDC17C74 & Bosch EDC17CP46 & Temic VL381",
                "Engine_code": "CJCD",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Temic VL381 & Temic DL501 Gen2 & Temic DL501 Gen1",
                "Engine_code": "CGLC",
            },
            "2.0 TDI CR 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DL501 Gen2 & Temic DL382",
                "Engine_code": "CNHA",
            },
            "3.0 V6 TDI FW 204hp": {
                "std_power": 204,
                "tuned_power": 270,
                "std_torque": 400,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "CLAB",
            },
            "3.0 V6 TDI 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 500,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic DL501 Gen2 & Bosch EDC17CP04",
                "Engine_code": "CKVB",
            },
            "1.4 TFSI 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1395,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "",
            },
            "1.8 TFSI 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 12 & Temic VL381",
                "Engine_code": "CJEB",
            },
            "1.8 TFSI 120hp": {
                "std_power": 120,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5.2 & Siemens/Continental Simos 12",
                "Engine_code": "CABB",
            },
            "1.8 TFSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5.2",
                "Engine_code": "CDHB",
            },
            "2.0 TFSI 180hp": {
                "std_power": 180,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Siemens/Continental Simos 18.1 & Temic VL381",
                "Engine_code": "CFKA",
            },
            "2.0 TFSI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Bosch MED17.5 & Temic DL381 & Temic DL501 & ZF AL551",
                "Engine_code": "CPMA",
            },
            "2.0 TFSI 225hp": {
                "std_power": 225,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DL501 Gen2 & Bosch MED17.1",
                "Engine_code": "CNCD",
            },
            "3.0 TFSI 272hp": {
                "std_power": 272,
                "tuned_power": 435,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & Siemens/Continental Simos 16 & Temic DL501",
                "Engine_code": "CMUA",
            },
            "RS4 4.2 V8 FSI 450hp": {
                "std_power": 450,
                "tuned_power": 470,
                "std_torque": 430,
                "tuned_torque": 450,
                "cc": 4163,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CFS",
            },
            "S4 3.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 16 & Siemens/Continental Simos 8.5",
                "Engine_code": "CAKA",
            },
        },
        "B9 - 2015 - 2018": {
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch EDC17C64 & Bosch EDC17C46 & Temic DL382",
                "Engine_code": "CSUA",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DL382 & Bosch EDC17C64 & Temic DL501",
                "Engine_code": "CUUA",
            },
            "2.0 TDI 122hp": {
                "std_power": 122,
                "tuned_power": 190,
                "std_torque": 270,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ382",
                "Engine_code": "CSU",
            },
            "2.0 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch EDC17C64 & Temic DL382",
                "Engine_code": "CSUB",
            },
            "3.0 TDI FWD 218hp": {
                "std_power": 218,
                "tuned_power": 300,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP54 & Bosch MD1CP004 & Temic DL382",
                "Engine_code": "CSWB",
            },
            "3.0 TDI Quattro 218hp": {
                "std_power": 218,
                "tuned_power": 300,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP54 & Bosch MD1CP004 & Temic DL382-7F",
                "Engine_code": "CSWB",
            },
            "3.0 TDI Quattro 272hp": {
                "std_power": 272,
                "tuned_power": 320,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "CRED",
            },
            "1.4 TFSI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.1.61 & Temic DL382 & Bosch MED17.5.25",
                "Engine_code": "CVNA",
            },
            "2.0 TFSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1.10 & Bosch MG1CS001 & Temic DL382 & Bosch MG1CS111",
                "Engine_code": "CVKB",
            },
            "2.0 TFSI 252hp": {
                "std_power": 252,
                "tuned_power": 310,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Siemens/Continental Simos 18.4 & Temic DL382 & Bosch MG1CS001",
                "Engine_code": "CYMC / CYRB",
            },
            "2.0 TFSI 150hp": {
                "std_power": 150,
                "tuned_power": 245,
                "std_torque": 270,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "",
            },
        },
        "B9 - 2019 ->": {
            "30 TDI (2.0D) 122hp": {
                "std_power": 122,
                "tuned_power": 185,
                "std_torque": 270,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004 & Bosch EDC17C74",
                "Engine_code": "DEUC",
            },
            "30 TDI (2.0D) 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DEZB",
            },
            "35 TDI (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch MD1CS004 & Bosch MG1CS001",
                "Engine_code": "",
            },
            "35 TDI (2.0D) 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004 & Bosch EDC17C74",
                "Engine_code": "DEZE",
            },
            "40 TDI (2.0D) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74",
                "Engine_code": "DESA / DETA",
            },
            "40 TDI (2.0D) 204hp": {
                "std_power": 204,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DTPA",
            },
            "45 TDI (3.0D) 231hp": {
                "std_power": 231,
                "tuned_power": 350,
                "std_torque": 500,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004",
                "Engine_code": "DCPE",
            },
            "50 TDI (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004",
                "Engine_code": "DCPC",
            },
            "35 TFSI (2.0T) 150hp": {
                "std_power": 150,
                "tuned_power": 245,
                "std_torque": 270,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1.61 & Bosch MG1CS001",
                "Engine_code": "DMSB",
            },
            "40 TFSI (2.0T) 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1.10 & Bosch MG1CS001 & Temic DL382 & Bosch MG1CS111 & Siemens/Continental Simos 18.4",
                "Engine_code": "DEMA / DKYA / DLVA",
            },
            "40 TFSI (2.0T) 204hp": {
                "std_power": 204,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Temic DL382",
                "Engine_code": "DMSA",
            },
            "40 TFSI G-Tron (2.0T) 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Bosch MG1CS111 & Temic DL381",
                "Engine_code": "",
            },
            "45 TFSI (2.0T) 245hp": {
                "std_power": 245,
                "tuned_power": 295,
                "std_torque": 370,
                "tuned_torque": 445,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.4 & Siemens/Continental Simos 19.7",
                "Engine_code": "DKNA",
            },
            "45 TFSI (2.0T) 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 370,
                "tuned_torque": 490,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.7",
                "Engine_code": "DMTA",
            },
        },
    },
    "A5": {
        "MK1 - 2007 - 2011": {
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Bosch EDC17CP14",
                "Engine_code": "CAHB",
            },
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Bosch EDC17CP20",
                "Engine_code": "CAGA",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch MED17.1",
                "Engine_code": "CGLC",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Bosch EDC17CP20",
                "Engine_code": "CAGB",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Bosch EDC17CP14",
                "Engine_code": "CAHA",
            },
            "2.7 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2698,
                "Type_ecu": "Bosch EDC17CP04 & Bosch EDC17CP14",
                "Engine_code": "CAMA",
            },
            "2.7 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2698,
                "Type_ecu": "Bosch EDC17CP04 & Bosch EDC17CP14",
                "Engine_code": "CAMB",
            },
            "3.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 300,
                "std_torque": 500,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP04 & Bosch EDC17CP14 & Temic DL501 Gen1",
                "Engine_code": "CAPA",
            },
            "3.0 TDI 204hp": {
                "std_power": 204,
                "tuned_power": 270,
                "std_torque": 400,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP14",
                "Engine_code": "CLAB",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 290,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "CCWB",
            },
            "1.8 TFSI 170hp": {
                "std_power": 170,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5",
                "Engine_code": "CABD",
            },
            "1.8 TFSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5",
                "Engine_code": "CDHB",
            },
            "2.0 TFSI 180hp": {
                "std_power": 180,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5 & Bosch MED17.1 & Temic VL381",
                "Engine_code": "CDNB",
            },
            "2.0 TFSI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Temic DL501 & ZF AL651",
                "Engine_code": "CDNC",
            },
            "3.2 FSI 265hp": {
                "std_power": 265,
                "tuned_power": 285,
                "std_torque": 330,
                "tuned_torque": 350,
                "cc": 3197,
                "Type_ecu": "Siemens/Continental Simos 8.2 & Temic DL501",
                "Engine_code": "CALA",
            },
            "RS5 4.2 V8 450hp": {
                "std_power": 450,
                "tuned_power": 470,
                "std_torque": 430,
                "tuned_torque": 455,
                "cc": 4163,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CFSA",
            },
            "S5 3.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 435,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.4 & Siemens/Continental Simos 8.5",
                "Engine_code": "CAKA",
            },
        },
        "MK2 - 2012 - 2016": {
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C46",
                "Engine_code": "CJCB",
            },
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C46",
                "Engine_code": "CJCA",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17CP20 & Bosch EDC17C46",
                "Engine_code": "CGLD",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CGLC",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C46 & Temic VL381",
                "Engine_code": "CJCD",
            },
            "2.0 TDI CR 190hp": {
                "std_power": 190,
                "tuned_power": 215,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C46 & Bosch EDC17CP14 & Temic VL381",
                "Engine_code": "CNHA",
            },
            "3.0 TDI 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic DL501",
                "Engine_code": "CDUC",
            },
            "3.0 TDI 204hp": {
                "std_power": 204,
                "tuned_power": 280,
                "std_torque": 400,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "CLAB",
            },
            "1.8 TFSI 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 12 & Bosch MED17.5 & Temic VL381",
                "Engine_code": "CJEB",
            },
            "1.8 TFSI 177hp": {
                "std_power": 177,
                "tuned_power": 220,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 12",
                "Engine_code": "CJEE",
            },
            "1.8 TFSI 144hp": {
                "std_power": 144,
                "tuned_power": 220,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 12",
                "Engine_code": "CJED",
            },
            "2.0 TFSI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Bosch MED17.5 & Temic DL501 Gen1 & Temic DL501 Gen2",
                "Engine_code": "CDNC",
            },
            "2.0 TFSI 225hp": {
                "std_power": 225,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DL501",
                "Engine_code": "CNCD",
            },
            "3.0 TFSI 272hp": {
                "std_power": 272,
                "tuned_power": 435,
                "std_torque": 400,
                "tuned_torque": 535,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & Siemens/Continental Simos 16 & Temic DL501 Gen2",
                "Engine_code": "CMUA",
            },
            "RS5 4.2 V8 450hp": {
                "std_power": 450,
                "tuned_power": 470,
                "std_torque": 430,
                "tuned_torque": 455,
                "cc": 4163,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CFSA",
            },
            "S5 3.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 435,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.4 & Siemens/Continental Simos 8.5",
                "Engine_code": "CAKA",
            },
        },
        "MK3 - 2019 ->": {
            "35 TDI (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74",
                "Engine_code": "",
            },
            "35 TDI (2.0D) 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch MD1CS004",
                "Engine_code": "DEZE",
            },
            "40 TDI (2.0D) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch MD1CS004",
                "Engine_code": "DETA / DESA",
            },
            "40 TDI (2.0D) 204hp": {
                "std_power": 204,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DTPA",
            },
            "45 TDI (3.0D) 231hp": {
                "std_power": 231,
                "tuned_power": 350,
                "std_torque": 500,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004",
                "Engine_code": "DCPE",
            },
            "50 TDI (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004",
                "Engine_code": "DCPC",
            },
            "35 TFSI (2.0T) 150hp": {
                "std_power": 150,
                "tuned_power": 245,
                "std_torque": 270,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.6.1 & Bosch MG1CS001 & Siemens/Continental Simos 18.4",
                "Engine_code": "DLVB",
            },
            "40 TFSI (2.0T) 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Bosch MED17.1.10 & Temic DL382",
                "Engine_code": "DLVA",
            },
            "40 TFSI (2.0T) 204hp": {
                "std_power": 204,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001",
                "Engine_code": "DMSA",
            },
            "45 TFSI (2.0T) 245hp": {
                "std_power": 245,
                "tuned_power": 295,
                "std_torque": 370,
                "tuned_torque": 445,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.4 & Siemens/Continental Simos 19.7",
                "Engine_code": "DKNA",
            },
            "45 TFSI (2.0T) 265hp": {
                "std_power": 265,
                "tuned_power": 295,
                "std_torque": 370,
                "tuned_torque": 445,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.7",
                "Engine_code": "DMTA",
            },
        },
        "Mk3 - 2016 - 2018": {
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 475,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DL382-7F & Bosch EDC17C74",
                "Engine_code": "DETA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DL382",
                "Engine_code": "DETA",
            },
            "3.0 TDI 218hp": {
                "std_power": 218,
                "tuned_power": 300,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & Temic DL382 & Bosch EDC17CP44",
                "Engine_code": "CSWB",
            },
            "3.0 TDI 272hp": {
                "std_power": 272,
                "tuned_power": 350,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "CRTC",
            },
            "3.0 TDI 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 620,
                "tuned_torque": 750,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004",
                "Engine_code": "DCPB",
            },
            "1.4 TFSi 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.61",
                "Engine_code": "",
            },
            "2.0 TFSI G-Tron 170hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TFSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1.10 & Siemens/Continental Simos 18.4 & Bosch MG1CS001 & Temic DL382 & Bosch EDC17C74",
                "Engine_code": "CVKB",
            },
            "2.0 TFSI 252hp": {
                "std_power": 252,
                "tuned_power": 310,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Siemens/Continental Simos 18.4 & Temic DL382 & Bosch MED17.1.10",
                "Engine_code": "CYMC / CYRB",
            },
            "2.0 TFSI 150hp": {
                "std_power": 150,
                "tuned_power": 240,
                "std_torque": 270,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001",
                "Engine_code": "DLVB",
            },
        },
    },
    "A6": {
        "C5 - 1997 - 2004": {
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 285,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15VM+ & Bosch EDC15P+",
                "Engine_code": "AWX",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 310,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AFN",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 310,
                "tuned_torque": 360,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AJM",
            },
            "2.5 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 390,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "BCZ",
            },
            "2.5 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AFB / AKN",
            },
            "2.5 TDI 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AKE",
            },
            "1.8 T 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 210,
                "tuned_torque": 320,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5 & Bosch ME7.1",
                "Engine_code": "AEB",
            },
            "2.4i V6 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 2393,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "BDV",
            },
            "2.7T 230hp": {
                "std_power": 230,
                "tuned_power": 315,
                "std_torque": 310,
                "tuned_torque": 480,
                "cc": 2671,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "AJK",
            },
            "2.7T 250hp": {
                "std_power": 250,
                "tuned_power": 315,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 2671,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BES",
            },
            "2.8i 193hp": {
                "std_power": 193,
                "tuned_power": 210,
                "std_torque": 280,
                "tuned_torque": 300,
                "cc": 2771,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "ALG",
            },
            "3.0 V6 220hp": {
                "std_power": 220,
                "tuned_power": 240,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2976,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "ASN",
            },
        },
        "C6 - 2004 - 2008": {
            "2.0 TDI DPF 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BRE",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16U34",
                "Engine_code": "BLB",
            },
            "2.0 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BNA",
            },
            "2.7 TDI 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2698,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC16U3",
                "Engine_code": "BPP",
            },
            "2.7 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2698,
                "Type_ecu": "Bosch EDC16CP34",
                "Engine_code": "BSG",
            },
            "3.0 TDI 225hp": {
                "std_power": 225,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC16U3 & ZF 6HP",
                "Engine_code": "BMK",
            },
            "3.0 TDI 233hp": {
                "std_power": 233,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC16U3 & Bosch GS19",
                "Engine_code": "ASB",
            },
            "2.0 TFSI 170hp": {
                "std_power": 170,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1.5",
                "Engine_code": "BPJ",
            },
            "2.0 TFSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1.5",
                "Engine_code": "",
            },
            "2.4i V6 177hp": {
                "std_power": 177,
                "tuned_power": 190,
                "std_torque": 230,
                "tuned_torque": 245,
                "cc": 2393,
                "Type_ecu": "Siemens/Continental Simos 6.2",
                "Engine_code": "BDW",
            },
            "2.8 FSI 210hp": {
                "std_power": 210,
                "tuned_power": 225,
                "std_torque": 280,
                "tuned_torque": 300,
                "cc": 2773,
                "Type_ecu": "Siemens/Continental Simos 8.1",
                "Engine_code": "BDX",
            },
            "3.2 FSI 255hp": {
                "std_power": 255,
                "tuned_power": 275,
                "std_torque": 330,
                "tuned_torque": 350,
                "cc": 3123,
                "Type_ecu": "Siemens/Continental Simos 6.2",
                "Engine_code": "BKH",
            },
            "4.2 FSI 335hp": {
                "std_power": 335,
                "tuned_power": 355,
                "std_torque": 420,
                "tuned_torque": 445,
                "cc": 4163,
                "Type_ecu": "Bosch ME7.1.1 & Bosch MED9 & Bosch ME9.1.1",
                "Engine_code": "BAT",
            },
            "5.2 FSI 435hp": {
                "std_power": 435,
                "tuned_power": 455,
                "std_torque": 540,
                "tuned_torque": 565,
                "cc": 5204,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BXA",
            },
            "RS6 5.0 TFSI 580hp": {
                "std_power": 580,
                "tuned_power": 680,
                "std_torque": 650,
                "tuned_torque": 750,
                "cc": 4991,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BUH",
            },
        },
        "C6 - 2008 - 2010": {
            "2.0 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17CP20",
                "Engine_code": "CAHB",
            },
            "2.0 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC16U3",
                "Engine_code": "CAGB",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17CP20",
                "Engine_code": "BRE",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17CP20",
                "Engine_code": "CAHA",
            },
            "2.7 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2698,
                "Type_ecu": "Bosch EDC17CP14 & Bosch GS19",
                "Engine_code": "CANA",
            },
            "2.7 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2698,
                "Type_ecu": "Bosch EDC17CP14",
                "Engine_code": "CANB",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 295,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2773,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC16CP34",
                "Engine_code": "CDYB",
            },
            "3.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 285,
                "std_torque": 500,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17CP20 & Bosch EDC16CP34 & Bosch GS19 & ZF 6HP",
                "Engine_code": "CDYA",
            },
            "2.0 TFSI 170hp": {
                "std_power": 170,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1.5 & Temic VL300 & Bosch MED9.1",
                "Engine_code": "BPJ",
            },
            "2.8 FSI 190hp": {
                "std_power": 190,
                "tuned_power": 212,
                "std_torque": 280,
                "tuned_torque": 305,
                "cc": 2773,
                "Type_ecu": "Siemens/Continental Simos 8.1",
                "Engine_code": "CCDA",
            },
            "2.8 FSI 220hp": {
                "std_power": 220,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 305,
                "cc": 2773,
                "Type_ecu": "Siemens/Continental Simos 8.1 & Siemens/Continental Simos 8.6",
                "Engine_code": "CCEA",
            },
            "3.0 TFSI 290hp": {
                "std_power": 290,
                "tuned_power": 435,
                "std_torque": 420,
                "tuned_torque": 535,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & Siemens/Continental Simos 8.1 & Siemens/Continental Simos 8.31 & Siemens/Continental Simos 8.4 & ZF 6HP",
                "Engine_code": "CAJA",
            },
            "4.2 FSI 350hp": {
                "std_power": 350,
                "tuned_power": 365,
                "std_torque": 440,
                "tuned_torque": 465,
                "cc": 4163,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BVJ",
            },
            "5.2 FSI 435hp": {
                "std_power": 435,
                "tuned_power": 460,
                "std_torque": 540,
                "tuned_torque": 565,
                "cc": 5204,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BXA",
            },
            "RS6 5.0 TFSI 580hp": {
                "std_power": 580,
                "tuned_power": 680,
                "std_torque": 650,
                "tuned_torque": 750,
                "cc": 4991,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BUH",
            },
        },
        "C7 - 2011 - 2018": {
            "2.0 TDI 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CGLC / CMCG",
            },
            "2.0 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17C64 & Bosch EDC17CP14 & Temic DL382",
                "Engine_code": "CGLE",
            },
            "2.0 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17C64",
                "Engine_code": "CGLD",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DL382 & Bosch MED17.1 & Temic DL501 Gen1",
                "Engine_code": "CNHA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DL382",
                "Engine_code": "CSUB",
            },
            "3.0 TDI Bi-Turbo 313hp": {
                "std_power": 313,
                "tuned_power": 370,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & ZF AL551",
                "Engine_code": "CGQB",
            },
            "3.0 TDI Bi-Turbo 320hp": {
                "std_power": 320,
                "tuned_power": 370,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & ZF AL551 & ZF AL450",
                "Engine_code": "CVUA",
            },
            "3.0 TDI Bi-Turbo 326hp": {
                "std_power": 326,
                "tuned_power": 380,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & ZF AL551",
                "Engine_code": "CVUB",
            },
            "3.0 TDI FWD 204hp": {
                "std_power": 204,
                "tuned_power": 270,
                "std_torque": 400,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic VL381 & Temic DL501 Gen2",
                "Engine_code": "CLAA",
            },
            "3.0 TDI FWD 218hp": {
                "std_power": 218,
                "tuned_power": 280,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Bosch EDC17CP54 & Bosch MD1CP004 & Temic DQ381 & Temic DL382 & Bosch EDC17CP44",
                "Engine_code": "CRTE",
            },
            "3.0 TDI Quattro 204hp": {
                "std_power": 204,
                "tuned_power": 260,
                "std_torque": 400,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic DL501",
                "Engine_code": "CLAA",
            },
            "3.0 TDI Quattro 218hp": {
                "std_power": 218,
                "tuned_power": 310,
                "std_torque": 500,
                "tuned_torque": 700,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP54 & Bosch MD1CP004 & Temic DL501 & Temic DL382-7F & Bosch EDC17CP44",
                "Engine_code": "CZVA",
            },
            "3.0 TDI 245hp": {
                "std_power": 245,
                "tuned_power": 310,
                "std_torque": 500,
                "tuned_torque": 680,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic DL501 Gen2 & Temic DL501 Gen1 & Bosch MD1CP004",
                "Engine_code": "CDUC",
            },
            "3.0 TDI 272hp": {
                "std_power": 272,
                "tuned_power": 310,
                "std_torque": 580,
                "tuned_torque": 680,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP54 & Bosch MD1CP004 & Temic DL501 Gen2 & ZF AL552",
                "Engine_code": "CRTD",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 310,
                "std_torque": 500,
                "tuned_torque": 680,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP54 & Temic VL381 & Bosch EDC17CP44 & Bosch MD1CP004",
                "Engine_code": "CRTF / CZVC",
            },
            "3.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 300,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & Temic DL501 Gen2 & Temic DL382",
                "Engine_code": "",
            },
            "1.8 TFSI 190hp": {
                "std_power": 190,
                "tuned_power": 240,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Temic DL382",
                "Engine_code": "CYGA",
            },
            "2.0 TFSI Hybrid 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 480,
                "tuned_torque": 550,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CAEB / CDNC / CPMA",
            },
            "2.0 TFSI 180hp": {
                "std_power": 180,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Siemens/Continental Simos 18 & Temic VL381 & ZF AL551",
                "Engine_code": "CDNB",
            },
            "2.0 TFSI 252hp": {
                "std_power": 252,
                "tuned_power": 310,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Siemens/Continental Simos 18 & Temic DL382 & Temic DL501 Gen2 & Siemens/Continental Simos 18.1 & ZF AL551",
                "Engine_code": "CYNB",
            },
            "3.0 TFSI 300hp": {
                "std_power": 300,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & ZF DL501 & Siemens/Continental Simos 16 & ZF AL551 & Siemens/Continental Simos 8.4",
                "Engine_code": "CGCB",
            },
            "3.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "ZF AL551 & Siemens/Continental Simos 16.1 & Temic DL501 & Siemens/Continental Simos 8.4 & Siemens/Continental Simos 18.10 & Siemens/Continental Simos 8.5",
                "Engine_code": "CREC, CTDA",
            },
            "RS6 4.0 TFSI Performance 605hp": {
                "std_power": 605,
                "tuned_power": 680,
                "std_torque": 750,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CRDB",
            },
            "RS6 4.0 TFSI 560hp": {
                "std_power": 560,
                "tuned_power": 670,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & ZF AL551",
                "Engine_code": "CRDB",
            },
            "S6 4.0 TFSI 420hp": {
                "std_power": 420,
                "tuned_power": 540,
                "std_torque": 550,
                "tuned_torque": 800,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CEUC",
            },
            "S6 4.0 TFSI 450hp": {
                "std_power": 450,
                "tuned_power": 540,
                "std_torque": 550,
                "tuned_torque": 800,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CTGE",
            },
        },
        "C8 - 2018 ->": {
            "30 TDI (2.0D) 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DEZD",
            },
            "35 TDI (2.0D) 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DEZD",
            },
            "40 TDI (2.0D) 204hp": {
                "std_power": 204,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "",
            },
            "45 TDI (3.0D) 231hp": {
                "std_power": 231,
                "tuned_power": 350,
                "std_torque": 500,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & Temic AL552",
                "Engine_code": "DDVE",
            },
            "50 TDI (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & Temic AL552",
                "Engine_code": "DDVB",
            },
            "55 TDI (3.0D) 349hp": {
                "std_power": 349,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 840,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "DEWA",
            },
            "45 TFSI (2.0T) 265hp": {
                "std_power": 265,
                "tuned_power": 320,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.7",
                "Engine_code": "",
            },
            "40 TFSI (2.0T) 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Bosch MG1CS111 & Siemens/Continental Simos 18.4 & Bosch EDC17CP74 & Siemens/Continental Simos 19.7",
                "Engine_code": "DESA / DETA",
            },
            "45 TFSI (2.0T) 245hp": {
                "std_power": 245,
                "tuned_power": 320,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.4 & Siemens/Continental Simos 16",
                "Engine_code": "DLBA / DKNA / DLHA",
            },
            "55 TFSI (3.0T) 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "DLZ",
            },
            "55 TFSI-e 367hp": {
                "std_power": 367,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1984,
                "Type_ecu": "Bosch MG1 & Siemens/Continental Simos 19.8",
                "Engine_code": "DLZA",
            },
        },
    },
    "A7": {
        "2018 ->": {
            "40 TDI (2.0D) 204hp": {
                "std_power": 204,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CP004",
                "Engine_code": "DFBA",
            },
            "45 TDI (3.0D) 231hp": {
                "std_power": 231,
                "tuned_power": 350,
                "std_torque": 500,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF 8HP55",
                "Engine_code": "DDVE",
            },
            "50 TDI (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 600,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "DDVB",
            },
            "50 TFSI-e 299hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "45 TFSI (2.0T) 245hp": {
                "std_power": 245,
                "tuned_power": 295,
                "std_torque": 370,
                "tuned_torque": 445,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.4 & Siemens/Continental Simos 19.7",
                "Engine_code": "DKNA / DLHA",
            },
            "55 TFSI (3.0T) 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "DLZA",
            },
            "55 TFSI-e 367hp": {
                "std_power": 367,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1984,
                "Type_ecu": "",
                "Engine_code": "DLZA",
            },
        },
        "4G - 2010 - 2014": {
            "3.0 TDI Bi-Turbo 313hp": {
                "std_power": 313,
                "tuned_power": 370,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic DL501 & ZF AL450 & ZF AL551",
                "Engine_code": "CGQB",
            },
            "3.0 TDI FWD 204hp": {
                "std_power": 204,
                "tuned_power": 280,
                "std_torque": 400,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "CLAA",
            },
            "3.0 TDI Quattro 204hp": {
                "std_power": 204,
                "tuned_power": 280,
                "std_torque": 400,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic DL501",
                "Engine_code": "CLAA",
            },
            "3.0 TDI 245hp": {
                "std_power": 245,
                "tuned_power": 290,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP54 & Temic DL501 & ZF 8HP55",
                "Engine_code": "CDUC",
            },
            "3.0 TDI 245hp": {
                "std_power": 245,
                "tuned_power": 290,
                "std_torque": 580,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & ZF AL551",
                "Engine_code": "CDUC",
            },
            "3.0 TFSI 300hp": {
                "std_power": 300,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 510,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & Temic DL501 Gen2",
                "Engine_code": "CGWB",
            },
            "3.0 TFSI 310hp": {
                "std_power": 310,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 510,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & ZF AL551 & Siemens/Continental Simos 18",
                "Engine_code": "CGWD",
            },
            "3.0 TFSI 330hp": {
                "std_power": 330,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 510,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5",
                "Engine_code": "",
            },
            "RS7 4.0 TFSI 560hp": {
                "std_power": 560,
                "tuned_power": 670,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CWUB",
            },
            "S7 4.0 TFSI 420hp": {
                "std_power": 420,
                "tuned_power": 540,
                "std_torque": 550,
                "tuned_torque": 800,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CEUC",
            },
            "S7 4.0 TFSI 450hp": {
                "std_power": 450,
                "tuned_power": 540,
                "std_torque": 550,
                "tuned_torque": 800,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CTGE",
            },
        },
        "4G - 2014 - 2017": {
            "3.0 TDI Bi-Turbo 320hp": {
                "std_power": 320,
                "tuned_power": 370,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & ZF 8HP",
                "Engine_code": "CVUA",
            },
            "3.0 TDI Bi-Turbo 326hp": {
                "std_power": 326,
                "tuned_power": 380,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "ZF AL551 & Bosch EDC17CP44",
                "Engine_code": "CVUB",
            },
            "3.0 TDI FWD 190hp": {
                "std_power": 190,
                "tuned_power": 280,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP54 & Bosch MD1CP004",
                "Engine_code": "CZVF",
            },
            "3.0 TDI FWD 218hp": {
                "std_power": 218,
                "tuned_power": 280,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP54 & Bosch MD1CP004 & Temic DL382 & Temic DL501 Gen2",
                "Engine_code": "CTCC",
            },
            "3.0 TDI Quattro 218hp": {
                "std_power": 218,
                "tuned_power": 310,
                "std_torque": 500,
                "tuned_torque": 700,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP54 & Bosch MD1CP004",
                "Engine_code": "CRTE",
            },
            "3.0 TDI Quattro 190hp": {
                "std_power": 190,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 700,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP54 & Bosch MD1CP004 & Temic DL382",
                "Engine_code": "CZVF",
            },
            "3.0 TDI Quattro 272hp": {
                "std_power": 272,
                "tuned_power": 310,
                "std_torque": 580,
                "tuned_torque": 680,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP54 & Temic DL501 Gen1 & ZF AL552",
                "Engine_code": "CRTD",
            },
            "3.0 TDI Quattro 211hp": {
                "std_power": 211,
                "tuned_power": 310,
                "std_torque": 500,
                "tuned_torque": 700,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP54",
                "Engine_code": "CZVF",
            },
            "3.0 TDI Bi-Turbo 313hp": {
                "std_power": 313,
                "tuned_power": 370,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & ZF 8HP",
                "Engine_code": "",
            },
            "1.8 TFSI 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Temic DL382",
                "Engine_code": "CYGA",
            },
            "2.0 TFSI 252hp": {
                "std_power": 252,
                "tuned_power": 320,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1.1 & Siemens/Continental Simos 18.1 & Temic DL501",
                "Engine_code": "CYNB",
            },
            "3.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & Siemens/Continental Simos 16 & Temic DL501 & ZF AL551",
                "Engine_code": "CREC",
            },
        },
    },
    "A8": {
        "1994 - 1999": {
            "2.5 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AFB / AKN",
            },
            "2.5 TDI 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AKE",
            },
        },
        "D2 - 1999 - 2003": {
            "2.5 TDI 180hp": {
                "std_power": 180,
                "tuned_power": 208,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AKE",
            },
            "2.5 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AFB / AKN",
            },
            "3.3 TDI V8 225hp": {
                "std_power": 225,
                "tuned_power": 265,
                "std_torque": 480,
                "tuned_torque": 550,
                "cc": 3328,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AKF",
            },
            "3.7 V8 260hp": {
                "std_power": 260,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 390,
                "cc": 2697,
                "Type_ecu": "Bosch ME7.1.5",
                "Engine_code": "AKC",
            },
            "S8 4.2 V8 360hp": {
                "std_power": 360,
                "tuned_power": 380,
                "std_torque": 430,
                "tuned_torque": 455,
                "cc": 4172,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "AQH",
            },
        },
        "D3 - 2003 - 2010": {
            "3.0 TDI 233hp": {
                "std_power": 233,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34 & Bosch GS19",
                "Engine_code": "ASB",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34",
                "Engine_code": "BNG",
            },
            "4.0 TDI 275hp": {
                "std_power": 275,
                "tuned_power": 330,
                "std_torque": 650,
                "tuned_torque": 770,
                "cc": 3936,
                "Type_ecu": "Bosch EDC16C4",
                "Engine_code": "ASE",
            },
            "4.2 TDI 326hp": {
                "std_power": 326,
                "tuned_power": 380,
                "std_torque": 650,
                "tuned_torque": 880,
                "cc": 4134,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EGS19 & ZF AL551 & Bosch EDC17CP24 & ZF 6HP",
                "Engine_code": "BMC",
            },
            "3.2 FSI 260hp": {
                "std_power": 260,
                "tuned_power": 280,
                "std_torque": 330,
                "tuned_torque": 355,
                "cc": 3123,
                "Type_ecu": "Siemens/Continental Simos 6.2",
                "Engine_code": "BPK",
            },
            "4.2 FSI 350hp": {
                "std_power": 350,
                "tuned_power": 375,
                "std_torque": 440,
                "tuned_torque": 465,
                "cc": 4163,
                "Type_ecu": "Bosch MED9.1.1",
                "Engine_code": "BVJ",
            },
            "4.2i 335hp": {
                "std_power": 335,
                "tuned_power": 355,
                "std_torque": 430,
                "tuned_torque": 450,
                "cc": 4172,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BFM",
            },
            "6.0i W12": {
                "std_power": 450,
                "tuned_power": 475,
                "std_torque": 580,
                "tuned_torque": 610,
                "cc": 5998,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BHT",
            },
            "S8 5.2 FSI 450hp": {
                "std_power": 450,
                "tuned_power": 475,
                "std_torque": 540,
                "tuned_torque": 565,
                "cc": 5204,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BSM",
            },
        },
        "D4 - 2010 - 2016": {
            "3.0 TDI Bi-Turbo 313hp": {
                "std_power": 313,
                "tuned_power": 370,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "",
            },
            "3.0 TDI FWD 204hp": {
                "std_power": 204,
                "tuned_power": 280,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "CLAB",
            },
            "3.0 TDI Quattro 204hp": {
                "std_power": 204,
                "tuned_power": 280,
                "std_torque": 400,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "CLAB",
            },
            "3.0 TDI 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 550,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & ZF 8HPXY",
                "Engine_code": "CDTA",
            },
            "3.0 TDI 258hp": {
                "std_power": 258,
                "tuned_power": 300,
                "std_torque": 580,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & ZF AL551",
                "Engine_code": "CTBA",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 300,
                "std_torque": 550,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "CDTB / CTBB",
            },
            "4.2 TDI 350hp": {
                "std_power": 350,
                "tuned_power": 400,
                "std_torque": 800,
                "tuned_torque": 950,
                "cc": 4134,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP24 & ZF AL551",
                "Engine_code": "CDSB",
            },
            "4.2 TDI 385hp": {
                "std_power": 385,
                "tuned_power": 450,
                "std_torque": 850,
                "tuned_torque": 950,
                "cc": 4134,
                "Type_ecu": "Bosch EDC17CP44 & ZF AL551",
                "Engine_code": "CTEC",
            },
            "2.0 TFSI Hybrid 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 480,
                "tuned_torque": 550,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CHJA",
            },
            "3.0 TFSI 310hp": {
                "std_power": 310,
                "tuned_power": 435,
                "std_torque": 440,
                "tuned_torque": 535,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & Siemens/Continental Simos 16.1 & ZF AL551",
                "Engine_code": "CGWD",
            },
            "3.0 TFSI 290hp": {
                "std_power": 290,
                "tuned_power": 435,
                "std_torque": 420,
                "tuned_torque": 535,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & Siemens/Continental Simos 16",
                "Engine_code": "CGWA",
            },
            "4.0 TFSI 420hp": {
                "std_power": 420,
                "tuned_power": 540,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CEUA",
            },
            "4.0 TFSI 435hp": {
                "std_power": 435,
                "tuned_power": 540,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & ZF AL551",
                "Engine_code": "CTGA",
            },
            "4.0 TFSI 520hp": {
                "std_power": 520,
                "tuned_power": 680,
                "std_torque": 650,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CGTA",
            },
            "4.2 FSI 372hp": {
                "std_power": 372,
                "tuned_power": 392,
                "std_torque": 445,
                "tuned_torque": 470,
                "cc": 4163,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CDRA",
            },
            "6.3 W12 500hp": {
                "std_power": 500,
                "tuned_power": 560,
                "std_torque": 625,
                "tuned_torque": 675,
                "cc": 6299,
                "Type_ecu": "Bosch MED17.1.6",
                "Engine_code": "CEJA",
            },
        },
        "D5 - 2016 -> ...": {
            "50 TDI (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 600,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "DDVC",
            },
            "60 TDI 435hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "55 TFSI (3.0T) 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2995,
                "Type_ecu": "Bosch MED17 & Bosch MG1CS002 & Siemens/Continental Simos 16.1",
                "Engine_code": "CZSE",
            },
            "60 TFSI-e 449hp": {
                "std_power": 449,
                "tuned_power": 500,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CZSE",
            },
        },
    },
    "Q2": {
        "2019 ->": {
            "30 TDI (1.6D) 116hp": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2V & Bosch MD1CS004",
                "Engine_code": "DTR",
            },
            "35 TDI (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP74 & Bosch MD1CS004",
                "Engine_code": "DTTC",
            },
            "35 TFSI (1.4T) 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 0,
                "Type_ecu": "Bosch MED17.1.27",
                "Engine_code": "",
            },
            "30 TFSI (1.0T) 116hp": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27",
                "Engine_code": "DLAA",
            },
            "35 TFSI (1.5T) 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200",
                "Engine_code": "DPCA",
            },
            "40 TFSI (2.0T) 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Bosch MG1CS111",
                "Engine_code": "DNN",
            },
        },
        "GA - 2016 - 2019": {
            "1.6 TDI 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ200",
                "Engine_code": "DDYA",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74",
                "Engine_code": "DFHA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2 & Bosch EDC17C74 & Bosch EDC17C67",
                "Engine_code": "DCYA",
            },
            "1.0 TFSI 116hp": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.25",
                "Engine_code": "CHZJ",
            },
            "1.4 TFSI 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200",
                "Engine_code": "CZEA",
            },
            "2.0 TFSI 190hp": {
                "std_power": 190,
                "tuned_power": 235,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS111 & Temic DQ381 & Bosch MG1CS001",
                "Engine_code": "DKZA",
            },
        },
    },
    "Q3": {
        "F3 - 2019 ->": {
            "35 TDI (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch MD1CS004 & Temic DQ381",
                "Engine_code": "DFGA",
            },
            "40 TDI (2.0D) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74",
                "Engine_code": "DFHA",
            },
            "40 TDI (2.0D) 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DTUA",
            },
            "35 TFSI (1.5T) 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1498,
                "Type_ecu": "Bosch MED17.1.27 & Bosch MG1CS011",
                "Engine_code": "DADA",
            },
            "35 TFSI (1.5T) 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Bosch MED17.1.27 & Temic DQ380",
                "Engine_code": "DADA",
            },
            "40 TFSI (2.0T) 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Bosch MG1CS001 & Temic DQ500",
                "Engine_code": "",
            },
            "45 TFSI (2.0T) 230hp": {
                "std_power": 230,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Temic DQ500",
                "Engine_code": "DKTA",
            },
            "45 TFSI-e 245hp": {
                "std_power": 245,
                "tuned_power": 285,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "Bosch MG1CS211 & Bosch MED17.1.27 & Siemens/Continental Simos 19.3",
                "Engine_code": "DGEA",
            },
        },
        "U8 - 2011 - 2015": {
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Temic DQ500",
                "Engine_code": "CLLB",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFFB",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17C64",
                "Engine_code": "CFFA",
            },
            "1.4 TFSI 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CZDA",
            },
            "2.0 TFSI 170hp": {
                "std_power": 170,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Temic DQ500",
                "Engine_code": "CCZC",
            },
            "2.0 TFSI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Temic DQ500",
                "Engine_code": "CPSA",
            },
            "2.5 TFSI RS 310hp": {
                "std_power": 310,
                "tuned_power": 420,
                "std_torque": 420,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CTSA",
            },
        },
        "U8 - 2015 - 2018": {
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ500 & Bosch EDC17C74",
                "Engine_code": "CUVB",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ500 & Bosch EDC17C74",
                "Engine_code": "CUWA",
            },
            "2.0 TDI CR 120hp": {
                "std_power": 120,
                "tuned_power": 185,
                "std_torque": 290,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CUVC / DFTA",
            },
            "1.4 TFSI 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ381 & Temic DQ250",
                "Engine_code": "CZDA",
            },
            "1.4 TFSI 125hp": {
                "std_power": 125,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 310,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.21 & Bosch MED17.5.25",
                "Engine_code": "CZDA",
            },
            "2.0 TFSI 180hp": {
                "std_power": 180,
                "tuned_power": 300,
                "std_torque": 320,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Bosch MED17.5.2 & Siemens/Continental Simos 18.2 & Temic DQ500",
                "Engine_code": "CULB",
            },
            "2.0 TFSI 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Temic DQ500",
                "Engine_code": "CULC",
            },
            "RS 2.5 TFSI Performance 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 450,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CZG",
            },
            "RS 2.5 TFSI 340hp": {
                "std_power": 340,
                "tuned_power": 420,
                "std_torque": 450,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CZGA",
            },
        },
    },
    "Q5": {
        "8R - 2008 - 2011": {
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Bosch EDC17CP14 & Bosch EDC17C46 & Temic DL501 Gen2",
                "Engine_code": "CAHB",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Temic DL501 & Bosch EDC17CP04",
                "Engine_code": "CAHA",
            },
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14",
                "Engine_code": "CAGA",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP20",
                "Engine_code": "CCJB",
            },
            "3.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 285,
                "std_torque": 500,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP14 & Temic DL501 Gen1",
                "Engine_code": "CCWA",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP14",
                "Engine_code": "CCWB",
            },
            "2.0 TFSI Hybrid 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 480,
                "tuned_torque": 550,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CHJ",
            },
            "2.0 TFSI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL501 & ZF AL551",
                "Engine_code": "CADA",
            },
            "2.0 TFSI 180hp": {
                "std_power": 180,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1",
                "Engine_code": "CCNB",
            },
            "3.2 FSI 270hp": {
                "std_power": 270,
                "tuned_power": 285,
                "std_torque": 330,
                "tuned_torque": 350,
                "cc": 3197,
                "Type_ecu": "Siemens/Continental Simos 8.2",
                "Engine_code": "CALB",
            },
        },
        "8R - 2012 - 2016": {
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C46 & Temic DL501",
                "Engine_code": "CJC",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Temic DL501 Gen2",
                "Engine_code": "CGL",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Bosch EDC17C46",
                "Engine_code": "CJC",
            },
            "2.0 TDI CR 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 475,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C46",
                "Engine_code": "CNH",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46 & Bosch EDC17C64",
                "Engine_code": "CGL",
            },
            "3.0 TDI 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 580,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic DL501 Gen2 & Bosch EDC17CP14",
                "Engine_code": "CDU",
            },
            "3.0 TDI 258hp": {
                "std_power": 258,
                "tuned_power": 300,
                "std_torque": 580,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic DL501 Gen2 & ZF 8HP",
                "Engine_code": "CTB",
            },
            "1.4 TFSI 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 285,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CZE",
            },
            "2.0 TFSI Hybrid 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 480,
                "tuned_torque": 550,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CHJ",
            },
            "2.0 TFSI 225hp": {
                "std_power": 225,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Bosch MED17.1 & Siemens/Continental Simos 12 & Bosch MED17.5 & ZF AL551 & Temic DL501",
                "Engine_code": "CNC",
            },
            "2.0 TFSI 180hp": {
                "std_power": 180,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 12 & Siemens/Continental Simos 18.1",
                "Engine_code": "CDN",
            },
            "3.0 TFSI 272hp": {
                "std_power": 272,
                "tuned_power": 400,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 16 & Siemens/Continental Simos 8.5 & Temic AL552 & Temic AL551",
                "Engine_code": "CTV",
            },
        },
        "FY - MK1 - 2017 - 2018": {
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74",
                "Engine_code": "CJCD",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17C74 & Temic DL382",
                "Engine_code": "CGLA",
            },
            "2.0 TDI CR 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DL382",
                "Engine_code": "DETA",
            },
            "2.0 TFSI 252hp": {
                "std_power": 252,
                "tuned_power": 310,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.4 & Temic DL382-7F",
                "Engine_code": "CNCD",
            },
        },
        "FY - MK2 - 2019 ->": {
            "35 TDI (2.0D) 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch MG1CS014",
                "Engine_code": "DEZE / DTNA",
            },
            "40 TDI (2.0D) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DL382 & Bosch MD1CS004",
                "Engine_code": "DETA",
            },
            "40 TDI (2.0D) 204hp": {
                "std_power": 204,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DETA",
            },
            "50 TDI (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & Temic AL552",
                "Engine_code": "DMGA",
            },
            "45 TFSI (2.0T) 252hp": {
                "std_power": 252,
                "tuned_power": 295,
                "std_torque": 370,
                "tuned_torque": 445,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Siemens/Continental Simos 18.4 & Bosch MG1CS111 & Bosch MG1CS002 & Siemens/Continental Simos 19.7",
                "Engine_code": "",
            },
            "45 TFSI (2.0T) 265hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "50 TFSI-e 299hp": {
                "std_power": 299,
                "tuned_power": 330,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.8",
                "Engine_code": "DRYA",
            },
            "55 TFSI-e 367hp": {
                "std_power": 367,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.8",
                "Engine_code": "DRYA",
            },
        },
    },
    "Q7": {
        "4L MK1 - 2006 - 2010": {
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 295,
                "std_torque": 500,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC17CP04",
                "Engine_code": "BUN",
            },
            "3.0 TDI 233hp": {
                "std_power": 233,
                "tuned_power": 285,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC16U3 & Bosch EDC16U34 & Bosch EDC17CP04",
                "Engine_code": "BUG",
            },
            "3.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 295,
                "std_torque": 550,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17CP44 & Bosch EDC17CP04 & Bosch EDC17CP24",
                "Engine_code": "CASA",
            },
            "4.2 TDI 326hp": {
                "std_power": 326,
                "tuned_power": 380,
                "std_torque": 760,
                "tuned_torque": 880,
                "cc": 4134,
                "Type_ecu": "Bosch EDC16CP34",
                "Engine_code": "BTR",
            },
            "6.0 V12 TDI 500hp": {
                "std_power": 500,
                "tuned_power": 600,
                "std_torque": 1000,
                "tuned_torque": 1200,
                "cc": 5934,
                "Type_ecu": "Bosch EDC17CP24 & ZF 6HP",
                "Engine_code": "CCGA",
            },
            "3.6 FSI 280hp": {
                "std_power": 280,
                "tuned_power": 302,
                "std_torque": 360,
                "tuned_torque": 384,
                "cc": 3597,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BHK",
            },
            "4.2 FSI 350hp": {
                "std_power": 350,
                "tuned_power": 367,
                "std_torque": 440,
                "tuned_torque": 458,
                "cc": 4163,
                "Type_ecu": "Bosch MED9.1.1",
                "Engine_code": "BAR",
            },
        },
        "4L MK2 - 2010 - 2014": {
            "3.0 TDI 204hp": {
                "std_power": 204,
                "tuned_power": 295,
                "std_torque": 450,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "CJGC",
            },
            "3.0 TDI 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 550,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Aisin AL1000",
                "Engine_code": "CLZB",
            },
            "3.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 295,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP24 & Aisin AL1000 & Bosch EDC17CP04",
                "Engine_code": "CJGA",
            },
            "4.2 TDI 340hp": {
                "std_power": 340,
                "tuned_power": 390,
                "std_torque": 760,
                "tuned_torque": 880,
                "cc": 4134,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP24 & Aisin AL1000",
                "Engine_code": "CCFC",
            },
            "6.0 V12 TDI 500hp": {
                "std_power": 500,
                "tuned_power": 600,
                "std_torque": 1000,
                "tuned_torque": 1200,
                "cc": 5934,
                "Type_ecu": "Bosch EDC17CP24",
                "Engine_code": "CCGA",
            },
            "3.0 TFSI 272hp": {
                "std_power": 272,
                "tuned_power": 400,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & Siemens/Continental Simos 8.31",
                "Engine_code": "CJTC",
            },
            "3.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 380,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.5 & Siemens/Continental Simos 16 & Siemens/Continental Simos 8.1 & Siemens/Continental Simos 8.31 & Aisin AL1000 & Bosch MED17.1.6",
                "Engine_code": "CJTB",
            },
            "3.6 FSI 280hp": {
                "std_power": 280,
                "tuned_power": 302,
                "std_torque": 360,
                "tuned_torque": 384,
                "cc": 3597,
                "Type_ecu": "Bosch MED9",
                "Engine_code": "BHK",
            },
            "4.2 FSI 350hp": {
                "std_power": 350,
                "tuned_power": 367,
                "std_torque": 440,
                "tuned_torque": 458,
                "cc": 4163,
                "Type_ecu": "Bosch MED9",
                "Engine_code": "BAR",
            },
        },
        "4M MK1 - 2015 - 2018": {
            "3.0 TDI CR 218hp": {
                "std_power": 218,
                "tuned_power": 310,
                "std_torque": 500,
                "tuned_torque": 700,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP54 & Temic AL552",
                "Engine_code": "CZZA",
            },
            "3.0 TDI CR 272hp": {
                "std_power": 272,
                "tuned_power": 320,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP54 & ZF AL552",
                "Engine_code": "CRTC",
            },
            "3.0 TDI CR 211hp": {
                "std_power": 211,
                "tuned_power": 310,
                "std_torque": 500,
                "tuned_torque": 680,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP04 & Bosch EDC17CP54",
                "Engine_code": "CZZB",
            },
            "3.0 TDI CR 249hp": {
                "std_power": 249,
                "tuned_power": 310,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & Bosch EDC17CP04 & Bosch EDC17CP44",
                "Engine_code": "CVMB",
            },
            "3.0 TDI E-Tron 373hp": {
                "std_power": 373,
                "tuned_power": 435,
                "std_torque": 700,
                "tuned_torque": 810,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & Temic AL552",
                "Engine_code": "CVZA",
            },
            "45 TDI 231hp": {
                "std_power": 231,
                "tuned_power": 350,
                "std_torque": 500,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "",
                "Engine_code": "DHXC",
            },
            "50 TDI 286hp": {
                "std_power": 286,
                "tuned_power": 345,
                "std_torque": 620,
                "tuned_torque": 710,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "DHXA",
            },
            "2.0 TFSI 252hp": {
                "std_power": 252,
                "tuned_power": 300,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Siemens/Continental Simos 18.4 & Temic AL552 & Temic DL382",
                "Engine_code": "CNCD",
            },
            "3.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 16 & Temic AL552 & Siemens/Continental Simos 18.3",
                "Engine_code": "CREC",
            },
        },
        "4M MK2 - 2019 ->": {
            "45 TDI (3.0D) 231hp": {
                "std_power": 231,
                "tuned_power": 350,
                "std_torque": 500,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004",
                "Engine_code": "DPXB",
            },
            "50 TDI (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MG1CS001 & Bosch MD1CP004 & ZF AL552",
                "Engine_code": "DPXA",
            },
            "55 TFSI (3.0T) 340hp": {
                "std_power": 340,
                "tuned_power": 410,
                "std_torque": 400,
                "tuned_torque": 600,
                "cc": 2995,
                "Type_ecu": "Temic AL552 & Bosch MG1CS002",
                "Engine_code": "DCDB",
            },
            "55 TFSI-e 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2995,
                "Type_ecu": "Bosch MED9.1.1",
                "Engine_code": "DCBE",
            },
            "60 TFSI-e 456hp": {
                "std_power": 456,
                "tuned_power": 520,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & ZF AL552",
                "Engine_code": "DCBE",
            },
        },
    },
    "Q8": {
        "2018 ->": {
            "45 TDI (3.0D) 231hp": {
                "std_power": 231,
                "tuned_power": 350,
                "std_torque": 500,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004",
                "Engine_code": "DPXB",
            },
            "50 TDI (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 600,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & Temic AL552",
                "Engine_code": "DPXA",
            },
            "55 TFSI (3.0T) 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & ZF AL552",
                "Engine_code": "DCBD",
            },
            "55 TFSI (3.0T) 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "DCBD",
            },
            "55 TFSI-e Quattro 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "DCBE",
            },
            "60 TFSI-e Quattro 462hp": {
                "std_power": 462,
                "tuned_power": 520,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & ZF 8HP",
                "Engine_code": "DCBE",
            },
        },
    },
    "R8": {
        "2007 - 2015": {
            "4.2 FSI 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 430,
                "tuned_torque": 455,
                "cc": 4163,
                "Type_ecu": "Bosch MED9",
                "Engine_code": "BYH",
            },
            "4.2 FSI 430hp": {
                "std_power": 430,
                "tuned_power": 440,
                "std_torque": 430,
                "tuned_torque": 455,
                "cc": 4163,
                "Type_ecu": "Bosch MED9",
                "Engine_code": "BYH",
            },
            "5.2 FSI Plus 550hp": {
                "std_power": 550,
                "tuned_power": 570,
                "std_torque": 530,
                "tuned_torque": 580,
                "cc": 5204,
                "Type_ecu": "Bosch MED9",
                "Engine_code": "CTP",
            },
            "5.2 FSI 525hp": {
                "std_power": 525,
                "tuned_power": 570,
                "std_torque": 530,
                "tuned_torque": 580,
                "cc": 5204,
                "Type_ecu": "Bosch MED9.1.2 & Temic DQ500 & Temic DL800",
                "Engine_code": "BUJ",
            },
            "GT 560hp": {
                "std_power": 560,
                "tuned_power": 570,
                "std_torque": 540,
                "tuned_torque": 580,
                "cc": 5204,
                "Type_ecu": "Bosch MED9",
                "Engine_code": "CMPA",
            },
        },
        "2015 ->": {
            "5.2 FSI Plus 610hp": {
                "std_power": 610,
                "tuned_power": 630,
                "std_torque": 560,
                "tuned_torque": 580,
                "cc": 5204,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL800",
                "Engine_code": "CSP",
            },
            "5.2 FSI 540hp": {
                "std_power": 540,
                "tuned_power": 630,
                "std_torque": 530,
                "tuned_torque": 580,
                "cc": 5204,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL800",
                "Engine_code": "CSPB",
            },
            "5.2 FSI 570hp": {
                "std_power": 570,
                "tuned_power": 630,
                "std_torque": 560,
                "tuned_torque": 580,
                "cc": 5204,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CSPB",
            },
        },
    },
    "RS3": {
        "2011 - 2014": {
            "2.5 TFSI 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 600,
                "cc": 2480,
                "Type_ecu": "Bosch MED9.1.2 & Temic DQ500 & Temic DQ381",
                "Engine_code": "CEPA",
            },
        },
        "2015 - 2017": {
            "2.5 TFSI 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 465,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.1 & Temic DQ500",
                "Engine_code": "CZGB",
            },
        },
        "2017 - 2020": {
            "2.5 TFSI 400hp": {
                "std_power": 400,
                "tuned_power": 470,
                "std_torque": 480,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.62 & Temic DQ500",
                "Engine_code": "DAZA",
            },
        },
        "2021 -> ...": {
            "2.5 TFSI 400hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "RS4": {
        "B5 -> 2001": {
            "2.7 V6 Biturbo 380hp": {
                "std_power": 380,
                "tuned_power": 430,
                "std_torque": 440,
                "tuned_torque": 570,
                "cc": 2671,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "ASJ / AZR",
            },
        },
        "B7 - 2004 - 2008": {
            "4.2 FSI V8 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 430,
                "tuned_torque": 455,
                "cc": 4163,
                "Type_ecu": "Bosch MED9",
                "Engine_code": "BNS",
            },
        },
        "B8 - 2012 - 2017": {
            "4.2 FSI 450hp": {
                "std_power": 450,
                "tuned_power": 475,
                "std_torque": 430,
                "tuned_torque": 460,
                "cc": 4163,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL501 Gen1",
                "Engine_code": "CFSA",
            },
        },
        "B9 - MK1 - 2017 - 2019": {
            "2.9 V6 Bi-Turbo 450hp": {
                "std_power": 450,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 780,
                "cc": 2984,
                "Type_ecu": "Bosch MG1CS002 & Temic AL552",
                "Engine_code": "DECA",
            },
        },
        "B9 - MK2 - 2020 ->": {
            "2.9 V6 Bi-Turbo 450hp": {
                "std_power": 450,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 780,
                "cc": 2984,
                "Type_ecu": "Bosch MG1CS002 & ZF AL552",
                "Engine_code": "DECA",
            },
        },
    },
    "RS5": {
        "8T - 2010 - 2016": {
            "4.2 FSI 450hp": {
                "std_power": 450,
                "tuned_power": 470,
                "std_torque": 430,
                "tuned_torque": 460,
                "cc": 4163,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL501",
                "Engine_code": "CFSA",
            },
        },
        "F5 - MK1 - 2016 - 2019": {
            "2.9 V6 TFSI 450hp": {
                "std_power": 450,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 780,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002 & ZF AL552",
                "Engine_code": "DECA",
            },
        },
        "F5 - MK2 - 2019 ->": {
            "2.9 V6 TFSI 450hp": {
                "std_power": 450,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 780,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002 & ZF 8HP & Bosch MG1CS008",
                "Engine_code": "DECA",
            },
        },
    },
    "RS6": {
        "C5 - 2002 - 2004": {
            "4.2 Biturbo 450hp": {
                "std_power": 450,
                "tuned_power": 520,
                "std_torque": 560,
                "tuned_torque": 725,
                "cc": 4172,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "BCY",
            },
            "4.2 Biturbo 480hp": {
                "std_power": 480,
                "tuned_power": 520,
                "std_torque": 560,
                "tuned_torque": 725,
                "cc": 4172,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "BCY",
            },
        },
        "C6 - 2004 - 2011": {
            "5.0 TFSI 580hp": {
                "std_power": 580,
                "tuned_power": 680,
                "std_torque": 650,
                "tuned_torque": 750,
                "cc": 4991,
                "Type_ecu": "Bosch MED9.1.2 & Bosch EGS19",
                "Engine_code": "BUH",
            },
        },
        "C7 - 2012 - 2019": {
            "4.0 TFSI - 560hp": {
                "std_power": 560,
                "tuned_power": 650,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & ZF AL551",
                "Engine_code": "CRDB",
            },
            "4.0 TFSI Performance 605hp": {
                "std_power": 605,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & ZF 8HPXY",
                "Engine_code": "CRDB",
            },
        },
        "C8 - 2020 ->": {
            "4.0 TFSI Performance 621hp": {
                "std_power": 621,
                "tuned_power": 700,
                "std_torque": 850,
                "tuned_torque": 970,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "DJP",
            },
            "4.0 TFSI 600hp": {
                "std_power": 600,
                "tuned_power": 700,
                "std_torque": 800,
                "tuned_torque": 970,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008 & ZF AL552",
                "Engine_code": "DJP",
            },
        },
    },
    "RS7": {
        "C7 - 2013 - 2019": {
            "4.0 TFSI 560hp": {
                "std_power": 560,
                "tuned_power": 680,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & ZF AL551",
                "Engine_code": "CWUB",
            },
            "4.0 TFSI 605hp Performance": {
                "std_power": 605,
                "tuned_power": 680,
                "std_torque": 750,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CWUC",
            },
        },
        "C8 - 2020 ->": {
            "4.0 TFSI Performance 621hp": {
                "std_power": 621,
                "tuned_power": 700,
                "std_torque": 850,
                "tuned_torque": 970,
                "cc": 3996,
                "Type_ecu": "",
                "Engine_code": "DJP",
            },
            "4.0 TFSI 600hp": {
                "std_power": 600,
                "tuned_power": 700,
                "std_torque": 800,
                "tuned_torque": 970,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008 & Temic AL552 & ZF AL952",
                "Engine_code": "DJP",
            },
        },
    },
    "RSQ3": {
        "-> 2015": {
            "2.5 TFSI 310hp": {
                "std_power": 310,
                "tuned_power": 420,
                "std_torque": 420,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CTSA",
            },
        },
        "2015 - 2019": {
            "2.5 TFSI Performance 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 465,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CZGA",
            },
            "2.5 TFSI 340hp": {
                "std_power": 340,
                "tuned_power": 420,
                "std_torque": 450,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.1 & Temic DQ500",
                "Engine_code": "CZGA",
            },
        },
        "2020 ->": {
            "2.5 TFSI 400hp": {
                "std_power": 400,
                "tuned_power": 470,
                "std_torque": 480,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.62 & Temic DQ500",
                "Engine_code": "DNWA",
            },
        },
    },
    "RSQ8": {
        "2020 ->": {
            "4.0 V8 Bi-Turbo 600hp": {
                "std_power": 600,
                "tuned_power": 700,
                "std_torque": 800,
                "tuned_torque": 970,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS011 & Bosch MG1CS008 & ZF AL552",
                "Engine_code": "DHU",
            },
        },
    },
    "S1": {
        "2014 ->": {
            "2.0 TFSI 231hp": {
                "std_power": 231,
                "tuned_power": 300,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18",
                "Engine_code": "CWZ",
            },
        },
    },
    "S3": {
        "All": {
            "1.8T 20V 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 270,
                "tuned_torque": 380,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "AMK",
            },
            "1.8T 20V 225hp": {
                "std_power": 225,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "BAM",
            },
            "2.0 TFSI 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Temic DQ250 & Siemens/Continental Simos 18",
                "Engine_code": "BZC",
            },
            "2.0 TFSI 300hp": {
                "std_power": 300,
                "tuned_power": 365,
                "std_torque": 380,
                "tuned_torque": 480,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 12 & Siemens/Continental Simos 18.1 & Temic DQ250 & Temic DQ381 & Temic DQ500",
                "Engine_code": "CJXC",
            },
            "2.0 TFSI 310hp": {
                "std_power": 310,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 12 & Siemens/Continental Simos 18 & Temic DQ381 & Siemens/Continental Simos 19.6",
                "Engine_code": "CJXB",
            },
        },
    },
    "S4": {
        "B5 - > 2000": {
            "2.7T 265hp": {
                "std_power": 265,
                "tuned_power": 315,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2671,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "AGB",
            },
        },
        "B6 - 2001 - 2004": {
            "4.2 V8 344hp": {
                "std_power": 344,
                "tuned_power": 364,
                "std_torque": 410,
                "tuned_torque": 440,
                "cc": 4163,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BBK",
            },
        },
        "B7 - 2004 - 2008": {
            "4.2 V8 344hp": {
                "std_power": 344,
                "tuned_power": 364,
                "std_torque": 410,
                "tuned_torque": 440,
                "cc": 4163,
                "Type_ecu": "Bosch ME7.1.1 & Bosch MED9.1",
                "Engine_code": "BBK",
            },
        },
        "B8 - 2008 - 2016": {
            "3.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.4 & Siemens/Continental Simos 16 & Siemens/Continental Simos 8.5 & Temic DL501",
                "Engine_code": "CAKA",
            },
        },
        "B9 - MK1 - 2016 - 2019": {
            "3.0 TFSI 354hp": {
                "std_power": 354,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & Temic AL552",
                "Engine_code": "CWGD",
            },
        },
        "B9 - MK2 - 2019 ->": {
            "3.0 TDI V6 347hp": {
                "std_power": 347,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 840,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "DEWB",
            },
        },
    },
    "S5": {
        "8T - 2007 - 2016": {
            "3.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 510,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.4 & Siemens/Continental Simos 8.5 & Siemens/Continental Simos 16 & Temic DL501 Gen2 & Temic DL501 Gen1",
                "Engine_code": "CREC",
            },
            "4.2 V8 354hp": {
                "std_power": 354,
                "tuned_power": 375,
                "std_torque": 440,
                "tuned_torque": 465,
                "cc": 4163,
                "Type_ecu": "Bosch MED9.1 & ZF AL651",
                "Engine_code": "CAUA",
            },
        },
        "F5 - MK1 - 2016 - 2018": {
            "S5 3.0 TFSI 354hp": {
                "std_power": 354,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & Temic DL501 Gen2 & ZF AL551 & ZF AL552",
                "Engine_code": "CWGD",
            },
        },
        "F5 - MK2 - 2019 ->": {
            "3.0 V6 TDI 347hp": {
                "std_power": 347,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 840,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "DEWB",
            },
            "S5 3.0 TFSI 354hp": {
                "std_power": 354,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & Temic AL552",
                "Engine_code": "CWGD",
            },
        },
    },
    "S6": {
        "C5 - 1999 - 2004": {
            "4.2 V8 340hp": {
                "std_power": 340,
                "tuned_power": 360,
                "std_torque": 420,
                "tuned_torque": 450,
                "cc": 4172,
                "Type_ecu": "Bosch ME7.1 & Bosch ME7.1.1",
                "Engine_code": "AQJ",
            },
        },
        "C6 - 2004 - 2012": {
            "5.2 FSI V10 435hp": {
                "std_power": 435,
                "tuned_power": 455,
                "std_torque": 540,
                "tuned_torque": 565,
                "cc": 5204,
                "Type_ecu": "Bosch MED9.1 & Bosch GS19",
                "Engine_code": "BXA",
            },
        },
        "C7 - 2012 - 2019": {
            "4.0 TFSI 420hp": {
                "std_power": 420,
                "tuned_power": 540,
                "std_torque": 550,
                "tuned_torque": 800,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL501",
                "Engine_code": "CEUC",
            },
            "4.0 TFSI 450hp": {
                "std_power": 450,
                "tuned_power": 540,
                "std_torque": 550,
                "tuned_torque": 800,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL501",
                "Engine_code": "CTGE",
            },
        },
        "C8 - 2019 ->": {
            "3.0 TDI 347hp": {
                "std_power": 347,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 840,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "DEWA",
            },
            "2.9 TFSI 450hp": {
                "std_power": 450,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 780,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "",
            },
        },
    },
    "S7": {
        "2012 - 2019": {
            "4.0 TFSI 420hp": {
                "std_power": 420,
                "tuned_power": 540,
                "std_torque": 550,
                "tuned_torque": 800,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL501 Gen2",
                "Engine_code": "CEUC",
            },
            "4.0 TFSI 450hp": {
                "std_power": 450,
                "tuned_power": 540,
                "std_torque": 550,
                "tuned_torque": 800,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL501",
                "Engine_code": "CEUC",
            },
        },
        "2019 ->": {
            "3.0 TDI 347hp": {
                "std_power": 347,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 840,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & Temic AL552",
                "Engine_code": "DEWA",
            },
            "2.9 TFSI 450hp": {
                "std_power": 450,
                "tuned_power": 540,
                "std_torque": 600,
                "tuned_torque": 780,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002 & ZF 8HP",
                "Engine_code": "",
            },
        },
    },
    "S8": {
        "2006 - 2009": {
            "5.2 FSI V10 450hp": {
                "std_power": 450,
                "tuned_power": 475,
                "std_torque": 540,
                "tuned_torque": 565,
                "cc": 5204,
                "Type_ecu": "Bosch MED9.1 & Bosch GS19",
                "Engine_code": "BSM",
            },
        },
        "2012 - 2018": {
            "4.0 TFSI Plus 605hp": {
                "std_power": 605,
                "tuned_power": 680,
                "std_torque": 700,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & ZF AL951 & ZF AL551",
                "Engine_code": "DDTA",
            },
            "4.0 TFSI 520hp": {
                "std_power": 520,
                "tuned_power": 680,
                "std_torque": 650,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & ZF AL551",
                "Engine_code": "CGTA",
            },
        },
        "2019 ->": {
            "4.0 TFSI 571hp": {
                "std_power": 571,
                "tuned_power": 600,
                "std_torque": 800,
                "tuned_torque": 900,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008 & ZF AL551",
                "Engine_code": "CWWB",
            },
        },
    },
    "SQ2": {
        "2019 ->": {
            "2.0 TFSi 300hp": {
                "std_power": 300,
                "tuned_power": 345,
                "std_torque": 400,
                "tuned_torque": 485,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Temic DQ381",
                "Engine_code": "DNUE",
            },
        },
    },
    "SQ5": {
        "8R - MK1 - 2012 - 2017": {
            "3.0 TDI Bi-Turbo Plus 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "CGQB",
            },
            "3.0 TDI Bi-Turbo 313hp": {
                "std_power": 313,
                "tuned_power": 380,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & ZF AL551",
                "Engine_code": "CGQB",
            },
            "3.0 TDI Bi-Turbo 326hp": {
                "std_power": 326,
                "tuned_power": 380,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & ZF AL551",
                "Engine_code": "CGQB",
            },
            "3.0 TFSI 354hp": {
                "std_power": 354,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & Temic DL501 & Siemens/Continental Simos 8.5 & ZF 8HP",
                "Engine_code": "CTUD",
            },
        },
        "FY - MK1 - 2017 - 2019": {
            "3.0 TFSI 354hp": {
                "std_power": 354,
                "tuned_power": 405,
                "std_torque": 500,
                "tuned_torque": 610,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & Siemens/Continental Simos 8.5 & ZF AL552",
                "Engine_code": "CWGD",
            },
        },
        "FY - MK2 - 2019 ->": {
            "3.0 TDI V6 341hp": {
                "std_power": 341,
                "tuned_power": 390,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & Temic DQ520",
                "Engine_code": "DMKC",
            },
            "3.0 TDI 347hp": {
                "std_power": 347,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 840,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "DEWB",
            },
        },
    },
    "SQ7": {
        "4M - 2016 ->": {
            "4.0 TDI Bi-Turbo 435hp": {
                "std_power": 435,
                "tuned_power": 500,
                "std_torque": 900,
                "tuned_torque": 1000,
                "cc": 3956,
                "Type_ecu": "Bosch MD1CP014 & ZF AL552",
                "Engine_code": "CZAC",
            },
            "4.0 TFSI 507hp": {
                "std_power": 507,
                "tuned_power": 600,
                "std_torque": 770,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "DCU",
            },
        },
    },
    "SQ8": {
        "2019 ->": {
            "4.0 TDI Bi-Turbo 435hp": {
                "std_power": 435,
                "tuned_power": 500,
                "std_torque": 900,
                "tuned_torque": 1000,
                "cc": 3956,
                "Type_ecu": "Bosch MD1CP014 & ZF AL552",
                "Engine_code": "DMVA",
            },
            "4.0 TFSI 507hp": {
                "std_power": 507,
                "tuned_power": 600,
                "std_torque": 770,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MG1CS008 & ZF AL552",
                "Engine_code": "DCU",
            },
        },
    },
    "TT": {
        "1998 - 2006 ( 8N )": {
            "1.8T 20V 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 235,
                "tuned_torque": 330,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5 & Bosch EDC15P+",
                "Engine_code": "AJQ",
            },
            "1.8T 20V 225hp": {
                "std_power": 225,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "APX",
            },
            "1.8T 20V 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "AUM",
            },
            "1.8T 20V 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 225,
                "tuned_torque": 335,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "BVP",
            },
            "3.2 V6 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 340,
                "cc": 2189,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "BPF",
            },
        },
        "2006 - 2014 ( 8J )": {
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17CP46",
                "Engine_code": "CBBB",
            },
            "1.8 TFSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5",
                "Engine_code": "CDAA",
            },
            "2.0 TFSI S 272hp": {
                "std_power": 272,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED9 & Temic DQ250 & Temic DQ500",
                "Engine_code": "CDL",
            },
            "2.0 TFSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Temic DQ250",
                "Engine_code": "AXX",
            },
            "2.0 TFSI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 350,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Bosch MED17.1 & Temic DQ250",
                "Engine_code": "CESA",
            },
            "2.5 TFSI RS Plus 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 465,
                "tuned_torque": 600,
                "cc": 2480,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "CEPB",
            },
            "2.5 TFSI RS 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 600,
                "cc": 2480,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "CEPA",
            },
            "3.2 V6 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 340,
                "cc": 3189,
                "Type_ecu": "Bosch ME7.1.1 & Temic DQ250",
                "Engine_code": "BUB",
            },
        },
        "2014 - 2018 ( 8S )": {
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74",
                "Engine_code": "CUNA",
            },
            "1.8 TFSI 180hp": {
                "std_power": 180,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Temic DQ200 Gen2",
                "Engine_code": "CJSA",
            },
            "2.0 TFSI 230hp": {
                "std_power": 230,
                "tuned_power": 300,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Temic DQ250-6A/6F/6V-MQB",
                "Engine_code": "CHHC",
            },
        },
        "2018 -> ( 8S II )": {
            "40 TFSI 197hp": {
                "std_power": 197,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS111",
                "Engine_code": "DKZB / DNNB",
            },
            "45 TFSI 245hp": {
                "std_power": 245,
                "tuned_power": 320,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Temic DQ381 & Siemens/Continental Simos 19.3",
                "Engine_code": "DKT",
            },
        },
    },
    "TT RS": {
        "All": {
            "2.5 TFSI RS Plus 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 465,
                "tuned_torque": 600,
                "cc": 2480,
                "Type_ecu": "Bosch MED9.1.2 & Temic DQ500",
                "Engine_code": "CEPB",
            },
            "2.5 TFSI RS 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 600,
                "cc": 2480,
                "Type_ecu": "Bosch ME9 & Temic DQ500 & Bosch MED9.1.2",
                "Engine_code": "CEPA",
            },
            "2.5 TFSI RS 400hp": {
                "std_power": 400,
                "tuned_power": 470,
                "std_torque": 480,
                "tuned_torque": 625,
                "cc": 2480,
                "Type_ecu": "Bosch MED17.1.6 & Temic DQ500",
                "Engine_code": "DAZA",
            },
        },
    },
    "TT S": {
        "All": {
            "2.0 TFSI 272hp": {
                "std_power": 272,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED9 & Siemens/Continental Simos 18.1 & Temic DQ250 & Temic DQ500",
                "Engine_code": "CDLB",
            },
            "2.0 TFSI 310hp": {
                "std_power": 310,
                "tuned_power": 365,
                "std_torque": 380,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Temic DQ250 & Siemens/Continental Simos 18.6",
                "Engine_code": "CJXG",
            },
            "2.0 TFSI 306hp": {
                "std_power": 306,
                "tuned_power": 350,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250-6A/6F/6V-MQB & Temic DQ381",
                "Engine_code": "DNUF",
            },
        },
    },
},
"BMW": {
    "1 serie": {
        "E87 - 2004 - 2007": {
            "118D 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C35 & Bosch EDC16C31",
                "Engine_code": "M47TU2D20",
            },
            "120D 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C35 & Bosch EDC16C31",
                "Engine_code": "M47TU2D20",
            },
            "116i 115hp": {
                "std_power": 115,
                "tuned_power": 128,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1596,
                "Type_ecu": "Bosch ME9.2 & Bosch ME17.2.1",
                "Engine_code": "N45B16",
            },
            "118i 129hp": {
                "std_power": 129,
                "tuned_power": 145,
                "std_torque": 180,
                "tuned_torque": 200,
                "cc": 1995,
                "Type_ecu": "Bosch MEV946 & Bosch MEV1746 & Bosch MEV9.2 & Bosch ME9.2",
                "Engine_code": "N46B20",
            },
            "120i 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1995,
                "Type_ecu": "Bosch MEV946 & Siemens/Continental MSD81",
                "Engine_code": "N46B20",
            },
            "130i 265hp": {
                "std_power": 265,
                "tuned_power": 285,
                "std_torque": 315,
                "tuned_torque": 340,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSV70 & Siemens/Continental MSV80",
                "Engine_code": "N52B30",
            },
            "135i 306hp": {
                "std_power": 306,
                "tuned_power": 370,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSV70 & Bosch MEVD17 & Siemens/Continental MSD80",
                "Engine_code": "N54B30",
            },
        },
        "E87 - 2007 - 2011": {
            "116D 115hp": {
                "std_power": 115,
                "tuned_power": 180,
                "std_torque": 260,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17CP02 & Bosch EDC17C06",
                "Engine_code": "M47TU2D20",
            },
            "118D 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C06 & Bosch EDC17C41 & Bosch EDC17CP02",
                "Engine_code": "N47D20",
            },
            "118D 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C06 & Bosch EDC17C01",
                "Engine_code": "M47TU2D20",
            },
            "120D 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP02 & Bosch EDC17C06 & Bosch EDC16C35",
                "Engine_code": "M47TU2D20",
            },
            "120D 163hp": {
                "std_power": 163,
                "tuned_power": 215,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP02",
                "Engine_code": "M47TU2D20",
            },
            "123D 204hp": {
                "std_power": 204,
                "tuned_power": 245,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP02",
                "Engine_code": "M47TU2D20",
            },
            "116i 122hp": {
                "std_power": 122,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 185,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental MSD81 & Bosch MEV17.4.6 & Siemens/Continental MSD80",
                "Engine_code": "N45B16",
            },
            "118i 143hp": {
                "std_power": 143,
                "tuned_power": 155,
                "std_torque": 190,
                "tuned_torque": 208,
                "cc": 1995,
                "Type_ecu": "Siemens/Continental MSD81 & Siemens/Continental MSD80 & Bosch MEV17.4.6",
                "Engine_code": "N46B20",
            },
            "120i 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 1995,
                "Type_ecu": "Siemens/Continental MSD81 & Bosch MED17 & Bosch MEV17.4.6 & Siemens/Continental MSD80.2",
                "Engine_code": "N46B20",
            },
            "125i 218hp": {
                "std_power": 218,
                "tuned_power": 250,
                "std_torque": 270,
                "tuned_torque": 310,
                "cc": 2996,
                "Type_ecu": "Bosch MEV1746 & Siemens/Continental MSD80 & Siemens/Continental MSV80 & Siemens/Continental MSV70",
                "Engine_code": "N52B30",
            },
            "130i 265hp": {
                "std_power": 265,
                "tuned_power": 285,
                "std_torque": 315,
                "tuned_torque": 340,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSD80 & Siemens/Continental MSV70",
                "Engine_code": "N52B30",
            },
            "135i - N54 306hp": {
                "std_power": 306,
                "tuned_power": 362,
                "std_torque": 400,
                "tuned_torque": 510,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2 & Siemens/Continental MSD80 & Siemens/Continental MSD81",
                "Engine_code": "N54B30",
            },
            "135i - N55 - twinscroll 306hp": {
                "std_power": 306,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD80 & Bosch MEVD17.2",
                "Engine_code": "N54B30",
            },
            "1M - 3.0i Bi-turbo 340hp": {
                "std_power": 340,
                "tuned_power": 375,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD80",
                "Engine_code": "N54B30A",
            },
        },
        "F20 - 2011 - 2015": {
            "114D 95hp (1598cc)": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C41",
                "Engine_code": "N47D16",
            },
            "116D 116hp (1598cc)": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C41 & Bosch MEVD17.2.5",
                "Engine_code": "N47D16",
            },
            "116D 116hp (1995cc)": {
                "std_power": 116,
                "tuned_power": 190,
                "std_torque": 260,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C56 & ZF 8HP45 & Bosch EDC17C41",
                "Engine_code": "N47D20",
            },
            "118D 143hp": {
                "std_power": 143,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & ZF 8HP50 & Bosch EDC17C41 & Bosch EDC17C56",
                "Engine_code": "N47D20",
            },
            "118D 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17CP41",
                "Engine_code": "N47D20",
            },
            "120D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & ZF 8HP45 & Bosch EDC17C56 & Bosch EDC17C41",
                "Engine_code": "N47D20",
            },
            "120D 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 420,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50",
                "Engine_code": "N47D20",
            },
            "120D 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & ZF 8HP",
                "Engine_code": "N47D20",
            },
            "125D 218hp (1995cc)": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP",
                "Engine_code": "N47D20",
            },
            "125D 211hp (1995cc)": {
                "std_power": 211,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N47D20",
            },
            "114i 102hp": {
                "std_power": 102,
                "tuned_power": 210,
                "std_torque": 180,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2.5",
                "Engine_code": "N13B16",
            },
            "116i 136hp": {
                "std_power": 136,
                "tuned_power": 210,
                "std_torque": 220,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2.5 & ZF 8HP & Bosch MEVD17.2.3",
                "Engine_code": "N13B16",
            },
            "118i (1600cc) 170hp": {
                "std_power": 170,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2.5 & ZF 8HP45",
                "Engine_code": "N13B16",
            },
            "125i MMP 238hp": {
                "std_power": 238,
                "tuned_power": 270,
                "std_torque": 330,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.9",
                "Engine_code": "N20B20",
            },
            "125i 218hp": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 310,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MEVD17.2.4 & Bosch MEVD17.2.9 & ZF 8HP45",
                "Engine_code": "N20B20",
            },
            "M 135i 320hp": {
                "std_power": 320,
                "tuned_power": 385,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.6 & ZF 8HP & Bosch MEVD17.2.G & Bosch MED17.1.1",
                "Engine_code": "N55B30",
            },
        },
        "F20 - 2015 - 2018": {
            "114D 95hp (1496cc)": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 335,
                "cc": 1496,
                "Type_ecu": "Bosch EDC17C50",
                "Engine_code": "N47D16",
            },
            "116D 116hp (1496cc)": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch EDC17C50 & ZF 8HP50 & Bosch EDC17C41",
                "Engine_code": "B37",
            },
            "118D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch MD1 & ZF 8HP50Z & Bosch EDC17C50",
                "Engine_code": "B47D20B",
            },
            "120D 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & ZF 8HP50Z & Bosch EDC17C41",
                "Engine_code": "B47D20A",
            },
            "120D 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "N47D20A",
            },
            "125D 224hp (1995cc)": {
                "std_power": 224,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch MD1 & ZF 8HP50Z",
                "Engine_code": "B47D20B",
            },
            "125D 211hp (1995cc)": {
                "std_power": 211,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch MD1",
                "Engine_code": "B47D20B",
            },
            "116i 109hp (1499cc)": {
                "std_power": 109,
                "tuned_power": 170,
                "std_torque": 180,
                "tuned_torque": 290,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MEVD17.2.5",
                "Engine_code": "B38A15M0",
            },
            "118i 136hp (1499cc)": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HP50Z & Bosch MEVD17.2.3 & Bosch MEVD17.2.5",
                "Engine_code": "B38A15M0",
            },
            "118i 136hp (1598cc)": {
                "std_power": 136,
                "tuned_power": 220,
                "std_torque": 220,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2.5 & ZF 8HP45Z & Bosch MEVD17.2.3 & Bosch MEVD17.2.8",
                "Engine_code": "N13B16",
            },
            "120i 177hp (1598cc)": {
                "std_power": 177,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MEVD17.2.5",
                "Engine_code": "N13B16",
            },
            "120i 184hp (1998cc) (B48B20B)": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 290,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MED17 & ZF 8HP",
                "Engine_code": "B48B20B",
            },
            "125i 218hp": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 310,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "N20B20",
            },
            "125i 224hp": {
                "std_power": 224,
                "tuned_power": 270,
                "std_torque": 310,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "N20B20",
            },
            "135i 326hp": {
                "std_power": 326,
                "tuned_power": 395,
                "std_torque": 450,
                "tuned_torque": 530,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HPXY & Bosch MEVD17.2.6",
                "Engine_code": "N55B30",
            },
            "M140i 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2979,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP50",
                "Engine_code": "B58B30M0",
            },
        },
        "F40 - 2019 ->": {
            "116D (1.5D) 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1CS001 & Bosch MD1CS006",
                "Engine_code": "B37",
            },
            "118D (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20B",
            },
            "120D (2.0D) 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50",
                "Engine_code": "N47D20A",
            },
            "120D (2.0D) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MDG1_MD1CP002 & Bosch MD1CS001 & Bosch EDC17C50",
                "Engine_code": "B47D20A",
            },
            "116i (1.5T) 109hp": {
                "std_power": 109,
                "tuned_power": 170,
                "std_torque": 190,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MG1CS201",
                "Engine_code": "B38A15M0",
            },
            "118i (1.5T) 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MG1CS201 & Bosch MEVD17.2",
                "Engine_code": "B38A15M0",
            },
            "120i (1.6T) 177hp": {
                "std_power": 177,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "N13B16",
            },
            "120i (2.0T) 184hp": {
                "std_power": 184,
                "tuned_power": 260,
                "std_torque": 290,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "N20B20",
            },
            "120i (2.0T) 184hp (B48B20B)": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 290,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS003",
                "Engine_code": "B48B20B",
            },
            "125i (2.0T) 218hp": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 310,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "N20B20",
            },
            "128ti 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "B48A20E",
            },
            "M135i 306hp": {
                "std_power": 306,
                "tuned_power": 335,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1998,
                "Type_ecu": "Bosch MG1SC201 & Bosch MG1CS024",
                "Engine_code": "B48A20T1",
            },
        },
    },
    "1M": {
        "E82 - 2011 ->": {
            "3.0i Bi-turbo 340hp": {
                "std_power": 340,
                "tuned_power": 375,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2 & Siemens/Continental MSD81",
                "Engine_code": "N54B30A",
            },
        },
    },
    "2 Serie GC": {
        "2020 ->": {
            "216d (1.5D) 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B57 C15 U0",
            },
            "218d (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "B47D20A",
            },
            "220d (2.0D) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "B47D20A",
            },
            "218i (1.5T) 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B38A15M0",
            },
            "220i (2.0T) 184hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "M235i 306hp": {
                "std_power": 306,
                "tuned_power": 335,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B48A20T1",
            },
            "M240i 374hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "2 serie": {
        "F2x - 2013 - 2020": {
            "218d 143hp": {
                "std_power": 143,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50",
                "Engine_code": "N47D20",
            },
            "218d 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50",
                "Engine_code": "N47D20",
            },
            "218d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B47D20",
            },
            "220d 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & Bosch EDC17C56",
                "Engine_code": "B47D20A",
            },
            "220d 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & ZF 8HP & Bosch EDC17C56",
                "Engine_code": "B47D20A",
            },
            "220d 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 420,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B47D20A",
            },
            "220d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & ZF 8HP & Bosch EDC17C56",
                "Engine_code": "B47D20A",
            },
            "225d 218hp": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & Bosch EDC17CP45",
                "Engine_code": "B47D20B",
            },
            "225d 224hp": {
                "std_power": 224,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch MD1",
                "Engine_code": "B47D20B",
            },
            "218i 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS201",
                "Engine_code": "B38A15M0",
            },
            "220i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 270,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.9 & Bosch MG1CS003 & ZF 8HP45Z & Bosch MEVD17.2.4 & Bosch MEVD17.2.3",
                "Engine_code": "B48B20B",
            },
            "228i 245hp": {
                "std_power": 245,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.9 & Bosch DME841 & Bosch MEVD17.2.3 & Bosch MEVD17.2.P & Bosch MEVD17.2.4 & ZF 8HP",
                "Engine_code": "N20B20",
            },
            "230i 252hp": {
                "std_power": 252,
                "tuned_power": 278,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "B48B20",
            },
            "M235i 326hp": {
                "std_power": 326,
                "tuned_power": 380,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HP & Bosch MEVD17.2.6 & Bosch MEVD17.2.G",
                "Engine_code": "N55B30",
            },
            "M240i 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP50",
                "Engine_code": "B58B30M0",
            },
        },
        "G42 - 2021 -> ...": {
            "220d 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "220i 184hp": {
                "std_power": 184,
                "tuned_power": 260,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B48B20A",
            },
            "M240i xDrive 374hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "2 serie Active Tourer": {
        "2014 - 2017": {
            "214d (1496cc) 95hp": {
                "std_power": 95,
                "tuned_power": 150,
                "std_torque": 220,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch EDC17 & Bosch EDC17C50",
                "Engine_code": "B37",
            },
            "216d (1496cc) 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1CS001",
                "Engine_code": "B37",
            },
            "218d (1995cc) 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch MD1",
                "Engine_code": "B47D20A",
            },
            "218d (1995cc) 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & Bosch EDC17C41",
                "Engine_code": "B47D20A",
            },
            "220d (1995cc) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & Bosch EDC17C56",
                "Engine_code": "B47D20A",
            },
            "225d (1995cc) 224hp": {
                "std_power": 224,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17",
                "Engine_code": "B47",
            },
            "216i (1499cc) 102hp": {
                "std_power": 102,
                "tuned_power": 165,
                "std_torque": 180,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2",
                "Engine_code": "B38A15M0",
            },
            "218i (1499cc) 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B38A15M0",
            },
            "220i 192hp": {
                "std_power": 192,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MG1CS201 & Bosch MG1CS003 & Bosch MEVD17.2.3",
                "Engine_code": "B48",
            },
            "225i (1998cc) 231hp": {
                "std_power": 231,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2",
                "Engine_code": "B48",
            },
            "225xe (1499cc) 225hp Hybrid": {
                "std_power": 225,
                "tuned_power": 255,
                "std_torque": 385,
                "tuned_torque": 445,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B38A20A",
            },
        },
        "2018 - 2021": {
            "216d (1496cc) 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1CS001",
                "Engine_code": "B57 C15 U0",
            },
            "218d (1995cc) 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1CS001",
                "Engine_code": "B57 C15 U0",
            },
            "218d xDrive (1995cc) 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1CS001",
                "Engine_code": "B57 C15 U0",
            },
            "220d (1995cc) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1998,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B47D20O0",
            },
            "220d xDrive (1995cc) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B47D20O0",
            },
            "215i (1499cc) 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 250,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS201",
                "Engine_code": "",
            },
            "218i (1499cc) 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1499,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B38A15A",
            },
            "220i (1998cc) 190hp": {
                "std_power": 190,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS201",
                "Engine_code": "B48 A20 A",
            },
            "225i xDrive (1998cc) 230hp": {
                "std_power": 230,
                "tuned_power": 260,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B38B15",
            },
            "225xe Hybrid (1499cc) 225hp": {
                "std_power": 225,
                "tuned_power": 275,
                "std_torque": 385,
                "tuned_torque": 450,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B38A20A",
            },
        },
        "2022 -> ...": {
            "216i 122hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "218d 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "223d 211hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "218i 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "220i 170hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "223i 218hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "3 serie": {
        "E36": {
            "318 TDS 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1665,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "174T1",
            },
            "325 TD 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 245,
                "tuned_torque": 285,
                "cc": 2497,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "256T1",
            },
            "325 TDS 143hp": {
                "std_power": 143,
                "tuned_power": 170,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 2497,
                "Type_ecu": "Bosch EDC16C31 & Bosch DDE",
                "Engine_code": "256T1",
            },
        },
        "E46 - 1998 - 2005": {
            "318D 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1951,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC15M & Bosch DDE3.0 & Bosch EDC16C10",
                "Engine_code": "M47TUD20",
            },
            "320D 136hp": {
                "std_power": 136,
                "tuned_power": 168,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1951,
                "Type_ecu": "Bosch DDE3.0 & Bosch EDC15M",
                "Engine_code": "M47D20",
            },
            "320D 150hp": {
                "std_power": 150,
                "tuned_power": 183,
                "std_torque": 330,
                "tuned_torque": 390,
                "cc": 1951,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C10",
                "Engine_code": "M47TUD20",
            },
            "330D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 390,
                "tuned_torque": 480,
                "cc": 2926,
                "Type_ecu": "Bosch DDE4.0 & Bosch EDC15",
                "Engine_code": "M57D30",
            },
            "330D 204hp": {
                "std_power": 204,
                "tuned_power": 250,
                "std_torque": 410,
                "tuned_torque": 500,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C10",
                "Engine_code": "M57D30",
            },
            "330D 193hp": {
                "std_power": 193,
                "tuned_power": 221,
                "std_torque": 410,
                "tuned_torque": 480,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C35",
                "Engine_code": "M57D30",
            },
            "316i 115hp": {
                "std_power": 115,
                "tuned_power": 126,
                "std_torque": 175,
                "tuned_torque": 192,
                "cc": 1895,
                "Type_ecu": "Bosch ME9 & Bosch BMS46 & Bosch MEV946 & Bosch DME7.1",
                "Engine_code": "N42B16",
            },
            "318i 118hp": {
                "std_power": 118,
                "tuned_power": 131,
                "std_torque": 180,
                "tuned_torque": 200,
                "cc": 1895,
                "Type_ecu": "Bosch ME9.2 & Bosch BMS46 & Bosch M5.2.1",
                "Engine_code": "M43B19",
            },
            "318i 143hp": {
                "std_power": 143,
                "tuned_power": 156,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1895,
                "Type_ecu": "Bosch ME9.2 & Siemens/Continental MS43",
                "Engine_code": "N46B20",
            },
            "318i 136hp": {
                "std_power": 136,
                "tuned_power": 148,
                "std_torque": 180,
                "tuned_torque": 195,
                "cc": 1895,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "N46B20",
            },
            "320i 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 1991,
                "Type_ecu": "Bosch ME9 & Siemens/Continental MS43 & Siemens/Continental MS42 & Siemens/Continental MS41",
                "Engine_code": "M54B22",
            },
            "320i 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 190,
                "tuned_torque": 208,
                "cc": 1991,
                "Type_ecu": "Bosch ME9 & Siemens/Continental MS42",
                "Engine_code": "M52TUB20",
            },
            "323i 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 245,
                "tuned_torque": 265,
                "cc": 2494,
                "Type_ecu": "Bosch ME9 & Siemens/Continental MS43 & Siemens/Continental MS41 & Bosch ME9 & Siemens/Continental MS42",
                "Engine_code": "M52TUB25",
            },
            "325i 192hp": {
                "std_power": 192,
                "tuned_power": 200,
                "std_torque": 245,
                "tuned_torque": 272,
                "cc": 2494,
                "Type_ecu": "Siemens/Continental MS45 & Siemens/Continental MS43 & Siemens/Continental MS42",
                "Engine_code": "M54B25",
            },
            "328i 193hp": {
                "std_power": 193,
                "tuned_power": 209,
                "std_torque": 280,
                "tuned_torque": 305,
                "cc": 2793,
                "Type_ecu": "Siemens/Continental MS42 & Siemens/Continental MS43",
                "Engine_code": "M52TUB28",
            },
            "330i 231hp": {
                "std_power": 231,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MS43 & Siemens/Continental MS45",
                "Engine_code": "M54B30",
            },
        },
        "E90/E91 - 2005 - 2010": {
            "316D 115hp": {
                "std_power": 115,
                "tuned_power": 180,
                "std_torque": 260,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C06 & Bosch EDC17C41",
                "Engine_code": "N47D20C",
            },
            "318D 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C35",
                "Engine_code": "M47TU2D20",
            },
            "318D 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C35 & Bosch EDC17C06 & Bosch EDC17C41 & Bosch EDC17CP02",
                "Engine_code": "N47D20C",
            },
            "318D 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C06 & Bosch EDC17C41 & Bosch MEV1746 & Bosch EDC17C50",
                "Engine_code": "N47D20C",
            },
            "320D 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C35 & Bosch EDC17CP02",
                "Engine_code": "M47TUD20",
            },
            "320D 163hp": {
                "std_power": 163,
                "tuned_power": 215,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch EDC16C31 & Bosch EDC16C35 & Bosch EDC17C41 & Bosch EDC17CP02 & Bosch EDC17C06",
                "Engine_code": "204D4",
            },
            "320D 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP02 & Bosch EDC17C06 & Bosch EDC17CP45",
                "Engine_code": "N47D20A",
            },
            "325D 177hp": {
                "std_power": 177,
                "tuned_power": 214,
                "std_torque": 400,
                "tuned_torque": 452,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C35",
                "Engine_code": "306D3",
            },
            "325D 197hp": {
                "std_power": 197,
                "tuned_power": 233,
                "std_torque": 400,
                "tuned_torque": 490,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C35",
                "Engine_code": "306D3",
            },
            "330D 211hp": {
                "std_power": 211,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 595,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C35 & Bosch EDC17CP09",
                "Engine_code": "306D3",
            },
            "330D 231hp": {
                "std_power": 231,
                "tuned_power": 275,
                "std_torque": 500,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16CP35 & ZF 6HP",
                "Engine_code": "306D3",
            },
            "330D 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 520,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09 & Bosch EDC16CP35",
                "Engine_code": "N57D30A",
            },
            "335D 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 580,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35 & Bosch EDC17CP09",
                "Engine_code": "306D5",
            },
            "335D 306hp": {
                "std_power": 306,
                "tuned_power": 340,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C35",
                "Engine_code": "306D5",
            },
            "316i 122hp": {
                "std_power": 122,
                "tuned_power": 136,
                "std_torque": 160,
                "tuned_torque": 177,
                "cc": 1597,
                "Type_ecu": "Bosch MEV946 & Siemens/Continental MSD80 & Bosch ME9.2 & Siemens/Continental MSD81.2",
                "Engine_code": "N43B16AA",
            },
            "318i 143hp": {
                "std_power": 143,
                "tuned_power": 157,
                "std_torque": 190,
                "tuned_torque": 216,
                "cc": 1995,
                "Type_ecu": "Bosch MEV1746 & Bosch ME9.2 & Siemens/Continental MSD81.2 & Siemens/Continental MSD80",
                "Engine_code": "N43B20A",
            },
            "320i 170hp": {
                "std_power": 170,
                "tuned_power": 182,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 1995,
                "Type_ecu": "Bosch MEV946 & Siemens/Continental MSD81 & Siemens/Continental MSD80",
                "Engine_code": "N43B20A",
            },
            "320i 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1995,
                "Type_ecu": "Bosch ME9.2 & Bosch MEV17.2 & Bosch MEV946",
                "Engine_code": "N46B20B",
            },
            "320i 163hp": {
                "std_power": 163,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1995,
                "Type_ecu": "Bosch MEV1746 & Siemens/Continental MSD81 & Siemens/Continental MSD80",
                "Engine_code": "N46B20",
            },
            "320si 173hp": {
                "std_power": 173,
                "tuned_power": 185,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1995,
                "Type_ecu": "Bosch MEV946",
                "Engine_code": "N45B20A",
            },
            "325i - N52 - 218hp": {
                "std_power": 218,
                "tuned_power": 232,
                "std_torque": 250,
                "tuned_torque": 275,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental MSV80 & Siemens/Continental MSD81 & Siemens/Continental MSV70 & Siemens/Continental MSD80",
                "Engine_code": "N52B25A",
            },
            "325i - N52 - 211hp": {
                "std_power": 211,
                "tuned_power": 232,
                "std_torque": 250,
                "tuned_torque": 275,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental MSD80 & Siemens/Continental MSV80 & Siemens/Continental MSV70",
                "Engine_code": "N52B25A",
            },
            "325i - N53 - 211hp": {
                "std_power": 211,
                "tuned_power": 265,
                "std_torque": 270,
                "tuned_torque": 315,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSV80 & Siemens/Continental MSD81 & Siemens/Continental MSD80",
                "Engine_code": "N53B30A",
            },
            "325i - N53 - 218hp": {
                "std_power": 218,
                "tuned_power": 265,
                "std_torque": 270,
                "tuned_torque": 315,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSD81 & Siemens/Continental MSV80 & Siemens/Continental MSD80 & Siemens/Continental MSV70",
                "Engine_code": "N53B30A",
            },
            "328i 230hp": {
                "std_power": 230,
                "tuned_power": 274,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSV80",
                "Engine_code": "N51B30A",
            },
            "330i 258hp": {
                "std_power": 258,
                "tuned_power": 274,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSD80 & Siemens/Continental MSV70 & Siemens/Continental MSV80",
                "Engine_code": "N52B30A",
            },
            "330i 272hp": {
                "std_power": 272,
                "tuned_power": 292,
                "std_torque": 320,
                "tuned_torque": 340,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSD80 & Siemens/Continental MSV80 & Siemens/Continental MSD81",
                "Engine_code": "N53B30A",
            },
            "335i - N54 - 306hp": {
                "std_power": 306,
                "tuned_power": 370,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD81 & Siemens/Continental MSD80",
                "Engine_code": "N54B30",
            },
            "335i - N54 PPK - 326hp": {
                "std_power": 326,
                "tuned_power": 370,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD81 & Siemens/Continental MSD80",
                "Engine_code": "N54B30",
            },
            "335i - N55 Twinscroll - 306hp": {
                "std_power": 306,
                "tuned_power": 360,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD81 & Bosch MEVD17.2",
                "Engine_code": "N55B30A",
            },
            "M3 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 3999,
                "Type_ecu": "Siemens/Continental MSS60",
                "Engine_code": "S65B40A",
            },
        },
        "E90/E91 - 2010 - 2011": {
            "316D 115hp": {
                "std_power": 115,
                "tuned_power": 180,
                "std_torque": 260,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C06 & Bosch EDC17C50 & Bosch EDC17CP45",
                "Engine_code": "N47D20C",
            },
            "318D 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch EDC17CP02 & Bosch EDC17C41 & Bosch EDC17C06",
                "Engine_code": "N47D20C",
            },
            "318D 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C50 & Bosch EDC17CP02",
                "Engine_code": "N47D20C",
            },
            "320D 184hp": {
                "std_power": 184,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch EDC17C41 & Bosch EDC17C50 & Bosch EDC17CP45 & Bosch EDC17CP02 & Bosch EDC17C56",
                "Engine_code": "N47D20A",
            },
            "320D 163hp": {
                "std_power": 163,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C50 & Bosch EDC17CP02",
                "Engine_code": "N47D20C",
            },
            "325D 204hp": {
                "std_power": 204,
                "tuned_power": 300,
                "std_torque": 430,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09",
                "Engine_code": "N57D30A",
            },
            "330D 211hp": {
                "std_power": 211,
                "tuned_power": 300,
                "std_torque": 520,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09",
                "Engine_code": "N57D30A",
            },
            "330D 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 520,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09",
                "Engine_code": "N57D30A",
            },
            "335D 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 580,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09 & Bosch EDC16CP35 & Bosch EDC17CP45",
                "Engine_code": "306D5",
            },
            "335D 306hp": {
                "std_power": 306,
                "tuned_power": 340,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09",
                "Engine_code": "306D5",
            },
            "316i 122hp": {
                "std_power": 122,
                "tuned_power": 136,
                "std_torque": 160,
                "tuned_torque": 177,
                "cc": 1597,
                "Type_ecu": "Bosch MEV946 & Siemens/Continental MSD80",
                "Engine_code": "N43B16AA",
            },
            "318i 143hp": {
                "std_power": 143,
                "tuned_power": 157,
                "std_torque": 190,
                "tuned_torque": 216,
                "cc": 1995,
                "Type_ecu": "Siemens/Continental MSD81 & Bosch MEV1746 & Siemens/Continental MSV80 & Bosch ME9.2",
                "Engine_code": "N43B20A",
            },
            "320i 170hp": {
                "std_power": 170,
                "tuned_power": 182,
                "std_torque": 210,
                "tuned_torque": 240,
                "cc": 1995,
                "Type_ecu": "Siemens/Continental MSD81 & Bosch MEV17.4.6 & Siemens/Continental MSD80",
                "Engine_code": "N43B20A",
            },
            "325i - N53 - 211hp": {
                "std_power": 211,
                "tuned_power": 265,
                "std_torque": 270,
                "tuned_torque": 315,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSV80 & Siemens/Continental MSD81 & Siemens/Continental MSD80",
                "Engine_code": "N53B30A",
            },
            "325i - N53 - 218hp": {
                "std_power": 218,
                "tuned_power": 265,
                "std_torque": 270,
                "tuned_torque": 315,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSV80 & Siemens/Continental MSD81",
                "Engine_code": "N53B30A",
            },
            "328i 230hp": {
                "std_power": 230,
                "tuned_power": 274,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSV80 & Siemens/Continental MSV80.1",
                "Engine_code": "N51B30A",
            },
            "330i 272hp": {
                "std_power": 272,
                "tuned_power": 292,
                "std_torque": 320,
                "tuned_torque": 345,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSD80 & Siemens/Continental MSD81",
                "Engine_code": "N52B30A",
            },
            "335i - N55 Twinscroll - 306hp": {
                "std_power": 306,
                "tuned_power": 360,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.7.1 & Bosch MEVD17.2.6 & Bosch MED17.1.1 & Bosch MEVD17.2 & Temic DKG 436 Gen1 & Siemens/Continental MSD81",
                "Engine_code": "N55B30A",
            },
            "335i - N55 Twinscroll PPK - 326hp": {
                "std_power": 326,
                "tuned_power": 360,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD81 & Bosch MED17",
                "Engine_code": "N55B30A",
            },
            "M3 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 3999,
                "Type_ecu": "Siemens/Continental MSS60 & Temic DKG 436 Gen1",
                "Engine_code": "S65B40A",
            },
        },
        "F3x - 2011 - 2015": {
            "316D 116hp (1995cc)": {
                "std_power": 116,
                "tuned_power": 190,
                "std_torque": 260,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C56 & Bosch EDC17C41 & ZF 8HP45",
                "Engine_code": "B47D20A",
            },
            "318D 143hp (1995cc)": {
                "std_power": 143,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C50 & ZF 8HP45 & Bosch EDC17C56 & Bosch EDC17C76",
                "Engine_code": "N47D20C",
            },
            "318D 136hp (1995cc)": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1600 bars rail system only) & Bosch EDC17C56 & Bosch EDC17C50 & Bosch EDC17C41",
                "Engine_code": "N47D20C",
            },
            "320D 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C56 & Bosch EDC17C41 & ZF 8HP & Bosch EDC17CP45",
                "Engine_code": "N47D20C",
            },
            "320D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C56 & Bosch MEVD17.2.9 & ZF 8HP50 & Bosch EDC17C41 & Bosch EDC17C76 & ZF 8HP45 & Bosch EDC17CP45",
                "Engine_code": "N47D20C",
            },
            "320D 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 420,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C41",
                "Engine_code": "N47D20C",
            },
            "325D 218hp (1995cc)": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch EDC17CP45",
                "Engine_code": "B47D20B",
            },
            "330D 258hp": {
                "std_power": 258,
                "tuned_power": 300,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C41 & ZF 8HP70 & Bosch EDC17C50 & Bosch EDC17C56 & Bosch EDC17CP09",
                "Engine_code": "N57D30A",
            },
            "335D xDrive 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 710,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP70 & Bosch EDC17CP49",
                "Engine_code": "N57D30B",
            },
            "316i 136hp": {
                "std_power": 136,
                "tuned_power": 210,
                "std_torque": 220,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2.5 & ZF 8HP",
                "Engine_code": "N13B16A",
            },
            "320i (1600cc) 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 315,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HP",
                "Engine_code": "N13B16A",
            },
            "320i 184hp": {
                "std_power": 184,
                "tuned_power": 260,
                "std_torque": 270,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.4 & ZF 8HP45 & Bosch MEVD17.2.9 & Bosch MG1CS003 & Siemens/Continental MSD81.2 & Bosch MEVD17.2.8",
                "Engine_code": "N20B20",
            },
            "328i 245hp": {
                "std_power": 245,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.4 & ZF 8HP45 & Bosch MEVD17.2.9 & Bosch MEVD17.2.P & Bosch MEVD17.8.4",
                "Engine_code": "N20B20A",
            },
            "335i Active Hybrid 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.2.6 & ZF 8HP",
                "Engine_code": "N55B30",
            },
            "335i 306hp": {
                "std_power": 306,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.6 & ZF 8HP45 & Bosch MED17.2 & Siemens/Continental MSD80 & Bosch MEVD17.2.G",
                "Engine_code": "N55B30A",
            },
            "M3 Competition 450hp": {
                "std_power": 450,
                "tuned_power": 500,
                "std_torque": 550,
                "tuned_torque": 680,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2",
                "Engine_code": "S55B30A",
            },
            "M3 431hp": {
                "std_power": 431,
                "tuned_power": 500,
                "std_torque": 550,
                "tuned_torque": 650,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2",
                "Engine_code": "S55B30A",
            },
        },
        "F3x LCI - 06 / 2015 - 2019": {
            "316d 116hp": {
                "std_power": 116,
                "tuned_power": 190,
                "std_torque": 270,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & ZF 8HP",
                "Engine_code": "B47D20",
            },
            "318d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1600 bars rail system only) & Bosch EDC17C50 & Bosch MD1 & ZF 8HP50",
                "Engine_code": "B47D20A",
            },
            "320d Efficient dynamics 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MD1 & Bosch EDC17C41 & Bosch EDC17C56 & Bosch EDC17C50 & ZF 8HP",
                "Engine_code": "B47D20A",
            },
            "320d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & Bosch EDC17C56 & ZF 8HP & Bosch EDC17C41 & Bosch EDC17C76",
                "Engine_code": "B47D20A",
            },
            "325d (1995cc) 218hp": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch MD1",
                "Engine_code": "B47D20B",
            },
            "325d (1995cc) 211hp": {
                "std_power": 211,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch ME1.0 & Bosch EDC17CP45",
                "Engine_code": "B47D20B",
            },
            "325d (1995cc) 224hp": {
                "std_power": 224,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch MD1 & ZF 8HP",
                "Engine_code": "B47D20B",
            },
            "330D 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & ZF 8HP",
                "Engine_code": "N57D30A",
            },
            "330d xDrive 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & Bosch EDC17C41 & ZF 8HP",
                "Engine_code": "N57D30A",
            },
            "335d xDrive 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 710,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP & Bosch EDC17CP49",
                "Engine_code": "N57D30B",
            },
            "318d 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B47D20A",
            },
            "318i (1500cc) 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3 & ZF 8HP",
                "Engine_code": "B38A15M0",
            },
            "320i xDrive 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 270,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "B48B20A",
            },
            "320i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 290,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP45 & Bosch MEVD17.2",
                "Engine_code": "B48A20M0",
            },
            "330e 252hp": {
                "std_power": 252,
                "tuned_power": 340,
                "std_torque": 420,
                "tuned_torque": 580,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS201 & Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "B48B20A",
            },
            "330i xDrive 252hp": {
                "std_power": 252,
                "tuned_power": 287,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "B58B30M0",
            },
            "330i 252hp": {
                "std_power": 252,
                "tuned_power": 287,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP & Bosch MG1CS201",
                "Engine_code": "B48B20O0",
            },
            "340i xDrive 326hp": {
                "std_power": 326,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 585,
                "cc": 2998,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "B58B30A",
            },
            "340i 326hp": {
                "std_power": 326,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 585,
                "cc": 2998,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MG1CS003 & ZF 8HP50 & Bosch MG1CS201",
                "Engine_code": "B58B30M0",
            },
        },
        "G2x - 2019 ->": {
            "318d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20",
            },
            "318d 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20",
            },
            "320d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001 & ZF 8HP",
                "Engine_code": "B47D20",
            },
            "320d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20B",
            },
            "330d 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 580,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002",
                "Engine_code": "B57D30O0",
            },
            "330d 286hp": {
                "std_power": 286,
                "tuned_power": 330,
                "std_torque": 650,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MD1CP002",
                "Engine_code": "B57D30B",
            },
            "M340d 340hp": {
                "std_power": 340,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "B57D30",
            },
            "320d 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20B",
            },
            "318i 156hp": {
                "std_power": 156,
                "tuned_power": 260,
                "std_torque": 250,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS003",
                "Engine_code": "B48B20",
            },
            "320e 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "320i (GPF) 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 290,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B48B20D",
            },
            "320i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201 & ZF 8HP",
                "Engine_code": "B48B20A",
            },
            "320i 184hp": {
                "std_power": 184,
                "tuned_power": 310,
                "std_torque": 300,
                "tuned_torque": 470,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "B48B20B",
            },
            "330e Hybrid 292hp": {
                "std_power": 292,
                "tuned_power": 400,
                "std_torque": 420,
                "tuned_torque": 700,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201 & ZF 8HP",
                "Engine_code": "B48B20A",
            },
            "330e 252hp": {
                "std_power": 252,
                "tuned_power": 340,
                "std_torque": 420,
                "tuned_torque": 540,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "B48B20A",
            },
            "330i 258hp": {
                "std_power": 258,
                "tuned_power": 287,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201 & ZF 8HP",
                "Engine_code": "B48B20",
            },
            "M340i 374hp": {
                "std_power": 374,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 575,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS024 & Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "B58B30O1",
            },
        },
    },
    "3 serie GT": {
        "F34 - 2012 - 2015": {
            "318D 143hp": {
                "std_power": 143,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1600 bars rail system only) & Bosch EDC17C50 & Bosch EDC17C41 & Bosch EDC17C56",
                "Engine_code": "N47D20C",
            },
            "318D 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1600 bars rail system only) & Bosch EDC17C50 & Bosch EDC17C56",
                "Engine_code": "N47D20C",
            },
            "320D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & ZF 8HP45 & Bosch MEVD17.2 & Bosch EDC17C56 & Bosch EDC17C41 & Bosch EDC17CP45",
                "Engine_code": "N47D20C",
            },
            "320D 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1800 bars rail system only) & Bosch EDC17C50 & Bosch EDC17C56",
                "Engine_code": "N47D20C",
            },
            "325D 218hp": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch EDC17CP45",
                "Engine_code": "N47D20D",
            },
            "330D PP 286hp": {
                "std_power": 286,
                "tuned_power": 310,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56",
                "Engine_code": "N57D30A",
            },
            "330D 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & ZF 8HP",
                "Engine_code": "N57D30A",
            },
            "335D 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 710,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30B",
            },
            "320i 184hp": {
                "std_power": 184,
                "tuned_power": 260,
                "std_torque": 270,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HP45 & Bosch MEVD17.2.4 & Bosch MEVD17.2.9",
                "Engine_code": "N20B20B",
            },
            "328i 245hp": {
                "std_power": 245,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HP",
                "Engine_code": "N20B20A",
            },
            "335i 306hp": {
                "std_power": 306,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2",
                "Engine_code": "N55B30A",
            },
        },
        "F34 LCI - 2016 ->": {
            "318d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & ZF 8HP",
                "Engine_code": "N47D20A",
            },
            "320d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B47D20A",
            },
            "330d 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56",
                "Engine_code": "N57D30A",
            },
            "335d 313hp": {
                "std_power": 313,
                "tuned_power": 360,
                "std_torque": 630,
                "tuned_torque": 700,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "N57D30B",
            },
            "320i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 290,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MG1CS003 & Bosch MEVD17.2 & ZF 8HP",
                "Engine_code": "B48B20A",
            },
            "330i 252hp": {
                "std_power": 252,
                "tuned_power": 287,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "B48B20A",
            },
            "340i Performance Package 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "",
                "Engine_code": "B58B30A",
            },
            "340i 326hp": {
                "std_power": 326,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "B58B30A",
            },
        },
    },
    "4 serie": {
        "F32/33 - 2013 - 2016": {
            "418D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C56 & Bosch EDC17C41",
                "Engine_code": "B47D20A",
            },
            "420D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1800 bars rail system only) & Bosch EDC17C50 & Bosch EDC17C41 & Bosch EDC17C56 & Bosch EDC17CP45 & ZF 8HP & Bosch MEVD17.2.9",
                "Engine_code": "B47D20A",
            },
            "420D 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50",
                "Engine_code": "B47D20A",
            },
            "420D 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C41 & ZF 8HP",
                "Engine_code": "B47D20A",
            },
            "425D 218hp": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch EDC17CP45 & ZF 8HP",
                "Engine_code": "B47D20B",
            },
            "430D 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & Bosch EDC17C50 & Bosch EDC17C41 & ZF 8HP",
                "Engine_code": "N57D30A",
            },
            "435xD 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 710,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP70",
                "Engine_code": "N57D30B",
            },
            "418i (1.5T) 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 220,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3 & ZF 8HP",
                "Engine_code": "",
            },
            "420i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 270,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MG1CS003 & Bosch MEVD17.2.4 & Bosch MEVD17.2.9 & Bosch EDC17C56 & ZF 8HP",
                "Engine_code": "B48B20A",
            },
            "428i 245hp": {
                "std_power": 245,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HP45 & Bosch MEVD17.2.9 & Bosch DME841 & Bosch MEVD17.2.4",
                "Engine_code": "N20B20A",
            },
            "430i 252hp": {
                "std_power": 252,
                "tuned_power": 287,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "B48B20",
            },
            "435i 306hp": {
                "std_power": 306,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2929,
                "Type_ecu": "Bosch MEVD17.2.G & ZF 8HP45 & Bosch MEVD17.2.6",
                "Engine_code": "N55B30A",
            },
            "M4 - 431hp": {
                "std_power": 431,
                "tuned_power": 500,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2",
                "Engine_code": "S55B30A",
            },
            "M4 Competition 450hp": {
                "std_power": 450,
                "tuned_power": 500,
                "std_torque": 550,
                "tuned_torque": 680,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2",
                "Engine_code": "S55B30A",
            },
            "M4 GTS 500hp": {
                "std_power": 500,
                "tuned_power": 530,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2",
                "Engine_code": "S55B30A",
            },
        },
        "F32/33 - May/2016 - 2019": {
            "418d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B47D20",
            },
            "420d 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP50 & Bosch MD1",
                "Engine_code": "B47D20",
            },
            "420d 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & Bosch EDC17C41",
                "Engine_code": "B47D20",
            },
            "420d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & ZF 8HP50",
                "Engine_code": "B47D20",
            },
            "425d 224hp": {
                "std_power": 224,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch MD1",
                "Engine_code": "B47D20B",
            },
            "430d 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002 & Bosch EDC17C56 & ZF 8HP",
                "Engine_code": "N57D30",
            },
            "435d 313hp": {
                "std_power": 313,
                "tuned_power": 360,
                "std_torque": 630,
                "tuned_torque": 700,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002 & ZF 8HP",
                "Engine_code": "N57D30",
            },
            "420i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 270,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP & Bosch MEVD17.2.9",
                "Engine_code": "B48B20",
            },
            "430i 252hp": {
                "std_power": 252,
                "tuned_power": 287,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "B48B20B",
            },
            "440i 326hp": {
                "std_power": 326,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 585,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP50Z",
                "Engine_code": "B58B30M0",
            },
        },
        "G22 - 2020 ->": {
            "420d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch MD1 & Bosch EDC17C50",
                "Engine_code": "B47D20",
            },
            "M440d 340hp": {
                "std_power": 340,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "420i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "B48B20A",
            },
            "430i 258hp": {
                "std_power": 258,
                "tuned_power": 287,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "B48B20B",
            },
            "M440i 374hp": {
                "std_power": 374,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 575,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B58B30O1",
            },
        },
    },
    "4 serie GC": {
        "F36 - 2014 - 2016": {
            "418D 143hp": {
                "std_power": 143,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1600 bars rail system only) & Bosch EDC17C50 & Bosch EDC17C56",
                "Engine_code": "B47D20A",
            },
            "418D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17",
                "Engine_code": "B47D20A",
            },
            "418D 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C41 & Bosch EDC17C56",
                "Engine_code": "B47D20A",
            },
            "420D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C41 & Bosch EDC17C56",
                "Engine_code": "B47D20A",
            },
            "420D 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50",
                "Engine_code": "B47D20A",
            },
            "430D 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & Bosch EDC17C41",
                "Engine_code": "N57D30A",
            },
            "435D 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 710,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30B",
            },
            "420D 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C41",
                "Engine_code": "B47D20A",
            },
            "420i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 270,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HP",
                "Engine_code": "B48B20A",
            },
            "428i 245hp": {
                "std_power": 245,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.4 & ZF 8HP45 & Bosch MEVD17.2.9",
                "Engine_code": "N20B20A",
            },
            "435i PP 339hp": {
                "std_power": 339,
                "tuned_power": 365,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2929,
                "Type_ecu": "Bosch MEVD17.2",
                "Engine_code": "N55B30A",
            },
            "435i 306hp": {
                "std_power": 306,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2929,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HP45",
                "Engine_code": "N55B30A",
            },
        },
        "F36 - May/2016 - 2021": {
            "418d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B47D20A",
            },
            "420d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & Bosch EDC17C41 & Bosch EDC17C56",
                "Engine_code": "B47D20",
            },
            "425d 224hp": {
                "std_power": 224,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch MD1",
                "Engine_code": "B47D20B",
            },
            "430d 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & Bosch EDC17C41 & ZF 8HP",
                "Engine_code": "N57D30O1",
            },
            "435d 313hp": {
                "std_power": 313,
                "tuned_power": 360,
                "std_torque": 630,
                "tuned_torque": 700,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30T1",
            },
            "418i 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3 & ZF 8HP",
                "Engine_code": "B38 B15A",
            },
            "420i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 270,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP45",
                "Engine_code": "B48B20A",
            },
            "430i 252hp": {
                "std_power": 252,
                "tuned_power": 287,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & Bosch DME841 & ZF 8HP",
                "Engine_code": "B48B20B",
            },
            "435i 306hp": {
                "std_power": 306,
                "tuned_power": 370,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.9",
                "Engine_code": "N55B30",
            },
            "440i 326hp": {
                "std_power": 326,
                "tuned_power": 380,
                "std_torque": 450,
                "tuned_torque": 560,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "B58B30M0",
            },
            "440i 374hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "G22 - 2021 ->": {
            "420D 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "M440d 340hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "420i 184hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "430i 245hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "M440i 374hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "5 serie": {
        "E34": {
            "524 TD 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 220,
                "tuned_torque": 262,
                "cc": 2443,
                "Type_ecu": "",
                "Engine_code": "246TB",
            },
            "525 TD 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 245,
                "tuned_torque": 285,
                "cc": 2498,
                "Type_ecu": "",
                "Engine_code": "256T1",
            },
            "525 TDS 143hp": {
                "std_power": 143,
                "tuned_power": 175,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 2498,
                "Type_ecu": "",
                "Engine_code": "256T1",
            },
        },
        "E39 - 1995 - 2003": {
            "520D 136hp": {
                "std_power": 136,
                "tuned_power": 168,
                "std_torque": 280,
                "tuned_torque": 348,
                "cc": 1951,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC15 & Bosch DDE3.0",
                "Engine_code": "204D1",
            },
            "525 TDS 143hp": {
                "std_power": 143,
                "tuned_power": 172,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 2498,
                "Type_ecu": "Bosch DDE3.0",
                "Engine_code": "256T1",
            },
            "525D 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 2497,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "256D1",
            },
            "530D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 390,
                "tuned_torque": 480,
                "cc": 2926,
                "Type_ecu": "Bosch DDE4.0",
                "Engine_code": "306D1",
            },
            "530D 193hp": {
                "std_power": 193,
                "tuned_power": 221,
                "std_torque": 410,
                "tuned_torque": 480,
                "cc": 2926,
                "Type_ecu": "Bosch EDC15 & Bosch DDE4.0",
                "Engine_code": "306D1",
            },
            "520i 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 190,
                "tuned_torque": 208,
                "cc": 1991,
                "Type_ecu": "Siemens/Continental MS41 & Siemens/Continental MS42",
                "Engine_code": "206S3",
            },
            "523i 170hp": {
                "std_power": 170,
                "tuned_power": 187,
                "std_torque": 245,
                "tuned_torque": 272,
                "cc": 2495,
                "Type_ecu": "Siemens/Continental MS43 & Siemens/Continental MS41 & Siemens/Continental MS42",
                "Engine_code": "256S3",
            },
            "525i 192hp": {
                "std_power": 192,
                "tuned_power": 208,
                "std_torque": 245,
                "tuned_torque": 270,
                "cc": 2494,
                "Type_ecu": "Siemens/Continental MS43",
                "Engine_code": "256S5",
            },
            "530i 231hp": {
                "std_power": 231,
                "tuned_power": 246,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MS43 & Bosch ME7.2",
                "Engine_code": "306S3",
            },
            "540i 4.4 V8 286hp": {
                "std_power": 286,
                "tuned_power": 300,
                "std_torque": 440,
                "tuned_torque": 460,
                "cc": 4398,
                "Type_ecu": "Bosch M5.2 & Bosch ME7.2",
                "Engine_code": "448S2",
            },
        },
        "E60/61 - 2003 - 2010": {
            "520D 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch EDC16C35 & Bosch EDC17C06 & Bosch EDC16C31 & Bosch EDC17CP02",
                "Engine_code": "204D4",
            },
            "520D 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP02 & Bosch EDC16CP35 & Bosch EDC17C06",
                "Engine_code": "204D4",
            },
            "520D 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch EDC16C35",
                "Engine_code": "204D4",
            },
            "520D 163hp - 2008": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch EDC16C31 & Bosch EDC17C06 & Bosch EDC17C41",
                "Engine_code": "204D4",
            },
            "525D 177hp": {
                "std_power": 177,
                "tuned_power": 214,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2497,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C10 & Bosch EDC16C35 & Bosch EDC17CP02",
                "Engine_code": "M57D25TU",
            },
            "525D 163hp": {
                "std_power": 163,
                "tuned_power": 214,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C10 & Bosch EDC16C35 & Bosch EDC17CP02",
                "Engine_code": "256D2",
            },
            "525D 197hp": {
                "std_power": 197,
                "tuned_power": 250,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C35",
                "Engine_code": "256D2",
            },
            "530D 218hp": {
                "std_power": 218,
                "tuned_power": 250,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16CP35 & Bosch EDC16C10",
                "Engine_code": "306D2",
            },
            "530D 231hp": {
                "std_power": 231,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35 & Bosch EDC16C31",
                "Engine_code": "306D2",
            },
            "530D 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 520,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35 & ZF 6HP",
                "Engine_code": "306D2",
            },
            "530D 235hp": {
                "std_power": 235,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35",
                "Engine_code": "M57D30TU",
            },
            "535D 272hp": {
                "std_power": 272,
                "tuned_power": 320,
                "std_torque": 560,
                "tuned_torque": 660,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35 & Siemens/Continental MSV70",
                "Engine_code": "306D4",
            },
            "535D 286hp": {
                "std_power": 286,
                "tuned_power": 340,
                "std_torque": 580,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35 & Bosch EDC17CP02",
                "Engine_code": "306D4",
            },
            "520i 170hp": {
                "std_power": 170,
                "tuned_power": 182,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 2171,
                "Type_ecu": "Siemens/Continental MSD81 & Siemens/Continental MS43 & Bosch MEV17.4.6 & Siemens/Continental MS45 & Siemens/Continental MSD80",
                "Engine_code": "226S1",
            },
            "523i 177hp": {
                "std_power": 177,
                "tuned_power": 194,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 2487,
                "Type_ecu": "Siemens/Continental MSV70 & Siemens/Continental MSV80 & Siemens/Continental MSD80",
                "Engine_code": "N52B25A",
            },
            "525i 192hp": {
                "std_power": 192,
                "tuned_power": 207,
                "std_torque": 245,
                "tuned_torque": 266,
                "cc": 2494,
                "Type_ecu": "Siemens/Continental MS45",
                "Engine_code": "256S5",
            },
            "525i 218hp": {
                "std_power": 218,
                "tuned_power": 230,
                "std_torque": 250,
                "tuned_torque": 270,
                "cc": 2494,
                "Type_ecu": "Siemens/Continental MSV70 & Siemens/Continental MSD81 & Siemens/Continental MSV80 & Siemens/Continental MSD80",
                "Engine_code": "256S5",
            },
            "530i 258hp": {
                "std_power": 258,
                "tuned_power": 272,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSV70 & Siemens/Continental MSD80 & Siemens/Continental MSV80",
                "Engine_code": "306S3",
            },
            "530i 231hp": {
                "std_power": 231,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MS45",
                "Engine_code": "306S3",
            },
            "530i 272hp": {
                "std_power": 272,
                "tuned_power": 286,
                "std_torque": 320,
                "tuned_torque": 345,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSD80 & Siemens/Continental MSD81",
                "Engine_code": "N53B30A",
            },
            "535i 300hp": {
                "std_power": 300,
                "tuned_power": 370,
                "std_torque": 407,
                "tuned_torque": 500,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD81",
                "Engine_code": "",
            },
            "540i 306hp": {
                "std_power": 306,
                "tuned_power": 330,
                "std_torque": 390,
                "tuned_torque": 420,
                "cc": 4000,
                "Type_ecu": "Bosch ME9.2 & Siemens/Continental MSD81",
                "Engine_code": "N62B40A",
            },
            "545i 333hp": {
                "std_power": 333,
                "tuned_power": 355,
                "std_torque": 450,
                "tuned_torque": 480,
                "cc": 4398,
                "Type_ecu": "Bosch ME9.2",
                "Engine_code": "N62B44A",
            },
            "550i 367hp": {
                "std_power": 367,
                "tuned_power": 390,
                "std_torque": 490,
                "tuned_torque": 525,
                "cc": 4799,
                "Type_ecu": "Bosch ME9.2 & ZF 6HP",
                "Engine_code": "N62B48B",
            },
            "M5 507hp": {
                "std_power": 507,
                "tuned_power": 537,
                "std_torque": 520,
                "tuned_torque": 550,
                "cc": 4999,
                "Type_ecu": "Siemens/Continental MSS65",
                "Engine_code": "S85B50A",
            },
        },
        "F10/11 - 2010 - 2016": {
            "518D 143hp (1995cc)": {
                "std_power": 143,
                "tuned_power": 220,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1600 bars rail system only) & Bosch MDG1_MD1CP002 & Bosch EDC17C50 & Bosch EDC17C56 & ZF 8HP & Bosch EDC17C41",
                "Engine_code": "B47D20A",
            },
            "518D 136hp (1995cc)": {
                "std_power": 136,
                "tuned_power": 220,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MD1 & Bosch EDC17C50 & Bosch EDC17C41 & ZF 8HP",
                "Engine_code": "B47D20A",
            },
            "518D 150hp (1995cc)": {
                "std_power": 150,
                "tuned_power": 220,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & Bosch EDC17C41 & ZF 8HP",
                "Engine_code": "B47D20A",
            },
            "520D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch MD1 & Bosch EDC17C50 & Bosch EDC17C56 & Bosch EDC17C45 & ZF 8HP",
                "Engine_code": "B47D20A",
            },
            "520D 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MD1 & Bosch EDC17C50 & Bosch EDC17C41 & ZF 8HP50 & Bosch EDC17C45",
                "Engine_code": "B47D20A",
            },
            "520D 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 420,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & ZF 8HP45",
                "Engine_code": "B47D20A",
            },
            "520D 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP45 & Bosch MD1 & ZF 8HP50 & Bosch EDC17C50 & Bosch EDC17C41 & Bosch EDC17C56",
                "Engine_code": "B47D20A",
            },
            "525D 218hp (1995cc)": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP50",
                "Engine_code": "N47D20D",
            },
            "525D 204hp (2993cc)": {
                "std_power": 204,
                "tuned_power": 300,
                "std_torque": 450,
                "tuned_torque": 610,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP45 & Bosch EDC17CP49",
                "Engine_code": "N57D30A",
            },
            "530D 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & Bosch MDG1_MD1CP002 & Bosch EDC17C41 & ZF 8HP",
                "Engine_code": "N57D30A",
            },
            "530D 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & Bosch EDC17C50 & Bosch EDC17C41 & ZF 8HP & Bosch EDC17CP45 & Bosch EDC17CP09",
                "Engine_code": "N57D30A",
            },
            "535D 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 710,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & Bosch EDC17C50 & ZF 8HP70 & Bosch EDC17CP49 & Bosch EDC17C56 & Bosch EDC17CP09",
                "Engine_code": "N57D30B",
            },
            "535D 306hp": {
                "std_power": 306,
                "tuned_power": 340,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & Bosch EDC17CP09 & Bosch EDC17C41 & ZF 8HP & Bosch EDC17CP14",
                "Engine_code": "N57D30B",
            },
            "M 550D - 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 740,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP70 & Bosch EDC17CP49 & Bosch EDC17C41",
                "Engine_code": "N57D30C",
            },
            "528i 258hp": {
                "std_power": 258,
                "tuned_power": 280,
                "std_torque": 310,
                "tuned_torque": 340,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSD87",
                "Engine_code": "N53B30A",
            },
            "535D 299hp": {
                "std_power": 299,
                "tuned_power": 340,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30",
            },
            "535i Active Hybrid 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.9",
                "Engine_code": "N55B30A",
            },
            "520i 184hp": {
                "std_power": 184,
                "tuned_power": 260,
                "std_torque": 270,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.4 & Bosch MEVD17.2.9 & ZF 8HP45 & Bosch EDC17C41 & Bosch MEVD17.2.3",
                "Engine_code": "N20B20B",
            },
            "523i 204hp": {
                "std_power": 204,
                "tuned_power": 265,
                "std_torque": 270,
                "tuned_torque": 315,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSD87.1 & Siemens/Continental MSD81 & Siemens/Continental MSV90 & ZF 8HP",
                "Engine_code": "N53B30",
            },
            "525i 218hp": {
                "std_power": 218,
                "tuned_power": 265,
                "std_torque": 270,
                "tuned_torque": 315,
                "cc": 2996,
                "Type_ecu": "Bosch MEVD17.2.P & Bosch MEVD17.2.9 & Bosch EDC17CP45 & Siemens/Continental MSV90 & Bosch MEVD17.8.4",
                "Engine_code": "N53B30A",
            },
            "528i 245hp": {
                "std_power": 245,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.9 & Bosch MEVD17.2.4 & ZF 8HP & Siemens/Continental MSD87 & Siemens/Continental MSD85 & Siemens/Continental MSV90",
                "Engine_code": "N20B20A",
            },
            "530i 272hp": {
                "std_power": 272,
                "tuned_power": 290,
                "std_torque": 320,
                "tuned_torque": 340,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSD80 & Siemens/Continental MSD87 & Siemens/Continental MSV90",
                "Engine_code": "N52B30A",
            },
            "535i 306hp": {
                "std_power": 306,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.2 & Bosch MEVD17.2.6 & Bosch MEVD17.2.G & ZF 8HP45 & Bosch ME7.2",
                "Engine_code": "N55B30A",
            },
            "550i Valvetronic 449hp": {
                "std_power": 449,
                "tuned_power": 540,
                "std_torque": 650,
                "tuned_torque": 780,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H & ZF 8HP70",
                "Engine_code": "N63B44O1",
            },
            "550i 407hp": {
                "std_power": 407,
                "tuned_power": 470,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85 & ZF 8HP70",
                "Engine_code": "N63B44O0",
            },
            "M5 '30 Jahre Edition' 600hp": {
                "std_power": 600,
                "tuned_power": 680,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H",
                "Engine_code": "S63B44B",
            },
            "M5 Bi-Turbo 560hp": {
                "std_power": 560,
                "tuned_power": 680,
                "std_torque": 680,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MED17 & Bosch MEVD17.2 & Bosch MEV17.2",
                "Engine_code": "S63B44B",
            },
            "M5 Competition 575hp": {
                "std_power": 575,
                "tuned_power": 680,
                "std_torque": 680,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H",
                "Engine_code": "S63B44B",
            },
        },
        "G30/31 - 2016 - 2019": {
            "518d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20",
            },
            "520d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MDG1_MD1CS001 & Bosch MDG1_MD1CP002 & ZF 8HP & Bosch MD1CS003",
                "Engine_code": "B47D20A",
            },
            "520d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001 & ZF 8HP45",
                "Engine_code": "B47D20",
            },
            "520d 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20A",
            },
            "525d 231hp": {
                "std_power": 231,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 575,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CP002",
                "Engine_code": "B47C20B",
            },
            "530d 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MD1CP002 & ZF 8HP70",
                "Engine_code": "B57D30M0",
            },
            "M550d 400hp": {
                "std_power": 400,
                "tuned_power": 460,
                "std_torque": 760,
                "tuned_torque": 880,
                "cc": 2993,
                "Type_ecu": "Bosch MD1 & ZF 8HP",
                "Engine_code": "B57D30C",
            },
            "520i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 290,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201 & ZF 8HP",
                "Engine_code": "B48B20B",
            },
            "520i 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS003 & Bosch MEVD17.2.4 & Bosch MED17.2.9",
                "Engine_code": "B16",
            },
            "530I 252hp": {
                "std_power": 252,
                "tuned_power": 287,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201 & ZF 8HP",
                "Engine_code": "B48B20B",
            },
            "530e IPerformance 252hp": {
                "std_power": 252,
                "tuned_power": 340,
                "std_torque": 420,
                "tuned_torque": 540,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201 & ZF 8HP",
                "Engine_code": "B48B20B",
            },
            "540I 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP50 & Bosch MG1CS201",
                "Engine_code": "B58B30M0",
            },
            "540d 320hp": {
                "std_power": 320,
                "tuned_power": 400,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002 & ZF 8HP & Bosch EDC17CP45",
                "Engine_code": "B57D30",
            },
            "M550I 462hp": {
                "std_power": 462,
                "tuned_power": 560,
                "std_torque": 650,
                "tuned_torque": 830,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "N63B44O2",
            },
            "M550I 530hp": {
                "std_power": 530,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 880,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS002 & Bosch MG1CS201",
                "Engine_code": "N63B44O2",
            },
        },
        "G30/31 LCI - 2020 ->": {
            "520d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B47D20A",
            },
            "520d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20A",
            },
            "530d 286hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "520e 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "520i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 290,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS003",
                "Engine_code": "B48B20B",
            },
            "530I 252hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "530e 292hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "540d 340hp": {
                "std_power": 340,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "B57D30B",
            },
            "540i 333hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "545e 394hp": {
                "std_power": 394,
                "tuned_power": 500,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 2998,
                "Type_ecu": "",
                "Engine_code": "B58D30",
            },
            "M550i 530hp": {
                "std_power": 530,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 880,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "N63B44O2",
            },
        },
    },
    "5 serie GT": {
        "F07 - 2009 - 2017": {
            "520D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C41 & ZF 8HP",
                "Engine_code": "N47D20C",
            },
            "520D 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1800 bars rail system only) & Bosch EDC17C41",
                "Engine_code": "N47D20C",
            },
            "530D 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09 & Bosch EDC17CP45 & ZF 8HP",
                "Engine_code": "N57D30A",
            },
            "530D 211hp": {
                "std_power": 211,
                "tuned_power": 300,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30A",
            },
            "530D 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09 & Bosch EDC17C56 & Bosch EDC17C50 & Bosch EDC17C41",
                "Engine_code": "N57D30A",
            },
            "535D 300hp": {
                "std_power": 300,
                "tuned_power": 360,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09 & ZF 8HPXY",
                "Engine_code": "N57D30B",
            },
            "535D 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 710,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP & Bosch EDC17CP09",
                "Engine_code": "N57D30B",
            },
            "520i 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2.5",
                "Engine_code": "N13B16",
            },
            "535i 306hp": {
                "std_power": 306,
                "tuned_power": 350,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.G & ZF 8HP45Z & Bosch MEVD17.2.6",
                "Engine_code": "N55B30A",
            },
            "550i 407hp": {
                "std_power": 407,
                "tuned_power": 470,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85",
                "Engine_code": "N63B44O0",
            },
            "550i 449hp": {
                "std_power": 449,
                "tuned_power": 470,
                "std_torque": 650,
                "tuned_torque": 700,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.H",
                "Engine_code": "N63B44",
            },
        },
    },
    "6 serie": {
        "E63 - 2004 - 2010": {
            "635D 286hp": {
                "std_power": 286,
                "tuned_power": 332,
                "std_torque": 580,
                "tuned_torque": 685,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C35",
                "Engine_code": "M57D30TU2",
            },
            "635D 306hp": {
                "std_power": 306,
                "tuned_power": 340,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35",
                "Engine_code": "M57D30TU2",
            },
            "630i 258hp": {
                "std_power": 258,
                "tuned_power": 284,
                "std_torque": 300,
                "tuned_torque": 330,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSV70 & Siemens/Continental MSV80",
                "Engine_code": "N52B30",
            },
            "630i 272hp": {
                "std_power": 272,
                "tuned_power": 285,
                "std_torque": 300,
                "tuned_torque": 330,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSV70 & Siemens/Continental MSD80",
                "Engine_code": "N53B30",
            },
            "645ci 333hp": {
                "std_power": 333,
                "tuned_power": 355,
                "std_torque": 450,
                "tuned_torque": 480,
                "cc": 4398,
                "Type_ecu": "Bosch ME9.2",
                "Engine_code": "N62B44",
            },
            "650ci 367hp": {
                "std_power": 367,
                "tuned_power": 390,
                "std_torque": 490,
                "tuned_torque": 525,
                "cc": 4799,
                "Type_ecu": "Bosch ME9.2 M&S",
                "Engine_code": "N62B48",
            },
            "M6 5.0 V10 - 507hp": {
                "std_power": 507,
                "tuned_power": 530,
                "std_torque": 537,
                "tuned_torque": 560,
                "cc": 4999,
                "Type_ecu": "Siemens/Continental MSS65",
                "Engine_code": "S85B50A",
            },
        },
        "F12/F13 - 2011 - 2016": {
            "640D 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 730,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & Bosch EDC17CP09 & ZF 8HP & Bosch EDC17CP49",
                "Engine_code": "N57D30",
            },
            "640i 320hp": {
                "std_power": 320,
                "tuned_power": 365,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.6 & Bosch MEVD17.2.G & Bosch MEVD17.26 & ZF 8HP",
                "Engine_code": "N55HP",
            },
            "650i Valvetronic 449hp": {
                "std_power": 449,
                "tuned_power": 540,
                "std_torque": 650,
                "tuned_torque": 780,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H & ZF 8HP70",
                "Engine_code": "N63B44O1",
            },
            "650i 407hp": {
                "std_power": 407,
                "tuned_power": 470,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85 & ZF 8HP70 & Bosch MEVD17",
                "Engine_code": "N63B44O0",
            },
            "M6 V8 Bi-turbo 560hp": {
                "std_power": 560,
                "tuned_power": 680,
                "std_torque": 680,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MED17 & Bosch MEVD17.2.8 & Temic DKG 436 Gen1",
                "Engine_code": "S63B44TU",
            },
            "M6 V8 Competition Bi-turbo 600hp": {
                "std_power": 600,
                "tuned_power": 680,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H",
                "Engine_code": "S63B44TU",
            },
        },
    },
    "6 serie GT": {
        "G32 - 2017 ->": {
            "620D 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B47D20A",
            },
            "630D 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MD1CP002 & ZF 8HP",
                "Engine_code": "B57D30M0",
            },
            "640D 320hp": {
                "std_power": 320,
                "tuned_power": 400,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch MD1 & ZF 8HP",
                "Engine_code": "B57D30",
            },
            "630i 258hp": {
                "std_power": 258,
                "tuned_power": 278,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS003",
                "Engine_code": "B48B20B",
            },
            "640i 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "B58B30M0",
            },
        },
    },
    "7 serie": {
        "2019 ->": {
            "730d 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 2993,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B57D30M0",
            },
            "730d 286hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "740d 320hp": {
                "std_power": 320,
                "tuned_power": 400,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002",
                "Engine_code": "B57D30",
            },
            "740d 340hp": {
                "std_power": 340,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "B57D30B",
            },
            "750d 400hp": {
                "std_power": 400,
                "tuned_power": 465,
                "std_torque": 760,
                "tuned_torque": 870,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002",
                "Engine_code": "B57D30C",
            },
            "740i 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "",
            },
            "745e 394hp": {
                "std_power": 394,
                "tuned_power": 500,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS002 & ZF 8HP & Bosch MG1CS201 & Bosch MG1CS024",
                "Engine_code": "B58B30",
            },
            "750i 530hp": {
                "std_power": 530,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 880,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "N63B44O2",
            },
            "M760i 585hp": {
                "std_power": 585,
                "tuned_power": 700,
                "std_torque": 850,
                "tuned_torque": 1000,
                "cc": 6592,
                "Type_ecu": "Bosch MG1CS201 & ZF 8HP75Z",
                "Engine_code": "N74B66TU",
            },
        },
        "E38 - 1995 - 2001": {
            "725 TDS 143hp": {
                "std_power": 143,
                "tuned_power": 175,
                "std_torque": 280,
                "tuned_torque": 345,
                "cc": 2498,
                "Type_ecu": "Bosch DDE3.0",
                "Engine_code": "M51D25",
            },
            "730D 184hp": {
                "std_power": 184,
                "tuned_power": 212,
                "std_torque": 390,
                "tuned_torque": 450,
                "cc": 2926,
                "Type_ecu": "Bosch DDE4.0",
                "Engine_code": "M57D29",
            },
            "730D 193hp": {
                "std_power": 193,
                "tuned_power": 221,
                "std_torque": 410,
                "tuned_torque": 480,
                "cc": 2926,
                "Type_ecu": "Bosch DDE4.0",
                "Engine_code": "M57D29",
            },
            "740D 245hp": {
                "std_power": 245,
                "tuned_power": 274,
                "std_torque": 560,
                "tuned_torque": 630,
                "cc": 3901,
                "Type_ecu": "Bosch DDE4.0",
                "Engine_code": "398D1",
            },
            "728i 193hp": {
                "std_power": 193,
                "tuned_power": 204,
                "std_torque": 280,
                "tuned_torque": 300,
                "cc": 2793,
                "Type_ecu": "Siemens/Continental MS41 & Siemens/Continental MS42",
                "Engine_code": "286S1",
            },
            "730i 218hp": {
                "std_power": 218,
                "tuned_power": 241,
                "std_torque": 290,
                "tuned_torque": 323,
                "cc": 2997,
                "Type_ecu": "Siemens/Continental MSD80",
                "Engine_code": "308S1",
            },
            "735i 238hp": {
                "std_power": 238,
                "tuned_power": 253,
                "std_torque": 345,
                "tuned_torque": 372,
                "cc": 3498,
                "Type_ecu": "Bosch ME7.2 & Bosch M5.2.1",
                "Engine_code": "358S2",
            },
        },
        "E65 - 2002 - 2008": {
            "730D 218hp": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C35 & Bosch EDC16C10",
                "Engine_code": "306D2",
            },
            "730D 231hp": {
                "std_power": 231,
                "tuned_power": 280,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35 & Bosch EDC17CP09",
                "Engine_code": "306D2",
            },
            "730D 204hp": {
                "std_power": 204,
                "tuned_power": 250,
                "std_torque": 410,
                "tuned_torque": 500,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "306D2",
            },
            "730D 211hp": {
                "std_power": 211,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C35",
                "Engine_code": "306D2",
            },
            "740D 258hp": {
                "std_power": 258,
                "tuned_power": 300,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 3901,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "398D1",
            },
            "745D 300hp": {
                "std_power": 300,
                "tuned_power": 340,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 4423,
                "Type_ecu": "Bosch EDC16CP35 M&S",
                "Engine_code": "448D1",
            },
            "740i 306hp": {
                "std_power": 306,
                "tuned_power": 335,
                "std_torque": 390,
                "tuned_torque": 425,
                "cc": 4000,
                "Type_ecu": "Bosch ME9.2 & Siemens/Continental MSD85",
                "Engine_code": "N62B40A",
            },
            "745i 333hp": {
                "std_power": 333,
                "tuned_power": 353,
                "std_torque": 450,
                "tuned_torque": 470,
                "cc": 4398,
                "Type_ecu": "Bosch ME9.2",
                "Engine_code": "N62B44A",
            },
            "750i 367hp": {
                "std_power": 367,
                "tuned_power": 394,
                "std_torque": 490,
                "tuned_torque": 520,
                "cc": 4799,
                "Type_ecu": "Bosch MEV946",
                "Engine_code": "N62B48B",
            },
            "760i 445hp": {
                "std_power": 445,
                "tuned_power": 462,
                "std_torque": 600,
                "tuned_torque": 630,
                "cc": 5972,
                "Type_ecu": "Bosch ME9.2",
                "Engine_code": "N73B60A",
            },
        },
        "F01 - 2009 - 2015": {
            "730D 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 520,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09 & Bosch EDC17CP45",
                "Engine_code": "N57D30A",
            },
            "730D 211hp": {
                "std_power": 211,
                "tuned_power": 300,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & Bosch EDC17CP09",
                "Engine_code": "N57D30A",
            },
            "730D 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & Bosch EDC17CP09",
                "Engine_code": "N57D30A",
            },
            "740D 306hp": {
                "std_power": 306,
                "tuned_power": 360,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP09",
                "Engine_code": "N57D30A",
            },
            "740D 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 760,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP",
                "Engine_code": "N57D30A",
            },
            "750D 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 740,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "B57",
            },
            "740i 326hp": {
                "std_power": 326,
                "tuned_power": 370,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD87 & Bosch MEVD17.2.6 & Bosch MEVD17.2.G & ZF 8HP",
                "Engine_code": "N55B30A",
            },
            "750i Valvetronic 510hp": {
                "std_power": 510,
                "tuned_power": 540,
                "std_torque": 750,
                "tuned_torque": 780,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H",
                "Engine_code": "N63B44B",
            },
            "750i 407hp": {
                "std_power": 407,
                "tuned_power": 520,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85",
                "Engine_code": "N63B44O0",
            },
            "750i 450hp": {
                "std_power": 450,
                "tuned_power": 540,
                "std_torque": 650,
                "tuned_torque": 780,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H & ZF 8HP70 & Siemens/Continental MSD85",
                "Engine_code": "N63B44O1",
            },
            "760i 544hp": {
                "std_power": 544,
                "tuned_power": 600,
                "std_torque": 750,
                "tuned_torque": 900,
                "cc": 5972,
                "Type_ecu": "Siemens/Continental MSD87",
                "Engine_code": "N74B60",
            },
        },
        "G11/G12 - 2016 - 2018": {
            "730d 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002",
                "Engine_code": "B57D30M0",
            },
            "740d 320hp": {
                "std_power": 320,
                "tuned_power": 400,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch MD1 & ZF 8HP",
                "Engine_code": "B57D30",
            },
            "750d 400hp": {
                "std_power": 400,
                "tuned_power": 460,
                "std_torque": 760,
                "tuned_torque": 880,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002 & ZF 8HP",
                "Engine_code": "B57D30C",
            },
            "730i - 258hp": {
                "std_power": 258,
                "tuned_power": 287,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "B48B20",
            },
            "740e IPerformance 326hp": {
                "std_power": 326,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "B48B20B",
            },
            "740i 326hp": {
                "std_power": 326,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "B58B30M0",
            },
            "750i 450hp": {
                "std_power": 450,
                "tuned_power": 540,
                "std_torque": 650,
                "tuned_torque": 780,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "N63B44O2",
            },
            "M760i 610hp": {
                "std_power": 610,
                "tuned_power": 680,
                "std_torque": 800,
                "tuned_torque": 920,
                "cc": 6592,
                "Type_ecu": "Bosch MG1CS003 & ZF 8HP",
                "Engine_code": "N74B66TU",
            },
        },
    },
    "8 serie": {
        "G15 - 2018 ->": {
            "840d 320hp": {
                "std_power": 320,
                "tuned_power": 400,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch MD1CP002",
                "Engine_code": "B57D30",
            },
            "840d 340hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "840i 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "B58B30",
            },
            "M850i 530hp": {
                "std_power": 530,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 880,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS001",
                "Engine_code": "N63B44T3",
            },
        },
    },
    "8 serie GC": {
        "2019 ->": {
            "840d 320hp": {
                "std_power": 320,
                "tuned_power": 400,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B57D30",
            },
            "840i 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B58B30",
            },
            "850i 530hp": {
                "std_power": 530,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 880,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201 & Bosch MDG1",
                "Engine_code": "N63B44T3",
            },
        },
    },
    "M2": {
        "F87 - 2015 ->": {
            "3.0 CS 450hp": {
                "std_power": 450,
                "tuned_power": 500,
                "std_torque": 550,
                "tuned_torque": 680,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.G",
                "Engine_code": "N55B30T0",
            },
            "3.0 Competition 410hp": {
                "std_power": 410,
                "tuned_power": 500,
                "std_torque": 550,
                "tuned_torque": 680,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.G & Temic DKG 436 Gen2",
                "Engine_code": "N55B30T0",
            },
            "3.0 370hp": {
                "std_power": 370,
                "tuned_power": 410,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.2.6 & Bosch MEVD17.2.G & Temic DKG 436 Gen2",
                "Engine_code": "N55B30T0",
            },
        },
    },
    "M3": {
        "E36 - 1991 - 1998": {
            "M3 321hp": {
                "std_power": 321,
                "tuned_power": 350,
                "std_torque": 350,
                "tuned_torque": 380,
                "cc": 3201,
                "Type_ecu": "Siemens/Continental MS41 & Siemens/Continental MSS50",
                "Engine_code": "326S1",
            },
            "M3 286hp": {
                "std_power": 286,
                "tuned_power": 306,
                "std_torque": 320,
                "tuned_torque": 345,
                "cc": 2990,
                "Type_ecu": "Siemens/Continental MS41 & Siemens/Continental MSS50",
                "Engine_code": "306S",
            },
        },
        "E46 - 1998 - 2005": {
            "M3 CSL 360hp": {
                "std_power": 360,
                "tuned_power": 380,
                "std_torque": 370,
                "tuned_torque": 390,
                "cc": 3246,
                "Type_ecu": "Siemens/Continental MSS54",
                "Engine_code": "326S4",
            },
            "M3 343hp": {
                "std_power": 343,
                "tuned_power": 365,
                "std_torque": 365,
                "tuned_torque": 390,
                "cc": 3246,
                "Type_ecu": "Siemens/Continental MSS52 & Siemens/Continental MSS54",
                "Engine_code": "326S4",
            },
        },
        "E90 - 2005 - 2011": {
            "4.0 V8 - 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 3999,
                "Type_ecu": "Siemens/Continental MSS60",
                "Engine_code": "S65B40A",
            },
        },
        "F80 - 2011 - 2020": {
            "M3 CS 460hp": {
                "std_power": 460,
                "tuned_power": 500,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2797,
                "Type_ecu": "Bosch MEVD17.2.G",
                "Engine_code": "S55B30A",
            },
            "M3 Competition 450hp": {
                "std_power": 450,
                "tuned_power": 500,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2797,
                "Type_ecu": "Bosch MEVD17.2.G & Temic DKG 436 Gen2",
                "Engine_code": "S55B30A",
            },
            "M3 431hp": {
                "std_power": 431,
                "tuned_power": 500,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2797,
                "Type_ecu": "Bosch MEVD17.2.G & Temic DKG 436 Gen2",
                "Engine_code": "S55B30A",
            },
        },
        "G80 - 2021 ->": {
            "3.0 CS 550hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0 Competition 510hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0 480hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "M4": {
        "F82 - 2014 ->": {
            "3.0 - 431hp": {
                "std_power": 431,
                "tuned_power": 500,
                "std_torque": 550,
                "tuned_torque": 680,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.G & Getrag DKG",
                "Engine_code": "S55B30A",
            },
            "3.0 CS 460hp": {
                "std_power": 460,
                "tuned_power": 500,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.G",
                "Engine_code": "S55B30A",
            },
            "3.0 Competition 450hp": {
                "std_power": 450,
                "tuned_power": 500,
                "std_torque": 550,
                "tuned_torque": 680,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.G & Temic DKG 436 Gen2",
                "Engine_code": "S55B30A",
            },
            "3.0 GTS 500hp": {
                "std_power": 500,
                "tuned_power": 530,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.G",
                "Engine_code": "S55B30A",
            },
        },
        "G82 - 2021 ->": {
            "3.0 Competition 510hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0 480hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "M5": {
        "E39 - 1995 - 2003": {
            "5.0 V8 400hp": {
                "std_power": 400,
                "tuned_power": 430,
                "std_torque": 500,
                "tuned_torque": 535,
                "cc": 4941,
                "Type_ecu": "Siemens/Continental MSS52",
                "Engine_code": "508S1",
            },
        },
        "E60 - 2003 - 2010": {
            "V10 507hp": {
                "std_power": 507,
                "tuned_power": 535,
                "std_torque": 520,
                "tuned_torque": 550,
                "cc": 4999,
                "Type_ecu": "Siemens/Continental MSS65",
                "Engine_code": "S85B50A",
            },
        },
        "F10 - 2010 - 2016": {
            "V8 Bi-turbo '30 Jahre Edition' 600hp": {
                "std_power": 600,
                "tuned_power": 680,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H",
                "Engine_code": "S63B44T0",
            },
            "V8 Bi-turbo Competition 575hp": {
                "std_power": 575,
                "tuned_power": 680,
                "std_torque": 680,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H",
                "Engine_code": "S63B44T0",
            },
            "V8 Bi-turbo 560hp": {
                "std_power": 560,
                "tuned_power": 680,
                "std_torque": 680,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H",
                "Engine_code": "S63B44T0",
            },
        },
        "F90 - 2017 - 2019": {
            "3.0 D Bi-Turbo 400hp": {
                "std_power": 400,
                "tuned_power": 435,
                "std_torque": 760,
                "tuned_torque": 820,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "B57D30C",
            },
            "4.4 V8 Bi-Turbo Competition 625hp": {
                "std_power": 625,
                "tuned_power": 680,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201 & ZF 8HP75Z",
                "Engine_code": "S63B44T4",
            },
            "4.4 V8 Bi-Turbo 602hp": {
                "std_power": 602,
                "tuned_power": 680,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201 & ZF 8HP75Z",
                "Engine_code": "S63B44T4",
            },
            "4.4 V8 Bi-Turbo 462hp": {
                "std_power": 462,
                "tuned_power": 495,
                "std_torque": 650,
                "tuned_torque": 710,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.H & Bosch MG1CS201",
                "Engine_code": "S63B44T4",
            },
        },
        "G30 - 2020 ->": {
            "4.4 V8 Bi-Turbo Competition 625hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "4.4 V8 Bi-Turbo 602hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "M6": {
        "E63/E64 - 2003 - 2012": {
            "V10 507hp": {
                "std_power": 507,
                "tuned_power": 535,
                "std_torque": 520,
                "tuned_torque": 550,
                "cc": 4999,
                "Type_ecu": "Siemens/Continental MSS65",
                "Engine_code": "S85B50A",
            },
        },
        "F06/F12/F13 - 2011 ->": {
            "V8 4.4 Bi-Turbo Competition 600hp": {
                "std_power": 600,
                "tuned_power": 680,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H & Temic DKG 346",
                "Engine_code": "S63B44T0",
            },
            "V8 4.4 Bi-Turbo 560hp": {
                "std_power": 560,
                "tuned_power": 680,
                "std_torque": 680,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H & Temic DKG 436 Gen1",
                "Engine_code": "S63B44T0",
            },
        },
    },
    "M8": {
        "2019 ->": {
            "4.4 V8 Bi-Turbo Competition 625hp": {
                "std_power": 625,
                "tuned_power": 680,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "S63B44B",
            },
            "4.4 V8 Bi-Turbo 600hp": {
                "std_power": 600,
                "tuned_power": 680,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "S63B44",
            },
        },
    },
    "X1": {
        "(F48 LCI) - 2019 -> ...": {
            "sDrive16d 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch MD1CP002 & Bosch MD1CS003 & Bosch MD1CS001",
                "Engine_code": "B37C15U0",
            },
            "sDrive18d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47C20U0",
            },
            "sDrive20d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47C20O1",
            },
            "xDrive18d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47C20U0",
            },
            "xDrive20d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B47C20O1",
            },
            "xDrive25d 231hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "sDrive18i 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1499,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B38A15M0",
            },
            "sDrive20i 192hp": {
                "std_power": 192,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B48A20M1",
            },
            "xDrive20i 192hp": {
                "std_power": 192,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B48A20M1",
            },
            "xDrive25e 220hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "xDrive25i 231hp": {
                "std_power": 231,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MG1CS201",
                "Engine_code": "B48B20",
            },
        },
        "2022 -> ...": {
            "M35i 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "sDrive23d 197hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "sDrive18d 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "sDrive18i 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "xDrive23i 218hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "xDrive25e 245hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "xDrive30e 326hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "E84 - 2009 - 2012": {
            "1.8D 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C06 & Bosch EDC17C41",
                "Engine_code": "N47D20C",
            },
            "1.8D 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C06",
                "Engine_code": "N47D20C",
            },
            "2.0D 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17",
                "Engine_code": "N47D20C",
            },
            "2.0D 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & Bosch EDC17CP02 & Bosch EDC17C41",
                "Engine_code": "N47D20C",
            },
            "2.3D 204hp": {
                "std_power": 204,
                "tuned_power": 260,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP02 & Bosch EDC16C41",
                "Engine_code": "N47D20C",
            },
            "1.8i 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1995,
                "Type_ecu": "Bosch MEV1746 & Siemens/Continental MSD81 & Bosch MEV17.1.2",
                "Engine_code": "N46B20E",
            },
            "1.8i 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1995,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MEV17.4.6",
                "Engine_code": "N46B20E",
            },
        },
        "E84 - 2012 - 2015": {
            "16d 116hp (1995cc)": {
                "std_power": 116,
                "tuned_power": 190,
                "std_torque": 260,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C41",
                "Engine_code": "N47D20C",
            },
            "18d 143hp (1995cc)": {
                "std_power": 143,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1600 bars rail system only) & Bosch EDC17C06 & Bosch EDC17C41 & Bosch EDC17C50 & Bosch EDC17C86",
                "Engine_code": "N47D20C",
            },
            "2.0D 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1800 bars rail system only) & Bosch EDC17C50",
                "Engine_code": "N47D20C",
            },
            "2.0D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1800 bars rail system only) & Bosch EDC17C41 & Bosch EDC17C50",
                "Engine_code": "N47D20C",
            },
            "2.5D 218hp (1995cc)": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N47D20D",
            },
            "1.8i 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1995,
                "Type_ecu": "Bosch MEV1746 & Bosch MEVD17.2.4",
                "Engine_code": "N46B20E",
            },
            "2.0i 184hp": {
                "std_power": 184,
                "tuned_power": 260,
                "std_torque": 270,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch MEVD17.2.4 & Bosch MEVD17.2.9 & Bosch EDC17C41 & Bosch MEVD17.2.1",
                "Engine_code": "N20B20A",
            },
            "2.8i 245hp": {
                "std_power": 245,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.4 & Bosch MEVD17.2.9 & Bosch MED17.1.1",
                "Engine_code": "N20B20A",
            },
        },
        "F48 - 2015 - 2018": {
            "sDrive16d 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B37C15U0",
            },
            "sDrive18d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B47C20A",
            },
            "xDrive18d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B47C20A",
            },
            "xDrive20d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & Bosch EDC17C46 & Bosch EDC17C41",
                "Engine_code": "B47C20B",
            },
            "xDrive25d 231hp": {
                "std_power": 231,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP49",
                "Engine_code": "B47C20B",
            },
            "sDrive18i 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.5 & Bosch MEVD17.2.3 & Bosch MG1CS201",
                "Engine_code": "B38A15A",
            },
            "sDrive20i 192hp": {
                "std_power": 192,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "B48A20A",
            },
            "xDrive20i 192hp": {
                "std_power": 192,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS201",
                "Engine_code": "B48A20M0",
            },
            "xDrive25i 231hp": {
                "std_power": 231,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B48A20B",
            },
        },
    },
    "X2": {
        "2018 ->": {
            "s16d 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "",
            },
            "s18d / x18d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch MDG1_MD1CS001 & Bosch MD1CS001 & Bosch MD1CS002",
                "Engine_code": "B47C20A",
            },
            "x20d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B47D20A",
            },
            "x25d 231hp": {
                "std_power": 231,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 550,
                "cc": 1995,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B47D20B",
            },
            "M35i 306hp": {
                "std_power": 306,
                "tuned_power": 335,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B48A20M1",
            },
            "s18i 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MG1CS201 & Bosch MEVD17.2.3",
                "Engine_code": "B38A15M0",
            },
            "s20i / x20i 192hp": {
                "std_power": 192,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B48A20A",
            },
            "x25e 220hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "X3": {
        "E83 - 2004 - 2010": {
            "1.8D 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C06 & Bosch EDC17CP02",
                "Engine_code": "N47D20C",
            },
            "1.8D 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C06 & Bosch EDC17CP02",
                "Engine_code": "N47D20C",
            },
            "2.0D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C35 & Bosch EDC16C31 & Bosch EDC16C10",
                "Engine_code": "204D4",
            },
            "2.0D 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C35 & Bosch EDC17CP02",
                "Engine_code": "204D4",
            },
            "2.0D 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP02 & Bosch EDC17C06",
                "Engine_code": "204D4",
            },
            "3.0D 204hp": {
                "std_power": 204,
                "tuned_power": 250,
                "std_torque": 410,
                "tuned_torque": 500,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "306D2",
            },
            "3.0D 218hp": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35",
                "Engine_code": "306D2",
            },
            "3.0D 211hp": {
                "std_power": 211,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35 & Bosch EDC16C31",
                "Engine_code": "306D2",
            },
            "3.5D 286hp": {
                "std_power": 286,
                "tuned_power": 332,
                "std_torque": 580,
                "tuned_torque": 685,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35",
                "Engine_code": "306D5",
            },
            "2.0i 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1995,
                "Type_ecu": "Bosch ME9.2",
                "Engine_code": "N46B20B",
            },
            "2.5i 214hp": {
                "std_power": 214,
                "tuned_power": 231,
                "std_torque": 250,
                "tuned_torque": 271,
                "cc": 2494,
                "Type_ecu": "Siemens/Continental MS45 & Siemens/Continental MSV80.1",
                "Engine_code": "256S5",
            },
            "3.0i 231hp": {
                "std_power": 231,
                "tuned_power": 245,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MS45 & Siemens/Continental MSD80",
                "Engine_code": "306S3",
            },
        },
        "F25 - 2011 - 2017": {
            "1.8D 143hp": {
                "std_power": 143,
                "tuned_power": 220,
                "std_torque": 320,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1600 bars rail system only) & Bosch MD1 & Bosch EDC17C50 & Bosch EDC17C41",
                "Engine_code": "B47D20A",
            },
            "1.8D 136hp": {
                "std_power": 136,
                "tuned_power": 220,
                "std_torque": 320,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1600 bars rail system only) & Bosch MD1 & Bosch EDC17C50",
                "Engine_code": "B47D20A",
            },
            "1.8D 150hp": {
                "std_power": 150,
                "tuned_power": 220,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1",
                "Engine_code": "B47D20A",
            },
            "2.0D 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch MD1 & Bosch EDC17C50 & Bosch EDC17C56 & ZF 8HP45",
                "Engine_code": "B47D20A",
            },
            "2.0D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 (1800 bars rail system only) & Bosch MD1 & Bosch EDC17C56 & ZF 8HP50Z & Bosch EDC17C41 & Bosch EDC17C50 & Bosch EDC17C76",
                "Engine_code": "B47D20A",
            },
            "2.0D 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17 & ZF 8HP50Z & Bosch EDC17C41 & Bosch EDC17C50",
                "Engine_code": "B47D20A",
            },
            "3.0D 211hp": {
                "std_power": 211,
                "tuned_power": 300,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP",
                "Engine_code": "N57D30A",
            },
            "3.0D 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30A",
            },
            "3.0D 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & Bosch EDC17C41 & ZF 8HP & Bosch EDC17C50",
                "Engine_code": "N57D30A",
            },
            "3.5D 313hp": {
                "std_power": 313,
                "tuned_power": 360,
                "std_torque": 630,
                "tuned_torque": 700,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & ZF 8HP",
                "Engine_code": "N57D30B",
            },
            "2.0i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 270,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MEVD17.2.4 & Bosch MEVD17.2.9 & Bosch MED17.2.8 & Bosch MG1CS003",
                "Engine_code": "N20B20",
            },
            "2.8i Turbo 245hp": {
                "std_power": 245,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental MSV80 & Bosch MEVD17.2.9 & Bosch MEVD17.2.4 & Siemens/Continental MSV90 & ZF 8HP",
                "Engine_code": "N20B20A",
            },
            "3.5i 306hp": {
                "std_power": 306,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MEVD17.2.6 & Bosch MEVD17.2.G & ZF 8HP",
                "Engine_code": "N55B30A",
            },
        },
        "G01/08 - 2017 - 2020": {
            "xDrive M40d 326hp": {
                "std_power": 326,
                "tuned_power": 400,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002",
                "Engine_code": "B57D30",
            },
            "xDrive M40d 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "B57D30",
            },
            "xDrive18d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MDG1_MD1CS001",
                "Engine_code": "B47D20O0",
            },
            "xDrive20d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MDG1_MD1CS001 & ZF 8HP",
                "Engine_code": "B47D20A",
            },
            "xDrive20d 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch MD1 & ZF 8HP",
                "Engine_code": "B47D20A",
            },
            "xDrive20d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20B",
            },
            "xDrive25d 231hp": {
                "std_power": 231,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CP002",
                "Engine_code": "B47D20",
            },
            "xDrive30d 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & Bosch MDG1_MD1CP002 & ZF 8HP",
                "Engine_code": "B57D30A",
            },
            "xDrive30d 286hp": {
                "std_power": 286,
                "tuned_power": 330,
                "std_torque": 650,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "B57D30B",
            },
            "30e 252hp": {
                "std_power": 252,
                "tuned_power": 340,
                "std_torque": 420,
                "tuned_torque": 550,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "",
            },
            "M40i 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS024 & ZF 8HP50 & Bosch MG1CS201",
                "Engine_code": "B58B30M0",
            },
            "sDrive20i 170hp": {
                "std_power": 170,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1597,
                "Type_ecu": "Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "B48B16A",
            },
            "xDrive20i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 290,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2.P & Bosch MG1CS003 & Bosch MG1CS201 & ZF 8HP & Bosch MG1CS024",
                "Engine_code": "B48B20B",
            },
            "xDrive30e 292hp": {
                "std_power": 292,
                "tuned_power": 400,
                "std_torque": 420,
                "tuned_torque": 700,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B48B20",
            },
            "xDrive30i 252hp": {
                "std_power": 252,
                "tuned_power": 287,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "ZF 8HP50 & Bosch MG1CS003 & Bosch MG1CS201",
                "Engine_code": "B46B20B / B48B20B",
            },
        },
        "G01/08 LCI - 2021 -> ...": {
            "M40d 340hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "sDrive18d 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "xDrive20d 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "xDrive30d 286hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "M40i 360hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "xDrive20i 184hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "xDrive30e 292hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "xDrive30i 245hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "X3 M": {
        "2019 ->": {
            "3.0 xDrive Steptronic Competition 510hp": {
                "std_power": 510,
                "tuned_power": 575,
                "std_torque": 600,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MG1CS024 & Bosch MG1CS049 & Bosch MG1CS003",
                "Engine_code": "S58B30",
            },
            "3.0 xDrive Steptronic 480hp": {
                "std_power": 480,
                "tuned_power": 575,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch MG1CS024 & Bosch MG1CS003",
                "Engine_code": "S58B30",
            },
        },
    },
    "X4": {
        "F26 - 2014 - 2017": {
            "2.0d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1 & Bosch EDC17C41 & Bosch EDC17C56 & ZF 8HP",
                "Engine_code": "B47D20A",
            },
            "3.0d 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & Bosch EDC17C41 & Bosch EDC17C50 & ZF 8HP",
                "Engine_code": "N57D30A",
            },
            "3.0d 211hp": {
                "std_power": 211,
                "tuned_power": 300,
                "std_torque": 560,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56",
                "Engine_code": "",
            },
            "3.5d 313hp": {
                "std_power": 313,
                "tuned_power": 360,
                "std_torque": 630,
                "tuned_torque": 760,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP49 & ZF 8HP & Bosch EDC17CP45",
                "Engine_code": "N57D30B",
            },
            "2.0i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 270,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.9 & ZF 8HP & Bosch MEVD17.2.4",
                "Engine_code": "N20B20A",
            },
            "2.8i 245hp": {
                "std_power": 245,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.9 & ZF 8HP",
                "Engine_code": "N20B20A",
            },
            "3.5i 306hp": {
                "std_power": 306,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.6 & Bosch MEVD17.2.G & ZF 8HP45",
                "Engine_code": "N55B30A",
            },
            "M40i 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HP",
                "Engine_code": "N55B30A",
            },
        },
        "G02 - 2018 ->": {
            "M40d 326hp": {
                "std_power": 326,
                "tuned_power": 380,
                "std_torque": 680,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B57D30",
            },
            "M40d 340hp": {
                "std_power": 340,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "B57D30",
            },
            "xDrive20d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch MD1 & ZF 8HP",
                "Engine_code": "B47D20A",
            },
            "xDrive20d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "B47D20",
            },
            "xDrive25d 231hp": {
                "std_power": 231,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1995,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B47D20B",
            },
            "xDrive30d 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 620,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B57D30M0",
            },
            "xDrive30d 286hp": {
                "std_power": 286,
                "tuned_power": 330,
                "std_torque": 650,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "B57D30M0",
            },
            "M40i 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS003 & Bosch MEVD17.2.G & Bosch MG1CS201 & ZF 8HP",
                "Engine_code": "B58B30M0",
            },
            "xDrive20i 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 290,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201 & ZF 8HP & Bosch MG1CS003",
                "Engine_code": "B48B20A",
            },
            "xDrive30i 252hp": {
                "std_power": 252,
                "tuned_power": 287,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201 & ZF 8HP & Bosch MG1CS003",
                "Engine_code": "B48B20B",
            },
        },
    },
    "X4 M": {
        "2019 ->": {
            "3.0 xDrive Steptronic Competition 510hp": {
                "std_power": 510,
                "tuned_power": 575,
                "std_torque": 600,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MG1CS024 & Bosch MG1CS201",
                "Engine_code": "S58B30",
            },
            "3.0 xDrive Steptronic 480hp": {
                "std_power": 480,
                "tuned_power": 575,
                "std_torque": 600,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MG1CS024",
                "Engine_code": "S58B30",
            },
        },
    },
    "X5": {
        "E53 - 2000 - 2007": {
            "3.0D 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 410,
                "tuned_torque": 480,
                "cc": 2926,
                "Type_ecu": "Bosch DDE3.0 & Bosch DDE4.0 & Bosch EDC15C4",
                "Engine_code": "306D1",
            },
            "3.0D 218hp": {
                "std_power": 218,
                "tuned_power": 260,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2926,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C35 & Bosch EDC16C10",
                "Engine_code": "306D1",
            },
            "3.0i 231hp": {
                "std_power": 231,
                "tuned_power": 246,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MS43",
                "Engine_code": "306S3",
            },
            "3.0i 272hp": {
                "std_power": 272,
                "tuned_power": 295,
                "std_torque": 315,
                "tuned_torque": 348,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSV80",
                "Engine_code": "306S3",
            },
            "4.4i 286hp": {
                "std_power": 286,
                "tuned_power": 300,
                "std_torque": 440,
                "tuned_torque": 460,
                "cc": 4398,
                "Type_ecu": "Bosch ME7.2",
                "Engine_code": "448S2",
            },
            "4.4i 320hp": {
                "std_power": 320,
                "tuned_power": 335,
                "std_torque": 440,
                "tuned_torque": 460,
                "cc": 4398,
                "Type_ecu": "Bosch ME9.2",
                "Engine_code": "448S2",
            },
            "4.6i 347hp": {
                "std_power": 347,
                "tuned_power": 360,
                "std_torque": 480,
                "tuned_torque": 500,
                "cc": 4619,
                "Type_ecu": "Bosch ME7.2",
                "Engine_code": "468S1",
            },
        },
        "E70 - 2007 - 2010": {
            "3.0D 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16C35 & Bosch EDC16CP35",
                "Engine_code": "306D3",
            },
            "3.0D 235hp": {
                "std_power": 235,
                "tuned_power": 280,
                "std_torque": 520,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35 & ZF 6HP",
                "Engine_code": "306D3",
            },
            "3.0D 286hp": {
                "std_power": 286,
                "tuned_power": 340,
                "std_torque": 580,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35 & Bosch EDC17CP09",
                "Engine_code": "306D5",
            },
            "3.0i 272hp": {
                "std_power": 272,
                "tuned_power": 285,
                "std_torque": 315,
                "tuned_torque": 335,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSV80 & Siemens/Continental MSD80",
                "Engine_code": "N52B30AF",
            },
            "4.8i 355hp": {
                "std_power": 355,
                "tuned_power": 385,
                "std_torque": 475,
                "tuned_torque": 500,
                "cc": 4799,
                "Type_ecu": "Bosch ME9.2",
                "Engine_code": "N62B48B",
            },
            "M 555hp": {
                "std_power": 555,
                "tuned_power": 610,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85",
                "Engine_code": "S63B44A",
            },
        },
        "E70 - 2010 - 2013": {
            "3.0D 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & Bosch EDC17CP09 & Bosch EDC17C41",
                "Engine_code": "N57D30A",
            },
            "3.0D 211hp": {
                "std_power": 211,
                "tuned_power": 300,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & Bosch EDC16CP35",
                "Engine_code": "N57D30A",
            },
            "3.5D 286hp": {
                "std_power": 286,
                "tuned_power": 340,
                "std_torque": 580,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35 & Bosch EDC17CP09",
                "Engine_code": "M57D30T2",
            },
            "4.0D 306hp": {
                "std_power": 306,
                "tuned_power": 360,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & Bosch EDC17CP09 & Bosch EDC17CP49 & Bosch EDC17C41 & ZF 8HP75",
                "Engine_code": "N57D30B",
            },
            "M 5.0D 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 740,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30C",
            },
            "3.5i 306hp": {
                "std_power": 306,
                "tuned_power": 370,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.6 & Bosch EDC17CP09 & Siemens/Continental MSD81 & Bosch MEVD17.1.1",
                "Engine_code": "N55B30A",
            },
            "5.0i 407hp": {
                "std_power": 407,
                "tuned_power": 470,
                "std_torque": 600,
                "tuned_torque": 710,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85",
                "Engine_code": "N63B44O0",
            },
            "M 555hp": {
                "std_power": 555,
                "tuned_power": 610,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85",
                "Engine_code": "S63B44A",
            },
        },
        "F15 - 2013 - 2018": {
            "2.5D 218hp": {
                "std_power": 218,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP49 & Bosch EDC17CP45 & Bosch EDC17C56",
                "Engine_code": "B47D20B",
            },
            "2.5D 231hp": {
                "std_power": 231,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP49",
                "Engine_code": "B47D20B",
            },
            "3.0D 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C76 & Bosch EDC17C50 & Bosch EDC17C41 & Bosch EDC17C56 & ZF 8HP70 & Bosch EDC17CP49",
                "Engine_code": "N57D30A",
            },
            "4.0D 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 760,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP49 & ZF 8HP75Z & Bosch EDC17C56 & Bosch EDC17CP45",
                "Engine_code": "N57D30B",
            },
            "M 5.0D 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 740,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP49 & ZF 70HP & Bosch EDC17CP45 & ZF 8HP",
                "Engine_code": "N57D30C",
            },
            "xDrive 25d 231hp": {
                "std_power": 231,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17CP49 & Bosch EDC17CP50",
                "Engine_code": "B47D20B",
            },
            "2.8i 245hp": {
                "std_power": 245,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.9",
                "Engine_code": "N20B20A",
            },
            "3.5i 306hp": {
                "std_power": 306,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.G & ZF 8HP45",
                "Engine_code": "N55B30A",
            },
            "5.0i 450hp": {
                "std_power": 450,
                "tuned_power": 540,
                "std_torque": 650,
                "tuned_torque": 780,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.H & Bosch MEVD17.2.8 & ZF 8HP",
                "Engine_code": "N63B44O1",
            },
            "xDrive40e Hybrid 313hp": {
                "std_power": 313,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 630,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.4 & Bosch MEVD17.2.9 & ZF 8HP75Z & Bosch EDC17",
                "Engine_code": "N20B20A",
            },
        },
        "G05 - 2018 ->": {
            "xDrive M50d 400hp": {
                "std_power": 400,
                "tuned_power": 430,
                "std_torque": 760,
                "tuned_torque": 820,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002",
                "Engine_code": "B57D30C",
            },
            "xDrive25d 231hp": {
                "std_power": 231,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CP032_TC299",
                "Engine_code": "B47B20",
            },
            "xDrive30d 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MDG1_MD1CP002",
                "Engine_code": "B57D30M0",
            },
            "xDrive30d 286hp": {
                "std_power": 286,
                "tuned_power": 330,
                "std_torque": 650,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MD1CP002",
                "Engine_code": "B57D30B",
            },
            "xDrive40d 340hp": {
                "std_power": 340,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2998,
                "Type_ecu": "Bosch MD1CP032 & Bosch MD1CP002",
                "Engine_code": "B58B30TU1",
            },
            "M50i 530hp": {
                "std_power": 530,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 880,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "N63B44T3",
            },
            "xDrive40i 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B58B30M1",
            },
            "xDrive45e 394hp": {
                "std_power": 394,
                "tuned_power": 500,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS024 & ZF 8HP",
                "Engine_code": "B58B30",
            },
            "xDrive50i 462hp": {
                "std_power": 462,
                "tuned_power": 540,
                "std_torque": 650,
                "tuned_torque": 780,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "N63B44M3",
            },
        },
    },
    "X5 M": {
        "All": {
            "E70 - 5.0D 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 740,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & Bosch EDC17CP49 & ZF 8HP",
                "Engine_code": "M57S",
            },
            "F15 - 5.0D 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 740,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP49 & Bosch EDC17CP45 & ZF 8HP",
                "Engine_code": "M57S",
            },
            "E70 - 4.4 Turbo 555hp": {
                "std_power": 555,
                "tuned_power": 610,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85 & Siemens/Continental MSD80",
                "Engine_code": "S63B44A",
            },
            "F85 - 4.4 Bi-Turbo 575hp": {
                "std_power": 575,
                "tuned_power": 680,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2 & ZF 8HP70 & Bosch MEVD17.2.8 & Bosch MG1CS201",
                "Engine_code": "S63B44T2",
            },
            "G05 - 4.4 Bi-Turbo Competition 625hp": {
                "std_power": 625,
                "tuned_power": 700,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "S63B44B",
            },
            "G05 - 4.4 Bi-Turbo 600hp": {
                "std_power": 600,
                "tuned_power": 700,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "S63B44B",
            },
        },
    },
    "X6": {
        "E71 - 2008 - 2014": {
            "3.0D 235hp": {
                "std_power": 235,
                "tuned_power": 300,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35",
                "Engine_code": "306D3",
            },
            "3.0D 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 520,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45 & Bosch EDC17CP49 & Bosch EDC16C35",
                "Engine_code": "306D3",
            },
            "3.0D 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "306D3",
            },
            "3.5D 286hp": {
                "std_power": 286,
                "tuned_power": 340,
                "std_torque": 580,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP35",
                "Engine_code": "306D5",
            },
            "4.0D 306hp": {
                "std_power": 306,
                "tuned_power": 350,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30B",
            },
            "M 5.0D 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 740,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP45",
                "Engine_code": "N57D30C",
            },
            "3.5i 306hp": {
                "std_power": 306,
                "tuned_power": 370,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2 & Siemens/Continental MSD81 & ZF 8HP",
                "Engine_code": "N55B30",
            },
            "5.0i ActiveHybrid 486hp": {
                "std_power": 486,
                "tuned_power": 530,
                "std_torque": 780,
                "tuned_torque": 860,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.H & Siemens/Continental MSD85",
                "Engine_code": "N63B44A",
            },
            "5.0i 407hp": {
                "std_power": 407,
                "tuned_power": 470,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85",
                "Engine_code": "N63B44O0",
            },
            "M 555hp": {
                "std_power": 555,
                "tuned_power": 610,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85 & Siemens/Continental MSD80",
                "Engine_code": "S63B44A",
            },
        },
        "F16 - 2014 - 2018": {
            "3.0D 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C56 & ZF 8HP70 & Bosch EDC17C50",
                "Engine_code": "N57D30A",
            },
            "4.0D 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 630,
                "tuned_torque": 700,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP49 & Bosch EDC17CP45 & Bosch EDC17CP09 & ZF 8HP",
                "Engine_code": "N57D30B",
            },
            "M50d 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 740,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP49 & Bosch EDC17CP45",
                "Engine_code": "N57D30S1",
            },
            "3.0D 211hp": {
                "std_power": 211,
                "tuned_power": 310,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C56 & Bosch EDC17C50",
                "Engine_code": "N57D30A",
            },
            "3.5i 306hp": {
                "std_power": 306,
                "tuned_power": 365,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "ZF 8HP45 & Bosch MEVD17.2.G",
                "Engine_code": "N55 830",
            },
            "5.0i 450hp": {
                "std_power": 450,
                "tuned_power": 540,
                "std_torque": 650,
                "tuned_torque": 780,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.H & ZF 8HP70 & Bosch MEVD17.2.8",
                "Engine_code": "N63B44O1",
            },
            "ActiveHybrid 486hp": {
                "std_power": 486,
                "tuned_power": 585,
                "std_torque": 780,
                "tuned_torque": 880,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.H",
                "Engine_code": "N63B44A",
            },
            "M 555hp": {
                "std_power": 555,
                "tuned_power": 610,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & Bosch MEVD17.2.H",
                "Engine_code": "S63B44A",
            },
        },
        "G06 - 2019 ->": {
            "xDrive M50d 400hp": {
                "std_power": 400,
                "tuned_power": 430,
                "std_torque": 760,
                "tuned_torque": 820,
                "cc": 2993,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "B57D30",
            },
            "xDrive30d 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MD1CP002",
                "Engine_code": "B57D30M0",
            },
            "xDrive30d 286hp": {
                "std_power": 286,
                "tuned_power": 330,
                "std_torque": 650,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MD1CP002",
                "Engine_code": "B57D30B",
            },
            "xDrive40d 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS201 & Bosch MD1CP032",
                "Engine_code": "B58B30TU1",
            },
            "M50i 530hp": {
                "std_power": 530,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 880,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "N63B44T3",
            },
            "xDrive40i 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS024",
                "Engine_code": "B58B30",
            },
        },
    },
    "X6 M": {
        "All": {
            "F16 - 5.0D 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 740,
                "tuned_torque": 800,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP49",
                "Engine_code": "N57S",
            },
            "E71 - 4.4 Turbo 555hp": {
                "std_power": 555,
                "tuned_power": 610,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 4395,
                "Type_ecu": "Siemens/Continental MSD85 & Bosch MEVD17.2.6",
                "Engine_code": "S63B44",
            },
            "F86 - 4.4 Turbo 575hp": {
                "std_power": 575,
                "tuned_power": 690,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MEVD17.2.8 & ZF 8HP70 & Bosch MG1CS003 & Bosch MEVD17.2.H",
                "Engine_code": "S63B44",
            },
            "G06 - 4.4 Bi-Turbo 600hp": {
                "std_power": 600,
                "tuned_power": 700,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "S63B44B",
            },
            "G06 - 4.4 Bi-Turbo 625hp": {
                "std_power": 625,
                "tuned_power": 700,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "",
            },
        },
    },
    "X7": {
        "G07 - 2018 ->": {
            "xDrive M50d 400hp": {
                "std_power": 400,
                "tuned_power": 430,
                "std_torque": 760,
                "tuned_torque": 820,
                "cc": 2993,
                "Type_ecu": "Bosch MD1CP002",
                "Engine_code": "B57D30C",
            },
            "xDrive30d 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2993,
                "Type_ecu": "Bosch MD1CP001 & Bosch MD1CP002",
                "Engine_code": "B57D30M0",
            },
            "xDrive40d 340hp": {
                "std_power": 340,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch MG1CS021 & Bosch MG1CS201",
                "Engine_code": "B57D30",
            },
            "M50i 530hp": {
                "std_power": 530,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 880,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS003",
                "Engine_code": "N63B44T3",
            },
            "xDrive40i 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B58B30M1",
            },
            "xDrive40i 333hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "xDrive50i 462hp": {
                "std_power": 462,
                "tuned_power": 550,
                "std_torque": 650,
                "tuned_torque": 780,
                "cc": 4395,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "N63B44",
            },
        },
    },
    "XM": {
        "2022 -> ...": {
            "4.4 Bi-Turbo V8 653hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "Red Label 4.4 Bi-Turbo V8 748hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Z1": {
        "All": {
            "2.5i 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 222,
                "tuned_torque": 240,
                "cc": 2494,
                "Type_ecu": "Siemens/Continental MS42",
                "Engine_code": "256K1",
            },
        },
    },
    "Z3": {
        "All": {
            "1.8i 118hp": {
                "std_power": 118,
                "tuned_power": 129,
                "std_torque": 180,
                "tuned_torque": 200,
                "cc": 1796,
                "Type_ecu": "Bosch M5.2 & Bosch BMS46 & Bosch BMS43",
                "Engine_code": "M43B18",
            },
            "2.0i 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 190,
                "tuned_torque": 210,
                "cc": 1881,
                "Type_ecu": "Siemens/Continental MS42",
                "Engine_code": "206S4",
            },
            "2.2i 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 210,
                "tuned_torque": 235,
                "cc": 2171,
                "Type_ecu": "Siemens/Continental MS45 & Siemens/Continental MS43",
                "Engine_code": "226S1",
            },
            "2.8i 193hp": {
                "std_power": 193,
                "tuned_power": 211,
                "std_torque": 280,
                "tuned_torque": 310,
                "cc": 2793,
                "Type_ecu": "Siemens/Continental MS42 & Siemens/Continental MS41",
                "Engine_code": "286S1",
            },
            "3.0i 231hp": {
                "std_power": 231,
                "tuned_power": 246,
                "std_torque": 300,
                "tuned_torque": 325,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MS45",
                "Engine_code": "306S3",
            },
            "M 3.2 321hp": {
                "std_power": 321,
                "tuned_power": 350,
                "std_torque": 350,
                "tuned_torque": 380,
                "cc": 3201,
                "Type_ecu": "Siemens/Continental MSS50 & Siemens/Continental MSS54 & Siemens/Continental MS41.1",
                "Engine_code": "326S1",
            },
            "M 3.2 325hp": {
                "std_power": 325,
                "tuned_power": 350,
                "std_torque": 350,
                "tuned_torque": 380,
                "cc": 3201,
                "Type_ecu": "Siemens/Continental MSS54",
                "Engine_code": "326S1",
            },
        },
    },
    "Z4": {
        "E85 - 2003 - 2008": {
            "2.0i 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 190,
                "tuned_torque": 210,
                "cc": 1995,
                "Type_ecu": "Bosch MEV946 & Bosch ME9.2",
                "Engine_code": "N46B20B",
            },
            "2.2i 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 210,
                "tuned_torque": 235,
                "cc": 2171,
                "Type_ecu": "Siemens/Continental MS45",
                "Engine_code": "226S1",
            },
            "2.3i 204hp": {
                "std_power": 204,
                "tuned_power": 225,
                "std_torque": 250,
                "tuned_torque": 275,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental MSV80",
                "Engine_code": "N52B25A",
            },
            "2.5i 192hp": {
                "std_power": 192,
                "tuned_power": 210,
                "std_torque": 245,
                "tuned_torque": 265,
                "cc": 2494,
                "Type_ecu": "Siemens/Continental MS45",
                "Engine_code": "256S5",
            },
            "2.5i 177hp": {
                "std_power": 177,
                "tuned_power": 193,
                "std_torque": 230,
                "tuned_torque": 255,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental MS45 & Siemens/Continental MSV70",
                "Engine_code": "N52B25A",
            },
            "2.5i 211hp": {
                "std_power": 211,
                "tuned_power": 235,
                "std_torque": 250,
                "tuned_torque": 270,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental MSV90",
                "Engine_code": "N52B25A",
            },
            "2.5si 218hp": {
                "std_power": 218,
                "tuned_power": 235,
                "std_torque": 250,
                "tuned_torque": 270,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental MSV70",
                "Engine_code": "N52B25A",
            },
            "3.0i 231hp": {
                "std_power": 231,
                "tuned_power": 246,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MS45",
                "Engine_code": "306S3",
            },
            "3.0i 258hp": {
                "std_power": 258,
                "tuned_power": 278,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSD80",
                "Engine_code": "306S3",
            },
            "3.0si 265hp": {
                "std_power": 265,
                "tuned_power": 280,
                "std_torque": 315,
                "tuned_torque": 360,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSV70",
                "Engine_code": "N52B30A",
            },
            "3.5i - N54 - 306hp": {
                "std_power": 306,
                "tuned_power": 370,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2.6",
                "Engine_code": "N54B30A",
            },
            "3.5i - N55 - Twinscroll - 306hp": {
                "std_power": 306,
                "tuned_power": 350,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MEVD17.2.6 & Bosch MEVD17.2.G",
                "Engine_code": "N55B30",
            },
            "3.5is - N54 - 340hp": {
                "std_power": 340,
                "tuned_power": 370,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD81",
                "Engine_code": "N54B30A",
            },
            "M 3.2 343hp": {
                "std_power": 343,
                "tuned_power": 365,
                "std_torque": 365,
                "tuned_torque": 385,
                "cc": 3246,
                "Type_ecu": "Siemens/Continental MSV70 & Bosch MSS70",
                "Engine_code": "326S4",
            },
        },
        "E89 - 2009 - 2018": {
            "1.8i 156hp": {
                "std_power": 156,
                "tuned_power": 260,
                "std_torque": 270,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2 & Bosch MEVD17.2.9 & Bosch MEVD17.2.4",
                "Engine_code": "N20B20A",
            },
            "2.0i 184hp": {
                "std_power": 184,
                "tuned_power": 260,
                "std_torque": 270,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.9 & Bosch MEVD17.2.4 & ZF 8HP45",
                "Engine_code": "N20B20A",
            },
            "2.3i 204hp": {
                "std_power": 204,
                "tuned_power": 219,
                "std_torque": 250,
                "tuned_torque": 275,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental MSV80",
                "Engine_code": "N52B25A",
            },
            "2.8i 245hp": {
                "std_power": 245,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MEVD17.2.4 & Bosch MEVD17.2.9 & Bosch MEVD17.2.8",
                "Engine_code": "N20B20A",
            },
            "3.0i 258hp": {
                "std_power": 258,
                "tuned_power": 278,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 2996,
                "Type_ecu": "Siemens/Continental MSV80",
                "Engine_code": "N52B30A",
            },
            "3.5i - N54 - 306hp": {
                "std_power": 306,
                "tuned_power": 370,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD81",
                "Engine_code": "N54B30A",
            },
            "3.5i - N55 - Twinscroll - 306hp": {
                "std_power": 306,
                "tuned_power": 350,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD81",
                "Engine_code": "N54B30",
            },
            "3.5is - N54 - 340hp": {
                "std_power": 340,
                "tuned_power": 370,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2979,
                "Type_ecu": "Siemens/Continental MSD80 & Siemens/Continental MSD81",
                "Engine_code": "N54B30A",
            },
        },
        "G29 - 2019 ->": {
            "20i 197hp": {
                "std_power": 197,
                "tuned_power": 250,
                "std_torque": 320,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B48B20",
            },
            "30i 258hp": {
                "std_power": 258,
                "tuned_power": 278,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B48B20",
            },
            "M40i 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS002 & Bosch MG1CS003 & Bosch MG1CS201 & Bosch MG1CS024 & ZF 8HP",
                "Engine_code": "B58B30M1 / B58B30O1",
            },
        },
    },
    "Z8": {
        "2002 - 2003": {
            "5.0 V8 400hp": {
                "std_power": 400,
                "tuned_power": 415,
                "std_torque": 500,
                "tuned_torque": 525,
                "cc": 4941,
                "Type_ecu": "Siemens/Continental MSS54 & Siemens/Continental MS52",
                "Engine_code": "508S1",
            },
        },
    },
    "i8": {
        "2014 ->": {
            "i8 362hp": {
                "std_power": 362,
                "tuned_power": 400,
                "std_torque": 570,
                "tuned_torque": 650,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B38K15A",
            },
            "i8 374hp": {
                "std_power": 374,
                "tuned_power": 400,
                "std_torque": 570,
                "tuned_torque": 650,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B38K15A",
            },
        },
    },
},
"Bentley": {
    "Bentayga": {
        "2016 - 2019": {
            "S 4.0 V8 TDI 435hp": {
                "std_power": 435,
                "tuned_power": 510,
                "std_torque": 900,
                "tuned_torque": 1000,
                "cc": 3996,
                "Type_ecu": "Bosch MD1CP014 & ZF AL552",
                "Engine_code": "CZAC",
            },
            "4.0 V8 Bi-Turbo 550hp": {
                "std_power": 550,
                "tuned_power": 620,
                "std_torque": 770,
                "tuned_torque": 870,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008 & ZF AL552",
                "Engine_code": "DCUA",
            },
            "6.0 W12 Bi-Turbo 608hp": {
                "std_power": 608,
                "tuned_power": 680,
                "std_torque": 900,
                "tuned_torque": 1050,
                "cc": 5998,
                "Type_ecu": "Bosch MED17.1.62 & Bosch MG1CS008 & ZF AL552",
                "Engine_code": "DBD",
            },
        },
        "2020 ->": {
            "3.0T V6 Hybrid 449hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "4.0 V8 Bi-Turbo 550hp": {
                "std_power": 550,
                "tuned_power": 620,
                "std_torque": 770,
                "tuned_torque": 870,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "DCUA",
            },
        },
    },
    "Continental Flying Spur": {
        "-> 2016": {
            "4.0 TFSI V8 507hp": {
                "std_power": 507,
                "tuned_power": 640,
                "std_torque": 660,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CMM",
            },
            "6.0 W12 Bi-Turbo 560hp": {
                "std_power": 560,
                "tuned_power": 680,
                "std_torque": 650,
                "tuned_torque": 900,
                "cc": 5998,
                "Type_ecu": "Bosch ME17.1.6 & Bosch ME7.1.1",
                "Engine_code": "BWR",
            },
            "6.0 W12 Bi-Turbo 610hp": {
                "std_power": 610,
                "tuned_power": 680,
                "std_torque": 750,
                "tuned_torque": 900,
                "cc": 5998,
                "Type_ecu": "Bosch ME17.1.6 & Bosch ME7.1.1",
                "Engine_code": "BWR",
            },
            "6.0 W12 Bi-Turbo 625hp": {
                "std_power": 625,
                "tuned_power": 680,
                "std_torque": 800,
                "tuned_torque": 900,
                "cc": 5998,
                "Type_ecu": "Bosch ME17.1.6",
                "Engine_code": "CVA",
            },
        },
        "2016 - 2019": {
            "4.0 V8 S 528hp": {
                "std_power": 528,
                "tuned_power": 640,
                "std_torque": 680,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CYCA",
            },
            "6.0 TSI W12 635hp": {
                "std_power": 635,
                "tuned_power": 665,
                "std_torque": 820,
                "tuned_torque": 880,
                "cc": 5998,
                "Type_ecu": "Bosch MED17.1.6",
                "Engine_code": "CVAA",
            },
            "6.0 TSI W12 625hp": {
                "std_power": 625,
                "tuned_power": 665,
                "std_torque": 800,
                "tuned_torque": 880,
                "cc": 5998,
                "Type_ecu": "",
                "Engine_code": "CVAA",
            },
        },
        "2019 ->": {
            "2.9T Hybrid 544hp": {
                "std_power": 544,
                "tuned_power": 630,
                "std_torque": 750,
                "tuned_torque": 820,
                "cc": 2894,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "4.0 Bi-Turbo V8 550hp": {
                "std_power": 550,
                "tuned_power": 660,
                "std_torque": 770,
                "tuned_torque": 900,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CVDA",
            },
            "6.0 TSI W12 635hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Continental GT Speed": {
        "All": {
            "6.0 W12 Bi-Turbo 635hp": {
                "std_power": 635,
                "tuned_power": 680,
                "std_torque": 820,
                "tuned_torque": 900,
                "cc": 5998,
                "Type_ecu": "Bosch ME17.1.6 & Bosch ME7.1.1 & Bosch MG1CS163",
                "Engine_code": "DBD",
            },
            "6.0 W12 Bi-Turbo 659hp": {
                "std_power": 659,
                "tuned_power": 680,
                "std_torque": 900,
                "tuned_torque": 950,
                "cc": 5950,
                "Type_ecu": "Bosch MG1CS163",
                "Engine_code": "",
            },
        },
    },
    "Continental GT/S": {
        "2011 - 2017": {
            "4.0 TFSi V8 S 528hp": {
                "std_power": 528,
                "tuned_power": 640,
                "std_torque": 680,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CYC",
            },
            "4.0 TFSi V8 507hp": {
                "std_power": 507,
                "tuned_power": 640,
                "std_torque": 660,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1 & ZF AL551",
                "Engine_code": "CMM",
            },
            "6.0 W12 Bi-Turbo 575hp": {
                "std_power": 575,
                "tuned_power": 680,
                "std_torque": 700,
                "tuned_torque": 900,
                "cc": 5998,
                "Type_ecu": "Bosch ME17.1.6 & Bosch ME7.1.1",
                "Engine_code": "CVAB / MTBHT",
            },
            "6.0 W12 Bi-Turbo 635hp": {
                "std_power": 635,
                "tuned_power": 680,
                "std_torque": 820,
                "tuned_torque": 900,
                "cc": 5998,
                "Type_ecu": "Bosch ME17.1.6",
                "Engine_code": "DBD",
            },
            "6.0 W12 Bi-Turbo 625hp": {
                "std_power": 625,
                "tuned_power": 680,
                "std_torque": 800,
                "tuned_torque": 900,
                "cc": 5998,
                "Type_ecu": "Bosch ME17.1.6",
                "Engine_code": "CVA",
            },
        },
        "2018 ->": {
            "2.9T Hybrid 544hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "4.0 TFSi V8 550hp": {
                "std_power": 550,
                "tuned_power": 640,
                "std_torque": 770,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CYC",
            },
            "6.0 TSI W12 635hp": {
                "std_power": 635,
                "tuned_power": 680,
                "std_torque": 900,
                "tuned_torque": 1000,
                "cc": 5950,
                "Type_ecu": "Bosch MG1CS163",
                "Engine_code": "DDBD",
            },
        },
    },
    "Continental GT3-R": {
        "All": {
            "4.0 TFSI V8 580hp": {
                "std_power": 580,
                "tuned_power": 640,
                "std_torque": 700,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CYC",
            },
        },
    },
    "Continental GTC": {
        "All": {
            "4.0 TFSI V8 S 528hp": {
                "std_power": 528,
                "tuned_power": 640,
                "std_torque": 680,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CYC",
            },
            "4.0 TFSI V8 507hp": {
                "std_power": 507,
                "tuned_power": 640,
                "std_torque": 660,
                "tuned_torque": 900,
                "cc": 3993,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CMM",
            },
            "4.0 TFSI V8 550hp": {
                "std_power": 550,
                "tuned_power": 640,
                "std_torque": 770,
                "tuned_torque": 900,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CVDA",
            },
            "6.0 W12 Bi-Turbo 560hp": {
                "std_power": 560,
                "tuned_power": 680,
                "std_torque": 650,
                "tuned_torque": 900,
                "cc": 5998,
                "Type_ecu": "Bosch ME17.1.6 & Bosch ME7.1.1",
                "Engine_code": "BWR",
            },
            "6.0 W12 Bi-Turbo 610hp": {
                "std_power": 610,
                "tuned_power": 680,
                "std_torque": 750,
                "tuned_torque": 900,
                "cc": 5998,
                "Type_ecu": "Bosch ME17.1.6",
                "Engine_code": "BWR",
            },
            "6.0 W12 Bi-Turbo 630hp": {
                "std_power": 630,
                "tuned_power": 680,
                "std_torque": 800,
                "tuned_torque": 900,
                "cc": 5998,
                "Type_ecu": "Bosch ME17.1.6",
                "Engine_code": "CVA",
            },
        },
    },
    "Mulsanne": {
        "-> 2016": {
            "6.75 V8 Bi-Turbo 537hp": {
                "std_power": 537,
                "tuned_power": 650,
                "std_torque": 1100,
                "tuned_torque": 1300,
                "cc": 6752,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "CKB",
            },
        },
        "2016 -> ...": {
            "6.75 V8 Bi-Turbo 512hp": {
                "std_power": 512,
                "tuned_power": 550,
                "std_torque": 1051,
                "tuned_torque": 1150,
                "cc": 6752,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "CZMB",
            },
        },
    },
},
"Buick": {
    "Cascada": {
        "2016 ->": {
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Delco E80",
                "Engine_code": "A16SHT",
            },
        },
    },
    "Century": {
        "1998 - 2005": {
            "3.1 V6 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 251,
                "tuned_torque": 280,
                "cc": 3136,
                "Type_ecu": "",
                "Engine_code": "L82",
            },
            "3.1 V6 175hp": {
                "std_power": 175,
                "tuned_power": 180,
                "std_torque": 251,
                "tuned_torque": 280,
                "cc": 3136,
                "Type_ecu": "",
                "Engine_code": "LG8",
            },
        },
    },
    "Enclave": {
        "2008 - 2009": {
            "3.6 V6 275hp": {
                "std_power": 275,
                "tuned_power": 290,
                "std_torque": 366,
                "tuned_torque": 390,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LY7",
            },
            "3.6 V6 288hp": {
                "std_power": 288,
                "tuned_power": 300,
                "std_torque": 366,
                "tuned_torque": 390,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LTT",
            },
        },
        "2014 - 2020": {
            "3.6 V6 288hp": {
                "std_power": 288,
                "tuned_power": 300,
                "std_torque": 366,
                "tuned_torque": 390,
                "cc": 3564,
                "Type_ecu": "Bosch ME9.1 & Bosch ME9.5",
                "Engine_code": "LTT",
            },
            "3.6 V6 310hp": {
                "std_power": 310,
                "tuned_power": 332,
                "std_torque": 361,
                "tuned_torque": 394,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LFY",
            },
        },
    },
    "Encore": {
        "2012 - 2016": {
            "1.7 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "",
                "Engine_code": "A17DTS",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1368,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
        },
        "2016 - 2018": {
            "1.6 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "B16DTH",
            },
            "1.4 Turbo 152hp": {
                "std_power": 152,
                "tuned_power": 180,
                "std_torque": 235,
                "tuned_torque": 290,
                "cc": 1399,
                "Type_ecu": "Delco E78",
                "Engine_code": "B14XFT",
            },
        },
    },
    "Envision": {
        "2015 - 2017": {
            "2.0 Turbo 252hp": {
                "std_power": 252,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E80",
                "Engine_code": "2.0 LTG",
            },
        },
        "2018 ->": {
            "1.5 Turbo 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1490,
                "Type_ecu": "Delco E80 & Denso SH72546",
                "Engine_code": "LFV",
            },
            "2.0 Turbo 252hp": {
                "std_power": 252,
                "tuned_power": 280,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E80",
                "Engine_code": "LTG",
            },
        },
    },
    "Excelle GT": {
        "All": {
            "1.6 Turbo 181hp": {
                "std_power": 181,
                "tuned_power": 210,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "A16XHT",
            },
            "1.6i 121hp": {
                "std_power": 121,
                "tuned_power": 131,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "",
            },
            "1.8 ECOTEC 146hp": {
                "std_power": 146,
                "tuned_power": 156,
                "std_torque": 177,
                "tuned_torque": 192,
                "cc": 1796,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Lacrosse": {
        "2005 - 2009": {
            "3.6 V6 240hp": {
                "std_power": 240,
                "tuned_power": 262,
                "std_torque": 311,
                "tuned_torque": 339,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LY7",
            },
            "3.8 V6 200hp": {
                "std_power": 200,
                "tuned_power": 219,
                "std_torque": 312,
                "tuned_torque": 337,
                "cc": 3791,
                "Type_ecu": "",
                "Engine_code": "L26",
            },
            "5.3 V8 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 438,
                "tuned_torque": 460,
                "cc": 5327,
                "Type_ecu": "",
                "Engine_code": "LS4",
            },
        },
        "2010 - 2016": {
            "2.4i - 182hp": {
                "std_power": 182,
                "tuned_power": 195,
                "std_torque": 233,
                "tuned_torque": 250,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
            "3.6 V6 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 305,
                "tuned_torque": 330,
                "cc": 3564,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "LY7",
            },
        },
        "2017 ->": {
            "1.5 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 252,
                "tuned_torque": 300,
                "cc": 1490,
                "Type_ecu": "Delco E80",
                "Engine_code": "",
            },
            "2.0 Turbo 262hp": {
                "std_power": 262,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 1998,
                "Type_ecu": "Delco E80 & Delco E39",
                "Engine_code": "LTG",
            },
            "3.6 V6 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 363,
                "tuned_torque": 389,
                "cc": 3649,
                "Type_ecu": "",
                "Engine_code": "LGX",
            },
        },
    },
    "Lesabre": {
        "1997 - 2005": {
            "3.8 V6 205hp": {
                "std_power": 205,
                "tuned_power": 220,
                "std_torque": 310,
                "tuned_torque": 340,
                "cc": 3800,
                "Type_ecu": "",
                "Engine_code": "L36",
            },
        },
    },
    "Lucerne": {
        "2006 - 2009": {
            "4.6 V8 275hp": {
                "std_power": 275,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 4565,
                "Type_ecu": "",
                "Engine_code": "LD8",
            },
        },
    },
    "Park Avenue": {
        "1999 - 2005": {
            "3.8 V6 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 3791,
                "Type_ecu": "",
                "Engine_code": "L67 Series 2",
            },
        },
    },
    "Rainier": {
        "2004 - 2007": {
            "4.2 V8 279hp": {
                "std_power": 279,
                "tuned_power": 295,
                "std_torque": 373,
                "tuned_torque": 395,
                "cc": 4157,
                "Type_ecu": "",
                "Engine_code": "LL8",
            },
            "5.3 V8 294hp": {
                "std_power": 294,
                "tuned_power": 310,
                "std_torque": 441,
                "tuned_torque": 465,
                "cc": 5327,
                "Type_ecu": "",
                "Engine_code": "5300 Small Block",
            },
            "5.3 V8 304hp": {
                "std_power": 304,
                "tuned_power": 321,
                "std_torque": 447,
                "tuned_torque": 471,
                "cc": 5327,
                "Type_ecu": "",
                "Engine_code": "5300 Small Block",
            },
        },
    },
    "Regal": {
        "2010 ->": {
            "1.5 Turbo 163hp": {
                "std_power": 163,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1490,
                "Type_ecu": "Delco E80",
                "Engine_code": "",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1399,
                "Type_ecu": "",
                "Engine_code": "LE2",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "A16XHT",
            },
            "2.0 Turbo 255hp": {
                "std_power": 255,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1998,
                "Type_ecu": "Delco E39 & Delco E80",
                "Engine_code": "A20LTG",
            },
            "2.0 Turbo 220hp": {
                "std_power": 220,
                "tuned_power": 255,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Bosch ME9.6 & Delco E39",
                "Engine_code": "A20LTG",
            },
            "2.0 Turbo 270hp": {
                "std_power": 270,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "A20LTG",
            },
            "3.8 V6 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 280,
                "tuned_torque": 310,
                "cc": 3791,
                "Type_ecu": "",
                "Engine_code": "L67 Series 2",
            },
        },
    },
    "Rendezvous": {
        "2003 - 2005": {
            "3.4 V6 185hp": {
                "std_power": 185,
                "tuned_power": 200,
                "std_torque": 285,
                "tuned_torque": 300,
                "cc": 3350,
                "Type_ecu": "",
                "Engine_code": "LA1",
            },
            "3.5 V6 198hp": {
                "std_power": 198,
                "tuned_power": 215,
                "std_torque": 289,
                "tuned_torque": 310,
                "cc": 3498,
                "Type_ecu": "",
                "Engine_code": "LX9",
            },
        },
        "2005 ->": {
            "3.6 V6 246hp": {
                "std_power": 246,
                "tuned_power": 260,
                "std_torque": 314,
                "tuned_torque": 330,
                "cc": 3498,
                "Type_ecu": "",
                "Engine_code": "LY7",
            },
        },
    },
    "Terraza": {
        "-> 2007": {
            "3.9 V6 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 332,
                "tuned_torque": 350,
                "cc": 3880,
                "Type_ecu": "",
                "Engine_code": "LX9",
            },
        },
    },
    "Verano": {
        "2013 - 2017": {
            "1.5 Turbo 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 301,
                "cc": 1490,
                "Type_ecu": "Delco E80",
                "Engine_code": "LFV",
            },
            "2.0 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 315,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "A20LTG",
            },
            "2.4i 180hp": {
                "std_power": 180,
                "tuned_power": 198,
                "std_torque": 232,
                "tuned_torque": 249,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
        },
    },
},
"Cadillac": {
    "ATS": {
        "2013 ->": {
            "2.0 Turbo 276hp": {
                "std_power": 276,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E39A & Delco E80",
                "Engine_code": "2.0 LTG",
            },
            "2.5i - 202hp": {
                "std_power": 202,
                "tuned_power": 225,
                "std_torque": 259,
                "tuned_torque": 280,
                "cc": 2457,
                "Type_ecu": "Delco E39A",
                "Engine_code": "LCV",
            },
            "3.6 V6 321hp": {
                "std_power": 321,
                "tuned_power": 350,
                "std_torque": 358,
                "tuned_torque": 380,
                "cc": 3546,
                "Type_ecu": "",
                "Engine_code": "LFX",
            },
            "3.6 V6 335hp": {
                "std_power": 335,
                "tuned_power": 350,
                "std_torque": 386,
                "tuned_torque": 400,
                "cc": 3649,
                "Type_ecu": "",
                "Engine_code": "LGX",
            },
            "3.6 V6 480hp": {
                "std_power": 480,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 3564,
                "Type_ecu": "Delco E92",
                "Engine_code": "LLT",
            },
        },
    },
    "BLS": {
        "2006 - 2010": {
            "1.9D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTH",
            },
            "1.9D 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 465,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z19DTR",
            },
            "2.0T 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 265,
                "tuned_torque": 320,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "B207L",
            },
            "2.0T 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1998,
                "Type_ecu": "Trionic Trionic 8",
                "Engine_code": "B207R",
            },
        },
    },
    "CTS": {
        "2002 - 2007": {
            "2.8 V6 215hp": {
                "std_power": 215,
                "tuned_power": 235,
                "std_torque": 262,
                "tuned_torque": 290,
                "cc": 2792,
                "Type_ecu": "",
                "Engine_code": "LP1",
            },
            "3.2 V6 24V 223hp": {
                "std_power": 223,
                "tuned_power": 243,
                "std_torque": 298,
                "tuned_torque": 336,
                "cc": 3175,
                "Type_ecu": "",
                "Engine_code": "LA3",
            },
            "3.6 V6 257hp": {
                "std_power": 257,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 365,
                "cc": 3546,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "LY7",
            },
            "5.7 V8 405hp": {
                "std_power": 405,
                "tuned_power": 430,
                "std_torque": 533,
                "tuned_torque": 560,
                "cc": 5665,
                "Type_ecu": "",
                "Engine_code": "LS6",
            },
            "5.7 V8 405hp": {
                "std_power": 405,
                "tuned_power": 430,
                "std_torque": 533,
                "tuned_torque": 560,
                "cc": 5665,
                "Type_ecu": "",
                "Engine_code": "LS6",
            },
            "6.0 V8 405hp": {
                "std_power": 405,
                "tuned_power": 425,
                "std_torque": 536,
                "tuned_torque": 555,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "LS2",
            },
        },
        "2008 - 2013": {
            "2.8 V6 211hp": {
                "std_power": 211,
                "tuned_power": 228,
                "std_torque": 246,
                "tuned_torque": 268,
                "cc": 2792,
                "Type_ecu": "",
                "Engine_code": "LP1",
            },
            "3.0 V6 274hp": {
                "std_power": 274,
                "tuned_power": 301,
                "std_torque": 302,
                "tuned_torque": 331,
                "cc": 2994,
                "Type_ecu": "Delco E39",
                "Engine_code": "LF1",
            },
            "3.6 V6 304hp": {
                "std_power": 304,
                "tuned_power": 325,
                "std_torque": 370,
                "tuned_torque": 390,
                "cc": 3564,
                "Type_ecu": "Bosch MED9.6.1",
                "Engine_code": "LLT",
            },
            "6.2 V8 556hp": {
                "std_power": 556,
                "tuned_power": 600,
                "std_torque": 747,
                "tuned_torque": 800,
                "cc": 6200,
                "Type_ecu": "Delco E67",
                "Engine_code": "LSA",
            },
        },
        "2014 ->": {
            "2.0 Turbo 272hp": {
                "std_power": 272,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E39A",
                "Engine_code": "2.0 LTG",
            },
            "3.6 V6 325hp": {
                "std_power": 325,
                "tuned_power": 347,
                "std_torque": 373,
                "tuned_torque": 399,
                "cc": 3564,
                "Type_ecu": "Delco E92",
                "Engine_code": "LFX",
            },
            "3.6 V6 340hp": {
                "std_power": 340,
                "tuned_power": 362,
                "std_torque": 386,
                "tuned_torque": 402,
                "cc": 3649,
                "Type_ecu": "",
                "Engine_code": "LGX",
            },
            "3.6 V6 426hp": {
                "std_power": 426,
                "tuned_power": 465,
                "std_torque": 583,
                "tuned_torque": 636,
                "cc": 3564,
                "Type_ecu": "Delco E82",
                "Engine_code": "LF3",
            },
        },
    },
    "DTS": {
        "2006 - 2009": {
            "3.6 V6 275hp": {
                "std_power": 275,
                "tuned_power": 295,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 4565,
                "Type_ecu": "",
                "Engine_code": "LD8",
            },
        },
    },
    "Escalade": {
        "2002 - 2006": {
            "5.3 V8 295hp": {
                "std_power": 295,
                "tuned_power": 315,
                "std_torque": 454,
                "tuned_torque": 480,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LM7",
            },
            "6.0 V8 345hp": {
                "std_power": 345,
                "tuned_power": 365,
                "std_torque": 515,
                "tuned_torque": 540,
                "cc": 6000,
                "Type_ecu": "",
                "Engine_code": "LQ9",
            },
        },
        "2007 - 2013": {
            "6.2 V8 403hp": {
                "std_power": 403,
                "tuned_power": 425,
                "std_torque": 565,
                "tuned_torque": 590,
                "cc": 6199,
                "Type_ecu": "Delco E38",
                "Engine_code": "L92",
            },
        },
        "2014 - 2019": {
            "6.2 V8 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 623,
                "tuned_torque": 645,
                "cc": 6162,
                "Type_ecu": "Delco E92",
                "Engine_code": "L86",
            },
        },
    },
    "SLS": {
        "-> 2012": {
            "2.0 Turbo 270hp": {
                "std_power": 270,
                "tuned_power": 315,
                "std_torque": 360,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "LTG 2.0L",
            },
        },
    },
    "SRX": {
        "2004 - 2009": {
            "3.6 V6 255hp": {
                "std_power": 255,
                "tuned_power": 275,
                "std_torque": 344,
                "tuned_torque": 370,
                "cc": 3564,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "LY7",
            },
            "4.6 V8 320hp": {
                "std_power": 320,
                "tuned_power": 340,
                "std_torque": 427,
                "tuned_torque": 445,
                "cc": 4565,
                "Type_ecu": "",
                "Engine_code": "LH2",
            },
        },
        "2010 - 2016": {
            "3.0 V6 265hp": {
                "std_power": 265,
                "tuned_power": 285,
                "std_torque": 302,
                "tuned_torque": 330,
                "cc": 2994,
                "Type_ecu": "Delco E39",
                "Engine_code": "LF1",
            },
            "3.6 V6 308hp": {
                "std_power": 308,
                "tuned_power": 328,
                "std_torque": 359,
                "tuned_torque": 380,
                "cc": 3546,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "LFX",
            },
        },
    },
    "STS": {
        "2006 - 2010": {
            "3.6 V6 255hp": {
                "std_power": 255,
                "tuned_power": 275,
                "std_torque": 342,
                "tuned_torque": 370,
                "cc": 3564,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "LY7",
            },
            "4.4 V8 469hp": {
                "std_power": 469,
                "tuned_power": 500,
                "std_torque": 595,
                "tuned_torque": 680,
                "cc": 4371,
                "Type_ecu": "",
                "Engine_code": "LC3",
            },
            "4.6 V8 320hp": {
                "std_power": 320,
                "tuned_power": 340,
                "std_torque": 427,
                "tuned_torque": 445,
                "cc": 4395,
                "Type_ecu": "",
                "Engine_code": "LH2",
            },
        },
    },
    "Seville": {
        "All": {
            "4.6 STS 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 4565,
                "Type_ecu": "",
                "Engine_code": "LH2",
            },
        },
    },
    "XLR": {
        "2006 - 2009": {
            "4.4 Supercharged V8 443hp": {
                "std_power": 443,
                "tuned_power": 480,
                "std_torque": 561,
                "tuned_torque": 600,
                "cc": 4371,
                "Type_ecu": "",
                "Engine_code": "LC3",
            },
            "4.6 V8 326hp": {
                "std_power": 326,
                "tuned_power": 346,
                "std_torque": 423,
                "tuned_torque": 450,
                "cc": 4565,
                "Type_ecu": "",
                "Engine_code": "LH2",
            },
        },
    },
    "XT4": {
        "2019 ->": {
            "350D 174hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 Turbo 240hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "XTS": {
        "2013 - 2016": {
            "2.0T 272hp": {
                "std_power": 272,
                "tuned_power": 300,
                "std_torque": 390,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E80",
                "Engine_code": "LTG",
            },
            "3.6 V6 Twin Turbo 420hp": {
                "std_power": 420,
                "tuned_power": 480,
                "std_torque": 583,
                "tuned_torque": 680,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LF3",
            },
            "3.6 V6 304hp": {
                "std_power": 304,
                "tuned_power": 324,
                "std_torque": 358,
                "tuned_torque": 380,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LFX",
            },
        },
    },
},
"Chevrolet": {
    "Astro": {
        "1995 - 2003": {
            "4.3 V6 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 339,
                "tuned_torque": 370,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LU3",
            },
        },
    },
    "Avalanche": {
        "2002 - 2006": {
            "5.3 V8 295hp": {
                "std_power": 295,
                "tuned_power": 315,
                "std_torque": 454,
                "tuned_torque": 485,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "L59",
            },
            "8.1 V8 340hp": {
                "std_power": 340,
                "tuned_power": 360,
                "std_torque": 606,
                "tuned_torque": 640,
                "cc": 8128,
                "Type_ecu": "",
                "Engine_code": "L18",
            },
        },
        "2007 - 2013": {
            "5.3 V8 320hp": {
                "std_power": 320,
                "tuned_power": 340,
                "std_torque": 454,
                "tuned_torque": 480,
                "cc": 5328,
                "Type_ecu": "Delco E38",
                "Engine_code": "LMG",
            },
            "6.0 V8 366hp": {
                "std_power": 366,
                "tuned_power": 386,
                "std_torque": 510,
                "tuned_torque": 540,
                "cc": 5976,
                "Type_ecu": "",
                "Engine_code": "L76",
            },
        },
    },
    "Aveo / Sonic": {
        "2008 - 2011": {
            "1.3 VCDI 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A13DTE",
            },
            "1.2i 16v 84hp": {
                "std_power": 84,
                "tuned_power": 93,
                "std_torque": 114,
                "tuned_torque": 129,
                "cc": 1208,
                "Type_ecu": "Delco E83 & Delphi MT80",
                "Engine_code": "B12D1",
            },
        },
        "2011 - >": {
            "1.3 VCDI 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A13DTE",
            },
            "1.3 VCDI 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A13DTE",
            },
            "1.2i 16v 86hp": {
                "std_power": 86,
                "tuned_power": 95,
                "std_torque": 115,
                "tuned_torque": 130,
                "cc": 1229,
                "Type_ecu": "Delco E83",
                "Engine_code": "A12XER",
            },
            "1.8 137hp": {
                "std_power": 137,
                "tuned_power": 150,
                "std_torque": 169,
                "tuned_torque": 185,
                "cc": 1796,
                "Type_ecu": "",
                "Engine_code": "LUW/LWE",
            },
        },
    },
    "Blazer": {
        "1998 - 2005": {
            "4.3 V6 190hp": {
                "std_power": 190,
                "tuned_power": 215,
                "std_torque": 353,
                "tuned_torque": 385,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LU3",
            },
        },
    },
    "Camaro": {
        "1998 - 2002": {
            "3.8 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 305,
                "tuned_torque": 330,
                "cc": 3791,
                "Type_ecu": "",
                "Engine_code": "L36",
            },
            "5.7 V8 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 454,
                "tuned_torque": 480,
                "cc": 5665,
                "Type_ecu": "",
                "Engine_code": "LS1",
            },
        },
        "2010 - 2015": {
            "3.6 V6 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 375,
                "tuned_torque": 400,
                "cc": 3564,
                "Type_ecu": "Bosch MED9.6.1 & Delco E39 & Delco E39A & Delco E82",
                "Engine_code": "LLT",
            },
            "6.2 V8 405hp": {
                "std_power": 405,
                "tuned_power": 425,
                "std_torque": 556,
                "tuned_torque": 580,
                "cc": 6162,
                "Type_ecu": "Delco E38",
                "Engine_code": "",
            },
            "6.2 V8 432hp": {
                "std_power": 432,
                "tuned_power": 450,
                "std_torque": 569,
                "tuned_torque": 600,
                "cc": 6162,
                "Type_ecu": "Delco E38 & Delco E92",
                "Engine_code": "",
            },
            "7.0 V8 505hp": {
                "std_power": 505,
                "tuned_power": 525,
                "std_torque": 652,
                "tuned_torque": 680,
                "cc": 7008,
                "Type_ecu": "",
                "Engine_code": "LS7",
            },
        },
        "2016 ->": {
            "2.0T 272hp": {
                "std_power": 272,
                "tuned_power": 300,
                "std_torque": 390,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E80 & Delco E85",
                "Engine_code": "2.0 LTG",
            },
            "3.6 V6 335hp": {
                "std_power": 335,
                "tuned_power": 355,
                "std_torque": 385,
                "tuned_torque": 405,
                "cc": 3649,
                "Type_ecu": "Delco E39A & Delco E82 & Delco E81",
                "Engine_code": "LGX",
            },
            "6.2 V8 SS 455hp": {
                "std_power": 455,
                "tuned_power": 475,
                "std_torque": 617,
                "tuned_torque": 645,
                "cc": 6162,
                "Type_ecu": "Delco E92",
                "Engine_code": "LT1",
            },
            "6.2 V8 ZL1 650hp": {
                "std_power": 650,
                "tuned_power": 700,
                "std_torque": 881,
                "tuned_torque": 940,
                "cc": 6162,
                "Type_ecu": "Delco E92",
                "Engine_code": "LT4",
            },
        },
    },
    "Caprice": {
        "2010 ->": {
            "3.6 V6 301hp": {
                "std_power": 301,
                "tuned_power": 320,
                "std_torque": 359,
                "tuned_torque": 390,
                "cc": 3564,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "LFX",
            },
            "6.0 V8 355hp": {
                "std_power": 355,
                "tuned_power": 375,
                "std_torque": 521,
                "tuned_torque": 550,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "L77",
            },
        },
    },
    "Captiva": {
        "2006 - 2011": {
            "2.0 VCDI 127hp": {
                "std_power": 127,
                "tuned_power": 185,
                "std_torque": 295,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z20S",
            },
            "2.0 VCDI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z20S",
            },
            "2.4i 136hp": {
                "std_power": 136,
                "tuned_power": 146,
                "std_torque": 220,
                "tuned_torque": 240,
                "cc": 2405,
                "Type_ecu": "Bosch ME7.9.9",
                "Engine_code": "Z24SED",
            },
            "3.2 V6 230hp": {
                "std_power": 230,
                "tuned_power": 245,
                "std_torque": 297,
                "tuned_torque": 320,
                "cc": 3195,
                "Type_ecu": "Bosch ME9.6.1 & Bosch ME9.2",
                "Engine_code": "10HM",
            },
        },
        "2011 - 2018": {
            "2.2 VCDI 184hp": {
                "std_power": 184,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 2231,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "Z22D1",
            },
            "2.2 VCDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 2231,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "Z22D1",
            },
        },
    },
    "Cavelier": {
        "1997 - 2005": {
            "2.2i 140hp": {
                "std_power": 140,
                "tuned_power": 155,
                "std_torque": 203,
                "tuned_torque": 225,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "Z22SE",
            },
            "2.4i 152hp": {
                "std_power": 152,
                "tuned_power": 165,
                "std_torque": 203,
                "tuned_torque": 225,
                "cc": 2392,
                "Type_ecu": "",
                "Engine_code": "LD9",
            },
        },
    },
    "Cobalt": {
        "2005 - 2007": {
            "2.0i 145hp": {
                "std_power": 145,
                "tuned_power": 160,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "Z22SE",
            },
            "SS 2.0 Supercharged 205hp": {
                "std_power": 205,
                "tuned_power": 240,
                "std_torque": 271,
                "tuned_torque": 305,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "LSJ",
            },
            "SS 2.4i 173hp": {
                "std_power": 173,
                "tuned_power": 190,
                "std_torque": 221,
                "tuned_torque": 240,
                "cc": 2384,
                "Type_ecu": "Delco E83",
                "Engine_code": "LE5",
            },
        },
        "2008 - 2010": {
            "2.0 Turbo 260hp": {
                "std_power": 260,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 390,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "2.0 LNF",
            },
            "2.2 155hp": {
                "std_power": 155,
                "tuned_power": 170,
                "std_torque": 203,
                "tuned_torque": 225,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "LAP",
            },
            "SS 2.4 173hp": {
                "std_power": 173,
                "tuned_power": 190,
                "std_torque": 221,
                "tuned_torque": 240,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
        },
    },
    "Colorado": {
        "2003 - 2012": {
            "2.8i - 175hp": {
                "std_power": 175,
                "tuned_power": 190,
                "std_torque": 251,
                "tuned_torque": 275,
                "cc": 2770,
                "Type_ecu": "Delco E98",
                "Engine_code": "LK5",
            },
            "2.9i - 185hp": {
                "std_power": 185,
                "tuned_power": 200,
                "std_torque": 258,
                "tuned_torque": 280,
                "cc": 2921,
                "Type_ecu": "Delco E67",
                "Engine_code": "LLV",
            },
            "3.5i - 220hp": {
                "std_power": 220,
                "tuned_power": 240,
                "std_torque": 305,
                "tuned_torque": 330,
                "cc": 3460,
                "Type_ecu": "Delco E92",
                "Engine_code": "L52",
            },
            "3.7i - 242hp": {
                "std_power": 242,
                "tuned_power": 260,
                "std_torque": 328,
                "tuned_torque": 350,
                "cc": 3653,
                "Type_ecu": "",
                "Engine_code": "LRR",
            },
            "5.3 V8 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 434,
                "tuned_torque": 455,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LH8",
            },
        },
        "2012 ->": {
            "2.8 181hp": {
                "std_power": 181,
                "tuned_power": 210,
                "std_torque": 500,
                "tuned_torque": 540,
                "cc": 2800,
                "Type_ecu": "Delco E98",
                "Engine_code": "A428 DOHC",
            },
            "2.8d 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 2766,
                "Type_ecu": "Delco E98",
                "Engine_code": "A 428 DOHC",
            },
            "3.6 V6 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 365,
                "tuned_torque": 390,
                "cc": 3564,
                "Type_ecu": "Delco E82 & Bosch MED9.5.10 & Delco E92",
                "Engine_code": "LFX",
            },
        },
    },
    "Corvette": {
        "(C5) 1997 - 2004": {
            "5.7 V8 345hp": {
                "std_power": 345,
                "tuned_power": 365,
                "std_torque": 470,
                "tuned_torque": 490,
                "cc": 5665,
                "Type_ecu": "Delco E82",
                "Engine_code": "LS1",
            },
        },
        "(C6) 2005 - 2013": {
            "6.0 V8 400hp": {
                "std_power": 400,
                "tuned_power": 420,
                "std_torque": 540,
                "tuned_torque": 570,
                "cc": 5967,
                "Type_ecu": "Delco E38",
                "Engine_code": "LS2",
            },
            "6.2 V8 430hp": {
                "std_power": 430,
                "tuned_power": 450,
                "std_torque": 575,
                "tuned_torque": 600,
                "cc": 6162,
                "Type_ecu": "Delco E38",
                "Engine_code": "LS3",
            },
        },
        "(C7) 2014 ->": {
            "6.2 V8 455hp": {
                "std_power": 455,
                "tuned_power": 475,
                "std_torque": 624,
                "tuned_torque": 650,
                "cc": 6162,
                "Type_ecu": "Delco E92",
                "Engine_code": "LT1",
            },
        },
    },
    "Corvette Z06": {
        "All": {
            "5.5 V8 670hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "6.2 V8 659hp": {
                "std_power": 659,
                "tuned_power": 680,
                "std_torque": 881,
                "tuned_torque": 900,
                "cc": 6162,
                "Type_ecu": "Delco E92",
                "Engine_code": "",
            },
            "7.0 V8 512hp": {
                "std_power": 512,
                "tuned_power": 542,
                "std_torque": 637,
                "tuned_torque": 667,
                "cc": 7011,
                "Type_ecu": "Delco E38",
                "Engine_code": "LS7",
            },
        },
    },
    "Corvette ZR1": {
        "All": {
            "6.2 V8 Compressor 647hp": {
                "std_power": 647,
                "tuned_power": 677,
                "std_torque": 819,
                "tuned_torque": 840,
                "cc": 6162,
                "Type_ecu": "",
                "Engine_code": "LS9",
            },
        },
    },
    "Cruze": {
        "-> 2017": {
            "1.7D 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "",
            },
            "1.7D 110hp": {
                "std_power": 110,
                "tuned_power": 165,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Bosch EDC17CP46",
                "Engine_code": "",
            },
            "2.0D 125hp": {
                "std_power": 125,
                "tuned_power": 185,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z20S1",
            },
            "2.0D 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39 & Delphi DCM3.7 & Bosch EDC17CP47",
                "Engine_code": "Z20S1",
            },
            "2.0D 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.7 & Bosch EDC17CP47",
                "Engine_code": "",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Delco E78 & Delco E80 & Delco E83",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "A16LET",
            },
            "1.6i 16V 124hp": {
                "std_power": 124,
                "tuned_power": 140,
                "std_torque": 154,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental SIMTEC 76 & Delco E83 & Delphi MT80",
                "Engine_code": "F16D4",
            },
            "1.8i 141hp": {
                "std_power": 141,
                "tuned_power": 154,
                "std_torque": 176,
                "tuned_torque": 190,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIMTEC 76 & Delco E78 & Delco E83",
                "Engine_code": "F18D4",
            },
        },
        "2017 - 2019": {
            "1.6 CDTI 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 325,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E83 & Delco E98",
                "Engine_code": "MDE",
            },
            "1.4 Turbo 155hp": {
                "std_power": 155,
                "tuned_power": 180,
                "std_torque": 240,
                "tuned_torque": 270,
                "cc": 1399,
                "Type_ecu": "Delco E80",
                "Engine_code": "LE2",
            },
        },
        "2019 ->": {
            "1.6 CDTI 137hp": {
                "std_power": 137,
                "tuned_power": 160,
                "std_torque": 325,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "MDE",
            },
            "1.4 Turbo 153hp": {
                "std_power": 153,
                "tuned_power": 180,
                "std_torque": 240,
                "tuned_torque": 270,
                "cc": 1399,
                "Type_ecu": "Delco E80",
                "Engine_code": "LE2",
            },
        },
    },
    "Epica": {
        "2006 - 2010": {
            "2.0 VCDI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z20S1",
            },
        },
    },
    "Equinox": {
        "2005 - 2009": {
            "3.4 V6 185hp": {
                "std_power": 185,
                "tuned_power": 205,
                "std_torque": 280,
                "tuned_torque": 305,
                "cc": 3350,
                "Type_ecu": "Delco E38",
                "Engine_code": "LNJ",
            },
            "3.6 V6 264hp": {
                "std_power": 264,
                "tuned_power": 285,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LY7",
            },
        },
        "2010 - 2017": {
            "2.4i 182hp": {
                "std_power": 182,
                "tuned_power": 200,
                "std_torque": 233,
                "tuned_torque": 260,
                "cc": 2384,
                "Type_ecu": "Delco E39A",
                "Engine_code": "LEA",
            },
            "3.0 V6 264hp": {
                "std_power": 264,
                "tuned_power": 285,
                "std_torque": 301,
                "tuned_torque": 330,
                "cc": 2994,
                "Type_ecu": "",
                "Engine_code": "LFW",
            },
        },
    },
    "Express": {
        "2001 - 2007": {
            "6.6 V8 250hp": {
                "std_power": 250,
                "tuned_power": 280,
                "std_torque": 624,
                "tuned_torque": 660,
                "cc": 6599,
                "Type_ecu": "Bosch EDC17CP18",
                "Engine_code": "LLY",
            },
            "4.8 V8 275hp": {
                "std_power": 275,
                "tuned_power": 295,
                "std_torque": 393,
                "tuned_torque": 420,
                "cc": 4807,
                "Type_ecu": "",
                "Engine_code": "L20",
            },
            "5.3 V8 290hp": {
                "std_power": 290,
                "tuned_power": 310,
                "std_torque": 441,
                "tuned_torque": 465,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LM7",
            },
            "6.0 V8 304hp": {
                "std_power": 304,
                "tuned_power": 325,
                "std_torque": 488,
                "tuned_torque": 515,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "L96",
            },
        },
        "2008 - 2014": {
            "6.6 V8 250hp": {
                "std_power": 250,
                "tuned_power": 280,
                "std_torque": 624,
                "tuned_torque": 660,
                "cc": 6599,
                "Type_ecu": "Bosch EDC17CP18",
                "Engine_code": "LLY",
            },
            "4.8 V8 279hp": {
                "std_power": 279,
                "tuned_power": 300,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 4807,
                "Type_ecu": "Delco E38",
                "Engine_code": "L20",
            },
            "5.3 V8 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 452,
                "tuned_torque": 480,
                "cc": 5328,
                "Type_ecu": "Delco E38",
                "Engine_code": "LMF",
            },
            "6.0 V8 304hp": {
                "std_power": 304,
                "tuned_power": 325,
                "std_torque": 488,
                "tuned_torque": 520,
                "cc": 5967,
                "Type_ecu": "Delco E38",
                "Engine_code": "L96",
            },
        },
    },
    "HHR": {
        "2005 - 2011": {
            "2.2i 143hp": {
                "std_power": 143,
                "tuned_power": 150,
                "std_torque": 203,
                "tuned_torque": 225,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "Z22SE",
            },
            "2.4i 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 222,
                "tuned_torque": 237,
                "cc": 2399,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
        },
    },
    "Impala": {
        "2000 - 2005": {
            "3.4 V6 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 278,
                "tuned_torque": 308,
                "cc": 3350,
                "Type_ecu": "",
                "Engine_code": "LA1",
            },
            "3.8 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 305,
                "tuned_torque": 330,
                "cc": 3791,
                "Type_ecu": "",
                "Engine_code": "L36",
            },
        },
        "2006 - 2014": {
            "3.5 V6 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 290,
                "tuned_torque": 320,
                "cc": 3510,
                "Type_ecu": "Delco E38",
                "Engine_code": "LZE",
            },
            "3.6 V6 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 355,
                "tuned_torque": 380,
                "cc": 3564,
                "Type_ecu": "Delco E39",
                "Engine_code": "LFX",
            },
            "3.9 V6 245hp": {
                "std_power": 245,
                "tuned_power": 265,
                "std_torque": 240,
                "tuned_torque": 265,
                "cc": 3880,
                "Type_ecu": "Delco E38",
                "Engine_code": "LZ9",
            },
            "SS 5.3 307hp": {
                "std_power": 307,
                "tuned_power": 332,
                "std_torque": 438,
                "tuned_torque": 467,
                "cc": 5327,
                "Type_ecu": "",
                "Engine_code": "LS4",
            },
        },
        "2014 ->": {
            "3.6 V6 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 355,
                "tuned_torque": 380,
                "cc": 3564,
                "Type_ecu": "Delco E39A",
                "Engine_code": "LFX",
            },
        },
    },
    "Lacetti": {
        "2005 - 2010": {
            "2.0 VCDI 121hp": {
                "std_power": 121,
                "tuned_power": 155,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "",
            },
        },
    },
    "Malibu": {
        "1997 - 2005": {
            "2.2 145hp": {
                "std_power": 145,
                "tuned_power": 165,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 2198,
                "Type_ecu": "Bosch MED7.6.1",
                "Engine_code": "Z22SE",
            },
            "2.4 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 2400,
                "Type_ecu": "",
                "Engine_code": "LD9",
            },
            "3.1 V6 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 258,
                "tuned_torque": 280,
                "cc": 3136,
                "Type_ecu": "",
                "Engine_code": "LG8",
            },
        },
        "2006 - 2012": {
            "2.4 169hp": {
                "std_power": 169,
                "tuned_power": 190,
                "std_torque": 219,
                "tuned_torque": 240,
                "cc": 2384,
                "Type_ecu": "Delco E37",
                "Engine_code": "LE5",
            },
            "3.5 V6 217hp": {
                "std_power": 217,
                "tuned_power": 235,
                "std_torque": 290,
                "tuned_torque": 310,
                "cc": 3510,
                "Type_ecu": "Delco E38",
                "Engine_code": "LZ4",
            },
            "3.6 V6 252hp": {
                "std_power": 252,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LY7",
            },
            "3.9 V6 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 325,
                "tuned_torque": 345,
                "cc": 3880,
                "Type_ecu": "",
                "Engine_code": "LZ9",
            },
        },
        "2013 - 2015": {
            "2.0 VCDi 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 230,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "A16LET",
            },
            "2.0T 250hp": {
                "std_power": 250,
                "tuned_power": 295,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E80 & Delco E39A",
                "Engine_code": "2.0 LTG",
            },
            "2.5 - 197hp": {
                "std_power": 197,
                "tuned_power": 220,
                "std_torque": 259,
                "tuned_torque": 285,
                "cc": 2499,
                "Type_ecu": "Delco E39",
                "Engine_code": "LCV",
            },
        },
        "2016 - 2018": {
            "1.5 Turbo 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 280,
                "cc": 1490,
                "Type_ecu": "Delco E80",
                "Engine_code": "LFV",
            },
            "2.0 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E80",
                "Engine_code": "2.0 LTG",
            },
        },
        "2019 ->": {
            "1.5 Turbo 165hp": {
                "std_power": 165,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 280,
                "cc": 1490,
                "Type_ecu": "Delco E83 & Delco E80",
                "Engine_code": "LFV",
            },
            "2.0 Turbo 253hp": {
                "std_power": 253,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E80",
                "Engine_code": "2.0 LTG",
            },
        },
    },
    "Monte Carlo": {
        "1997 - 2005": {
            "3.1 V6 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 251,
                "tuned_torque": 275,
                "cc": 3100,
                "Type_ecu": "",
                "Engine_code": "L82",
            },
            "3.4 V6 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 278,
                "tuned_torque": 310,
                "cc": 3350,
                "Type_ecu": "",
                "Engine_code": "LA1",
            },
            "SS 3.8 Supercharged V6 240hp": {
                "std_power": 240,
                "tuned_power": 280,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 3800,
                "Type_ecu": "",
                "Engine_code": "L67",
            },
            "SS 3.8 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 305,
                "tuned_torque": 330,
                "cc": 3800,
                "Type_ecu": "",
                "Engine_code": "L36",
            },
        },
        "2006 - 2007": {
            "3.5 V6 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 290,
                "tuned_torque": 310,
                "cc": 3510,
                "Type_ecu": "Delco E38",
                "Engine_code": "LZ4",
            },
            "3.9 V6 242hp": {
                "std_power": 242,
                "tuned_power": 260,
                "std_torque": 328,
                "tuned_torque": 350,
                "cc": 3900,
                "Type_ecu": "Delco E38",
                "Engine_code": "LZ9",
            },
        },
    },
    "Nubira": {
        "All": {
            "2.0 VCDI 121hp": {
                "std_power": 121,
                "tuned_power": 155,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z20S",
            },
        },
    },
    "Orlando": {
        "2011 - 2018": {
            "2.0D 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 315,
                "tuned_torque": 380,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.7 & Delphi DCM3.7AP",
                "Engine_code": "Z20D1",
            },
            "2.0D 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.7 & Delphi DCM3.7AP",
                "Engine_code": "Z20D1",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
        },
    },
    "S10": {
        "1997 - 2004": {
            "2.2i - 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 140,
                "tuned_torque": 160,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "4.3 V6 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 325,
                "tuned_torque": 345,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LF6",
            },
        },
        "2012 ->": {
            "2.8 CDTi 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 2766,
                "Type_ecu": "Bosch EDC16C39 & Delco E98",
                "Engine_code": "R428 DOHC",
            },
        },
    },
    "SS": {
        "2014 ->": {
            "6.2 V8 415hp": {
                "std_power": 415,
                "tuned_power": 435,
                "std_torque": 563,
                "tuned_torque": 585,
                "cc": 6202,
                "Type_ecu": "Delco E38",
                "Engine_code": "LS3",
            },
        },
    },
    "SSR": {
        "2003 - 2006": {
            "5.3 V8 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 449,
                "tuned_torque": 470,
                "cc": 5327,
                "Type_ecu": "",
                "Engine_code": "LM4",
            },
            "6.0 V8 396hp": {
                "std_power": 396,
                "tuned_power": 415,
                "std_torque": 549,
                "tuned_torque": 580,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "LS2",
            },
        },
    },
    "Silverado": {
        "1999 - 2007": {
            "4.3 V6 195hp": {
                "std_power": 195,
                "tuned_power": 215,
                "std_torque": 353,
                "tuned_torque": 385,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LU3",
            },
            "4.8 V8 285hp": {
                "std_power": 285,
                "tuned_power": 305,
                "std_torque": 400,
                "tuned_torque": 425,
                "cc": 5327,
                "Type_ecu": "Delco E38",
                "Engine_code": "LR4",
            },
            "5.3 V8 285hp": {
                "std_power": 285,
                "tuned_power": 305,
                "std_torque": 425,
                "tuned_torque": 450,
                "cc": 5328,
                "Type_ecu": "Delco E38",
                "Engine_code": "LM7",
            },
            "6.0 V8 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 488,
                "tuned_torque": 510,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "LQ4",
            },
            "8.1 V8 330hp": {
                "std_power": 330,
                "tuned_power": 350,
                "std_torque": 610,
                "tuned_torque": 630,
                "cc": 8128,
                "Type_ecu": "",
                "Engine_code": "L18",
            },
        },
        "2007 - 2014": {
            "6.6 V8 397hp": {
                "std_power": 397,
                "tuned_power": 440,
                "std_torque": 1037,
                "tuned_torque": 1100,
                "cc": 6599,
                "Type_ecu": "Bosch EDC17CP18 & Bosch EDC16C42 & Bosch EDC16C31 & Delco E98 & Delco E86",
                "Engine_code": "Duramax V8",
            },
            "4.3 V6 195hp": {
                "std_power": 195,
                "tuned_power": 215,
                "std_torque": 260,
                "tuned_torque": 280,
                "cc": 4300,
                "Type_ecu": "Delco E37",
                "Engine_code": "LU3",
            },
            "4.8 V8 295hp": {
                "std_power": 295,
                "tuned_power": 314,
                "std_torque": 414,
                "tuned_torque": 439,
                "cc": 4807,
                "Type_ecu": "Delco E38",
                "Engine_code": "",
            },
            "5.3 V8 315hp": {
                "std_power": 315,
                "tuned_power": 335,
                "std_torque": 458,
                "tuned_torque": 480,
                "cc": 5328,
                "Type_ecu": "Delco E38",
                "Engine_code": "L53",
            },
            "6.0 V8 360hp": {
                "std_power": 360,
                "tuned_power": 380,
                "std_torque": 515,
                "tuned_torque": 535,
                "cc": 5967,
                "Type_ecu": "Delco E38",
                "Engine_code": "L96",
            },
            "6.2 V8 403hp": {
                "std_power": 403,
                "tuned_power": 425,
                "std_torque": 565,
                "tuned_torque": 585,
                "cc": 6200,
                "Type_ecu": "Delco E38",
                "Engine_code": "L92",
            },
        },
        "2014 ->": {
            "6.6 V8 397hp": {
                "std_power": 397,
                "tuned_power": 440,
                "std_torque": 1037,
                "tuned_torque": 1100,
                "cc": 6599,
                "Type_ecu": "Bosch EDC17CP18 & Delco E86 & Delco E41",
                "Engine_code": "Duramax V8",
            },
            "2.7 Turbo 310hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "4.3 V6 285hp": {
                "std_power": 285,
                "tuned_power": 305,
                "std_torque": 414,
                "tuned_torque": 435,
                "cc": 4301,
                "Type_ecu": "",
                "Engine_code": "LV3",
            },
            "5.3 V8 355hp": {
                "std_power": 355,
                "tuned_power": 375,
                "std_torque": 519,
                "tuned_torque": 540,
                "cc": 5328,
                "Type_ecu": "Delco E96 & Delco E92",
                "Engine_code": "L83",
            },
            "6.0 V8 360hp": {
                "std_power": 360,
                "tuned_power": 380,
                "std_torque": 542,
                "tuned_torque": 570,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "LY6",
            },
            "6.2 V8 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 609,
                "tuned_torque": 640,
                "cc": 6162,
                "Type_ecu": "",
                "Engine_code": "L86",
            },
        },
    },
    "Spark": {
        "2010 ->": {
            "1.0i 68hp": {
                "std_power": 68,
                "tuned_power": 75,
                "std_torque": 93,
                "tuned_torque": 105,
                "cc": 995,
                "Type_ecu": "Siemens/Continental D42 & Siemens/Continental D52 & Siemens/Continental D32",
                "Engine_code": "B10D1",
            },
            "1.2i 81hp": {
                "std_power": 81,
                "tuned_power": 94,
                "std_torque": 111,
                "tuned_torque": 126,
                "cc": 1206,
                "Type_ecu": "Siemens/Continental D52",
                "Engine_code": "B12D1",
            },
        },
    },
    "Suburban": {
        "2000 - 2006": {
            "5.3 V8 295hp": {
                "std_power": 295,
                "tuned_power": 315,
                "std_torque": 454,
                "tuned_torque": 480,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "L59",
            },
            "6.0 V8 335hp": {
                "std_power": 335,
                "tuned_power": 355,
                "std_torque": 508,
                "tuned_torque": 530,
                "cc": 5976,
                "Type_ecu": "",
                "Engine_code": "LQ4",
            },
            "8.1 V8 325hp": {
                "std_power": 325,
                "tuned_power": 345,
                "std_torque": 606,
                "tuned_torque": 635,
                "cc": 8128,
                "Type_ecu": "",
                "Engine_code": "L18",
            },
        },
        "2007 - 2014": {
            "5.3 V8 320hp": {
                "std_power": 320,
                "tuned_power": 350,
                "std_torque": 454,
                "tuned_torque": 480,
                "cc": 5328,
                "Type_ecu": "Delco E38",
                "Engine_code": "LY5",
            },
            "6.0 V8 352hp": {
                "std_power": 352,
                "tuned_power": 375,
                "std_torque": 517,
                "tuned_torque": 540,
                "cc": 5967,
                "Type_ecu": "Delco E38",
                "Engine_code": "LY6",
            },
            "6.2 V8 426hp": {
                "std_power": 426,
                "tuned_power": 455,
                "std_torque": 610,
                "tuned_torque": 640,
                "cc": 6162,
                "Type_ecu": "Delco E38",
                "Engine_code": "L94",
            },
        },
        "2015 ->": {
            "5.3 V8 355hp": {
                "std_power": 355,
                "tuned_power": 375,
                "std_torque": 519,
                "tuned_torque": 550,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "L83",
            },
            "6.2 V8 420hp": {
                "std_power": 420,
                "tuned_power": 441,
                "std_torque": 623,
                "tuned_torque": 657,
                "cc": 6162,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Tahoe": {
        "2000 - 2006": {
            "4.8 V8 285hp": {
                "std_power": 285,
                "tuned_power": 305,
                "std_torque": 454,
                "tuned_torque": 480,
                "cc": 4807,
                "Type_ecu": "",
                "Engine_code": "LR4",
            },
            "5.3 V8 295hp": {
                "std_power": 295,
                "tuned_power": 315,
                "std_torque": 454,
                "tuned_torque": 480,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LM7",
            },
        },
        "2007 - 2013": {
            "4.8 V8 295hp": {
                "std_power": 295,
                "tuned_power": 315,
                "std_torque": 414,
                "tuned_torque": 440,
                "cc": 4807,
                "Type_ecu": "Delco E38",
                "Engine_code": "LY2",
            },
            "5.3 V8 320hp": {
                "std_power": 320,
                "tuned_power": 340,
                "std_torque": 461,
                "tuned_torque": 480,
                "cc": 5328,
                "Type_ecu": "Delco E38",
                "Engine_code": "LMG",
            },
            "6.2 V8 405hp": {
                "std_power": 405,
                "tuned_power": 425,
                "std_torque": 565,
                "tuned_torque": 585,
                "cc": 6162,
                "Type_ecu": "Delco E38",
                "Engine_code": "L94",
            },
        },
        "2014 ->": {
            "5.3 V8 380hp": {
                "std_power": 380,
                "tuned_power": 400,
                "std_torque": 416,
                "tuned_torque": 440,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "L83",
            },
        },
    },
    "Trailblazer": {
        "All": {
            "2.5d 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 380,
                "tuned_torque": 445,
                "cc": 2449,
                "Type_ecu": "Delco E98",
                "Engine_code": "",
            },
            "2.8d 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 500,
                "tuned_torque": 575,
                "cc": 2776,
                "Type_ecu": "Delco E98",
                "Engine_code": "",
            },
            "4.2i 279hp": {
                "std_power": 279,
                "tuned_power": 300,
                "std_torque": 373,
                "tuned_torque": 395,
                "cc": 4157,
                "Type_ecu": "Delco E67",
                "Engine_code": "LL8",
            },
            "5.3 V8 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 447,
                "tuned_torque": 470,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LM4",
            },
            "SS 6.0 V8 400hp": {
                "std_power": 400,
                "tuned_power": 420,
                "std_torque": 542,
                "tuned_torque": 565,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "LS2",
            },
        },
    },
    "Traverse": {
    },
    "Trax": {
        "2013 ->": {
            "1.7 VCDI 110hp": {
                "std_power": 110,
                "tuned_power": 165,
                "std_torque": 260,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "A17DTE",
            },
            "1.7 VCDI 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 260,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "A17DTS",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
        },
    },
    "Uplander": {
        "2007 - 2009": {
            "3.5 V6 203hp": {
                "std_power": 203,
                "tuned_power": 230,
                "std_torque": 298,
                "tuned_torque": 330,
                "cc": 3498,
                "Type_ecu": "",
                "Engine_code": "LX9",
            },
            "3.9 V6 243hp": {
                "std_power": 243,
                "tuned_power": 265,
                "std_torque": 325,
                "tuned_torque": 360,
                "cc": 3880,
                "Type_ecu": "",
                "Engine_code": "LZ9",
            },
        },
    },
    "Venture": {
        "1997 - 2005": {
            "3.4 V6 185hp": {
                "std_power": 185,
                "tuned_power": 205,
                "std_torque": 280,
                "tuned_torque": 300,
                "cc": 3307,
                "Type_ecu": "",
                "Engine_code": "LA1",
            },
        },
    },
},
"Chrysler": {
    "200": {
        "2010 - 2016": {
            "3.6 V6 283hp": {
                "std_power": 283,
                "tuned_power": 305,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "3.6 Pentastar V6",
            },
        },
    },
    "300C": {
        "2004 - 2011": {
            "3.0 CRD 218hp": {
                "std_power": 218,
                "tuned_power": 275,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Siemens/Continental EGS53",
                "Engine_code": "642982",
            },
            "3.0 CRD 211hp": {
                "std_power": 211,
                "tuned_power": 275,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "642982",
            },
            "2.7 V6 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 258,
                "tuned_torque": 280,
                "cc": 2736,
                "Type_ecu": "Motorola NGC4",
                "Engine_code": "EER",
            },
            "3.5 V6 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 339,
                "tuned_torque": 360,
                "cc": 3518,
                "Type_ecu": "Motorola NGC4 & Motorola NGC3",
                "Engine_code": "EGG",
            },
            "5.7 Hemi V8 340hp": {
                "std_power": 340,
                "tuned_power": 360,
                "std_torque": 525,
                "tuned_torque": 545,
                "cc": 5654,
                "Type_ecu": "Motorola NCG4 & Motorola NGC3",
                "Engine_code": "5.7 Hemi V8",
            },
            "SRT8 6.1 Hemi V8 425hp": {
                "std_power": 425,
                "tuned_power": 445,
                "std_torque": 569,
                "tuned_torque": 590,
                "cc": 6059,
                "Type_ecu": "Motorola NCG4 & Motorola NGC3",
                "Engine_code": "6.1 Hemi V8",
            },
        },
        "2011 ->": {
            "3.6 V6 292hp": {
                "std_power": 292,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "3.6 Pentastar V6",
            },
            "5.7 Hemi V8 363hp": {
                "std_power": 363,
                "tuned_power": 385,
                "std_torque": 534,
                "tuned_torque": 555,
                "cc": 5654,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "5.7 Hemi V8",
            },
            "SRT8 6.4 Hemi V8 475hp": {
                "std_power": 475,
                "tuned_power": 495,
                "std_torque": 637,
                "tuned_torque": 655,
                "cc": 6417,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "6.4 Hemi V8",
            },
        },
    },
    "Aspen": {
        "2004 - 2009": {
            "4.7 V8 234hp": {
                "std_power": 234,
                "tuned_power": 255,
                "std_torque": 393,
                "tuned_torque": 415,
                "cc": 4701,
                "Type_ecu": "",
                "Engine_code": "4.7 Powertech V8",
            },
            "4.7 V8 303hp": {
                "std_power": 303,
                "tuned_power": 325,
                "std_torque": 447,
                "tuned_torque": 465,
                "cc": 4701,
                "Type_ecu": "",
                "Engine_code": "4.7 Corsair Powertech V8",
            },
            "5.7 Hemi V8 335hp": {
                "std_power": 335,
                "tuned_power": 355,
                "std_torque": 502,
                "tuned_torque": 525,
                "cc": 5654,
                "Type_ecu": "",
                "Engine_code": "5.7 Hemi V8",
            },
        },
    },
    "Crossfire": {
        "2003 - 2008": {
            "3.2 SRT6 335hp": {
                "std_power": 335,
                "tuned_power": 360,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 3199,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "EGZ",
            },
            "3.2 V6 218hp": {
                "std_power": 218,
                "tuned_power": 233,
                "std_torque": 310,
                "tuned_torque": 330,
                "cc": 3199,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "112947",
            },
        },
    },
    "Grand Voyager": {
        "2008 - 2011": {
            "2.8 CRDi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2786,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "ENS",
            },
        },
    },
    "PT Cruiser": {
        "2004 - 2010": {
            "2.2 CRD 121hp": {
                "std_power": 121,
                "tuned_power": 155,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2 & Bosch EDC15",
                "Engine_code": "664",
            },
            "2.2 CRD 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 382,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "664",
            },
            "2.0i 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 188,
                "tuned_torque": 205,
                "cc": 1995,
                "Type_ecu": "Motorola Chrysler",
                "Engine_code": "2.0L",
            },
            "GT 2.4 Turbo 215hp": {
                "std_power": 215,
                "tuned_power": 270,
                "std_torque": 332,
                "tuned_torque": 390,
                "cc": 2429,
                "Type_ecu": "Siemens/Continental SIM90T",
                "Engine_code": "EDV/EDT",
            },
            "GT 2.4 Turbo 230hp": {
                "std_power": 230,
                "tuned_power": 270,
                "std_torque": 332,
                "tuned_torque": 390,
                "cc": 2429,
                "Type_ecu": "Motorola NGC3",
                "Engine_code": "EDV/EDT",
            },
        },
    },
    "Pacifica": {
        "2004 - 2008": {
            "4.0 V6 253hp": {
                "std_power": 253,
                "tuned_power": 275,
                "std_torque": 355,
                "tuned_torque": 375,
                "cc": 3952,
                "Type_ecu": "",
                "Engine_code": "EGQ",
            },
        },
        "2017 ->": {
            "3.6 V6 287hp": {
                "std_power": 287,
                "tuned_power": 305,
                "std_torque": 355,
                "tuned_torque": 375,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "3.6 Pentastar V6",
            },
        },
    },
    "Sebring": {
        "All": {
            "2.0 CRD 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BYL",
            },
            "2.0i 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 190,
                "tuned_torque": 210,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SIM90T",
                "Engine_code": "2.0 World Gasoline Engine",
            },
            "2.4i 143hp": {
                "std_power": 143,
                "tuned_power": 153,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 2429,
                "Type_ecu": "Mitsubishi MH8XXX",
                "Engine_code": "EDZ",
            },
            "2.7 V6 203hp": {
                "std_power": 203,
                "tuned_power": 225,
                "std_torque": 262,
                "tuned_torque": 280,
                "cc": 2736,
                "Type_ecu": "Motorola Chrysler",
                "Engine_code": "EER",
            },
            "3.5 V6 235hp": {
                "std_power": 235,
                "tuned_power": 255,
                "std_torque": 315,
                "tuned_torque": 335,
                "cc": 3518,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Town & Country": {
        "2005 - 2016": {
            "3.3 V6 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 278,
                "tuned_torque": 300,
                "cc": 3301,
                "Type_ecu": "",
                "Engine_code": "EGA",
            },
            "3.6 V8 283hp": {
                "std_power": 283,
                "tuned_power": 305,
                "std_torque": 353,
                "tuned_torque": 375,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "3.6 Pentastar V6",
            },
            "3.8 V6 215hp": {
                "std_power": 215,
                "tuned_power": 235,
                "std_torque": 332,
                "tuned_torque": 360,
                "cc": 3778,
                "Type_ecu": "",
                "Engine_code": "EGH",
            },
            "4.0 V8 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 3952,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Voyager": {
        "-> 2009": {
            "2.5 CRD 140hp": {
                "std_power": 140,
                "tuned_power": 176,
                "std_torque": 343,
                "tuned_torque": 435,
                "cc": 2499,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "VM20C",
            },
            "2.5TD 115hp": {
                "std_power": 115,
                "tuned_power": 142,
                "std_torque": 278,
                "tuned_torque": 315,
                "cc": 2499,
                "Type_ecu": "Bosch EDC15C3",
                "Engine_code": "25TD",
            },
            "2.8 CRD 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 2776,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC17CP27",
                "Engine_code": "VM28C",
            },
            "2.8 CRD 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 2776,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17CP27",
                "Engine_code": "VM64C",
            },
        },
    },
},
"Citroën": {
    "Ami": {
        "2020 -> ...": {
            "5.5 kWh 8hp": {
                "std_power": 8,
                "tuned_power": 8,
                "std_torque": 39,
                "tuned_torque": 39,
                "cc": 0,
                "Type_ecu": "EVPT VCU3000",
                "Engine_code": "",
            },
        },
    },
    "Berlingo": {
        "1997 - 2002": {
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 255,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2 & Siemens/Continental SID801",
                "Engine_code": "DW10TD",
            },
        },
        "2002 - 2009": {
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Siemens/Continental SID801",
                "Engine_code": "DV6ATED4",
            },
            "1.6 HDi 16V 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 175,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6BTED4",
            },
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 255,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2 & Siemens/Continental SID801",
                "Engine_code": "DW10TD",
            },
            "2.0 HDi 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID801",
                "Engine_code": "DW10TD",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 147,
                "tuned_torque": 160,
                "cc": 1587,
                "Type_ecu": "Bosch EDC16C34 & Marelli IAW48P2 & Bosch ME7.4.4",
                "Engine_code": "TU5JP4",
            },
        },
        "2008 - 2012": {
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV6ATED4",
            },
            "1.6 HDi 16V 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 185,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV6BTED4",
            },
            "1.6 HDiF 16V 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDiF 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Bosch EDC16C34",
                "Engine_code": "DV6DTED",
            },
            "1.6 HDiF 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "2.0 HDiF 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "DW10TD",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.2",
                "Engine_code": "EP6C",
            },
            "1.6 VTi 98hp": {
                "std_power": 98,
                "tuned_power": 108,
                "std_torque": 152,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.2 & Bosch ME7.4.5",
                "Engine_code": "EP6C B",
            },
        },
        "2012 - 2015": {
            "1.6 HDi 16V 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 185,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV6BTED4",
            },
            "1.6 HDiF 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "1.6 HDiF 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDi 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.6 VTi 98hp": {
                "std_power": 98,
                "tuned_power": 108,
                "std_torque": 152,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6C B",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6C",
            },
        },
        "2015 - 2017": {
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 BlueHDI 8V 75hp": {
                "std_power": 75,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Bosch EDC17C10",
                "Engine_code": "DV6FETED",
            },
        },
        "2018 ->": {
            "1.5 BlueHDI 102hp": {
                "std_power": 102,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C60 & Bosch MD1CS003",
                "Engine_code": "DV5",
            },
            "1.5 BlueHDI 75hp": {
                "std_power": 75,
                "tuned_power": 125,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003 & Bosch EDC17C60",
                "Engine_code": "DV5",
            },
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "DV5",
            },
            "1.2 Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "C-Crosser": {
        "2007 - 2012": {
            "2.2 HDIF 156hp": {
                "std_power": 156,
                "tuned_power": 185,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "DW12ME5",
            },
        },
    },
    "C1": {
        "2005 - 2013": {
            "1.4 HDi 8V 55hp": {
                "std_power": 55,
                "tuned_power": 81,
                "std_torque": 130,
                "tuned_torque": 168,
                "cc": 1398,
                "Type_ecu": "Siemens/Continental SID805",
                "Engine_code": "DV4TD",
            },
            "1.0i 68hp": {
                "std_power": 68,
                "tuned_power": 79,
                "std_torque": 93,
                "tuned_torque": 122,
                "cc": 998,
                "Type_ecu": "Bosch M7.9.5 & Valeo VD46",
                "Engine_code": "386F",
            },
        },
        "2014 ->": {
            "1.0 e-VTI 69hp": {
                "std_power": 69,
                "tuned_power": 75,
                "std_torque": 95,
                "tuned_torque": 110,
                "cc": 998,
                "Type_ecu": "Bosch ME17.9.52",
                "Engine_code": "1KR",
            },
            "1.0 e-VTI 82hp": {
                "std_power": 82,
                "tuned_power": 90,
                "std_torque": 116,
                "tuned_torque": 130,
                "cc": 998,
                "Type_ecu": "Bosch ME17.9.53",
                "Engine_code": "EB2",
            },
        },
    },
    "C2": {
        "2003 - 2010": {
            "1.4 HDi 8V 68hp": {
                "std_power": 68,
                "tuned_power": 86,
                "std_torque": 150,
                "tuned_torque": 192,
                "cc": 1398,
                "Type_ecu": "Siemens/Continental SID802 & Siemens/Continental SID804 & Siemens/Continental SID806 & Siemens/Continental SID805 & Bosch EDC16C34",
                "Engine_code": "DV4TD",
            },
            "1.6 HDiF 16V 110hp": {
                "std_power": 110,
                "tuned_power": 137,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "1.6 16v VTS 125hp": {
                "std_power": 125,
                "tuned_power": 137,
                "std_torque": 140,
                "tuned_torque": 160,
                "cc": 1587,
                "Type_ecu": "Bosch ME7.4.5 & Bosch M7.4.4",
                "Engine_code": "NFS",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 147,
                "tuned_torque": 165,
                "cc": 1587,
                "Type_ecu": "Bosch M7.4.4 & Bosch ME7.4.5",
                "Engine_code": "TU5JP4",
            },
        },
    },
    "C3": {
        "2002 - 2010": {
            "1.4 HDi 8V 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 150,
                "tuned_torque": 200,
                "cc": 1398,
                "Type_ecu": "Siemens/Continental SID802 & Bosch EDC16C34 & Siemens/Continental SID806 & Siemens/Continental SID804",
                "Engine_code": "DV4TD",
            },
            "1.4 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1398,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "8HY",
            },
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Delphi DDCR",
                "Engine_code": "DV4ATED4",
            },
            "1.6 HDiF 16V 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "1.1i 60hp": {
                "std_power": 60,
                "tuned_power": 72,
                "std_torque": 94,
                "tuned_torque": 104,
                "cc": 1124,
                "Type_ecu": "Marelli IAW48P2 & Valeo J34P",
                "Engine_code": "TU1JP",
            },
            "1.4i 16v 90hp": {
                "std_power": 90,
                "tuned_power": 102,
                "std_torque": 133,
                "tuned_torque": 148,
                "cc": 1360,
                "Type_ecu": "Marelli IAW 6LP & Sagem 2000",
                "Engine_code": "ET3J4",
            },
            "1.4i 75hp": {
                "std_power": 75,
                "tuned_power": 83,
                "std_torque": 120,
                "tuned_torque": 134,
                "cc": 1360,
                "Type_ecu": "Valeo J34P",
                "Engine_code": "TU3JP",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 147,
                "tuned_torque": 165,
                "cc": 1587,
                "Type_ecu": "Bosch ME7.4.4",
                "Engine_code": "TU5JP4",
            },
        },
        "2010 - 2013": {
            "1.4 HDI 8V 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID804",
                "Engine_code": "DV4TD",
            },
            "1.6 HDI 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6A TED4",
            },
            "1.6 HDI 16V 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDI 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDI 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6CTED",
            },
            "1.0 VTi 68hp": {
                "std_power": 68,
                "tuned_power": 75,
                "std_torque": 116,
                "tuned_torque": 130,
                "cc": 998,
                "Type_ecu": "Valeo V46.11",
                "Engine_code": "",
            },
            "1.1i 61hp": {
                "std_power": 61,
                "tuned_power": 68,
                "std_torque": 95,
                "tuned_torque": 105,
                "cc": 1124,
                "Type_ecu": "Marelli IAW48P2",
                "Engine_code": "TU1AE5",
            },
            "1.2 VTi 82hp": {
                "std_power": 82,
                "tuned_power": 102,
                "std_torque": 116,
                "tuned_torque": 130,
                "cc": 1199,
                "Type_ecu": "Valeo V46.11",
                "Engine_code": "EB2",
            },
            "1.4 VTI 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 135,
                "tuned_torque": 150,
                "cc": 1397,
                "Type_ecu": "Bosch MEV17.4",
                "Engine_code": "EP3C",
            },
            "1.4i 75hp": {
                "std_power": 75,
                "tuned_power": 83,
                "std_torque": 120,
                "tuned_torque": 134,
                "cc": 1360,
                "Type_ecu": "Marelli IAW 6LP",
                "Engine_code": "TU3A",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 156,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "5F01",
            },
        },
        "2013 - 2019": {
            "1.4 HDI 68hp": {
                "std_power": 68,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV4C 8HP",
            },
            "1.5 BlueHDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHY DV5RD",
            },
            "1.6 BlueHDI 8V 75hp": {
                "std_power": 75,
                "tuned_power": 120,
                "std_torque": 233,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Bosch EDC17C60",
                "Engine_code": "DV6FETED",
            },
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 e-HDI 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.6 e-HDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "",
                "Engine_code": "DV6CTED",
            },
            "1.0 PureTech 68hp": {
                "std_power": 68,
                "tuned_power": 99,
                "std_torque": 116,
                "tuned_torque": 140,
                "cc": 999,
                "Type_ecu": "Valeo V46.11 & Valeo VD56.1 & Bosch ME17.9.53",
                "Engine_code": "EB0F",
            },
            "1.2 PureTech 82hp": {
                "std_power": 82,
                "tuned_power": 92,
                "std_torque": 116,
                "tuned_torque": 130,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "HMZ",
            },
            "1.2 PureTech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "EB2DT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 156,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4",
                "Engine_code": "EP6C",
            },
        },
        "2020 ->": {
            "1.5 BlueHDI 102hp": {
                "std_power": 102,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHY DV5RD",
            },
            "1.2 PureTech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "EB2DT",
            },
            "1.2 PureTech 83hp": {
                "std_power": 83,
                "tuned_power": 92,
                "std_torque": 118,
                "tuned_torque": 130,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HMZ",
            },
        },
    },
    "C3 Aircross": {
        "2017 - 2020": {
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6 FDTED",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6 FCTED",
            },
            "1.2 Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 205,
                "tuned_torque": 245,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1 & Valeo VD56.1",
                "Engine_code": "HNZ EB2DT",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1 & Valeo VD46.1",
                "Engine_code": "HNY EB2DTS",
            },
        },
        "2021 -> ...": {
            "1.5 BlueHDI 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 BlueHDI 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 Puretech 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 Puretech 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "C3 Picasso": {
        "2009 - 2013": {
            "1.6 HDi 16V 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Siemens/Continental SID807",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDi 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "1.4 VTi 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 135,
                "tuned_torque": 150,
                "cc": 1397,
                "Type_ecu": "Bosch MEV17.4",
                "Engine_code": "8FR",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6",
            },
        },
        "2013 ->": {
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDI 8V 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 233,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FETED",
            },
            "1.6 HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDi 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6CTED",
            },
            "1.2 PureTech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1",
                "Engine_code": "EB2DT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6",
            },
        },
    },
    "C4": {
        "2004 - 2010": {
            "1.6 HDi 16V 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6ATED4",
            },
            "2.0 HDi 138hp": {
                "std_power": 138,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Delphi DCM3.4",
                "Engine_code": "DW10FD",
            },
            "1.6 THP 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6DT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 147,
                "tuned_torque": 165,
                "cc": 1587,
                "Type_ecu": "Bosch ME7.4.4 & Bosch ME7.5.4 & Bosch ME7.4.5",
                "Engine_code": "TU5JP4",
            },
            "2.0i 16v vts 180hp": {
                "std_power": 180,
                "tuned_power": 194,
                "std_torque": 202,
                "tuned_torque": 228,
                "cc": 1997,
                "Type_ecu": "Marelli IAW 6LP",
                "Engine_code": "RFK",
            },
            "2.0i 16v 138hp": {
                "std_power": 138,
                "tuned_power": 148,
                "std_torque": 190,
                "tuned_torque": 210,
                "cc": 1997,
                "Type_ecu": "Marelli IAW 6LP & Siemens/Continental SID803A",
                "Engine_code": "EW10WJ4",
            },
        },
        "2010 - 2015": {
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 HDi 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.6 HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO & Bosch EDC17C10",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "2.0 HDi 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10FD",
            },
            "2.0 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM3.5",
                "Engine_code": "DW10FD",
            },
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10CTED4 DTR",
            },
            "1.2 e-THP 131hp": {
                "std_power": 131,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1",
                "Engine_code": "EB2DT S",
            },
            "1.4 VTi 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 135,
                "tuned_torque": 150,
                "cc": 1397,
                "Type_ecu": "Bosch MEV17.4",
                "Engine_code": "EP3C",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6CDT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6C",
            },
        },
        "2015 - 2019": {
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 e-HDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807 & Bosch EDC17C60",
                "Engine_code": "DV6CTED",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10FD",
            },
            "1.2 PureTech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2DT",
            },
            "1.2 PureTech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1",
                "Engine_code": "EB2DT S",
            },
        },
        "2020 ->": {
            "1.5 BlueHDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHS DV5RCE",
            },
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHZ DV5RC",
            },
            "1.2 PureTech 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "EB2DT",
            },
            "1.2 PureTech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "EB2DT S",
            },
            "1.2 PureTech 155hp": {
                "std_power": 155,
                "tuned_power": 165,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "EB2DT S",
            },
        },
    },
    "C4 Aircross": {
        "2012 ->": {
            "1.6 HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807 & Siemens/Continental SID807EVO & Denso RA6",
                "Engine_code": "DV6CTED",
            },
            "1.6i 117hp": {
                "std_power": 117,
                "tuned_power": 127,
                "std_torque": 154,
                "tuned_torque": 170,
                "cc": 1590,
                "Type_ecu": "Mitsubishi E6T78",
                "Engine_code": "4A92",
            },
        },
    },
    "C4 Cactus": {
        "2014 - 2018": {
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 e-HDI 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.2 VTi 82hp": {
                "std_power": 82,
                "tuned_power": 90,
                "std_torque": 118,
                "tuned_torque": 130,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2F",
            },
            "1.2 e-THP 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 205,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1",
                "Engine_code": "EB2DT",
            },
        },
        "2018 ->": {
            "1.5 BlueHDI 102hp": {
                "std_power": 102,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHY DV5RD",
            },
            "1.5 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHX DV5RC",
            },
            "1.6 BlueHDi 102hp": {
                "std_power": 102,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "BHY DV6FD",
            },
            "1.2 Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1 & Bosch MED17.4.4",
                "Engine_code": "HNV EB2DT",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "HNS EB2ADTS",
            },
        },
    },
    "C4 Picasso / C4 SpaceTourer": {
        "2006 - 2013": {
            "1.6 HDi 16V 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Siemens/Continental SID807",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "2.0 HDI 138hp": {
                "std_power": 138,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803 & Siemens/Continental SID803A",
                "Engine_code": "DW10BTED4",
            },
            "2.0 HDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10BTED4",
            },
            "2.0 HDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "",
            },
            "1.6 THP 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6CDT",
            },
            "1.6 THP 155hp": {
                "std_power": 155,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6CDT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6C",
            },
            "2.0i 143hp": {
                "std_power": 143,
                "tuned_power": 153,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1997,
                "Type_ecu": "Marelli IAW 6LP",
                "Engine_code": "RFJ",
            },
        },
        "2013 - 2016": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 e-HDI 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.6 e-HDI 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM6.2A & Delphi DCM6.2A",
                "Engine_code": "DW10FD",
            },
            "2.0 HDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 & Delphi DCM6.2A",
                "Engine_code": "DW10BTED4",
            },
            "1.6 THP 155hp": {
                "std_power": 155,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4",
                "Engine_code": "EP6C",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "EP6C",
            },
            "1.6 e-THP 165hp": {
                "std_power": 165,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "EP6CDT",
            },
        },
        "2017 ->": {
            "1.5 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHZ DV5RC",
            },
            "1.6 BlueHDi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "BHY DV6FD",
            },
            "1.6 BlueHDi 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 254,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "BHZ DV6FC",
            },
            "2.0 BlueHDi 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "AHX DW10FD",
            },
            "2.0 BlueHDi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "EHY DW10FCC",
            },
            "1.2 PureTech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46 & Valeo VD56.1",
                "Engine_code": "EB2DT",
            },
            "1.2 PureTech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "EB2DT S",
            },
            "1.6 THP 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "EP6FDTM",
            },
        },
    },
    "C5": {
        "2001 - 2008": {
            "1.6 HDiF 16V 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "2.0 HDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 205,
                "tuned_torque": 275,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID801",
                "Engine_code": "DW10",
            },
            "2.0 HDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2 & Siemens/Continental SID803A",
                "Engine_code": "DW10",
            },
            "2.0 HDiF FAP 136hp": {
                "std_power": 136,
                "tuned_power": 163,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Delphi DCM3.5",
                "Engine_code": "DW10BTED4 FAP",
            },
            "2.0 HDiF 138hp": {
                "std_power": 138,
                "tuned_power": 170,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "DW10BTED4",
            },
            "2.2 HDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 317,
                "tuned_torque": 400,
                "cc": 2179,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW12TED4",
            },
            "2.2 HDiF 173hp": {
                "std_power": 173,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16+ & Bosch EDC16C39",
                "Engine_code": "DW12C",
            },
            "2.0i 16v 136hp": {
                "std_power": 136,
                "tuned_power": 146,
                "std_torque": 190,
                "tuned_torque": 205,
                "cc": 1997,
                "Type_ecu": "Marelli IAW48P2 & Marelli IAW 6LP",
                "Engine_code": "EW10J4",
            },
            "2.0i HPi 143hp": {
                "std_power": 143,
                "tuned_power": 153,
                "std_torque": 192,
                "tuned_torque": 210,
                "cc": 1997,
                "Type_ecu": "Marelli IAW 6KPB",
                "Engine_code": "RLZ",
            },
            "3.0i V6 210hp": {
                "std_power": 210,
                "tuned_power": 220,
                "std_torque": 285,
                "tuned_torque": 300,
                "cc": 2946,
                "Type_ecu": "Bosch ME7.4.7",
                "Engine_code": "XFV",
            },
        },
        "2008 - >": {
            "1.6 HDI 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807 & Bosch EDC16C34",
                "Engine_code": "DV6CTED",
            },
            "1.6 HDiF 16V 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "1.6 e-HDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807 & Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM3.4 & Delphi DCM6.2A",
                "Engine_code": "DW10FD",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "2.0 HDI FAP 136hp": {
                "std_power": 136,
                "tuned_power": 163,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.4 & Delphi DCM3.5",
                "Engine_code": "DW12C",
            },
            "2.0 HDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10CTED4",
            },
            "2.2 HDI 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 435,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "DW10CTED4",
            },
            "2.2 HDI 204hp": {
                "std_power": 204,
                "tuned_power": 235,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "4HL",
            },
            "2.2 HDiF 173hp": {
                "std_power": 173,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16CP39",
                "Engine_code": "DW12BTED4 FAP",
            },
            "2.7 HDI 208hp": {
                "std_power": 208,
                "tuned_power": 240,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2720,
                "Type_ecu": "Siemens/Continental SID201",
                "Engine_code": "DT17TED4",
            },
            "3.0 HDI 207hp": {
                "std_power": 207,
                "tuned_power": 290,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "DT20C",
            },
            "3.0 HDI 241hp": {
                "std_power": 241,
                "tuned_power": 290,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "DT20C",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2 & Bosch MEVD17.4.4",
                "Engine_code": "EP6CDT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6C",
            },
            "2.0i 16v 143hp": {
                "std_power": 143,
                "tuned_power": 153,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1997,
                "Type_ecu": "Marelli IAW 6LP & Marelli IAW6 KPB FAL",
                "Engine_code": "EW10A",
            },
            "3.0i V6 210hp": {
                "std_power": 210,
                "tuned_power": 225,
                "std_torque": 285,
                "tuned_torque": 305,
                "cc": 2946,
                "Type_ecu": "Bosch ME7.4.7",
                "Engine_code": "ES9A",
            },
        },
    },
    "C5 Aircross": {
        "2018 - 2021": {
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "DV5",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1",
                "Engine_code": "EHZ DW10FC",
            },
            "1.2 PureTech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HNS EB2ADTS",
            },
            "1.6 Puretech 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042",
                "Engine_code": "5GF EP6FADTXD",
            },
        },
        "2022 -> ...": {
            "1.2 Puretech Hybrid 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech Hybrid 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 BlueHDI 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 Puretech 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "C5 X": {
        "2022 -> ...": {
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "EB2DTS",
            },
            "1.6 Puretech Hybrid 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "C6": {
        "2006 - 2013": {
            "2.2 HDi 173hp": {
                "std_power": 173,
                "tuned_power": 203,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16CP39",
                "Engine_code": "DW12BTED4 FAP",
            },
            "2.7 HDi 208hp": {
                "std_power": 208,
                "tuned_power": 240,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2720,
                "Type_ecu": "Siemens/Continental SID803 & Siemens/Continental SID201",
                "Engine_code": "DT17TED4",
            },
            "3.0 HDi 207hp": {
                "std_power": 207,
                "tuned_power": 290,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2992,
                "Type_ecu": "",
                "Engine_code": "DT20C",
            },
            "3.0 HDi 241hp": {
                "std_power": 241,
                "tuned_power": 290,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2992,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "DT20C",
            },
            "3.0i v6 215hp": {
                "std_power": 215,
                "tuned_power": 225,
                "std_torque": 290,
                "tuned_torque": 310,
                "cc": 2946,
                "Type_ecu": "Bosch ME7.4.7",
                "Engine_code": "XFV",
            },
        },
    },
    "C8": {
        "All": {
            "2.0 HDi 138hp": {
                "std_power": 138,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 Nexus",
                "Engine_code": "DW10BTED4 FAP",
            },
            "2.0 HDi 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 340,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW10ATED4 FAP",
            },
            "2.0 HDi 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803",
                "Engine_code": "DW10UTED4",
            },
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803",
                "Engine_code": "DW10CTED4",
            },
            "2.0 HDi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Delphi DCM3.5",
                "Engine_code": "DW10CB FAP DTR",
            },
            "2.2 HDi 130hp": {
                "std_power": 130,
                "tuned_power": 162,
                "std_torque": 314,
                "tuned_torque": 400,
                "cc": 2179,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW12TED4",
            },
            "2.2 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 410,
                "cc": 2179,
                "Type_ecu": "",
                "Engine_code": "DW12TED",
            },
            "2.2 HDi 173hp": {
                "std_power": 173,
                "tuned_power": 190,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "DW12BTED4 FAP",
            },
            "2.0i 16v 143hp": {
                "std_power": 143,
                "tuned_power": 150,
                "std_torque": 190,
                "tuned_torque": 210,
                "cc": 1998,
                "Type_ecu": "Marelli IAW 6LP",
                "Engine_code": "EW10J4",
            },
            "3.0i v6 208hp": {
                "std_power": 208,
                "tuned_power": 225,
                "std_torque": 285,
                "tuned_torque": 305,
                "cc": 2946,
                "Type_ecu": "Bosch ME7.4.4",
                "Engine_code": "ES9A",
            },
        },
    },
    "DS 7 Crossback": {
        "2018 ->": {
            "1.5 BlueHDi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "DV5",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "EHZ",
            },
            "1.6 PureTech E-Tense 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 PureTech 225hp": {
                "std_power": 225,
                "tuned_power": 240,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "5GC",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "HNS EB2ADTS",
            },
            "1.6 PureTech E-Tense 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 PureTech 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042",
                "Engine_code": "5GF EP6FADTXD",
            },
        },
    },
    "DS3": {
        "2010 - 2014": {
            "1.4 HDi 8V 68hp": {
                "std_power": 68,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV4TD",
            },
            "1.6 HDi 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.6 HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807 & Siemens/Continental SID807EVO & Bosch EDC17C10",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6CTED",
            },
            "1.2 VTi 82hp": {
                "std_power": 82,
                "tuned_power": 92,
                "std_torque": 116,
                "tuned_torque": 126,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2",
            },
            "1.4 VTi 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 135,
                "tuned_torque": 150,
                "cc": 1397,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP3C",
            },
            "1.6 THP Sportchic 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6FDT",
            },
            "1.6 THP 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 275,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2 & Bosch MED17.4",
                "Engine_code": "EP6DTS",
            },
            "1.6 THP 155hp": {
                "std_power": 155,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6FDT",
            },
            "1.6 THP 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2 & Bosch MED17.2",
                "Engine_code": "EP6FDT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 156,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6C",
            },
        },
        "2014 - 2015": {
            "1.4 e-HDi 8V 68hp": {
                "std_power": 68,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV4TD",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 BlueHDi 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Bosch EDC17C10",
                "Engine_code": "DV6FDTED",
            },
            "1.2 PureTech 82hp": {
                "std_power": 82,
                "tuned_power": 92,
                "std_torque": 118,
                "tuned_torque": 128,
                "cc": 1199,
                "Type_ecu": "Valeo V46.11",
                "Engine_code": "EB2",
            },
            "1.2 THP 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 205,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2DT",
            },
            "1.6 THP 155hp": {
                "std_power": 155,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6DT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6DTS",
            },
        },
        "2015 ->": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 BlueHDi 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.2 Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo V46.11 & Valeo VD56.1",
                "Engine_code": "EB2DT",
            },
            "1.6 THP 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2 & Bosch MED17.4.4",
                "Engine_code": "EP6CDT",
            },
        },
    },
    "DS4": {
        "2011 - 2015": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 210,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10FD",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10CB FAP DTR",
            },
            "1.6 THP 155hp": {
                "std_power": 155,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6CDT",
            },
            "1.6 THP 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 275,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6CDTX",
            },
            "1.6 THP 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4",
                "Engine_code": "EP6CDT M",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 158,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6C",
            },
        },
        "2015 ->": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2A",
                "Engine_code": "DW10FD",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "1.2 PureTech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2DT S",
            },
            "1.6 THP 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4",
                "Engine_code": "EP6CDT M",
            },
            "1.6 THP 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 285,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6CDTX",
            },
        },
    },
    "DS5": {
        "2011 - 2015": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 e-HDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807EVO & Bosch EDC17C60",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDI 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807EVO & Bosch EDC17C60",
                "Engine_code": "DV6CTED",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2A",
                "Engine_code": "DW10FD",
            },
            "2.0 HDI 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10FC",
            },
            "2.0 HDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 0,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 195,
                "std_torque": 275,
                "tuned_torque": 345,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6DT",
            },
            "1.6 THP 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 275,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2 & Bosch MEVD17.2",
                "Engine_code": "EP6CDTX",
            },
            "1.6 THP 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "EP6DT M",
            },
            "1.6 THP 210hp": {
                "std_power": 210,
                "tuned_power": 220,
                "std_torque": 275,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6CDTX",
            },
        },
        "2015 ->": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2",
                "Engine_code": "DW10FD",
            },
            "1.6 THP 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "EP6DT M",
            },
            "1.6 THP 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 285,
                "tuned_torque": 305,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6CDTX",
            },
        },
    },
    "Jumper": {
        "2006 - 2012": {
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 205,
                "tuned_torque": 275,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 & Bosch EDC15C7",
                "Engine_code": "DW10TD",
            },
            "2.2 HDi 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 2198,
                "Type_ecu": "Bosch EDC15 & Visteon DCU102",
                "Engine_code": "PUMA 22DT",
            },
            "2.2 HDi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208 & Visteon DCU102",
                "Engine_code": "PUMA 22DT",
            },
            "2.8 HDi 128hp": {
                "std_power": 128,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 0,
                "Type_ecu": "Bosch EDC15C7 & Bosch EDC17C70",
                "Engine_code": "SOFIM 2800 TD",
            },
            "3.0 HDi 157hp": {
                "std_power": 157,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 0,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17CP52",
                "Engine_code": "F 30DT Z",
            },
        },
        "2012 - 2014": {
            "2.2 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 410,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208 & Delphi DCM6.2C",
                "Engine_code": "PUMA 22DT",
            },
            "2.2 HDi 130hp": {
                "std_power": 130,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "PUMA 22DT",
            },
            "2.2 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "PUMA 22DT",
            },
            "3.0 HDi 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2999,
                "Type_ecu": "Bosch EDC17CP52",
                "Engine_code": "F 30DT Euro5",
            },
        },
        "2015 - 2019": {
            "2.0 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM6.2C",
                "Engine_code": "AHV",
            },
            "2.0 BlueHDI 110hp": {
                "std_power": 110,
                "tuned_power": 200,
                "std_torque": 300,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM6.2C",
                "Engine_code": "",
            },
            "2.0 BlueHDI 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2C & Delphi DCM6.2A",
                "Engine_code": "AHP",
            },
            "2.2 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "PUMA 22DT",
            },
            "2.2 HDi 130hp": {
                "std_power": 130,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 360,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208 & Delphi DCM6.2",
                "Engine_code": "PUMA 22DT",
            },
            "2.2 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "PUMA 22DT",
            },
            "2.2 HDi 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2198,
                "Type_ecu": "Delphi DCM7.1B",
                "Engine_code": "PUMA 22DT",
            },
            "3.0 HDi 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2999,
                "Type_ecu": "Bosch EDC17CP52",
                "Engine_code": "F 30DT Euro5",
            },
        },
        "2019 ->": {
            "2.2 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 185,
                "std_torque": 310,
                "tuned_torque": 430,
                "cc": 2179,
                "Type_ecu": "Siemens/Continental SID208 & Delphi DCM7.1B",
                "Engine_code": "",
            },
            "2.2 BlueHDI 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 2179,
                "Type_ecu": "Delphi DCM7.1 & Delphi DCM7.1B",
                "Engine_code": "",
            },
            "2.2 BlueHDI 165hp": {
                "std_power": 165,
                "tuned_power": 185,
                "std_torque": 370,
                "tuned_torque": 430,
                "cc": 2179,
                "Type_ecu": "Delphi DCM6.2C & Delphi DCM7.1B & Delphi DCM7.1A",
                "Engine_code": "",
            },
        },
    },
    "Jumpy": {
        "-> 2016": {
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV6ATED4",
            },
            "1.6 HDi 16V 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "",
            },
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 205,
                "tuned_torque": 275,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW10TED",
            },
            "2.0 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15 & Siemens/Continental SID803A",
                "Engine_code": "DW10TED",
            },
            "2.0 HDi 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10TED",
            },
            "2.0 HDi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Delphi DCM3.5",
                "Engine_code": "DW10UTED4",
            },
            "2.0 HDi 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 & Delphi DCM6.2A",
                "Engine_code": "DW10TED",
            },
            "2.0 HDi 128hp": {
                "std_power": 128,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10TED",
            },
        },
        "2016 - 2019": {
            "1.6 BlueHDI 8V 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 210,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DW10FC",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2 & Delphi DCM7.1A",
                "Engine_code": "DW10FD",
            },
            "2.0 BlueHDI 122hp": {
                "std_power": 122,
                "tuned_power": 205,
                "std_torque": 310,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2 & Delphi DCM7.1A",
                "Engine_code": "DW10FC",
            },
        },
        "2019 ->": {
            "1.5 BlueHDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "",
            },
            "1.5 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1998,
                "Type_ecu": "Delphi DCM7.1A & Delphi DCM7.1B",
                "Engine_code": "",
            },
            "2.0 BlueHDI 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1998,
                "Type_ecu": "Delphi DCM7.1A & Delphi DCM6.2A",
                "Engine_code": "",
            },
            "2.0 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 460,
                "cc": 1998,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "",
            },
        },
    },
    "Nemo": {
        "All": {
            "1.3 HDI 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8",
                "Engine_code": "",
            },
            "1.3 HDi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF & Marelli MJ8F3 & Marelli MJD9DF",
                "Engine_code": "199A9000",
            },
            "1.4 HDi 8V 70hp": {
                "std_power": 70,
                "tuned_power": 92,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1399,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV4TD",
            },
        },
    },
    "SpaceTourer": {
        "2016 ->": {
            "1.5 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHV DV5RUC",
            },
            "1.6 BlueHDi 8V 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 210,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1",
                "Engine_code": "AHX",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "AHH",
            },
        },
    },
    "Xantia": {
        "All": {
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 205,
                "tuned_torque": 275,
                "cc": 1998,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "",
            },
            "2.0 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1998,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "",
            },
            "2.0T 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "Bosch M3.2",
                "Engine_code": "",
            },
        },
    },
    "Xsara Picasso": {
        "All": {
            "1.4 HDi 8V 68hp": {
                "std_power": 68,
                "tuned_power": 98,
                "std_torque": 160,
                "tuned_torque": 220,
                "cc": 1399,
                "Type_ecu": "",
                "Engine_code": "DV4TD",
            },
            "1.6 HDi 16V 110hp": {
                "std_power": 110,
                "tuned_power": 137,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 205,
                "tuned_torque": 275,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "RHY",
            },
            "2.0 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 295,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID801 & Bosch EDC15C2",
                "Engine_code": "RHZ",
            },
            "2.0 HDi 138hp": {
                "std_power": 138,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "RFN",
            },
        },
    },
},
"Cupra": {
    "Ateca": {
        "2018 - 2019": {
            "2.0 TSI 300hp": {
                "std_power": 300,
                "tuned_power": 350,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Temic DQ381",
                "Engine_code": "DNUE",
            },
        },
        "2020 ->": {
            "2.0 TSI 300hp": {
                "std_power": 300,
                "tuned_power": 350,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Temic DQ381 & Siemens/Continental Simos 18 & Siemens/Continental Simos 19.3",
                "Engine_code": "DNFC",
            },
        },
    },
    "Formentor": {
        "2020 ->": {
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DTT",
            },
            "2.0 TSI 245hp": {
                "std_power": 245,
                "tuned_power": 327,
                "std_torque": 370,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.6",
                "Engine_code": "DNPA",
            },
            "1.4 TSI e-Hybrid 245hp": {
                "std_power": 245,
                "tuned_power": 285,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "",
                "Engine_code": "DGEA",
            },
            "1.4 TSI e-Hybrid 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ381",
                "Engine_code": "DPCA",
            },
            "2.0 TSI 310hp": {
                "std_power": 310,
                "tuned_power": 380,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ381 & Siemens/Continental Simos 19.6",
                "Engine_code": "DNFB",
            },
        },
    },
    "Ibiza": {
        "2018 ->": {
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "",
            },
        },
    },
    "Leon": {
        "2020 -> ...": {
            "2.0 TFSI 245hp": {
                "std_power": 245,
                "tuned_power": 327,
                "std_torque": 370,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.6",
                "Engine_code": "DNFB",
            },
            "1.4T e-Hybrid 204hp": {
                "std_power": 204,
                "tuned_power": 230,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1395,
                "Type_ecu": "",
                "Engine_code": "DGEA",
            },
            "1.4T e-Hybrid 245hp": {
                "std_power": 245,
                "tuned_power": 285,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.27 & Bosch MED17.1.21",
                "Engine_code": "DGEA",
            },
            "2.0 TFSI 310hp": {
                "std_power": 310,
                "tuned_power": 380,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Siemens/Continental Simos 19.6",
                "Engine_code": "DNFB",
            },
        },
    },
},
"DS": {
    "3 / Cabrio": {
        "2015 ->": {
            "1.5 BlueHDI 102hp": {
                "std_power": 102,
                "tuned_power": 140,
                "std_torque": 254,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "",
            },
            "1.5 BlueHDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 254,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "",
            },
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.2 Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2DT",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1 & Valeo VD46.1",
                "Engine_code": "EB2DTS",
            },
            "1.2 Puretech 155hp": {
                "std_power": 155,
                "tuned_power": 165,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "EB2DTS",
            },
            "1.6 THP Performance 208hp": {
                "std_power": 208,
                "tuned_power": 220,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "EP6CDTX",
            },
            "1.6 THP 165hp": {
                "std_power": 165,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "EP6CDT",
            },
        },
    },
    "3 Crossback": {
        "2019 ->": {
            "1.5 BlueHDI 102hp": {
                "std_power": 102,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHY DV5RD",
            },
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "YHZ DV5RC",
            },
            "1.5 BlueHDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "YH01",
            },
            "1.2 Puretech 102hp": {
                "std_power": 102,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HNK EB2ADTD",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HNS EB2ADTS",
            },
            "1.2 Puretech 155hp": {
                "std_power": 155,
                "tuned_power": 165,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HNN EB2ADTX",
            },
        },
    },
    "4": {
        "2015 - 2020": {
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDi 8V 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 BlueHDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "",
            },
            "2.0 BlueHDi 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10FD",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "1.2 PureTech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2DT S",
            },
            "1.6 THP 165hp": {
                "std_power": 165,
                "tuned_power": 215,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4",
                "Engine_code": "EP6CDT M",
            },
            "1.6 THP 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 275,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6CDTX",
            },
            "1.6 THP 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 285,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "EP6CDTX",
            },
        },
        "2021 -> ...": {
            "1.5 BlueHDI 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 Puretech 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech E-Tense 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "4 Crossback": {
        "2015 ->": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "",
                "Engine_code": "DV6FDTED",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "1.2 PureTech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2DT S",
            },
            "1.6 THP 165hp": {
                "std_power": 165,
                "tuned_power": 215,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4",
                "Engine_code": "EP6CDT M",
            },
        },
    },
    "5": {
        "2015 ->": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10FD",
            },
            "2.0d Hybrid 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 THP 165hp": {
                "std_power": 165,
                "tuned_power": 215,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4 & Bosch MED17.4.2",
                "Engine_code": "EP6DT M",
            },
            "1.6 THP 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 285,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "EP6CDTX",
            },
        },
    },
    "7 Crossback": {
        "2017 ->": {
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHZ",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "EHZ",
            },
            "1.6 Puretech E-Tense 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HNS EB2ADTS",
            },
            "1.6 Puretech E-Tense 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech 225hp": {
                "std_power": 225,
                "tuned_power": 240,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042",
                "Engine_code": "5GC",
            },
            "1.6 Puretech 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042 & Delphi DCM7.1A",
                "Engine_code": "5GC",
            },
        },
    },
    "9": {
        "2021 -> ...": {
            "1.6 Puretech E-Tense 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech E-Tense 360hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech 225hp": {
                "std_power": 225,
                "tuned_power": 240,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "5GG EP6FADTX",
            },
        },
    },
},
"Dacia": {
    "Dokker": {
        "2013 - 2016": {
            "1.5 DCI 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 180,
                "tuned_torque": 220,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "K9K 612",
            },
            "1.2 TCE 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental EMS3110 & Siemens/Continental EMS3150",
                "Engine_code": "H5FACD4",
            },
        },
        "2017 ->": {
            "1.5 BlueDCI 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 220,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006 & Bosch MD1CS016",
                "Engine_code": "K9K 872",
            },
            "1.5 DCI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 220,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Bosch MD1CS006",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch MD1CS006 & Bosch EDC17C84",
                "Engine_code": "K9K E6",
            },
            "1.2 TCE 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental EMS3110 & Siemens/Continental EMS3150",
                "Engine_code": "H5FACD4",
            },
            "1.3 TCe 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Duster": {
        "2010 - 2017": {
            "1.5 DCI 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Siemens/Continental SID306",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Delphi DCM3.4 & Bosch EDC17C84 & Siemens/Continental SID210",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Siemens/Continental SID310 & Siemens/Continental SID306",
                "Engine_code": "K9KCXX6",
            },
            "1.2 TCE 125hp": {
                "std_power": 125,
                "tuned_power": 140,
                "std_torque": 205,
                "tuned_torque": 230,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental EMS3110 & Siemens/Continental EMS3150 & Siemens/Continental EMS3155 & Siemens/Continental EMS3125",
                "Engine_code": "H5FB4E4",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 113,
                "std_torque": 148,
                "tuned_torque": 164,
                "cc": 1598,
                "Type_ecu": "Valeo V40 & Siemens/Continental EMS3125 & Siemens/Continental EMS3130 & Siemens/Continental EMS3120",
                "Engine_code": "H4MD4D4",
            },
        },
        "2018 - 2020": {
            "1.5 dCi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C84 & Bosch MD1CS003 & Bosch MD1CS006 & Siemens/Continental SID310",
                "Engine_code": "K9K 628",
            },
            "1.5 dCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006 & Bosch MD1CS016 & Siemens/Continental SID306 & Siemens/Continental SID310",
                "Engine_code": "K9K 666",
            },
            "1.5 dCi 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310 & Bosch MD1CS006",
                "Engine_code": "K9K 628",
            },
            "1.2 TCe 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 205,
                "tuned_torque": 265,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental EMS3150 & Siemens/Continental EMS3155",
                "Engine_code": "H5F410",
            },
            "1.3 TCe 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2021 -> ...": {
            "1.5 BlueDCi 116hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 TCe ECO-G 101hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 TCe 90hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Jogger": {
        "2022 -> ...": {
            "1.0 TCe 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 TCe 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Lodgy": {
        "2012 - 2017": {
            "1.5 DCI 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Siemens/Continental SID306 & Siemens/Continental SID310",
                "Engine_code": "K9KCXX6",
            },
            "1.2 TCE 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental EMS3110 & Siemens/Continental EMS3150 & Siemens/Continental EMS3155",
                "Engine_code": "H5FACD4",
            },
        },
        "2017 ->": {
            "1.5 DCI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Siemens/Continental SID306 & Siemens/Continental SID310",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "K9KCXX6",
            },
            "1.5 dCi 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 220,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS016",
                "Engine_code": "K9KCXX6",
            },
            "1.5 dCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006 & Bosch MD1CS016",
                "Engine_code": "K9K 872",
            },
            "1.2 TCE 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental EMS3110 & Siemens/Continental EMS3150 & Siemens/Continental EMS3155",
                "Engine_code": "H5FACD4",
            },
            "1.3 TCe 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Logan": {
        "2004 - 2012": {
            "1.5 DCI 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2 & Delphi DCM3.4",
                "Engine_code": "K9K-794",
            },
            "1.5 DCI 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 180,
                "tuned_torque": 220,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "K9K-794",
            },
            "1.5 DCI 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "K9KCXX6",
            },
            "1.2i 16V 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 107,
                "tuned_torque": 120,
                "cc": 1149,
                "Type_ecu": "Valeo V40 & Valeo V42",
                "Engine_code": "D4F-732",
            },
            "1.4i 75hp": {
                "std_power": 75,
                "tuned_power": 82,
                "std_torque": 112,
                "tuned_torque": 122,
                "cc": 1390,
                "Type_ecu": "Siemens/Continental SIRIUS 32 & Siemens/Continental EMS3125",
                "Engine_code": "K7M-710",
            },
            "1.6i 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Valeo V40 & Siemens/Continental EMS3130",
                "Engine_code": "K7MA812",
            },
            "1.6i 87hp": {
                "std_power": 87,
                "tuned_power": 95,
                "std_torque": 128,
                "tuned_torque": 145,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental SIRIUS 32 & Siemens/Continental EMS3132",
                "Engine_code": "H4MD4D4",
            },
        },
        "2013 - 2015": {
            "1.5 DCI 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "K9K-794",
            },
            "0.9 TCE 90hp": {
                "std_power": 90,
                "tuned_power": 107,
                "std_torque": 135,
                "tuned_torque": 175,
                "cc": 898,
                "Type_ecu": "Valeo V50",
                "Engine_code": "D4D-745",
            },
        },
        "2016 - 2020": {
            "1.5 DCI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Bosch MD1CS006",
                "Engine_code": "K9K-794",
            },
            "1.5 DCI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 220,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "K9K 95",
            },
            "0.9 TCe 90hp": {
                "std_power": 90,
                "tuned_power": 107,
                "std_torque": 135,
                "tuned_torque": 175,
                "cc": 898,
                "Type_ecu": "Valeo V50 & Siemens/Continental EMS3125",
                "Engine_code": "D4D-745",
            },
            "1.0 TCe 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 999,
                "Type_ecu": "Siemens/Continental EMS3150",
                "Engine_code": "H4D",
            },
        },
        "2021 ->": {
            "1.0i SCe 67hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "TCE 100 Eco-G 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "TCe 90 90hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Sandero": {
        "2008 - 2012": {
            "1.5 DCI 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K-794",
            },
            "1.5 DCI 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 180,
                "tuned_torque": 220,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "K9K-794",
            },
            "1.5 DCI 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCI 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Delphi DCM3.4",
                "Engine_code": "K9KCXX6",
            },
            "1.2i 16V 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 107,
                "tuned_torque": 125,
                "cc": 1149,
                "Type_ecu": "Valeo V42 & Siemens/Continental SIM32",
                "Engine_code": "D4F-732",
            },
            "1.4i 75hp": {
                "std_power": 75,
                "tuned_power": 82,
                "std_torque": 112,
                "tuned_torque": 122,
                "cc": 1390,
                "Type_ecu": "Siemens/Continental SIRIUS 32 & Siemens/Continental EMS3132",
                "Engine_code": "K7M-710",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 148,
                "tuned_torque": 160,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental EMS3130 & Siemens/Continental EMS3120",
                "Engine_code": "K7MA812",
            },
            "1.6i 8v 85hp": {
                "std_power": 85,
                "tuned_power": 95,
                "std_torque": 135,
                "tuned_torque": 150,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "H4MD4D4",
            },
        },
        "2013 - 2015": {
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCi 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "K9K-794",
            },
            "0.9 TCE 90hp": {
                "std_power": 90,
                "tuned_power": 107,
                "std_torque": 135,
                "tuned_torque": 175,
                "cc": 898,
                "Type_ecu": "Valeo V50",
                "Engine_code": "D4D-745",
            },
        },
        "2016 - 2020": {
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Bosch MD1CS006",
                "Engine_code": "K9KCXX6",
            },
            "1.5 DCi 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Delphi DCM3.4",
                "Engine_code": "K9K-794",
            },
            "0.9 TCe 90hp": {
                "std_power": 90,
                "tuned_power": 107,
                "std_torque": 135,
                "tuned_torque": 175,
                "cc": 898,
                "Type_ecu": "Valeo V50",
                "Engine_code": "H4B 408",
            },
            "1.0 TCe 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "H4D",
            },
            "RS 2.0 145hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2021 ->": {
            "1.0i SCe 67hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "TCE 100 Eco-G 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "TCe 90 90hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
},
"Dodge": {
    "Avenger": {
        "2007 - 2010": {
            "2.0 CRD 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BSY",
            },
            "2.4i 173hp": {
                "std_power": 173,
                "tuned_power": 195,
                "std_torque": 225,
                "tuned_torque": 245,
                "cc": 2360,
                "Type_ecu": "",
                "Engine_code": "2.4 World Gas Engine",
            },
            "3.5 V6 235hp": {
                "std_power": 235,
                "tuned_power": 255,
                "std_torque": 315,
                "tuned_torque": 340,
                "cc": 3518,
                "Type_ecu": "",
                "Engine_code": "EGF",
            },
            "3.6 V6 283hp": {
                "std_power": 283,
                "tuned_power": 305,
                "std_torque": 383,
                "tuned_torque": 420,
                "cc": 3604,
                "Type_ecu": "",
                "Engine_code": "Pentastar 3.6",
            },
        },
    },
    "Caliber": {
        "2006 - 2011": {
            "2.0 CRD 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BYL",
            },
            "2.2 CRD 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 2143,
                "Type_ecu": "",
                "Engine_code": "OM664",
            },
            "2.4T 295hp": {
                "std_power": 295,
                "tuned_power": 330,
                "std_torque": 363,
                "tuned_torque": 420,
                "cc": 2359,
                "Type_ecu": "Siemens/Continental SIM90T",
                "Engine_code": "ED4",
            },
            "2.0i 158hp": {
                "std_power": 158,
                "tuned_power": 180,
                "std_torque": 191,
                "tuned_torque": 210,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM90T",
                "Engine_code": "2.0 World Gasoline Engine",
            },
            "2.4i 172hp": {
                "std_power": 172,
                "tuned_power": 195,
                "std_torque": 224,
                "tuned_torque": 245,
                "cc": 2360,
                "Type_ecu": "Siemens/Continental SIM90E",
                "Engine_code": "2.4",
            },
        },
        "2012 ->": {
            "2.4 SRT4 295hp": {
                "std_power": 295,
                "tuned_power": 330,
                "std_torque": 363,
                "tuned_torque": 420,
                "cc": 2359,
                "Type_ecu": "Siemens/Continental SIM90T",
                "Engine_code": "ED4",
            },
        },
    },
    "Caravan": {
        "2001 - 2007": {
            "3.3 V6 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 280,
                "tuned_torque": 300,
                "cc": 3301,
                "Type_ecu": "",
                "Engine_code": "EGA",
            },
            "3.8 V8 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 332,
                "tuned_torque": 350,
                "cc": 3778,
                "Type_ecu": "",
                "Engine_code": "EGH",
            },
        },
        "2008 ->": {
            "3.6 V6 283hp": {
                "std_power": 283,
                "tuned_power": 305,
                "std_torque": 353,
                "tuned_torque": 375,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "Pentasar 3.6",
            },
            "4.0 V6 251hp": {
                "std_power": 251,
                "tuned_power": 270,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 3952,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Challenger": {
        "2008 ->": {
            "3.5 V6 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 339,
                "tuned_torque": 360,
                "cc": 3518,
                "Type_ecu": "Motorola NGC4",
                "Engine_code": "EGG",
            },
            "3.6 V6 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 363,
                "tuned_torque": 385,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2 & ZF 8HP45",
                "Engine_code": "Pentastar 3.6",
            },
            "6.4 HEMI V8 470hp": {
                "std_power": 470,
                "tuned_power": 500,
                "std_torque": 640,
                "tuned_torque": 700,
                "cc": 6410,
                "Type_ecu": "Motorola NGC5 & Siemens/Continental GPEC2A",
                "Engine_code": "ESG",
            },
            "Hellcat 6.2 V8 Supercharged 707hp": {
                "std_power": 707,
                "tuned_power": 740,
                "std_torque": 880,
                "tuned_torque": 918,
                "cc": 6166,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "6.2 Hemi V8",
            },
            "Hellcat Redeye 6.2 V8 Supercharged 797hp": {
                "std_power": 797,
                "tuned_power": 840,
                "std_torque": 959,
                "tuned_torque": 1010,
                "cc": 6166,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "ESD",
            },
            "R/T 5.7 V8 372hp": {
                "std_power": 372,
                "tuned_power": 390,
                "std_torque": 540,
                "tuned_torque": 560,
                "cc": 5654,
                "Type_ecu": "Motorola NGC4 & Siemens/Continental GPEC2",
                "Engine_code": "5.7 Hemi V8",
            },
            "SRT 392 6.4 V8 485hp": {
                "std_power": 485,
                "tuned_power": 505,
                "std_torque": 644,
                "tuned_torque": 665,
                "cc": 6410,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "6.4 Hemi V8",
            },
            "SRT8 6.1 V8 425hp": {
                "std_power": 425,
                "tuned_power": 445,
                "std_torque": 570,
                "tuned_torque": 590,
                "cc": 6059,
                "Type_ecu": "Motorola NGC4 & Siemens/Continental GPEC2",
                "Engine_code": "6.1 Hemi V8",
            },
        },
    },
    "Charger": {
        "2006 - 2010": {
            "2.7 V6 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 258,
                "tuned_torque": 280,
                "cc": 2736,
                "Type_ecu": "Motorola NCG5",
                "Engine_code": "EER",
            },
            "3.5 V6 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 339,
                "tuned_torque": 360,
                "cc": 3518,
                "Type_ecu": "",
                "Engine_code": "EGG",
            },
            "R/T 5.7 V8 370hp": {
                "std_power": 370,
                "tuned_power": 390,
                "std_torque": 536,
                "tuned_torque": 555,
                "cc": 5654,
                "Type_ecu": "Motorola NGC3",
                "Engine_code": "5.7 Hemi V8",
            },
            "SRT8 8.1 V8 432hp": {
                "std_power": 432,
                "tuned_power": 460,
                "std_torque": 569,
                "tuned_torque": 608,
                "cc": 6059,
                "Type_ecu": "Chrysler NGC4 & Chrysler NCG3",
                "Engine_code": "Hemi ESF",
            },
        },
        "2011 - 2018": {
            "3.6 V6 292hp": {
                "std_power": 292,
                "tuned_power": 310,
                "std_torque": 353,
                "tuned_torque": 375,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "Pentastar 3.6",
            },
            "6.2 Hemi V8 Hellcat 707hp": {
                "std_power": 707,
                "tuned_power": 740,
                "std_torque": 880,
                "tuned_torque": 918,
                "cc": 6162,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "6.2 Hemi Supercharged V8",
            },
            "R/T 5.7 Hemi V8 370hp": {
                "std_power": 370,
                "tuned_power": 385,
                "std_torque": 536,
                "tuned_torque": 562,
                "cc": 5654,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "EZH",
            },
            "SRT 392 6.4 V8 485hp": {
                "std_power": 485,
                "tuned_power": 505,
                "std_torque": 644,
                "tuned_torque": 665,
                "cc": 6410,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "6.4 Hemi V8",
            },
            "SRT8 6.1 V8 425hp": {
                "std_power": 425,
                "tuned_power": 445,
                "std_torque": 569,
                "tuned_torque": 590,
                "cc": 6059,
                "Type_ecu": "Motorola NGC3 & Siemens/Continental GPEC2",
                "Engine_code": "6.1 Hemi V8",
            },
        },
        "2019 ->": {
            "6.4 Hemi V8 485hp": {
                "std_power": 485,
                "tuned_power": 512,
                "std_torque": 644,
                "tuned_torque": 674,
                "cc": 6417,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "ESG",
            },
            "GT 3.6 V6 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 358,
                "tuned_torque": 373,
                "cc": 3604,
                "Type_ecu": "",
                "Engine_code": "Pentasar V6",
            },
            "R/T 5.7 Hemi V8 375hp": {
                "std_power": 375,
                "tuned_power": 395,
                "std_torque": 536,
                "tuned_torque": 566,
                "cc": 5654,
                "Type_ecu": "",
                "Engine_code": "EZH",
            },
            "SRT Hellcat 6.2 Hemi V8 707hp": {
                "std_power": 707,
                "tuned_power": 737,
                "std_torque": 881,
                "tuned_torque": 901,
                "cc": 6166,
                "Type_ecu": "",
                "Engine_code": "ESD",
            },
            "SXT 3.6 V6 292hp": {
                "std_power": 292,
                "tuned_power": 308,
                "std_torque": 353,
                "tuned_torque": 368,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "Pentasar V6",
            },
        },
    },
    "Dakota": {
        "2005 - 2011": {
            "3.7 V6 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 319,
                "tuned_torque": 340,
                "cc": 3701,
                "Type_ecu": "",
                "Engine_code": "3.7 Magnum V6",
            },
            "4.7 V8 235hp": {
                "std_power": 235,
                "tuned_power": 255,
                "std_torque": 400,
                "tuned_torque": 425,
                "cc": 4698,
                "Type_ecu": "Motorola NGC3",
                "Engine_code": "4.7 Magnum V8",
            },
            "4.7 V8 265hp": {
                "std_power": 265,
                "tuned_power": 285,
                "std_torque": 450,
                "tuned_torque": 475,
                "cc": 4698,
                "Type_ecu": "",
                "Engine_code": "4.7 Magnum V8 H.O.",
            },
        },
    },
    "Dart": {
        "2013 - 2016": {
            "1.4T Multiair 160hp": {
                "std_power": 160,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF & Marelli 8GMK",
                "Engine_code": "EAF",
            },
            "2.0i 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 1999,
                "Type_ecu": "",
                "Engine_code": "Tigershark",
            },
        },
    },
    "Durango": {
        "2010 ->": {
            "3.6 V6 290hp": {
                "std_power": 290,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 375,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "Pentasar 3.6",
            },
            "5.7 V8 360hp": {
                "std_power": 360,
                "tuned_power": 380,
                "std_torque": 530,
                "tuned_torque": 550,
                "cc": 5654,
                "Type_ecu": "Motorola NCG5 & Siemens/Continental GPEC2A",
                "Engine_code": "5.7 Hemi V8",
            },
            "SRT 6.4 V8 Hemi 475hp": {
                "std_power": 475,
                "tuned_power": 495,
                "std_torque": 637,
                "tuned_torque": 655,
                "cc": 6424,
                "Type_ecu": "Motorola NGC4",
                "Engine_code": "6.4 Hemi V8",
            },
        },
    },
    "Journey": {
        "2008 - 2011": {
            "2.0 CRD 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BWD",
            },
            "3.5 V6 235hp": {
                "std_power": 235,
                "tuned_power": 255,
                "std_torque": 315,
                "tuned_torque": 335,
                "cc": 3518,
                "Type_ecu": "",
                "Engine_code": "EGF",
            },
            "3.6 V6 283hp": {
                "std_power": 283,
                "tuned_power": 305,
                "std_torque": 353,
                "tuned_torque": 375,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "Pentastar 3.6",
            },
        },
    },
    "Magnum": {
        "2005 - 2008": {
            "3.5 V6 254hp": {
                "std_power": 254,
                "tuned_power": 275,
                "std_torque": 339,
                "tuned_torque": 360,
                "cc": 3518,
                "Type_ecu": "Motorola NGC3",
                "Engine_code": "EGJ",
            },
            "R/T 5.7 V8 345hp": {
                "std_power": 345,
                "tuned_power": 365,
                "std_torque": 529,
                "tuned_torque": 560,
                "cc": 5654,
                "Type_ecu": "",
                "Engine_code": "5.7 Hemi V8",
            },
            "SRT8 6.1 V8 425hp": {
                "std_power": 425,
                "tuned_power": 445,
                "std_torque": 469,
                "tuned_torque": 490,
                "cc": 6059,
                "Type_ecu": "",
                "Engine_code": "6.1 Hemi V8",
            },
        },
    },
    "Neon": {
        "2000 - 2005": {
            "SRT4 2.4i 215hp": {
                "std_power": 215,
                "tuned_power": 270,
                "std_torque": 332,
                "tuned_torque": 390,
                "cc": 2429,
                "Type_ecu": "Siemens/Continental SIM90T",
                "Engine_code": "EDV/EDT",
            },
        },
    },
    "Nitro": {
        "2007 - 2010": {
            "2.8 CRD 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2768,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "A 428 DOHC",
            },
            "3.7 V6 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 319,
                "tuned_torque": 340,
                "cc": 3701,
                "Type_ecu": "Motorola NGC4",
                "Engine_code": "3.7 Powertech V6",
            },
            "4.0 V6 260hp": {
                "std_power": 260,
                "tuned_power": 280,
                "std_torque": 359,
                "tuned_torque": 390,
                "cc": 3952,
                "Type_ecu": "Motorola NCG4",
                "Engine_code": "EGS",
            },
        },
    },
    "Ram": {
        "All": {
            "1500 EcoDiesel 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 570,
                "tuned_torque": 630,
                "cc": 2988,
                "Type_ecu": "Bosch EDC17C79 & Bosch EDC17C49 & Bosch EDC17C69 & ZF 8HP",
                "Engine_code": "",
            },
            "6.7 L6 350hp": {
                "std_power": 350,
                "tuned_power": 380,
                "std_torque": 890,
                "tuned_torque": 950,
                "cc": 6685,
                "Type_ecu": "Siemens/Continental CM2350B & Siemens/Continental CM2100 & Siemens/Continental CM2200 & Siemens/Continental CM2450B",
                "Engine_code": "ISB6.7",
            },
            "6.7 L6 375hp": {
                "std_power": 375,
                "tuned_power": 405,
                "std_torque": 1100,
                "tuned_torque": 1160,
                "cc": 6685,
                "Type_ecu": "Siemens/Continental CM2350B & Siemens/Continental CM2450B & Siemens/Continental CM2200",
                "Engine_code": "ISB6.7",
            },
            "6.7 L6 385hp": {
                "std_power": 385,
                "tuned_power": 415,
                "std_torque": 1150,
                "tuned_torque": 1210,
                "cc": 6685,
                "Type_ecu": "Siemens/Continental CM2350B & Siemens/Continental CM2200 & Siemens/Continental CM2450B",
                "Engine_code": "ISB6.7",
            },
            "3.6 V6 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 365,
                "tuned_torque": 395,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "Pentastar 3.6",
            },
            "3.7 V6 215hp": {
                "std_power": 215,
                "tuned_power": 235,
                "std_torque": 319,
                "tuned_torque": 340,
                "cc": 3701,
                "Type_ecu": "Motorola NGC4",
                "Engine_code": "3.7 Powertech V6",
            },
            "4.7 V8 235hp": {
                "std_power": 235,
                "tuned_power": 255,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 4698,
                "Type_ecu": "Motorola Chrysler",
                "Engine_code": "4.7 Magnum V8",
            },
            "5.7 Hemi V8 396hp": {
                "std_power": 396,
                "tuned_power": 424,
                "std_torque": 552,
                "tuned_torque": 586,
                "cc": 5654,
                "Type_ecu": "Siemens/Continental GPEC2A & Siemens/Continental CM2100 & Motorola NGC5 & ZF 8HP & Siemens/Continental GPEC2 & Motorola NGC4",
                "Engine_code": "5.7 Hemi V8",
            },
            "5.7 V8 345hp": {
                "std_power": 345,
                "tuned_power": 365,
                "std_torque": 540,
                "tuned_torque": 560,
                "cc": 5654,
                "Type_ecu": "Chrysler NGC5 & Chrysler NGC4 & Chrysler NCG3 & Siemens/Continental GPEC2A",
                "Engine_code": "5.7 Hemi V8",
            },
            "6.4 Hemi V8 410hp": {
                "std_power": 410,
                "tuned_power": 430,
                "std_torque": 582,
                "tuned_torque": 600,
                "cc": 6410,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "6.4 Hemi V8",
            },
            "TRX 6.2 Hemi Supercharged V8 702hp": {
                "std_power": 702,
                "tuned_power": 737,
                "std_torque": 882,
                "tuned_torque": 901,
                "cc": 6166,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "ESD",
            },
        },
    },
    "Viper": {
        "2008 ->": {
            "8.4 V10 SRT-10 600hp": {
                "std_power": 600,
                "tuned_power": 620,
                "std_torque": 760,
                "tuned_torque": 790,
                "cc": 8277,
                "Type_ecu": "Motorola NCG5",
                "Engine_code": "EWE",
            },
            "8.4 V10 640hp": {
                "std_power": 640,
                "tuned_power": 670,
                "std_torque": 815,
                "tuned_torque": 875,
                "cc": 8304,
                "Type_ecu": "Motorola NGC5 HWII",
                "Engine_code": "EWE",
            },
        },
    },
},
"Donkervoort": {
    "D8": {
        "All": {
            "1.8T 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 225,
                "tuned_torque": 280,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.8T 270hp": {
                "std_power": 270,
                "tuned_power": 280,
                "std_torque": 360,
                "tuned_torque": 400,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "D8 GT": {
        "All": {
            "1.8T 270hp": {
                "std_power": 270,
                "tuned_power": 280,
                "std_torque": 360,
                "tuned_torque": 400,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "D8 GTO": {
        "2016 -> ...": {
            "RS 2.5 TFSI 386hp": {
                "std_power": 386,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "S 2.5 TFSI 360hp": {
                "std_power": 360,
                "tuned_power": 420,
                "std_torque": 450,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "S 2.5 TFSI 345hp": {
                "std_power": 345,
                "tuned_power": 420,
                "std_torque": 450,
                "tuned_torque": 620,
                "cc": 2480,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
},
"Ferrari": {
    "296 GTB": {
        "2021 -> ...": {
            "3.0 V6 Bi-Turbo 830hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "348 GTS/GTB/Spider": {
        "All": {
            "3.4 V8 320hp": {
                "std_power": 320,
                "tuned_power": 345,
                "std_torque": 325,
                "tuned_torque": 350,
                "cc": 3405,
                "Type_ecu": "Bosch M2.7",
                "Engine_code": "F119D040",
            },
        },
    },
    "355 GTS/GTB/Spider": {
        "All": {
            "3.5 V8 380hp": {
                "std_power": 380,
                "tuned_power": 400,
                "std_torque": 360,
                "tuned_torque": 388,
                "cc": 3496,
                "Type_ecu": "Bosch M2.7",
                "Engine_code": "F129B/40",
            },
        },
    },
    "360 Modena/Spider": {
        "All": {
            "3.6 V8 401hp": {
                "std_power": 401,
                "tuned_power": 424,
                "std_torque": 375,
                "tuned_torque": 400,
                "cc": 3586,
                "Type_ecu": "Bosch ME7.3",
                "Engine_code": "F131B40",
            },
        },
    },
    "456": {
        "All": {
            "5.5 V12 428hp": {
                "std_power": 428,
                "tuned_power": 435,
                "std_torque": 490,
                "tuned_torque": 510,
                "cc": 5474,
                "Type_ecu": "Bosch M2.7",
                "Engine_code": "F116B00",
            },
        },
    },
    "458 Italia": {
        "All": {
            "4.5 V8 Speciale 605hp": {
                "std_power": 605,
                "tuned_power": 625,
                "std_torque": 540,
                "tuned_torque": 580,
                "cc": 4497,
                "Type_ecu": "Bosch MED9.6.1",
                "Engine_code": "F136FL",
            },
            "4.5 V8 570hp": {
                "std_power": 570,
                "tuned_power": 590,
                "std_torque": 540,
                "tuned_torque": 560,
                "cc": 4499,
                "Type_ecu": "Bosch MED9.6.1",
                "Engine_code": "F136FB",
            },
        },
    },
    "488 GTB/Spider": {
        "2015 ->": {
            "3.9 V8 Bi-Turbo Pista 720hp": {
                "std_power": 720,
                "tuned_power": 770,
                "std_torque": 770,
                "tuned_torque": 850,
                "cc": 3902,
                "Type_ecu": "Bosch MED17.6.9 & Bosch MED17.3.5",
                "Engine_code": "F154",
            },
            "3.9 V8 Bi-Turbo 670hp": {
                "std_power": 670,
                "tuned_power": 720,
                "std_torque": 760,
                "tuned_torque": 920,
                "cc": 3902,
                "Type_ecu": "Bosch MED17.3.5 & Bosch MED17.6.9",
                "Engine_code": "F154",
            },
        },
    },
    "512TR": {
        "All": {
            "5.0 V12 428hp": {
                "std_power": 428,
                "tuned_power": 447,
                "std_torque": 490,
                "tuned_torque": 510,
                "cc": 4942,
                "Type_ecu": "Bosch M2.7",
                "Engine_code": "F110HB",
            },
        },
    },
    "599 GTB": {
        "All": {
            "6.0 V12 620hp": {
                "std_power": 620,
                "tuned_power": 645,
                "std_torque": 608,
                "tuned_torque": 635,
                "cc": 5999,
                "Type_ecu": "Bosch ME7.3.2",
                "Engine_code": "F140C",
            },
        },
    },
    "599 GTO": {
        "All": {
            "6.0 V12 670hp": {
                "std_power": 670,
                "tuned_power": 680,
                "std_torque": 620,
                "tuned_torque": 635,
                "cc": 5999,
                "Type_ecu": "Bosch ME7.3.2",
                "Engine_code": "F140CE",
            },
        },
    },
    "612 Scaglietti": {
        "All": {
            "5.7 V12 540hp": {
                "std_power": 540,
                "tuned_power": 564,
                "std_torque": 588,
                "tuned_torque": 607,
                "cc": 5748,
                "Type_ecu": "Bosch ME7.3.2",
                "Engine_code": "F133F",
            },
        },
    },
    "812 Superfast": {
        "2017 ->": {
            "6.5 V12 800hp": {
                "std_power": 800,
                "tuned_power": 825,
                "std_torque": 718,
                "tuned_torque": 750,
                "cc": 6496,
                "Type_ecu": "Bosch MED17.3.5 & Bosch MED17.6.9",
                "Engine_code": "F140GA",
            },
        },
    },
    "California": {
        "All": {
            "3.9 V8 Turbo 560hp": {
                "std_power": 560,
                "tuned_power": 660,
                "std_torque": 755,
                "tuned_torque": 850,
                "cc": 3855,
                "Type_ecu": "Bosch MED17.3.5",
                "Engine_code": "F154BB",
            },
            "4.3 V8 460hp": {
                "std_power": 460,
                "tuned_power": 480,
                "std_torque": 485,
                "tuned_torque": 505,
                "cc": 4297,
                "Type_ecu": "Bosch MED9.6.1",
                "Engine_code": "F136IB",
            },
        },
    },
    "F12 Berlinetta": {
        "All": {
            "6.3 V12 740hp": {
                "std_power": 740,
                "tuned_power": 770,
                "std_torque": 690,
                "tuned_torque": 720,
                "cc": 6262,
                "Type_ecu": "Bosch MED9.6.1",
                "Engine_code": "F140FC",
            },
        },
    },
    "F430": {
        "All": {
            "4.3 V8 490hp": {
                "std_power": 490,
                "tuned_power": 517,
                "std_torque": 465,
                "tuned_torque": 490,
                "cc": 4308,
                "Type_ecu": "Bosch ME7.3.2",
                "Engine_code": "F136E",
            },
            "4.3 V8 510hp": {
                "std_power": 510,
                "tuned_power": 540,
                "std_torque": 490,
                "tuned_torque": 520,
                "cc": 4308,
                "Type_ecu": "Bosch ME7.3.2",
                "Engine_code": "F136ED",
            },
        },
    },
    "F8 Tributo / Spider": {
        "2020 -> ...": {
            "3.9 V8 Bi-Turbo 710hp": {
                "std_power": 710,
                "tuned_power": 800,
                "std_torque": 770,
                "tuned_torque": 900,
                "cc": 3902,
                "Type_ecu": "Bosch MED17.3.5",
                "Engine_code": "",
            },
        },
    },
    "FF": {
        "All": {
            "6.2 V12 660hp": {
                "std_power": 660,
                "tuned_power": 685,
                "std_torque": 683,
                "tuned_torque": 703,
                "cc": 6262,
                "Type_ecu": "Bosch MED9.6.1",
                "Engine_code": "F140EB",
            },
        },
    },
    "GTC 4 Lusso": {
        "2016 ->": {
            "3.9 V8 Bi-Turbo 610hp": {
                "std_power": 610,
                "tuned_power": 720,
                "std_torque": 760,
                "tuned_torque": 850,
                "cc": 3855,
                "Type_ecu": "Bosch MED17.3.5",
                "Engine_code": "F154BD",
            },
            "6.3 V12 690hp": {
                "std_power": 690,
                "tuned_power": 720,
                "std_torque": 697,
                "tuned_torque": 725,
                "cc": 6262,
                "Type_ecu": "Bosch MED17.3.5",
                "Engine_code": "F140ED",
            },
        },
    },
    "LaFerrari": {
        "2013 -> ...": {
            "6.3 V12 963hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "M575": {
        "All": {
            "5.8 V12 515hp": {
                "std_power": 515,
                "tuned_power": 537,
                "std_torque": 589,
                "tuned_torque": 612,
                "cc": 5748,
                "Type_ecu": "Bosch ME7.3.2",
                "Engine_code": "F133E",
            },
        },
    },
    "Portofino": {
        "2017 ->": {
            "3.9 V8 bi-Turbo 600hp": {
                "std_power": 600,
                "tuned_power": 690,
                "std_torque": 760,
                "tuned_torque": 910,
                "cc": 3855,
                "Type_ecu": "Bosch MED17.3.5",
                "Engine_code": "F154BD",
            },
        },
    },
    "Roma": {
        "2020 ->": {
            "3.9 V8 Bi-Turbo 620hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "SF90 Stradale": {
        "2020 -> ...": {
            "4.0 V8 Bi-Turbo 1000hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
},
"Fiat": {
    "124 Spider": {
        "2016 ->": {
            "1.4 MultiAir Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 240,
                "tuned_torque": 270,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMK",
                "Engine_code": "55253268",
            },
            "1.4 MultiAir Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 270,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF & Marelli 8GMK",
                "Engine_code": "55253268",
            },
        },
    },
    "500 / 595 / 695": {
        "500 - 2007 - 2015": {
            "1.3 JTDM 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 245,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3 & Marelli MJD8F2.E1",
                "Engine_code": "169A1000",
            },
            "1.3 JTDM 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ9DF & Marelli 8F3.C1 & Marelli MJD8F2",
                "Engine_code": "330A1000",
            },
            "1.3 JTDM 85hp": {
                "std_power": 85,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "",
                "Engine_code": "199B4000",
            },
            "1.6 JTDM 105hp": {
                "std_power": 105,
                "tuned_power": 135,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 JTDM 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "0.9 Twinair 85hp": {
                "std_power": 85,
                "tuned_power": 100,
                "std_torque": 145,
                "tuned_torque": 185,
                "cc": 875,
                "Type_ecu": "Marelli 8GSF & Marelli 8GSW",
                "Engine_code": "312A2000",
            },
            "0.9 Twinair 60hp": {
                "std_power": 60,
                "tuned_power": 70,
                "std_torque": 88,
                "tuned_torque": 100,
                "cc": 875,
                "Type_ecu": "Marelli MM8GSW",
                "Engine_code": "312A6000",
            },
            "0.9 Twinair 80hp": {
                "std_power": 80,
                "tuned_power": 90,
                "std_torque": 140,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli MM8GMF & Marelli MM8GSW",
                "Engine_code": "312A6000",
            },
            "0.9 Twinair 105hp": {
                "std_power": 105,
                "tuned_power": 110,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli 8GSF",
                "Engine_code": "199B6000",
            },
            "1.2i 8v 69hp": {
                "std_power": 69,
                "tuned_power": 75,
                "std_torque": 102,
                "tuned_torque": 112,
                "cc": 1242,
                "Type_ecu": "Marelli IAW5SF & Marelli 8GSF & Marelli 9GF",
                "Engine_code": "169A4000",
            },
            "1.4 T-Jet 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 206,
                "tuned_torque": 250,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0",
                "Engine_code": "",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 112,
                "std_torque": 131,
                "tuned_torque": 150,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Marelli 8GSF",
                "Engine_code": "169A3000",
            },
            "1.4i 16v 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 127,
                "tuned_torque": 140,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "169A3000",
            },
            "Abarth 1.4 T-Jet 135hp": {
                "std_power": 135,
                "tuned_power": 170,
                "std_torque": 206,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch MED17.3.0",
                "Engine_code": "312A1000",
            },
            "Abarth 1.4 T-Jet 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 206,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Marelli MM8GMW & Bosch MED17.3.0",
                "Engine_code": "312A3000",
            },
            "Abarth 1.4 T-Jet 160hp": {
                "std_power": 160,
                "tuned_power": 170,
                "std_torque": 234,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Marelli MM8GMF & Bosch MED17.3.0 & Bosch ME17.3.0",
                "Engine_code": "312A1000",
            },
            "Abarth 1.4 T-Jet 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch MED17.3.1",
                "Engine_code": "312A3000",
            },
            "Abarth 595 Competizione 1.4 T-Jet 160hp": {
                "std_power": 160,
                "tuned_power": 170,
                "std_torque": 234,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Marelli MM8GMF & Bosch MED17.3.0",
                "Engine_code": "312A1000",
            },
            "Abarth 595 Competizione 1.4 T-Jet 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch ME17.3.0",
                "Engine_code": "312A3000",
            },
            "Abarth 695 Tributo Ferrari 1.4 T-Jet 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "312A3000",
            },
            "Abarth 695 Tributo Maserati 1.4 T-Jet 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "312A3000",
            },
            "Abarth Assetto Corse 1.4 T-Jet 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "312A3000",
            },
            "Abarth Biposto 1.4 T-Jet 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "312A3000",
            },
        },
        "595 / 695 - 2016 ->": {
            "1.3 JTDM 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD9DF & Marelli MJD8DF",
                "Engine_code": "330A1000",
            },
            "0.9 TwinAir 80hp": {
                "std_power": 80,
                "tuned_power": 95,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli 8GSF & Marelli MM8GMF & Marelli 8GSW",
                "Engine_code": "312A5000",
            },
            "0.9 TwinAir 105hp": {
                "std_power": 105,
                "tuned_power": 110,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli 8GSW",
                "Engine_code": "",
            },
            "Abarth 1.4 T-Jet 135hp": {
                "std_power": 135,
                "tuned_power": 170,
                "std_torque": 206,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch ME17.3.0",
                "Engine_code": "312A1000",
            },
            "Abarth 1.4 T-Jet 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 206,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Marelli 8GMK",
                "Engine_code": "312A3000",
            },
            "Abarth 1.4 T-Jet 145hp": {
                "std_power": 145,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch MED17.3.0",
                "Engine_code": "312B4000",
            },
            "Abarth 595 Competizione 1.4 T-Jet 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch MED17.3.0 & Marelli 8GMK",
                "Engine_code": "312A3000",
            },
            "Abarth 595 Essesse 1.4 T-Jet 160hp": {
                "std_power": 160,
                "tuned_power": 170,
                "std_torque": 234,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Marelli MM8GMF & Bosch MED17.3.0",
                "Engine_code": "312A1000",
            },
            "Abarth 595 Pista 1.4 T-Jet 160hp": {
                "std_power": 160,
                "tuned_power": 170,
                "std_torque": 234,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Marelli MM8GMF & Bosch ME17.3.0",
                "Engine_code": "312A1000",
            },
            "Abarth 595 Turismo 1.4 T-Jet 160hp": {
                "std_power": 160,
                "tuned_power": 170,
                "std_torque": 234,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Marelli MM8GMF & Bosch ME17.3.0",
                "Engine_code": "312A1000",
            },
            "Abarth 595 Turismo 1.4 T-Jet 165hp": {
                "std_power": 165,
                "tuned_power": 200,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch MED17.3.0",
                "Engine_code": "312B3000",
            },
            "Abarth 695 XSR 1.4 T-Jet 163hp": {
                "std_power": 163,
                "tuned_power": 180,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch ME17.3.0",
                "Engine_code": "312AB6000",
            },
            "Abarth Biposto / Rivale 1.4 T-Jet 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "312A3000",
            },
        },
    },
    "500 L": {
        "2012 ->": {
            "1.3 JTDM 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8F3 & Marelli MJ9DF",
                "Engine_code": "199B4000",
            },
            "1.3 JTDM 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF & Marelli MJD9DF",
                "Engine_code": "330A1000",
            },
            "1.6 JTDM 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "199B5000",
            },
            "1.6 JTDM 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17C69",
                "Engine_code": "940C1000",
            },
            "0.9 Twinair 105hp": {
                "std_power": 105,
                "tuned_power": 110,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli 8GSF",
                "Engine_code": "199B6000",
            },
            "0.9 Twinair 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 140,
                "tuned_torque": 185,
                "cc": 875,
                "Type_ecu": "Marelli 8GSF",
                "Engine_code": "312A2000",
            },
            "1.4 T-jet 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 215,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0 & Marelli 8GMK",
                "Engine_code": "940B7000",
            },
            "1.4i 16v 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 127,
                "tuned_torque": 140,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0",
                "Engine_code": "843A1000",
            },
        },
    },
    "500 X": {
        "2015 - 2018": {
            "1.3 Multijet 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF & Marelli MJD9DF",
                "Engine_code": "55266963",
            },
            "1.6 Multijet 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55260384",
            },
            "2.0 Multijet 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55263088",
            },
            "1.4T MultiAir 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMK & Bosch MED17.3.0",
                "Engine_code": "55263624",
            },
            "1.4T MultiAir 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMK & Bosch ME17.3.0",
                "Engine_code": "55263623",
            },
        },
        "2019 ->": {
            "1.3 Multijet 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD9DF",
                "Engine_code": "55266963",
            },
            "2.0 Multijet 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55263088",
            },
            "1.5 GSE Hybrid 131hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "0.9 TwinAir 85hp": {
                "std_power": 85,
                "tuned_power": 95,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 Firefly Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 999,
                "Type_ecu": "Siemens/Continental GPEC4",
                "Engine_code": "55282151",
            },
            "1.3 Firefly Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 270,
                "tuned_torque": 310,
                "cc": 1332,
                "Type_ecu": "Siemens/Continental GPEC4 & Marelli MJ9DF",
                "Engine_code": "55282328",
            },
            "1.6 Multijet 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55260384",
            },
        },
    },
    "Brava": {
        "1998 - 2001": {
            "1.9 JTD 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1910,
                "Type_ecu": "",
                "Engine_code": "182A7000",
            },
            "1.9 JTD 105hp": {
                "std_power": 105,
                "tuned_power": 138,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "182B4000",
            },
            "1.9 JTD 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 295,
                "cc": 1910,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Bravo": {
        "2001 - 2007": {
            "1.9 JTD 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "182B9000",
            },
            "1.9 JTD 105hp": {
                "std_power": 105,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "182B4000",
            },
            "1.9 JTD 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 255,
                "tuned_torque": 335,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "182B4000",
            },
        },
        "2007 - 2015": {
            "1.6 M-JTD 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 290,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "198A3000",
            },
            "1.6 M-JTD 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C49",
                "Engine_code": "198A2000",
            },
            "1.6 M-JTD 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "198A6000",
            },
            "1.9 M-JTD 16v 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 305,
                "tuned_torque": 385,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "937A5000",
            },
            "1.9 M-JTD 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 255,
                "tuned_torque": 350,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "192A8000",
            },
            "1.9 M-JTD 115hp": {
                "std_power": 115,
                "tuned_power": 160,
                "std_torque": 255,
                "tuned_torque": 350,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "192A1000",
            },
            "2.0 M-JTD 165hp": {
                "std_power": 165,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "198A5000",
            },
            "1.4 Multiair 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMF",
                "Engine_code": "198A7000",
            },
            "1.4 T-Jet 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 206,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "198A4000",
            },
            "1.4 T-Jet 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "198A1000",
            },
            "1.4i 16v 90hp": {
                "std_power": 90,
                "tuned_power": 100,
                "std_torque": 127,
                "tuned_torque": 140,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch ME7.3H4",
                "Engine_code": "192B2000",
            },
        },
    },
    "Croma": {
        "2005 - 2008": {
            "1.9 JTDm 115hp": {
                "std_power": 115,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A7000",
            },
            "1.9 JTDm 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A1000",
            },
            "1.9 JTDm 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "",
                "Engine_code": "939A8000",
            },
            "1.9 JTDm 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 305,
                "tuned_torque": 385,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A2000",
            },
            "2.4 JTD 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A3000",
            },
        },
        "2008 - 2011": {
            "1.9 JTDm 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 385,
                "cc": 1910,
                "Type_ecu": "",
                "Engine_code": "939A8000",
            },
            "1.9 JTDm 115hp": {
                "std_power": 115,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "",
                "Engine_code": "939A7000",
            },
            "1.9 JTDm 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A1000",
            },
            "1.9 JTDm 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 385,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A2000",
            },
            "2.4 JTD 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A3000",
            },
        },
    },
    "Doblo": {
        "2001 - 2010": {
            "1.3 JTD 70hp": {
                "std_power": 70,
                "tuned_power": 85,
                "std_torque": 180,
                "tuned_torque": 220,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "188A9000",
            },
            "1.3 JTD 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3",
                "Engine_code": "223A9000",
            },
            "1.3 JTD 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3 & Marelli MJ8DFH",
                "Engine_code": "199A9000",
            },
            "1.9 JTD 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 290,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "182B9000",
            },
            "1.9 JTD 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 205,
                "tuned_torque": 290,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7 & Bosch EDC16C39",
                "Engine_code": "223B1000",
            },
            "1.9 JTD 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 280,
                "tuned_torque": 365,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "186A9000",
            },
            "1.4i 16v 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 127,
                "tuned_torque": 144,
                "cc": 1368,
                "Type_ecu": "",
                "Engine_code": "843A1000",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 145,
                "tuned_torque": 164,
                "cc": 1598,
                "Type_ecu": "Marelli IAW5NF",
                "Engine_code": "182B6000",
            },
        },
        "2010 - 2015": {
            "1.3 M-JTD 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF & Marelli MJD6JF & Marelli MJD8F3.D3 & Marelli MJ8F3H & Marelli MJD8F2 & Bosch EDC16C39 & Marelli MJD8F3.D4",
                "Engine_code": "199A3000",
            },
            "1.3 M-JTD 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli 8F3.F1 & Marelli 8F3.D4",
                "Engine_code": "",
            },
            "1.6 M-JTD 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 290,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C49",
                "Engine_code": "198A3000",
            },
            "1.6 M-JTD 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C49 & Marelli MJD6F3",
                "Engine_code": "199A3000",
            },
            "2.0 M-JTD 135hp": {
                "std_power": 135,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C49",
                "Engine_code": "263A1000",
            },
            "1.4 T-Jet 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 206,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch MED17.3.0",
                "Engine_code": "198A4000",
            },
            "1.4i 16v 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 127,
                "tuned_torque": 144,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch ME17.3.0",
                "Engine_code": "843A1000",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 145,
                "tuned_torque": 164,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "182B6000",
            },
        },
        "2015 - 2021": {
            "1.3 Multijet 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF & Marelli MJD9DF & Marelli 8F3.D4 & Marelli 8F3.QH",
                "Engine_code": "263A2000",
            },
            "1.6 Multijet 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17C69",
                "Engine_code": "263A5000",
            },
            "1.6 Multijet 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 290,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17C69 & Bosch EDC16C39",
                "Engine_code": "198A3000",
            },
            "1.6 Multijet 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 320,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "",
            },
            "1.6 Multijet 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 320,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17",
                "Engine_code": "",
            },
            "2.0 M-JTD 135hp": {
                "std_power": 135,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "263A1000",
            },
            "1.4 T-Jet 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 206,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0",
                "Engine_code": "198A4000",
            },
        },
    },
    "Ducato": {
        "2001 - 2011": {
            "2.0 JTD 84hp": {
                "std_power": 84,
                "tuned_power": 115,
                "std_torque": 192,
                "tuned_torque": 265,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "RHV",
            },
            "2.2 JTDM 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 0,
                "Type_ecu": "Visteon DCU102",
                "Engine_code": "",
            },
            "2.3 JTD 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 315,
                "cc": 2287,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC15C7",
                "Engine_code": "F1AE0481C",
            },
            "2.3 JTDM 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 2287,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "",
            },
            "2.3 JTDM 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 412,
                "cc": 2287,
                "Type_ecu": "Bosch EDC16C39 & Marelli MJ9DF & Marelli MJD8 & Bosch EDC15C7",
                "Engine_code": "",
            },
            "2.8 JTD 127hp": {
                "std_power": 127,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 370,
                "cc": 2800,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "B140.43S",
            },
            "2.8 JTD 145hp": {
                "std_power": 145,
                "tuned_power": 175,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 2800,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "B140.43N",
            },
            "3.0 JTDM 158hp": {
                "std_power": 158,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 510,
                "cc": 2999,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17CP52",
                "Engine_code": "",
            },
        },
        "2011 - 2016": {
            "2.0 115 Multijet 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49 & Marelli MJD8",
                "Engine_code": "250A1000",
            },
            "2.3 120 Multijet 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2287,
                "Type_ecu": "Marelli MJD8DF & Bosch EDC16",
                "Engine_code": "F1AE0481D",
            },
            "2.3 130 Multijet 130hp": {
                "std_power": 130,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 2287,
                "Type_ecu": "Marelli MJD8F3 & Marelli MJD8 & Marelli MJD9DF & Bosch EDC16C36 & Bosch EDC19C39 & Bosch EDC16C39",
                "Engine_code": "F1AE0481N",
            },
            "2.3 150 Multijet 148hp": {
                "std_power": 148,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2287,
                "Type_ecu": "Marelli MJD8DF & Marelli MJD9DF & Marelli MJD8F3",
                "Engine_code": "F1AE0481E",
            },
            "2.3 160 Multijet 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2287,
                "Type_ecu": "Bosch EDC17CP52 & Bosch EDC16C39",
                "Engine_code": "F1CE0481D",
            },
            "3.0 180 Multijet 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2999,
                "Type_ecu": "Marelli MJ9DF & Bosch EDC17CP52 & Bosch EDC17C79",
                "Engine_code": "F1CE0481E",
            },
        },
        "2016 - 2019": {
            "2.0 115 Multijet (EUR6) 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17C69",
                "Engine_code": "250A2000",
            },
            "2.3 130 Multijet (EUR6) 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2287,
                "Type_ecu": "Marelli MJD9DF & Marelli MJD8 & Bosch EDC17C69",
                "Engine_code": "F1AGL411D",
            },
            "2.3 150 Multijet (EUR6) 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 2287,
                "Type_ecu": "Marelli MJ9DF & Bosch EDC17C69 & Bosch EDC17C69",
                "Engine_code": "F1AE0481E",
            },
            "2.3 180 Multijet (EUR6) 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2287,
                "Type_ecu": "Marelli MJD9DF & Bosch EDC17CP52",
                "Engine_code": "F1AGL411B",
            },
        },
        "2019 - 2021": {
            "2.3 Multijet 120hp": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 460,
                "cc": 2287,
                "Type_ecu": "Marelli MJ9DF",
                "Engine_code": "",
            },
            "2.3 Multijet 140hp": {
                "std_power": 140,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 460,
                "cc": 2287,
                "Type_ecu": "Marelli MJ9DFH",
                "Engine_code": "",
            },
            "2.3 Multijet 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2287,
                "Type_ecu": "Marelli MJ9DF",
                "Engine_code": "",
            },
            "2.3 Multijet 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2287,
                "Type_ecu": "Marelli MJ9DFH",
                "Engine_code": "",
            },
            "2.3 Multijet 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2287,
                "Type_ecu": "Marelli MJ9DFH",
                "Engine_code": "",
            },
        },
        "2022 -> ...": {
            "2.2 Multijet 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.2 Multijet 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.2 Multijet 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.2 Multijet 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Fiorino": {
        "All": {
            "1.3 MJTD 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Marelli IAW4AF & Marelli MJ9DF & Marelli MJD6F3 & Marelli MJ8F3H & Marelli MJ8DF",
                "Engine_code": "199A2000",
            },
            "1.3 MJTD 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF & Marelli MJ9DF",
                "Engine_code": "199A9000",
            },
        },
    },
    "Freemont": {
        "2012 ->": {
            "2.0 JTDm 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "939B5000",
            },
            "2.0 JTDm 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "939B5000",
            },
            "2.0 JTDm 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "939B5000",
            },
            "2.0 JTDm 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "939B5000",
            },
        },
    },
    "Fullback": {
        "2017 ->": {
            "2.4D 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 430,
                "tuned_torque": 500,
                "cc": 2442,
                "Type_ecu": "Denso RA6 & Denso SH7059",
                "Engine_code": "",
            },
            "2.4D 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 380,
                "tuned_torque": 500,
                "cc": 2442,
                "Type_ecu": "Denso RA6",
                "Engine_code": "",
            },
        },
    },
    "Grande Punto": {
        "2005 ->": {
            "1.3 JTD 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3 & Marelli MJD8F2.E1 & Marelli MJD8F3.GB",
                "Engine_code": "199A2000",
            },
            "1.3 JTD 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3",
                "Engine_code": "199A3000",
            },
            "1.3 JTD 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3",
                "Engine_code": "188A9000",
            },
            "1.3 Mjet 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3 & Marelli MJD8F2 & Marelli MJD8F3",
                "Engine_code": "263A2000",
            },
            "1.3 Mjet 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF & Marelli MJD8F2",
                "Engine_code": "233A9000",
            },
            "1.6 JTDm 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "955A3000",
            },
            "1.9 JTD 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "939A1000",
            },
            "1.9 JTD 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "199A5000",
            },
            "1.4 T-Jet 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "",
            },
            "1.4 T-jet 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 206,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "198A4000",
            },
            "1.4i 16v 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 127,
                "tuned_torque": 144,
                "cc": 1368,
                "Type_ecu": "Marelli IAW5SF & Bosch ME7.6.3",
                "Engine_code": "199A6000",
            },
            "Abarth 1.4 T-Jet 155hp": {
                "std_power": 155,
                "tuned_power": 180,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "199A8000",
            },
            "Abarth 1.4 T-Jet 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "199A8000",
            },
            "Abarth Supersport 1.4 T-Jet 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF & Bosch ME7.9.10",
                "Engine_code": "199A8000",
            },
        },
    },
    "Idea": {
        "All": {
            "1.3 JTD 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 180,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "188A9000",
            },
            "1.3 JTD 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3",
                "Engine_code": "199A3000",
            },
            "1.3 Multijet 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF",
                "Engine_code": "199B1000",
            },
            "1.9 JTD 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 340,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9",
                "Engine_code": "188A9000",
            },
            "1.4i 16v 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 128,
                "tuned_torque": 145,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.3.1 & Bosch ME7.9.10",
                "Engine_code": "843A1000",
            },
        },
    },
    "Linea": {
        "2007 - 2012": {
            "1.3 Multijet 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ6F3",
                "Engine_code": "199A3000",
            },
        },
    },
    "Marea": {
        "All": {
            "1.9 JTD 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7 & Bosch EDC16C39",
                "Engine_code": "",
            },
            "1.9 JTD 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 295,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "182B4000",
            },
            "1.9 JTD 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 203,
                "tuned_torque": 260,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "",
            },
            "2.4 JTD 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 310,
                "tuned_torque": 365,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C5",
                "Engine_code": "185A6000",
            },
        },
    },
    "Multipla": {
        "All": {
            "1.9 JTD 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "182B9000",
            },
            "1.9 JTD 105hp": {
                "std_power": 105,
                "tuned_power": 138,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C5",
                "Engine_code": "182B4000",
            },
            "1.9 JTD 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 295,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "186A6000",
            },
            "1.9 JTD 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 203,
                "tuned_torque": 260,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "186A8000",
            },
            "1.9 Multijet 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 206,
                "tuned_torque": 270,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "186A9000",
            },
        },
    },
    "Ottimo": {
        "2014 ->": {
            "1.4 T-Jet 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 207,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0",
                "Engine_code": "343B0027",
            },
            "1.4 T-Jet 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 231,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0",
                "Engine_code": "343A0027",
            },
        },
    },
    "Panda": {
        "All": {
            "1.3 JTD 70hp": {
                "std_power": 70,
                "tuned_power": 85,
                "std_torque": 145,
                "tuned_torque": 180,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "188A9000",
            },
            "1.3 JTDm 75hp": {
                "std_power": 75,
                "tuned_power": 90,
                "std_torque": 145,
                "tuned_torque": 180,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF & Marelli MJ8F3 & Marelli MJD8F2",
                "Engine_code": "199A3000",
            },
            "1.3 JTDm 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF & Marelli MJD9DF",
                "Engine_code": "199B1000",
            },
            "0.9 Twinair 85hp": {
                "std_power": 85,
                "tuned_power": 100,
                "std_torque": 145,
                "tuned_torque": 185,
                "cc": 875,
                "Type_ecu": "Marelli 8GSW",
                "Engine_code": "312A2000",
            },
            "0.9 Twinair 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 145,
                "tuned_torque": 185,
                "cc": 875,
                "Type_ecu": "Marelli 8GSW",
                "Engine_code": "312A5000",
            },
            "1.4 100hp SPORT": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 131,
                "tuned_torque": 150,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.6.3",
                "Engine_code": "169A3000",
            },
        },
    },
    "Punto": {
        "2003->": {
            "1.3 JTD 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 180,
                "tuned_torque": 230,
                "cc": 1251,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "188A9000",
            },
            "1.3 M-Jet 85hp": {
                "std_power": 85,
                "tuned_power": 105,
                "std_torque": 204,
                "tuned_torque": 250,
                "cc": 1268,
                "Type_ecu": "Marelli MJD8DF & Marelli MJ8F3 & Marelli MJD8F2",
                "Engine_code": "188A2000",
            },
            "1.3 M-Jet 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1268,
                "Type_ecu": "Marelli MJD6F3 & Marelli MJD8DS2.S2 & Marelli MJD8F2.G1 & Marelli MJD8F3",
                "Engine_code": "188A9000",
            },
            "1.6 JTDm 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.9 JTD 80hp": {
                "std_power": 80,
                "tuned_power": 115,
                "std_torque": 196,
                "tuned_torque": 260,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C",
                "Engine_code": "188A2000",
            },
            "1.9 JTD 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 340,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "188B2000",
            },
            "1.9 JTD 85hp": {
                "std_power": 85,
                "tuned_power": 115,
                "std_torque": 196,
                "tuned_torque": 260,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "199B4000",
            },
            "1.2i 16v 80hp": {
                "std_power": 80,
                "tuned_power": 87,
                "std_torque": 114,
                "tuned_torque": 124,
                "cc": 1242,
                "Type_ecu": "Bosch ME7.3H4",
                "Engine_code": "188A5000",
            },
            "1.2i 8v 60hp": {
                "std_power": 60,
                "tuned_power": 67,
                "std_torque": 102,
                "tuned_torque": 112,
                "cc": 1242,
                "Type_ecu": "Marelli IAW5AF & Marelli IAW59F & Marelli IAW5NF & Marelli IAW5SF",
                "Engine_code": "188A4000",
            },
            "1.4i 16V 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 128,
                "tuned_torque": 144,
                "cc": 1268,
                "Type_ecu": "Bosch ME7.3H4 & Marelli IAW5SF",
                "Engine_code": "843A1000",
            },
        },
        "2012 ->": {
            "1.3 Mjet 85hp": {
                "std_power": 85,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF & Marelli 8F3.GA",
                "Engine_code": "199B4000",
            },
            "1.3 Mjet 80hp": {
                "std_power": 80,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8",
                "Engine_code": "199B8000",
            },
            "1.3 Mjet 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF & Marelli MJD9DF & Marelli MJD6F3",
                "Engine_code": "199B1000",
            },
            "1.6 Mjet 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "",
            },
            "0.9 Twinair 85hp": {
                "std_power": 85,
                "tuned_power": 95,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli 8GSF",
                "Engine_code": "312A2000",
            },
            "0.9 Twinair 100hp": {
                "std_power": 100,
                "tuned_power": 105,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli 8GSW",
                "Engine_code": "199B7000",
            },
        },
    },
    "Punto EVO": {
        "2009 ->": {
            "1.3 Mjet 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF & Marelli MJD6F3",
                "Engine_code": "199B2000",
            },
            "1.3 Mjet 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF & Marelli MJ8F3 & Marelli MJD8F2",
                "Engine_code": "199B4000",
            },
            "1.3 Mjet 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF & Marelli MJD6F3",
                "Engine_code": "199B1000",
            },
            "1.3 Mjet 80hp": {
                "std_power": 80,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8F3H",
                "Engine_code": "199B8000",
            },
            "1.4 Multiair 135hp": {
                "std_power": 135,
                "tuned_power": 150,
                "std_torque": 206,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMF",
                "Engine_code": "955A2000",
            },
            "1.6 Mjet 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "955A3000",
            },
            "Abarth 1.4 T-jet Essesse 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMF",
                "Engine_code": "955A8000",
            },
            "Abarth 1.4 T-jet Scorpione 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1368,
                "Type_ecu": "",
                "Engine_code": "955A8000",
            },
            "Abarth 1.4 T-jet Supersport 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1368,
                "Type_ecu": "Marelli MM8GMF",
                "Engine_code": "955A8000",
            },
            "Abarth 1.4 T-jet 165hp": {
                "std_power": 165,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMF & Bosch ME7.9.10",
                "Engine_code": "955A8000",
            },
        },
    },
    "Qubo": {
        "2008 ->": {
            "1.3 Mjet 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3 & Marelli MJD8F3.QA & Marelli MJD8F2.F4",
                "Engine_code": "199A2000",
            },
            "1.3 Mjet 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DF & Marelli MJD9DF",
                "Engine_code": "199B1000",
            },
            "1.3 Mjet 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD9DF & Marelli MJD9DF.F5",
                "Engine_code": "225 A2.000",
            },
        },
    },
    "Scudo": {
        "1995 - 2007": {
            "2.0 JTD 109hp": {
                "std_power": 109,
                "tuned_power": 132,
                "std_torque": 250,
                "tuned_torque": 295,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "RHK",
            },
        },
        "2007 - 2020": {
            "1.6 JTDm 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 180,
                "tuned_torque": 240,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "9HU",
            },
            "1.6 JTDm 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "",
            },
            "2.0 JTD 110hp": {
                "std_power": 110,
                "tuned_power": 132,
                "std_torque": 250,
                "tuned_torque": 295,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "RHZ",
            },
            "2.0 JTDm 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A",
                "Engine_code": "RHR",
            },
            "2.0 JTDm 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Delphi DCM3.5",
                "Engine_code": "RHK",
            },
            "2.0 JTDm 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 425,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RH02",
            },
            "2.0 JTDm 128hp": {
                "std_power": 128,
                "tuned_power": 185,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RH02",
            },
        },
        "2021 -> ...": {
            "1.5 Mjet 102hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1",
                "Engine_code": "",
            },
            "2.0 BlueHDI 145hp": {
                "std_power": 145,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1",
                "Engine_code": "",
            },
        },
    },
    "Sedici": {
        "All": {
            "1.9 JTD 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D19AA",
            },
            "2.0 Mjet 135hp": {
                "std_power": 135,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D20AA",
            },
            "2.0 Mjet 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 367,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Stilo": {
        "2001 - 2007": {
            "1.9 JTD 80hp": {
                "std_power": 80,
                "tuned_power": 112,
                "std_torque": 196,
                "tuned_torque": 268,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "192A3000",
            },
            "1.9 JTD 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "192A9000",
            },
            "1.9 JTD 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 255,
                "tuned_torque": 325,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7 & Bosch EDC16C39",
                "Engine_code": "D19AA",
            },
            "1.9 JTD 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 280,
                "tuned_torque": 345,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "192A8000",
            },
            "1.9 JTD 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 370,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "192A5000",
            },
            "1.9 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "937A5000",
            },
            "2.4i 20v 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 221,
                "tuned_torque": 240,
                "cc": 2446,
                "Type_ecu": "Bosch ME7.3H4",
                "Engine_code": "192A2000",
            },
        },
    },
    "Strada": {
        "All": {
            "1.3 MJTD 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3",
                "Engine_code": "223A9000",
            },
            "1.3 MJTD 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF",
                "Engine_code": "199B1000",
            },
            "1.9 JTD 80hp": {
                "std_power": 80,
                "tuned_power": 110,
                "std_torque": 196,
                "tuned_torque": 260,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "188A2000",
            },
        },
    },
    "Talento": {
        "2016 - 2019": {
            "1.6 EcoJet TwinTurbo 125hp": {
                "std_power": 125,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Bosch MD1CS006",
                "Engine_code": "R9MMH",
            },
            "1.6 EcoJet TwinTurbo 145hp": {
                "std_power": 145,
                "tuned_power": 165,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Bosch MD1CS006",
                "Engine_code": "R9MMG",
            },
            "1.6 Ecojet 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch MD1CS006 & Bosch EDC17C84",
                "Engine_code": "R9M 413",
            },
            "1.6 MultJet 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M H4",
            },
            "1.6 MultJet 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69 & Bosch EDC17C84 & Bosch MD1CS006",
                "Engine_code": "R9M H4",
            },
        },
        "2020 ->": {
            "1.6 Ecojet 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M 413",
            },
            "2.0 Ecojet 120hp": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "2.0 Ecojet 145hp": {
                "std_power": 145,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "2.0 Ecojet 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
        },
    },
    "Tipo": {
        "2016 - 2020": {
            "1.3 Mjet 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD9DF",
                "Engine_code": "55266963",
            },
            "1.6 Mjet 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55260384",
            },
            "1.4 T-Jet 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 215,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0 & Bosch M7.9.10",
                "Engine_code": "940B7000",
            },
            "1.4i 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 127,
                "tuned_torque": 140,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0",
                "Engine_code": "843A1000",
            },
            "1.6i 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 152,
                "tuned_torque": 182,
                "cc": 1598,
                "Type_ecu": "Marelli 9GF",
                "Engine_code": "55263842",
            },
        },
        "2021 ->": {
            "1.5 GSE Hybrid 131hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0T 100hp": {
                "std_power": 100,
                "tuned_power": 120,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 999,
                "Type_ecu": "Siemens/Continental GPEC4",
                "Engine_code": "55282151",
            },
        },
    },
    "Topolino": {
        "2022 -> ...": {
            "5.5 kWh 8hp": {
                "std_power": 8,
                "tuned_power": 8,
                "std_torque": 39,
                "tuned_torque": 39,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Ulysse": {
        "All": {
            "2.0 JTD 110hp": {
                "std_power": 110,
                "tuned_power": 132,
                "std_torque": 250,
                "tuned_torque": 292,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "",
            },
            "2.0 JTD 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 375,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A",
                "Engine_code": "",
            },
            "2.2 JTD 128hp": {
                "std_power": 128,
                "tuned_power": 155,
                "std_torque": 314,
                "tuned_torque": 400,
                "cc": 2179,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "",
            },
        },
    },
    "Viaggio": {
        "2014 ->": {
            "1.4 T-Jet 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 207,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0",
                "Engine_code": "343B0027",
            },
            "1.4 T-Jet 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 231,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME17.3.0",
                "Engine_code": "343A0027",
            },
        },
    },
},
"Ford": {
    "B-Max": {
        "2012 ->": {
            "1.5 TDCI 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 215,
                "tuned_torque": 310,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70 & Bosch EDC17C10",
                "Engine_code": "UGJC",
            },
            "1.5 TDCI 75hp": {
                "std_power": 75,
                "tuned_power": 130,
                "std_torque": 185,
                "tuned_torque": 280,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C10 & Bosch EDC17C70",
                "Engine_code": "UGJC",
            },
            "1.6 TDCI 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 215,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Bosch EDC17C70",
                "Engine_code": "T3JB",
            },
            "1.0 EcoBoost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "SFJC",
            },
            "1.0 EcoBoost 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "M1JA",
            },
            "1.0 EcoBoost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "M1JH",
            },
            "1.6 Ti-VCT 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental EMS2205",
                "Engine_code": "IQJA",
            },
        },
    },
    "Bronco": {
        "2021 -> ...": {
            "1.5T Ecoboost 181hp": {
                "std_power": 181,
                "tuned_power": 190,
                "std_torque": 258,
                "tuned_torque": 340,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS017",
                "Engine_code": "",
            },
            "2.3T Ecoboost 270hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.7T Ecoboost V6 310hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0T Ecoboost Raptor 400hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Bronco Sport": {
        "2021 -> ...": {
            "1.5T Ecoboost 181hp": {
                "std_power": 181,
                "tuned_power": 190,
                "std_torque": 258,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS017",
                "Engine_code": "",
            },
            "2.0T Ecoboost 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 373,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MG1CS017",
                "Engine_code": "",
            },
        },
    },
    "C-Max": {
        "2007 - 2010": {
            "1.6 TDCI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C3 & Bosch EDC16C34",
                "Engine_code": "HHDA",
            },
            "1.6 TDCI 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 245,
                "tuned_torque": 315,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "G8DB",
            },
            "1.6 TDCI 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 215,
                "tuned_torque": 290,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "G8DC",
            },
            "1.8 TDCI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID803A & Siemens/Continental SID202 & Siemens/Continental SID206",
                "Engine_code": "KKDA",
            },
            "2.0 TDCI 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803 & Siemens/Continental SID206",
                "Engine_code": "G6DB",
            },
            "2.0 TDCI 115hp": {
                "std_power": 115,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 & Siemens/Continental SID206",
                "Engine_code": "T1DB",
            },
            "2.0 TDCI 110hp": {
                "std_power": 110,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A",
                "Engine_code": "",
            },
            "1.6i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 157,
                "tuned_torque": 174,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental SIM28",
                "Engine_code": "SHDA",
            },
        },
        "2011 - 2015": {
            "1.6 TDCI 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "T3DB",
            },
            "1.6 TDCI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807 & Siemens/Continental SID206",
                "Engine_code": "T1DA",
            },
            "2.0 TDCI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 415,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "TXDB",
            },
            "2.0 TDCI 115hp": {
                "std_power": 115,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "T1DB",
            },
            "2.0 TDCI 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID206 & Delphi DCM3.5",
                "Engine_code": "UKDB",
            },
            "2.0 TDCI 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "UFDB",
            },
            "1.0 EcoBoost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "M1DA",
            },
            "1.0 EcoBoost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "M2DA",
            },
            "1.6 EcoBoost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0",
                "Engine_code": "JQDB",
            },
            "1.6 EcoBoost 182hp": {
                "std_power": 182,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "JTDB",
            },
        },
        "2015 ->": {
            "1.5 TDCI 95hp": {
                "std_power": 95,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 350,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XXDC",
            },
            "1.5 TDCI 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 350,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XWDB",
            },
            "1.5 TDCI 105hp": {
                "std_power": 105,
                "tuned_power": 125,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "KVJA",
            },
            "2.0 TDCi 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "T7DB",
            },
            "2.0 TDCi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "T8DE",
            },
            "1.0 EcoBoost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.1 & Bosch MD1CS016",
                "Engine_code": "M2DA",
            },
            "1.0 EcoBoost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.1 & Bosch MG1CS016",
                "Engine_code": "M1DA",
            },
            "1.5 EcoBoost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 999,
                "Type_ecu": "Siemens/Continental EMS2511",
                "Engine_code": "M8D8",
            },
        },
    },
    "Crown Victoria": {
        "2005 - 2012": {
            "4.6 V8 239hp": {
                "std_power": 239,
                "tuned_power": 260,
                "std_torque": 382,
                "tuned_torque": 410,
                "cc": 4601,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "4.6 Modular",
            },
        },
    },
    "E-Series": {
        "2006 - 2014": {
            "4.6 V8 225hp": {
                "std_power": 225,
                "tuned_power": 245,
                "std_torque": 388,
                "tuned_torque": 410,
                "cc": 4601,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "4.6 Modular",
            },
            "5.4 V8 255hp": {
                "std_power": 255,
                "tuned_power": 275,
                "std_torque": 438,
                "tuned_torque": 465,
                "cc": 5408,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "5.4 Modular",
            },
        },
    },
    "EcoSport": {
        "2014 - 2018": {
            "1.5 TDCI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 204,
                "tuned_torque": 250,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70 & Bosch EDC17C10",
                "Engine_code": "UGJE",
            },
            "1.5 TDCI 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 215,
                "tuned_torque": 280,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "",
            },
            "1.0 EcoBoost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1 & Bosch MED17.0.7",
                "Engine_code": "M1JJ",
            },
            "1.0 EcoBoost 140hp": {
                "std_power": 140,
                "tuned_power": 145,
                "std_torque": 210,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.1",
                "Engine_code": "YYJD",
            },
            "1.5 Ti-VCT 112hp": {
                "std_power": 112,
                "tuned_power": 135,
                "std_torque": 140,
                "tuned_torque": 180,
                "cc": 1499,
                "Type_ecu": "Siemens/Continental EMS2211 & Bosch ME17.0.3 & Siemens/Continental EMS2302",
                "Engine_code": "UEJE",
            },
        },
        "2018 ->": {
            "1.5 Ecoblue 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005",
                "Engine_code": "ZTJD",
            },
            "1.5 TDCI 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 215,
                "tuned_torque": 280,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70 & Bosch MD1CS005 & Bosch EDC17C10",
                "Engine_code": "XVJD",
            },
            "1.0 Ecoboost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.1 & Bosch MED17.2 & Bosch MED17.0.7 & Bosch MEDG17.0",
                "Engine_code": "M1JC",
            },
            "1.0 Ecoboost 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 170,
                "tuned_torque": 250,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.1 & Bosch MED17.0.7",
                "Engine_code": "YYJD",
            },
            "1.0 Ecoboost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.7",
                "Engine_code": "SFJL",
            },
        },
    },
    "Edge": {
        "2007 - 2015": {
            "2.0 Ecoboost 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 365,
                "tuned_torque": 415,
                "cc": 1999,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "2.0 Ecoboost",
            },
            "3.5 V6 265hp": {
                "std_power": 265,
                "tuned_power": 285,
                "std_torque": 339,
                "tuned_torque": 360,
                "cc": 3496,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "Duratec 35",
            },
            "3.5 V6 285hp": {
                "std_power": 285,
                "tuned_power": 315,
                "std_torque": 343,
                "tuned_torque": 365,
                "cc": 3496,
                "Type_ecu": "Siemens/Continental EMS24xx & Siemens/Continental 5WY8",
                "Engine_code": "Duratec 35",
            },
        },
        "2016 - 2018": {
            "2.0 TDCi 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "T8CC",
            },
            "2.0 TDCi 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X & Bosch DCU17PC43",
                "Engine_code": "T9CE / T9CF",
            },
            "2.0 Ecoboost 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 373,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MEDG17.0",
                "Engine_code": "",
            },
            "2.7 Ecoboost V6 315hp": {
                "std_power": 315,
                "tuned_power": 350,
                "std_torque": 475,
                "tuned_torque": 550,
                "cc": 2700,
                "Type_ecu": "Bosch MED17.2.2",
                "Engine_code": "2.7 Ecoboost",
            },
        },
        "2019 ->": {
            "2.0 Ecoblue Bi-TDCI 238hp": {
                "std_power": 238,
                "tuned_power": 260,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 Ecoblue 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0T Ecoboost 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 373,
                "tuned_torque": 430,
                "cc": 1999,
                "Type_ecu": "Bosch MG1CS017 & Bosch MED17.2.2",
                "Engine_code": "",
            },
            "2.7T Ecoboost 335hp": {
                "std_power": 335,
                "tuned_power": 360,
                "std_torque": 515,
                "tuned_torque": 550,
                "cc": 2688,
                "Type_ecu": "Bosch MG1CS018",
                "Engine_code": "",
            },
        },
    },
    "Excursion": {
        "1999 - 2005": {
            "6.0 V8 325hp": {
                "std_power": 325,
                "tuned_power": 375,
                "std_torque": 759,
                "tuned_torque": 820,
                "cc": 6000,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "6.0 Power Stroke",
            },
        },
    },
    "Expedition": {
        "2007 - 2017": {
            "3.5T V6 Ecoboost 365hp": {
                "std_power": 365,
                "tuned_power": 400,
                "std_torque": 569,
                "tuned_torque": 610,
                "cc": 3500,
                "Type_ecu": "Bosch MEDG7",
                "Engine_code": "3.5 Ecoboost",
            },
            "5.4 V8 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 495,
                "tuned_torque": 525,
                "cc": 5408,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "Modular 5.4",
            },
        },
    },
    "Explorer": {
        "2006 - 2010": {
            "4.0 V8 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 344,
                "tuned_torque": 375,
                "cc": 4009,
                "Type_ecu": "Ford EEC-V & Ford EEC-VI",
                "Engine_code": "4.0 Cologne V6",
            },
            "4.6 V8 292hp": {
                "std_power": 292,
                "tuned_power": 310,
                "std_torque": 427,
                "tuned_torque": 455,
                "cc": 4600,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "Modular 4.6",
            },
        },
        "2011 - 2018": {
            "2.0T Ecoboost 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 366,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.2.2",
                "Engine_code": "",
            },
            "2.3 Ecoboost 280hp": {
                "std_power": 280,
                "tuned_power": 295,
                "std_torque": 420,
                "tuned_torque": 480,
                "cc": 2261,
                "Type_ecu": "Bosch MEDG17.0 & Bosch MED17.2.2",
                "Engine_code": "",
            },
            "3.5T V6 Ecoboost 370hp": {
                "std_power": 370,
                "tuned_power": 400,
                "std_torque": 465,
                "tuned_torque": 550,
                "cc": 3500,
                "Type_ecu": "Bosch MEDG17.0 & Siemens/Continental EMS24xx",
                "Engine_code": "3.5 Ecoboost",
            },
        },
        "2019 ->": {
            "2.3 Ecoboost 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0 Ecoboost V6 457hp": {
                "std_power": 457,
                "tuned_power": 485,
                "std_torque": 825,
                "tuned_torque": 875,
                "cc": 2956,
                "Type_ecu": "Bosch MG1CS017 & Bosch MG1CS018",
                "Engine_code": "BQWA",
            },
            "3.0 V6 365hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "ST 3.0 V6 457hp": {
                "std_power": 457,
                "tuned_power": 485,
                "std_torque": 825,
                "tuned_torque": 875,
                "cc": 2956,
                "Type_ecu": "Bosch MG1CS018",
                "Engine_code": "",
            },
        },
    },
    "F-150": {
        "2008 - 2014": {
            "3.5 Ecoboost 365hp": {
                "std_power": 365,
                "tuned_power": 400,
                "std_torque": 569,
                "tuned_torque": 600,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.0 & Bosch MG1CS015 & Bosch MEDG17.0",
                "Engine_code": "3.5 Ecoboost",
            },
            "3.7 V6 302hp": {
                "std_power": 302,
                "tuned_power": 320,
                "std_torque": 377,
                "tuned_torque": 400,
                "cc": 3701,
                "Type_ecu": "Siemens/Continental 5WY8",
                "Engine_code": "Duratec 37",
            },
            "4.6 V8 248hp": {
                "std_power": 248,
                "tuned_power": 267,
                "std_torque": 399,
                "tuned_torque": 418,
                "cc": 4606,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "4.6 V8 292hp": {
                "std_power": 292,
                "tuned_power": 306,
                "std_torque": 434,
                "tuned_torque": 452,
                "cc": 4606,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "5.0 V8 360hp": {
                "std_power": 360,
                "tuned_power": 379,
                "std_torque": 515,
                "tuned_torque": 545,
                "cc": 4949,
                "Type_ecu": "Siemens/Continental 5WY8",
                "Engine_code": "",
            },
            "5.4 V8 310hp": {
                "std_power": 310,
                "tuned_power": 328,
                "std_torque": 495,
                "tuned_torque": 520,
                "cc": 5408,
                "Type_ecu": "Ford EEC-V & Ford EEC-VI",
                "Engine_code": "",
            },
            "6.2 V8 411hp": {
                "std_power": 411,
                "tuned_power": 430,
                "std_torque": 588,
                "tuned_torque": 614,
                "cc": 6211,
                "Type_ecu": "Siemens/Continental EMS24xx & Siemens/Continental 5WY8",
                "Engine_code": "",
            },
        },
        "2015 - 2017": {
            "2.7 V6 325hp": {
                "std_power": 325,
                "tuned_power": 344,
                "std_torque": 508,
                "tuned_torque": 530,
                "cc": 2687,
                "Type_ecu": "Bosch MEDG17.0",
                "Engine_code": "",
            },
            "3.5 V6 282hp": {
                "std_power": 282,
                "tuned_power": 299,
                "std_torque": 343,
                "tuned_torque": 360,
                "cc": 3514,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5T Ecoboost 375hp": {
                "std_power": 375,
                "tuned_power": 400,
                "std_torque": 637,
                "tuned_torque": 700,
                "cc": 3514,
                "Type_ecu": "Bosch MEDG17.0 & Bosch MG1CS015",
                "Engine_code": "",
            },
            "5.0 V8 385hp": {
                "std_power": 385,
                "tuned_power": 402,
                "std_torque": 525,
                "tuned_torque": 550,
                "cc": 4949,
                "Type_ecu": "Siemens/Continental EMS24xx",
                "Engine_code": "",
            },
        },
        "2018 - 2020": {
            "2.7T Ecoboost V6 325hp": {
                "std_power": 325,
                "tuned_power": 360,
                "std_torque": 508,
                "tuned_torque": 550,
                "cc": 2700,
                "Type_ecu": "Bosch MED17.2 & Bosch MG1CS018 & Bosch MG1CS036",
                "Engine_code": "Ecoboost 27",
            },
            "3.3 TI-VCT V6 290hp": {
                "std_power": 290,
                "tuned_power": 310,
                "std_torque": 359,
                "tuned_torque": 390,
                "cc": 3300,
                "Type_ecu": "Bosch MG1CS019",
                "Engine_code": "",
            },
            "3.5T Ecoboost Raptor 450hp": {
                "std_power": 450,
                "tuned_power": 500,
                "std_torque": 691,
                "tuned_torque": 830,
                "cc": 3490,
                "Type_ecu": "Bosch MG1CS015 & Bosch MG1CS018 & Bosch MG1CS036",
                "Engine_code": "3.5 Ecoboost",
            },
            "3.5T Ecoboost 375hp": {
                "std_power": 375,
                "tuned_power": 400,
                "std_torque": 637,
                "tuned_torque": 700,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.0 & Bosch MG1CS015 & Bosch MEDG17.0 & Bosch MG1CS018 & Bosch MG1CS036",
                "Engine_code": "3.5 Ecoboost",
            },
            "5.0 V8 395hp": {
                "std_power": 395,
                "tuned_power": 440,
                "std_torque": 542,
                "tuned_torque": 565,
                "cc": 4949,
                "Type_ecu": "Siemens/Continental EMS24xx & Bosch MG1CS019",
                "Engine_code": "",
            },
        },
        "2021 -> ...": {
            "3.5T Ecoboost 450hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.7T Ecoboost V6 325hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.3 TI-VCT V6 290hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5T Ecoboost 400hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5T Powerboost 430hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "5.0 Ti-VCT V8 395hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "F-250": {
        "-> 2020": {
            "6.0 V8 325hp": {
                "std_power": 325,
                "tuned_power": 345,
                "std_torque": 772,
                "tuned_torque": 795,
                "cc": 5954,
                "Type_ecu": "Visteon DPU-442 & Ford EEC-VI",
                "Engine_code": "6.0 Power Stroke Diesel",
            },
            "6.4 V8 350hp": {
                "std_power": 350,
                "tuned_power": 390,
                "std_torque": 881,
                "tuned_torque": 930,
                "cc": 6400,
                "Type_ecu": "Siemens/Continental SID902",
                "Engine_code": "6.4 Power Stroke Diesel",
            },
            "6.7 V8 400hp": {
                "std_power": 400,
                "tuned_power": 440,
                "std_torque": 1084,
                "tuned_torque": 1140,
                "cc": 6700,
                "Type_ecu": "Bosch EDC17CP05",
                "Engine_code": "6.7 Power Stroke Diesel V8",
            },
            "6.7 V8 440hp": {
                "std_power": 440,
                "tuned_power": 470,
                "std_torque": 1166,
                "tuned_torque": 1200,
                "cc": 6700,
                "Type_ecu": "Bosch EDC17CP05 & Bosch EDC17CP65 & Bosch MD1CP006",
                "Engine_code": "6.7 Power Stroke Diesel V8",
            },
            "5.4 V8 320hp": {
                "std_power": 320,
                "tuned_power": 340,
                "std_torque": 529,
                "tuned_torque": 550,
                "cc": 5408,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "5.4 Modular",
            },
            "6.2 V8 385hp": {
                "std_power": 385,
                "tuned_power": 415,
                "std_torque": 549,
                "tuned_torque": 580,
                "cc": 6200,
                "Type_ecu": "Siemens/Continental 5WY8",
                "Engine_code": "6.2 Boss",
            },
        },
    },
    "F-350": {
        "All": {
            "6.0 V8 325hp": {
                "std_power": 325,
                "tuned_power": 375,
                "std_torque": 760,
                "tuned_torque": 810,
                "cc": 5954,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "6.0 Power Stroke Diesel - VT365",
            },
            "6.4 V8 350hp": {
                "std_power": 350,
                "tuned_power": 380,
                "std_torque": 880,
                "tuned_torque": 920,
                "cc": 6400,
                "Type_ecu": "Siemens/Continental SID902",
                "Engine_code": "6.4 Power Stroke Diesel",
            },
            "6.7 V8 440hp": {
                "std_power": 440,
                "tuned_power": 470,
                "std_torque": 1166,
                "tuned_torque": 1200,
                "cc": 6702,
                "Type_ecu": "Bosch EDC17CP05 & Bosch EDC17CP65",
                "Engine_code": "6.7 Power Stroke Diesel V8",
            },
            "6.7 V8 400hp": {
                "std_power": 400,
                "tuned_power": 440,
                "std_torque": 1084,
                "tuned_torque": 1140,
                "cc": 6702,
                "Type_ecu": "Bosch EDC17CP05",
                "Engine_code": "6.7 Power Stroke Diesel V8",
            },
            "6.7d V8 475hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "5.4 V8 260hp": {
                "std_power": 260,
                "tuned_power": 280,
                "std_torque": 475,
                "tuned_torque": 495,
                "cc": 5400,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "5.4 Modular",
            },
            "6.2 V8 385hp": {
                "std_power": 385,
                "tuned_power": 400,
                "std_torque": 583,
                "tuned_torque": 600,
                "cc": 6211,
                "Type_ecu": "Bosch MG1CS019",
                "Engine_code": "",
            },
        },
    },
    "F-450": {
        "All": {
            "6.7 V8 400hp": {
                "std_power": 400,
                "tuned_power": 430,
                "std_torque": 1086,
                "tuned_torque": 1120,
                "cc": 6702,
                "Type_ecu": "Bosch EDC17CP05",
                "Engine_code": "6.7 Power Stroke Diesel V8",
            },
            "6.7 V8 440hp": {
                "std_power": 440,
                "tuned_power": 480,
                "std_torque": 1166,
                "tuned_torque": 1200,
                "cc": 6702,
                "Type_ecu": "Delco E38 & Bosch EDC17CP05 & Bosch MD1CP006 & Bosch EDC17CP65",
                "Engine_code": "6.7 Power Stroke Diesel V8",
            },
        },
    },
    "F-550": {
        "All": {
            "6.0 V8 325hp": {
                "std_power": 325,
                "tuned_power": 360,
                "std_torque": 772,
                "tuned_torque": 810,
                "cc": 5954,
                "Type_ecu": "Siemens/Continental SID902",
                "Engine_code": "6.0 Power Stroke Diesel",
            },
            "6.7 V8 400hp": {
                "std_power": 400,
                "tuned_power": 430,
                "std_torque": 1086,
                "tuned_torque": 1120,
                "cc": 6702,
                "Type_ecu": "Delco E38 & Bosch EDC17CP05",
                "Engine_code": "6.7 Power Stroke Diesel V8",
            },
            "6.7 V8 440hp": {
                "std_power": 440,
                "tuned_power": 480,
                "std_torque": 1166,
                "tuned_torque": 1200,
                "cc": 6702,
                "Type_ecu": "Delco E38 & Bosch EDC17CP65 & Bosch MD1CP006 & Bosch EDC17CP05",
                "Engine_code": "6.7 Power Stroke Diesel V8",
            },
            "5.4 V8 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 494,
                "tuned_torque": 515,
                "cc": 5400,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "5.4 Modular",
            },
            "6.8 V10 362hp": {
                "std_power": 362,
                "tuned_power": 385,
                "std_torque": 620,
                "tuned_torque": 650,
                "cc": 6800,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "6.8 Modular V10",
            },
            "7.3 V8 350hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Fiesta": {
        "MK8 - 2017 ->": {
            "1.5 TDCi 85hp": {
                "std_power": 85,
                "tuned_power": 120,
                "std_torque": 215,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XUJC",
            },
            "1.5 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005 & Bosch EDC17C70 & Bosch EDC17C10",
                "Engine_code": "XWJA / XWJB / XWJC",
            },
            "1.0T Ecoboost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.7 & Bosch MEDG17.0 & Bosch MG1CS016 & Bosch MED17.0.1 & Bosch MED17.2.2",
                "Engine_code": "SFJE",
            },
            "1.0T Ecoboost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.1 & Bosch MG1CS016 & Bosch MED17.0.7 & Bosch MED17.2",
                "Engine_code": "M1JL",
            },
            "1.0T Ecoboost 140hp": {
                "std_power": 140,
                "tuned_power": 145,
                "std_torque": 180,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.7 & Bosch MG1CS016",
                "Engine_code": "YYJE / YYJG",
            },
            "1.0T Ecoboost 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 230,
                "cc": 999,
                "Type_ecu": "Bosch MG1CS016 & Bosch ME17.0.3",
                "Engine_code": "MOJA / MOJB",
            },
            "1.5T Ecoboost 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 290,
                "tuned_torque": 370,
                "cc": 1497,
                "Type_ecu": "Bosch MG1CS016 & Bosch MG1CS017 & Bosch MEDG17.0",
                "Engine_code": "YZJA",
            },
        },
        "Mk5 - 2001 - 2008": {
            "1.4 TDCI 68hp": {
                "std_power": 68,
                "tuned_power": 92,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Siemens/Continental SID804 & Siemens/Continental SID802",
                "Engine_code": "F6JB",
            },
            "1.4 TDCI 70hp": {
                "std_power": 70,
                "tuned_power": 92,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Siemens/Continental SID804",
                "Engine_code": "KVJA",
            },
            "1.6 TDCI 16v 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 204,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "HHJB",
            },
            "1.25i 16v 70hp": {
                "std_power": 70,
                "tuned_power": 77,
                "std_torque": 106,
                "tuned_torque": 120,
                "cc": 1242,
                "Type_ecu": "Siemens/Continental Simos 22 & Siemens/Continental SIM210",
                "Engine_code": "M7JA",
            },
            "1.6i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 146,
                "tuned_torque": 160,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental Simos 22 & Ford EEC-V",
                "Engine_code": "FYJA",
            },
            "2.0i 16v ST 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 190,
                "tuned_torque": 205,
                "cc": 1999,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "N4JB",
            },
        },
        "Mk6 - 2008 - 2012": {
            "1.4 TDCI 68hp": {
                "std_power": 68,
                "tuned_power": 92,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1368,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID206",
                "Engine_code": "F6JB",
            },
            "1.4 TDCI 70hp": {
                "std_power": 70,
                "tuned_power": 92,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1368,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "KVJA",
            },
            "1.5 TDCI 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XVJB / XVJC",
            },
            "1.6 TDCI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 204,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "HHJD",
            },
            "1.6 TDCI 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "X3JA / TZJA / TZJB",
            },
            "1.25i 60hp": {
                "std_power": 60,
                "tuned_power": 82,
                "std_torque": 114,
                "tuned_torque": 116,
                "cc": 1242,
                "Type_ecu": "Siemens/Continental EMS2102 & Bosch MED17.0.1",
                "Engine_code": "STJB",
            },
            "1.4i 16v 96hp": {
                "std_power": 96,
                "tuned_power": 105,
                "std_torque": 128,
                "tuned_torque": 142,
                "cc": 1368,
                "Type_ecu": "Siemens/Continental EMS2102",
                "Engine_code": "SPJC",
            },
            "1.6 TI-VCT 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 152,
                "tuned_torque": 175,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental EMS2205 & Siemens/Continental EMS2102",
                "Engine_code": "HXJA",
            },
            "1.6 TI-VCT 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 152,
                "tuned_torque": 175,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental EMS2211 & Marelli IAW4CF",
                "Engine_code": "IQJA / IQJC / IQJE",
            },
            "1.6T STCI 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1596,
                "Type_ecu": "Bosch MED9",
                "Engine_code": "",
            },
            "1.6i 120hp": {
                "std_power": 120,
                "tuned_power": 132,
                "std_torque": 152,
                "tuned_torque": 167,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental EMS2211 & Siemens/Continental EMS2205 & Siemens/Continental EMS2102",
                "Engine_code": "HXJA",
            },
        },
        "Mk7 - 2013 - 2017": {
            "1.5 TDCI 75hp": {
                "std_power": 75,
                "tuned_power": 130,
                "std_torque": 185,
                "tuned_torque": 280,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C10 & Bosch EDC17C70",
                "Engine_code": "XVJB",
            },
            "1.5 TDCI 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 215,
                "tuned_torque": 280,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70 & Bosch EDC17C10",
                "Engine_code": "XVJB / XVJC",
            },
            "1.6 TDCI 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807EVO & Bosch EDC17C70",
                "Engine_code": "X3JA / TZJA / TZJB",
            },
            "1.6 TDCI 95hp 87gr CO2": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "X3JA / TZJA / TZJB",
            },
            "1.0 EcoBoost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1 & Bosch MED17.0.7",
                "Engine_code": "SFJC",
            },
            "1.0 EcoBoost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1 & Bosch MED17.0.7 & Bosch MEDG17.0",
                "Engine_code": "M1JH",
            },
            "1.0 EcoBoost 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.7 & Bosch MED17.0.1",
                "Engine_code": "YYJB",
            },
            "1.0i 65hp": {
                "std_power": 65,
                "tuned_power": 80,
                "std_torque": 105,
                "tuned_torque": 110,
                "cc": 999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "XMJC",
            },
            "1.5 Ti-VCT 112hp": {
                "std_power": 112,
                "tuned_power": 135,
                "std_torque": 140,
                "tuned_torque": 180,
                "cc": 1499,
                "Type_ecu": "Siemens/Continental EMS2510",
                "Engine_code": "",
            },
            "1.6 Ti-VCT 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1596,
                "Type_ecu": "Bosch ME17.0 & Siemens/Continental EMS2205",
                "Engine_code": "IQJA",
            },
            "ST - 1.6T 182hp": {
                "std_power": 182,
                "tuned_power": 205,
                "std_torque": 290,
                "tuned_torque": 330,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "JTJB",
            },
            "ST200 - 1.6T 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 290,
                "tuned_torque": 330,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "JTJB",
            },
        },
    },
    "Five Hundred": {
        "2005 - 2007": {
            "3.0 V6 205hp": {
                "std_power": 205,
                "tuned_power": 225,
                "std_torque": 281,
                "tuned_torque": 310,
                "cc": 2967,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "Duratec 30",
            },
        },
    },
    "Flex": {
        "2009 - 2014": {
            "3.5T V6 365hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5 V6 287hp": {
                "std_power": 287,
                "tuned_power": 305,
                "std_torque": 344,
                "tuned_torque": 375,
                "cc": 3496,
                "Type_ecu": "",
                "Engine_code": "Duratec 35",
            },
        },
        "2014 - 2019": {
            "3.5 V6 287hp": {
                "std_power": 287,
                "tuned_power": 305,
                "std_torque": 344,
                "tuned_torque": 375,
                "cc": 3496,
                "Type_ecu": "",
                "Engine_code": "Duratec 35",
            },
        },
    },
    "Focus": {
        "1998 - 2005": {
            "1.6 TDCi 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 245,
                "tuned_torque": 315,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "NGDB",
            },
            "1.6 TDCi 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "HHDA",
            },
            "1.8 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 350,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID202",
                "Engine_code": "FFDA",
            },
            "1.8 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 350,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID202",
                "Engine_code": "F9DA",
            },
            "1.8 TDDI 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1753,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "BHDA / BHDB",
            },
            "1.8 TDDI 90hp": {
                "std_power": 90,
                "tuned_power": 116,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1753,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "C9DB",
            },
            "1.6 Ti-VCT 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1596,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "HXDA",
            },
            "1.6i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 112,
                "std_torque": 145,
                "tuned_torque": 150,
                "cc": 1596,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "FYDB",
            },
            "2.0i 16v ST 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 196,
                "tuned_torque": 225,
                "cc": 1989,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "ALDA",
            },
            "2.0i 16v turbo RS 215hp": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 1988,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "HMDA",
            },
            "2.0i 130hp": {
                "std_power": 130,
                "tuned_power": 140,
                "std_torque": 174,
                "tuned_torque": 190,
                "cc": 1989,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "EDDC",
            },
        },
        "2004 - 2010": {
            "1.6 TDCi 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC16C3",
                "Engine_code": "HHDA",
            },
            "1.6 TDCi 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 245,
                "tuned_torque": 315,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC16C3",
                "Engine_code": "G8DA",
            },
            "1.6 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 215,
                "tuned_torque": 290,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "G8DC",
            },
            "1.8 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1798,
                "Type_ecu": "Delphi MPC555 & Siemens/Continental SID206 & Siemens/Continental SID202",
                "Engine_code": "KKDA",
            },
            "2.0 TDCi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Siemens/Continental SID206",
                "Engine_code": "G6DA",
            },
            "2.0 TDCi 110hp": {
                "std_power": 110,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "",
            },
            "1.6 Ti-VCT 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 155,
                "tuned_torque": 175,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental EMS & Siemens/Continental SIM29",
                "Engine_code": "HXDA",
            },
            "1.8i 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 165,
                "tuned_torque": 180,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 29 & Visteon 7M51ADA & Ford ECV6 & Ford EEC-VI",
                "Engine_code": "QQDB",
            },
            "2.0i 16v 145hp": {
                "std_power": 145,
                "tuned_power": 155,
                "std_torque": 185,
                "tuned_torque": 203,
                "cc": 1999,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "AODA",
            },
            "2.5 Turbo ST 225hp": {
                "std_power": 225,
                "tuned_power": 255,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2522,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "HYDA",
            },
            "RS 305hp": {
                "std_power": 305,
                "tuned_power": 350,
                "std_torque": 440,
                "tuned_torque": 540,
                "cc": 2522,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "JZDA",
            },
            "RS500 350hp": {
                "std_power": 350,
                "tuned_power": 370,
                "std_torque": 460,
                "tuned_torque": 560,
                "cc": 2522,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "SHDC",
            },
        },
        "2011 - 2014": {
            "1.6 TDCi 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807",
                "Engine_code": "T3DB",
            },
            "1.6 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807 & Bosch EDC17C10 & Siemens/Continental SID807EVO",
                "Engine_code": "T1DB",
            },
            "1.6 TDCi 105hp": {
                "std_power": 105,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "NGDB",
            },
            "2.0 TDCi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 415,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "TXDB",
            },
            "2.0 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "TYDA",
            },
            "2.0 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "UFDB",
            },
            "2.0 TDCi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID206 & Siemens/Continental SID803",
                "Engine_code": "UKDB",
            },
            "1.0 EcoBoost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 995,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "M2DA",
            },
            "1.0 EcoBoost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 995,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "M1DA",
            },
            "1.5 EcoBoost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "M8DA",
            },
            "1.6 EcoBoost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MED17",
                "Engine_code": "JQDB",
            },
            "1.6 EcoBoost 182hp": {
                "std_power": 182,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0",
                "Engine_code": "JTDB",
            },
            "1.6 Ti-VCT 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental EMS2204",
                "Engine_code": "IQDB",
            },
            "1.6 Ti-VCT 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental EMS2204",
                "Engine_code": "PNDA",
            },
            "2.0 ST EcoBoost 250hp": {
                "std_power": 250,
                "tuned_power": 260,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0",
                "Engine_code": "R9DA",
            },
        },
        "2014 - 2017": {
            "1.5 TDCi 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 270,
                "tuned_torque": 310,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XXDD",
            },
            "1.5 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XWDE",
            },
            "1.5 TDCi 105hp": {
                "std_power": 105,
                "tuned_power": 135,
                "std_torque": 270,
                "tuned_torque": 310,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XWDE",
            },
            "2.0 TDCI ST 185hp": {
                "std_power": 185,
                "tuned_power": 215,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Bosch MED17.0",
                "Engine_code": "2.0 Duratec TDC",
            },
            "2.0 TDCi 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Bosch MED17.2.2",
                "Engine_code": "R7DB",
            },
            "1.0 EcoBoost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 995,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.7.2 & Bosch MED17.0.7 & Bosch MED17.0.1 & Bosch MED17.7.1",
                "Engine_code": "M2DC",
            },
            "1.0 EcoBoost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 995,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1 & Bosch MEDG17.0 & Bosch MG1CS016",
                "Engine_code": "M1DD",
            },
            "1.5 EcoBoost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1498,
                "Type_ecu": "Siemens/Continental EMS2511 & Siemens/Continental EMS2510 & Siemens/Continental EMS24xx",
                "Engine_code": "M8DD",
            },
            "1.5 EcoBoost 182hp": {
                "std_power": 182,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1498,
                "Type_ecu": "Siemens/Continental EMS2511 & Siemens/Continental EMS2510 & Bosch MEDG17.0",
                "Engine_code": "M9DB",
            },
            "2.0 EcoBoost ST 250hp": {
                "std_power": 250,
                "tuned_power": 260,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MEDG17.0 & Bosch MED17.0",
                "Engine_code": "R9DC",
            },
            "2.3 EcoBoost RS 350hp": {
                "std_power": 350,
                "tuned_power": 370,
                "std_torque": 440,
                "tuned_torque": 530,
                "cc": 2253,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.2.2 & Bosch MEDG17.0 & Bosch MED17.0",
                "Engine_code": "YVDA",
            },
        },
        "2018 ->": {
            "1.5 Ecoblue 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003 & Bosch MD1CS005",
                "Engine_code": "ZTDA",
            },
            "1.5 Ecoblue 95hp": {
                "std_power": 95,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005",
                "Engine_code": "Z2DA",
            },
            "2.0 Ecoblue 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 Ecoboost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 998,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MG1CS016 & Bosch MED17.0.1 & Bosch MG1CS017",
                "Engine_code": "B3DA",
            },
            "1.0 Ecoboost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 998,
                "Type_ecu": "Bosch MG1CS016 & Bosch MG1CS017 & Bosch MED17.2 & Bosch MED17.0",
                "Engine_code": "B7DA",
            },
            "1.0 Ecoboost 125hp": {
                "std_power": 125,
                "tuned_power": 140,
                "std_torque": 210,
                "tuned_torque": 260,
                "cc": 998,
                "Type_ecu": "Bosch MG1CS016",
                "Engine_code": "B7DA",
            },
            "1.0 Ecoboost 155hp": {
                "std_power": 155,
                "tuned_power": 165,
                "std_torque": 240,
                "tuned_torque": 270,
                "cc": 998,
                "Type_ecu": "Bosch MG1CS016",
                "Engine_code": "M0DC",
            },
            "1.5 Ecoboost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS017 & Bosch MG1CS016 & Siemens/Continental EMS2511",
                "Engine_code": "YZDA",
            },
            "1.5 Ecoboost 182hp": {
                "std_power": 182,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS017 & Bosch MG1CS016 & Siemens/Continental EMS24xx",
                "Engine_code": "Y1DA",
            },
            "2.3 Ecoboost ST 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 420,
                "tuned_torque": 480,
                "cc": 2253,
                "Type_ecu": "Bosch MG1CS017",
                "Engine_code": "N3DA",
            },
        },
    },
    "Freestar": {
        "2004 - 2007": {
            "3.9 V6 195hp": {
                "std_power": 195,
                "tuned_power": 215,
                "std_torque": 325,
                "tuned_torque": 355,
                "cc": 3802,
                "Type_ecu": "",
                "Engine_code": "3.9 Essex V6",
            },
            "4.2 V6 201hp": {
                "std_power": 201,
                "tuned_power": 220,
                "std_torque": 357,
                "tuned_torque": 380,
                "cc": 4195,
                "Type_ecu": "",
                "Engine_code": "4.2 Essex V6",
            },
        },
    },
    "Freestyle": {
        "2005 - 2007": {
            "3.0 V6 205hp": {
                "std_power": 205,
                "tuned_power": 225,
                "std_torque": 281,
                "tuned_torque": 300,
                "cc": 2967,
                "Type_ecu": "",
                "Engine_code": "Duratec 30",
            },
        },
    },
    "Fusion": {
        "2002 - 2012": {
            "1.4 TDCi 68hp": {
                "std_power": 68,
                "tuned_power": 92,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Siemens/Continental SID804 & Siemens/Continental SID802",
                "Engine_code": "F6JB",
            },
            "1.6 TDCi 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1360,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "HHJA",
            },
            "1.4i 16v 80hp": {
                "std_power": 80,
                "tuned_power": 88,
                "std_torque": 124,
                "tuned_torque": 136,
                "cc": 1388,
                "Type_ecu": "Siemens/Continental SIM201 & Siemens/Continental SID804",
                "Engine_code": "FXJC",
            },
            "1.6i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 112,
                "std_torque": 146,
                "tuned_torque": 162,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental SIM201",
                "Engine_code": "FYJA",
            },
        },
        "2013 ->": {
            "2.0 Turbo 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 366,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MEDG17.0 & Bosch MED17.2.2",
                "Engine_code": "",
            },
            "2.7 Ecoboost Supercharged V6 325hp": {
                "std_power": 325,
                "tuned_power": 375,
                "std_torque": 515,
                "tuned_torque": 580,
                "cc": 2688,
                "Type_ecu": "Bosch MED17.2.2",
                "Engine_code": "Ecoboost 27",
            },
        },
    },
    "Fusion (USA)": {
        "2013 ->": {
            "1.5 EcoBoost 178hp": {
                "std_power": 178,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1498,
                "Type_ecu": "Bosch MED17.2",
                "Engine_code": "",
            },
            "1.5 EcoBoost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1498,
                "Type_ecu": "Siemens/Continental EMS2511",
                "Engine_code": "",
            },
            "1.6 EcoBoost 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1594,
                "Type_ecu": "Bosch MED17.2.2",
                "Engine_code": "",
            },
            "2.0 EcoBoost 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MEDG17.0",
                "Engine_code": "",
            },
            "2.5 Duratec 175hp": {
                "std_power": 175,
                "tuned_power": 185,
                "std_torque": 230,
                "tuned_torque": 245,
                "cc": 2488,
                "Type_ecu": "Siemens/Continental 5WY8",
                "Engine_code": "",
            },
            "2.7 Ecoboost V6 325hp": {
                "std_power": 325,
                "tuned_power": 375,
                "std_torque": 515,
                "tuned_torque": 550,
                "cc": 2694,
                "Type_ecu": "Bosch MEDG17.0",
                "Engine_code": "",
            },
        },
    },
    "Galaxy": {
        "1995 - 2006": {
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 202,
                "tuned_torque": 280,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ANU",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 285,
                "tuned_torque": 335,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AUY",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 162,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "1.9 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "BTB",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AUY",
            },
        },
        "2006 - 2015": {
            "1.6 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "T1WB",
            },
            "1.8 TDCi 125hp": {
                "std_power": 125,
                "tuned_power": 153,
                "std_torque": 320,
                "tuned_torque": 375,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "QYWA",
            },
            "1.8 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "FFWA",
            },
            "2.0 TDCi 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 330,
                "tuned_torque": 390,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "AZWA",
            },
            "2.0 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID206 & Delphi DCM3.5",
                "Engine_code": "QXWA",
            },
            "2.0 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID206 & Delphi DCM3.5",
                "Engine_code": "T1WA",
            },
            "2.0 TDCi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 415,
                "cc": 1999,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "TXWA",
            },
            "2.0 TDCi 136hp EuroV >2010": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 410,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "AZWC / UKWA",
            },
            "2.2 TDCi 175hp": {
                "std_power": 175,
                "tuned_power": 202,
                "std_torque": 400,
                "tuned_torque": 472,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16CP39",
                "Engine_code": "Q4WA",
            },
            "2.2 TDCi 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 420,
                "tuned_torque": 480,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "KNWA",
            },
            "1.6 EcoBoost 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MED17.0",
                "Engine_code": "JTWB",
            },
            "2.0 EcoBoost 203hp": {
                "std_power": 203,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2",
                "Engine_code": "TNWA",
            },
            "2.0i 16v 143hp": {
                "std_power": 143,
                "tuned_power": 155,
                "std_torque": 190,
                "tuned_torque": 205,
                "cc": 1999,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "AOWA",
            },
        },
        "2015 ->": {
            "2.0 TDCi 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "T7CJ",
            },
            "2.0 TDCi 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "T8CJ",
            },
            "2.0 TDCi 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "T9CB / T9CC",
            },
            "2.0 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 195,
                "std_torque": 310,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1",
                "Engine_code": "UFCA / UFCB",
            },
            "1.5 EcoBoost 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Bosch MED17.0 & Siemens/Continental EMS2510",
                "Engine_code": "UNCJ",
            },
            "2.0 EcoBoost 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 345,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MEDG17.0",
                "Engine_code": "R9CI",
            },
        },
    },
    "Ka": {
        "2008 ->": {
            "1.3 TDCi 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 145,
                "tuned_torque": 245,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3 & Marelli MJ8DF",
                "Engine_code": "169A1000",
            },
        },
    },
    "Kuga": {
        "2008 - 2013": {
            "2.0 TDCi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Siemens/Continental SID202 & Siemens/Continental SID206 & Delphi DCM3.5",
                "Engine_code": "G6DG",
            },
            "2.0 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 & Siemens/Continental SID206",
                "Engine_code": "UFDA",
            },
            "2.0 TDCi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 410,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "TXDA",
            },
            "2.5 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2521,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "HYDB",
            },
        },
        "2013 - 2016": {
            "1.6 TDCi 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "UFMA",
            },
            "2.0 TDCi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 415,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "TXMA",
            },
            "2.0 TDCi 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 & Delphi DCM6.1",
                "Engine_code": "T7MA",
            },
            "2.0 TDCi 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1",
                "Engine_code": "T8MA",
            },
            "2.0 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 185,
                "std_torque": 310,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "XRMA",
            },
            "2.0 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 185,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "XRMA",
            },
            "1.5 EcoBoost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Bosch MED17 & Siemens/Continental EMS24xx & Siemens/Continental EMS2511 & Siemens/Continental EMS2510",
                "Engine_code": "M8MA",
            },
            "1.5 EcoBoost 182hp": {
                "std_power": 182,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1498,
                "Type_ecu": "Bosch MED17.2 & Bosch MEDG17.0",
                "Engine_code": "M9MA",
            },
            "1.5 EcoBoost 120hp": {
                "std_power": 120,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "BNMA",
            },
            "1.6 EcoBoost 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1597,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0.1",
                "Engine_code": "M8MA",
            },
            "1.6 EcoBoost 182hp": {
                "std_power": 182,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 330,
                "cc": 1597,
                "Type_ecu": "Bosch MED17.2.2 & Siemens/Continental EMS2511 & Bosch MEDG17.0",
                "Engine_code": "JTMA",
            },
            "2.0 EcoBoost 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MED17.0",
                "Engine_code": "",
            },
        },
        "2016 - 2019": {
            "1.5 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XWMB",
            },
            "2.0 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 310,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1",
                "Engine_code": "XRMA / XRMB",
            },
            "2.0 TDCi 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM3.5",
                "Engine_code": "T7MA / T7MB",
            },
            "2.0 TDCi 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1",
                "Engine_code": "T8MA",
            },
            "1.5T Ecoboost 120hp": {
                "std_power": 120,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 330,
                "cc": 1498,
                "Type_ecu": "Siemens/Continental EMS2511 & Siemens/Continental EMS2510 & Siemens/Continental EMS2513",
                "Engine_code": "BNMA",
            },
            "1.5T Ecoboost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Bosch MED17.0 & Siemens/Continental EMS24xx & Siemens/Continental EMS2511 & Siemens/Continental EMS2510 & Siemens/Continental EMS2513",
                "Engine_code": "M8MA",
            },
            "1.5T Ecoboost 182hp": {
                "std_power": 182,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Bosch MED17.0 & Siemens/Continental EMS2511 & Siemens/Continental EMS24xx & Siemens/Continental EMS2512",
                "Engine_code": "M9MA",
            },
            "2.0T Ecoboost 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 394,
                "tuned_torque": 430,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MED17.0 & Bosch MEDG17.0",
                "Engine_code": "R9MA",
            },
        },
        "2019 ->": {
            "1.5 Ecoblue 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005",
                "Engine_code": "ZTDA",
            },
            "2.0 Ecoblue 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5T Ecoboost 120hp": {
                "std_power": 120,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1498,
                "Type_ecu": "Siemens/Continental EMS2510 & Bosch MG1CS016",
                "Engine_code": "ZTDA",
            },
            "1.5T Ecoboost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1498,
                "Type_ecu": "Siemens/Continental EMS2510 & Siemens/Continental EMS2513 & Bosch MG1CS016",
                "Engine_code": "YZDA / YZDB",
            },
            "1.5T Ecoboost 182hp": {
                "std_power": 182,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1498,
                "Type_ecu": "Siemens/Continental EMS2511 & Bosch MG1CS017",
                "Engine_code": "",
            },
            "2.0T Ecoboost 242hp": {
                "std_power": 242,
                "tuned_power": 270,
                "std_torque": 394,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MG1CS017",
                "Engine_code": "",
            },
        },
    },
    "Mondeo": {
        "2000 - 2007": {
            "2.0 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1998,
                "Type_ecu": "Ford EEC-V & Delphi MPC555",
                "Engine_code": "HJBB",
            },
            "2.0 TDCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Delphi MPC555 & Ford EEC-V & Siemens/Continental SID206 & Delphi DCM3.1",
                "Engine_code": "FMBA",
            },
            "2.0 TDDi 90hp": {
                "std_power": 90,
                "tuned_power": 113,
                "std_torque": 245,
                "tuned_torque": 285,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "D5BA",
            },
            "2.0 TDDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1998,
                "Type_ecu": "Delphi MPC555",
                "Engine_code": "D6BA",
            },
            "2.2 TDCi 155hp": {
                "std_power": 155,
                "tuned_power": 185,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 2179,
                "Type_ecu": "Delphi MPC555",
                "Engine_code": "QJBA",
            },
            "1.6i 16v 90hp": {
                "std_power": 90,
                "tuned_power": 100,
                "std_torque": 140,
                "tuned_torque": 155,
                "cc": 1598,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "L1J",
            },
            "1.8i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 165,
                "tuned_torque": 180,
                "cc": 1798,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "CGBA",
            },
            "1.8i 16v 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1798,
                "Type_ecu": "Ford EEC-V & Bosch MED7.8.1",
                "Engine_code": "CHBA",
            },
            "2.0i 16v 145hp": {
                "std_power": 145,
                "tuned_power": 155,
                "std_torque": 190,
                "tuned_torque": 205,
                "cc": 1999,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "CJBB",
            },
            "2.5i v6 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 220,
                "tuned_torque": 240,
                "cc": 2498,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "LCBD",
            },
            "220 ST 226hp": {
                "std_power": 226,
                "tuned_power": 245,
                "std_torque": 285,
                "tuned_torque": 310,
                "cc": 2967,
                "Type_ecu": "Bosch ME9 & Ford EEC-V",
                "Engine_code": "MEBA",
            },
        },
        "2007 - 2010": {
            "1.8 TDCi 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "QYBA",
            },
            "1.8 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID202 & Siemens/Continental SID206",
                "Engine_code": "FFBA",
            },
            "2.0 TDCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SID206 & Delphi DCM3.5",
                "Engine_code": "N7BA",
            },
            "2.0 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SID206 & Delphi DCM3.5",
                "Engine_code": "QXBA",
            },
            "2.0 TDCi 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "TXBB",
            },
            "2.0 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 300,
                "tuned_torque": 340,
                "cc": 1998,
                "Type_ecu": "Delphi MPC555 & Siemens/Continental SID206 & Delphi DCM3.5",
                "Engine_code": "KLBA",
            },
            "2.0 TDCi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SID202",
                "Engine_code": "UKBA",
            },
            "2.0 TDCi 115hp var.Turbo": {
                "std_power": 115,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Delphi MPC555",
                "Engine_code": "KLBA",
            },
            "2.2 TDCi 175hp": {
                "std_power": 175,
                "tuned_power": 202,
                "std_torque": 400,
                "tuned_torque": 472,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16CP39",
                "Engine_code": "Q4BA",
            },
            "2.0 SCTi 16v 203hp": {
                "std_power": 203,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "TNBA",
            },
            "2.5T 220hp": {
                "std_power": 220,
                "tuned_power": 265,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 2521,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "HUBA",
            },
        },
        "2010 - 2014": {
            "1.6 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807EVO",
                "Engine_code": "T1BB",
            },
            "2.0 TDCi 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "UKBA",
            },
            "2.0 TDCi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 415,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "TXBB",
            },
            "2.0 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SID206 & Delphi DCM3.5",
                "Engine_code": "UFBB",
            },
            "2.0 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.5 & Siemens/Continental SID206",
                "Engine_code": "TYBA",
            },
            "2.2 TDCi 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 420,
                "tuned_torque": 480,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "KNBA",
            },
            "1.6 EcoBoost 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0",
                "Engine_code": "JTBB",
            },
            "2.0 EcoBoost 203hp": {
                "std_power": 203,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MED17.0.1",
                "Engine_code": "TNBA",
            },
            "2.0 EcoBoost 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MED17.0.1",
                "Engine_code": "TPBA",
            },
        },
        "2015 - 2018": {
            "1.5 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 350,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70 & Delphi DCM6.1",
                "Engine_code": "XUCA",
            },
            "1.6 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "NGCA",
            },
            "2.0 TDCi Bi-Turbo 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "T9CA",
            },
            "2.0 TDCi 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "T7CF",
            },
            "2.0 TDCi 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.X",
                "Engine_code": "T8CC",
            },
            "1.0 EcoBoost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 998,
                "Type_ecu": "Bosch MED17.0.1",
                "Engine_code": "M1CB",
            },
            "1.5 EcoBoost 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1498,
                "Type_ecu": "Bosch MED17.0.1 & Bosch MEDG17.0 & Bosch MED17.7.2 & Bosch MED17.2 & Siemens/Continental EMS2513 & Siemens/Continental EMS2510",
                "Engine_code": "UNCE",
            },
            "2.0 EcoBoost 203hp": {
                "std_power": 203,
                "tuned_power": 270,
                "std_torque": 345,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.0 & Bosch MED17.2.2 & Bosch MEDG17.0",
                "Engine_code": "TNCD",
            },
            "2.0 EcoBoost 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 345,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MEDG17.0",
                "Engine_code": "R9CB",
            },
        },
        "2019 ->": {
            "2.0 Ecoblue 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 Ecoblue 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 Ecoblue 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 Ecoboost 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 242,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Bosch MEDG17.0 & Siemens/Continental EMS2513 & Siemens/Continental EMS2512",
                "Engine_code": "UNCA / UNCB / UNCE / UNCF / UNCM",
            },
        },
    },
    "Mustang": {
        "-> 2014": {
            "3.7 V6 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 380,
                "tuned_torque": 410,
                "cc": 3700,
                "Type_ecu": "Siemens/Continental EMS24xx & Siemens/Continental 5WY8",
                "Engine_code": "Duratec 37",
            },
            "4.0 V6 205hp": {
                "std_power": 205,
                "tuned_power": 225,
                "std_torque": 319,
                "tuned_torque": 340,
                "cc": 4009,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "4.0 Cologne V6",
            },
            "GT 4.6 V8 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 430,
                "tuned_torque": 460,
                "cc": 4606,
                "Type_ecu": "Ford EEC-VI & Ford EEC-V",
                "Engine_code": "",
            },
            "GT 4.6 V8 319hp": {
                "std_power": 319,
                "tuned_power": 339,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 4606,
                "Type_ecu": "Ford EEC-VI",
                "Engine_code": "",
            },
            "GT 5.0 V8 420hp": {
                "std_power": 420,
                "tuned_power": 445,
                "std_torque": 524,
                "tuned_torque": 554,
                "cc": 4949,
                "Type_ecu": "Siemens/Continental 5WY8 & Bosch MG1CS019 & Delco E37",
                "Engine_code": "MF8F",
            },
            "GT500 5.4 V8 507hp": {
                "std_power": 507,
                "tuned_power": 560,
                "std_torque": 650,
                "tuned_torque": 750,
                "cc": 5409,
                "Type_ecu": "Ford EEC-VI & Siemens/Continental 5WY8",
                "Engine_code": "",
            },
        },
        "2015 - 2018": {
            "2.3 EcoBoost 317hp": {
                "std_power": 317,
                "tuned_power": 340,
                "std_torque": 434,
                "tuned_torque": 515,
                "cc": 2253,
                "Type_ecu": "Bosch MED17.7.2 & Bosch MEDG17.0 & Bosch MG1CS017 & Bosch MG1CS007",
                "Engine_code": "H",
            },
            "3.7 V6 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 380,
                "tuned_torque": 410,
                "cc": 3700,
                "Type_ecu": "Siemens/Continental EMS24xx",
                "Engine_code": "Duratec 37",
            },
            "5.2 V8 526hp": {
                "std_power": 526,
                "tuned_power": 580,
                "std_torque": 582,
                "tuned_torque": 650,
                "cc": 5163,
                "Type_ecu": "Siemens/Continental EMS24xx",
                "Engine_code": "5.2 Vodoo",
            },
            "GT 5.0 V8 421hp": {
                "std_power": 421,
                "tuned_power": 441,
                "std_torque": 542,
                "tuned_torque": 562,
                "cc": 4949,
                "Type_ecu": "Siemens/Continental EMS24xx & Bosch MG1CS019",
                "Engine_code": "",
            },
            "GT 5.0 V8 450hp": {
                "std_power": 450,
                "tuned_power": 468,
                "std_torque": 569,
                "tuned_torque": 589,
                "cc": 4949,
                "Type_ecu": "Bosch MG1CS019 & Siemens/Continental EMS24xx",
                "Engine_code": "",
            },
        },
        "2018 -> ...": {
            "5.2 V8 Shelby GT500 771hp": {
                "std_power": 771,
                "tuned_power": 791,
                "std_torque": 847,
                "tuned_torque": 867,
                "cc": 5162,
                "Type_ecu": "Bosch MG1CS019 & Siemens/Continental EMS24xx",
                "Engine_code": "",
            },
            "2.3 Ecoboost 290hp": {
                "std_power": 290,
                "tuned_power": 320,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2254,
                "Type_ecu": "Bosch MG1CS015 & Bosch MG1CS017 & Bosch MG1CS016",
                "Engine_code": "",
            },
            "5.0 V8 GT Bullit 460hp": {
                "std_power": 460,
                "tuned_power": 480,
                "std_torque": 529,
                "tuned_torque": 599,
                "cc": 5031,
                "Type_ecu": "Bosch MG1CS019",
                "Engine_code": "Coyote 5.0",
            },
            "5.0 V8 GT 449hp": {
                "std_power": 449,
                "tuned_power": 470,
                "std_torque": 529,
                "tuned_torque": 560,
                "cc": 4949,
                "Type_ecu": "Bosch MG1CS019 & Siemens/Continental EMS24xx",
                "Engine_code": "Coyote 5.0",
            },
        },
    },
    "Puma": {
        "2019 ->": {
            "1.0 Ecoboost ST Powershift 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 248,
                "tuned_torque": 275,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 Ecoblue 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005",
                "Engine_code": "ZTDA",
            },
            "1.0 Ecoboost 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MG1CS016",
                "Engine_code": "B7JE",
            },
            "1.0 Ecoboost 155hp": {
                "std_power": 155,
                "tuned_power": 165,
                "std_torque": 220,
                "tuned_torque": 245,
                "cc": 999,
                "Type_ecu": "Bosch MG1CS016",
                "Engine_code": "BZJA",
            },
            "1.5T Ecoboost ST 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1497,
                "Type_ecu": "Bosch MG1CS016",
                "Engine_code": "YZJA",
            },
        },
    },
    "Ranger": {
        "-> 2010": {
            "2.5 TD 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 266,
                "tuned_torque": 303,
                "cc": 2499,
                "Type_ecu": "Mitsubishi E6T0xx",
                "Engine_code": "WL-T",
            },
            "2.5 TDCi 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 330,
                "tuned_torque": 420,
                "cc": 2499,
                "Type_ecu": "Bosch EDC16C7-7",
                "Engine_code": "WL",
            },
            "3.0 TDCi 156hp": {
                "std_power": 156,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 500,
                "cc": 2953,
                "Type_ecu": "Bosch EDC16C7-7 & Siemens/Continental SID901",
                "Engine_code": "",
            },
            "2.3i 143hp": {
                "std_power": 143,
                "tuned_power": 160,
                "std_torque": 208,
                "tuned_torque": 230,
                "cc": 2260,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "Duratec 23",
            },
            "3.0 V6 148hp": {
                "std_power": 148,
                "tuned_power": 170,
                "std_torque": 245,
                "tuned_torque": 265,
                "cc": 2986,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "Vulcan V6",
            },
            "4.0 V6 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 344,
                "tuned_torque": 365,
                "cc": 3958,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "4.0 Cologne V6",
            },
        },
        "2011 - 2015": {
            "2.2 TDCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 375,
                "tuned_torque": 450,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID209 & Siemens/Continental SID208",
                "Engine_code": "QJ2R",
            },
            "3.2 TDCi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 470,
                "tuned_torque": 550,
                "cc": 3199,
                "Type_ecu": "Siemens/Continental SID209 & Siemens/Continental SID208 & Siemens/Continental SID309 & Siemens/Continental SID603",
                "Engine_code": "SA2R",
            },
        },
        "2015 - 2018": {
            "2.2 TDCi 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 460,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208 & Siemens/Continental SID209",
                "Engine_code": "",
            },
            "2.2 TDCi 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 375,
                "tuned_torque": 460,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID309 & Siemens/Continental SID209 & Siemens/Continental SID208",
                "Engine_code": "",
            },
            "3.2 TDCi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 470,
                "tuned_torque": 550,
                "cc": 3199,
                "Type_ecu": "Siemens/Continental SID209 & Siemens/Continental SID309 & Siemens/Continental SID208 & Siemens/Continental SID603 & Bosch DCU17CP01",
                "Engine_code": "",
            },
            "2.3 Ecoboost 270hp": {
                "std_power": 270,
                "tuned_power": 335,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2253,
                "Type_ecu": "",
                "Engine_code": "2.3 Ecoboost",
            },
        },
        "2019 ->": {
            "2.0 Ecoblue 170hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 Ecoblue 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 Ecoblue 213hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.2 TDCi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 470,
                "tuned_torque": 550,
                "cc": 3198,
                "Type_ecu": "Siemens/Continental SID309 & Siemens/Continental SID209",
                "Engine_code": "",
            },
            "2.3T Ecoboost 270hp": {
                "std_power": 270,
                "tuned_power": 300,
                "std_torque": 420,
                "tuned_torque": 480,
                "cc": 2253,
                "Type_ecu": "Bosch MG1CS017",
                "Engine_code": "",
            },
        },
    },
    "Raptor": {
        "2010 - 2018": {
            "3.5 Ecoboost Raptor 450hp": {
                "std_power": 450,
                "tuned_power": 500,
                "std_torque": 691,
                "tuned_torque": 830,
                "cc": 3490,
                "Type_ecu": "Bosch MG1CS015",
                "Engine_code": "3.5 Ecoboost",
            },
            "5.4 V8 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 495,
                "tuned_torque": 525,
                "cc": 5408,
                "Type_ecu": "",
                "Engine_code": "5.4 Modular",
            },
            "6.2 V8 411hp": {
                "std_power": 411,
                "tuned_power": 430,
                "std_torque": 588,
                "tuned_torque": 610,
                "cc": 6200,
                "Type_ecu": "Siemens/Continental 5WY8",
                "Engine_code": "6.2 Boss",
            },
        },
        "2019 - 2021": {
            "2.0 Ecoblue Bi-Turbo 214hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2022 -> ...": {
            "3.0T Ecoboost Raptor 288hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "S-Max": {
        "2006 - 2010": {
            "1.8 TDCi 125hp": {
                "std_power": 125,
                "tuned_power": 153,
                "std_torque": 320,
                "tuned_torque": 375,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "QYWA",
            },
            "2.0 TDCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "AZWA",
            },
            "2.0 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "QXWB",
            },
            "2.0 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 142,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 & Siemens/Continental SID206",
                "Engine_code": "KLWA / TYWA",
            },
            "2.0 TDCi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID206 & Siemens/Continental SID803 & Delphi DCM3.5 Nexus",
                "Engine_code": "AZWC / UKWA",
            },
            "2.2 TDCi 175hp": {
                "std_power": 175,
                "tuned_power": 202,
                "std_torque": 400,
                "tuned_torque": 472,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16CP39",
                "Engine_code": "Q4WA",
            },
            "2.5 T 220hp": {
                "std_power": 220,
                "tuned_power": 255,
                "std_torque": 320,
                "tuned_torque": 405,
                "cc": 2522,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "HUWA",
            },
        },
        "2010 - 2015": {
            "1.6 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "T1WB",
            },
            "2.0 TDCi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 415,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 Nexus",
                "Engine_code": "TXWA",
            },
            "2.0 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 Nexus",
                "Engine_code": "KLWA / TYWA",
            },
            "2.0 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "UFWA",
            },
            "2.2 TDCi 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 420,
                "tuned_torque": 480,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "KNWA",
            },
            "1.6 EcoBoost 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0",
                "Engine_code": "JTWB",
            },
            "2.0 EcoBoost 203hp": {
                "std_power": 203,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0",
                "Engine_code": "TNWA",
            },
            "2.0 EcoBoost 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "TPWA",
            },
        },
        "2015 ->": {
            "1.5 EcoBoost 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 242,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Siemens/Continental EMS2510",
                "Engine_code": "C15HDTX / UNCP",
            },
            "2.0 TDCi 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.X",
                "Engine_code": "T7CL",
            },
            "2.0 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 310,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "UFCA / UFCB",
            },
            "2.0 TDCi 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "T8CJ",
            },
            "2.0 TDCi 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.X",
                "Engine_code": "T9CD",
            },
            "2.0 TDCi 190hp": {
                "std_power": 190,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 EcoBoost 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Bosch MED17.0 & Bosch MEDG17.0 & Bosch MED17.0.1",
                "Engine_code": "UNCK",
            },
            "2.0 EcoBoost 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MEDG17.0 & Bosch MED17.2",
                "Engine_code": "R9CI",
            },
        },
    },
    "Sport trac": {
        "2007 - 2010": {
            "4.0 V6 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 344,
                "tuned_torque": 365,
                "cc": 3958,
                "Type_ecu": "",
                "Engine_code": "4.0 Cologne V6",
            },
        },
    },
    "Taurus": {
        "All": {
            "1.5T EcoBoost 182hp": {
                "std_power": 182,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Siemens/Continental EMS2511",
                "Engine_code": "M9DB",
            },
            "2.0T Ecoboost 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 373,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MG1CS017",
                "Engine_code": "",
            },
            "3.0 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 271,
                "tuned_torque": 290,
                "cc": 2966,
                "Type_ecu": "",
                "Engine_code": "Vulcan V6",
            },
            "3.5 V6 263hp": {
                "std_power": 263,
                "tuned_power": 285,
                "std_torque": 338,
                "tuned_torque": 360,
                "cc": 3496,
                "Type_ecu": "Siemens/Continental EMS24xx",
                "Engine_code": "Duratec 35",
            },
            "3.5T Ecoboost 365hp": {
                "std_power": 365,
                "tuned_power": 400,
                "std_torque": 474,
                "tuned_torque": 550,
                "cc": 3500,
                "Type_ecu": "Bosch MEDG9.8.1",
                "Engine_code": "3.5 Ecoboost",
            },
        },
    },
    "Tourneo": {
        "All": {
            "1.5 TDCI 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 185,
                "tuned_torque": 310,
                "cc": 1498,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "UGCB",
            },
            "1.5 TDCI 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 270,
                "tuned_torque": 310,
                "cc": 1498,
                "Type_ecu": "Bosch EDC17C70 & Bosch MD1CS005",
                "Engine_code": "XWGA",
            },
            "1.8 TDCi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "P9PA",
            },
            "1.8 TDCi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "RPWA",
            },
            "1.8 TDCi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID202 & Siemens/Continental SID206",
                "Engine_code": "BHPA",
            },
            "1.0 Ecoboost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.1",
                "Engine_code": "SFCD",
            },
        },
    },
    "Tourneo Connect": {
        "2014 - 2018": {
            "1.5 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1498,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XWGA",
            },
            "1.5 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1498,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "UGCB",
            },
            "1.5 TDCi 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 190,
                "tuned_torque": 250,
                "cc": 1498,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "",
            },
            "1.6 TDCi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807EVO & Bosch EDC17C70 & Siemens/Continental SID206",
                "Engine_code": "TZGA / TZGB",
            },
            "1.6 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 285,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "T1GA",
            },
            "1.0 EcoBoost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.1 & Bosch MED17.2",
                "Engine_code": "SFCD",
            },
            "1.6T EcoBoost 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1597,
                "Type_ecu": "Bosch MED17.2.2",
                "Engine_code": "JQGA",
            },
        },
        "2019 ->": {
            "1.5 Ecoblue 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005",
                "Engine_code": "Z2GA",
            },
            "1.5 Ecoblue 75hp": {
                "std_power": 75,
                "tuned_power": 110,
                "std_torque": 220,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005",
                "Engine_code": "BEGA",
            },
            "1.5 Ecoblue 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1SC008 & Bosch MD1CS005",
                "Engine_code": "ZTGA",
            },
        },
    },
    "Tourneo Custom": {
        "2013 - 2016": {
            "2.2 TDCI 100hp": {
                "std_power": 100,
                "tuned_power": 180,
                "std_torque": 310,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "DRF4 / DRFF",
            },
            "2.2 TDCI 125hp": {
                "std_power": 125,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "CYF4 / CYFF",
            },
            "2.2 TDCI 155hp": {
                "std_power": 155,
                "tuned_power": 180,
                "std_torque": 385,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "CVFF",
            },
        },
        "2017 ->": {
            "2.0 Ecoblue 105hp": {
                "std_power": 105,
                "tuned_power": 180,
                "std_torque": 360,
                "tuned_torque": 450,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SID211",
                "Engine_code": "YLF",
            },
            "2.0 Ecoblue 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 385,
                "tuned_torque": 450,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SID209 & Siemens/Continental SID211",
                "Engine_code": "",
            },
            "2.0 Ecoblue 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 405,
                "tuned_torque": 450,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SID211",
                "Engine_code": "",
            },
        },
    },
    "Transit": {
        "2019 -> ...": {
            "2.0 TDCI Ecoblue 105hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDCI Ecoblue 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDCI Ecoblue 170hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDCI Ecoblue 185hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "5th - 2000 - 2006": {
            "2.0 DDi 85hp": {
                "std_power": 85,
                "tuned_power": 130,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "Ford EEC-V & Delphi MPC555",
                "Engine_code": "F3FA",
            },
            "2.0 TDCi 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 285,
                "tuned_torque": 330,
                "cc": 1999,
                "Type_ecu": "Delphi MPC555",
                "Engine_code": "FIFA",
            },
            "2.0 TDCi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 1999,
                "Type_ecu": "Delphi MPC555",
                "Engine_code": "",
            },
            "2.0 TDDi 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1999,
                "Type_ecu": "EFI WP41.4",
                "Engine_code": "D3FA",
            },
            "2.4 TDCi 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 285,
                "tuned_torque": 355,
                "cc": 2402,
                "Type_ecu": "Ford EEC-V & Delphi MPC555",
                "Engine_code": "DOFA",
            },
            "2.4 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 375,
                "tuned_torque": 430,
                "cc": 2402,
                "Type_ecu": "Ford EEC-V & Delphi J1850 & Visteon DCU102 & Visteon DCU212",
                "Engine_code": "H9FA",
            },
        },
        "6th - 2006 - 2013": {
            "2.2 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 2198,
                "Type_ecu": "Visteon DCU102 & Siemens/Continental SID208 & Visteon DCU106 & Visteon DCU209",
                "Engine_code": "PGFA / PGFB",
            },
            "2.2 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 2198,
                "Type_ecu": "Visteon DCU102 & Visteon DCU108 & Siemens/Continental SID208 & Visteon DCU209 & Visteon DCU106 & Siemens/Continental SID206",
                "Engine_code": "5RFA / SRFB",
            },
            "2.2 TDCi 85hp": {
                "std_power": 85,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 2198,
                "Type_ecu": "Visteon DCU102 & Siemens/Continental SID208 & Visteon DCU106",
                "Engine_code": "P8FA / P8FB",
            },
            "2.2 TDCi 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 2198,
                "Type_ecu": "Visteon DCU102 & Siemens/Continental SID208 & Siemens/Continental SID209 & Visteon DCU106",
                "Engine_code": "QWFA",
            },
            "2.4 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 285,
                "tuned_torque": 335,
                "cc": 2402,
                "Type_ecu": "Visteon DCU102",
                "Engine_code": "PHFA / PHFC",
            },
            "2.4 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 2402,
                "Type_ecu": "Visteon DCU102 & Visteon DCU106",
                "Engine_code": "JXFA / JXFC",
            },
            "2.4 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 375,
                "tuned_torque": 430,
                "cc": 2402,
                "Type_ecu": "Visteon DCU102 & Visteon DCU106 & Visteon DCU104",
                "Engine_code": "H9FD",
            },
            "3.2 TDCi 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 470,
                "tuned_torque": 530,
                "cc": 3199,
                "Type_ecu": "Visteon DCU102 & Siemens/Continental SID209 & Siemens/Continental SID208",
                "Engine_code": "SAFA / SAFB",
            },
            "2.2 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "5RFA / SRFB",
            },
        },
        "7th - 2013 - 2016": {
            "2.2 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 180,
                "std_torque": 310,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "DRF5 / DRFF / DRFG",
            },
            "2.2 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208 & Siemens/Continental SID209",
                "Engine_code": "",
            },
            "2.2 TDCi 125hp": {
                "std_power": 125,
                "tuned_power": 180,
                "std_torque": 330,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208 & Siemens/Continental SID209",
                "Engine_code": "CY14 / CYF5",
            },
            "2.2 TDCi 155hp": {
                "std_power": 155,
                "tuned_power": 180,
                "std_torque": 355,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208 & Siemens/Continental SID209",
                "Engine_code": "CVF5",
            },
            "2.2 TDCi 85hp": {
                "std_power": 85,
                "tuned_power": 110,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "P8FA / P8FB",
            },
            "2.2 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "",
            },
            "2.4 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 285,
                "tuned_torque": 335,
                "cc": 2402,
                "Type_ecu": "Visteon DCU102",
                "Engine_code": "PHFA / PHFC",
            },
            "2.4 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 310,
                "tuned_torque": 365,
                "cc": 2402,
                "Type_ecu": "Visteon DCU102",
                "Engine_code": "JXFA / JXFC",
            },
            "2.4 TDCi 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 375,
                "tuned_torque": 430,
                "cc": 2402,
                "Type_ecu": "Visteon DCU102",
                "Engine_code": "H9FD",
            },
        },
        "8th - 2017 - 2018": {
            "2.0 TDCi EcoBlue 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 405,
                "tuned_torque": 450,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SID211 & Siemens/Continental SID208",
                "Engine_code": "YNF6 / YNFS",
            },
            "2.0 TDCi EcoBlue 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 385,
                "tuned_torque": 450,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SID211 & Siemens/Continental SID208 & Siemens/Continental SID209",
                "Engine_code": "YMF6 / YMFS",
            },
            "2.0 TDCi EcoBlue 105hp": {
                "std_power": 105,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 450,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SID211 & Siemens/Continental SID208 & Siemens/Continental SID209",
                "Engine_code": "YLF6 / YLFS",
            },
        },
    },
    "Transit Connect": {
        "1st - 2002 - 2012": {
            "1.8 TDCi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID202 & Siemens/Continental SID206 & Delphi MPC555 & Ford EEC-V",
                "Engine_code": "BHPA / P7PA",
            },
            "1.8 TDCi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID206 & Delphi MPC555 & Siemens/Continental SID803A & Siemens/Continental SID202",
                "Engine_code": "P9PC",
            },
            "1.8 TDCi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1753,
                "Type_ecu": "Siemens/Continental SID206 & Siemens/Continental SID202",
                "Engine_code": "RWPA",
            },
        },
        "2th - 2013 - 2016": {
            "1.5 TDCi 75hp": {
                "std_power": 75,
                "tuned_power": 130,
                "std_torque": 185,
                "tuned_torque": 280,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70 & Siemens/Continental SID807EVO",
                "Engine_code": "UBGA",
            },
            "1.5 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XVGA",
            },
            "1.5 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70",
                "Engine_code": "XWGB",
            },
            "1.6 TDCi 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "TZGA",
            },
            "1.6 TDCi 75hp": {
                "std_power": 75,
                "tuned_power": 120,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "UBGA",
            },
            "1.6 TDCi 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO & Siemens/Continental SID309 & Siemens/Continental SID209",
                "Engine_code": "T1GA",
            },
            "1.0 EcoBoost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.1 & Bosch MG1CS016 & Bosch MED17.2",
                "Engine_code": "M2GB",
            },
            "1.6 EcoBoost 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1597,
                "Type_ecu": "Bosch MED17.2.2",
                "Engine_code": "JQGA",
            },
        },
        "3th - 2016 ->": {
            "1.5 Ecoblue 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 220,
                "tuned_torque": 245,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005",
                "Engine_code": "UBGA",
            },
            "1.5 Ecoblue 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005 & Bosch EDC17C70",
                "Engine_code": "",
            },
            "1.5 Ecoblue 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005",
                "Engine_code": "XWGB",
            },
            "1.5 TDCi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 245,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C70 & Bosch MD1CS005",
                "Engine_code": "UBGA",
            },
            "1.5 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005 & Bosch EDC17C70",
                "Engine_code": "XVGA",
            },
            "1.5 TDCi 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS005 & Bosch EDC17C70",
                "Engine_code": "XWGB",
            },
            "1.0 Ecoboost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MG1CS016 & Bosch MED17.2",
                "Engine_code": "",
            },
        },
    },
    "Transit Courier": {
        "2014 ->": {
            "1.5 TDCi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 185,
                "tuned_torque": 245,
                "cc": 1498,
                "Type_ecu": "Bosch EDC17C70 & Bosch EDC17C10",
                "Engine_code": "XUCD",
            },
            "1.5 TDCi 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1498,
                "Type_ecu": "Bosch EDC17C10 & Bosch EDC17C70",
                "Engine_code": "",
            },
            "1.6 TDCi 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "TZGA",
            },
            "1.0 EcoBoost 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.0.1 & Bosch MED17.2.2 & Bosch MG1CS016",
                "Engine_code": "SFCD",
            },
        },
    },
    "Transit Custom": {
        "2013 - 2016": {
            "2.2 TDCi 125hp": {
                "std_power": 125,
                "tuned_power": 180,
                "std_torque": 330,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208 & Siemens/Continental SID211 & Siemens/Continental SID209",
                "Engine_code": "CYFF",
            },
            "2.2 TDCi 155hp": {
                "std_power": 155,
                "tuned_power": 180,
                "std_torque": 385,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208 & Siemens/Continental SID209",
                "Engine_code": "CVFF",
            },
            "2.2 TDCi 100hp": {
                "std_power": 100,
                "tuned_power": 180,
                "std_torque": 310,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "DRFF",
            },
        },
        "2017 - 2018": {
            "2.0 TDCI Ecoblue 185hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDCi Ecoblue 105hp": {
                "std_power": 105,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 460,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SID211 & Siemens/Continental SID209 & Siemens/Continental SID208",
                "Engine_code": "YLF6 / YLFS",
            },
            "2.0 TDCi Ecoblue 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 385,
                "tuned_torque": 460,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SID211 & Siemens/Continental SID209",
                "Engine_code": "YMF6 / YMFS",
            },
            "2.0 TDCi Ecoblue 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 405,
                "tuned_torque": 460,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SID211",
                "Engine_code": "YNF6 / YNFS",
            },
        },
        "2019 -> ...": {
            "2.0 TDCI Ecoblue 185hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDCi Ecoblue Hybrid 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDCi Ecoblue 105hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDCi Ecoblue 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDCi Ecoblue 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 405,
                "tuned_torque": 460,
                "cc": 1996,
                "Type_ecu": "Siemens/Continental SID211",
                "Engine_code": "YNF6 / YNFS",
            },
        },
    },
},
"GMC": {
    "Acadia": {
        "2007 - 2013": {
            "3.6 V6 275hp": {
                "std_power": 275,
                "tuned_power": 295,
                "std_torque": 251,
                "tuned_torque": 280,
                "cc": 3564,
                "Type_ecu": "Bosch ME9.1 & Bosch ME9.5 & Delco E67",
                "Engine_code": "LY7",
            },
            "3.6 V6 288hp": {
                "std_power": 288,
                "tuned_power": 310,
                "std_torque": 366,
                "tuned_torque": 395,
                "cc": 3564,
                "Type_ecu": "Bosch MED9.6.1",
                "Engine_code": "LLT",
            },
        },
        "2017 ->": {
            "3.6 V6 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 367,
                "tuned_torque": 390,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LGX",
            },
        },
    },
    "Canyon": {
        "2003 - 2012": {
            "2.8i 175hp": {
                "std_power": 175,
                "tuned_power": 195,
                "std_torque": 251,
                "tuned_torque": 270,
                "cc": 2770,
                "Type_ecu": "",
                "Engine_code": "LK5",
            },
            "2.9i 185hp": {
                "std_power": 185,
                "tuned_power": 205,
                "std_torque": 258,
                "tuned_torque": 290,
                "cc": 2921,
                "Type_ecu": "",
                "Engine_code": "LLV",
            },
            "3.5 V6 220hp": {
                "std_power": 220,
                "tuned_power": 240,
                "std_torque": 305,
                "tuned_torque": 330,
                "cc": 3460,
                "Type_ecu": "",
                "Engine_code": "L52",
            },
            "3.7 V6 242hp": {
                "std_power": 242,
                "tuned_power": 265,
                "std_torque": 328,
                "tuned_torque": 350,
                "cc": 3653,
                "Type_ecu": "",
                "Engine_code": "LLR",
            },
            "5.3 V8 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 434,
                "tuned_torque": 455,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LH8",
            },
        },
        "2016 ->": {
            "2.8 Duramax 181hp": {
                "std_power": 181,
                "tuned_power": 210,
                "std_torque": 500,
                "tuned_torque": 540,
                "cc": 2800,
                "Type_ecu": "Delco E98",
                "Engine_code": "A 428 DOHC",
            },
            "3.6 V6 308hp": {
                "std_power": 308,
                "tuned_power": 330,
                "std_torque": 373,
                "tuned_torque": 395,
                "cc": 3649,
                "Type_ecu": "",
                "Engine_code": "LGZ",
            },
        },
    },
    "Envoy": {
        "2002 - 2010": {
            "4.2i - 270hp": {
                "std_power": 270,
                "tuned_power": 290,
                "std_torque": 373,
                "tuned_torque": 395,
                "cc": 4160,
                "Type_ecu": "",
                "Engine_code": "LL8",
            },
            "5.3 V8 290hp": {
                "std_power": 290,
                "tuned_power": 310,
                "std_torque": 441,
                "tuned_torque": 460,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LM4",
            },
            "5.3 V8 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 447,
                "tuned_torque": 470,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LM4",
            },
        },
    },
    "Safari": {
        "2001 - 2005": {
            "4.3i - 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 339,
                "tuned_torque": 360,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LU3",
            },
        },
    },
    "Savana": {
        "2003 ->": {
            "6.6 V8 365hp": {
                "std_power": 365,
                "tuned_power": 390,
                "std_torque": 894,
                "tuned_torque": 930,
                "cc": 6599,
                "Type_ecu": "Bosch EDC16C42 & Bosch EDC17CP18",
                "Engine_code": "LMM",
            },
            "4.3 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 353,
                "tuned_torque": 380,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LU3",
            },
            "4.8 V8 275hp": {
                "std_power": 275,
                "tuned_power": 295,
                "std_torque": 393,
                "tuned_torque": 415,
                "cc": 4807,
                "Type_ecu": "Delco E38",
                "Engine_code": "L20",
            },
            "5.3 V8 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 453,
                "tuned_torque": 480,
                "cc": 5328,
                "Type_ecu": "Delco E38",
                "Engine_code": "LMF",
            },
            "5.7 V8 255hp": {
                "std_power": 255,
                "tuned_power": 275,
                "std_torque": 447,
                "tuned_torque": 470,
                "cc": 5736,
                "Type_ecu": "Bosch EDC16C37",
                "Engine_code": "L31",
            },
            "6.0 V8 342hp": {
                "std_power": 342,
                "tuned_power": 360,
                "std_torque": 506,
                "tuned_torque": 530,
                "cc": 5967,
                "Type_ecu": "Delco E38",
                "Engine_code": "L96",
            },
        },
    },
    "Sierra": {
        "1999 - 2007": {
            "6.6 Duramax 365hp": {
                "std_power": 365,
                "tuned_power": 395,
                "std_torque": 894,
                "tuned_torque": 945,
                "cc": 6599,
                "Type_ecu": "Bosch EDC16C42 & Bosch EDC16C41",
                "Engine_code": "LMM",
            },
            "4.3 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LU3",
            },
            "4.8 V8 295hp": {
                "std_power": 295,
                "tuned_power": 315,
                "std_torque": 414,
                "tuned_torque": 435,
                "cc": 4807,
                "Type_ecu": "Delco E38",
                "Engine_code": "L20",
            },
            "5.3 V8 295hp": {
                "std_power": 295,
                "tuned_power": 315,
                "std_torque": 447,
                "tuned_torque": 470,
                "cc": 5327,
                "Type_ecu": "Delco E38",
                "Engine_code": "LMG",
            },
            "6.0 V8 360hp": {
                "std_power": 360,
                "tuned_power": 380,
                "std_torque": 525,
                "tuned_torque": 545,
                "cc": 5967,
                "Type_ecu": "Delco E38",
                "Engine_code": "L96",
            },
            "8,1 V8 330hp": {
                "std_power": 330,
                "tuned_power": 350,
                "std_torque": 610,
                "tuned_torque": 630,
                "cc": 8128,
                "Type_ecu": "",
                "Engine_code": "L18",
            },
        },
        "2008 - 2013": {
            "6.6 Duramax 365hp": {
                "std_power": 365,
                "tuned_power": 395,
                "std_torque": 894,
                "tuned_torque": 945,
                "cc": 6599,
                "Type_ecu": "Bosch EDC16C42 & Bosch EDC17CP18",
                "Engine_code": "LMM",
            },
            "4.3 V6 195hp": {
                "std_power": 195,
                "tuned_power": 215,
                "std_torque": 353,
                "tuned_torque": 370,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LU3",
            },
            "4.8 V8 302hp": {
                "std_power": 302,
                "tuned_power": 320,
                "std_torque": 414,
                "tuned_torque": 435,
                "cc": 4807,
                "Type_ecu": "Delco E38",
                "Engine_code": "L20",
            },
            "5.3 V8 326hp": {
                "std_power": 326,
                "tuned_power": 345,
                "std_torque": 472,
                "tuned_torque": 495,
                "cc": 5328,
                "Type_ecu": "Delco E83",
                "Engine_code": "LMG",
            },
            "6.0 V8 360hp": {
                "std_power": 360,
                "tuned_power": 380,
                "std_torque": 515,
                "tuned_torque": 535,
                "cc": 5967,
                "Type_ecu": "Delco E38",
                "Engine_code": "L76",
            },
            "6.2 V8 403hp": {
                "std_power": 403,
                "tuned_power": 425,
                "std_torque": 565,
                "tuned_torque": 585,
                "cc": 6162,
                "Type_ecu": "Delco E38",
                "Engine_code": "LS3",
            },
        },
        "2014 - 2016": {
            "6.6 Duramax 365hp": {
                "std_power": 365,
                "tuned_power": 395,
                "std_torque": 894,
                "tuned_torque": 945,
                "cc": 6599,
                "Type_ecu": "Bosch EDC16C42 & Bosch EDC17CP18",
                "Engine_code": "LMM",
            },
            "4.3 V6 285hp": {
                "std_power": 285,
                "tuned_power": 305,
                "std_torque": 413,
                "tuned_torque": 440,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LU3",
            },
            "5.3 V8 355hp": {
                "std_power": 355,
                "tuned_power": 375,
                "std_torque": 519,
                "tuned_torque": 540,
                "cc": 5328,
                "Type_ecu": "Delco E92",
                "Engine_code": "L83",
            },
            "6.2 V8 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 609,
                "tuned_torque": 630,
                "cc": 6162,
                "Type_ecu": "Delco E92",
                "Engine_code": "L86",
            },
        },
        "2017 ->": {
            "6.6 V8 445hp": {
                "std_power": 445,
                "tuned_power": 500,
                "std_torque": 1234,
                "tuned_torque": 1350,
                "cc": 6359,
                "Type_ecu": "",
                "Engine_code": "DURAMAX L5P",
            },
            "4.3 V6 285hp": {
                "std_power": 285,
                "tuned_power": 305,
                "std_torque": 413,
                "tuned_torque": 440,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LU3",
            },
            "5.3 V8 355hp": {
                "std_power": 355,
                "tuned_power": 375,
                "std_torque": 519,
                "tuned_torque": 540,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "L83",
            },
            "6.0 V8 360hp": {
                "std_power": 360,
                "tuned_power": 383,
                "std_torque": 515,
                "tuned_torque": 539,
                "cc": 5965,
                "Type_ecu": "",
                "Engine_code": "Vortec 6000",
            },
            "6.2 V8 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 609,
                "tuned_torque": 630,
                "cc": 6162,
                "Type_ecu": "",
                "Engine_code": "L86",
            },
        },
    },
    "Sonoma": {
        "1997 - 2004": {
            "2.2i 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 210,
                "cc": 2189,
                "Type_ecu": "",
                "Engine_code": "L43",
            },
            "4.3 V6 195hp": {
                "std_power": 195,
                "tuned_power": 215,
                "std_torque": 352,
                "tuned_torque": 375,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "LU3",
            },
        },
    },
    "Terrain": {
        "2009 - 2015": {
            "2.4i 182hp": {
                "std_power": 182,
                "tuned_power": 200,
                "std_torque": 233,
                "tuned_torque": 255,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LAF",
            },
            "3.0 V6 264hp": {
                "std_power": 264,
                "tuned_power": 285,
                "std_torque": 301,
                "tuned_torque": 320,
                "cc": 2994,
                "Type_ecu": "Delco E39A",
                "Engine_code": "LF1 / LFW",
            },
            "3.6 V6 301hp": {
                "std_power": 301,
                "tuned_power": 320,
                "std_torque": 369,
                "tuned_torque": 390,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LFX",
            },
        },
        "2016 - 2017": {
            "2.4i 182hp": {
                "std_power": 182,
                "tuned_power": 200,
                "std_torque": 233,
                "tuned_torque": 255,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LAF",
            },
            "3.6 V6 301hp": {
                "std_power": 301,
                "tuned_power": 320,
                "std_torque": 369,
                "tuned_torque": 390,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LFX",
            },
        },
        "2017 - 2020": {
            "1.6d 137hp": {
                "std_power": 137,
                "tuned_power": 165,
                "std_torque": 325,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "LH7",
            },
            "1.5T 170hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0T 252hp": {
                "std_power": 252,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E80",
                "Engine_code": "2.0 LTG",
            },
        },
    },
    "Yukon": {
        "2000 - 2006": {
            "4.8 V8 275hp": {
                "std_power": 275,
                "tuned_power": 295,
                "std_torque": 393,
                "tuned_torque": 415,
                "cc": 4807,
                "Type_ecu": "",
                "Engine_code": "LR4",
            },
            "5.3 V8 295hp": {
                "std_power": 295,
                "tuned_power": 315,
                "std_torque": 447,
                "tuned_torque": 465,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LM7",
            },
            "6.0 V8 329hp": {
                "std_power": 329,
                "tuned_power": 350,
                "std_torque": 495,
                "tuned_torque": 515,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "L96",
            },
            "8.1 V8 325hp": {
                "std_power": 325,
                "tuned_power": 345,
                "std_torque": 597,
                "tuned_torque": 620,
                "cc": 8128,
                "Type_ecu": "",
                "Engine_code": "L18",
            },
        },
        "2007 - 2013": {
            "4.8 V8 295hp": {
                "std_power": 295,
                "tuned_power": 315,
                "std_torque": 393,
                "tuned_torque": 415,
                "cc": 4807,
                "Type_ecu": "Delco E38",
                "Engine_code": "LY2",
            },
            "5.3 V8 320hp": {
                "std_power": 320,
                "tuned_power": 340,
                "std_torque": 481,
                "tuned_torque": 500,
                "cc": 5328,
                "Type_ecu": "Delco E38",
                "Engine_code": "LMG",
            },
            "6.0 V8 Hybrid 332hp": {
                "std_power": 332,
                "tuned_power": 335,
                "std_torque": 498,
                "tuned_torque": 530,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "LZ1",
            },
            "6.2 V8 405hp": {
                "std_power": 405,
                "tuned_power": 425,
                "std_torque": 565,
                "tuned_torque": 585,
                "cc": 6162,
                "Type_ecu": "Delco E38",
                "Engine_code": "L92",
            },
        },
        "2014 ->": {
            "5.3 V8 340hp": {
                "std_power": 340,
                "tuned_power": 360,
                "std_torque": 519,
                "tuned_torque": 540,
                "cc": 5328,
                "Type_ecu": "Delco E92",
                "Engine_code": "L83",
            },
            "6.2 V8 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 610,
                "tuned_torque": 630,
                "cc": 6162,
                "Type_ecu": "Delco E92",
                "Engine_code": "L86",
            },
        },
    },
},
"Genesis": {
    "G70": {
        "2017 - 2020": {
            "2.2D 202hp": {
                "std_power": 202,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "",
                "Engine_code": "D4HC",
            },
            "2.0 T-GDI 252hp": {
                "std_power": 252,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "G4KL",
            },
            "3.3 T-GDI 370hp": {
                "std_power": 370,
                "tuned_power": 400,
                "std_torque": 520,
                "tuned_torque": 610,
                "cc": 3342,
                "Type_ecu": "Siemens/Continental SIM2K-260",
                "Engine_code": "G6DP",
            },
        },
        "2020 -> ...": {
            "2.2 CRDi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4HC",
            },
            "2.2 e-VGT 202hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T-GDI 252hp": {
                "std_power": 252,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "G4KL",
            },
            "2.0 T-GDI 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "G4KL",
            },
            "3.3 T-GDI 370hp": {
                "std_power": 370,
                "tuned_power": 400,
                "std_torque": 520,
                "tuned_torque": 610,
                "cc": 3342,
                "Type_ecu": "Siemens/Continental SIM2K-260",
                "Engine_code": "G6DP",
            },
        },
    },
    "G80": {
        "2016 - 2020": {
            "2.0T 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "G4KL",
            },
            "3.3 T-GDI 370hp": {
                "std_power": 370,
                "tuned_power": 400,
                "std_torque": 510,
                "tuned_torque": 610,
                "cc": 3342,
                "Type_ecu": "Siemens/Continental SIM2K-260",
                "Engine_code": "G6DP",
            },
        },
        "2020 -> ...": {
            "2.2 e-VGT 210hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.5 T-GDI 304hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5 T-GDI 380hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "G90": {
        "2016 - 2018": {
            "3.3 T-GDI 370hp": {
                "std_power": 370,
                "tuned_power": 400,
                "std_torque": 510,
                "tuned_torque": 610,
                "cc": 3342,
                "Type_ecu": "Siemens/Continental SIM2K-260",
                "Engine_code": "G6DP",
            },
        },
        "2018 - 2021": {
            "3.8 T-GDI 370hp": {
                "std_power": 370,
                "tuned_power": 400,
                "std_torque": 510,
                "tuned_torque": 610,
                "cc": 3342,
                "Type_ecu": "Siemens/Continental SIM2K-260",
                "Engine_code": "G6DP",
            },
        },
    },
    "GV70": {
        "2021 -> ...": {
            "2.2 e-VGT 210hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.5 T-GDI 304hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5 T-GDI 380hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "GV80": {
        "2020 -> ...": {
            "3.0D 278hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.5 T-GDI 304hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5 T-GDi 380hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
},
"Holden": {
    "Captiva": {
        "2007 - 2009": {
            "2.0 VCDI 127hp": {
                "std_power": 127,
                "tuned_power": 185,
                "std_torque": 295,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "",
                "Engine_code": "Z20S",
            },
            "2.0 VCDI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z20S",
            },
            "3.2 V6 230hp": {
                "std_power": 230,
                "tuned_power": 250,
                "std_torque": 297,
                "tuned_torque": 315,
                "cc": 3195,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "10HM",
            },
        },
        "2013 ->": {
            "2.2L 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2231,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "Z22D1",
            },
            "2.4L 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 230,
                "tuned_torque": 290,
                "cc": 2384,
                "Type_ecu": "Delco E37",
                "Engine_code": "LE5",
            },
        },
    },
    "Colorado": {
        "All": {
            "2.8 181hp": {
                "std_power": 181,
                "tuned_power": 200,
                "std_torque": 500,
                "tuned_torque": 530,
                "cc": 2800,
                "Type_ecu": "Bosch EDC16C39 & Delco E98",
                "Engine_code": "A 428 DOHC",
            },
            "3.0D 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2999,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Commodore": {
        "1999 - 2005": {
            "5.7 V8 306hp": {
                "std_power": 306,
                "tuned_power": 340,
                "std_torque": 460,
                "tuned_torque": 500,
                "cc": 4665,
                "Type_ecu": "",
                "Engine_code": "LS1",
            },
        },
        "2005 - 2008": {
            "3.6 V6 235hp": {
                "std_power": 235,
                "tuned_power": 255,
                "std_torque": 325,
                "tuned_torque": 345,
                "cc": 3564,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "LY7",
            },
            "6.0 V8 Clubsport 412hp": {
                "std_power": 412,
                "tuned_power": 435,
                "std_torque": 550,
                "tuned_torque": 575,
                "cc": 5967,
                "Type_ecu": "Delco E38",
                "Engine_code": "LS2",
            },
            "6.0 V8 354hp": {
                "std_power": 354,
                "tuned_power": 375,
                "std_torque": 510,
                "tuned_torque": 540,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "L76",
            },
        },
        "2013 ->": {
            "6.0 V8 350hp": {
                "std_power": 350,
                "tuned_power": 370,
                "std_torque": 517,
                "tuned_torque": 535,
                "cc": 5967,
                "Type_ecu": "Delco E38",
                "Engine_code": "L77",
            },
            "6.0 V8 360hp": {
                "std_power": 360,
                "tuned_power": 380,
                "std_torque": 530,
                "tuned_torque": 550,
                "cc": 5967,
                "Type_ecu": "Delco E38",
                "Engine_code": "L77",
            },
            "6.2 V8 408hp": {
                "std_power": 408,
                "tuned_power": 430,
                "std_torque": 570,
                "tuned_torque": 590,
                "cc": 6162,
                "Type_ecu": "Delco E38",
                "Engine_code": "LS3",
            },
        },
    },
    "Cruze": {
        "2012 - 2013": {
            "1.4 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 125,
                "tuned_torque": 145,
                "cc": 1364,
                "Type_ecu": "",
                "Engine_code": "Z14XEP",
            },
        },
    },
    "Monaro": {
        "2001 - 2005": {
            "5.7 V8 306hp": {
                "std_power": 306,
                "tuned_power": 325,
                "std_torque": 460,
                "tuned_torque": 480,
                "cc": 5665,
                "Type_ecu": "",
                "Engine_code": "LS1",
            },
            "5.7 V8 408hp": {
                "std_power": 408,
                "tuned_power": 430,
                "std_torque": 460,
                "tuned_torque": 480,
                "cc": 4556,
                "Type_ecu": "",
                "Engine_code": "LS1",
            },
        },
    },
    "Rodeo": {
        "2006 - 2007": {
            "3.6 V6 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 313,
                "tuned_torque": 330,
                "cc": 3564,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "LY7",
            },
        },
    },
    "Trax": {
        "2013->": {
            "1.7D 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "A17DTS",
            },
            "1.4L 16v 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6L 16v 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 155,
                "tuned_torque": 215,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "A16XER",
            },
            "1.8L 16v 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIMTEC 76",
                "Engine_code": "A18XER",
            },
        },
    },
},
"Honda": {
    "Accord": {
        "All": {
            "2.2 CTDi 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2204,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C1-7",
                "Engine_code": "NN2A2",
            },
            "2.2 i-DTEC 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17CP06 & Bosch EDC17CP16 & Bosch EDC17CP09 & Bosch EDC17CP50",
                "Engine_code": "N22B1",
            },
            "2.2 i-DTEC 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17CP16",
                "Engine_code": "N22B2",
            },
            "1.5 Turbo 192hp": {
                "std_power": 192,
                "tuned_power": 220,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Keihin TC1782",
                "Engine_code": "L15BM",
            },
            "2.0 Turbo 252hp": {
                "std_power": 252,
                "tuned_power": 280,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1996,
                "Type_ecu": "Keihin PGM-FI KEHIN & Keihin 37820",
                "Engine_code": "K20C4",
            },
        },
    },
    "CR-V": {
        "2002 - 2006": {
            "2.2 CTDi 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2204,
                "Type_ecu": "Bosch EDC16C7 & Bosch EDC16C10",
                "Engine_code": "N22A1",
            },
        },
        "2007 - 2012": {
            "2.2 CTDi 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2204,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C7",
                "Engine_code": "N22A1",
            },
            "2.2 i-DTEC 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2204,
                "Type_ecu": "Bosch EDC17CP16",
                "Engine_code": "N22B3",
            },
        },
        "2012 - 2015": {
            "1.6 i-DTEC 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "N16A1",
            },
            "1.6 i-DTEC 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "N16A2",
            },
            "2.2 i-DTEC 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2204,
                "Type_ecu": "Bosch EDC17CP50 & Bosch EDC17CP16",
                "Engine_code": "N22B3",
            },
        },
        "2015 - 2016": {
            "1.6 i-DTEC 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "N16A1",
            },
            "1.6 i-DTEC 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "N16A2",
            },
        },
        "2017 - 2019": {
            "1.6 i-DTEC 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "N16A1",
            },
            "1.6 i-DTEC 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "N16A2",
            },
            "1.5 VTEC Turbo 173hp": {
                "std_power": 173,
                "tuned_power": 220,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Keihin TC1782",
                "Engine_code": "L15",
            },
            "1.5 VTEC Turbo 193hp": {
                "std_power": 193,
                "tuned_power": 220,
                "std_torque": 243,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Keihin TC1782 & Keihin 37805-Pxx & Keihin 37820-5Ax",
                "Engine_code": "L15",
            },
        },
        "2019 ->": {
            "1.5T VTEC 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 243,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Keihin 37820",
                "Engine_code": "L15BE",
            },
            "1.5T VTEC 173hp": {
                "std_power": 173,
                "tuned_power": 220,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Keihin TC1766 & Keihin TC1782",
                "Engine_code": "L15",
            },
        },
    },
    "City": {
        "2017 - 2019": {
            "1.5 i-DTEC 99hp": {
                "std_power": 99,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 1498,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "",
            },
        },
        "2020 -> ...": {
            "1.0T 122hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Civic": {
        "2002 - 2005": {
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 220,
                "tuned_torque": 270,
                "cc": 1686,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "4EE2",
            },
        },
        "2006 - 2012": {
            "2.2 CTDi 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2204,
                "Type_ecu": "Bosch EDC16C7 & Bosch EDC16C2 & Bosch EDC16C31",
                "Engine_code": "N22A1",
            },
            "2.2 i-DTEC 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17CP50",
                "Engine_code": "N22B4",
            },
            "2.0 Type R i-VTEC 201hp": {
                "std_power": 201,
                "tuned_power": 216,
                "std_torque": 193,
                "tuned_torque": 210,
                "cc": 1998,
                "Type_ecu": "Keihin 37805-Rxx",
                "Engine_code": "K20A",
            },
        },
        "2013 - 2016": {
            "1.6 i-DTEC 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "N16A1",
            },
            "2.2 i-DTEC 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17CP50",
                "Engine_code": "N22B4",
            },
            "1.8 i-VTEC 142hp": {
                "std_power": 142,
                "tuned_power": 155,
                "std_torque": 174,
                "tuned_torque": 185,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.9.3 & Keihin SH72543 & Keihin 37820",
                "Engine_code": "R18Z4",
            },
            "2.0 T Type R 310hp": {
                "std_power": 310,
                "tuned_power": 350,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 1996,
                "Type_ecu": "Bosch MED17.9.3",
                "Engine_code": "K20C1",
            },
        },
        "2017 - 2020": {
            "1.6 i-DTEC 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "N16A1",
            },
            "1.0 VTEC 129hp": {
                "std_power": 129,
                "tuned_power": 150,
                "std_torque": 180,
                "tuned_torque": 240,
                "cc": 988,
                "Type_ecu": "Bosch MED17.9.3",
                "Engine_code": "P10A2",
            },
            "1.5 VTEC 182hp": {
                "std_power": 182,
                "tuned_power": 220,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Keihin 37805-Pxx & Keihin TC1782 & Keihin 37820",
                "Engine_code": "L15B7",
            },
            "2.0T Type-R 320hp": {
                "std_power": 320,
                "tuned_power": 360,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 1996,
                "Type_ecu": "Bosch MED17.9.3",
                "Engine_code": "K20C1",
            },
        },
        "2020 - 2022": {
            "1.6 iDTEC 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "",
            },
            "1.5T VTEC 182hp": {
                "std_power": 182,
                "tuned_power": 220,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Keihin TC1782",
                "Engine_code": "L15B7",
            },
            "2.0T Type R 306hp": {
                "std_power": 306,
                "tuned_power": 360,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 1996,
                "Type_ecu": "Bosch EDC17C08",
                "Engine_code": "",
            },
        },
        "2022 -> ...": {
            "2.0T Type R 330hp": {
                "std_power": 330,
                "tuned_power": 360,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 1996,
                "Type_ecu": "Bosch MED17.9.3",
                "Engine_code": "",
            },
            "1.5T VTEC 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "SI 1.5T VTEC 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "FR-V": {
        "2004 - 2010": {
            "2.2 CTDi 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2204,
                "Type_ecu": "Bosch EDC16C1-7",
                "Engine_code": "N22A2",
            },
        },
    },
    "HR-V": {
        "2015 - 2018": {
            "1.6 i-DTEC 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "N16A1",
            },
        },
        "2018 - 2020": {
            "1.6 i-DTEC 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1597,
                "Type_ecu": "Bosch EDC17C58",
                "Engine_code": "N16A1",
            },
            "1.5T VTEC 182hp": {
                "std_power": 182,
                "tuned_power": 220,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Keihin SH72543",
                "Engine_code": "L15BY",
            },
        },
    },
    "NSX": {
        "2017 ->": {
            "3.5 V6 Bi-Turbo 580hp": {
                "std_power": 580,
                "tuned_power": 630,
                "std_torque": 645,
                "tuned_torque": 700,
                "cc": 3493,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "Type S 3.5 V6 Bi-Turbo 607hp": {
                "std_power": 607,
                "tuned_power": 630,
                "std_torque": 645,
                "tuned_torque": 667,
                "cc": 3493,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "S2000": {
        "2003 ->": {
            "2.2 VTEC 240hp": {
                "std_power": 240,
                "tuned_power": 255,
                "std_torque": 220,
                "tuned_torque": 240,
                "cc": 2157,
                "Type_ecu": "Keihin 37805-Pxx",
                "Engine_code": "F22C1",
            },
        },
    },
},
"Hummer": {
    "H1": {
        "-> 2006": {
            "6.6 V8 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 820,
                "tuned_torque": 850,
                "cc": 6599,
                "Type_ecu": "",
                "Engine_code": "LLY",
            },
        },
    },
    "H2": {
        "2003 - 2007": {
            "6.0 V8 316hp": {
                "std_power": 316,
                "tuned_power": 335,
                "std_torque": 522,
                "tuned_torque": 545,
                "cc": 5964,
                "Type_ecu": "Delphi E3",
                "Engine_code": "LQ4",
            },
        },
        "2008 - 2009": {
            "6.2 V8 393hp": {
                "std_power": 393,
                "tuned_power": 415,
                "std_torque": 574,
                "tuned_torque": 600,
                "cc": 6162,
                "Type_ecu": "Delco E38",
                "Engine_code": "",
            },
        },
    },
    "H3": {
        "2006 - 2010": {
            "3.5 220hp": {
                "std_power": 220,
                "tuned_power": 240,
                "std_torque": 305,
                "tuned_torque": 325,
                "cc": 3460,
                "Type_ecu": "",
                "Engine_code": "L52",
            },
            "3.7 242hp": {
                "std_power": 242,
                "tuned_power": 260,
                "std_torque": 328,
                "tuned_torque": 350,
                "cc": 3653,
                "Type_ecu": "Delco E67",
                "Engine_code": "LLR",
            },
            "5.3 V8 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 430,
                "tuned_torque": 450,
                "cc": 5328,
                "Type_ecu": "Delco E38",
                "Engine_code": "LH8",
            },
        },
    },
},
"Hyundai": {
    "Accent": {
        "All": {
            "1.5 CRDi 82hp": {
                "std_power": 82,
                "tuned_power": 102,
                "std_torque": 184,
                "tuned_torque": 234,
                "cc": 1493,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D4FA",
            },
            "1.5 CRDi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1493,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D3EA",
            },
            "1.6 CRDI 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1582,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 CRDI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C53",
                "Engine_code": "D4FB",
            },
        },
    },
    "Bayon": {
        "2021 -> ...": {
            "1.0 T-GDI 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 T-GDI 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Elantra": {
        "2000 - 2006": {
            "2.0 CRDi 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 285,
                "cc": 1991,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D4EA",
            },
        },
    },
    "Genesis": {
        "2011 ->": {
            "2.0 Turbo 213hp": {
                "std_power": 213,
                "tuned_power": 240,
                "std_torque": 302,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-141",
                "Engine_code": "G4KF",
            },
            "2.0 Turbo 260hp": {
                "std_power": 260,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K P521 & Siemens/Continental SIM2K-141",
                "Engine_code": "G4KF",
            },
        },
    },
    "Getz": {
        "2002 - 2008": {
            "1.5 CRDi 88hp": {
                "std_power": 88,
                "tuned_power": 105,
                "std_torque": 215,
                "tuned_torque": 265,
                "cc": 1493,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "DF4A",
            },
            "1.5 CRDi 82hp": {
                "std_power": 82,
                "tuned_power": 102,
                "std_torque": 187,
                "tuned_torque": 230,
                "cc": 1493,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D3EA",
            },
            "1.5 CRDi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1493,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "DF4B",
            },
            "2.0 CRDi 113hp": {
                "std_power": 113,
                "tuned_power": 140,
                "std_torque": 255,
                "tuned_torque": 300,
                "cc": 1991,
                "Type_ecu": "",
                "Engine_code": "D4EA",
            },
        },
    },
    "Grandeur": {
        "2005 - 2010": {
            "2.2 CRDi 155hp": {
                "std_power": 155,
                "tuned_power": 182,
                "std_torque": 343,
                "tuned_torque": 420,
                "cc": 2188,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C57",
                "Engine_code": "D4EB",
            },
        },
        "2016 - 2019": {
            "2.2 CRDi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "H-Serie": {
        "2008 - 2015": {
            "H200 2.5 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 343,
                "tuned_torque": 400,
                "cc": 2497,
                "Type_ecu": "Bosch EDC15C7 & Bosch EDC16C39",
                "Engine_code": "D4CB-G",
            },
            "H200 2.5 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 343,
                "tuned_torque": 400,
                "cc": 2497,
                "Type_ecu": "Delphi DCM3.7 & Denso 39199 & Denso SH72546",
                "Engine_code": "D4CB-G",
            },
            "H200 2.5 CRDi 116hp": {
                "std_power": 116,
                "tuned_power": 170,
                "std_torque": 343,
                "tuned_torque": 400,
                "cc": 2497,
                "Type_ecu": "Delphi DCM3.7AP & Bosch EDC16C39",
                "Engine_code": "",
            },
            "H300 2.5 CRDi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 463,
                "cc": 2497,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4CB",
            },
            "H300 2.5 CRDi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 463,
                "cc": 2497,
                "Type_ecu": "Bosch EDC16C39 & Delphi DCM3.7 & Denso SH72546 & Denso MB275700-5714",
                "Engine_code": "D4CB",
            },
        },
        "2015 - 2018": {
            "H200 2.5 CRDi 116hp": {
                "std_power": 116,
                "tuned_power": 170,
                "std_torque": 343,
                "tuned_torque": 400,
                "cc": 2497,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "H200 2.5 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 343,
                "tuned_torque": 400,
                "cc": 2497,
                "Type_ecu": "Delphi DCM3.7 & Denso SH72546",
                "Engine_code": "D4CB-G",
            },
            "H300 2.5 CRDi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 463,
                "cc": 2497,
                "Type_ecu": "Denso 39199 & Denso MB275700-5715",
                "Engine_code": "D4CB",
            },
        },
        "2018 -> ...": {
            "2.5D 175hp": {
                "std_power": 175,
                "tuned_power": 200,
                "std_torque": 441,
                "tuned_torque": 460,
                "cc": 2497,
                "Type_ecu": "Denso SH72546 & Delphi DCM3.7",
                "Engine_code": "D4CB",
            },
            "H200 2.5 CRDi 116hp": {
                "std_power": 116,
                "tuned_power": 170,
                "std_torque": 343,
                "tuned_torque": 400,
                "cc": 2497,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "H200 2.5 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 343,
                "tuned_torque": 400,
                "cc": 2497,
                "Type_ecu": "Bosch MD1CS012",
                "Engine_code": "D4CB-G",
            },
            "H300 2.5 CRDi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 463,
                "cc": 2497,
                "Type_ecu": "Bosch MD1CS012 & Denso MB275700-5715 & Bosch EDC17C81",
                "Engine_code": "D4CB",
            },
        },
    },
    "Kona": {
        "2017 - 2020": {
            "1.6 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.4 & Kefico CPEGD2.20.1",
                "Engine_code": "G3LC",
            },
            "1.6 T-GDI 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 265,
                "tuned_torque": 340,
                "cc": 1591,
                "Type_ecu": "Kefico CPEGD2.20.2 & Kefico CPEGD2.20.3",
                "Engine_code": "G4FJ",
            },
        },
        "2020 - 2023": {
            "1.6 CRDi 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.4",
                "Engine_code": "G3LC",
            },
            "1.6 T-GDI 195hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T-GDI N Performance 280hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2023 -> ...": {
            "1.0 T-GDI 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI 198hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Matrix": {
        "All": {
            "1.5 CRDi 82hp": {
                "std_power": 82,
                "tuned_power": 112,
                "std_torque": 187,
                "tuned_torque": 240,
                "cc": 1493,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D3EA",
            },
            "1.5 CRDi 102hp": {
                "std_power": 102,
                "tuned_power": 130,
                "std_torque": 235,
                "tuned_torque": 270,
                "cc": 1493,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D3EA",
            },
            "1.5 CRDi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1493,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4FA",
            },
        },
    },
    "Santa Fe": {
        "2001 - 2006": {
            "2.0 CRDi 125hp": {
                "std_power": 125,
                "tuned_power": 152,
                "std_torque": 285,
                "tuned_torque": 330,
                "cc": 1991,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D4EA VGT",
            },
            "2.0 CRDi 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 255,
                "tuned_torque": 300,
                "cc": 1991,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D4EA",
            },
            "2.0 CRDi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 335,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D4HA",
            },
        },
        "2006 - 2013": {
            "2.2 CRDi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2188,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EB-V",
            },
            "2.2 CRDi 197hp": {
                "std_power": 197,
                "tuned_power": 230,
                "std_torque": 422,
                "tuned_torque": 480,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17CP14",
                "Engine_code": "D4HB",
            },
        },
        "2013 - 2018": {
            "2.0 CRDi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 335,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC15C7 & Bosch EDC17C53",
                "Engine_code": "D4HA",
            },
            "2.2 CRDI 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C57 & Bosch EDC17CP14",
                "Engine_code": "D4HB",
            },
            "2.0T 264hp": {
                "std_power": 264,
                "tuned_power": 300,
                "std_torque": 365,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-24x & Siemens/Continental SIM2K-25x",
                "Engine_code": "",
            },
        },
        "2018 - 2020": {
            "2.0 CRDi 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 CRDi 185hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.2 CRDi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4HB",
            },
            "2.4 GDI 185hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Sonata": {
        "All": {
            "2.0 CRDi VGT 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 365,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA-V",
            },
            "2.0 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 305,
                "tuned_torque": 365,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA",
            },
            "2.0 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 305,
                "tuned_torque": 365,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA",
            },
            "1.6 T-GDI 177hp": {
                "std_power": 177,
                "tuned_power": 220,
                "std_torque": 265,
                "tuned_torque": 350,
                "cc": 1591,
                "Type_ecu": "Siemens/Continental SIM2K-305 & Bosch MEDG17.9.8",
                "Engine_code": "G4FJ",
            },
            "2.0 T-GDI 248hp": {
                "std_power": 248,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "",
            },
        },
    },
    "Starex": {
        "All": {
            "2.5 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 343,
                "tuned_torque": 400,
                "cc": 2497,
                "Type_ecu": "Bosch EDC15C7 & Denso SH72546 & Delphi 3.N5 & Bosch EDC16CP31 & Delphi DCM3.7AP",
                "Engine_code": "D4CB",
            },
            "2.5 CRDi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 460,
                "cc": 2497,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16CP31 & Denso SH72546 & Denso SH7058 & Denso MB275700-5715",
                "Engine_code": "D4CB",
            },
            "2.5 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 343,
                "tuned_torque": 400,
                "cc": 2497,
                "Type_ecu": "Denso 39199",
                "Engine_code": "",
            },
        },
    },
    "Staria": {
        "2021 -> ...": {
            "2.2 CRDI 177hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Terracan": {
        "2001 - 2007": {
            "2.9 CRDi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 333,
                "tuned_torque": 390,
                "cc": 2902,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "J3 CR",
            },
            "2.9 CRDi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 460,
                "cc": 2902,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "J3 CR",
            },
        },
    },
    "Trajet": {
        "2000 - 2008": {
            "2.0 CRDi 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 255,
                "tuned_torque": 300,
                "cc": 1991,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D4EA",
            },
        },
    },
    "Tucson": {
        "2004 - 2015": {
            "2.0 CRDi 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 245,
                "tuned_torque": 300,
                "cc": 1991,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D4EA",
            },
            "2.0 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 305,
                "tuned_torque": 365,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17CP14",
                "Engine_code": "D4EA",
            },
            "2.0i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 152,
                "std_torque": 184,
                "tuned_torque": 204,
                "cc": 1975,
                "Type_ecu": "Siemens/Continental SIMK-43",
                "Engine_code": "G4GC",
            },
        },
        "2015 - 2018": {
            "1.7 CRDi 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FD",
            },
            "1.7 CRDi 141hp": {
                "std_power": 141,
                "tuned_power": 170,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FD",
            },
            "2.0 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 373,
                "tuned_torque": 430,
                "cc": 1991,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4EA",
            },
            "2.0 CRDi 185hp": {
                "std_power": 185,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C57 & Bosch EDC17CP14",
                "Engine_code": "D4HA",
            },
            "1.6 T-GDI 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 265,
                "tuned_torque": 340,
                "cc": 1591,
                "Type_ecu": "Bosch MED17.9.8 & Siemens/Continental SIM2K-305 & Kefico CPEGD2.20.3",
                "Engine_code": "G4FJ",
            },
        },
        "2018 - 2020": {
            "1.6 CRDi 116hp": {
                "std_power": 116,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FE",
            },
            "1.6 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FE",
            },
            "2.0 CRDi 185hp": {
                "std_power": 185,
                "tuned_power": 215,
                "std_torque": 400,
                "tuned_torque": 475,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4HA",
            },
            "1.6 T-GDI 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 265,
                "tuned_torque": 350,
                "cc": 1591,
                "Type_ecu": "Kefico CPEGD2.20.3 & Bosch MED17.9.8",
                "Engine_code": "G4FJ",
            },
        },
        "2021 ->": {
            "1.6 CRDI 115hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 CRDI 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI Hybrid 265hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI Hybrid 230hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Veloster": {
        "2011 ->": {
            "1.6 GDI Turbo 186hp": {
                "std_power": 186,
                "tuned_power": 220,
                "std_torque": 265,
                "tuned_torque": 325,
                "cc": 1591,
                "Type_ecu": "Bosch MED17.9.8 & Kefico CPEGD2.20.3",
                "Engine_code": "G4FJ",
            },
            "1.6 GDI Turbo 204hp": {
                "std_power": 204,
                "tuned_power": 235,
                "std_torque": 265,
                "tuned_torque": 340,
                "cc": 1591,
                "Type_ecu": "Bosch MED17.9.8 & Kefico CPEGD2.20.4 & Bosch MEDG17.0 & Kefico CPEGD2.20.3",
                "Engine_code": "G4FJ",
            },
            "1.6 GDI 140hp": {
                "std_power": 140,
                "tuned_power": 155,
                "std_torque": 167,
                "tuned_torque": 185,
                "cc": 1591,
                "Type_ecu": "Bosch MED17.9.8 & Bosch MED17.9.X",
                "Engine_code": "G4FD",
            },
            "N 2.0 T-GDI 275hp": {
                "std_power": 275,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "G4KC",
            },
        },
    },
    "Verna": {
        "2010 ->": {
            "1.5 CRDi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1493,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4FA",
            },
        },
        "2020 ->": {
            "1.5 CRDI 115hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 171,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.4",
                "Engine_code": "",
            },
        },
    },
    "i10": {
        "2008 - 2018": {
            "1.1 CRDi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 153,
                "tuned_torque": 190,
                "cc": 1120,
                "Type_ecu": "Bosch EDC16C39 & Delphi DCM6.2AP & Delphi DCM3.7AP",
                "Engine_code": "D3FA",
            },
            "1.0i 69hp": {
                "std_power": 69,
                "tuned_power": 79,
                "std_torque": 95,
                "tuned_torque": 110,
                "cc": 998,
                "Type_ecu": "Bosch M7.9.8 & Bosch ME17.9.11 & Kefico CPEGD2.20.x & Bosch ME17.9.61",
                "Engine_code": "G3LA",
            },
            "1.1i 66hp": {
                "std_power": 66,
                "tuned_power": 73,
                "std_torque": 98,
                "tuned_torque": 110,
                "cc": 1086,
                "Type_ecu": "Bosch ME17.9.11 & Kefico ME798 & Kefico KME1.9.3",
                "Engine_code": "G4HG Epsilon",
            },
        },
        "2019 ->": {
            "1.0 T-GDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.x",
                "Engine_code": "",
            },
        },
    },
    "i20": {
        "2008 - 2014": {
            "1.1 CRDi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 180,
                "tuned_torque": 220,
                "cc": 1120,
                "Type_ecu": "Bosch EDC16C39 & Delphi DCM3.7 & Delphi DCM3.7AP",
                "Engine_code": "D3FA",
            },
            "1.4 CRDi 75hp": {
                "std_power": 75,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Delphi DCM3.7 & Delphi DCM3.7AP",
                "Engine_code": "D4FC",
            },
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Bosch EDC17 & Delphi DCM3.7",
                "Engine_code": "D4FC",
            },
            "1.6 CRDi 128hp": {
                "std_power": 128,
                "tuned_power": 160,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08",
                "Engine_code": "D4FB",
            },
        },
        "2014 - 2018": {
            "1.1 CRDi 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 180,
                "tuned_torque": 220,
                "cc": 1120,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DF3A",
            },
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Bosch EDC17C57 & Delphi DCM3.7",
                "Engine_code": "D4FC",
            },
            "1.0 T-GDi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2 & Bosch ME17.9.21",
                "Engine_code": "G3LC",
            },
            "1.0 T-GDi 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2",
                "Engine_code": "G3LC",
            },
        },
        "2018 - 2019": {
            "1.4 CRDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Bosch EDC17C57 & Delphi DCM3.7AP",
                "Engine_code": "D4FC",
            },
            "1.0 T-GDi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.4 & Kefico CPEGD2.20.2",
                "Engine_code": "G3LC",
            },
            "1.0 T-GDi 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.4",
                "Engine_code": "G3LC",
            },
        },
        "2020 - 2023": {
            "1.0 T-GDi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.4",
                "Engine_code": "G3LC",
            },
            "1.0 T-GDi 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 T-GDi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "",
                "Engine_code": "G3LC",
            },
            "N 1.6 T-GDI 204hp": {
                "std_power": 204,
                "tuned_power": 230,
                "std_torque": 275,
                "tuned_torque": 320,
                "cc": 1591,
                "Type_ecu": "Kefico CPEGD2.20.1",
                "Engine_code": "G4FP",
            },
        },
        "2023 -> ...": {
            "1.0 T-GDi 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 T-GDi 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "i30": {
        "2007 - 2010": {
            "1.6 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 255,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C08",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 145,
                "std_torque": 235,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C08",
                "Engine_code": "D4FB",
            },
            "2.0 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 304,
                "tuned_torque": 365,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA",
            },
            "2.0 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 304,
                "tuned_torque": 365,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA",
            },
        },
        "2010 - 2015": {
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Bosch EDC17 & Bosch EDC17C57 & Delphi DCM3.7AP & Bosch ME17.9.11",
                "Engine_code": "U-II",
            },
            "1.6 CRDi 128hp": {
                "std_power": 128,
                "tuned_power": 160,
                "std_torque": 260,
                "tuned_torque": 325,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C53",
                "Engine_code": "D4FC",
            },
            "1.6 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 160,
                "std_torque": 260,
                "tuned_torque": 325,
                "cc": 1582,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C08 & Bosch EDC17C53",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 110hp": {
                "std_power": 110,
                "tuned_power": 160,
                "std_torque": 255,
                "tuned_torque": 325,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C53 & Bosch EDC17C57",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08",
                "Engine_code": "",
            },
            "1.6 GDI Turbo 186hp": {
                "std_power": 186,
                "tuned_power": 220,
                "std_torque": 265,
                "tuned_torque": 325,
                "cc": 1591,
                "Type_ecu": "Bosch MED17.9.8",
                "Engine_code": "G4FJ",
            },
        },
        "2015 - 2017": {
            "1.6 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FB",
            },
            "1.6 GDI Turbo 186hp": {
                "std_power": 186,
                "tuned_power": 220,
                "std_torque": 265,
                "tuned_torque": 325,
                "cc": 1591,
                "Type_ecu": "Bosch MED17.9.8",
                "Engine_code": "G4FJ",
            },
            "2.0 T-GDi N 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250 TCU",
                "Engine_code": "G4KH",
            },
        },
        "2017 - 2020": {
            "1.6 CRDi 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 95hp": {
                "std_power": 95,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "",
            },
            "1.6 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 171,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico GPEDC2.20.1 & Kefico CPEGD2.20.4",
                "Engine_code": "G3LC",
            },
            "1.4 T-GDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 242,
                "tuned_torque": 310,
                "cc": 1353,
                "Type_ecu": "Kefico CPEGD2.20.1 & Kefico CPEGD2.20.3",
                "Engine_code": "G4LD",
            },
            "2.0 T-GDi N Performance 275hp": {
                "std_power": 275,
                "tuned_power": 300,
                "std_torque": 378,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "G4KH",
            },
            "2.0 T-GDi N 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 378,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "G4KH",
            },
        },
        "2020 ->": {
            "1.6 CRDi iMT DCT 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 CRDi iMT 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "D4FE",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "",
                "Engine_code": "G3LC",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 T-GDI 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T-GDi N 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 392,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "G4KH",
            },
        },
    },
    "i40": {
        "2011 ->": {
            "1.7 CRDi 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C53 & Bosch EDC17C57",
                "Engine_code": "DF4D",
            },
            "1.7 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 330,
                "tuned_torque": 370,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C53 & Bosch EDC17C57",
                "Engine_code": "DF4D",
            },
            "1.7 CRDi 141hp": {
                "std_power": 141,
                "tuned_power": 170,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "DF4D",
            },
        },
    },
    "ix20": {
        "2010 - 2015": {
            "1.4 CRDi 75hp": {
                "std_power": 75,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Delphi DCM3.7 & Bosch EDC17C57",
                "Engine_code": "DF4C",
            },
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Bosch EDC17C57 & Delphi DCM3.7",
                "Engine_code": "DF4C",
            },
        },
        "2015 ->": {
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Delphi DCM3.7 & Delphi DCM6.2AP",
                "Engine_code": "DF4C",
            },
            "1.4 CRDi 90hp Euro 6": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 240,
                "tuned_torque": 285,
                "cc": 1396,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "DF4C",
            },
            "1.6 CRDi 116hp": {
                "std_power": 116,
                "tuned_power": 160,
                "std_torque": 260,
                "tuned_torque": 325,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08",
                "Engine_code": "DF4B",
            },
            "1.6 CRDi 128hp": {
                "std_power": 128,
                "tuned_power": 160,
                "std_torque": 260,
                "tuned_torque": 325,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "DF4B",
            },
            "1.4 90hp Euro 6": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 137,
                "tuned_torque": 197,
                "cc": 1396,
                "Type_ecu": "Bosch ME17.9.11",
                "Engine_code": "G4FA",
            },
        },
    },
    "ix35": {
        "2009 ->": {
            "1.7 CRDi 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C57",
                "Engine_code": "D4FD",
            },
            "2.0 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C53 & Bosch EDC17C57",
                "Engine_code": "D4HA",
            },
            "2.0 CRDi 184hp": {
                "std_power": 184,
                "tuned_power": 205,
                "std_torque": 383,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch EDC17CP14",
                "Engine_code": "D4HA",
            },
            "1.6 GDI 135hp": {
                "std_power": 135,
                "tuned_power": 145,
                "std_torque": 165,
                "tuned_torque": 180,
                "cc": 1591,
                "Type_ecu": "Bosch MED17.9.8 & Siemens/Continental SIM2K-141",
                "Engine_code": "G4FD",
            },
            "2.0 16V 165hp": {
                "std_power": 165,
                "tuned_power": 180,
                "std_torque": 205,
                "tuned_torque": 230,
                "cc": 1998,
                "Type_ecu": "Bosch MED17.9.8",
                "Engine_code": "G4HD",
            },
        },
    },
    "ix45": {
        "2013 ->": {
            "2.0 CRDi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 380,
                "tuned_torque": 435,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "D4EA",
            },
            "2.2 CRDi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 430,
                "tuned_torque": 480,
                "cc": 2199,
                "Type_ecu": "",
                "Engine_code": "D4HB",
            },
        },
    },
    "ix55": {
        "2008 ->": {
            "3.0 V6 CRDi 240hp": {
                "std_power": 240,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 530,
                "cc": 2959,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC17CP14",
                "Engine_code": "D6EA",
            },
            "3.0 V6 CRDi 250hp": {
                "std_power": 250,
                "tuned_power": 280,
                "std_torque": 470,
                "tuned_torque": 530,
                "cc": 2959,
                "Type_ecu": "Bosch EDC17CP14",
                "Engine_code": "D6EA",
            },
        },
    },
},
"Infiniti": {
    "EX": {
        "All": {
            "3.0d 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "V9X",
            },
            "3.0d 238hp": {
                "std_power": 238,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP42",
                "Engine_code": "V9X",
            },
        },
    },
    "FX": {
        "2008-2013": {
            "3.0d 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "V9X",
            },
            "3.0d 238hp": {
                "std_power": 238,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP42",
                "Engine_code": "V9X",
            },
            "3.5 V6 302hp": {
                "std_power": 302,
                "tuned_power": 317,
                "std_torque": 355,
                "tuned_torque": 382,
                "cc": 3498,
                "Type_ecu": "Hitachi MEC100 & Hitachi SH705822N",
                "Engine_code": "VQ35VH",
            },
            "3.7 V6 320hp": {
                "std_power": 320,
                "tuned_power": 350,
                "std_torque": 360,
                "tuned_torque": 385,
                "cc": 3696,
                "Type_ecu": "Hitachi NEC001-097",
                "Engine_code": "VQ37VH",
            },
            "3.7 V6 331hp": {
                "std_power": 331,
                "tuned_power": 350,
                "std_torque": 366,
                "tuned_torque": 385,
                "cc": 3696,
                "Type_ecu": "",
                "Engine_code": "VQ37VHR",
            },
            "5.0 V8 390hp": {
                "std_power": 390,
                "tuned_power": 417,
                "std_torque": 500,
                "tuned_torque": 532,
                "cc": 5026,
                "Type_ecu": "Hitachi MEC140",
                "Engine_code": "VK50VE",
            },
        },
    },
    "G35": {
        "All": {
            "3.5 V6 306hp": {
                "std_power": 306,
                "tuned_power": 317,
                "std_torque": 363,
                "tuned_torque": 382,
                "cc": 3498,
                "Type_ecu": "Hitachi MEC100",
                "Engine_code": "VQ35HR",
            },
        },
    },
    "G37/M37": {
        "All": {
            "3.0d 235hp": {
                "std_power": 235,
                "tuned_power": 250,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "V9X",
            },
            "3.7 V6 320hp": {
                "std_power": 320,
                "tuned_power": 350,
                "std_torque": 360,
                "tuned_torque": 385,
                "cc": 3696,
                "Type_ecu": "Hitachi NEC001-097 & Hitachi MEC107-400",
                "Engine_code": "VQ37VHR",
            },
            "3.7 V6 331hp": {
                "std_power": 331,
                "tuned_power": 345,
                "std_torque": 366,
                "tuned_torque": 380,
                "cc": 3696,
                "Type_ecu": "Hitachi NEC001-097",
                "Engine_code": "VQ37VHR",
            },
        },
    },
    "M": {
        "All": {
            "3.0d 238hp": {
                "std_power": 238,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Hitachi NEC001-097 & Bosch EDC16CP42",
                "Engine_code": "V9X",
            },
            "3.0d 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Hitachi NEC001-097",
                "Engine_code": "V9X",
            },
        },
    },
    "Q30": {
        "2015 ->": {
            "1.5d 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "K9K",
            },
            "2.2d 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi 3.N5 & Delphi CRD3.30",
                "Engine_code": "OM651",
            },
            "1.6 Turbo 122hp": {
                "std_power": 122,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270.910",
            },
            "1.6 Turbo 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270.910",
            },
            "2.0T 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270",
            },
        },
    },
    "Q50": {
        "2013 - 2017": {
            "2.2d 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Delphi 3.N5 & Delphi CRD3.x & Delphi CRD3.30",
                "Engine_code": "OM651",
            },
            "2.0T 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270",
            },
            "3.0T 405hp": {
                "std_power": 405,
                "tuned_power": 440,
                "std_torque": 475,
                "tuned_torque": 620,
                "cc": 2997,
                "Type_ecu": "Hitachi SH72533 & Hitachi SH7059",
                "Engine_code": "VR30DDTT",
            },
            "3.5 V6 Hybrid 365hp": {
                "std_power": 365,
                "tuned_power": 395,
                "std_torque": 545,
                "tuned_torque": 605,
                "cc": 3498,
                "Type_ecu": "Hitachi MEC119.030 & Hitachi MEC100",
                "Engine_code": "VQ35HR",
            },
        },
        "2017 ->": {
            "2.2d 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Delphi 3.N5 & Delphi CRD3.x",
                "Engine_code": "OM651",
            },
            "2.0T 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270",
            },
            "3.5 V6 Hybrid 365hp": {
                "std_power": 365,
                "tuned_power": 395,
                "std_torque": 545,
                "tuned_torque": 605,
                "cc": 3498,
                "Type_ecu": "Hitachi MEC119.030",
                "Engine_code": "VQ35HR",
            },
            "S 3.0T 405hp": {
                "std_power": 405,
                "tuned_power": 440,
                "std_torque": 475,
                "tuned_torque": 620,
                "cc": 2997,
                "Type_ecu": "Hitachi SH72533 & Hitachi NEC015",
                "Engine_code": "VR30DDTT",
            },
        },
    },
    "Q60": {
        "-> 2016": {
            "3.7 V6 320hp": {
                "std_power": 320,
                "tuned_power": 339,
                "std_torque": 360,
                "tuned_torque": 389,
                "cc": 3696,
                "Type_ecu": "Hitachi MEC107-400",
                "Engine_code": "VQ37VH",
            },
        },
        "2016 ->": {
            "2.0T 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270",
            },
            "3.0 Bi-Turbo 304hp": {
                "std_power": 304,
                "tuned_power": 380,
                "std_torque": 400,
                "tuned_torque": 475,
                "cc": 2997,
                "Type_ecu": "Hitachi SH72533",
                "Engine_code": "VR30DDTT",
            },
            "S 3.0 Bi-Turbo 405hp": {
                "std_power": 405,
                "tuned_power": 455,
                "std_torque": 475,
                "tuned_torque": 525,
                "cc": 2997,
                "Type_ecu": "Hitachi SN72533 & Hitachi SN72533 gen2",
                "Engine_code": "VR30DDTT",
            },
        },
    },
    "Q70": {
        "2014 - 2015": {
            "3.0d 238hp": {
                "std_power": 238,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP42",
                "Engine_code": "V9X",
            },
        },
        "2015 ->": {
            "2.2d 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Delphi 3.N5 & Delphi CRD3.7A",
                "Engine_code": "OM 651",
            },
            "3.5 V6 Hybrid 365hp": {
                "std_power": 365,
                "tuned_power": 395,
                "std_torque": 545,
                "tuned_torque": 605,
                "cc": 3498,
                "Type_ecu": "Hitachi MEC119.030",
                "Engine_code": "VQR35HR",
            },
            "3.7 V6 330hp": {
                "std_power": 330,
                "tuned_power": 348,
                "std_torque": 366,
                "tuned_torque": 383,
                "cc": 3696,
                "Type_ecu": "",
                "Engine_code": "VQ37VHR",
            },
        },
    },
    "QX30": {
        "2016 ->": {
            "2.2d 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi 3.N5 & Delphi CRD3.30",
                "Engine_code": "OM 651",
            },
            "2.0T 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270",
            },
        },
    },
    "QX50": {
        "2013 - 2016": {
            "3.0d 238hp": {
                "std_power": 238,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP42",
                "Engine_code": "V9X",
            },
        },
        "2017 ->": {
            "2.0 Turbo 272hp": {
                "std_power": 272,
                "tuned_power": 300,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Denso R7F701202",
                "Engine_code": "",
            },
        },
    },
    "QX60": {
        "2016 ->": {
            "3.5 V6 295hp": {
                "std_power": 295,
                "tuned_power": 317,
                "std_torque": 366,
                "tuned_torque": 382,
                "cc": 3498,
                "Type_ecu": "",
                "Engine_code": "VQ35DE",
            },
            "3.5 V6 262hp": {
                "std_power": 262,
                "tuned_power": 284,
                "std_torque": 334,
                "tuned_torque": 360,
                "cc": 3498,
                "Type_ecu": "Hitachi GEN2",
                "Engine_code": "VQ35DE",
            },
        },
    },
    "QX70": {
        "2013 ->": {
            "3.0d 238hp": {
                "std_power": 238,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2993,
                "Type_ecu": "Bosch EDC16CP42",
                "Engine_code": "V9X",
            },
            "3.7 V6 320hp": {
                "std_power": 320,
                "tuned_power": 350,
                "std_torque": 360,
                "tuned_torque": 385,
                "cc": 3696,
                "Type_ecu": "Hitachi SH7059",
                "Engine_code": "VQ37VH",
            },
            "5.0 V8 390hp": {
                "std_power": 390,
                "tuned_power": 417,
                "std_torque": 500,
                "tuned_torque": 532,
                "cc": 5026,
                "Type_ecu": "Hitachi GEN2",
                "Engine_code": "5.0 V8 390hp",
            },
        },
    },
},
"Isuzu": {
    "Ascender": {
        "2003 - 2008": {
            "4.2 275hp": {
                "std_power": 275,
                "tuned_power": 295,
                "std_torque": 373,
                "tuned_torque": 395,
                "cc": 4160,
                "Type_ecu": "",
                "Engine_code": "LL8",
            },
            "5.3 V8 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 447,
                "tuned_torque": 465,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LM4",
            },
            "5.3 V8 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 447,
                "tuned_torque": 465,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LH6",
            },
        },
    },
    "D-Max": {
        "2003 - 2012": {
            "2.5 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 2499,
                "Type_ecu": "Transtron 4Jxx & Bosch EDC15M & Delphi MPC555",
                "Engine_code": "4JK1-TCX",
            },
            "3.0 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2999,
                "Type_ecu": "Transtron 4Jxx & Bosch EDC17CV41",
                "Engine_code": "4JJ1-TCX",
            },
        },
        "2012 - 2016": {
            "2.5D twinturbo 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2499,
                "Type_ecu": "Transtron 4Jxx & Denso SH7058",
                "Engine_code": "4JK-1E5-TC",
            },
        },
        "2016 - 2019": {
            "1.9D 164hp": {
                "std_power": 164,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1898,
                "Type_ecu": "Bosch EDC17C83",
                "Engine_code": "RZ4E-TC",
            },
            "3.0D 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 2999,
                "Type_ecu": "Bosch EDC17CV41",
                "Engine_code": "4JJ3-TCX",
            },
        },
        "2020 -> ...": {
            "1.9D 164hp": {
                "std_power": 164,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1898,
                "Type_ecu": "Bosch EDC17C83 & Bosch MD1CS089 & Bosch MD1CS018",
                "Engine_code": "RZ4E-TC",
            },
            "3.0D 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "I-Series": {
        "All": {
            "I-280 2.8 175hp": {
                "std_power": 175,
                "tuned_power": 195,
                "std_torque": 250,
                "tuned_torque": 270,
                "cc": 2800,
                "Type_ecu": "",
                "Engine_code": "LK5",
            },
            "I-290 2.9 185hp": {
                "std_power": 185,
                "tuned_power": 205,
                "std_torque": 258,
                "tuned_torque": 275,
                "cc": 2921,
                "Type_ecu": "Bosch EDC17CV41",
                "Engine_code": "LLV",
            },
            "I-350 3.5 220hp": {
                "std_power": 220,
                "tuned_power": 240,
                "std_torque": 305,
                "tuned_torque": 325,
                "cc": 3460,
                "Type_ecu": "",
                "Engine_code": "L52",
            },
            "I-370 3.7 242hp": {
                "std_power": 242,
                "tuned_power": 260,
                "std_torque": 328,
                "tuned_torque": 350,
                "cc": 3653,
                "Type_ecu": "",
                "Engine_code": "L5R",
            },
        },
    },
    "MU-X": {
        "All": {
            "1.9D 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1989,
                "Type_ecu": "Bosch EDC17C83",
                "Engine_code": "",
            },
            "2.5d 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2499,
                "Type_ecu": "Transtron 4Jxx",
                "Engine_code": "4JK1-TCY",
            },
            "3.0d 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 430,
                "tuned_torque": 480,
                "cc": 2999,
                "Type_ecu": "Transtron 4Jxx & Bosch EDC17CV41",
                "Engine_code": "4JJ1 TC",
            },
        },
    },
},
"Iveco": {
    "Cursor": {
        "All": {
            "10 - 400 - 400hp": {
                "std_power": 400,
                "tuned_power": 480,
                "std_torque": 1900,
                "tuned_torque": 2300,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "10 - 430 - 430hp": {
                "std_power": 430,
                "tuned_power": 510,
                "std_torque": 1900,
                "tuned_torque": 2300,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "13 - 380 - 380hp": {
                "std_power": 380,
                "tuned_power": 460,
                "std_torque": 1800,
                "tuned_torque": 2200,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "13 - 440 - 440hp": {
                "std_power": 440,
                "tuned_power": 520,
                "std_torque": 2100,
                "tuned_torque": 2500,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "13 - 480 - 480hp": {
                "std_power": 480,
                "tuned_power": 560,
                "std_torque": 2200,
                "tuned_torque": 2600,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "13 - 540 - 540hp": {
                "std_power": 540,
                "tuned_power": 620,
                "std_torque": 2350,
                "tuned_torque": 2750,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "8 - 240 - 245hp": {
                "std_power": 245,
                "tuned_power": 315,
                "std_torque": 950,
                "tuned_torque": 1250,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "8 - 270 - 272hp": {
                "std_power": 272,
                "tuned_power": 340,
                "std_torque": 1115,
                "tuned_torque": 1400,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "8 - 310 - 310hp": {
                "std_power": 310,
                "tuned_power": 380,
                "std_torque": 1115,
                "tuned_torque": 1400,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "8 - 350 - 350hp": {
                "std_power": 350,
                "tuned_power": 420,
                "std_torque": 1280,
                "tuned_torque": 1600,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Daily": {
        "-> 2016": {
            "2.3 HPI 96hp": {
                "std_power": 96,
                "tuned_power": 125,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 2287,
                "Type_ecu": "Bosch MS6.3 & Bosch EDC16C39 & Bosch EDC16C8",
                "Engine_code": "",
            },
            "2.3 HPI 116hp": {
                "std_power": 116,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 2287,
                "Type_ecu": "Bosch EDC17C69 & Bosch EDC16C8 & Bosch MS6.3 & Bosch EDC16C39",
                "Engine_code": "",
            },
            "2.3 HPT 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2287,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C49 & Bosch EDC17CP52 & Bosch EDC16C8",
                "Engine_code": "",
            },
            "2.3 M-jet II 106hp": {
                "std_power": 106,
                "tuned_power": 180,
                "std_torque": 270,
                "tuned_torque": 420,
                "cc": 2287,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17CP52 & Bosch EDC16C39",
                "Engine_code": "F1AE3481A",
            },
            "2.3 M-jet II 126hp": {
                "std_power": 126,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 2287,
                "Type_ecu": "Bosch EDC17C49 & Bosch MS6.3 & Bosch EDC16C39 & Bosch EDC16C8",
                "Engine_code": "F1AE3481B",
            },
            "2.3 M-jet II 146hp": {
                "std_power": 146,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2287,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC16C39 & Bosch EDC17CP52 & Bosch EDC17C69",
                "Engine_code": "F1AFL411C",
            },
            "3.0 CR (EUR 5) 146hp": {
                "std_power": 146,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2998,
                "Type_ecu": "Bosch EDC17CP52 & Bosch EDC16 & Bosch EDC16C39 & Bosch EDC17C49",
                "Engine_code": "",
            },
            "3.0 CR (EUR 5) 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2998,
                "Type_ecu": "Bosch EDC17CP52 & Bosch EDC17C49 & Bosch EDC16C39 & Bosch EDC16+",
                "Engine_code": "F1CFL411F",
            },
            "3.0 CR (EUR 6) 156hp": {
                "std_power": 156,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2998,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C49",
                "Engine_code": "",
            },
            "3.0 CR TwintT euro5 205hp": {
                "std_power": 205,
                "tuned_power": 250,
                "std_torque": 470,
                "tuned_torque": 560,
                "cc": 2998,
                "Type_ecu": "Bosch EDC17CP52 & Bosch EDC17C49",
                "Engine_code": "F1CFL411G",
            },
            "3.0 HPI 146hp": {
                "std_power": 146,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 410,
                "cc": 2998,
                "Type_ecu": "Bosch EDC17CP52 & Bosch EDC16C8 & Bosch EDC16C39 & Bosch EDC17C49",
                "Engine_code": "",
            },
            "3.0 HPT 176hp": {
                "std_power": 176,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2998,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C8 & Bosch EDC17CP52",
                "Engine_code": "",
            },
        },
        "2016 ->": {
            "120 2.3 (EUR 6) 116hp": {
                "std_power": 116,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2287,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C69 & Bosch EDC17C49",
                "Engine_code": "",
            },
            "140 2.3 (EUR 6) 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2287,
                "Type_ecu": "Bosch EDC17C69 & Bosch EDC17C49",
                "Engine_code": "",
            },
            "145 2.3 (EUR 6) 145hp": {
                "std_power": 145,
                "tuned_power": 170,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2287,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17CP52",
                "Engine_code": "",
            },
            "150 3.0 (EUR 6) 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2998,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17CP52",
                "Engine_code": "",
            },
            "160 2.3 (EUR 6) 156hp": {
                "std_power": 156,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2287,
                "Type_ecu": "Bosch EDC17C69 & Bosch EDC17C49 & Bosch EDC17C53",
                "Engine_code": "",
            },
            "180 3.0 (EUR 6) 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 430,
                "tuned_torque": 480,
                "cc": 2998,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17CP52",
                "Engine_code": "F1C",
            },
            "210 3.0 (EUR 6) 205hp": {
                "std_power": 205,
                "tuned_power": 250,
                "std_torque": 470,
                "tuned_torque": 560,
                "cc": 2998,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17CP52",
                "Engine_code": "",
            },
        },
    },
},
"Jaguar": {
    "E Pace": {
        "2017 - 2020": {
            "D150 (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 490,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MEDC17.9 & Bosch EDC17C08",
                "Engine_code": "204DTD",
            },
            "D180 (2.0D) 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 430,
                "tuned_torque": 465,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17C09 & Bosch MEDC17.9",
                "Engine_code": "204DTD",
            },
            "D240 (2.0D) 240hp": {
                "std_power": 240,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1999,
                "Type_ecu": "Bosch MEVD17.9 & Bosch MEDC17.9",
                "Engine_code": "204DTA",
            },
            "P200 (2.0T) 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "PT204",
            },
            "P250 (2.0T) 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 365,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9 & Bosch MED17.1.9",
                "Engine_code": "PT204",
            },
            "P300 (3.0T) 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "PT204",
            },
        },
        "2021 -> ...": {
            "D165 (2.0D) 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D165 (2.0D) 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D200 (2.0D) 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P160 (1.5T) 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P200 (2.0T) 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "AJ20P4",
            },
            "P250 (2.0T) 249hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P300 (2.0T) 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P300e (1.5T) 309hp": {
                "std_power": 309,
                "tuned_power": 340,
                "std_torque": 540,
                "tuned_torque": 595,
                "cc": 1498,
                "Type_ecu": "",
                "Engine_code": "AJ20P3",
            },
        },
    },
    "F Pace": {
        "2015 - 2019": {
            "2.0D 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 430,
                "tuned_torque": 465,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MEDC17.9",
                "Engine_code": "204DTD",
            },
            "2.0D 240hp": {
                "std_power": 240,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MEDC17.9",
                "Engine_code": "204DTD",
            },
            "3.0D 300hp": {
                "std_power": 300,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "306DT",
            },
            "2.0 Turbo 300hp": {
                "std_power": 300,
                "tuned_power": 325,
                "std_torque": 400,
                "tuned_torque": 448,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "PT204",
            },
            "2.0 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 365,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9 & Bosch MED17.2",
                "Engine_code": "PT204",
            },
            "3.0 V6 Supercharged 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.31 & Bosch MED17.5.4",
                "Engine_code": "AJ26",
            },
            "3.0 V6 Supercharged 380hp": {
                "std_power": 380,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.31",
                "Engine_code": "AJ26",
            },
            "5.0 V8 SVR 550hp": {
                "std_power": 550,
                "tuned_power": 570,
                "std_torque": 680,
                "tuned_torque": 720,
                "cc": 4999,
                "Type_ecu": "Bosch MED17.8.32",
                "Engine_code": "508PS",
            },
        },
        "2020 -> ...": {
            "2.0d 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0d 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0d 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 P400e 404hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0T 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0T 340hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0T 400hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "5.0 V8 SVR 550hp": {
                "std_power": 550,
                "tuned_power": 570,
                "std_torque": 700,
                "tuned_torque": 720,
                "cc": 4999,
                "Type_ecu": "Bosch MED17.8.32",
                "Engine_code": "508PS",
            },
        },
    },
    "F type": {
        "2013 - 2019": {
            "2.0 Turbo 300hp": {
                "std_power": 300,
                "tuned_power": 325,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.1.9 & Bosch MED17.9.9 & ZF 8HP",
                "Engine_code": "PT204",
            },
            "3.0 V6 Supercharged 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.31 & Bosch MED17.5.4 & Bosch MED17.8.32",
                "Engine_code": "306PS",
            },
            "3.0 V6 Supercharged 380hp": {
                "std_power": 380,
                "tuned_power": 400,
                "std_torque": 460,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.31 & ZF 8HP70",
                "Engine_code": "306PS",
            },
            "5.0 V8 Supercharged 'Project 7' 575hp": {
                "std_power": 575,
                "tuned_power": 600,
                "std_torque": 680,
                "tuned_torque": 720,
                "cc": 5000,
                "Type_ecu": "Bosch MED17.8.3 & Bosch MED17.5.4",
                "Engine_code": "508PS",
            },
            "5.0 V8 Supercharged 550hp": {
                "std_power": 550,
                "tuned_power": 570,
                "std_torque": 680,
                "tuned_torque": 720,
                "cc": 4999,
                "Type_ecu": "Bosch MED17.8.31",
                "Engine_code": "508PS",
            },
            "5.0 V8 Supercharged 495hp": {
                "std_power": 495,
                "tuned_power": 560,
                "std_torque": 625,
                "tuned_torque": 700,
                "cc": 4999,
                "Type_ecu": "Bosch MED17.8.31",
                "Engine_code": "508PS",
            },
        },
        "2020 ->": {
            "2.0 Turbo 300hp": {
                "std_power": 300,
                "tuned_power": 325,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "PT204",
            },
            "5.0 V8 Supercharged 450hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "R 5.0 V8 Supercharged 575hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "S Type": {
        "All": {
            "2.2D 155hp": {
                "std_power": 155,
                "tuned_power": 185,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "DURATQ22",
            },
            "2.7D 207hp": {
                "std_power": 207,
                "tuned_power": 240,
                "std_torque": 435,
                "tuned_torque": 520,
                "cc": 2720,
                "Type_ecu": "Siemens/Continental SID20x",
                "Engine_code": "276DT / AJD-V6",
            },
            "2.5 V6 201hp": {
                "std_power": 201,
                "tuned_power": 215,
                "std_torque": 250,
                "tuned_torque": 270,
                "cc": 2497,
                "Type_ecu": "Denso MB079700",
                "Engine_code": "AJ25",
            },
            "3.0 V6 240hp": {
                "std_power": 240,
                "tuned_power": 255,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 2967,
                "Type_ecu": "Denso MB079700",
                "Engine_code": "AJ30",
            },
            "4.2 V8 R 400hp": {
                "std_power": 400,
                "tuned_power": 420,
                "std_torque": 541,
                "tuned_torque": 565,
                "cc": 4196,
                "Type_ecu": "Denso MB079700",
                "Engine_code": "AJ34",
            },
            "4.2 V8 300hp": {
                "std_power": 300,
                "tuned_power": 315,
                "std_torque": 420,
                "tuned_torque": 440,
                "cc": 4196,
                "Type_ecu": "Denso MB079700",
                "Engine_code": "AJ34",
            },
        },
    },
    "X Type": {
        "All": {
            "2.0D 130hp": {
                "std_power": 130,
                "tuned_power": 166,
                "std_torque": 330,
                "tuned_torque": 380,
                "cc": 1998,
                "Type_ecu": "Delphi MPC555",
                "Engine_code": "X404",
            },
            "2.2D 155hp": {
                "std_power": 155,
                "tuned_power": 185,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 2198,
                "Type_ecu": "Delphi MPC555",
                "Engine_code": "DURATQ22",
            },
            "2.5 V6 194hp": {
                "std_power": 194,
                "tuned_power": 210,
                "std_torque": 244,
                "tuned_torque": 264,
                "cc": 2495,
                "Type_ecu": "Denso MB079700",
                "Engine_code": "X400",
            },
            "3.0 V6 231hp": {
                "std_power": 231,
                "tuned_power": 245,
                "std_torque": 284,
                "tuned_torque": 305,
                "cc": 2968,
                "Type_ecu": "Denso MB079700",
                "Engine_code": "AJ30",
            },
        },
    },
    "XE": {
        "2015 - 2018": {
            "2.0D 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 430,
                "tuned_torque": 465,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MEDC17.9",
                "Engine_code": "AJ200D",
            },
            "2.0D 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 490,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MEDC17.9",
                "Engine_code": "AJ200D",
            },
            "2.0D 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1999,
                "Type_ecu": "Bosch MEDC17.9",
                "Engine_code": "204DTA",
            },
            "2.0 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.9.7 & Bosch MED17.9.9 & Bosch MED17.1.9",
                "Engine_code": "2.0 GTDI-14",
            },
            "2.0 Turbo 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.1.9 & Bosch MED17.9.7 & Bosch MED17.9.9",
                "Engine_code": "PT204",
            },
            "2.0 Turbo 300hp": {
                "std_power": 300,
                "tuned_power": 325,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "PT204",
            },
            "3.0 V6 Supercharged 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.31 & ZF 8HP45",
                "Engine_code": "AJ126",
            },
        },
        "2019 ->": {
            "D180 (2.0D) 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D200 (2.0D) 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P250 (2.0T) 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 365,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "PT204",
            },
            "P300 (2.0T) 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "PT204",
            },
        },
    },
    "XF": {
        "-> 2015": {
            "2.2 D 163hp": {
                "std_power": 163,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "224DT",
            },
            "2.2 D 190hp": {
                "std_power": 190,
                "tuned_power": 235,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "224DT",
            },
            "2.2 D 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "224DT",
            },
            "2.7 TDV6 207hp": {
                "std_power": 207,
                "tuned_power": 240,
                "std_torque": 435,
                "tuned_torque": 520,
                "cc": 2720,
                "Type_ecu": "Siemens/Continental SID20x",
                "Engine_code": "TDV6",
            },
            "3.0 TDV6 211hp": {
                "std_power": 211,
                "tuned_power": 290,
                "std_torque": 450,
                "tuned_torque": 570,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "TDV6",
            },
            "3.0 TDV6 240hp": {
                "std_power": 240,
                "tuned_power": 290,
                "std_torque": 500,
                "tuned_torque": 575,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "TDV6",
            },
            "3.0 TDV6 275hp": {
                "std_power": 275,
                "tuned_power": 325,
                "std_torque": 600,
                "tuned_torque": 675,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "TDV6",
            },
            "2.0 SC Turbo 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.9.7",
                "Engine_code": "204PT",
            },
            "3.0 V6 SC Turbo 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.31",
                "Engine_code": "AJ126",
            },
            "4.2 V8 Supercharged 416hp": {
                "std_power": 416,
                "tuned_power": 470,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 4196,
                "Type_ecu": "Denso MB079700 & Denso MB279700-9xxx",
                "Engine_code": "AJ133",
            },
            "5.0 V8 supercharged 510hp": {
                "std_power": 510,
                "tuned_power": 560,
                "std_torque": 625,
                "tuned_torque": 700,
                "cc": 5000,
                "Type_ecu": "Denso P5.0SC & Bosch MED17.8.31 & ZF 6HP",
                "Engine_code": "AJ133",
            },
            "5.0 V8 385hp": {
                "std_power": 385,
                "tuned_power": 405,
                "std_torque": 515,
                "tuned_torque": 540,
                "cc": 5000,
                "Type_ecu": "Denso P5.0SC",
                "Engine_code": "AJ-V8-5.0",
            },
        },
        "2015 - 2019": {
            "2.0D 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 430,
                "tuned_torque": 465,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MEDC17.9 & ZF 8HP",
                "Engine_code": "AJ200D",
            },
            "2.0D 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP42 & Bosch MEDC17.9",
                "Engine_code": "AJ200D",
            },
            "3.0D 300hp": {
                "std_power": 300,
                "tuned_power": 330,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "306DT",
            },
            "2.0 Turbo 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.9.7 & Bosch MED17.9.9",
                "Engine_code": "204PT",
            },
            "2.0 Turbo 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9 & Bosch MED17.2",
                "Engine_code": "PT204",
            },
            "2.0 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 365,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MED17.1.9 & Bosch MED17.2 & Bosch MED17.9.9",
                "Engine_code": "204PT",
            },
            "3.0 V6 Supercharged 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.31",
                "Engine_code": "AJ126",
            },
            "3.0 V6 Supercharged 380hp": {
                "std_power": 380,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.31 & ZF 8HP",
                "Engine_code": "AJ126",
            },
        },
        "2020 -> ...": {
            "D200 (2.0d) 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P250 (2.0T) 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P300 (2.0T) 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "XF Sportbrake": {
        "2012 ->": {
            "2.2D 163hp": {
                "std_power": 163,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "224DT",
            },
            "2.2D 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "224DT",
            },
            "3.0D V6 S 275hp": {
                "std_power": 275,
                "tuned_power": 325,
                "std_torque": 600,
                "tuned_torque": 675,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "AJV6D",
            },
            "3.0D V6 240hp": {
                "std_power": 240,
                "tuned_power": 290,
                "std_torque": 500,
                "tuned_torque": 575,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "AJV6D",
            },
            "3.0D 300hp": {
                "std_power": 300,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "306DT",
            },
        },
    },
    "XJ": {
        "2003 - 2009": {
            "2.7 TDV6 207hp": {
                "std_power": 207,
                "tuned_power": 240,
                "std_torque": 435,
                "tuned_torque": 520,
                "cc": 2722,
                "Type_ecu": "Siemens/Continental SID20x",
                "Engine_code": "AJD-V6",
            },
            "4.2 V8 SC 400hp": {
                "std_power": 400,
                "tuned_power": 470,
                "std_torque": 540,
                "tuned_torque": 630,
                "cc": 4196,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "AJ34",
            },
        },
        "2009 ->": {
            "3.0D 275hp": {
                "std_power": 275,
                "tuned_power": 325,
                "std_torque": 600,
                "tuned_torque": 675,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "AJV6D",
            },
            "3.0D 300hp": {
                "std_power": 300,
                "tuned_power": 330,
                "std_torque": 700,
                "tuned_torque": 780,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "AJV6D",
            },
            "2.0 SC Turbo 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2",
                "Engine_code": "2.0 GTDI-14",
            },
            "3.0 V6 SC Turbo 340hp": {
                "std_power": 340,
                "tuned_power": 390,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.31 & ZF 8HP70",
                "Engine_code": "306PS",
            },
            "5.0 V8 Supercharged 510hp": {
                "std_power": 510,
                "tuned_power": 560,
                "std_torque": 625,
                "tuned_torque": 700,
                "cc": 4999,
                "Type_ecu": "Denso P5.0SC & Bosch MED17.8.31",
                "Engine_code": "508PS",
            },
            "5.0 V8 385hp": {
                "std_power": 385,
                "tuned_power": 405,
                "std_torque": 515,
                "tuned_torque": 540,
                "cc": 5000,
                "Type_ecu": "Denso P5.0SC & Bosch MED17.8.31",
                "Engine_code": "508PN",
            },
            "R 5.0 V8 Supercharged 550hp": {
                "std_power": 550,
                "tuned_power": 570,
                "std_torque": 680,
                "tuned_torque": 720,
                "cc": 4999,
                "Type_ecu": "Bosch MED17.8.31",
                "Engine_code": "508PS",
            },
            "R575 5.0 V8 Supercharged 575hp": {
                "std_power": 575,
                "tuned_power": 600,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 5000,
                "Type_ecu": "Bosch MED17.8.31",
                "Engine_code": "508PS",
            },
        },
    },
    "XKR": {
        "All": {
            "5.0 V8 S Supercharged 550hp": {
                "std_power": 550,
                "tuned_power": 570,
                "std_torque": 680,
                "tuned_torque": 720,
                "cc": 4999,
                "Type_ecu": "Bosch MED17.8.31",
                "Engine_code": "508PS",
            },
            "5.0 V8 Supercharged 510hp": {
                "std_power": 510,
                "tuned_power": 560,
                "std_torque": 625,
                "tuned_torque": 700,
                "cc": 4999,
                "Type_ecu": "Denso P5.0SC & Bosch MED17.8.31",
                "Engine_code": "508PS",
            },
        },
    },
},
"Jeep": {
    "Cherokee": {
        "2000 - 2005": {
            "2.5 CRD 143hp": {
                "std_power": 143,
                "tuned_power": 170,
                "std_torque": 343,
                "tuned_torque": 400,
                "cc": 2500,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "R425 DOHC",
            },
            "2.5 TD 115hp": {
                "std_power": 115,
                "tuned_power": 142,
                "std_torque": 278,
                "tuned_torque": 315,
                "cc": 2499,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "",
            },
            "2.7 CRD 163hp": {
                "std_power": 163,
                "tuned_power": 202,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2685,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "",
            },
            "2.8 CRD 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 2755,
                "Type_ecu": "Bosch EDC16C2 & Bosch EDC15C2",
                "Engine_code": "ENR",
            },
            "2.8 CRD 163hp": {
                "std_power": 163,
                "tuned_power": 197,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2755,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "ENR",
            },
        },
        "2005 - 2008": {
            "2.8 CRD 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2776,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C2",
                "Engine_code": "R428 DOHC",
            },
            "2.8 CRD 158hp": {
                "std_power": 158,
                "tuned_power": 195,
                "std_torque": 400,
                "tuned_torque": 465,
                "cc": 2776,
                "Type_ecu": "Bosch EDC16",
                "Engine_code": "26C",
            },
        },
        "2008 - 2013": {
            "2.8 CRD 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 460,
                "tuned_torque": 520,
                "cc": 2776,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "R428 DOHC",
            },
            "2.8 CRD 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2776,
                "Type_ecu": "Bosch EDC16C2 & Bosch EDC16CP31",
                "Engine_code": "R428 DOHC",
            },
        },
        "2014 ->": {
            "2.0 CRD 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17C69",
                "Engine_code": "Y",
            },
            "2.0 CRD 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "Y",
            },
            "2.2 CRD 185hp": {
                "std_power": 185,
                "tuned_power": 200,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2186,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "U",
            },
            "2.2 CRD 200hp": {
                "std_power": 200,
                "tuned_power": 210,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2186,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "U",
            },
            "2.0T 272hp": {
                "std_power": 272,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1994,
                "Type_ecu": "Siemens/Continental GPEC4",
                "Engine_code": "EC1",
            },
            "3.2 V6 270hp": {
                "std_power": 270,
                "tuned_power": 290,
                "std_torque": 324,
                "tuned_torque": 345,
                "cc": 3239,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "3.2 Pentastar V6",
            },
        },
    },
    "Commander": {
        "2006 - 2010": {
            "2.2 CRD 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2143,
                "Type_ecu": "Delphi DCM3.5 - CRD2",
                "Engine_code": "OM651",
            },
            "3.0 CRD 218hp": {
                "std_power": 218,
                "tuned_power": 265,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16CP31",
                "Engine_code": "OM642.980",
            },
            "3.0 CRD 211hp": {
                "std_power": 211,
                "tuned_power": 265,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642.980",
            },
            "3.7 V8 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 319,
                "tuned_torque": 340,
                "cc": 3701,
                "Type_ecu": "Chrysler NGC4",
                "Engine_code": "3.7 Powertech V6",
            },
            "4.7 V8 235hp": {
                "std_power": 235,
                "tuned_power": 255,
                "std_torque": 393,
                "tuned_torque": 415,
                "cc": 4698,
                "Type_ecu": "Motorola NGC4",
                "Engine_code": "4.7 Magnum V8",
            },
            "4.7 V8 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 453,
                "tuned_torque": 475,
                "cc": 4698,
                "Type_ecu": "",
                "Engine_code": "4.7 Magnum V8",
            },
            "5.7 Hemi V8 330hp": {
                "std_power": 330,
                "tuned_power": 350,
                "std_torque": 500,
                "tuned_torque": 520,
                "cc": 5654,
                "Type_ecu": "",
                "Engine_code": "5.7 Hemi V8",
            },
            "5.7 Hemi V8 357hp": {
                "std_power": 357,
                "tuned_power": 375,
                "std_torque": 527,
                "tuned_torque": 545,
                "cc": 5654,
                "Type_ecu": "",
                "Engine_code": "5.7 Hemi V8",
            },
        },
        "2011 - 2020": {
            "2.2 CRD 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2143,
                "Type_ecu": "Delphi DCM3.5 - CRD2",
                "Engine_code": "OM651",
            },
        },
    },
    "Compass": {
        "2006 - 2011": {
            "2.0 CRD 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BYL",
            },
            "2.0i 158hp": {
                "std_power": 158,
                "tuned_power": 180,
                "std_torque": 191,
                "tuned_torque": 210,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM90E",
                "Engine_code": "2.0 World Gasoline Engine",
            },
            "2.4i 172hp": {
                "std_power": 172,
                "tuned_power": 190,
                "std_torque": 220,
                "tuned_torque": 240,
                "cc": 2360,
                "Type_ecu": "Siemens/Continental GPEC2 & Siemens/Continental SIM90E",
                "Engine_code": "2.4 World Gasoline Engine",
            },
        },
        "2011 - 2016": {
            "2.1 CRD 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 2143,
                "Type_ecu": "Delphi DCM3.5 - CRD2",
                "Engine_code": "ENE",
            },
            "2.2 CRD 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi DCM3.5 - CRD2",
                "Engine_code": "OM651.925",
            },
            "2.2 CRD 136hp": {
                "std_power": 136,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi DCM3.5 - CRD2",
                "Engine_code": "OM651.925",
            },
        },
        "2017 - 2020": {
            "1.6 Multijet 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55260384",
            },
            "2.0 Multijet 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55263087",
            },
            "2.0 Multijet 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C69 & ZF 9HP",
                "Engine_code": "55263088",
            },
            "1.4 Multiair 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMK & Marelli MM8GML",
                "Engine_code": "55263624",
            },
            "1.4 Multiair 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMK & Marelli MM8GML",
                "Engine_code": "55263624",
            },
        },
        "2020 ->": {
            "1.6 Multijet 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3T 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 270,
                "tuned_torque": 310,
                "cc": 1332,
                "Type_ecu": "Siemens/Continental GPEC4",
                "Engine_code": "55282328",
            },
            "1.3T 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 270,
                "tuned_torque": 310,
                "cc": 1332,
                "Type_ecu": "Siemens/Continental GPEC4",
                "Engine_code": "55282328",
            },
            "1.3T 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3T 240hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Gladiator": {
        "2020 -> ...": {
            "3.0 Multijet V6 264hp": {
                "std_power": 264,
                "tuned_power": 294,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17C69 & ZF 8HP75",
                "Engine_code": "EXJ",
            },
        },
    },
    "Grand Cherokee": {
        "2001 - 2005": {
            "2.7 CRD 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2685,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC16",
                "Engine_code": "OM655.921",
            },
        },
        "2005 - 2011": {
            "3.0 CRD 218hp": {
                "std_power": 218,
                "tuned_power": 265,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC16U31",
                "Engine_code": "OM642.980",
            },
            "3.0 CRD 211hp": {
                "std_power": 211,
                "tuned_power": 265,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC16CP31",
                "Engine_code": "OM642.980",
            },
            "3.7 V6 215hp": {
                "std_power": 215,
                "tuned_power": 235,
                "std_torque": 319,
                "tuned_torque": 340,
                "cc": 3687,
                "Type_ecu": "",
                "Engine_code": "EKG",
            },
            "4.7 V8 230hp": {
                "std_power": 230,
                "tuned_power": 250,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 4701,
                "Type_ecu": "Motorola NGC3",
                "Engine_code": "4.7 Powertech V8",
            },
            "4.7 V8 305hp": {
                "std_power": 305,
                "tuned_power": 325,
                "std_torque": 453,
                "tuned_torque": 475,
                "cc": 4701,
                "Type_ecu": "Motorola NCG4",
                "Engine_code": "4.7 Powertech V8",
            },
            "5.7 Hemi V8 325hp": {
                "std_power": 325,
                "tuned_power": 345,
                "std_torque": 500,
                "tuned_torque": 520,
                "cc": 5654,
                "Type_ecu": "Motorola NGC4 & Motorola NGC5 & Motorola NGC3",
                "Engine_code": "5.7 Hemi V8",
            },
            "5.7 Hemi V8 357hp": {
                "std_power": 357,
                "tuned_power": 375,
                "std_torque": 527,
                "tuned_torque": 545,
                "cc": 5654,
                "Type_ecu": "Chrysler NGC5 & Siemens/Continental EGS53",
                "Engine_code": "5.7 Hemi V8",
            },
            "6.1 Hemi V8 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 569,
                "tuned_torque": 590,
                "cc": 6059,
                "Type_ecu": "Chrysler NGC4",
                "Engine_code": "6.1 Hemi V8",
            },
        },
        "2011 - 2020": {
            "2.0 M-Jet 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "Y",
            },
            "2.0 M-Jet 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C79",
                "Engine_code": "Y",
            },
            "3.0 CRD 241hp": {
                "std_power": 241,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 630,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17C79",
                "Engine_code": "VM A 630 DOHC",
            },
            "3.0 CRD 190hp": {
                "std_power": 190,
                "tuned_power": 280,
                "std_torque": 440,
                "tuned_torque": 610,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17C79 & Bosch EDC17C49",
                "Engine_code": "OM642.980",
            },
            "3.0 CRD 250hp": {
                "std_power": 250,
                "tuned_power": 285,
                "std_torque": 570,
                "tuned_torque": 630,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17C79 & Bosch EDC17C69 & ZF 8HP & Bosch EDC17C49",
                "Engine_code": "VM A 630 DOHC",
            },
            "3.6 V6 VVT 286hp": {
                "std_power": 286,
                "tuned_power": 300,
                "std_torque": 347,
                "tuned_torque": 370,
                "cc": 3606,
                "Type_ecu": "Motorola NCG5 & Siemens/Continental GPEC2 & Motorola NGC4",
                "Engine_code": "G",
            },
            "5.7 Hemi V8 360hp": {
                "std_power": 360,
                "tuned_power": 380,
                "std_torque": 529,
                "tuned_torque": 550,
                "cc": 5654,
                "Type_ecu": "Siemens/Continental GPEC2A",
                "Engine_code": "5.7 Hemi V8",
            },
            "SRT8 6.4 Hemi V8 475hp": {
                "std_power": 475,
                "tuned_power": 495,
                "std_torque": 637,
                "tuned_torque": 655,
                "cc": 6417,
                "Type_ecu": "Siemens/Continental GPEC2 & Siemens/Continental GPEC2A",
                "Engine_code": "6.4 Hemi V8",
            },
        },
        "2021 -> ...": {
            "2.0T 4xe 380hp": {
                "std_power": 380,
                "tuned_power": 408,
                "std_torque": 637,
                "tuned_torque": 687,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.6 V6 293hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "5.7 V8 364hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Liberty": {
        "2005 - 2012": {
            "3.7 V6 - 210hp": {
                "std_power": 210,
                "tuned_power": 230,
                "std_torque": 318,
                "tuned_torque": 340,
                "cc": 3701,
                "Type_ecu": "Motorola NGC4 & Motorola NGC5",
                "Engine_code": "3.7 Powertech V6",
            },
        },
    },
    "Patriot": {
        "2007 - 2011": {
            "2.0 CRD 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BWD",
            },
            "2.2 CRD 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi DCM3.5 - CRD2",
                "Engine_code": "OM651.925",
            },
            "2.0i 158hp": {
                "std_power": 158,
                "tuned_power": 180,
                "std_torque": 191,
                "tuned_torque": 210,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "2.0 World Gasoline Engine",
            },
            "2.4i 173hp": {
                "std_power": 173,
                "tuned_power": 195,
                "std_torque": 223,
                "tuned_torque": 245,
                "cc": 2360,
                "Type_ecu": "Siemens/Continental SIM90T & Siemens/Continental SIM90E",
                "Engine_code": "2.4 World Gas Engine",
            },
        },
    },
    "Renegade": {
        "2014 - 2018": {
            "1.6 JTDm 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "",
            },
            "1.6 JTDm 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55263113",
            },
            "2.0 JTDm 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C69 & ZF 9HP",
                "Engine_code": "55263088",
            },
            "2.0 JTDm 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55263088",
            },
            "1.4 Multiair 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMK & Bosch ME17.3.0",
                "Engine_code": "55263624 EAM",
            },
            "1.4 Multiair 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMK",
                "Engine_code": "55263623 EAM",
            },
        },
        "2019 ->": {
            "1.6 Multijet 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55263113",
            },
            "2.0 Multijet 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55283099",
            },
            "2.0 Multijet 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "55284064",
            },
            "1.0 GSE 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 999,
                "Type_ecu": "Siemens/Continental GPEC4",
                "Engine_code": "55282151",
            },
            "1.3 GSE 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 270,
                "tuned_torque": 310,
                "cc": 1332,
                "Type_ecu": "Siemens/Continental GPEC4",
                "Engine_code": "55282328",
            },
            "1.3 Multiair turbo 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Wrangler": {
        "2007 - 2010": {
            "2.8 CRD 163 - 177hp": {
                "std_power": 177,
                "tuned_power": 207,
                "std_torque": 410,
                "tuned_torque": 520,
                "cc": 2777,
                "Type_ecu": "Bosch EDC16CP31 & Delphi CRD2",
                "Engine_code": "50C",
            },
            "3.6 V6 285hp": {
                "std_power": 285,
                "tuned_power": 305,
                "std_torque": 353,
                "tuned_torque": 375,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "3.6 Pentastar V6",
            },
            "3.8 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 321,
                "tuned_torque": 340,
                "cc": 3778,
                "Type_ecu": "Chrysler NGC4",
                "Engine_code": "EGH",
            },
        },
        "2010 - 2016": {
            "2.8 CRD 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 410,
                "tuned_torque": 520,
                "cc": 2776,
                "Type_ecu": "Bosch EDC17CP27 & Siemens/Continental EGS53 & Bosch EDC17C69",
                "Engine_code": "VM11D / ENS",
            },
            "3.6 V6 285hp": {
                "std_power": 285,
                "tuned_power": 305,
                "std_torque": 353,
                "tuned_torque": 375,
                "cc": 3604,
                "Type_ecu": "Motorola NGC5 & Siemens/Continental GPEC2A",
                "Engine_code": "3.6 Pentastar V6",
            },
            "3.8 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 321,
                "tuned_torque": 340,
                "cc": 3778,
                "Type_ecu": "Motorola NGC5 & Siemens/Continental GPEC4 & Chrysler NGC4",
                "Engine_code": "EGH",
            },
        },
        "2017 ->": {
            "2.2 CRDi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 480,
                "cc": 2184,
                "Type_ecu": "Bosch EDC17C69",
                "Engine_code": "EBH",
            },
            "3.0 Ecodiesel 260hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0T 4xe 380hp": {
                "std_power": 380,
                "tuned_power": 410,
                "std_torque": 637,
                "tuned_torque": 690,
                "cc": 1995,
                "Type_ecu": "Siemens/Continental GPEC4",
                "Engine_code": "",
            },
            "2.0T 272hp": {
                "std_power": 272,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Siemens/Continental GPEC4 & ZF 8HP75",
                "Engine_code": "",
            },
            "3.6 V6 285hp": {
                "std_power": 285,
                "tuned_power": 305,
                "std_torque": 353,
                "tuned_torque": 375,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental GPEC2",
                "Engine_code": "3.6 Pentastar V6",
            },
        },
    },
},
"Kia": {
    "Carens": {
        "2002 - 2011": {
            "2.0 CRDi 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 245,
                "tuned_torque": 300,
                "cc": 1991,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "D4EA",
            },
            "2.0 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 305,
                "tuned_torque": 370,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA",
            },
            "2.0 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 305,
                "tuned_torque": 370,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA",
            },
        },
        "2013 - 2016": {
            "1.7 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 325,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C53",
                "Engine_code": "D4FD",
            },
            "1.7 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 330,
                "tuned_torque": 370,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C53 & Bosch EDC17C57",
                "Engine_code": "D4FD",
            },
            "1.7 CRDi 141hp": {
                "std_power": 141,
                "tuned_power": 170,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FD",
            },
        },
        "2016 ->": {
            "1.7 CRDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C57",
                "Engine_code": "D4FD",
            },
            "1.7 CRDI 141hp": {
                "std_power": 141,
                "tuned_power": 170,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FD",
            },
        },
    },
    "Carnival": {
        "All": {
            "2.2 CRDI 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 431,
                "tuned_torque": 480,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C57 & Bosch EDC17CP14 & Bosch MD1CS012",
                "Engine_code": "D4HB",
            },
            "2.9 CRDi 185hp": {
                "std_power": 185,
                "tuned_power": 210,
                "std_torque": 343,
                "tuned_torque": 440,
                "cc": 2902,
                "Type_ecu": "Delphi DCM3.2",
                "Engine_code": "J3",
            },
            "2.9 CRDi 144hp": {
                "std_power": 144,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 2902,
                "Type_ecu": "Delco DDCR",
                "Engine_code": "J3",
            },
        },
    },
    "Ceed": {
        "2007 - 2010": {
            "1.6 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 255,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C08",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4FB",
            },
            "2.0 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 305,
                "tuned_torque": 370,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA",
            },
            "2.0 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 305,
                "tuned_torque": 370,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA",
            },
        },
        "2010 - 2015": {
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1386,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D4FC",
            },
            "1.4 CRDi 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1386,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D4FC",
            },
            "1.6 CRDi 128hp": {
                "std_power": 128,
                "tuned_power": 155,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C53",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1582,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C08",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 255,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08",
                "Engine_code": "D4FB",
            },
            "1.6 GDi 135hp": {
                "std_power": 135,
                "tuned_power": 145,
                "std_torque": 164,
                "tuned_torque": 175,
                "cc": 1591,
                "Type_ecu": "Kefico CPGDSH2.26.1 & Bosch MED17.9.8",
                "Engine_code": "G4FD",
            },
            "1.6 T-GDi 204hp": {
                "std_power": 204,
                "tuned_power": 230,
                "std_torque": 265,
                "tuned_torque": 320,
                "cc": 1591,
                "Type_ecu": "Bosch MED17.9.8",
                "Engine_code": "G4FJ",
            },
        },
        "2015 - 2017": {
            "1.4 CRDI 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Delphi DCM3.7 & Bosch EDC17C57",
                "Engine_code": "D4FC",
            },
            "1.6 CRDI 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C57",
                "Engine_code": "D4FB",
            },
            "1.6 CRDI 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 1582,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C57 & Siemens/Continental SIM2K-305",
                "Engine_code": "D4FB",
            },
            "1.0 T-GDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 988,
                "Type_ecu": "Kefico CPEGD2.20.1",
                "Engine_code": "G3LC",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 988,
                "Type_ecu": "Kefico CPEGD2.20.1",
                "Engine_code": "G3LC",
            },
            "1.6 T-GDI GT 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 265,
                "tuned_torque": 320,
                "cc": 1591,
                "Type_ecu": "Bosch MED17.9.8",
                "Engine_code": "G4FJ",
            },
        },
        "2018 ->": {
            "1.5 T-GDI 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 320,
                "cc": 1589,
                "Type_ecu": "",
                "Engine_code": "D4FE",
            },
            "1.6 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FE",
            },
            "1.0 T-GDi 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 170,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.2 & Kefico CPEGD2.20.4",
                "Engine_code": "G3LC",
            },
            "1.0 T-GDi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 170,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.4",
                "Engine_code": "G3LC",
            },
            "1.4 T-GDi 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 242,
                "tuned_torque": 310,
                "cc": 1353,
                "Type_ecu": "Kefico CPEGD2.20.3 & Kefico CPEGD2.20.1",
                "Engine_code": "G4LD",
            },
            "1.6 T-GDI GT 204hp": {
                "std_power": 204,
                "tuned_power": 235,
                "std_torque": 265,
                "tuned_torque": 340,
                "cc": 1591,
                "Type_ecu": "Kefico CPEGD2.20.3 & Bosch MED17.9.8 & Siemens/Continental SIM2K-305",
                "Engine_code": "G4FJ",
            },
        },
    },
    "Cerato": {
    },
    "Magentis": {
        "All": {
            "2.0 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 305,
                "tuned_torque": 370,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA",
            },
        },
    },
    "Mohave": {
        "All": {
            "3.0 CRDI 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2959,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17CP62",
                "Engine_code": "",
            },
        },
    },
    "Optima": {
        "2012 - 2015": {
            "1.7 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 325,
                "tuned_torque": 380,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C53",
                "Engine_code": "G4FD",
            },
            "2.0 T-GDI 278hp": {
                "std_power": 278,
                "tuned_power": 300,
                "std_torque": 365,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-240",
                "Engine_code": "",
            },
        },
        "2016 - 2019": {
            "1.6 CRDI 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "D4FE",
            },
            "1.7 CRDI 141hp": {
                "std_power": 141,
                "tuned_power": 170,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C57",
                "Engine_code": "D4FD",
            },
            "1.6 T-GDI 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 265,
                "tuned_torque": 325,
                "cc": 1591,
                "Type_ecu": "Kefico CPEGD2.20.3 & Kefico CPEGD2.20.1",
                "Engine_code": "G4FJ",
            },
            "2.0 T-GDI 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K",
                "Engine_code": "G4KH",
            },
        },
        "2020 ->": {
            "1.6 T-GDI 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T-GDI 238hp": {
                "std_power": 238,
                "tuned_power": 270,
                "std_torque": 353,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "G4KH",
            },
        },
    },
    "Picanto": {
        "-> 2017": {
            "1.1 CRDi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 153,
                "tuned_torque": 190,
                "cc": 1120,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D3FA",
            },
        },
        "2017 - 2019": {
            "1.0 T-GDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.4 & Kefico CPEGD2.20.1",
                "Engine_code": "G3LC",
            },
        },
        "2020 -> ...": {
            "1.0 T-GDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "",
                "Engine_code": "G3LE",
            },
        },
    },
    "ProCeed": {
        "2013 - 2015": {
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1386,
                "Type_ecu": "",
                "Engine_code": "D4FC",
            },
            "1.6 CRDi 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 128hp": {
                "std_power": 128,
                "tuned_power": 155,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "",
                "Engine_code": "D4FB",
            },
            "1.6 GDI 135hp": {
                "std_power": 135,
                "tuned_power": 145,
                "std_torque": 164,
                "tuned_torque": 175,
                "cc": 1591,
                "Type_ecu": "",
                "Engine_code": "G4FD",
            },
            "GT 1.6 T-GDi 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 265,
                "tuned_torque": 320,
                "cc": 1591,
                "Type_ecu": "",
                "Engine_code": "G4FJ",
            },
        },
        "2018 - 2021": {
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 988,
                "Type_ecu": "",
                "Engine_code": "G3LC",
            },
            "1.4 T-GDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 242,
                "tuned_torque": 310,
                "cc": 1353,
                "Type_ecu": "Kefico CPEGD2.20.3",
                "Engine_code": "G4LD",
            },
            "1.6 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 1582,
                "Type_ecu": "",
                "Engine_code": "D4FB",
            },
            "GT 1.6 T-GDi 204hp": {
                "std_power": 204,
                "tuned_power": 235,
                "std_torque": 265,
                "tuned_torque": 340,
                "cc": 1591,
                "Type_ecu": "",
                "Engine_code": "G4FJ",
            },
        },
        "2021 -> ...": {
            "1.5 T-GDI 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "GT 1.6 T-GDi 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 265,
                "tuned_torque": 320,
                "cc": 1591,
                "Type_ecu": "Siemens/Continental SIM2K-305 & Kefico CPEGD2.20.1",
                "Engine_code": "G4FJ",
            },
        },
    },
    "Rio": {
        "2005 - 2008": {
            "1.5 CRDi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 235,
                "tuned_torque": 285,
                "cc": 1493,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4FA",
            },
        },
        "2011 - 2014": {
            "1.1 CRDi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 210,
                "cc": 1120,
                "Type_ecu": "Bosch EDC16C39 & Delphi DCM3.7",
                "Engine_code": "D3FA",
            },
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Bosch EDC17C57 & Delphi DCM3.7",
                "Engine_code": "D4FC",
            },
        },
        "2015 - 2016": {
            "1.1 CRDi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 210,
                "cc": 1386,
                "Type_ecu": "Delphi DCM3.7 & Delphi DCM6.2 & Delphi DCM6.2AP",
                "Engine_code": "D4FC",
            },
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D4FC",
            },
        },
        "2017 - 2019": {
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FC",
            },
            "1.0 T-GDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico GPEDC2.20.1 & Kefico CPEGD2.20.2 & Kefico CPEGD2.20.3",
                "Engine_code": "G3LC",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2",
                "Engine_code": "G3LC",
            },
        },
        "2020 ->": {
            "1.0 T-GDI 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Sorento": {
        "2002 - 2007": {
            "2.5 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2497,
                "Type_ecu": "Bosch EDC15C7 & Bosch EDC16C39 & Bosch EDC15C2",
                "Engine_code": "D4CB",
            },
            "2.5 CRDi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 460,
                "cc": 2497,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4CB-VGT",
            },
        },
        "2007 - 2009": {
            "2.5 CRDi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 460,
                "cc": 2497,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4CB-VGT",
            },
            "2.5 CRDi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 460,
                "cc": 2497,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4CB",
            },
        },
        "2010 - 2015": {
            "2.0 CRDI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C53",
                "Engine_code": "D4HA",
            },
            "2.2 CRDi 197hp": {
                "std_power": 197,
                "tuned_power": 225,
                "std_torque": 430,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17C57 & Bosch EDC17CP14",
                "Engine_code": "D4HB",
            },
            "2.2 CRDi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 430,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17C57 & Bosch EDC17CP14",
                "Engine_code": "D4HB",
            },
            "2.5 CRDi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 460,
                "cc": 2497,
                "Type_ecu": "Bosch EDC16",
                "Engine_code": "D4CB-VGT",
            },
        },
        "2015 - 2018": {
            "2.2 CRDi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 430,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17C57 & Bosch EDC17CP14",
                "Engine_code": "D4HB",
            },
        },
        "2018 - 2020": {
            "2.2 CRDi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 430,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17CP57",
                "Engine_code": "D4HB",
            },
            "2.0 T-GDI 240hp": {
                "std_power": 240,
                "tuned_power": 300,
                "std_torque": 353,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "G4KH",
            },
        },
        "2020 ->": {
            "2.2 CRDI 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI Hybrid 230hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI 265hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Soul": {
        "2008 - 2013": {
            "1.6 CRDi 128hp": {
                "std_power": 128,
                "tuned_power": 160,
                "std_torque": 260,
                "tuned_torque": 325,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08",
                "Engine_code": "D4FC",
            },
            "1.6 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 255,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08",
                "Engine_code": "D4FC",
            },
            "1.6 T-GDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 265,
                "tuned_torque": 325,
                "cc": 1591,
                "Type_ecu": "",
                "Engine_code": "G4FJ",
            },
        },
        "2014 - 2015": {
            "1.6 CRDi 128hp": {
                "std_power": 128,
                "tuned_power": 160,
                "std_torque": 260,
                "tuned_torque": 325,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08",
                "Engine_code": "D4FC",
            },
        },
        "2016 ->": {
            "1.6 CRDI 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "DF4B",
            },
            "1.6 T-GDI 204hp": {
                "std_power": 204,
                "tuned_power": 235,
                "std_torque": 265,
                "tuned_torque": 340,
                "cc": 1591,
                "Type_ecu": "Kefico CPEGD2.20.1 & Kefico CPEGD2.20.3",
                "Engine_code": "G4FJ",
            },
        },
    },
    "Sportage": {
        "2004 - 2007": {
            "2.0 CRDi 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 245,
                "tuned_torque": 295,
                "cc": 1991,
                "Type_ecu": "Bosch EDC15C7 & Bosch EDC15C2",
                "Engine_code": "D4EA",
            },
            "2.0 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 305,
                "tuned_torque": 370,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39 & Siemens/Continental SIRIUS 32",
                "Engine_code": "D4EA VGT",
            },
        },
        "2007 - 2010": {
            "2.0 CRDi 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 305,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D4EA VGT",
            },
            "2.0 CRDi 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 305,
                "tuned_torque": 370,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C34 & Siemens/Continental SIMK-43",
                "Engine_code": "D4EA VGT",
            },
        },
        "2010 - 2016": {
            "1.7 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C57",
                "Engine_code": "D4FD",
            },
            "2.0 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C57",
                "Engine_code": "D4HA",
            },
            "2.0 CRDi 184hp": {
                "std_power": 184,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17CP14",
                "Engine_code": "D4HA",
            },
            "1.6 GDi 135hp": {
                "std_power": 135,
                "tuned_power": 145,
                "std_torque": 164,
                "tuned_torque": 180,
                "cc": 1590,
                "Type_ecu": "Bosch ME17.9",
                "Engine_code": "G4FD",
            },
        },
        "2016 - 2018": {
            "1.7 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1685,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FD",
            },
            "2.0 CRDi 184hp": {
                "std_power": 184,
                "tuned_power": 200,
                "std_torque": 392,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C55 & Bosch EDC17C57 & Bosch EDC17CP14",
                "Engine_code": "D4HA",
            },
            "2.0 CRDi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 373,
                "tuned_torque": 430,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C57",
                "Engine_code": "D4HA",
            },
            "1.6 T-GDI 177hp": {
                "std_power": 177,
                "tuned_power": 220,
                "std_torque": 265,
                "tuned_torque": 350,
                "cc": 1591,
                "Type_ecu": "Bosch MED17.9.8 & Kefico CPEGD2.20.3",
                "Engine_code": "G4FJ",
            },
        },
        "2018 - 2020": {
            "1.6 CRDI 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.9.8 & Bosch DCU17PC43",
                "Engine_code": "D4FE",
            },
            "1.6 CRDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FE",
            },
            "1.6 CRDI 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 CRDI 185hp": {
                "std_power": 185,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4HA",
            },
            "2.2 CRDI 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 441,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "Bosch MD1CS012",
                "Engine_code": "D4HB",
            },
            "1.6 T-GDI 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 265,
                "tuned_torque": 340,
                "cc": 1591,
                "Type_ecu": "Kefico CPEGD2.20.4 & Kefico CPEGD2.20.3",
                "Engine_code": "G4FJ",
            },
        },
        "2021 ->": {
            "1.6 CRDI 116hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 CRDI 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 CRDI 186hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI Hybrid 230hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI 265hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Stinger": {
        "2017 - 2019": {
            "2.2 CRDi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4HC",
            },
            "2.0 Turbo 255hp": {
                "std_power": 255,
                "tuned_power": 286,
                "std_torque": 353,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "G4KL",
            },
            "3.3 V6 Bi-Turbo 370hp": {
                "std_power": 370,
                "tuned_power": 400,
                "std_torque": 510,
                "tuned_torque": 610,
                "cc": 3342,
                "Type_ecu": "Siemens/Continental SIM2K-260 & Kefico CPTSH2.08.1",
                "Engine_code": "G6DP",
            },
        },
        "2020 ->": {
            "2.2 CRDi 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2199,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 Turbo 245hp": {
                "std_power": 245,
                "tuned_power": 286,
                "std_torque": 353,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM2K-250",
                "Engine_code": "",
            },
            "3.3 V6 Bi-Turbo 366hp": {
                "std_power": 366,
                "tuned_power": 400,
                "std_torque": 510,
                "tuned_torque": 610,
                "cc": 3342,
                "Type_ecu": "Siemens/Continental SIM2K-260",
                "Engine_code": "G6DP",
            },
        },
    },
    "Stonic": {
        "2017 - 2019": {
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 171,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.4",
                "Engine_code": "G3LC",
            },
            "1.0 T-GDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 171,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.4",
                "Engine_code": "G3LC",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 171,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "",
                "Engine_code": "G3LC",
            },
            "1.2 MPI 84hp": {
                "std_power": 84,
                "tuned_power": 100,
                "std_torque": 122,
                "tuned_torque": 140,
                "cc": 1248,
                "Type_ecu": "",
                "Engine_code": "G4LA",
            },
            "1.6 CRDi 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C57",
                "Engine_code": "D4FB",
            },
        },
        "2020 ->": {
            "1.0 T-GDI 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Venga": {
        "2009 ->": {
            "1.4 CRDi 75hp": {
                "std_power": 75,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D4FC",
            },
            "1.4 CRDi 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Delphi DCM3.7 & Bosch ME17.9.11",
                "Engine_code": "D4FC",
            },
            "1.4 CRDi 77hp": {
                "std_power": 77,
                "tuned_power": 125,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1396,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D4FC",
            },
            "1.6 CRDi 128hp": {
                "std_power": 128,
                "tuned_power": 160,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08",
                "Engine_code": "D4FB",
            },
            "1.6 CRDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 255,
                "tuned_torque": 320,
                "cc": 1582,
                "Type_ecu": "Bosch EDC17C08 & Bosch EDC17C57",
                "Engine_code": "D4FB",
            },
        },
    },
    "Xceed": {
        "2019 - 2022": {
            "1.6 CRDi 115hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 CRDi 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Kefico CPEGD2.20.1 & Kefico CPEGD2.20.4",
                "Engine_code": "G3LC",
            },
            "1.4 T-GDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 242,
                "tuned_torque": 310,
                "cc": 1353,
                "Type_ecu": "Kefico CPEGD2.20.3",
                "Engine_code": "",
            },
            "1.5 T-GDI 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI 204hp": {
                "std_power": 204,
                "tuned_power": 235,
                "std_torque": 265,
                "tuned_torque": 340,
                "cc": 1591,
                "Type_ecu": "Kefico CPEGD2.20.3",
                "Engine_code": "",
            },
        },
        "2022 ->": {
            "1.0 T-GDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 172,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "",
                "Engine_code": "G3LE",
            },
            "1.5 T-GDI 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 CRDi 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 T-GDI 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
},
"Lamborghini": {
    "Aventador": {
        "All": {
            "6.5 V12 700hp": {
                "std_power": 700,
                "tuned_power": 730,
                "std_torque": 690,
                "tuned_torque": 720,
                "cc": 6498,
                "Type_ecu": "Campi LIE",
                "Engine_code": "L539",
            },
            "S 6.5 V12 740hp": {
                "std_power": 740,
                "tuned_power": 750,
                "std_torque": 690,
                "tuned_torque": 704,
                "cc": 6498,
                "Type_ecu": "Campi LIE",
                "Engine_code": "L539",
            },
            "S 6.5 V12 750hp": {
                "std_power": 750,
                "tuned_power": 760,
                "std_torque": 690,
                "tuned_torque": 704,
                "cc": 6498,
                "Type_ecu": "Campi LIE",
                "Engine_code": "L539",
            },
            "SVJ 6.5 V12 770hp": {
                "std_power": 770,
                "tuned_power": 780,
                "std_torque": 720,
                "tuned_torque": 735,
                "cc": 6498,
                "Type_ecu": "Campi LIE",
                "Engine_code": "L539",
            },
        },
    },
    "Gallardo": {
        "All": {
            "5.0 V10 LP530-2 530hp": {
                "std_power": 530,
                "tuned_power": 555,
                "std_torque": 510,
                "tuned_torque": 535,
                "cc": 4961,
                "Type_ecu": "Bosch MED9.1.1 & Campi 05S",
                "Engine_code": "07L",
            },
            "5.0 V10 500hp": {
                "std_power": 500,
                "tuned_power": 520,
                "std_torque": 510,
                "tuned_torque": 530,
                "cc": 4961,
                "Type_ecu": "Bosch MED9.1.1 & Campi LIE & Campi GFA",
                "Engine_code": "07L",
            },
            "5.2 V10 LP550-2 550hp": {
                "std_power": 550,
                "tuned_power": 575,
                "std_torque": 540,
                "tuned_torque": 565,
                "cc": 5204,
                "Type_ecu": "Bosch MED9.1.1 & Bosch MED9.1.2",
                "Engine_code": "CEH",
            },
            "5.2 V10 LP560-4 560hp": {
                "std_power": 560,
                "tuned_power": 585,
                "std_torque": 540,
                "tuned_torque": 565,
                "cc": 5204,
                "Type_ecu": "Bosch MED9.1.1",
                "Engine_code": "CEH",
            },
            "5.2 V10 LP570-4 Superleggera 570hp": {
                "std_power": 570,
                "tuned_power": 595,
                "std_torque": 540,
                "tuned_torque": 565,
                "cc": 5204,
                "Type_ecu": "Bosch MED9.1.1",
                "Engine_code": "CEH",
            },
        },
    },
    "Huracan": {
        "All": {
            "5.2 V10 580-2 580hp": {
                "std_power": 580,
                "tuned_power": 630,
                "std_torque": 540,
                "tuned_torque": 580,
                "cc": 5204,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL800",
                "Engine_code": "CSJ",
            },
            "5.2 V10 610-4 610hp": {
                "std_power": 610,
                "tuned_power": 630,
                "std_torque": 560,
                "tuned_torque": 580,
                "cc": 5204,
                "Type_ecu": "Bosch MED17.7.1 & Temic DL800",
                "Engine_code": "CSJ",
            },
            "EVO 640hp": {
                "std_power": 640,
                "tuned_power": 655,
                "std_torque": 600,
                "tuned_torque": 630,
                "cc": 5204,
                "Type_ecu": "Bosch MED17.1.1 & Temic DL800",
                "Engine_code": "BUJ",
            },
            "Performante 640hp": {
                "std_power": 640,
                "tuned_power": 655,
                "std_torque": 600,
                "tuned_torque": 630,
                "cc": 5204,
                "Type_ecu": "Bosch MED17.1.1 & Temic DQ500",
                "Engine_code": "BUJ",
            },
        },
    },
    "Murcielago": {
        "All": {
            "6.2 V12 580hp": {
                "std_power": 580,
                "tuned_power": 602,
                "std_torque": 650,
                "tuned_torque": 679,
                "cc": 6192,
                "Type_ecu": "Campi 05S",
                "Engine_code": "L535",
            },
            "6.5 V12 LP640-4 640hp": {
                "std_power": 640,
                "tuned_power": 665,
                "std_torque": 660,
                "tuned_torque": 688,
                "cc": 6496,
                "Type_ecu": "Campi 05S",
                "Engine_code": "L537",
            },
            "6.5 V12 LP670-4 SuperVeloce 670hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Urus": {
        "2018 ->": {
            "4.0 V8 Bi-Turbo Performante 666hp": {
                "std_power": 666,
                "tuned_power": 720,
                "std_torque": 850,
                "tuned_torque": 1000,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "",
            },
            "4.0 V8 Bi-Turbo 650hp": {
                "std_power": 650,
                "tuned_power": 720,
                "std_torque": 850,
                "tuned_torque": 1000,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008 & ZF AL552",
                "Engine_code": "DHU",
            },
        },
    },
},
"Lancia": {
    "Delta": {
        "2008 ->": {
            "1.6 JTDm 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C69",
                "Engine_code": "198A2000",
            },
            "1.6 JTDm 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "844A3000",
            },
            "1.9 JTDm 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "844A1000",
            },
            "2.0 JTDm 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 425,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C49",
                "Engine_code": "198A5000",
            },
            "1.4 Multiair 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 290,
                "cc": 1368,
                "Type_ecu": "Marelli 8GMF",
                "Engine_code": "198A7000",
            },
            "1.4 T-jet 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 206,
                "tuned_torque": 265,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch ME17.3.0",
                "Engine_code": "198A4000",
            },
            "1.4 T-jet 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10",
                "Engine_code": "198A1000",
            },
            "1.8 DI T-Jet 200hp": {
                "std_power": 200,
                "tuned_power": 255,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1742,
                "Type_ecu": "Bosch MED17.3.1",
                "Engine_code": "939B1000",
            },
        },
    },
    "Kappa": {
        "All": {
            "2.4 JTD 136hp": {
                "std_power": 136,
                "tuned_power": 168,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "838A8000",
            },
            "2.4 JTD 140hp": {
                "std_power": 140,
                "tuned_power": 168,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "838A8000",
            },
        },
    },
    "Lybra": {
        "All": {
            "1.9 JTD 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 255,
                "tuned_torque": 315,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C5",
                "Engine_code": "AR32302",
            },
            "1.9 JTD 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 275,
                "tuned_torque": 330,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "937A2000",
            },
            "1.9 JTD 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 275,
                "tuned_torque": 330,
                "cc": 1910,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "AR37101",
            },
            "2.4 JTD 140hp": {
                "std_power": 140,
                "tuned_power": 173,
                "std_torque": 304,
                "tuned_torque": 382,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "839A6000",
            },
            "2.4 JTD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 308,
                "tuned_torque": 385,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "841C000",
            },
        },
    },
    "Musa": {
        "All": {
            "1.3 JTDm 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 180,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "188A9000",
            },
            "1.3 JTDm 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 245,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3 & Marelli MJ8DF",
                "Engine_code": "199A3000",
            },
            "1.9 JTDm 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 305,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "188B2000",
            },
            "1.4i 16v 95hp": {
                "std_power": 95,
                "tuned_power": 104,
                "std_torque": 128,
                "tuned_torque": 145,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.9.10 & Bosch ME7.3.1",
                "Engine_code": "843A1000",
            },
        },
    },
    "Phedra": {
        "All": {
            "2.0 JTD 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A",
                "Engine_code": "RHK",
            },
            "2.0 JTD 110hp": {
                "std_power": 110,
                "tuned_power": 132,
                "std_torque": 250,
                "tuned_torque": 295,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "RHM",
            },
            "2.0 JTDm 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 375,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A",
                "Engine_code": "RFN",
            },
            "2.2 JTD 128hp": {
                "std_power": 128,
                "tuned_power": 160,
                "std_torque": 314,
                "tuned_torque": 400,
                "cc": 2179,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "4HW",
            },
            "2.2 JTDm 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 435,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "4HT",
            },
        },
    },
    "Thema": {
        "2011 ->": {
            "3.0 Mjet 190hp": {
                "std_power": 190,
                "tuned_power": 270,
                "std_torque": 440,
                "tuned_torque": 630,
                "cc": 3000,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "M",
            },
            "3.0 Mjet 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 550,
                "tuned_torque": 630,
                "cc": 3000,
                "Type_ecu": "Bosch EDC17C49",
                "Engine_code": "M",
            },
        },
    },
    "Thesis": {
        "2002 - 2008": {
            "2.4 JTD 10v 150hp": {
                "std_power": 150,
                "tuned_power": 182,
                "std_torque": 305,
                "tuned_torque": 380,
                "cc": 2387,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "841C000",
            },
            "2.4 JTD 20v 175hp": {
                "std_power": 175,
                "tuned_power": 208,
                "std_torque": 380,
                "tuned_torque": 465,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C8",
                "Engine_code": "841H000",
            },
            "2.4 JTDm 185hp": {
                "std_power": 185,
                "tuned_power": 212,
                "std_torque": 390,
                "tuned_torque": 485,
                "cc": 2387,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "841P000",
            },
            "2.0 Turbo 185hp": {
                "std_power": 185,
                "tuned_power": 220,
                "std_torque": 308,
                "tuned_torque": 358,
                "cc": 1998,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "8,31E+02",
            },
        },
    },
    "Voyager": {
        "2011 ->": {
            "2.8 Multijet 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2768,
                "Type_ecu": "Bosch EDC17CP27 & Bosch EDC16CP31",
                "Engine_code": "ENS",
            },
            "2.8 Multijet 178hp": {
                "std_power": 178,
                "tuned_power": 215,
                "std_torque": 360,
                "tuned_torque": 500,
                "cc": 2776,
                "Type_ecu": "Bosch EDC17CP27",
                "Engine_code": "ENS",
            },
        },
    },
    "Ypsilon": {
        "2004 - 2011": {
            "1.3 Multijet 70hp": {
                "std_power": 70,
                "tuned_power": 85,
                "std_torque": 180,
                "tuned_torque": 220,
                "cc": 1251,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "188A9000",
            },
            "1.3 Multijet 90hp": {
                "std_power": 90,
                "tuned_power": 114,
                "std_torque": 200,
                "tuned_torque": 245,
                "cc": 1251,
                "Type_ecu": "Marelli MJD6F3",
                "Engine_code": "199A3000",
            },
            "1.3 Multijet 105hp": {
                "std_power": 105,
                "tuned_power": 125,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6F3",
                "Engine_code": "199A3000",
            },
            "1.2i 16v 80hp": {
                "std_power": 80,
                "tuned_power": 88,
                "std_torque": 114,
                "tuned_torque": 125,
                "cc": 1242,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "188A5000",
            },
            "1.2i 8V 60hp": {
                "std_power": 60,
                "tuned_power": 68,
                "std_torque": 102,
                "tuned_torque": 112,
                "cc": 1242,
                "Type_ecu": "Marelli IAW5AF",
                "Engine_code": "188A4.000",
            },
            "1.4i 16v 95hp": {
                "std_power": 95,
                "tuned_power": 107,
                "std_torque": 128,
                "tuned_torque": 144,
                "cc": 1368,
                "Type_ecu": "Bosch ME7.3.1",
                "Engine_code": "843A1000",
            },
            "1.4i 8v 77hp": {
                "std_power": 77,
                "tuned_power": 85,
                "std_torque": 113,
                "tuned_torque": 125,
                "cc": 1368,
                "Type_ecu": "Marelli IAW5SF",
                "Engine_code": "350A1000",
            },
        },
        "2011 - 2015": {
            "1.3 Multijet 90hp": {
                "std_power": 90,
                "tuned_power": 114,
                "std_torque": 200,
                "tuned_torque": 232,
                "cc": 1248,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 Multijet 95hp": {
                "std_power": 95,
                "tuned_power": 114,
                "std_torque": 200,
                "tuned_torque": 232,
                "cc": 1248,
                "Type_ecu": "Marelli MJD9DF",
                "Engine_code": "199B1000 / 312B1000",
            },
            "0.9 Twinair 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 145,
                "tuned_torque": 185,
                "cc": 875,
                "Type_ecu": "Marelli IAW8GMF",
                "Engine_code": "312A2.000",
            },
            "0.9 Twinair 85hp": {
                "std_power": 85,
                "tuned_power": 100,
                "std_torque": 145,
                "tuned_torque": 185,
                "cc": 875,
                "Type_ecu": "Marelli 8GSF & Marelli 9GF",
                "Engine_code": "312A2.000",
            },
        },
        "2016 ->": {
            "1.3 Multijet 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli 9DF SPC5644",
                "Engine_code": "199B1000 / 312B1000",
            },
            "0.9 Twinair 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 145,
                "tuned_torque": 190,
                "cc": 875,
                "Type_ecu": "Marelli 9GF",
                "Engine_code": "312A2.000",
            },
        },
    },
},
"Land Rover": {
    "Defender": {
        "2000 - 2007": {
            "2.5 TD5 122hp": {
                "std_power": 122,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 365,
                "cc": 2495,
                "Type_ecu": "Motorola MEMS3",
                "Engine_code": "TD5 10P",
            },
        },
        "2007 - 2011": {
            "TD4 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2401,
                "Type_ecu": "Motorola MEMS3 & Visteon DCU102 & Visteon DCU106",
                "Engine_code": "DT244",
            },
        },
        "2011 - 2018": {
            "TD4 122hp": {
                "std_power": 122,
                "tuned_power": 160,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Motorola MEMS3 & Siemens/Continental SID208",
                "Engine_code": "DT244",
            },
        },
        "2019 ->": {
            "D200 (2.0D) 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 430,
                "tuned_torque": 480,
                "cc": 1999,
                "Type_ecu": "Bosch MEDC17.9",
                "Engine_code": "204DT",
            },
            "D200 (3.0D) 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D240 (2.0D) 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1999,
                "Type_ecu": "Bosch MEDC17.9",
                "Engine_code": "",
            },
            "D250 (3.0D) 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P525 (5.0) 525hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P300 (2.0T) 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "204PT",
            },
            "P400 (3.0T) 400hp": {
                "std_power": 400,
                "tuned_power": 425,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2996,
                "Type_ecu": "Bosch MG1CS028",
                "Engine_code": "306PT",
            },
            "P400e (2.0T) 404hp": {
                "std_power": 404,
                "tuned_power": 425,
                "std_torque": 640,
                "tuned_torque": 690,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "204PT",
            },
        },
    },
    "Discovery": {
        "1998 - 2004": {
            "2.5 TD5 137hp": {
                "std_power": 137,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 2495,
                "Type_ecu": "Motorola MEMS3",
                "Engine_code": "16P",
            },
            "TD5 aut 137hp": {
                "std_power": 137,
                "tuned_power": 160,
                "std_torque": 340,
                "tuned_torque": 390,
                "cc": 2495,
                "Type_ecu": "Motorola MEMS3",
                "Engine_code": "15P",
            },
        },
        "2004 - 2016": {
            "2.7 TDV6 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2720,
                "Type_ecu": "Siemens/Continental SID204 & Siemens/Continental SID201 & Siemens/Continental SID203 & Siemens/Continental SID208",
                "Engine_code": "276DT",
            },
            "3.0 SDV6 256hp": {
                "std_power": 256,
                "tuned_power": 300,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11 & Bosch EDC17CP55",
                "Engine_code": "306DT",
            },
            "3.0 TDV6 211hp": {
                "std_power": 211,
                "tuned_power": 295,
                "std_torque": 520,
                "tuned_torque": 660,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11 & Bosch EDC17CP55",
                "Engine_code": "306DT",
            },
            "3.0 TDV6 245hp": {
                "std_power": 245,
                "tuned_power": 295,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11 & Bosch EDC17CP55",
                "Engine_code": "306DT",
            },
        },
        "2017 - 2020": {
            "2.0 SD4 240hp": {
                "std_power": 240,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1999,
                "Type_ecu": "Bosch MEDC17.9 & Bosch MED17.9.7",
                "Engine_code": "204DTA",
            },
            "2.0 TD4 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 420,
                "tuned_torque": 490,
                "cc": 1999,
                "Type_ecu": "Bosch MEDC17.9.3 & Bosch EDC17CP55",
                "Engine_code": "204DTD",
            },
            "3.0 SD6 306hp": {
                "std_power": 306,
                "tuned_power": 330,
                "std_torque": 700,
                "tuned_torque": 760,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "306DT",
            },
            "3.0 TD6 258hp": {
                "std_power": 258,
                "tuned_power": 300,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "306DT",
            },
            "2.0 SI4 300hp": {
                "std_power": 300,
                "tuned_power": 325,
                "std_torque": 400,
                "tuned_torque": 448,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.7",
                "Engine_code": "204PT",
            },
            "3.0 V6 Supercharged 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2993,
                "Type_ecu": "Bosch MED17.8.32 & Bosch MED17.8.31",
                "Engine_code": "306DT",
            },
        },
        "2021 -> ...": {
            "D250 (3.0D) 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D300 (3.0D) 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P300 (2.0T) 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P360 (3.0T) 360hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Discovery Sport": {
        "2014 - 2019": {
            "2.0 ED4 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch EDC17C08 & Bosch MEDC17.9 & ZF 8HP",
                "Engine_code": "204DTD",
            },
            "2.0 SD4 240hp": {
                "std_power": 240,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1999,
                "Type_ecu": "Bosch MEDC17.9 & Bosch EDC17C08 & Bosch MED17.2",
                "Engine_code": "204DTA",
            },
            "2.0 TD4 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 470,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MEDC17.9",
                "Engine_code": "204DTD",
            },
            "2.0 TD4 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch MEDC17.9",
                "Engine_code": "204DTD",
            },
            "2.2 SD4 190hp": {
                "std_power": 190,
                "tuned_power": 215,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "",
            },
            "2.2 TD4 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "224DT",
            },
            "2.0 SI4 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.9.9 & Bosch MED17.9.7",
                "Engine_code": "204PT",
            },
            "2.0 SI4 290hp": {
                "std_power": 290,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.9.7",
                "Engine_code": "204PT",
            },
        },
        "2019 -> ...": {
            "D150 (2.0D) 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D165 (2.0D) 165hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D180 (2.0D) 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D200 (2.0D) 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D240 (2.0D) 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "204DTA",
            },
            "P200 (2.0T) 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "204PT",
            },
            "P250 (2.0T) 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 365,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.1.9",
                "Engine_code": "204PT",
            },
        },
    },
    "Evoque": {
        "2011 - 2015": {
            "2.2 ED4 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42 & ZF 9HP",
                "Engine_code": "224DT",
            },
            "2.2 SD4 190hp": {
                "std_power": 190,
                "tuned_power": 215,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42 & Bosch EDC17C46 & Bosch MEDC17.9 & ZF 6HP & ZF 9HP48",
                "Engine_code": "224DT",
            },
            "2.2 TD4 150hp": {
                "std_power": 150,
                "tuned_power": 215,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "224DT",
            },
            "2.0 Si4 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0 & Bosch MED17.9.7 & Bosch MED17.9.9 & Bosch MED17.1.9",
                "Engine_code": "204PT",
            },
        },
        "2015 - 2018": {
            "2.0 ED4 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MEDC17.9",
                "Engine_code": "204DTD",
            },
            "2.0 TD4 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 430,
                "tuned_torque": 490,
                "cc": 1998,
                "Type_ecu": "Bosch MEDC17.9 & Bosch EDC17C9",
                "Engine_code": "204DTD",
            },
            "2.0 TD4 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MEDC17.9",
                "Engine_code": "204DTD",
            },
            "2.0 Si4 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.1.9 & Bosch MED17.9.7 & Bosch MED17.9.9 & Bosch MED17.0",
                "Engine_code": "204PT",
            },
        },
        "2019 - 2022": {
            "D150 (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MED17.9.X & Bosch MEDC17.9.3 & Bosch MEDC17.9",
                "Engine_code": "204DTD",
            },
            "D165 (2.0D) 165hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D180 (2.0D) 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 430,
                "tuned_torque": 465,
                "cc": 1999,
                "Type_ecu": "Bosch MEDC17.9",
                "Engine_code": "204DTD",
            },
            "D200 (2.0D) 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D240 (2.0D) 240hp": {
                "std_power": 240,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1999,
                "Type_ecu": "Bosch MEDC17.9",
                "Engine_code": "204DTA",
            },
            "P160 (1.5T) 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P200 (2.0T) 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "204PT",
            },
            "P250 (2.0T) 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 365,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.1.9 & Bosch MED17.9.9",
                "Engine_code": "204PT",
            },
            "P300 (2.0T) 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "204PT",
            },
            "P300e (1.5T) 309hp": {
                "std_power": 309,
                "tuned_power": 340,
                "std_torque": 540,
                "tuned_torque": 595,
                "cc": 1498,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2023 -> ...": {
            "D165 (2.0D) 165hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D200 (2.0D) 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P200 (2.0T) 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P250 (2.0T) 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P300e (1.5T) 309hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Freelander": {
        "2003 - 2007": {
            "2.0 TD4 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 340,
                "cc": 1951,
                "Type_ecu": "Bosch DDE4.0 & Bosch EDC15C4",
                "Engine_code": "M47R",
            },
        },
        "2007 - 2010": {
            "2.2 TD4 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42 & Bosch EDC16CP39",
                "Engine_code": "224DT",
            },
            "2.2 TD4 160hp": {
                "std_power": 160,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16CP39",
                "Engine_code": "224DT",
            },
        },
        "2010 ->": {
            "2.2 TD4 150hp": {
                "std_power": 150,
                "tuned_power": 215,
                "std_torque": 420,
                "tuned_torque": 480,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "224DT",
            },
            "2.2 TD4 190hp": {
                "std_power": 190,
                "tuned_power": 215,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "224DT",
            },
            "2.0 SI4 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.9.7",
                "Engine_code": "204PT",
            },
        },
    },
    "Range Rover / Sport": {
        "1994 - 2002": {
            "2.5 TD 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 270,
                "tuned_torque": 360,
                "cc": 2497,
                "Type_ecu": "Bosch EDC1.3.1",
                "Engine_code": "25 6T (M) OF 25",
            },
        },
        "2002 - 2012 (L322)": {
            "2.7 TDV6 190hp": {
                "std_power": 190,
                "tuned_power": 240,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2720,
                "Type_ecu": "Siemens/Continental SID201 & Siemens/Continental SID204 & Siemens/Continental SID203",
                "Engine_code": "267DT",
            },
            "3.0 TD6 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 390,
                "tuned_torque": 470,
                "cc": 2926,
                "Type_ecu": "Bosch DDE4.0 & Bosch EDC15C4",
                "Engine_code": "306D1",
            },
            "3.0 TDV6 211hp": {
                "std_power": 211,
                "tuned_power": 295,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "306DT",
            },
            "3.0 TDV6 245hp": {
                "std_power": 245,
                "tuned_power": 295,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "306DT",
            },
            "3.0 TDV6 256hp": {
                "std_power": 256,
                "tuned_power": 295,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "306DT",
            },
            "3.6 TDV8 272hp": {
                "std_power": 272,
                "tuned_power": 302,
                "std_torque": 640,
                "tuned_torque": 740,
                "cc": 3628,
                "Type_ecu": "Siemens/Continental SID204 & Siemens/Continental SID201 & Siemens/Continental SID20x & Siemens/Continental SID203 & ZF 6HP",
                "Engine_code": "368DT",
            },
            "4.4 TDV8 313hp": {
                "std_power": 313,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 4367,
                "Type_ecu": "Bosch EDC17CP11 & Bosch ME7.2",
                "Engine_code": "448DT",
            },
            "4.2 V8 Supercharged 396hp": {
                "std_power": 396,
                "tuned_power": 450,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 4196,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "428PS",
            },
            "5.0 Supercharged 510hp": {
                "std_power": 510,
                "tuned_power": 560,
                "std_torque": 625,
                "tuned_torque": 700,
                "cc": 4999,
                "Type_ecu": "Bosch MED17.8.31 & Denso P5.0SC & ZF 6HP",
                "Engine_code": "508PS",
            },
        },
        "2012 - 2018 (L405)": {
            "3.0 SDV6 Hybrid 340hp": {
                "std_power": 340,
                "tuned_power": 380,
                "std_torque": 760,
                "tuned_torque": 790,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11 & Bosch EDC17CP55",
                "Engine_code": "",
            },
            "3.0 SDV6 292hp": {
                "std_power": 292,
                "tuned_power": 320,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2720,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "306DT",
            },
            "3.0 SDV6 306hp": {
                "std_power": 306,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 760,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55 & Bosch EDC17CP11 & Bosch MED17.8.31",
                "Engine_code": "306DT",
            },
            "3.0 TDV6 258hp": {
                "std_power": 258,
                "tuned_power": 300,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55 & Bosch EDC17CP11",
                "Engine_code": "306DT",
            },
            "3.0 TDV6 211hp": {
                "std_power": 211,
                "tuned_power": 300,
                "std_torque": 520,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11 & Bosch EDC17CP55",
                "Engine_code": "306DT",
            },
            "4.4 SDV8 339hp": {
                "std_power": 339,
                "tuned_power": 390,
                "std_torque": 740,
                "tuned_torque": 820,
                "cc": 4367,
                "Type_ecu": "Bosch EDC17CP11 & Bosch EDC17CP55",
                "Engine_code": "448DT",
            },
            "4.4 TDV8 313hp": {
                "std_power": 313,
                "tuned_power": 390,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 4367,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "448DT",
            },
            "2.0 Si4 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MEDC17.9",
                "Engine_code": "204PT",
            },
            "3.0 V6 Supercharged 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.31 & Bosch MED17.5.4 & Bosch MED17.8.32",
                "Engine_code": "306PS",
            },
            "5.0 Supercharged SVR 550hp": {
                "std_power": 550,
                "tuned_power": 570,
                "std_torque": 680,
                "tuned_torque": 720,
                "cc": 4999,
                "Type_ecu": "Bosch MED17.8.31 & Bosch MED17.5.4 & Bosch MED17.8.32",
                "Engine_code": "508PS",
            },
            "5.0 Supercharged 510hp": {
                "std_power": 510,
                "tuned_power": 560,
                "std_torque": 625,
                "tuned_torque": 700,
                "cc": 4999,
                "Type_ecu": "Bosch MED17.8.31 & Bosch MED17.5.4 & Bosch MED17.8.32",
                "Engine_code": "508PS",
            },
        },
        "2018 - 2021": {
            "2.0 SD4 240hp": {
                "std_power": 240,
                "tuned_power": 250,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1997,
                "Type_ecu": "Bosch EDC17 & Bosch MEDC17.9",
                "Engine_code": "",
            },
            "3.0 SDV6 275hp": {
                "std_power": 275,
                "tuned_power": 305,
                "std_torque": 625,
                "tuned_torque": 675,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "",
            },
            "3.0 SDV6 306hp": {
                "std_power": 306,
                "tuned_power": 330,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch MED17.5.4 & Bosch MED17.8.32 & Bosch EDC17CP55 & Bosch MG1CS028",
                "Engine_code": "306DT",
            },
            "3.0 SDV6 249hp": {
                "std_power": 249,
                "tuned_power": 305,
                "std_torque": 600,
                "tuned_torque": 675,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17C55",
                "Engine_code": "30DDTX",
            },
            "3.0 TDV6 258hp": {
                "std_power": 258,
                "tuned_power": 300,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "",
            },
            "4.4 SDV8 339hp": {
                "std_power": 339,
                "tuned_power": 390,
                "std_torque": 740,
                "tuned_torque": 820,
                "cc": 4367,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "448DT",
            },
            "2.0 Si4 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9 & ZF 9HP48",
                "Engine_code": "204PT",
            },
            "3.0 V6 Supercharged 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS028 & Bosch MED17.8.32 & Bosch EDC17CP55",
                "Engine_code": "306PS",
            },
            "5.0 V8 Supercharged SVR 565hp": {
                "std_power": 565,
                "tuned_power": 590,
                "std_torque": 700,
                "tuned_torque": 720,
                "cc": 5000,
                "Type_ecu": "Bosch MED17.8.32 & Bosch MED17.5.4",
                "Engine_code": "508PS",
            },
            "5.0 V8 Supercharged 525hp": {
                "std_power": 525,
                "tuned_power": 570,
                "std_torque": 680,
                "tuned_torque": 720,
                "cc": 5000,
                "Type_ecu": "Bosch MED17.8.3",
                "Engine_code": "508PS",
            },
            "P400 3.0T 400hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P400e 404hp": {
                "std_power": 404,
                "tuned_power": 425,
                "std_torque": 640,
                "tuned_torque": 690,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9 & Bosch MG1CS028",
                "Engine_code": "204PT",
            },
        },
        "2022 -> ...": {
            "D250 (3.0D) 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D300 (3.0D) 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D350 (3.0D) 350hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "SV 4.4 Bi-Turbo V8 635hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P360 (3.0T) 360hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P400 (3.0T) 400hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P440e (2.0T) 440hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P510e (3.0T) 510hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P530 (4.4T) 530hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Velar": {
        "2017 - 2020": {
            "D180 (2.0D) 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 430,
                "tuned_torque": 465,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MEDC17.9.3",
                "Engine_code": "204DTD",
            },
            "D240 (2.0D) 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1999,
                "Type_ecu": "Bosch EDC17CP55 & Bosch EDC17C08 & Bosch MEDC17.9",
                "Engine_code": "204DTA",
            },
            "D275 (3.0D) 275hp": {
                "std_power": 275,
                "tuned_power": 305,
                "std_torque": 625,
                "tuned_torque": 675,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "306DT",
            },
            "D300 (3.0D) 300hp": {
                "std_power": 300,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP55",
                "Engine_code": "306DT",
            },
            "P250 (2.0T) 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 365,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9 & Bosch MED17.1.9",
                "Engine_code": "204PT",
            },
            "P300 (2.0T) 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch EDC17CP55 & Bosch MED17.9.9 & Bosch MED17.1.9",
                "Engine_code": "204PT",
            },
            "P340 (3.0T) 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.8.32",
                "Engine_code": "306PS",
            },
            "P380 (3.0T) 380hp": {
                "std_power": 380,
                "tuned_power": 400,
                "std_torque": 460,
                "tuned_torque": 500,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.5.4 & Bosch MED17.8.32",
                "Engine_code": "306PS",
            },
        },
        "2020 - 2023": {
            "D200 (2.0D) 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D300 (3.0D) 300hp": {
                "std_power": 300,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 2993,
                "Type_ecu": "",
                "Engine_code": "306DT",
            },
            "P250 (2.0T) 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 365,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Bosch MED17.9.9",
                "Engine_code": "204PT",
            },
        },
        "2023 -> ...": {
            "D200 (2.0D) 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "D300 (3.0D) 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P250 (2.0T) 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P340 (3.0T) 340hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P400 (3.0T) 400hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "P400e (2.0T) 404hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
},
"Lexus": {
    "ES": {
        "2019 ->": {
            "300h 218hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "GS": {
        "2005 - 2012": {
            "300 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 310,
                "tuned_torque": 330,
                "cc": 2995,
                "Type_ecu": "Denso 275036-8730 (CAN)",
                "Engine_code": "3GR-FSE",
            },
        },
    },
    "IS": {
        "2005 - 2013": {
            "200d 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 2231,
                "Type_ecu": "Denso 896xx",
                "Engine_code": "2AD-FHV",
            },
            "220d 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2231,
                "Type_ecu": "Denso 175822-733 (CAN) & Denso 896xx & Denso 76F00XX",
                "Engine_code": "2AD-FHV",
            },
        },
        "2013 - 2016": {
        },
        "2016 - 2020": {
            "300 (2.0T) 241hp": {
                "std_power": 241,
                "tuned_power": 265,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Denso D76F019XXX",
                "Engine_code": "8AR-FTS",
            },
        },
    },
    "NX": {
        "2015 - 2017": {
            "200t 238hp": {
                "std_power": 238,
                "tuned_power": 270,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Denso 89661-78100 & Denso 76F00XX",
                "Engine_code": "8AR-FTS",
            },
            "300h 155hp": {
                "std_power": 155,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 2494,
                "Type_ecu": "Denso 76F00XX",
                "Engine_code": "2AR-FXE",
            },
        },
        "2017 - 2020": {
            "2.0 Turbo 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Denso 896xx & Denso 76F00XX & Denso P5",
                "Engine_code": "8AR-FTS",
            },
        },
    },
    "RX": {
        "2012 - 2015": {
        },
        "2015 - 2019": {
            "2.0T 235hp": {
                "std_power": 235,
                "tuned_power": 265,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Denso P5 & Denso 89661-48z70 & Denso D76F019XXX",
                "Engine_code": "8AR-FTS",
            },
        },
        "2020 -> ...": {
            "450h V6 308hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "UX": {
        "2018 ->": {
            "200 - 177hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "250h 177hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
},
"Lincoln": {
    "Continental": {
        "2017 -> ...": {
            "2.7 Ecoboost V6 335hp": {
                "std_power": 335,
                "tuned_power": 350,
                "std_torque": 515,
                "tuned_torque": 550,
                "cc": 2688,
                "Type_ecu": "",
                "Engine_code": "2.7 Ecoboost",
            },
            "3.0 Ecoboost V6 400hp": {
                "std_power": 400,
                "tuned_power": 430,
                "std_torque": 542,
                "tuned_torque": 620,
                "cc": 2956,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MEDG17.0",
                "Engine_code": "3.0 Ecoboost",
            },
        },
    },
    "LS": {
        "2000 - 2006": {
            "3.9 V8 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 388,
                "tuned_torque": 420,
                "cc": 3934,
                "Type_ecu": "",
                "Engine_code": "AJ30/AJ35",
            },
        },
    },
    "MKS": {
        "2008 - 2012": {
            "3.5 V6 Ecoboost 365hp": {
                "std_power": 365,
                "tuned_power": 410,
                "std_torque": 575,
                "tuned_torque": 635,
                "cc": 3510,
                "Type_ecu": "",
                "Engine_code": "3.5 Ecoboost",
            },
            "3.7 V6 276hp": {
                "std_power": 276,
                "tuned_power": 295,
                "std_torque": 374,
                "tuned_torque": 400,
                "cc": 3721,
                "Type_ecu": "Siemens/Continental EMS24xx",
                "Engine_code": "Duratec 37",
            },
        },
    },
    "MKT": {
        "2010 - 2014": {
            "3.7 V6 268hp": {
                "std_power": 268,
                "tuned_power": 290,
                "std_torque": 362,
                "tuned_torque": 385,
                "cc": 3726,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "Duratec 37",
            },
            "3.7 V6 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 366,
                "tuned_torque": 390,
                "cc": 3726,
                "Type_ecu": "Siemens/Continental EMS24xx",
                "Engine_code": "Duratec 37",
            },
        },
    },
    "Navigator": {
        "2003 - 2006": {
            "5.4 V8 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 481,
                "tuned_torque": 500,
                "cc": 5408,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "Modular 5.4",
            },
        },
        "2007 - 2017": {
            "3.5 Ecoboost 370hp": {
                "std_power": 370,
                "tuned_power": 420,
                "std_torque": 583,
                "tuned_torque": 630,
                "cc": 3490,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MG1CS015",
                "Engine_code": "3.5 Ecoboost",
            },
            "3.5 Ecoboost 456hp": {
                "std_power": 456,
                "tuned_power": 480,
                "std_torque": 691,
                "tuned_torque": 770,
                "cc": 3496,
                "Type_ecu": "Bosch MG1CS015",
                "Engine_code": "3.5 Ecoboost",
            },
            "5.4 V8 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 395,
                "tuned_torque": 425,
                "cc": 5408,
                "Type_ecu": "Ford EEC-V",
                "Engine_code": "Modular 5.4",
            },
        },
        "2017 - 2020": {
            "3.5 V6 Bi-Turbo 456hp": {
                "std_power": 456,
                "tuned_power": 480,
                "std_torque": 690,
                "tuned_torque": 770,
                "cc": 3496,
                "Type_ecu": "Bosch MG1CS015",
                "Engine_code": "",
            },
        },
        "2021 -> ...": {
            "3.5 V6 Bi-Turbo 440hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Towncar": {
        "2005 - 2012": {
            "4.6 V8 239hp": {
                "std_power": 239,
                "tuned_power": 260,
                "std_torque": 389,
                "tuned_torque": 410,
                "cc": 4606,
                "Type_ecu": "",
                "Engine_code": "Modular 4.6",
            },
        },
    },
},
"Lotus": {
    "Elise": {
        "All": {
            "Elise Cup 246hp": {
                "std_power": 246,
                "tuned_power": 265,
                "std_torque": 250,
                "tuned_torque": 270,
                "cc": 1798,
                "Type_ecu": "EFI T6E",
                "Engine_code": "2ZR-FE",
            },
            "R 192hp": {
                "std_power": 192,
                "tuned_power": 205,
                "std_torque": 181,
                "tuned_torque": 196,
                "cc": 1796,
                "Type_ecu": "EFI T4",
                "Engine_code": "2ZZ",
            },
            "S 136hp": {
                "std_power": 136,
                "tuned_power": 150,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1796,
                "Type_ecu": "EFI T4E",
                "Engine_code": "1ZZ",
            },
            "SC 220hp": {
                "std_power": 220,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 280,
                "cc": 1796,
                "Type_ecu": "EFI T4 & EFI T6E",
                "Engine_code": "2ZZ-GE",
            },
        },
    },
    "Emira": {
        "2021 -> ...": {
            "2.0T 360hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5 V6 405hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Europa": {
        "All": {
            "SC 220hp": {
                "std_power": 220,
                "tuned_power": 240,
                "std_torque": 272,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "EFI T4E",
                "Engine_code": "Z20LER",
            },
        },
    },
    "Evora": {
        "All": {
            "3.5 V6 400 406hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5 V6 S / SR 350hp": {
                "std_power": 350,
                "tuned_power": 360,
                "std_torque": 400,
                "tuned_torque": 415,
                "cc": 3456,
                "Type_ecu": "EFI T6E",
                "Engine_code": "2GR",
            },
            "3.5 V6 280hp": {
                "std_power": 280,
                "tuned_power": 305,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 3456,
                "Type_ecu": "EFI T6E",
                "Engine_code": "2GR-FE",
            },
        },
    },
    "Exige": {
        "All": {
            "Cup 3.5 V6 436hp": {
                "std_power": 436,
                "tuned_power": 460,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 3456,
                "Type_ecu": "EFI T6E",
                "Engine_code": "2GR-FE",
            },
            "1.8 192hp": {
                "std_power": 192,
                "tuned_power": 205,
                "std_torque": 185,
                "tuned_torque": 200,
                "cc": 1796,
                "Type_ecu": "EFI T4",
                "Engine_code": "2ZZ",
            },
            "Cup R 3.5 V6 366hp": {
                "std_power": 366,
                "tuned_power": 375,
                "std_torque": 413,
                "tuned_torque": 430,
                "cc": 3456,
                "Type_ecu": "EFI T4E",
                "Engine_code": "2GR",
            },
            "S 3.5 V6 350hp": {
                "std_power": 350,
                "tuned_power": 360,
                "std_torque": 400,
                "tuned_torque": 415,
                "cc": 3456,
                "Type_ecu": "EFI T6E",
                "Engine_code": "2GR",
            },
            "S 221hp": {
                "std_power": 221,
                "tuned_power": 240,
                "std_torque": 215,
                "tuned_torque": 230,
                "cc": 1796,
                "Type_ecu": "EFI T4E",
                "Engine_code": "2ZZ-GE",
            },
        },
    },
},
"MAN": {
    "TGE": {
        "2017 ->": {
            "2.0 TDI CR 102hp": {
                "std_power": 102,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17CV42",
                "Engine_code": "",
            },
            "2.0 TDI CR 122hp": {
                "std_power": 122,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC7C32",
                "Engine_code": "",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2V",
                "Engine_code": "",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17CV42 & Bosch EDC7C32",
                "Engine_code": "DAVA",
            },
        },
    },
},
"Maserati": {
    "Coupé": {
        "2002 - 2007": {
            "GT 4.2 V8 390hp": {
                "std_power": 390,
                "tuned_power": 420,
                "std_torque": 451,
                "tuned_torque": 475,
                "cc": 4244,
                "Type_ecu": "Bosch ME7.3.2 & Bosch ME7.1.1",
                "Engine_code": "M 138",
            },
        },
    },
    "Ghibli": {
        "2013 - 2016": {
            "3.0D V6 275hp": {
                "std_power": 275,
                "tuned_power": 305,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17C79",
                "Engine_code": "M 15746D",
            },
            "3.0 V6 Bi-Turbo 330hp": {
                "std_power": 330,
                "tuned_power": 380,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.3.4 & Bosch MED17.6.9 & Bosch MED17.3.5 & ZF 8HP",
                "Engine_code": "M 156C",
            },
            "S 3.0 V6 Bi-Turbo 410hp": {
                "std_power": 410,
                "tuned_power": 460,
                "std_torque": 550,
                "tuned_torque": 665,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.3.4 & Bosch MED17.6.9 & Bosch MED17.3.5",
                "Engine_code": "M 156B",
            },
        },
        "2017 ->": {
            "3.0D V6 275hp": {
                "std_power": 275,
                "tuned_power": 305,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17C79",
                "Engine_code": "A630HP",
            },
            "3.0D V6 250hp": {
                "std_power": 250,
                "tuned_power": 305,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17C79",
                "Engine_code": "A630HP",
            },
            "3.0 V6 350hp": {
                "std_power": 350,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.3.5 & Bosch MED17.6.9",
                "Engine_code": "F 160AM",
            },
            "3.8 V8 Bi-Turbo Trofeo 590hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "S 3.0 V6 Bi-Turbo 410hp": {
                "std_power": 410,
                "tuned_power": 460,
                "std_torque": 550,
                "tuned_torque": 700,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.3.4 & Bosch MED17.3.5",
                "Engine_code": "F 160AM",
            },
        },
    },
    "GranTurismo": {
        "2007 - 2016": {
            "4.2 V8 405hp": {
                "std_power": 405,
                "tuned_power": 430,
                "std_torque": 460,
                "tuned_torque": 495,
                "cc": 4244,
                "Type_ecu": "Bosch ME7.3.2 & Bosch ME9.1.1",
                "Engine_code": "M 139P",
            },
            "4.7 V8 440hp": {
                "std_power": 440,
                "tuned_power": 460,
                "std_torque": 490,
                "tuned_torque": 510,
                "cc": 4691,
                "Type_ecu": "Bosch ME9.1.1 & Bosch ME7.3.2",
                "Engine_code": "M 145",
            },
        },
        "2017 - 2019": {
            "4.7 V8 460hp": {
                "std_power": 460,
                "tuned_power": 485,
                "std_torque": 520,
                "tuned_torque": 550,
                "cc": 4691,
                "Type_ecu": "Bosch ME9.1.1",
                "Engine_code": "M 145A",
            },
        },
        "2023 -> ...": {
            "3.0 V6 Bi-Turbo 490hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0 V6 Bi-Turbo 550hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Levante": {
        "2016 ->": {
            "3.0D V6 275hp": {
                "std_power": 275,
                "tuned_power": 305,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2787,
                "Type_ecu": "Bosch EDC17C79",
                "Engine_code": "M 16164D",
            },
            "3.0D V6 250hp": {
                "std_power": 250,
                "tuned_power": 305,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2787,
                "Type_ecu": "Bosch EDC17C79",
                "Engine_code": "M 16164D",
            },
            "2.0T 330hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0 V6 Bi-Turbo S 430hp": {
                "std_power": 430,
                "tuned_power": 460,
                "std_torque": 580,
                "tuned_torque": 700,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.6.9 & Bosch MED17.3.5 & ZF 8HP70",
                "Engine_code": "M 156E",
            },
            "3.0 V6 Bi-Turbo 350hp": {
                "std_power": 350,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.6.9 & Bosch MED17.3.5",
                "Engine_code": "M 139S",
            },
            "3.8 V8 Bi-Turbo GTS 550hp": {
                "std_power": 550,
                "tuned_power": 570,
                "std_torque": 730,
                "tuned_torque": 800,
                "cc": 3799,
                "Type_ecu": "Bosch MED17.3.0",
                "Engine_code": "F 156AS",
            },
            "3.8 V8 Bi-Turbo Trofeo 590hp": {
                "std_power": 590,
                "tuned_power": 650,
                "std_torque": 730,
                "tuned_torque": 820,
                "cc": 3799,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "F 154AQ",
            },
        },
    },
    "MC20": {
        "2021 -> ...": {
            "3.0 Bi-Turbo V6 630hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Quattroporte": {
        "2004 - 2012": {
            "4.2 V8 400hp": {
                "std_power": 400,
                "tuned_power": 425,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 4244,
                "Type_ecu": "Bosch ME7.3.2 & Bosch ME9.1.1",
                "Engine_code": "M 139A",
            },
            "4.7 V8 430hp": {
                "std_power": 430,
                "tuned_power": 445,
                "std_torque": 490,
                "tuned_torque": 510,
                "cc": 4691,
                "Type_ecu": "Bosch ME7.3.2 & Bosch ME9.1.1",
                "Engine_code": "M 139R",
            },
            "4.7 V8 440hp": {
                "std_power": 440,
                "tuned_power": 460,
                "std_torque": 490,
                "tuned_torque": 510,
                "cc": 4691,
                "Type_ecu": "Bosch ME7.3.2 & Bosch ME9.1.1",
                "Engine_code": "M 139L",
            },
        },
        "2013 ->": {
            "3.0D V6 275hp": {
                "std_power": 275,
                "tuned_power": 305,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17C79 & Bosch EDC17C49",
                "Engine_code": "M 15746D",
            },
            "3.0 V6 Turbo 330hp": {
                "std_power": 330,
                "tuned_power": 380,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2979,
                "Type_ecu": "Bosch MED17.3.4 & Bosch MED17.6.9 & Bosch MED17.3.5",
                "Engine_code": "M 156C",
            },
            "3.8 V8 Bi-Turbo Trofeo 590hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.8 V8 GTS 530hp": {
                "std_power": 530,
                "tuned_power": 570,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 3799,
                "Type_ecu": "Bosch MED17.3.4 & Bosch MED17.3.5",
                "Engine_code": "M 156 A",
            },
            "S 3.0 V6 Turbo 410hp": {
                "std_power": 410,
                "tuned_power": 500,
                "std_torque": 550,
                "tuned_torque": 665,
                "cc": 2987,
                "Type_ecu": "Bosch MED17.3.4 & Bosch MED17.3.5",
                "Engine_code": "M 156B",
            },
            "S 3.0 V6 Turbo 430hp": {
                "std_power": 430,
                "tuned_power": 500,
                "std_torque": 580,
                "tuned_torque": 665,
                "cc": 2987,
                "Type_ecu": "Bosch MED17.3.5",
                "Engine_code": "",
            },
        },
    },
},
"Mazda": {
    "2": {
        "2003 - 2006": {
            "1.4 CiTD 68hp": {
                "std_power": 68,
                "tuned_power": 92,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1399,
                "Type_ecu": "Siemens/Continental SID206 & Siemens/Continental SID210",
                "Engine_code": "MZ-CD",
            },
        },
        "2007 - 2013": {
            "1.4 CiTD 68hp": {
                "std_power": 68,
                "tuned_power": 92,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1399,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "MZ-CD",
            },
            "1.6 CD 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16+",
                "Engine_code": "",
            },
            "1.3i 86hp": {
                "std_power": 86,
                "tuned_power": 91,
                "std_torque": 121,
                "tuned_torque": 133,
                "cc": 1349,
                "Type_ecu": "Siemens/Continental SIM210 & Denso ZJ77",
                "Engine_code": "ZJ-VE MZR",
            },
            "1.3i 75hp": {
                "std_power": 75,
                "tuned_power": 82,
                "std_torque": 121,
                "tuned_torque": 133,
                "cc": 1349,
                "Type_ecu": "Denso ZJ77",
                "Engine_code": "ZJ-VE MZR",
            },
            "1.5i 103hp": {
                "std_power": 103,
                "tuned_power": 113,
                "std_torque": 137,
                "tuned_torque": 150,
                "cc": 1498,
                "Type_ecu": "Denso ZJ77",
                "Engine_code": "ZY-DE",
            },
        },
        "2014 - 2018": {
            "1.5 SkyActiv-D 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 220,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Denso S5",
                "Engine_code": "P5-VPS",
            },
            "1.5 Skyactiv-G 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 148,
                "tuned_torque": 158,
                "cc": 1496,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "P5YP / P5Y7 / P5Y8",
            },
            "1.5 Skyactiv-G 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 148,
                "tuned_torque": 158,
                "cc": 1496,
                "Type_ecu": "",
                "Engine_code": "P5YP / P5Y7",
            },
            "1.5 Skyactiv-G 75hp": {
                "std_power": 75,
                "tuned_power": 125,
                "std_torque": 148,
                "tuned_torque": 158,
                "cc": 1496,
                "Type_ecu": "Denso SH72543",
                "Engine_code": "P5YP / P5Y7 / P5Y8",
            },
        },
        "2019 ->": {
            "1.5 SkyActiv-D 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Denso S5",
                "Engine_code": "",
            },
            "1.5 SkyActiv-G 75hp": {
                "std_power": 75,
                "tuned_power": 130,
                "std_torque": 135,
                "tuned_torque": 160,
                "cc": 1496,
                "Type_ecu": "Denso Pxxx",
                "Engine_code": "",
            },
            "1.5 SkyActiv-G 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 148,
                "tuned_torque": 160,
                "cc": 1496,
                "Type_ecu": "Denso P5",
                "Engine_code": "",
            },
        },
    },
    "3": {
        "2003 - 2009": {
            "1.6 CiTD 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 245,
                "tuned_torque": 315,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C3 & Bosch EDC16C34",
                "Engine_code": "Y6",
            },
            "1.6 CiTD 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C3 & Bosch EDC16C34",
                "Engine_code": "1.6 MZ-CD",
            },
            "2.0 CiTD 143hp": {
                "std_power": 143,
                "tuned_power": 170,
                "std_torque": 360,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Denso RF7X",
                "Engine_code": "RF",
            },
            "2.2 CiTD 185hp": {
                "std_power": 185,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2184,
                "Type_ecu": "Denso R2AB",
                "Engine_code": "2.2 MZR-CD",
            },
            "1.4i 16v 84hp": {
                "std_power": 84,
                "tuned_power": 90,
                "std_torque": 122,
                "tuned_torque": 135,
                "cc": 1349,
                "Type_ecu": "Denso ZJ77",
                "Engine_code": "ZJ",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 145,
                "tuned_torque": 160,
                "cc": 1598,
                "Type_ecu": "Denso Z601 & Denso ZJ77 & Denso SH7058 & Denso SH7055",
                "Engine_code": "Z6",
            },
        },
        "2009 - 2013": {
            "1.6 CiTD 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC16C3",
                "Engine_code": "Y6",
            },
            "1.6 CiTD 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "Y6",
            },
            "2.2 CITD 150hp": {
                "std_power": 150,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 460,
                "cc": 2184,
                "Type_ecu": "Denso R2AB & Denso R2AW",
                "Engine_code": "2.2 MZR-CD",
            },
            "2.2 CiTD 185hp": {
                "std_power": 185,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2184,
                "Type_ecu": "Denso R2AB",
                "Engine_code": "2.2 MZR-CD",
            },
        },
        "2013 - 2015": {
            "1.5 SkyActiv-D 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1496,
                "Type_ecu": "Denso S5",
                "Engine_code": "S5DPTS",
            },
            "2.2 SkyActiv-D 150hp": {
                "std_power": 150,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 480,
                "cc": 2191,
                "Type_ecu": "Denso SH12 & Denso SH13",
                "Engine_code": "SH-VPTS",
            },
            "2.0 SkyActiv-G 120hp": {
                "std_power": 120,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso P5",
                "Engine_code": "PE-VPS",
            },
            "2.0 SkyActiv-G 165hp": {
                "std_power": 165,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso Pxxx",
                "Engine_code": "PE-VPS",
            },
        },
        "2016 - 2018": {
            "1.5 Skyactiv-D 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Denso S5",
                "Engine_code": "S5DPTS",
            },
            "2.2 SkyActiv-D 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2191,
                "Type_ecu": "Denso SH12 & Denso SH13",
                "Engine_code": "SH-VPTS",
            },
            "1.5 Skyactiv-G 100hp": {
                "std_power": 100,
                "tuned_power": 113,
                "std_torque": 150,
                "tuned_torque": 160,
                "cc": 1496,
                "Type_ecu": "",
                "Engine_code": "P5-VPS",
            },
            "2.0 SkyActiv-G 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 210,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso Pxxx",
                "Engine_code": "PE-VPS",
            },
            "2.0 SkyActiv-G 165hp": {
                "std_power": 165,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso SH72543 & Denso P5",
                "Engine_code": "PE-VPS",
            },
        },
        "2019 ->": {
            "1.8 Skyactiv-D 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1759,
                "Type_ecu": "Denso SH9",
                "Engine_code": "",
            },
            "2.0 SkyActiv-G 165hp": {
                "std_power": 165,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso P5",
                "Engine_code": "PE-VPS",
            },
            "2.0 Skyactiv-G 122hp": {
                "std_power": 122,
                "tuned_power": 135,
                "std_torque": 213,
                "tuned_torque": 235,
                "cc": 1998,
                "Type_ecu": "Denso Pxxx",
                "Engine_code": "",
            },
        },
    },
    "5": {
        "2004 ->": {
            "1.6 CiTD 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "1.6 MZ-CD",
            },
            "2.0 CiTD 110hp": {
                "std_power": 110,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Denso RF8K & Denso RF7N & Denso RF7X",
                "Engine_code": "2.0 MZ-CD",
            },
            "2.0 CiTD 143hp": {
                "std_power": 143,
                "tuned_power": 169,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Denso RF7X & Denso RF8K",
                "Engine_code": "2.0 MZ-CD",
            },
        },
    },
    "6": {
        "2003 - 2008": {
            "2.0 CiTD 120hp": {
                "std_power": 120,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "Denso RF7X & Denso RF7X",
                "Engine_code": "2.0 MZR-CD",
            },
            "2.0 CiTD 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "Denso R2AA & Denso RF7N",
                "Engine_code": "2.0 MZR-CD",
            },
            "2.0 CiTD 143hp": {
                "std_power": 143,
                "tuned_power": 170,
                "std_torque": 360,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Denso RF7X & Denso RF5P",
                "Engine_code": "2.0 MZR-CD",
            },
            "2.0 CiTD 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Denso RF8G & Denso RF7X",
                "Engine_code": "2.0 MZR-CD",
            },
            "2.0 CiTD 121hp": {
                "std_power": 121,
                "tuned_power": 170,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "Denso RF7X & Denso RF8X & Denso RF5P",
                "Engine_code": "2.0 MZR-CD",
            },
            "1.8i 16v 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 165,
                "tuned_torque": 180,
                "cc": 1798,
                "Type_ecu": "Mitsubishi E6T5x",
                "Engine_code": "L8-DE",
            },
            "2.5 S-VT 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 226,
                "tuned_torque": 240,
                "cc": 2488,
                "Type_ecu": "Mitsubishi E6T58xxx",
                "Engine_code": "MZR 2.5",
            },
        },
        "2008 - 2012": {
            "2.0 CiTD 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 330,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Denso RF8G",
                "Engine_code": "2.0 MZR-CD",
            },
            "2.2 CiTD 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 460,
                "cc": 2184,
                "Type_ecu": "Denso R2AB & Denso R2AC & Denso R2AA",
                "Engine_code": "PY-VPS",
            },
            "2.2 CiTD 185hp": {
                "std_power": 185,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2184,
                "Type_ecu": "Denso R2AB & Denso R2AA",
                "Engine_code": "2.2 MZR-CD",
            },
            "2.2 CiTD 129hp": {
                "std_power": 129,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 460,
                "cc": 2184,
                "Type_ecu": "Denso SH7058 & Denso R2AB & Denso R2BF-188K1",
                "Engine_code": "SH-VPTS",
            },
            "2.2 CiTD 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2184,
                "Type_ecu": "Denso R2AB",
                "Engine_code": "2.2 MZR-CD",
            },
            "2.0i 16v 155hp": {
                "std_power": 155,
                "tuned_power": 165,
                "std_torque": 193,
                "tuned_torque": 208,
                "cc": 1999,
                "Type_ecu": "Mitsubishi E6T58xxx",
                "Engine_code": "SH-VPTS",
            },
            "2.5 S-VT 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 226,
                "tuned_torque": 240,
                "cc": 2488,
                "Type_ecu": "Mitsubishi E6T58xxx",
                "Engine_code": "L5-VE",
            },
        },
        "2013 ->": {
            "2.2 SkyActiv-D 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2191,
                "Type_ecu": "Denso SH1 & Denso SH12 & Denso SH14 & Denso SH9V & Denso SH15 & Denso SH06",
                "Engine_code": "SH-VPTS",
            },
            "2.2 SkyActiv-D 175hp": {
                "std_power": 175,
                "tuned_power": 200,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2191,
                "Type_ecu": "Denso SH1 & Denso SH9 & Denso SH14 & Denso SH72543 & Denso SH9V & Denso SH15 & Denso SH12 & Denso SH06",
                "Engine_code": "SH-VPTS",
            },
            "2.0 SkyActiv-G 145hp": {
                "std_power": 145,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 1998,
                "Type_ecu": "Denso P5",
                "Engine_code": "PE-VPS",
            },
            "2.0 SkyActiv-G 165hp": {
                "std_power": 165,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso SH CRPT3 & Denso SH72543",
                "Engine_code": "PE-VPS",
            },
            "2.5 SkyActiv-G 192hp": {
                "std_power": 192,
                "tuned_power": 205,
                "std_torque": 256,
                "tuned_torque": 270,
                "cc": 2488,
                "Type_ecu": "Denso SH72543 & Denso PYFA",
                "Engine_code": "PY-VPS",
            },
        },
    },
    "BT-50": {
        "All": {
            "2.2 TDCI 155hp": {
                "std_power": 155,
                "tuned_power": 185,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "",
            },
            "2.5 CiTD 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 330,
                "tuned_torque": 420,
                "cc": 2500,
                "Type_ecu": "Bosch EDC16C7-7 & Siemens/Continental SID208",
                "Engine_code": "WLAA",
            },
            "3.0D 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.2 TDCi 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 470,
                "tuned_torque": 550,
                "cc": 3198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "P5AT",
            },
        },
    },
    "CX-3": {
        "2015 - 2018": {
            "1.5 SkyActiv-D 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Denso S5 & Denso SH72546",
                "Engine_code": "S5DPTS",
            },
            "2.0 Skyactiv-G 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 210,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso SH72543 & Denso P5.0SC",
                "Engine_code": "PE-VPS",
            },
            "2.0 Skyactiv-G 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 204,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso SH72543 & Denso Pxxx",
                "Engine_code": "PE-VPS",
            },
            "2.5 Skyactiv-G 192hp": {
                "std_power": 192,
                "tuned_power": 205,
                "std_torque": 256,
                "tuned_torque": 272,
                "cc": 2488,
                "Type_ecu": "",
                "Engine_code": "PY-VPS",
            },
        },
        "2018 ->": {
            "1.8 SkyActiv-D 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1759,
                "Type_ecu": "Denso SH9",
                "Engine_code": "",
            },
            "2.0 Skyactiv-G 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 210,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso Gen4 & Denso P5 & Denso PYFB",
                "Engine_code": "PE-VPS",
            },
            "2.0 Skyactiv-G 150hp": {
                "std_power": 150,
                "tuned_power": 161,
                "std_torque": 204,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso SH72543",
                "Engine_code": "PE-VPS",
            },
        },
    },
    "CX-30": {
        "2019 ->": {
            "1.8 Skyactiv-D 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1759,
                "Type_ecu": "Denso SH9V",
                "Engine_code": "",
            },
            "2.0 Skyactiv-G 122hp": {
                "std_power": 122,
                "tuned_power": 165,
                "std_torque": 213,
                "tuned_torque": 225,
                "cc": 1998,
                "Type_ecu": "Denso Pxxx",
                "Engine_code": "",
            },
            "2.0 Skyactiv-X 180hp": {
                "std_power": 180,
                "tuned_power": 190,
                "std_torque": 224,
                "tuned_torque": 235,
                "cc": 1998,
                "Type_ecu": "Denso MPC5746",
                "Engine_code": "",
            },
            "2.0 e-Skyactiv X 186hp": {
                "std_power": 186,
                "tuned_power": 196,
                "std_torque": 240,
                "tuned_torque": 250,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "CX-5": {
        "2012 - 2016": {
            "2.2 SkyActiv-D 150hp": {
                "std_power": 150,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 480,
                "cc": 2191,
                "Type_ecu": "Denso SH12 & Denso SH01 & Denso SH13 & Denso R2AX & Denso SH72543 & Denso 275700-9541 & Denso 275700",
                "Engine_code": "SH-VPTS",
            },
            "2.0 SkyActiv-G 145hp": {
                "std_power": 145,
                "tuned_power": 175,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "PE-VPS",
            },
            "2.0 SkyActiv-G 165hp": {
                "std_power": 165,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 220,
                "cc": 1997,
                "Type_ecu": "Denso Pxxx",
                "Engine_code": "PE-VPS",
            },
            "2.5 SkyActiv-G 192hp": {
                "std_power": 192,
                "tuned_power": 205,
                "std_torque": 256,
                "tuned_torque": 270,
                "cc": 2488,
                "Type_ecu": "Denso SH CRPT3",
                "Engine_code": "PYY1",
            },
        },
        "2017 ->": {
            "2.2 SkyActiv-D 175hp": {
                "std_power": 175,
                "tuned_power": 200,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2191,
                "Type_ecu": "Denso SH12 & Denso SH72543 & Denso SH9N",
                "Engine_code": "SH-VPTS",
            },
            "2.2 SkyActiv-D 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2191,
                "Type_ecu": "Denso SH12 & Denso SH9V & Denso SH9N & Denso SH72543",
                "Engine_code": "SH-VPTS",
            },
            "2.2 SkyActiv-D 184hp": {
                "std_power": 184,
                "tuned_power": 200,
                "std_torque": 445,
                "tuned_torque": 460,
                "cc": 2191,
                "Type_ecu": "Denso SH9V & Denso SH9V",
                "Engine_code": "",
            },
            "2.0 SkyActiv-G 165hp": {
                "std_power": 165,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 220,
                "cc": 1997,
                "Type_ecu": "Denso Gen4",
                "Engine_code": "PE-VPS",
            },
            "2.0 SkyActiv-G 160hp": {
                "std_power": 160,
                "tuned_power": 170,
                "std_torque": 208,
                "tuned_torque": 220,
                "cc": 1997,
                "Type_ecu": "Denso Renesas",
                "Engine_code": "",
            },
            "2.5 SkyActiv-G 194hp": {
                "std_power": 194,
                "tuned_power": 215,
                "std_torque": 258,
                "tuned_torque": 280,
                "cc": 2488,
                "Type_ecu": "Mitsubishi Gen4 & Denso Pxxx",
                "Engine_code": "PY-VPS",
            },
            "2.5 SkyActiv-G 250hp": {
                "std_power": 250,
                "tuned_power": 279,
                "std_torque": 420,
                "tuned_torque": 468,
                "cc": 2488,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "CX-60": {
        "2022 -> ...": {
            "3.3 e-SkyActiv-D 254hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.3 e-SkyActiv-D 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "CX-7": {
        "2006 - 2009": {
            "2.3 Turbo MPS 260hp": {
                "std_power": 260,
                "tuned_power": 295,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2261,
                "Type_ecu": "Mitsubishi E6T5x",
                "Engine_code": "MZR-DISI",
            },
        },
        "2009 - 2012": {
            "2.2 CiTD 173hp": {
                "std_power": 173,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2184,
                "Type_ecu": "Denso R2AX & Denso SH7059",
                "Engine_code": "MZR-CD",
            },
            "2.3 Turbo MPS 260hp": {
                "std_power": 260,
                "tuned_power": 295,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2261,
                "Type_ecu": "Mitsubishi E6T5x",
                "Engine_code": "MZR-DISI",
            },
        },
    },
    "CX-9": {
        "2016 ->": {
            "2.5 SkyActiv-G 230hp": {
                "std_power": 230,
                "tuned_power": 260,
                "std_torque": 420,
                "tuned_torque": 492,
                "cc": 2488,
                "Type_ecu": "Denso MB_MH8501_GGD & Mitsubishi E6T6x",
                "Engine_code": "",
            },
        },
    },
    "MPV": {
        "All": {
            "2.0 CiTD 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "2.0 MZR-CD",
            },
        },
    },
    "MX-5": {
        "2005 - 2015": {
            "1.8i 16v 126hp": {
                "std_power": 126,
                "tuned_power": 136,
                "std_torque": 167,
                "tuned_torque": 182,
                "cc": 1798,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "BP-ZE",
            },
            "2.0i 16v 160hp": {
                "std_power": 160,
                "tuned_power": 172,
                "std_torque": 188,
                "tuned_torque": 203,
                "cc": 1999,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "LF",
            },
        },
        "2015 ->": {
            "1.5 SkyActiv-G 131hp": {
                "std_power": 131,
                "tuned_power": 141,
                "std_torque": 150,
                "tuned_torque": 158,
                "cc": 1496,
                "Type_ecu": "Denso SH27522 & Denso P5",
                "Engine_code": "PE-VPS",
            },
            "2.0 SkyActiv-G 160hp": {
                "std_power": 160,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso 27522 & Denso SH7058 & Denso SH72543",
                "Engine_code": "P5-VPS",
            },
            "2.0 SkyActiv-G 184hp": {
                "std_power": 184,
                "tuned_power": 192,
                "std_torque": 205,
                "tuned_torque": 217,
                "cc": 1998,
                "Type_ecu": "Denso SH27522",
                "Engine_code": "",
            },
        },
    },
    "RX-8": {
        "All": {
            "1.3 192hp": {
                "std_power": 192,
                "tuned_power": 207,
                "std_torque": 216,
                "tuned_torque": 236,
                "cc": 1308,
                "Type_ecu": "Denso N3J1",
                "Engine_code": "13B",
            },
            "1.3 231hp": {
                "std_power": 231,
                "tuned_power": 246,
                "std_torque": 216,
                "tuned_torque": 236,
                "cc": 1308,
                "Type_ecu": "Denso N3J1 & Denso SH7055",
                "Engine_code": "13B",
            },
        },
    },
},
"McLaren": {
    "540C": {
        "All": {
            "3.8 V8 540hp": {
                "std_power": 540,
                "tuned_power": 620,
                "std_torque": 540,
                "tuned_torque": 680,
                "cc": 3799,
                "Type_ecu": "Bosch MED17.8.3",
                "Engine_code": "",
            },
        },
    },
    "570 S / Sprint / GT4": {
        "All": {
            "3.8 V8 570hp": {
                "std_power": 570,
                "tuned_power": 620,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 3799,
                "Type_ecu": "Bosch MED17.8.3",
                "Engine_code": "38JBAE",
            },
        },
    },
    "570GT": {
        "All": {
            "3.8 V8 570hp": {
                "std_power": 570,
                "tuned_power": 620,
                "std_torque": 600,
                "tuned_torque": 680,
                "cc": 3799,
                "Type_ecu": "Bosch ME17.8.32",
                "Engine_code": "38JBAE",
            },
        },
    },
    "600LT": {
        "2018 ->": {
            "3.8 V8 600hp": {
                "std_power": 600,
                "tuned_power": 660,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 3799,
                "Type_ecu": "Bosch MED17.8.32",
                "Engine_code": "M838TE",
            },
        },
    },
    "620R": {
        "2020 -> ...": {
            "3.8 V8 620hp": {
                "std_power": 620,
                "tuned_power": 660,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 3799,
                "Type_ecu": "Bosch ME17.8.3",
                "Engine_code": "M838TE",
            },
        },
    },
    "625C": {
        "2014 ->": {
            "3.8 V8 625hp": {
                "std_power": 625,
                "tuned_power": 680,
                "std_torque": 610,
                "tuned_torque": 700,
                "cc": 3799,
                "Type_ecu": "",
                "Engine_code": "M838T",
            },
        },
    },
    "650S": {
        "All": {
            "3.8 V8 650hp": {
                "std_power": 650,
                "tuned_power": 700,
                "std_torque": 678,
                "tuned_torque": 740,
                "cc": 3799,
                "Type_ecu": "Bosch MED17.8.3.2",
                "Engine_code": "38JBAA",
            },
        },
    },
    "675LT": {
        "All": {
            "3.8 V8 675hp": {
                "std_power": 675,
                "tuned_power": 700,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 3799,
                "Type_ecu": "Bosch ME17.8.3",
                "Engine_code": "38JBAC",
            },
        },
    },
    "720S": {
        "2017 ->": {
            "4.0 V8 720hp": {
                "std_power": 720,
                "tuned_power": 820,
                "std_torque": 770,
                "tuned_torque": 880,
                "cc": 3994,
                "Type_ecu": "Bosch ME17.8.33",
                "Engine_code": "MB40T",
            },
        },
    },
    "765LT": {
        "2020 -> ...": {
            "4.0 V8 765hp": {
                "std_power": 765,
                "tuned_power": 820,
                "std_torque": 800,
                "tuned_torque": 880,
                "cc": 3994,
                "Type_ecu": "Bosch ME17.8.33",
                "Engine_code": "M840T",
            },
        },
    },
    "Artura": {
        "2021 -> ...": {
            "3.0 V6 Bi-Turbo 680hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "GT": {
        "2019 -> ...": {
            "4.0 V8 620hp": {
                "std_power": 620,
                "tuned_power": 820,
                "std_torque": 630,
                "tuned_torque": 880,
                "cc": 3994,
                "Type_ecu": "Bosch ME17.8.33",
                "Engine_code": "M840TE",
            },
        },
    },
    "MP4-12C": {
        "All": {
            "3.8 V8 625hp": {
                "std_power": 625,
                "tuned_power": 680,
                "std_torque": 600,
                "tuned_torque": 700,
                "cc": 3799,
                "Type_ecu": "Bosch ME17.8.32",
                "Engine_code": "38JBAA",
            },
        },
    },
},
"Mercedes-Benz": {
    "A": {
        "W168 - 2001 - 2004": {
            "160 CDI 75hp": {
                "std_power": 75,
                "tuned_power": 105,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1689,
                "Type_ecu": "Bosch EDC15C0",
                "Engine_code": "OM668 DE 17 LA red.",
            },
            "160 CDI 60hp": {
                "std_power": 60,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1689,
                "Type_ecu": "Bosch EDC15C0 & Bosch EDC15C5",
                "Engine_code": "OM668 DE 17 A red.",
            },
            "170 CDI 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 180,
                "tuned_torque": 230,
                "cc": 1689,
                "Type_ecu": "Bosch EDC15C0",
                "Engine_code": "OM668 DE 17 LA",
            },
            "170 CDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 180,
                "tuned_torque": 230,
                "cc": 1689,
                "Type_ecu": "Bosch EDC15C0",
                "Engine_code": "OM668 DE 17 A",
            },
        },
        "W169 - 2004 - 2012": {
            "160 CDI 82hp": {
                "std_power": 82,
                "tuned_power": 139,
                "std_torque": 180,
                "tuned_torque": 310,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C32 & Bosch EDC17C43 & Bosch EDC16CP31",
                "Engine_code": "M169.006",
            },
            "180 CDI 109hp": {
                "std_power": 109,
                "tuned_power": 139,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C32 & Bosch EDC16C31 & Bosch EDC17C43",
                "Engine_code": "M169.306",
            },
            "180 CDI 109hp (-<2009)": {
                "std_power": 109,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C32",
                "Engine_code": "M169.306",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C32",
                "Engine_code": "OM651",
            },
            "150 - 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 140,
                "tuned_torque": 155,
                "cc": 1498,
                "Type_ecu": "Siemens/Continental SIM266",
                "Engine_code": "M226.920",
            },
            "200 Turbo 193hp": {
                "std_power": 193,
                "tuned_power": 225,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 2034,
                "Type_ecu": "Siemens/Continental SIM266",
                "Engine_code": "M266.980",
            },
        },
        "W176 - 2012 - 2015": {
            "160 CDI 90hp": {
                "std_power": 90,
                "tuned_power": 135,
                "std_torque": 220,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310",
                "Engine_code": "OM607 DE 15 LA",
            },
            "180 CDI (1500cc) 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310",
                "Engine_code": "OM607 DE 15 LA",
            },
            "180 CDI (1800cc) 109hp": {
                "std_power": 109,
                "tuned_power": 165,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Delphi CRD3.40",
                "Engine_code": "M651.901",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Delphi CRD3.60 & Delphi CRD3.40 & Delphi CRD3.10",
                "Engine_code": "OM651",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3",
                "Engine_code": "OM651",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3",
                "Engine_code": "OM646.963",
            },
            "180 CGi 122hp": {
                "std_power": 122,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS2-FDCT",
                "Engine_code": "M270 DE 16 Al. Red.",
            },
            "200 CGi 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 16 AL",
            },
            "220 - 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.x.x",
                "Engine_code": "M270 DE 20 AL",
            },
            "250 Turbo 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS-FDCT",
                "Engine_code": "M270 DE 20 AL",
            },
            "45 AMG 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS-FDCT & Getrag VGS2-FDCT",
                "Engine_code": "M133 DE 20 AL",
            },
        },
        "W176 - 2015 - 2018": {
            "160 CDI (1500ccc) 90hp": {
                "std_power": 90,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310",
                "Engine_code": "OM607 DE 15 LA",
            },
            "180 CDI (1500ccc) 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310 & Delphi DCM3.7",
                "Engine_code": "OM607 DE 15 LA",
            },
            "200 CDI 4MATIC 136hp": {
                "std_power": 136,
                "tuned_power": 200,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.30",
                "Engine_code": "OM 651 DE 22 LA red",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.30 & Delphi CRD3.E1 & Delphi CRD3.F1",
                "Engine_code": "OM651 DE 22 LA",
            },
            "220 CDI 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.x & Delphi CRD2.x Nexus",
                "Engine_code": "OM651 DE 22 LA",
            },
            "220 - 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "",
                "Engine_code": "M270 DE 20 AL",
            },
            "160 (1.6T) 102hp": {
                "std_power": 102,
                "tuned_power": 175,
                "std_torque": 180,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270.910",
            },
            "180 (1.6T) 122hp": {
                "std_power": 122,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 16 Al red.",
            },
            "200 (1.6T) 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS2-FDCT",
                "Engine_code": "M270 DE 16 Al",
            },
            "250 (2.0T) 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 20 AL",
            },
            "250 Sport (2.0T) 218hp": {
                "std_power": 218,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Bosch MED17.7.7",
                "Engine_code": "M270 DE 20 AL",
            },
            "45 AMG (2.0T) 381hp": {
                "std_power": 381,
                "tuned_power": 400,
                "std_torque": 475,
                "tuned_torque": 520,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS2-FDCT",
                "Engine_code": "M133 DE 20 AL",
            },
        },
        "W177 - 2018 ->": {
            "160d 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 330,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "180d (1.5D) 116hp": {
                "std_power": 116,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "OM608.915",
            },
            "180d (2.0D) 116hp": {
                "std_power": 116,
                "tuned_power": 175,
                "std_torque": 260,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "OM 654",
            },
            "200d 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654 DE20",
            },
            "220d 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001 & Bosch MD1CS006",
                "Engine_code": "OM654",
            },
            "160 (1.3T) 109hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "180 (1.3T) 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "200 (1.3T) 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "220 (2.0T) 190hp": {
                "std_power": 190,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7 & Getrag VGS2-FDCT",
                "Engine_code": "M260 DE20 LA",
            },
            "250 (2.0T) 224hp": {
                "std_power": 224,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M260 DE20 LA",
            },
            "35 AMG 306hp": {
                "std_power": 306,
                "tuned_power": 330,
                "std_torque": 400,
                "tuned_torque": 490,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7 & Getrag VGS2-FDCT",
                "Engine_code": "M260.920",
            },
            "45 AMG S 421hp": {
                "std_power": 421,
                "tuned_power": 470,
                "std_torque": 500,
                "tuned_torque": 620,
                "cc": 1991,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M139",
            },
            "45 AMG 387hp": {
                "std_power": 387,
                "tuned_power": 470,
                "std_torque": 480,
                "tuned_torque": 620,
                "cc": 1991,
                "Type_ecu": "Bosch MG1CP002 & Getrag VGS2-FDCT",
                "Engine_code": "M139",
            },
        },
    },
    "AMG GT 4-door Coupe": {
        "2018 ->": {
            "43 AMG 3.0 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 620,
                "cc": 2999,
                "Type_ecu": "Bosch MG1CP002 & Getrag VGSNAG3",
                "Engine_code": "M256",
            },
            "53 AMG 3.0 435hp": {
                "std_power": 435,
                "tuned_power": 480,
                "std_torque": 520,
                "tuned_torque": 680,
                "cc": 2999,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M256",
            },
            "63 AMG 4.0 V8 Bi-Turbo 585hp": {
                "std_power": 585,
                "tuned_power": 650,
                "std_torque": 800,
                "tuned_torque": 850,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M177.980",
            },
            "63 AMG S 4.0 V8 Bi-Turbo 640hp": {
                "std_power": 640,
                "tuned_power": 680,
                "std_torque": 900,
                "tuned_torque": 1000,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5 & Getrag VGSNAG3",
                "Engine_code": "M177.980",
            },
        },
    },
    "B": {
        "W245 - 2005 - 2011": {
            "180 CDI 109hp": {
                "std_power": 109,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C32 & Bosch EDC17C43 & Bosch EDC16CP31 & Siemens/Continental SID307",
                "Engine_code": "M640.940",
            },
            "200 CDI 136/140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C32 & Bosch EDC17C43 & Bosch EDC16C31",
                "Engine_code": "M640.941",
            },
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.x",
                "Engine_code": "",
            },
            "200 Turbo 193hp": {
                "std_power": 193,
                "tuned_power": 225,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 2034,
                "Type_ecu": "Siemens/Continental SIM266",
                "Engine_code": "M266.980",
            },
        },
        "W246 - 2012 - 2014": {
            "160 CDI 90hp": {
                "std_power": 90,
                "tuned_power": 135,
                "std_torque": 220,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310",
                "Engine_code": "OM607 DE 15 LA",
            },
            "180 CDI (1500cc) 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310 & Delphi CRD3",
                "Engine_code": "OM607 DE 15 LA",
            },
            "180 CDI (1800cc) 109hp": {
                "std_power": 109,
                "tuned_power": 165,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Delphi CRD3 & Delphi CRD3.40",
                "Engine_code": "OM651 DE 18 LA red.",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1796,
                "Type_ecu": "Delphi CRD3 & Getrag VGS2-FDCT",
                "Engine_code": "OM651 DE 18 LA",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3",
                "Engine_code": "OM651 DE 22 LA",
            },
            "160 (1.6T) 102hp": {
                "std_power": 102,
                "tuned_power": 175,
                "std_torque": 180,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270.910",
            },
            "180 CGi 122hp": {
                "std_power": 122,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270.910",
            },
            "200 CGi 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 20 AL",
            },
            "220 CGI 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 20 AL",
            },
            "250 CGI 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 20 AL",
            },
        },
        "W246 - 2014 - 2018": {
            "160 CDI 90hp": {
                "std_power": 90,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310",
                "Engine_code": "OM607 DE 15 LA",
            },
            "180 CDI (1500cc) 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310",
                "Engine_code": "OM607 DE 15 LA",
            },
            "180 CDI (1800cc) 109hp": {
                "std_power": 109,
                "tuned_power": 165,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Bosch EDC17C43",
                "Engine_code": "OM651 DE 18 LA red.",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1796,
                "Type_ecu": "Delphi CRD2.x & Delphi CRD3.x",
                "Engine_code": "OM651 DE 18 LA",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x & Delphi CRD3",
                "Engine_code": "OM651 DE 22 LA",
            },
            "160 (1.6T) 102hp": {
                "std_power": 102,
                "tuned_power": 175,
                "std_torque": 180,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "OM607 DE 16 AL red.",
            },
            "180 CGi 122hp": {
                "std_power": 122,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "OM270 DE 16 AL red.",
            },
            "200 CGi 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 20 AL",
            },
            "220 CGI 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270.920",
            },
            "250 CGI 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 20 AL",
            },
        },
        "W247 - 2018 - 2022": {
            "180d 116hp": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310 & Bosch MD1CS006 & Bosch MD1CP001",
                "Engine_code": "OM654",
            },
            "200d - 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "220d 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "160 (1.3T) 109hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "180 (1.3T) 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "200 (1.3T) 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "220 (2.0T) 190hp": {
                "std_power": 190,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M260 DE20 LA",
            },
            "250 (2.0T) 224hp": {
                "std_power": 224,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M260 DE20 LA",
            },
            "250e 218hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "C": {
        "W202 - 1997 - 2000": {
            "200 CDI 102hp": {
                "std_power": 102,
                "tuned_power": 151,
                "std_torque": 235,
                "tuned_torque": 347,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15C0",
                "Engine_code": "M611.960",
            },
            "220 CDI 125hp": {
                "std_power": 125,
                "tuned_power": 151,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15C0",
                "Engine_code": "M611.960",
            },
            "230 K - 193hp": {
                "std_power": 193,
                "tuned_power": 205,
                "std_torque": 280,
                "tuned_torque": 295,
                "cc": 2295,
                "Type_ecu": "Bosch ME2.1 & Bosch MSA & Siemens/Continental SIM4LKE",
                "Engine_code": "M111.975",
            },
        },
        "W203 - 2000 - 2004": {
            "200 CDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15C6 & Bosch EDC16C2",
                "Engine_code": "M611.962",
            },
            "220 CDI 143hp": {
                "std_power": 143,
                "tuned_power": 176,
                "std_torque": 315,
                "tuned_torque": 380,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15C6 & Bosch EDC16C2",
                "Engine_code": "OM611.962",
            },
            "270 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 2685,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM612.962",
            },
            "270 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2685,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM612.962",
            },
            "3.0 CDI AMG 231hp": {
                "std_power": 231,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2950,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM612.990",
            },
            "180 K - 143hp": {
                "std_power": 143,
                "tuned_power": 155,
                "std_torque": 220,
                "tuned_torque": 240,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM4LKE",
                "Engine_code": "M111 E18",
            },
            "200 K - 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 280,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM4LKE",
                "Engine_code": "M271.940",
            },
            "240 V6 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 240,
                "tuned_torque": 255,
                "cc": 2597,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M112 E26",
            },
            "32 AMG 354hp": {
                "std_power": 354,
                "tuned_power": 375,
                "std_torque": 450,
                "tuned_torque": 480,
                "cc": 3199,
                "Type_ecu": "Bosch ME2.8.1",
                "Engine_code": "M112.961",
            },
        },
        "W203 - 2004 - 2007": {
            "200 CDI 122hp": {
                "std_power": 122,
                "tuned_power": 175,
                "std_torque": 270,
                "tuned_torque": 380,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2 & Bosch EDC16C31 & Delphi DCM3.2",
                "Engine_code": "OM646.962",
            },
            "200 CDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15C6 & Bosch EDC16C2",
                "Engine_code": "M111",
            },
            "220 CDI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C2",
                "Engine_code": "OM646.963",
            },
            "220 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 315,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15C6 & Bosch EDC16C31 & Bosch EDC16C2",
                "Engine_code": "OM611 DE22LA",
            },
            "270 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2685,
                "Type_ecu": "Bosch EDC15C6 & Bosch EDC16C31",
                "Engine_code": "OM612.962",
            },
            "3.0 CDI AMG 231hp": {
                "std_power": 231,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2950,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM612.990",
            },
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "180 K - 143hp": {
                "std_power": 143,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 270,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM4LKE",
                "Engine_code": "M271.946",
            },
            "200 K - 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 280,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM4LKE",
                "Engine_code": "M271.940",
            },
            "230 K - 192hp": {
                "std_power": 192,
                "tuned_power": 225,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM4LKE & Bosch ME9.7",
                "Engine_code": "M271.948",
            },
            "55 AMG 367hp": {
                "std_power": 367,
                "tuned_power": 385,
                "std_torque": 510,
                "tuned_torque": 535,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M113.988",
            },
        },
        "W204 - 2007 - 2010": {
            "180 CDI 120hp": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 300,
                "tuned_torque": 440,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi CRD2",
                "Engine_code": "M651.913",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 270,
                "tuned_torque": 450,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi DCM3.2 & Delphi CRD2 & Delphi CRD. 11",
                "Engine_code": "OM646",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2148,
                "Type_ecu": "Delphi CRD2.x & Delphi DCM3.2 & Delphi CRD. 11 & Delphi CRD3.40 & Delphi CRD3.10",
                "Engine_code": "OM646",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2148,
                "Type_ecu": "Delphi DCM3.2 & Delphi CRD2.x & Delphi CRD. 11",
                "Engine_code": "OM646",
            },
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi CRD2.x",
                "Engine_code": "OM646",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01",
                "Engine_code": "OM642",
            },
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01 & Bosch EDC16 & Getrag VGS2-FDCT & Siemens/Continental VGS3-NAG2",
                "Engine_code": "OM642",
            },
            "350 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 620,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01",
                "Engine_code": "OM642",
            },
            "350 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "",
                "Engine_code": "OM642",
            },
            "350 CDI 231hp": {
                "std_power": 231,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01 & Bosch EDC17CP10",
                "Engine_code": "OM642",
            },
            "180 Kompressor (1600cc) 156hp": {
                "std_power": 156,
                "tuned_power": 171,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 1597,
                "Type_ecu": "Siemens/Continental SIM271DE & Siemens/Continental SIM271KE",
                "Engine_code": "M271 KE 16 ML",
            },
            "180 Kompressor (1800cc) 156hp": {
                "std_power": 156,
                "tuned_power": 176,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM271KE & Siemens/Continental SIM271DE",
                "Engine_code": "M271 KE 18 ML",
            },
            "200 Kompressor (1800cc) 184hp": {
                "std_power": 184,
                "tuned_power": 204,
                "std_torque": 250,
                "tuned_torque": 270,
                "cc": 1796,
                "Type_ecu": "Bosch ME9.7 & Siemens/Continental SIM271KE",
                "Engine_code": "M271 KE 18 ML",
            },
            "250 CGI 204hp": {
                "std_power": 204,
                "tuned_power": 250,
                "std_torque": 310,
                "tuned_torque": 357,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM271DE & Bosch ME9.7 & Siemens/Continental SIM271DE20",
                "Engine_code": "M271",
            },
            "350 CGI 292hp": {
                "std_power": 292,
                "tuned_power": 312,
                "std_torque": 365,
                "tuned_torque": 390,
                "cc": 3498,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M272 E30",
            },
            "350i 272hp": {
                "std_power": 272,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 375,
                "cc": 3498,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M272 E35",
            },
            "63 AMG PPP 487hp": {
                "std_power": 487,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M159",
            },
            "63 AMG 457hp": {
                "std_power": 457,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M156",
            },
        },
        "W204 - 2010 - 2014": {
            "180 CDI 120hp": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 310,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi CRD2",
                "Engine_code": "OM646",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 450,
                "cc": 2143,
                "Type_ecu": "Delphi DCM3.1 & Delphi CRD3.10 & Delphi CRD2",
                "Engine_code": "OM646",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x & Delphi CRD3.x & Delphi CRD3P.A0",
                "Engine_code": "OM646",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi CRD2 & Delphi CRD3P.A0 & Delphi DCM3.2 & Delphi CRD3.7A & Delphi CRD3.5 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "OM646",
            },
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2 & Delphi CRD3.x",
                "Engine_code": "OM646",
            },
            "300 CDI 231hp": {
                "std_power": 231,
                "tuned_power": 280,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01 & Bosch EDC17CP10 & Bosch MED9.7",
                "Engine_code": "OM642.832",
            },
            "350 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP10",
                "Engine_code": "OM642",
            },
            "350 CDI 265hp": {
                "std_power": 265,
                "tuned_power": 295,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP01",
                "Engine_code": "OM642",
            },
            "350 CDI 231hp": {
                "std_power": 231,
                "tuned_power": 290,
                "std_torque": 540,
                "tuned_torque": 630,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01 & Bosch EDC17CP10",
                "Engine_code": "OM642",
            },
            "180 CGI 156hp (1600cc)": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2 & Siemens/Continental SIM271DE & Getrag VGS2-FDCT & Siemens/Continental SIM271KE",
                "Engine_code": "M274.910",
            },
            "180 CGI 156hp (1800cc)": {
                "std_power": 156,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM271DE & Siemens/Continental VGS3-NAG2 & Bosch MED17.7.2",
                "Engine_code": "M271 DE18 EVO",
            },
            "200 CGI 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 270,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Bosch MED17 & Siemens/Continental SIM271DE & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M271 DE18 EVO",
            },
            "250 CGI 204hp": {
                "std_power": 204,
                "tuned_power": 220,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM271DE & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M271 DE18 EVO",
            },
            "350 CGI 292hp": {
                "std_power": 292,
                "tuned_power": 310,
                "std_torque": 365,
                "tuned_torque": 390,
                "cc": 3498,
                "Type_ecu": "Bosch MED9.7 & Bosch MED17.7.3",
                "Engine_code": "M272 E30",
            },
            "350 V6 Blue Efficiency - 306hp": {
                "std_power": 306,
                "tuned_power": 328,
                "std_torque": 370,
                "tuned_torque": 411,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.7.1 & Bosch MED17.7.3",
                "Engine_code": "M276.957",
            },
            "63 AMG Black Series 517hp": {
                "std_power": 517,
                "tuned_power": 540,
                "std_torque": 620,
                "tuned_torque": 650,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M156",
            },
            "63 AMG Edition 507": {
                "std_power": 507,
                "tuned_power": 515,
                "std_torque": 610,
                "tuned_torque": 650,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M156",
            },
            "63 AMG 457hp": {
                "std_power": 457,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M156",
            },
        },
        "W205 - 2014 - 2018": {
            "180 CDI (1600cc) 116hp": {
                "std_power": 116,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C66 & Bosch MD1CS006",
                "Engine_code": "R9M",
            },
            "180 CDI (2100cc) 120hp": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 300,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3P.A0",
                "Engine_code": "M651.913",
            },
            "200 CDI (1600cc) 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C66",
                "Engine_code": "R9M",
            },
            "200 CDI (2100cc) 136hp": {
                "std_power": 136,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi CRD3P.D1 & Bosch EDC17C66 & Delphi CRD3P.A0 & Delphi DCM3.7 & Delphi CRD3.F1",
                "Engine_code": "M651.921",
            },
            "200d 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 360,
                "tuned_torque": 400,
                "cc": 1597,
                "Type_ecu": "Bosch MED17 & Delphi CRD3",
                "Engine_code": "",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57 & Delphi CRD3P.A0 & Delphi CRD3P.D1 & Delphi DCM3.5 & Delphi CRD3.10 & Delphi CRD3.S2 & Delphi CRD3.40",
                "Engine_code": "OM651.921",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Delphi DCM3.1 & Delphi CRD3P.D1 & Delphi CRD3P.A0",
                "Engine_code": "OM651.921",
            },
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57 & Delphi CRD3P.A0 & Delphi CRD3P.D1 & Delphi CRD3.S2 & Delphi CRD3.10 & Delphi CRD3.7A",
                "Engine_code": "OM646",
            },
            "300 CDI Hybrid 231hp": {
                "std_power": 231,
                "tuned_power": 260,
                "std_torque": 550,
                "tuned_torque": 610,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57",
                "Engine_code": "OM651.921",
            },
            "160 (1.6T) 129hp": {
                "std_power": 129,
                "tuned_power": 175,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2 & Bosch MED17.7.7",
                "Engine_code": "M274 E16",
            },
            "180 (1600cc) 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270.910",
            },
            "200 - 184hp": {
                "std_power": 184,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Bosch MED17.7.7 & Siemens/Continental SIM271DE & Siemens/Continental VGS4-NAG2",
                "Engine_code": "M274.920",
            },
            "250 - 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Siemens/Continental SIM271DE20",
                "Engine_code": "M274.920",
            },
            "300 - 245hp": {
                "std_power": 245,
                "tuned_power": 265,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Bosch MED17.7.3.1 & Bosch MED17.7.7 & Siemens/Continental VGS4-NAG2",
                "Engine_code": "M274.920",
            },
            "350 e Hybrid 279hp": {
                "std_power": 279,
                "tuned_power": 330,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "400 - 333hp": {
                "std_power": 333,
                "tuned_power": 415,
                "std_torque": 480,
                "tuned_torque": 600,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276 DE 30 LA",
            },
            "43 AMG 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1 & Siemens/Continental VGS3-NAG2 & Getrag VGSNAG3",
                "Engine_code": "M276.823",
            },
            "450 AMG 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 590,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276 DEH 30 LA",
            },
            "63 AMG S 510hp": {
                "std_power": 510,
                "tuned_power": 570,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M177",
            },
            "63 AMG 476hp": {
                "std_power": 476,
                "tuned_power": 570,
                "std_torque": 650,
                "tuned_torque": 850,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5 & Getrag VGSNAG3",
                "Engine_code": "M177",
            },
        },
        "W205 - 2018 - 2020": {
            "160 (1497cc) - 129hp": {
                "std_power": 129,
                "tuned_power": 175,
                "std_torque": 210,
                "tuned_torque": 295,
                "cc": 1497,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M264 E15 DEH LA",
            },
            "300e 320hp": {
                "std_power": 320,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "180d 122hp": {
                "std_power": 122,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1597,
                "Type_ecu": "Bosch MD1CP001 & Bosch EDC17C66",
                "Engine_code": "OM654.916",
            },
            "200d 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 360,
                "tuned_torque": 400,
                "cc": 1597,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.916",
            },
            "200d 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "220d 194hp": {
                "std_power": 194,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "300d 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "300de 306hp": {
                "std_power": 306,
                "tuned_power": 335,
                "std_torque": 700,
                "tuned_torque": 760,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "160 (1600cc) - 129hp": {
                "std_power": 129,
                "tuned_power": 175,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2 & Bosch EDC17C66 & Bosch MED17.7.7",
                "Engine_code": "M274.910",
            },
            "180 (1600cc) - 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.7.2 & Bosch MED17.7.7",
                "Engine_code": "M274.910",
            },
            "200 (1500cc) - 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1497,
                "Type_ecu": "Bosch MED17.7.7 & Bosch MED17.7.2",
                "Engine_code": "M264.915",
            },
            "300 - 258hp": {
                "std_power": 258,
                "tuned_power": 275,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7 & Bosch MED17.7.2",
                "Engine_code": "M264.920",
            },
            "400 - 333hp": {
                "std_power": 333,
                "tuned_power": 415,
                "std_torque": 480,
                "tuned_torque": 600,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.7 & Bosch MED17.7.3.1",
                "Engine_code": "M276.823",
            },
            "43 AMG 390hp": {
                "std_power": 390,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1 & Getrag VGSNAG3",
                "Engine_code": "M276.823",
            },
            "63 AMG S 510hp": {
                "std_power": 510,
                "tuned_power": 570,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5 & Bosch ME9.7 & Siemens/Continental VGS4-NAG2",
                "Engine_code": "M177.980",
            },
            "63 AMG 476hp": {
                "std_power": 476,
                "tuned_power": 570,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M177.980",
            },
        },
        "W206 - 2021 -> ...": {
            "200d (1.6D) 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "220d (2.0D) 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "300d (2.0D) 265hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "43 AMG 408hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "AMG 63 S E Performance 680hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "180 (1.5T) 170hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "200 (1.5T) 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "300 (2.0T) 258hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "300e (2.0T) 313hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "CL": {
        "C215 - 2002 - 2006": {
            "500 306hp": {
                "std_power": 306,
                "tuned_power": 326,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 4966,
                "Type_ecu": "Bosch ME2.0 & Bosch ME2.8",
                "Engine_code": "M113 E50",
            },
            "55 AMG 500hp": {
                "std_power": 500,
                "tuned_power": 540,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8.1",
                "Engine_code": "M113 5.4L",
            },
            "600 500hp": {
                "std_power": 500,
                "tuned_power": 578,
                "std_torque": 800,
                "tuned_torque": 910,
                "cc": 5513,
                "Type_ecu": "Bosch ME2.7.1",
                "Engine_code": "M275.950",
            },
            "65 AMG 612hp": {
                "std_power": 612,
                "tuned_power": 646,
                "std_torque": 1000,
                "tuned_torque": 1100,
                "cc": 5980,
                "Type_ecu": "Bosch ME2.7.1",
                "Engine_code": "M275.982",
            },
        },
        "C216 - 2006 - 2010": {
            "500 388hp": {
                "std_power": 388,
                "tuned_power": 408,
                "std_torque": 530,
                "tuned_torque": 570,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M273.961",
            },
            "600 517hp": {
                "std_power": 517,
                "tuned_power": 585,
                "std_torque": 830,
                "tuned_torque": 910,
                "cc": 5513,
                "Type_ecu": "Bosch ME2.7.2 & Bosch ME9.7",
                "Engine_code": "M275.953",
            },
            "63 AMG 525hp": {
                "std_power": 525,
                "tuned_power": 555,
                "std_torque": 630,
                "tuned_torque": 660,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M156",
            },
            "65 AMG 612hp": {
                "std_power": 612,
                "tuned_power": 646,
                "std_torque": 1000,
                "tuned_torque": 1100,
                "cc": 6980,
                "Type_ecu": "Bosch ME2.7.2",
                "Engine_code": "M275.982",
            },
        },
        "C216 - 2010 ->": {
            "500 435hp": {
                "std_power": 435,
                "tuned_power": 510,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.1 & Bosch MED17.7.3",
                "Engine_code": "M278.920",
            },
            "600 517hp": {
                "std_power": 517,
                "tuned_power": 585,
                "std_torque": 830,
                "tuned_torque": 910,
                "cc": 5513,
                "Type_ecu": "Bosch ME7.2.7",
                "Engine_code": "M275.953",
            },
            "63 AMG PPK 571hp": {
                "std_power": 571,
                "tuned_power": 620,
                "std_torque": 800,
                "tuned_torque": 1050,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M157.980",
            },
            "63 AMG 544hp": {
                "std_power": 544,
                "tuned_power": 620,
                "std_torque": 800,
                "tuned_torque": 1050,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.1",
                "Engine_code": "M157.980",
            },
            "65 AMG 630hp": {
                "std_power": 630,
                "tuned_power": 700,
                "std_torque": 1000,
                "tuned_torque": 1100,
                "cc": 5980,
                "Type_ecu": "Bosch ME2.7.2",
                "Engine_code": "M275.982",
            },
        },
    },
    "CLA": {
        "C117 - 2013 - 2016": {
            "180 CDI (1500cc) 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310 & Siemens/Continental SID307",
                "Engine_code": "OM 607 DE 15 LA",
            },
            "200 CDI (1800cc) 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Delphi CRD3.40 & Delphi CRD3.60",
                "Engine_code": "OM 651 DE 18 LA",
            },
            "200 CDI (2100cc) 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.E1 & Delphi CRD3.30 & Delphi CRD3.F1 & Delphi CRD3.60 & Delphi CRD3.40",
                "Engine_code": "OM651 DE 22 LA red.",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi CRD3.E1 & Delphi CRD3.60",
                "Engine_code": "OM651 DE 22 AL",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.E1 & Delphi CRD3.x",
                "Engine_code": "OM 651 DE 22 AL",
            },
            "220 CDI 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x Nexus & Delphi CRD3.x",
                "Engine_code": "OM651 DE 22 AL",
            },
            "180 CGi 122hp": {
                "std_power": 122,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS2-FDCT",
                "Engine_code": "M270 DE 16 AL red.",
            },
            "200 CGi 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 16 AL",
            },
            "250 CGi 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 20 AL",
            },
            "45 AMG 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 550,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS2-FDCT",
                "Engine_code": "M133 De 20 AL",
            },
        },
        "C117 - 2016 - 2019": {
            "180 CDI (1500cc) 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310",
                "Engine_code": "OM607 DE 15 LA",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x Nexus & Delphi CRD3.x & Delphi CRD3.F1 & Delphi CRD3.E1 & Getrag VGS2-FDCT & Delphi CRD3.30",
                "Engine_code": "OM651 DE 22 LA",
            },
            "220 CDI 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x & Delphi CRD3.x",
                "Engine_code": "OM651 DE 22 AL",
            },
            "180 CGi 122hp": {
                "std_power": 122,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS2-FDCT",
                "Engine_code": "M270 DE 16 AL red.",
            },
            "200 CGi 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 16 AL",
            },
            "220 - 184hp": {
                "std_power": 184,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270.920",
            },
            "250 CGi Sport 218hp": {
                "std_power": 218,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS2-FDCT",
                "Engine_code": "M270 DE 20 AL",
            },
            "250 CGi 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 20 AL",
            },
            "45 AMG 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 475,
                "tuned_torque": 540,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS2-FDCT",
                "Engine_code": "M133 De 20 AL",
            },
        },
        "C118 - 2019 ->": {
            "180d (1.5D) 116hp": {
                "std_power": 116,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "OM608 DE 15 SCR",
            },
            "200d (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654 DE 20 SCR",
            },
            "220d (2.0D) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CS001 & Bosch MD1CP001",
                "Engine_code": "OM654 DE 20 SCR",
            },
            "180 (1.3T) 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "200 (1.3T) 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "220 (2.0T) 190hp": {
                "std_power": 190,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M260 DE 20 AL",
            },
            "250 (2.0T) 224hp": {
                "std_power": 224,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M260 DE 20 AL",
            },
            "250e 218hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "35 AMG 306hp": {
                "std_power": 306,
                "tuned_power": 345,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M260 DE20 LA",
            },
            "45 AMG S 421hp": {
                "std_power": 421,
                "tuned_power": 470,
                "std_torque": 500,
                "tuned_torque": 620,
                "cc": 1991,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M139",
            },
            "45 AMG 387hp": {
                "std_power": 387,
                "tuned_power": 470,
                "std_torque": 480,
                "tuned_torque": 620,
                "cc": 1991,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M139",
            },
        },
    },
    "CLC": {
        "2008 - 2011": {
            "200 CDI 122hp": {
                "std_power": 122,
                "tuned_power": 170,
                "std_torque": 270,
                "tuned_torque": 370,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2 & Bosch EDC16C31",
                "Engine_code": "M646.963",
            },
            "220 CDI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "OM646.963",
            },
            "220 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 315,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "OM646.962",
            },
        },
    },
    "CLE": {
        "2023 -> ...": {
            "200 (2.0T) 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "220d (2.0d) 197hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "300 (2.0T) 258hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "450 (3.0T) 381hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "CLK": {
        "W208 - 1997 - 2002": {
            "270 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 2685,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM612 DE 27 LA",
            },
            "200 K 163hp": {
                "std_power": 163,
                "tuned_power": 175,
                "std_torque": 230,
                "tuned_torque": 245,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM4LKE",
                "Engine_code": "M111.956",
            },
            "230 K 193hp": {
                "std_power": 193,
                "tuned_power": 204,
                "std_torque": 280,
                "tuned_torque": 295,
                "cc": 2295,
                "Type_ecu": "Bosch ME2.1 & Siemens/Continental SIM4LE",
                "Engine_code": "M111.982",
            },
            "320 - 218hp": {
                "std_power": 218,
                "tuned_power": 230,
                "std_torque": 310,
                "tuned_torque": 330,
                "cc": 3199,
                "Type_ecu": "Bosch ME2.0",
                "Engine_code": "M111 E32",
            },
            "430 - 279hp": {
                "std_power": 279,
                "tuned_power": 300,
                "std_torque": 400,
                "tuned_torque": 425,
                "cc": 4266,
                "Type_ecu": "Bosch ME2.0",
                "Engine_code": "M113.943",
            },
            "55 AMG 347hp": {
                "std_power": 347,
                "tuned_power": 367,
                "std_torque": 510,
                "tuned_torque": 535,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M113 E55",
            },
        },
        "W209 - 2003 - 2009": {
            "220 CDI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "OM646 DE 22 LA",
            },
            "220 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "OM 646 DE 22 LA",
            },
            "270 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2685,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM 612 DE 27 LA",
            },
            "270 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2685,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM612 DE 27 LA",
            },
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 275,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642 DE 30 LA",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 275,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM 642 DE 30 LA",
            },
            "200 K 184hp": {
                "std_power": 184,
                "tuned_power": 195,
                "std_torque": 250,
                "tuned_torque": 265,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM4LKE",
                "Engine_code": "M271 E 18 ML",
            },
            "200 K 163hp": {
                "std_power": 163,
                "tuned_power": 175,
                "std_torque": 240,
                "tuned_torque": 255,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM4LKE",
                "Engine_code": "M271 E 18 ML",
            },
            "240 V6 - 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 240,
                "tuned_torque": 255,
                "cc": 2597,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M112 E 26",
            },
            "320 V6 - 218hp": {
                "std_power": 218,
                "tuned_power": 230,
                "std_torque": 310,
                "tuned_torque": 330,
                "cc": 3199,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M112 E 32",
            },
            "500 V8 - 306hp": {
                "std_power": 306,
                "tuned_power": 320,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 4966,
                "Type_ecu": "Bosch ME2.8 & Bosch ME9.7",
                "Engine_code": "M113 E 50",
            },
            "55 AMG 367hp": {
                "std_power": 367,
                "tuned_power": 385,
                "std_torque": 510,
                "tuned_torque": 535,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M113 E 55 EVO",
            },
            "63 AMG Black Series 508hp": {
                "std_power": 508,
                "tuned_power": 530,
                "std_torque": 630,
                "tuned_torque": 660,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M156 E 63",
            },
            "63 AMG 481hp": {
                "std_power": 481,
                "tuned_power": 530,
                "std_torque": 630,
                "tuned_torque": 660,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M156 E 63",
            },
            "CLK500 387hp": {
                "std_power": 387,
                "tuned_power": 407,
                "std_torque": 530,
                "tuned_torque": 550,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7 & Bosch ME2.8",
                "Engine_code": "M273 E 30",
            },
        },
    },
    "CLS": {
        "C218 - 2010 - 2014": {
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Bosch EDC17CP46",
                "Engine_code": "OM651 DE 22 LA",
            },
            "350 CDI Bluetec 252hp": {
                "std_power": 252,
                "tuned_power": 300,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP57",
                "Engine_code": "OM642 LS DE 30 LA",
            },
            "350 CDI 265hp": {
                "std_power": 265,
                "tuned_power": 300,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP01 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "OM642 LS DE 30 LA",
            },
            "500 Bi-Turbo 407hp": {
                "std_power": 407,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 820,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.1 & Bosch MED17.7.3 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M278 DE 46 AL red.",
            },
            "63 AMG Bi-Turbo 525hp": {
                "std_power": 525,
                "tuned_power": 620,
                "std_torque": 700,
                "tuned_torque": 900,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.1",
                "Engine_code": "M157 DE 55 AL",
            },
            "63 AMG PPP 557hp": {
                "std_power": 557,
                "tuned_power": 620,
                "std_torque": 720,
                "tuned_torque": 900,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.1",
                "Engine_code": "M157 DE 55 AL",
            },
        },
        "C218 - 2014 - 2018": {
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3",
                "Engine_code": "M651.924",
            },
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.7A & Bosch EDC17CP46",
                "Engine_code": "M651.924",
            },
            "350 CDI 258hp": {
                "std_power": 258,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 690,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP57 & Bosch EDC17CP46 & Bosch EDC17CP60",
                "Engine_code": "OM642 LS DE 30 LA",
            },
            "400 - 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 480,
                "tuned_torque": 580,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.7 & Bosch MED17.7.3.1 & Bosch EDC17CP46",
                "Engine_code": "M276.850",
            },
            "500 - 408hp": {
                "std_power": 408,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 850,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.1 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "278 DE 46 AL red.",
            },
            "63 AMG S 585hp": {
                "std_power": 585,
                "tuned_power": 660,
                "std_torque": 800,
                "tuned_torque": 1100,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.1 & Bosch MED17.7.3 & Siemens/Continental VGS4-NAG2",
                "Engine_code": "M157 DE 55 AL",
            },
            "63 AMG 557hp": {
                "std_power": 557,
                "tuned_power": 660,
                "std_torque": 720,
                "tuned_torque": 1100,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M157 DE 55 AL",
            },
        },
        "C219 - 2004 - 2010": {
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Getrag VGS2-FDCT",
                "Engine_code": "OM642.920",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16CP31",
                "Engine_code": "OM642.920",
            },
            "350 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 270,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "",
                "Engine_code": "OM642.920",
            },
            "350 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 270,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642.920",
            },
            "350 CGI 292hp": {
                "std_power": 292,
                "tuned_power": 312,
                "std_torque": 365,
                "tuned_torque": 390,
                "cc": 3498,
                "Type_ecu": "Bosch MED9.7",
                "Engine_code": "M272",
            },
            "350i 272hp": {
                "std_power": 272,
                "tuned_power": 292,
                "std_torque": 350,
                "tuned_torque": 375,
                "cc": 3498,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M272.964",
            },
            "500 306hp": {
                "std_power": 306,
                "tuned_power": 326,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 4966,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M113",
            },
            "500 388hp": {
                "std_power": 388,
                "tuned_power": 408,
                "std_torque": 530,
                "tuned_torque": 570,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M273",
            },
            "55 AMG 476hp": {
                "std_power": 476,
                "tuned_power": 515,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8.1 & Siemens/Continental EGS52",
                "Engine_code": "M113 E 55",
            },
            "63 AMG 514hp": {
                "std_power": 514,
                "tuned_power": 530,
                "std_torque": 630,
                "tuned_torque": 660,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M156",
            },
        },
        "C257 - 2018 - 2020": {
            "220d (2.0D) 194hp": {
                "std_power": 194,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "300d (2.0D) 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "350d (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 380,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656.929",
            },
            "400d (3.0D) 340hp": {
                "std_power": 340,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656.929",
            },
            "350 (2.0T) 299hp": {
                "std_power": 299,
                "tuned_power": 350,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M264.920",
            },
            "450 (3.0T) 389hp": {
                "std_power": 389,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2999,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M256",
            },
            "450 (3.0T) 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2999,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M256",
            },
            "53 AMG 435hp": {
                "std_power": 435,
                "tuned_power": 460,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2999,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M256",
            },
        },
        "C257 - 2021 ->": {
            "220d (2.0D) 194hp": {
                "std_power": 194,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1950,
                "Type_ecu": "",
                "Engine_code": "OM654.920",
            },
            "300d (2.0D) 265hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "400d (3.0D) 330hp": {
                "std_power": 330,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656.929",
            },
            "450 (3.0T) 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 620,
                "cc": 2999,
                "Type_ecu": "",
                "Engine_code": "M256.930",
            },
            "53 AMG 435hp": {
                "std_power": 435,
                "tuned_power": 460,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2999,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M256.930",
            },
        },
    },
    "Citan": {
        "2013 - 2018": {
            "108 CDI 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 180,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "OM607 DE 15 LA red.",
            },
            "109 CDI 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Bosch MD1CS006 & Siemens/Continental SID309",
                "Engine_code": "OM607 DE 15 LA",
            },
            "111 CDI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Siemens/Continental SID307 & Siemens/Continental SID310",
                "Engine_code": "OM607 DE 15 LA",
            },
            "112 - 114hp": {
                "std_power": 114,
                "tuned_power": 125,
                "std_torque": 190,
                "tuned_torque": 205,
                "cc": 1461,
                "Type_ecu": "",
                "Engine_code": "M200 DE 12 AL",
            },
        },
        "2019 - 2020": {
            "108 CDI 80hp": {
                "std_power": 80,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 270,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "OM608",
            },
            "109 CDI 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "OM608",
            },
            "111 CDI 116hp": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "OM608",
            },
        },
        "2021 -> ...": {
            "108 CDI 75hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "110 CDI 95hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "112 CDI 116hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "110 (1.3T) 102hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "113 (1.3T) 131hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "E": {
        "2020 ->": {
            "200d (1.6d) 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "220d (2.0d) 194hp": {
                "std_power": 194,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001 & Bosch MED17.7.7",
                "Engine_code": "OM654.920",
            },
            "300de 306hp": {
                "std_power": 306,
                "tuned_power": 335,
                "std_torque": 700,
                "tuned_torque": 760,
                "cc": 1951,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM 654.920",
            },
            "400d (3.0D) 330hp": {
                "std_power": 330,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP006",
                "Engine_code": "OM656 DE 30 LA",
            },
            "300d 265hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "200 (2.0T) 197hp": {
                "std_power": 197,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M264 DE 20 LA",
            },
            "300 (2.0T) 258hp": {
                "std_power": 258,
                "tuned_power": 280,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M264 DE 20 LA",
            },
            "300e 320hp": {
                "std_power": 320,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 750,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "",
            },
            "450 (3.0T) 367hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "53 AMG 435hp": {
                "std_power": 435,
                "tuned_power": 490,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2999,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M256 E30 DEH LA G",
            },
            "63 AMG S 612hp": {
                "std_power": 612,
                "tuned_power": 690,
                "std_torque": 900,
                "tuned_torque": 950,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M177",
            },
            "63 AMG 571hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "W210 - 1998 - 2002": {
            "200 CDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15C0 & Bosch EDC15C6",
                "Engine_code": "OM611 DE 22",
            },
            "220 CDI 143hp": {
                "std_power": 143,
                "tuned_power": 176,
                "std_torque": 315,
                "tuned_torque": 365,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM611 DE 22",
            },
            "220 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 176,
                "std_torque": 315,
                "tuned_torque": 365,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "OM611 DE 22",
            },
            "270 CDI 163-163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 2685,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM612 DE 27",
            },
            "320 CDI 197hp": {
                "std_power": 197,
                "tuned_power": 235,
                "std_torque": 470,
                "tuned_torque": 550,
                "cc": 3222,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM613",
            },
            "200 K 163hp": {
                "std_power": 163,
                "tuned_power": 175,
                "std_torque": 240,
                "tuned_torque": 255,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM4LE & Bosch ME2.1",
                "Engine_code": "M111 E 20",
            },
            "55 AMG 354hp": {
                "std_power": 354,
                "tuned_power": 374,
                "std_torque": 530,
                "tuned_torque": 560,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8 & Bosch ME2.0",
                "Engine_code": "M113 E 50",
            },
        },
        "W211 - 2002 - 2006": {
            "200 CDI 122hp": {
                "std_power": 122,
                "tuned_power": 170,
                "std_torque": 270,
                "tuned_torque": 370,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2 & Bosch EDC16C31",
                "Engine_code": "OM611 DE 22",
            },
            "220 CDI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2 & Delphi DCM3.2",
                "Engine_code": "OM611 DE 22",
            },
            "220 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 315,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16 & Delphi CRD. 11",
                "Engine_code": "OM611 DE 22",
            },
            "270 CDI 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2685,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "OM612 DE 27",
            },
            "280 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 400,
                "tuned_torque": 600,
                "cc": 3222,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM648",
            },
            "280 CDI 177hp": {
                "std_power": 177,
                "tuned_power": 240,
                "std_torque": 425,
                "tuned_torque": 570,
                "cc": 3222,
                "Type_ecu": "Bosch EDC16C2 & Bosch EDC16C31",
                "Engine_code": "OM648",
            },
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 3222,
                "Type_ecu": "Bosch EDC16CP31 & Bosch ME2.8",
                "Engine_code": "OM648",
            },
            "320 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 570,
                "cc": 3222,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "OM648",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 3222,
                "Type_ecu": "Bosch EDC16C0",
                "Engine_code": "OM648",
            },
            "400 CDI 260hp": {
                "std_power": 260,
                "tuned_power": 300,
                "std_torque": 560,
                "tuned_torque": 630,
                "cc": 3996,
                "Type_ecu": "Temic CRV2.0",
                "Engine_code": "OM628",
            },
            "420 CDI 314hp": {
                "std_power": 314,
                "tuned_power": 354,
                "std_torque": 730,
                "tuned_torque": 810,
                "cc": 3996,
                "Type_ecu": "Bosch EDC16CP36",
                "Engine_code": "M629.910",
            },
            "200 K 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 280,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM4LKE",
                "Engine_code": "M111 E 20",
            },
            "240 V6 - 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 255,
                "cc": 2597,
                "Type_ecu": "Bosch M2.8",
                "Engine_code": "M112.913",
            },
            "500 306hp": {
                "std_power": 306,
                "tuned_power": 326,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 4966,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M113 E50",
            },
            "E 55 AMG 476hp": {
                "std_power": 476,
                "tuned_power": 515,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8.1",
                "Engine_code": "M113 5.5L",
            },
        },
        "W211 - 2006 - 2009": {
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 460,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2 & Delphi DCM3.2 & Delphi CRD. 11",
                "Engine_code": "OM651",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2 & Delphi DCM3.2 & Delphi CRD. 11",
                "Engine_code": "OM651",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2 & Delphi DCM3.2 & Delphi CRD. 11 & Delphi CRD2",
                "Engine_code": "OM651",
            },
            "280 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC16C2",
                "Engine_code": "OM642",
            },
            "300 CDI Bluetec 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16+",
                "Engine_code": "OM642",
            },
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC16CP36",
                "Engine_code": "OM642",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "420 CDI 314hp": {
                "std_power": 314,
                "tuned_power": 354,
                "std_torque": 730,
                "tuned_torque": 810,
                "cc": 3996,
                "Type_ecu": "Bosch EDC16CP36",
                "Engine_code": "M629.910",
            },
            "200 K 184hp": {
                "std_power": 184,
                "tuned_power": 204,
                "std_torque": 250,
                "tuned_torque": 270,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM4LKE",
                "Engine_code": "M271.946",
            },
            "500 388hp": {
                "std_power": 388,
                "tuned_power": 408,
                "std_torque": 530,
                "tuned_torque": 570,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M273.960",
            },
            "63 AMG 514hp": {
                "std_power": 514,
                "tuned_power": 530,
                "std_torque": 630,
                "tuned_torque": 660,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M156",
            },
        },
        "W212 - 2009 - 2013": {
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Delphi DCM3.5 & Delphi CRD3 & Delphi CRD2.x & Delphi DCM3.10 & Delphi CRD3.7A & Delphi CRD. 11",
                "Engine_code": "OM651 DE 22 La red.",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2148,
                "Type_ecu": "Delphi CRD2 & Delphi CRD3 & Delphi CRD3.7A & Delphi CRD3.10",
                "Engine_code": "OM651",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2148,
                "Type_ecu": "Delphi CRD3 & Delphi DCM3.2 & Delphi CRD2.x & Delphi DCM3.7",
                "Engine_code": "OM651",
            },
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2 & Delphi CRD3 & Siemens/Continental SIM271DE20",
                "Engine_code": "OM 651 DE 22 La red.",
            },
            "300 CDI Bluetec 231hp": {
                "std_power": 231,
                "tuned_power": 270,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP10 & Bosch EDC17CP01 & Bosch EDC17C57 & Bosch EDC17CP46 & Delphi CRD3.7A & Delphi CRD3.2R",
                "Engine_code": "M642.850",
            },
            "300 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 260,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2 & Delphi CRD3.2R & Bosch EDC17CP10",
                "Engine_code": "M651.924",
            },
            "350 CDI Bluetec 252hp": {
                "std_power": 252,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57 & Bosch EDC17CP10",
                "Engine_code": "M642.852",
            },
            "350 CDI 231hp": {
                "std_power": 231,
                "tuned_power": 280,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP10 & Bosch EDC17CP46 & Siemens/Continental VGS3-NAG2 & Bosch EDC17CP01",
                "Engine_code": "M642.850",
            },
            "350 CDI 265hp": {
                "std_power": 265,
                "tuned_power": 295,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M642.858",
            },
            "350 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP10",
                "Engine_code": "",
            },
            "200 CGi 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 270,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Bosch MED17.7.2 & Siemens/Continental SIM271DE & Siemens/Continental SIM271DE20",
                "Engine_code": "M271 DE18 EVO",
            },
            "250 CGi 204hp": {
                "std_power": 204,
                "tuned_power": 220,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Bosch MED17.7.2 & Siemens/Continental SIM271DE20",
                "Engine_code": "M271 DE18 EVO",
            },
            "300 CGi 252hp": {
                "std_power": 252,
                "tuned_power": 310,
                "std_torque": 340,
                "tuned_torque": 389,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.7.3 & Bosch ME9.7",
                "Engine_code": "M276.952",
            },
            "350 CGi 292hp": {
                "std_power": 292,
                "tuned_power": 310,
                "std_torque": 365,
                "tuned_torque": 389,
                "cc": 3498,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M272.983",
            },
            "350 CGi 272hp": {
                "std_power": 272,
                "tuned_power": 285,
                "std_torque": 360,
                "tuned_torque": 375,
                "cc": 3498,
                "Type_ecu": "Bosch ME9.7 & Bosch MED17.7.1 & Bosch MED17.7.3.1",
                "Engine_code": "M272.934",
            },
            "350 CGi 306hp": {
                "std_power": 306,
                "tuned_power": 310,
                "std_torque": 365,
                "tuned_torque": 389,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.1",
                "Engine_code": "M276.957",
            },
            "500 - 408hp": {
                "std_power": 408,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 820,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.1",
                "Engine_code": "M278.922",
            },
            "500 - 388hp": {
                "std_power": 388,
                "tuned_power": 408,
                "std_torque": 530,
                "tuned_torque": 570,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M273.971",
            },
            "63 AMG Bi-Turbo 525hp": {
                "std_power": 525,
                "tuned_power": 620,
                "std_torque": 700,
                "tuned_torque": 900,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7 & Bosch MED17.7.1 & Bosch MED17.7.3",
                "Engine_code": "M157",
            },
            "63 AMG PPK 557hp": {
                "std_power": 557,
                "tuned_power": 620,
                "std_torque": 720,
                "tuned_torque": 900,
                "cc": 6208,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M156 E 63",
            },
            "63 AMG 525hp": {
                "std_power": 525,
                "tuned_power": 555,
                "std_torque": 630,
                "tuned_torque": 655,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7 & Bosch MED17.7.1",
                "Engine_code": "M156.985",
            },
        },
        "W212 - 2013 - 2016": {
            "200 CDI BlueTEC 136hp": {
                "std_power": 136,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2 & Delphi CRD3 & Delphi CRD3.7A",
                "Engine_code": "OM651 DE 22 La red.",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.7A & Delphi CRD2 & Delphi CRD3.10",
                "Engine_code": "OM651 DE 22 La red.",
            },
            "220 CDI BlueTEC 170hp": {
                "std_power": 170,
                "tuned_power": 250,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.7A & Delphi CRD3P.A0 & Delphi CRD3.10",
                "Engine_code": "OM651 DE 22 La red.",
            },
            "220 CDI BlueTEC 163hp": {
                "std_power": 163,
                "tuned_power": 250,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.7A",
                "Engine_code": "OM651 DE 22 La red.",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi CRD3.7A",
                "Engine_code": "OM651 DE 22 La red.",
            },
            "250 CDI BlueTec 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46 & Delphi CRD3.x & Bosch EDC17CP57",
                "Engine_code": "OM651 DE 22 La red.",
            },
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46 & Delphi CRD3.x & Bosch EDC17CP57 & Delphi CRD3.7A",
                "Engine_code": "OM651 DE 22 La red.",
            },
            "300 CDI BlueTec Hybrid 231hp": {
                "std_power": 231,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP10 & Bosch EDC17CP57 & Bosch CRD3.20 & Bosch EDC17CP46 & Bosch EDC17CP01 & Delphi CRD3.2R & Delphi CRD3.7A",
                "Engine_code": "OM642 DE 30 LA",
            },
            "350 CDI BlueTec 258hp": {
                "std_power": 258,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 690,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57",
                "Engine_code": "OM642 LS DE 30 LA",
            },
            "350 CDI 252hp": {
                "std_power": 252,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 690,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57",
                "Engine_code": "OM642 LS DE 30 LA",
            },
            "200 CGi 184hp": {
                "std_power": 184,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M271 DE 18 EVO",
            },
            "250 CGi 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Bosch EDC17CP57 & Siemens/Continental SIM271DE20",
                "Engine_code": "M274.920",
            },
            "300 CGi 252hp": {
                "std_power": 252,
                "tuned_power": 312,
                "std_torque": 340,
                "tuned_torque": 390,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.1",
                "Engine_code": "M276 DE 35 red.",
            },
            "350 CGi 306hp": {
                "std_power": 306,
                "tuned_power": 320,
                "std_torque": 370,
                "tuned_torque": 390,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.7 & Bosch MED17.7.1",
                "Engine_code": "M276 DE 35",
            },
            "400 CGi 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 480,
                "tuned_torque": 580,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276 DE 30 LA",
            },
            "500 CGi 408hp": {
                "std_power": 408,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 850,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.3 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M278 DE 48 AL red.",
            },
            "63 AMG S 585hp": {
                "std_power": 585,
                "tuned_power": 685,
                "std_torque": 800,
                "tuned_torque": 1100,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M157",
            },
            "63 AMG 558hp": {
                "std_power": 558,
                "tuned_power": 685,
                "std_torque": 720,
                "tuned_torque": 1100,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.1",
                "Engine_code": "M157",
            },
        },
        "W213 - 2016 - 2019": {
            "200d 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "200d 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 360,
                "tuned_torque": 400,
                "cc": 1597,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "220d 194hp": {
                "std_power": 194,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001 & Delphi CRD3.7A & Getrag VGSNAG3",
                "Engine_code": "OM654.920",
            },
            "300d 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 1950,
                "Type_ecu": "Bosch MRD1.41 & Bosch MD1CP001 & Bosch MRD1.76",
                "Engine_code": "OM656.920",
            },
            "300de 306hp": {
                "std_power": 306,
                "tuned_power": 335,
                "std_torque": 700,
                "tuned_torque": 760,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654 D 20 SCR",
            },
            "300e 320hp": {
                "std_power": 320,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 760,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "350d 258hp": {
                "std_power": 258,
                "tuned_power": 300,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP57 & Bosch EDC17CP46 & Getrag VGSNAG3",
                "Engine_code": "M642.855",
            },
            "350d 286hp": {
                "std_power": 286,
                "tuned_power": 380,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 2925,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57 & Bosch MD1CP001",
                "Engine_code": "OM656 DE 30 LA",
            },
            "350e Hybrid 286hp": {
                "std_power": 286,
                "tuned_power": 325,
                "std_torque": 550,
                "tuned_torque": 670,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Bosch MED17.7.7",
                "Engine_code": "M274.920",
            },
            "400d 340hp": {
                "std_power": 340,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656 DE 30 LA",
            },
            "200 (2.0T) 197hp": {
                "std_power": 197,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 450,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Bosch MED17.7.7",
                "Engine_code": "M274.920",
            },
            "200 - 184hp": {
                "std_power": 184,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Bosch MED17.7.7 & Bosch MED17.7.1 & Bosch MG1CS006",
                "Engine_code": "M271 DE 18 AL",
            },
            "250 - 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "300 - 245hp": {
                "std_power": 245,
                "tuned_power": 265,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Bosch MED17.7.7",
                "Engine_code": "M274.920",
            },
            "350 299hp": {
                "std_power": 299,
                "tuned_power": 348,
                "std_torque": 400,
                "tuned_torque": 451,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7 & Bosch MED17.7.2",
                "Engine_code": "M264 E20 DEH LA",
            },
            "400 - 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 480,
                "tuned_torque": 580,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.7 & Bosch MED17.7.3.1",
                "Engine_code": "M276 DE 30 LA",
            },
            "43 AMG 401hp": {
                "std_power": 401,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1 & Getrag VGSNAG3",
                "Engine_code": "M276.823",
            },
            "450 - 367hp": {
                "std_power": 367,
                "tuned_power": 410,
                "std_torque": 500,
                "tuned_torque": 590,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276.823",
            },
            "53 AMG 435hp": {
                "std_power": 435,
                "tuned_power": 460,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2999,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M256.930",
            },
            "63 AMG S 612hp": {
                "std_power": 612,
                "tuned_power": 690,
                "std_torque": 850,
                "tuned_torque": 950,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M177.980",
            },
            "63 AMG 571hp": {
                "std_power": 571,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M177.980",
            },
        },
    },
    "G": {
        "-> 2017": {
            "270 CDI 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2685,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM612",
            },
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 275,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16+ & Bosch EDC17CP10",
                "Engine_code": "OM642",
            },
            "350 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 275,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "350 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 275,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP10 & Bosch EDC17CP01",
                "Engine_code": "OM642",
            },
            "350 CDI 245hp": {
                "std_power": 245,
                "tuned_power": 280,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP57",
                "Engine_code": "OM642",
            },
            "400 CDI 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 3996,
                "Type_ecu": "Temic CRV1.0 & Temic CRV2.0",
                "Engine_code": "M628.962",
            },
            "500 - 421hp": {
                "std_power": 421,
                "tuned_power": 500,
                "std_torque": 610,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5 & Bosch ME9.7 & Bosch ME2.8",
                "Engine_code": "M177",
            },
            "55 AMG 476hp": {
                "std_power": 476,
                "tuned_power": 540,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8.1",
                "Engine_code": "M113",
            },
            "55 AMG 500hp": {
                "std_power": 500,
                "tuned_power": 540,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8.1 & Bosch MED17.7.5",
                "Engine_code": "M113",
            },
            "63 AMG 571hp": {
                "std_power": 571,
                "tuned_power": 660,
                "std_torque": 760,
                "tuned_torque": 1100,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M278",
            },
            "63 AMG 544hp": {
                "std_power": 544,
                "tuned_power": 660,
                "std_torque": 760,
                "tuned_torque": 1100,
                "cc": 5461,
                "Type_ecu": "Bosch ME2.8 & Bosch MED17.7.3",
                "Engine_code": "M278",
            },
            "65 AMG 612hp": {
                "std_power": 612,
                "tuned_power": 670,
                "std_torque": 1000,
                "tuned_torque": 1100,
                "cc": 5980,
                "Type_ecu": "Bosch ME17.7.8",
                "Engine_code": "M275",
            },
        },
        "2018 ->": {
            "350d (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 380,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 2925,
                "Type_ecu": "Bosch EDC17CP57 & Bosch MD1CP001",
                "Engine_code": "OM656",
            },
            "400d (3.0D) 330hp": {
                "std_power": 330,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656.929",
            },
            "500 - 422hp": {
                "std_power": 422,
                "tuned_power": 510,
                "std_torque": 610,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M176.980",
            },
            "63 AMG 585hp": {
                "std_power": 585,
                "tuned_power": 660,
                "std_torque": 850,
                "tuned_torque": 1100,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5 & Bosch MED17.7.3",
                "Engine_code": "M177.980",
            },
        },
    },
    "GL": {
        "X164 - 2006 - 2012": {
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC16CP31 & Bosch EDC17CP10",
                "Engine_code": "OM642",
            },
            "350 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 275,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17CP10 & Bosch EDC17C01 & Bosch EDC17CP46",
                "Engine_code": "OM642",
            },
            "350 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 275,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17CP46 & Bosch EDC17CP10",
                "Engine_code": "OM642",
            },
            "350 CDI 265hp": {
                "std_power": 265,
                "tuned_power": 295,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17CP46 & Bosch EDC17CP01",
                "Engine_code": "OM642",
            },
            "420 CDI 306hp": {
                "std_power": 306,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 810,
                "cc": 3996,
                "Type_ecu": "Bosch EDC16CP36",
                "Engine_code": "OM646",
            },
            "450 CDI 306hp": {
                "std_power": 306,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 810,
                "cc": 3996,
                "Type_ecu": "Bosch EDC16CP36",
                "Engine_code": "OM646",
            },
            "450 - 340hp": {
                "std_power": 340,
                "tuned_power": 355,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 4663,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M273",
            },
            "500 - 388hp": {
                "std_power": 388,
                "tuned_power": 405,
                "std_torque": 530,
                "tuned_torque": 570,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M273.963",
            },
        },
        "X166 - 2012 ->": {
            "350 Bluetec 258hp": {
                "std_power": 258,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 690,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57 & Siemens/Continental VGS3-NAG2 & Bosch EDC17CP10",
                "Engine_code": "OM642",
            },
            "400 - 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 480,
                "tuned_torque": 580,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276",
            },
            "450 367hp": {
                "std_power": 367,
                "tuned_power": 510,
                "std_torque": 500,
                "tuned_torque": 820,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "",
            },
            "500 - 435hp": {
                "std_power": 435,
                "tuned_power": 510,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M278",
            },
            "GL 63 AMG - 558hp": {
                "std_power": 558,
                "tuned_power": 620,
                "std_torque": 760,
                "tuned_torque": 900,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M157",
            },
        },
    },
    "GLA": {
        "H247 - 2020 ->": {
            "180d 116hp": {
                "std_power": 116,
                "tuned_power": 200,
                "std_torque": 260,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MRD1.42",
                "Engine_code": "OM654",
            },
            "200d (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "OM654 DE20",
            },
            "220d (2.0d) 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM 654",
            },
            "180 (1.3T) 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "200 (1.3T) 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "250 (2.0T) 224hp": {
                "std_power": 224,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M260 DE 20 AL",
            },
            "35 AMG 306hp": {
                "std_power": 306,
                "tuned_power": 345,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1991,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "M260 DE 20 AL",
            },
            "45 AMG - 387hp": {
                "std_power": 387,
                "tuned_power": 470,
                "std_torque": 480,
                "tuned_torque": 620,
                "cc": 1991,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M139",
            },
            "45 AMG S - 421hp": {
                "std_power": 421,
                "tuned_power": 470,
                "std_torque": 500,
                "tuned_torque": 620,
                "cc": 1991,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M139",
            },
        },
        "X156 - 2014 - 2016": {
            "180 CDI (1500cc) 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "OM607 DE 15 LA",
            },
            "200 CDI 4 Matic 136hp": {
                "std_power": 136,
                "tuned_power": 200,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3",
                "Engine_code": "OM651 DE 22 LA",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3",
                "Engine_code": "OM 651 DE 22 LA",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.x & Delphi CRD3.60",
                "Engine_code": "OM 651 DE 22 LA",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.60",
                "Engine_code": "OM 651 DE 22 LA",
            },
            "180 CGi 122hp": {
                "std_power": 122,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 16 AL red.",
            },
            "200 CGi 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 16 AL",
            },
            "250 Turbo 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 20 AL",
            },
            "45 AMG 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M133 DE 20 AL",
            },
        },
        "X156 - 2017 - 2019": {
            "180d (1.5D) 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "OM607 DE 15 LA",
            },
            "200d (2.2D) 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x & Delphi CRD3.E1 & Delphi CRD3.F1",
                "Engine_code": "OM651 DE 22 LA",
            },
            "220d (2.2D) 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x & Delphi CRD3.x & Delphi CRD3.E1",
                "Engine_code": "OM651 DE 22 LA",
            },
            "180 (1.6T) 122hp": {
                "std_power": 122,
                "tuned_power": 175,
                "std_torque": 200,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS2-FDCT",
                "Engine_code": "M270 DE 16 AL red.",
            },
            "200 (1.6T) 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 16 AL",
            },
            "250 (2.0T) 211hp": {
                "std_power": 211,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M270 DE 20 AL",
            },
            "35 AMG 306hp": {
                "std_power": 306,
                "tuned_power": 345,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1991,
                "Type_ecu": "",
                "Engine_code": "M260 DE 20 AL",
            },
            "45 AMG 381hp": {
                "std_power": 381,
                "tuned_power": 420,
                "std_torque": 475,
                "tuned_torque": 540,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Getrag VGS2-FDCT",
                "Engine_code": "M270 DE 20 AL",
            },
        },
    },
    "GLB": {
        "2019 ->": {
            "180d (2.0D) 116hp": {
                "std_power": 116,
                "tuned_power": 175,
                "std_torque": 280,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654",
            },
            "200d (2.0D) 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "220d (2.0D) 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "180 (1.3T) 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "200 (1.3T) 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "250 / 240 (2.0T) 224hp": {
                "std_power": 224,
                "tuned_power": 250,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M260",
            },
            "35 AMG 306hp": {
                "std_power": 306,
                "tuned_power": 345,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M260 DE 20 AL",
            },
        },
    },
    "GLC": {
        "2015 - 2019": {
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57",
                "Engine_code": "M651.921",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57 & Bosch EDC17CP60 & Getrag VGSNAG3",
                "Engine_code": "M651.921",
            },
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M651.921",
            },
            "350 CDI 258hp": {
                "std_power": 258,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 690,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP57",
                "Engine_code": "OM642.873",
            },
            "200 4MATIC 184hp": {
                "std_power": 184,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "",
            },
            "250 4MATIC 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2 & Bosch EDC17CP57 & Getrag VGSNAG3 & Bosch MED17.7.7",
                "Engine_code": "M274.920",
            },
            "300 - 245hp": {
                "std_power": 245,
                "tuned_power": 265,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "350e Hybrid 320hp": {
                "std_power": 320,
                "tuned_power": 366,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "43 AMG 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276.823",
            },
            "63 AMG S 510hp": {
                "std_power": 510,
                "tuned_power": 570,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.7.5",
                "Engine_code": "M177.980",
            },
            "63 AMG 476hp": {
                "std_power": 476,
                "tuned_power": 570,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M177.980",
            },
        },
        "2019 - 2023": {
            "200d 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "220d 194hp": {
                "std_power": 194,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "300d 245hp": {
                "std_power": 245,
                "tuned_power": 265,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654 DE 20 SCR",
            },
            "300de 306hp": {
                "std_power": 306,
                "tuned_power": 335,
                "std_torque": 700,
                "tuned_torque": 760,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "400d 330hp": {
                "std_power": 330,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656.929",
            },
            "200 - 197hp": {
                "std_power": 197,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 450,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M264.920",
            },
            "250/260 - 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "300 - 258hp": {
                "std_power": 258,
                "tuned_power": 275,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.7",
                "Engine_code": "M264.920",
            },
            "300e 320hp": {
                "std_power": 320,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 760,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "43 AMG 390hp": {
                "std_power": 390,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276 DE 30 LA",
            },
            "63 AMG S 510hp": {
                "std_power": 510,
                "tuned_power": 570,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M177 DE 40 AL",
            },
            "63 AMG 476hp": {
                "std_power": 476,
                "tuned_power": 570,
                "std_torque": 650,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.5.5",
                "Engine_code": "M177 DE 40 AL",
            },
        },
        "2023 -> ...": {
            "200 (2.0T) 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "220d (2.0d) 197hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "300 (2.0T) 258hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "300e 313hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "400e 381hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "43 AMG 421hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "AMG 63 S E Performance 680hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "GLE": {
        "2015 - 2018": {
            "250 CDI 4Matic 204hp": {
                "std_power": 204,
                "tuned_power": 250,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57",
                "Engine_code": "OM651",
            },
            "350 CDI 4Matic 258hp": {
                "std_power": 258,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 690,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP57 & Bosch EDC17C46 & Bosch EDC17CP10",
                "Engine_code": "OM642",
            },
            "400 4Matic 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 480,
                "tuned_torque": 580,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M276 DE 30 LA",
            },
            "43 AMG 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276.821",
            },
            "43 AMG 390hp": {
                "std_power": 390,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.3.1 & Bosch MED17.7.3.1",
                "Engine_code": "M276.821",
            },
            "450 AMG 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 590,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1 & Bosch MG1CP002",
                "Engine_code": "M276.821",
            },
            "500 4Matic 435hp": {
                "std_power": 435,
                "tuned_power": 500,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 2996,
                "Type_ecu": "Bosch MG1",
                "Engine_code": "M276.821",
            },
            "500 4Matic 455hp": {
                "std_power": 455,
                "tuned_power": 500,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M278.928",
            },
            "500e 449hp": {
                "std_power": 449,
                "tuned_power": 490,
                "std_torque": 650,
                "tuned_torque": 720,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276.821",
            },
            "63 AMG S 585hp": {
                "std_power": 585,
                "tuned_power": 660,
                "std_torque": 760,
                "tuned_torque": 1050,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.1",
                "Engine_code": "M157.982",
            },
            "63 AMG 558hp": {
                "std_power": 558,
                "tuned_power": 660,
                "std_torque": 700,
                "tuned_torque": 1050,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M157.982",
            },
        },
        "2019 ->": {
            "300d (2.0D) 245hp": {
                "std_power": 245,
                "tuned_power": 265,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001 & Bosch EDC17CP57",
                "Engine_code": "OM654 DE20",
            },
            "350d (3.0D) 272hp": {
                "std_power": 272,
                "tuned_power": 380,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656 D 29 R SCR",
            },
            "350de 320hp": {
                "std_power": 320,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 780,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654 DE20",
            },
            "400d (3.0D) 330hp": {
                "std_power": 330,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656 D 29 R SCR",
            },
            "350 (2.0T) 258hp": {
                "std_power": 258,
                "tuned_power": 275,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7",
                "Engine_code": "M264.920",
            },
            "320 (3.0T) 272hp": {
                "std_power": 272,
                "tuned_power": 300,
                "std_torque": 620,
                "tuned_torque": 670,
                "cc": 2925,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "",
            },
            "350e 333hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "450 4Matic (3.0T) 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 590,
                "cc": 2996,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M256 E 30 DEH LA",
            },
            "53 AMG 435hp": {
                "std_power": 435,
                "tuned_power": 460,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2999,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M256 E 30 DEH LA",
            },
            "580 4Matic (4.0T) 490hp": {
                "std_power": 490,
                "tuned_power": 570,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.5.5",
                "Engine_code": "M176",
            },
            "63 AMG S V8 BiTurbo 612hp": {
                "std_power": 612,
                "tuned_power": 690,
                "std_torque": 850,
                "tuned_torque": 950,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.7.5",
                "Engine_code": "M 177 DE 40 AL",
            },
            "63 AMG V8 BiTurbo 571hp": {
                "std_power": 571,
                "tuned_power": 650,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.5.5 & Getrag VGS2-FDCT",
                "Engine_code": "M 177 DE 40 AL",
            },
        },
    },
    "GLK": {
        "X204 - 2008 - 2010": {
            "200 CDI 143hp": {
                "std_power": 143,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46",
                "Engine_code": "OM651 DE 22 LA red.",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 580,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi CRD2",
                "Engine_code": "OM651 DE 22 LA",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 580,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46 & Delphi CRD3 & Delphi CRD2.x",
                "Engine_code": "OM651 DE 22 LA",
            },
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi CRD2.x",
                "Engine_code": "OM651 DE 22 LA",
            },
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01",
                "Engine_code": "OM642 LS DE 30 LA",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "",
                "Engine_code": "OM642 LS DE 30 LA",
            },
            "350 CDI 231hp": {
                "std_power": 231,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 3499,
                "Type_ecu": "Bosch EDC17CP01 & Bosch EDC17CP10",
                "Engine_code": "OM642.832",
            },
            "350 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 3499,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "X204 - 2010 ->": {
            "200 CDI 143hp": {
                "std_power": 143,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46 & Delphi CRD2",
                "Engine_code": "OM651 DE 22 LA red.",
            },
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46 & Delphi CRD3.10",
                "Engine_code": "OM651 DE 22 LA red.",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 560,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57 & Delphi CRD3.1R",
                "Engine_code": "OM651 DE 22 LA",
            },
            "220 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 560,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57 & Delphi CRD2.x & Delphi CRD3.x",
                "Engine_code": "OM651 DE 22 LA",
            },
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP46 & Delphi CRD3.x & Delphi CRD2.x",
                "Engine_code": "OM651 DE 22 LA",
            },
            "350 CDI 265hp": {
                "std_power": 265,
                "tuned_power": 295,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 3499,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57 & Bosch EDC17CP10 & Bosch EDC17CP01",
                "Engine_code": "M276 DE 35",
            },
            "200 CGi 184hp": {
                "std_power": 184,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "250 CGi 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "350 CGI 306hp": {
                "std_power": 306,
                "tuned_power": 326,
                "std_torque": 370,
                "tuned_torque": 400,
                "cc": 3499,
                "Type_ecu": "Bosch MED17.7.1",
                "Engine_code": "M 276 DE 35",
            },
        },
    },
    "GLS": {
        "2015 - 2018": {
            "350d 258hp": {
                "std_power": 258,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 690,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57",
                "Engine_code": "M642.826",
            },
            "400 (3.0T) 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 480,
                "tuned_torque": 580,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276 DE 30 LA",
            },
            "500 (4.7 V8 TT) 455hp": {
                "std_power": 455,
                "tuned_power": 500,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M278 DE 40 LA",
            },
            "63 AMG 585hp": {
                "std_power": 585,
                "tuned_power": 635,
                "std_torque": 760,
                "tuned_torque": 935,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M157",
            },
        },
        "2019 ->": {
            "350d 286hp": {
                "std_power": 286,
                "tuned_power": 380,
                "std_torque": 600,
                "tuned_torque": 820,
                "cc": 2987,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656 D 29 R SCR",
            },
            "400d 331hp": {
                "std_power": 331,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 840,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001 & Getrag VGSNAG3",
                "Engine_code": "OM656 D 29 R SCR",
            },
            "450 (3.0T) 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 590,
                "cc": 2996,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M276 DE30 LA",
            },
            "580 (4.0T) 490hp": {
                "std_power": 490,
                "tuned_power": 570,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M176.980",
            },
            "600 - 558hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "63 AMG V8 Bi-Turbo 612hp": {
                "std_power": 612,
                "tuned_power": 690,
                "std_torque": 850,
                "tuned_torque": 950,
                "cc": 3982,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "M 177 DE 40 AL",
            },
        },
    },
    "GT": {
        "2014 - 2017": {
            "4.0 - 462hp": {
                "std_power": 462,
                "tuned_power": 560,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M178",
            },
            "R 4.0 - 585hp": {
                "std_power": 585,
                "tuned_power": 660,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M178",
            },
            "S 4.0 - 510hp": {
                "std_power": 510,
                "tuned_power": 560,
                "std_torque": 650,
                "tuned_torque": 800,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M178",
            },
        },
        "2017 - 2021": {
            "4.0 - 476hp": {
                "std_power": 476,
                "tuned_power": 575,
                "std_torque": 630,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5 & Bosch MG1CP002",
                "Engine_code": "M178",
            },
            "Black Series 4.0 Bi-Turbo 730hp": {
                "std_power": 730,
                "tuned_power": 790,
                "std_torque": 800,
                "tuned_torque": 1040,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M178",
            },
            "C 4.0 - 557hp": {
                "std_power": 557,
                "tuned_power": 575,
                "std_torque": 670,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M178",
            },
            "R 4.0 - 585hp": {
                "std_power": 585,
                "tuned_power": 660,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M178",
            },
            "Roadster S 4.0 - 522hp": {
                "std_power": 522,
                "tuned_power": 575,
                "std_torque": 670,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M178",
            },
            "S 4.0 - 522hp": {
                "std_power": 522,
                "tuned_power": 575,
                "std_torque": 670,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M178",
            },
        },
        "2023 -> ...": {
            "55 AMG 476hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "63 AMG 585hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "ML": {
        "W163 - 1997 - 2005": {
            "270 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 2688,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM612 DE 27 LA",
            },
            "400 CDI 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 3996,
                "Type_ecu": "Temic CRV1.0",
                "Engine_code": "M163.128",
            },
            "320 V6 - 218hp": {
                "std_power": 218,
                "tuned_power": 230,
                "std_torque": 310,
                "tuned_torque": 330,
                "cc": 3199,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M112.942",
            },
            "350 V6 - 245hp": {
                "std_power": 245,
                "tuned_power": 260,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 3724,
                "Type_ecu": "Bosch ME9.7 & Bosch ME2.8",
                "Engine_code": "M112.970",
            },
            "430 V8 - 272hp": {
                "std_power": 272,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 425,
                "cc": 4266,
                "Type_ecu": "Bosch ME2.8 & Bosch ME9.7",
                "Engine_code": "M113.964",
            },
            "55 AMG 347hp": {
                "std_power": 347,
                "tuned_power": 367,
                "std_torque": 510,
                "tuned_torque": 535,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8 & Bosch ME2.0",
                "Engine_code": "M113 E55",
            },
        },
        "W164 - 2005 - 2009": {
            "280 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "M642.940",
            },
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17CP10",
                "Engine_code": "M642.940",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17CP10 & Bosch EDC17CP01",
                "Engine_code": "M642.940",
            },
            "420 CDI 306hp": {
                "std_power": 306,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 810,
                "cc": 3996,
                "Type_ecu": "Bosch EDC16CP36",
                "Engine_code": "M629.912",
            },
            "500 V8 306hp": {
                "std_power": 306,
                "tuned_power": 326,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 4966,
                "Type_ecu": "Bosch ME2.8 & Bosch ME9.7",
                "Engine_code": "M113 E50",
            },
            "63 AMG 510hp": {
                "std_power": 510,
                "tuned_power": 530,
                "std_torque": 630,
                "tuned_torque": 660,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M156",
            },
        },
        "W164 - 2009 - 2011": {
            "300 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 260,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01 & Bosch EDC17CP10",
                "Engine_code": "M642.820",
            },
            "300 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17CP10",
                "Engine_code": "M642.940",
            },
            "350 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 275,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17CP10 & Bosch EDC17CP01 & Bosch EDC17CP46",
                "Engine_code": "OM642",
            },
            "350 CDI 231hp": {
                "std_power": 231,
                "tuned_power": 275,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01 & Bosch EDC17CP46 & Bosch EDC17CP10 & Bosch EDC16+",
                "Engine_code": "M642.820",
            },
            "350 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 275,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17CP10",
                "Engine_code": "M642.820",
            },
            "450 CDI 306hp": {
                "std_power": 306,
                "tuned_power": 350,
                "std_torque": 700,
                "tuned_torque": 810,
                "cc": 3996,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC16CP36",
                "Engine_code": "M163",
            },
            "63 AMG 510hp": {
                "std_power": 510,
                "tuned_power": 530,
                "std_torque": 630,
                "tuned_torque": 660,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M156",
            },
        },
        "W166 - 2011 ->": {
            "250 Bluetec 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57 & Delphi CRD3.x & Bosch EDC17CP46 & Bosch MED17.7.3 & Bosch EDC17CP60",
                "Engine_code": "OM651",
            },
            "350 CDI 258hp": {
                "std_power": 258,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 690,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57 & Bosch MED17.7.1 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "OM642",
            },
            "400 4 MATIC 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 480,
                "tuned_torque": 580,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1 & Bosch MED17.7.1",
                "Engine_code": "M276 DE 30 LA",
            },
            "500 biturbo - 408hp": {
                "std_power": 408,
                "tuned_power": 510,
                "std_torque": 600,
                "tuned_torque": 820,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M 278",
            },
            "63 AMG 525hp": {
                "std_power": 525,
                "tuned_power": 650,
                "std_torque": 700,
                "tuned_torque": 920,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.1",
                "Engine_code": "M157",
            },
            "63 AMG 558hp": {
                "std_power": 558,
                "tuned_power": 650,
                "std_torque": 720,
                "tuned_torque": 920,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M157",
            },
        },
    },
    "R": {
        "2006 - 2010": {
            "280 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "300 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP01 & Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "320 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "350 BlueTec 211hp": {
                "std_power": 211,
                "tuned_power": 275,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP10",
                "Engine_code": "OM642",
            },
            "350 CDI 224hp": {
                "std_power": 224,
                "tuned_power": 275,
                "std_torque": 510,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01",
                "Engine_code": "OM642",
            },
            "500 V8 - 306hp": {
                "std_power": 306,
                "tuned_power": 326,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 4966,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M113 E 50",
            },
            "500 V8 - 388hp": {
                "std_power": 388,
                "tuned_power": 408,
                "std_torque": 530,
                "tuned_torque": 570,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M273.963",
            },
            "63 AMG 510hp": {
                "std_power": 510,
                "tuned_power": 530,
                "std_torque": 630,
                "tuned_torque": 660,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M156",
            },
        },
        "2010 - 2013": {
            "300 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17CP46",
                "Engine_code": "OM642",
            },
            "350 BlueTec 210hp": {
                "std_power": 210,
                "tuned_power": 270,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP10 & Bosch EDC17C01",
                "Engine_code": "OM642",
            },
            "350 CDI 265hp": {
                "std_power": 265,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46",
                "Engine_code": "OM642",
            },
            "350 272hp": {
                "std_power": 272,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 380,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.7.3 & Bosch ME9.7",
                "Engine_code": "",
            },
            "500 V8 - 388hp": {
                "std_power": 388,
                "tuned_power": 408,
                "std_torque": 530,
                "tuned_torque": 570,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M273.963",
            },
        },
    },
    "S": {
        "W220 - 1997 - 2002": {
            "320 CDI 197hp": {
                "std_power": 197,
                "tuned_power": 235,
                "std_torque": 470,
                "tuned_torque": 550,
                "cc": 3224,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM648",
            },
            "400 CDI 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 560,
                "tuned_torque": 630,
                "cc": 3996,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "OM628",
            },
            "55 AMG 360hp": {
                "std_power": 360,
                "tuned_power": 378,
                "std_torque": 530,
                "tuned_torque": 555,
                "cc": 4966,
                "Type_ecu": "Bosch ME2.8 & Bosch ME2.0",
                "Engine_code": "M113 5.4L",
            },
            "600 V12 - 367hp": {
                "std_power": 367,
                "tuned_power": 383,
                "std_torque": 530,
                "tuned_torque": 555,
                "cc": 5786,
                "Type_ecu": "Bosch ME2.7",
                "Engine_code": "M137 5.8L",
            },
        },
        "W220 - 2002 - 2005": {
            "320 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 570,
                "cc": 3222,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "M648.960",
            },
            "400 CDI 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 3996,
                "Type_ecu": "Temic CRV1.0 & Temic CRV2.0",
                "Engine_code": "OM628",
            },
            "55 AMG - 500hp": {
                "std_power": 500,
                "tuned_power": 550,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8.1",
                "Engine_code": "M156 E 55",
            },
            "600 V12 - 500hp": {
                "std_power": 500,
                "tuned_power": 578,
                "std_torque": 800,
                "tuned_torque": 910,
                "cc": 5513,
                "Type_ecu": "Bosch ME2.7.1",
                "Engine_code": "M275.950",
            },
            "65 AMG V12 Turbo 612hp": {
                "std_power": 612,
                "tuned_power": 670,
                "std_torque": 1000,
                "tuned_torque": 1100,
                "cc": 5980,
                "Type_ecu": "",
                "Engine_code": "M275 E 60 AL",
            },
        },
        "W221 - 2005 - 2013": {
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 230,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57 & Delphi CRD3.10",
                "Engine_code": "M651.961",
            },
            "320 CDI 235hp": {
                "std_power": 235,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP36 & Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "320 CDI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC16CP36 & Bosch EDC16CP31",
                "Engine_code": "OM642",
            },
            "350 CDI BlueTec 258hp": {
                "std_power": 258,
                "tuned_power": 300,
                "std_torque": 620,
                "tuned_torque": 700,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57",
                "Engine_code": "OM642",
            },
            "350 CDI 235hp": {
                "std_power": 235,
                "tuned_power": 290,
                "std_torque": 540,
                "tuned_torque": 620,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP36 & Bosch EDC17CP46",
                "Engine_code": "OM642",
            },
            "420 CDI 320hp": {
                "std_power": 320,
                "tuned_power": 360,
                "std_torque": 730,
                "tuned_torque": 820,
                "cc": 3996,
                "Type_ecu": "Bosch EDC16CP36 & Bosch EDC16CP31",
                "Engine_code": "OM628",
            },
            "450 CDI 320hp": {
                "std_power": 320,
                "tuned_power": 360,
                "std_torque": 730,
                "tuned_torque": 820,
                "cc": 3996,
                "Type_ecu": "Bosch EDC16CP36",
                "Engine_code": "OM628",
            },
            "400 Hybrid 299hp": {
                "std_power": 299,
                "tuned_power": 314,
                "std_torque": 384,
                "tuned_torque": 399,
                "cc": 3499,
                "Type_ecu": "Bosch ME9.7 & Bosch ME17.7",
                "Engine_code": "M276.960",
            },
            "500 - 388hp": {
                "std_power": 388,
                "tuned_power": 408,
                "std_torque": 530,
                "tuned_torque": 570,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M278",
            },
            "500 Turbo 435hp": {
                "std_power": 435,
                "tuned_power": 510,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.1",
                "Engine_code": "M278.932",
            },
            "600 V12 Turbo 517hp": {
                "std_power": 517,
                "tuned_power": 585,
                "std_torque": 830,
                "tuned_torque": 910,
                "cc": 5513,
                "Type_ecu": "Bosch ME2.7.2",
                "Engine_code": "OM275",
            },
            "63 AMG Turbo 544hp": {
                "std_power": 544,
                "tuned_power": 590,
                "std_torque": 800,
                "tuned_torque": 900,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.1",
                "Engine_code": "M278",
            },
            "63 AMG 525hp": {
                "std_power": 525,
                "tuned_power": 541,
                "std_torque": 630,
                "tuned_torque": 650,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M157",
            },
            "65 AMG V12 Turbo 630hp": {
                "std_power": 630,
                "tuned_power": 670,
                "std_torque": 1000,
                "tuned_torque": 1100,
                "cc": 5980,
                "Type_ecu": "Bosch ME2.7.2",
                "Engine_code": "M275 AMG",
            },
        },
        "W222 - 2013 - 2017": {
            "300 Hybrid 231hp": {
                "std_power": 231,
                "tuned_power": 260,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57",
                "Engine_code": "OM651 DE 22 LA",
            },
            "350 CDI BlueTec 258hp": {
                "std_power": 258,
                "tuned_power": 290,
                "std_torque": 620,
                "tuned_torque": 690,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57 & Getrag VGS2-FDCT & Bosch EDC17CP01",
                "Engine_code": "OM642",
            },
            "400 Hybrid 333hp": {
                "std_power": 333,
                "tuned_power": 380,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 3498,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276 DE 35",
            },
            "400 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 480,
                "tuned_torque": 580,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276.824",
            },
            "500 V8 Turbo 456hp": {
                "std_power": 456,
                "tuned_power": 500,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.1 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M278 DE 46 AL",
            },
            "600 V12 Turbo 530hp": {
                "std_power": 530,
                "tuned_power": 680,
                "std_torque": 830,
                "tuned_torque": 1000,
                "cc": 5980,
                "Type_ecu": "Bosch MED17.7.8",
                "Engine_code": "M277 E 60 AL",
            },
            "63 AMG V8 Turbo 585hp": {
                "std_power": 585,
                "tuned_power": 660,
                "std_torque": 900,
                "tuned_torque": 1050,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3 & Bosch MED17.7.1 & Siemens/Continental VGS3-NAG2",
                "Engine_code": "M157 DE 55 AL",
            },
            "65 AMG V12 Turbo 630hp": {
                "std_power": 630,
                "tuned_power": 670,
                "std_torque": 1000,
                "tuned_torque": 1300,
                "cc": 5980,
                "Type_ecu": "Bosch ME17.7.8",
                "Engine_code": "M279 E 60 AL",
            },
        },
        "W222 - 2017 - 2019": {
            "350d 286hp": {
                "std_power": 286,
                "tuned_power": 380,
                "std_torque": 600,
                "tuned_torque": 800,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001 & Bosch EDC17CP57",
                "Engine_code": "OM656 DE 30 LA",
            },
            "400d 340hp": {
                "std_power": 340,
                "tuned_power": 410,
                "std_torque": 700,
                "tuned_torque": 840,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656.929",
            },
            "450 - 367hp": {
                "std_power": 367,
                "tuned_power": 410,
                "std_torque": 500,
                "tuned_torque": 590,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1 & Bosch MG1CP002",
                "Engine_code": "M256.930",
            },
            "560 - 469hp": {
                "std_power": 469,
                "tuned_power": 570,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M176.980",
            },
            "560e 476hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "600 - 530hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "63 AMG S 612hp": {
                "std_power": 612,
                "tuned_power": 690,
                "std_torque": 900,
                "tuned_torque": 950,
                "cc": 3982,
                "Type_ecu": "Bosch MED17.7.5 & Getrag VGSNAG3",
                "Engine_code": "M177.980",
            },
            "65 AMG 630hp": {
                "std_power": 630,
                "tuned_power": 690,
                "std_torque": 1000,
                "tuned_torque": 1100,
                "cc": 5980,
                "Type_ecu": "Bosch ME17.7.8",
                "Engine_code": "M277.980",
            },
            "650 - 630hp": {
                "std_power": 630,
                "tuned_power": 690,
                "std_torque": 1000,
                "tuned_torque": 1100,
                "cc": 5980,
                "Type_ecu": "Bosch ME17.7.8",
                "Engine_code": "M279.980",
            },
        },
        "W223 - 2020 ->": {
            "350d (3.0D) 286hp": {
                "std_power": 286,
                "tuned_power": 380,
                "std_torque": 600,
                "tuned_torque": 820,
                "cc": 2987,
                "Type_ecu": "",
                "Engine_code": "OM656",
            },
            "400d (3.0D) 330hp": {
                "std_power": 330,
                "tuned_power": 380,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2925,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM656",
            },
            "450 (3.0T) 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2999,
                "Type_ecu": "",
                "Engine_code": "M256",
            },
            "500 (3.0T) 435hp": {
                "std_power": 435,
                "tuned_power": 480,
                "std_torque": 520,
                "tuned_torque": 600,
                "cc": 2999,
                "Type_ecu": "",
                "Engine_code": "M256",
            },
            "580 (4.0T) 503hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "580e 507hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "63 AMG 650hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "S 63 E Performance AMG 802hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "SL": {
        "2016 - 2021": {
            "400 - 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 590,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7",
                "Engine_code": "M276 DE30 LA",
            },
            "500 - 456hp": {
                "std_power": 456,
                "tuned_power": 485,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M278.927",
            },
            "63 AMG V8 BiTurbo 585hp": {
                "std_power": 585,
                "tuned_power": 660,
                "std_torque": 900,
                "tuned_torque": 1100,
                "cc": 5461,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "M157.983",
            },
            "65 AMG V12 BiTurbo 630hp": {
                "std_power": 630,
                "tuned_power": 740,
                "std_torque": 1000,
                "tuned_torque": 1300,
                "cc": 5980,
                "Type_ecu": "",
                "Engine_code": "M279.981",
            },
        },
        "2022 -> ...": {
            "AMG 43 395hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "AMG 55 476hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "AMG 63 585hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "R230 - 2001 - 2011": {
            "500 V8 - 388hp": {
                "std_power": 388,
                "tuned_power": 408,
                "std_torque": 530,
                "tuned_torque": 570,
                "cc": 5461,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M273.965",
            },
            "500 V8 306hp": {
                "std_power": 306,
                "tuned_power": 326,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 4966,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M113 E50",
            },
            "55 AMG 476hp": {
                "std_power": 476,
                "tuned_power": 540,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8.1",
                "Engine_code": "M113 5.4L",
            },
            "55 AMG 500hp": {
                "std_power": 500,
                "tuned_power": 540,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8.1",
                "Engine_code": "M113 5.4L",
            },
            "55 AMG 517hp": {
                "std_power": 517,
                "tuned_power": 540,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8.1",
                "Engine_code": "M113 5.4L",
            },
            "600 V12 Turbo 517hp": {
                "std_power": 517,
                "tuned_power": 585,
                "std_torque": 830,
                "tuned_torque": 910,
                "cc": 5987,
                "Type_ecu": "Bosch ME2.7.2",
                "Engine_code": "M120",
            },
            "600 V12 500hp": {
                "std_power": 500,
                "tuned_power": 578,
                "std_torque": 800,
                "tuned_torque": 910,
                "cc": 5987,
                "Type_ecu": "Bosch ME2.7.1",
                "Engine_code": "M120",
            },
            "63 AMG 525hp": {
                "std_power": 525,
                "tuned_power": 540,
                "std_torque": 630,
                "tuned_torque": 660,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M156",
            },
            "65 AMG BlackSeries Turbo 670hp": {
                "std_power": 670,
                "tuned_power": 700,
                "std_torque": 1000,
                "tuned_torque": 1100,
                "cc": 5980,
                "Type_ecu": "Bosch ME2.7.2",
                "Engine_code": "M277",
            },
            "65 AMG Turbo 612hp": {
                "std_power": 612,
                "tuned_power": 670,
                "std_torque": 1000,
                "tuned_torque": 1100,
                "cc": 5980,
                "Type_ecu": "Bosch ME2.7.1",
                "Engine_code": "M277",
            },
        },
        "R231 - 2012 - 2015": {
            "400 V6 BiTurbo 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 480,
                "tuned_torque": 580,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276 DE 30 LA",
            },
            "500 V8 BiTurbo 435hp": {
                "std_power": 435,
                "tuned_power": 550,
                "std_torque": 700,
                "tuned_torque": 850,
                "cc": 4663,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M278",
            },
            "63 AMG V8 BiTurbo Pack Performance 571hp": {
                "std_power": 571,
                "tuned_power": 660,
                "std_torque": 900,
                "tuned_torque": 900,
                "cc": 6208,
                "Type_ecu": "",
                "Engine_code": "M157",
            },
            "63 AMG V8 BiTurbo 537hp": {
                "std_power": 537,
                "tuned_power": 660,
                "std_torque": 800,
                "tuned_torque": 900,
                "cc": 6208,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M157",
            },
            "63 AMG V8 BiTurbo 585hp": {
                "std_power": 585,
                "tuned_power": 660,
                "std_torque": 900,
                "tuned_torque": 900,
                "cc": 6208,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M157",
            },
            "65 AMG V12 BiTurbo 630hp": {
                "std_power": 630,
                "tuned_power": 680,
                "std_torque": 1000,
                "tuned_torque": 1150,
                "cc": 5980,
                "Type_ecu": "Bosch ME17.7.8",
                "Engine_code": "M277",
            },
        },
    },
    "SLC": {
        "2016 ->": {
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.E1 & Delphi CRD3.7A",
                "Engine_code": "OM651.980",
            },
            "180 (1.6T) 156hp": {
                "std_power": 156,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.910",
            },
            "200 (2.0T) 184hp": {
                "std_power": 184,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.2",
                "Engine_code": "M274.920",
            },
            "300 - 245hp": {
                "std_power": 245,
                "tuned_power": 265,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 1991,
                "Type_ecu": "Bosch MED17.7.5",
                "Engine_code": "M276.920",
            },
            "43 AMG 367hp": {
                "std_power": 367,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2996,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "M276.822",
            },
            "43 AMG 390hp": {
                "std_power": 390,
                "tuned_power": 420,
                "std_torque": 520,
                "tuned_torque": 620,
                "cc": 2996,
                "Type_ecu": "Bosch MG1CP002",
                "Engine_code": "M276.822",
            },
        },
    },
    "SLK": {
        "R170 - 2000 - 2004": {
            "200 K 163hp": {
                "std_power": 163,
                "tuned_power": 175,
                "std_torque": 230,
                "tuned_torque": 245,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM4LE",
                "Engine_code": "M111.946",
            },
            "230 K 193hp": {
                "std_power": 193,
                "tuned_power": 205,
                "std_torque": 280,
                "tuned_torque": 295,
                "cc": 2295,
                "Type_ecu": "Bosch ME2.1 & Siemens/Continental SIM4LE & Bosch ME2.0",
                "Engine_code": "M111.943",
            },
            "32 AMG 354hp": {
                "std_power": 354,
                "tuned_power": 375,
                "std_torque": 450,
                "tuned_torque": 475,
                "cc": 3199,
                "Type_ecu": "Bosch ME2.8.1",
                "Engine_code": "M111.960",
            },
        },
        "R171 - 2004 - 2010": {
            "200 K 163hp": {
                "std_power": 163,
                "tuned_power": 175,
                "std_torque": 240,
                "tuned_torque": 255,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM4LKE",
                "Engine_code": "M271 E18 ML",
            },
            "200 K 184hp": {
                "std_power": 184,
                "tuned_power": 205,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIM4LE",
                "Engine_code": "M111.946",
            },
            "55 AMG 360hp": {
                "std_power": 360,
                "tuned_power": 385,
                "std_torque": 510,
                "tuned_torque": 535,
                "cc": 5439,
                "Type_ecu": "Bosch ME2.8",
                "Engine_code": "M113 5.4L",
            },
        },
        "R172 - 2011 ->": {
            "250 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3",
                "Engine_code": "M651",
            },
            "200 (1.8T) 184hp": {
                "std_power": 184,
                "tuned_power": 210,
                "std_torque": 270,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Bosch MED17.7.2 & Siemens/Continental SIM271DE",
                "Engine_code": "M271 E18 ML",
            },
            "250 (1.8T) 204hp": {
                "std_power": 204,
                "tuned_power": 220,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIM271DE",
                "Engine_code": "M271.861",
            },
            "350 - 306hp": {
                "std_power": 306,
                "tuned_power": 320,
                "std_torque": 370,
                "tuned_torque": 390,
                "cc": 3498,
                "Type_ecu": "Bosch ME9.7 & Bosch MED17.7.1",
                "Engine_code": "M276.956",
            },
            "55 AMG V8 421hp": {
                "std_power": 421,
                "tuned_power": 440,
                "std_torque": 540,
                "tuned_torque": 565,
                "cc": 5461,
                "Type_ecu": "Bosch MED17.7.3",
                "Engine_code": "M113 5.5L Supercharger",
            },
        },
    },
    "SLS": {
        "All": {
            "6.2 V8 AMG 571hp": {
                "std_power": 571,
                "tuned_power": 595,
                "std_torque": 650,
                "tuned_torque": 670,
                "cc": 6208,
                "Type_ecu": "Bosch ME9.7",
                "Engine_code": "M159",
            },
        },
    },
    
    "Sprinter": {
        "2000 - 2010": {
            "208/308/408 CDI 82hp": {
                "std_power": 82,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 330,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15C6",
                "Engine_code": "OM611",
            },
            "209/309/509 CDI 88hp": {
                "std_power": 88,
                "tuned_power": 180,
                "std_torque": 220,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16CP31",
                "Engine_code": "OM646",
            },
            "210/310 CDI 102hp": {
                "std_power": 102,
                "tuned_power": 140,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 2874,
                "Type_ecu": "Delphi CRD2.30",
                "Engine_code": "OM602",
            },
            "211/311/411 CDI 109hp": {
                "std_power": 109,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC15C6 & Bosch EDC16CP31 & Bosch EDC16C2",
                "Engine_code": "OM611",
            },
            "212/312 CDI 122hp": {
                "std_power": 122,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 2874,
                "Type_ecu": "Bosch EDC17CP14",
                "Engine_code": "OM602",
            },
            "213/313/413 CDI 129hp": {
                "std_power": 129,
                "tuned_power": 152,
                "std_torque": 305,
                "tuned_torque": 365,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC15C6 & Delphi CRD2.x & Bosch EDC16CP31",
                "Engine_code": "OM611",
            },
            "215/315/515 CDI 150hp": {
                "std_power": 150,
                "tuned_power": 182,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C2 & Bosch EDC16CP31",
                "Engine_code": "OM611",
            },
            "216/316/416 CDI 156hp": {
                "std_power": 156,
                "tuned_power": 177,
                "std_torque": 330,
                "tuned_torque": 385,
                "cc": 2685,
                "Type_ecu": "Bosch EDC15C6 & Delphi CRD2",
                "Engine_code": "OM612 / OM 647",
            },
            "216/316/416 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x & Delphi CRD3P.B0 & Bosch EDC17CP10",
                "Engine_code": "",
            },
            "218/318/518 CDI 184hp": {
                "std_power": 184,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 520,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16CP36 & Bosch EDC16CP31 & Bosch EDC17CP10 & Bosch EDC16+",
                "Engine_code": "OM642",
            },
        },
        "2010 - 2014": {
            "210/310/510 CDI 95hp": {
                "std_power": 95,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2 & Delphi CRD3",
                "Engine_code": "",
            },
            "213/313/413/513 CDI 129hp": {
                "std_power": 129,
                "tuned_power": 190,
                "std_torque": 305,
                "tuned_torque": 420,
                "cc": 2148,
                "Type_ecu": "Bosch EDC15C6 & Bosch EDC17CP10 & Delphi CRD2.x & Delphi CRD3.x & Bosch EDC16",
                "Engine_code": "OM611",
            },
            "215/315/415/515 CDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 2148,
                "Type_ecu": "Delphi CRD2",
                "Engine_code": "OM611",
            },
            "216/316/416/516 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2685,
                "Type_ecu": "Delphi DCM3.5 & Delphi CRD2.x & Delphi CRD3 & Delphi DCM3.10 & Delphi CRD3.20",
                "Engine_code": "OM612",
            },
            "219/319/419/519 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 265,
                "std_torque": 440,
                "tuned_torque": 530,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17C10 & Bosch EDC17CP46 & Bosch EDC17C01 & Bosch EDC17CP10 & Bosch EDC17CP01 & Bosch EDC16C4 & Delphi CRD2.30",
                "Engine_code": "OM642 DE 30 LA",
            },
        },
        "2014 - 2016": {
            "210/310/510 BlueTEC 95hp": {
                "std_power": 95,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x & Bosch CRD3.20",
                "Engine_code": "",
            },
            "213/313/413/513 BlueTEC 129hp": {
                "std_power": 129,
                "tuned_power": 190,
                "std_torque": 305,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x & Delphi CRD3.x",
                "Engine_code": "",
            },
            "216/316/416/516 BlueTEC 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x Nexus & Delphi CRD3.20 & Bosch EDC17CP57 & Delphi CRD3.60 & Delphi CRD3P.B0",
                "Engine_code": "",
            },
            "219/319/519 BlueTEC 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 540,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP01 & Bosch EDC17CP46",
                "Engine_code": "OM642 DE 30 LA",
            },
        },
        "2016 - 2018": {
            "211/311/511 CDI 114hp": {
                "std_power": 114,
                "tuned_power": 145,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57 & Delphi CRD3P.B0 & Delphi CRD2 & Delphi CRD3.E1 & Delphi CRD3P.CO & Delphi CRD3.20",
                "Engine_code": "OM651 DE22LA",
            },
            "214/314/414/514 CDI 143hp": {
                "std_power": 143,
                "tuned_power": 210,
                "std_torque": 330,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3P.D1 & Delphi DCM3.4 & Delphi CRD3P.B0 & Delphi CRD3P.C0 & Delphi CRD3P.G0",
                "Engine_code": "OM651 DE22LA",
            },
            "216/316/416/516 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57 & Delphi CRD3P.B0 & Bosch CRD3.20 & Delphi CRD3P.C0",
                "Engine_code": "OM651 DE22LA",
            },
            "219/319/519 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 540,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP46 & Bosch EDC17CP57 & Bosch EDC17C66 & Bosch EDC17CP10",
                "Engine_code": "OM642 DE30LA",
            },
        },
        "2018 ->": {
            "211/311 CDI 114hp": {
                "std_power": 114,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 415,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.x & Delphi CRD3P.C0 & Bosch MD1CP001",
                "Engine_code": "M651 DE22",
            },
            "214/314 CDI 143hp": {
                "std_power": 143,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3 & Delphi CRD3P.C0 & Delphi CRD3P.G0 & Delphi CRD3P.B0 & Delphi CRD3.E1",
                "Engine_code": "M651 DE22",
            },
            "216/316 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3P.C0 & Delphi CRD3.E1 & Bosch EDC17CP57 & Delphi CRD3P.G0 & Delphi CRD3P.B0",
                "Engine_code": "M651 DE22",
            },
            "315 CDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "317 CDI 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 480,
                "cc": 1950,
                "Type_ecu": "Delphi CRD3P.B0 & Bosch MD1CP001 & Delphi CRD3P.GO",
                "Engine_code": "",
            },
            "318/418 CDI 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3.x",
                "Engine_code": "OM651 DE 22 LA",
            },
            "319/419/519 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 540,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP57 & Bosch EDC17CP46 & Bosch EDC17CP10",
                "Engine_code": "OM642 DE30LA",
            },
        },
    },
    "V-Klasse": {
        "1999 - 2003": {
            "200 CDI 102hp": {
                "std_power": 102,
                "tuned_power": 130,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 2151,
                "Type_ecu": "Bosch EDC15C0",
                "Engine_code": "OM611",
            },
            "220 CDI 122hp": {
                "std_power": 122,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 2151,
                "Type_ecu": "Bosch EDC15C0",
                "Engine_code": "OM611",
            },
        },
        "2014 - 2018": {
            "200 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 215,
                "std_torque": 330,
                "tuned_torque": 460,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C66 & Bosch MRD1.41",
                "Engine_code": "OM651",
            },
            "220 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 490,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C66 & Bosch MD1CP001",
                "Engine_code": "OM651",
            },
            "250 CDI BlueTec 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C66 & Bosch MD1CP001 & Bosch MED17.7.2",
                "Engine_code": "OM651",
            },
        },
        "2019 ->": {
            "220d (Euro 6 - 2021 ->) 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 380,
                "tuned_torque": 435,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "250d (Euro 6 - 2021 ->) 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 550,
                "cc": 1950,
                "Type_ecu": "",
                "Engine_code": "OM654.920",
            },
            "260 (2.0T) 211hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "220d 163hp": {
                "std_power": 163,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 480,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CS002 & Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "250d 190hp": {
                "std_power": 190,
                "tuned_power": 270,
                "std_torque": 440,
                "tuned_torque": 570,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
            "300d 240hp": {
                "std_power": 240,
                "tuned_power": 265,
                "std_torque": 500,
                "tuned_torque": 560,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "OM654.920",
            },
        },
    },
    "Vaneo": {
        "2002 - 2006": {
            "1.7 CDI 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1689,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "OM668 DE 17 LA red.",
            },
            "1.7 CDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 180,
                "tuned_torque": 230,
                "cc": 1689,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "OM668 DE 17 LA",
            },
            "2.0 CDI 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "OM646",
            },
            "2.2 CDI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 330,
                "tuned_torque": 380,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "",
            },
            "3.0 CDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "",
            },
        },
    },
    "Viano": {
        "2003 - 2010": {
            "2.0 CDI 109hp": {
                "std_power": 109,
                "tuned_power": 180,
                "std_torque": 270,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C2",
                "Engine_code": "OM646.982",
            },
            "2.0 CDI 116hp": {
                "std_power": 116,
                "tuned_power": 180,
                "std_torque": 290,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM646.980",
            },
            "2.2 CDI 150hp": {
                "std_power": 150,
                "tuned_power": 182,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 2148,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16C2",
                "Engine_code": "OM646",
            },
            "3.0 CDI V6 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 440,
                "tuned_torque": 560,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "OM642.990",
            },
        },
        "2010 ->": {
            "2.0 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 200,
                "std_torque": 310,
                "tuned_torque": 440,
                "cc": 2143,
                "Type_ecu": "Delphi CRD3",
                "Engine_code": "OM651 DE 22 LA red.",
            },
            "2.2 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17CP57 & Bosch EDC17C66 & Delphi CRD2.x",
                "Engine_code": "OM651 DE 22 LA",
            },
            "3.0 CDI V6 224hp": {
                "std_power": 224,
                "tuned_power": 265,
                "std_torque": 440,
                "tuned_torque": 580,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP10 & Bosch ME9.7 & Bosch EDC17C01",
                "Engine_code": "OM642 DE 30 LA",
            },
        },
    },
    "Vito": {
        "2020 ->": {
            "110 CDI (Euro 6 - 2021 ->) 102hp": {
                "std_power": 102,
                "tuned_power": 160,
                "std_torque": 270,
                "tuned_torque": 360,
                "cc": 0,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "114 CDI (2.0D) (Euro 6 - 2021 ->) 136hp": {
                "std_power": 136,
                "tuned_power": 195,
                "std_torque": 330,
                "tuned_torque": 435,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "116 CDI (Euro 6 - 2021 ->) 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 435,
                "cc": 1950,
                "Type_ecu": "",
                "Engine_code": "OM654 DE20LA",
            },
            "119 CDI (Euro 6 - 2021 ->) 190hp": {
                "std_power": 190,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 550,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CS001 & Bosch MD1CP001",
                "Engine_code": "OM654 DE20LA",
            },
            "114 CDI (1.8D) 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 330,
                "tuned_torque": 360,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CP001 & Bosch MD1CS006 & Bosch EDC17C66",
                "Engine_code": "OM622 DE 17 LA",
            },
            "114 CDI (2.0D) 136hp": {
                "std_power": 136,
                "tuned_power": 250,
                "std_torque": 330,
                "tuned_torque": 510,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001 & Bosch EDC17CP66",
                "Engine_code": "OM651 DE 22 LA",
            },
            "116 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 250,
                "std_torque": 380,
                "tuned_torque": 510,
                "cc": 1950,
                "Type_ecu": "Bosch EDC17C66 & Bosch MD1",
                "Engine_code": "OM654 DE20LA",
            },
            "119 CDI 190hp": {
                "std_power": 190,
                "tuned_power": 270,
                "std_torque": 440,
                "tuned_torque": 550,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001 & Bosch EDC17C66",
                "Engine_code": "OM654 DE20LA",
            },
            "124 CDI 239hp": {
                "std_power": 239,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 570,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP004 & Bosch MD1CP001",
                "Engine_code": "OM654 DE20LA",
            },
            "110 CDI 102hp": {
                "std_power": 102,
                "tuned_power": 155,
                "std_torque": 270,
                "tuned_torque": 360,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CP001 & Bosch MD1CS006",
                "Engine_code": "OM622 DE 17 LA",
            },
        },
        "W447 - 2015 - 2019": {
            "109 CDI 88hp (1600cc)": {
                "std_power": 88,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C66",
                "Engine_code": "OM622.951",
            },
            "110 CDI 102hp (1750cc)": {
                "std_power": 102,
                "tuned_power": 155,
                "std_torque": 270,
                "tuned_torque": 360,
                "cc": 1750,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "OM622 DE17 LA",
            },
            "111 CDI 114hp (1600cc)": {
                "std_power": 114,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C66",
                "Engine_code": "OM622.951",
            },
            "114 CDI 136hp (2100cc)": {
                "std_power": 136,
                "tuned_power": 215,
                "std_torque": 330,
                "tuned_torque": 490,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C66 & Bosch MD1CP001 & Bosch MD1CP001 & Bosch EDC17CP57",
                "Engine_code": "OM651 DE 22 AL red.",
            },
            "116 CDI 163hp (2100cc)": {
                "std_power": 163,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 490,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C66",
                "Engine_code": "OM651 DE 22 AL",
            },
            "119 BlueTEC 190hp (2100cc)": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 440,
                "tuned_torque": 490,
                "cc": 2143,
                "Type_ecu": "Bosch EDC17C66 & Bosch MD1CP001",
                "Engine_code": "OM651 DE 22 AL",
            },
        },
        "W638 - 1999 - 2003": {
            "108 CDI 82hp": {
                "std_power": 82,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 310,
                "cc": 2143,
                "Type_ecu": "Bosch EDC15C0",
                "Engine_code": "OM611",
            },
            "110 CDI 102hp": {
                "std_power": 102,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 2143,
                "Type_ecu": "Bosch EDC15C0",
                "Engine_code": "OM646 DE 22 LA red.",
            },
            "112 CDI 122hp": {
                "std_power": 122,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 2151,
                "Type_ecu": "Bosch EDC15C0",
                "Engine_code": "OM611.980",
            },
        },
        "W639 - 2003 - 2010": {
            "109 CDI 95hp": {
                "std_power": 95,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 400,
                "cc": 2143,
                "Type_ecu": "Bosch EDC16C31 & Delphi CRD2 & Bosch EDC16CP31 & Bosch EDC16C2",
                "Engine_code": "OM646 DE 22 LA red.",
            },
            "109 CDI 88hp": {
                "std_power": 88,
                "tuned_power": 145,
                "std_torque": 220,
                "tuned_torque": 330,
                "cc": 2143,
                "Type_ecu": "Bosch EDC16C2 & Bosch EDC16CP31",
                "Engine_code": "OM646 DE 22 LA red.",
            },
            "111 CDI 116hp": {
                "std_power": 116,
                "tuned_power": 180,
                "std_torque": 290,
                "tuned_torque": 400,
                "cc": 2143,
                "Type_ecu": "Bosch EDC16CP31 & Delphi CRD2.x & Bosch EDC16C2",
                "Engine_code": "OM646 DE 22 LA red.",
            },
            "111 CDI 109hp": {
                "std_power": 109,
                "tuned_power": 180,
                "std_torque": 270,
                "tuned_torque": 400,
                "cc": 2143,
                "Type_ecu": "Bosch EDC16C2 & Bosch EDC16CP31 & Delphi CRD2.x",
                "Engine_code": "OM646 DE 22 LA red.",
            },
            "115 CDI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 2143,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC16CP31 & Bosch EDC16C2 & Delphi CRD2",
                "Engine_code": "OM646 DE 22 LA",
            },
            "120 CDI V6 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 440,
                "tuned_torque": 560,
                "cc": 2987,
                "Type_ecu": "Bosch EDC16CP31 & Siemens/Continental EGS53",
                "Engine_code": "OM642",
            },
        },
        "W639 - 2010 - 2014": {
            "110 CDI 95hp": {
                "std_power": 95,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Bosch EDC16CP31 & Delphi CRD2.x & Bosch EDC17C01",
                "Engine_code": "OM646 DE 22 LA red.",
            },
            "111 CDI 116hp": {
                "std_power": 116,
                "tuned_power": 190,
                "std_torque": 290,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Bosch EDC16CP31 & Bosch EDC17C66",
                "Engine_code": "OM646 DE 22 LA red.",
            },
            "113 CDI 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 310,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Delphi CRD2.x & Delphi CRD3.x & Delphi DCM3.5 & Bosch EDC16C2",
                "Engine_code": "OM646 DE 22 LA red.",
            },
            "116 CDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2143,
                "Type_ecu": "Bosch EDC16CP31 & Delphi CRD2.x",
                "Engine_code": "OM646 DE 22 LA red.",
            },
            "122 CDI V6 224hp": {
                "std_power": 224,
                "tuned_power": 260,
                "std_torque": 440,
                "tuned_torque": 580,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP10 & Bosch EDC17C01",
                "Engine_code": "OM642",
            },
        },
    },
    "X-Klasse": {
        "2017 ->": {
            "220d 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 403,
                "tuned_torque": 460,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "OM699.300",
            },
            "250d 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310 & Bosch EDC17C66",
                "Engine_code": "OM699.301",
            },
            "350d 258hp": {
                "std_power": 258,
                "tuned_power": 290,
                "std_torque": 550,
                "tuned_torque": 690,
                "cc": 2987,
                "Type_ecu": "Bosch EDC17CP57",
                "Engine_code": "OM642.889",
            },
        },
    },
},
"Mercury": {
    "Grand Marquis": {
        "2003 - 2012": {
            "4.6 V8 239hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
    },
    "Mariner": {
        "2005 - 2012": {
            "2.5i 171hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "3.0 V6 240hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
    },
    "Mountaineer": {
        "2005 - 2010": {
            "4.0 V6 210hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "4.6 V8 292hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
    },
    "Sable": {
        "2004 - 2009": {
            "3.0 V6 201hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "3.5 V6 263hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
    },
},
"Mini": {
    "Clubman": {
        "(2007 - 2010) - R55": {
            "1.6D 110hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6T JCW 211hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6T 163hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6T 174hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6T 184hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
        "(2010 - 2015) - R55": {
            "1.6D 90hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6D 112hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0D 143hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0D 112hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6T 184hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6T 163hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6i 98hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6i 115hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6i 122hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
        "(2015 - 2018) - F54": {
            "1.5D 116hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0 SD 190hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5 Turbo 136hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5 Turbo 102hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T JCW 231hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T S 192hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
        "(2019 ->) - F54 LCI": {
            "1.5D 116hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0SD 190hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5T aut 102hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5T aut 136hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5T 102hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5T 136hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T S JCW 306hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T S 192hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
    },
    "Cooper": {
        "F56 - 2014 - 2018": {
            "1.5D 116hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5 Turbo 136hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5 Turbo 75hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5 Turbo 102hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
        "F56 - LCI - 2018 ->": {
            "1.5D 116hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5T aut 136hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5T 136hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5T 136hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.5T 102hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
        "R50 - 2002 - 2007": {
            "1.6i 115hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
        "R56 - 2007 - 2010": {
            "1.6D 109hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6i 122hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
        "R56 - 2010 - 2014": {
            "1.6D 112hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6D 90hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0D 112hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6i 122hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6i 115hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
    },
    "Cooper S": {
        "F56 - 2014 - 2018": {
            "2.0T JCW 211hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T AUT 192hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T JCW 231hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T JCW 231hp (B48)": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T 192hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T 163hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
        "F56 - LCI - 2018 ->": {
            "2.0T AUT 192hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T JCW GP 306hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T JCW 231hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "2.0T 192hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
        },
        "R53 - 2002 - 2007": {
            "1.6T 170hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 1950,
                "Type_ecu": "Bosch MD1CP001",
                "Engine_code": "",
            },
            "1.6T GP 218hp": {
                "std_power": 218,
                "tuned_power": 225,
                "std_torque": 250,
                "tuned_torque": 260,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental EMS2",
                "Engine_code": "W11B16",
            },
            "1.6T JCW 210hp": {
                "std_power": 210,
                "tuned_power": 225,
                "std_torque": 240,
                "tuned_torque": 260,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental EMS2",
                "Engine_code": "W11B16",
            },
            "1.6T 163hp": {
                "std_power": 163,
                "tuned_power": 175,
                "std_torque": 210,
                "tuned_torque": 225,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental EMS2 & Siemens/Continental EMS5150",
                "Engine_code": "W11 B16A",
            },
        },
        "R56 - 2007 - 2014": {
            "1.6T GP2 218hp": {
                "std_power": 218,
                "tuned_power": 230,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.2 & Bosch MEVD17.2.7",
                "Engine_code": "N18 B16",
            },
            "1.6T JCW 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.2.2 & Bosch MEVD17.2.2 & Bosch MEVD17.2.7 & Siemens/Continental EMS5150",
                "Engine_code": "N14 B16",
            },
            "1.6T 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental EMS2 & Bosch MED17.2 & Bosch MEVD17.2.2",
                "Engine_code": "N14B16",
            },
            "1.6T 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.2 & Bosch MEVD17.2",
                "Engine_code": "N14B16",
            },
            "1.6T 184hp": {
                "std_power": 184,
                "tuned_power": 210,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.1.1 & Bosch MEVD17.2.2 & Bosch MEVD17.2.7",
                "Engine_code": "N18 B16",
            },
        },
    },
    "Cooper S D": {
        "F56 - 2014 - 2018": {
            "2.0D 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MD1 & Bosch EDC17C50",
                "Engine_code": "B47D20A",
            },
        },
        "F56 - LCI - 2018 ->": {
            "2.0D 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20A",
            },
        },
        "R56 - 2010 - 2014": {
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 305,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41",
                "Engine_code": "N47C20A",
            },
            "2.0D 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 305,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C50",
                "Engine_code": "N47C20A",
            },
        },
    },
    "Countryman": {
        "F60 - 2017 - 2019": {
            "1.5D 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1CS001",
                "Engine_code": "B37C15A",
            },
            "2.0D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1CS001",
                "Engine_code": "B47D20A",
            },
            "2.0SD 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B47D20A",
            },
            "1.5T aut 102hp": {
                "std_power": 102,
                "tuned_power": 165,
                "std_torque": 180,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "B38A14A",
            },
            "1.5T aut 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B38A15M0",
            },
            "1.5T 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS201",
                "Engine_code": "B38A15M0",
            },
            "1.5T 102hp": {
                "std_power": 102,
                "tuned_power": 165,
                "std_torque": 180,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS201",
                "Engine_code": "B38A14A",
            },
            "2.0T S JCW 231hp": {
                "std_power": 231,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS201",
                "Engine_code": "B48A20B",
            },
            "2.0T S JCW 306hp": {
                "std_power": 306,
                "tuned_power": 335,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "",
            },
            "2.0T S 192hp": {
                "std_power": 192,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS201",
                "Engine_code": "B48B20A",
            },
            "SE Hybrid ALL4 1.5T 224hp": {
                "std_power": 224,
                "tuned_power": 275,
                "std_torque": 358,
                "tuned_torque": 450,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B38A15A",
            },
        },
        "F60 LCI - 2020 ->": {
            "1.5T aut 102hp": {
                "std_power": 102,
                "tuned_power": 165,
                "std_torque": 180,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "B38A15A",
            },
            "1.5T aut 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "B38A15M0",
            },
            "1.5T 102hp": {
                "std_power": 102,
                "tuned_power": 165,
                "std_torque": 180,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "B38A14A",
            },
            "1.5T 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B38A15M0",
            },
            "2.0T S JCW 231hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0T S 178hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "SE Hybrid ALL4 1.5T 224hp": {
                "std_power": 224,
                "tuned_power": 270,
                "std_torque": 358,
                "tuned_torque": 450,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B38A15A",
            },
        },
        "R60 - 2010 - 2016": {
            "1.6D 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 215,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C50",
                "Engine_code": "N47C16A",
            },
            "1.6D 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C50",
                "Engine_code": "N47C16A",
            },
            "2.0D 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 305,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C50",
                "Engine_code": "N47C20A",
            },
            "2.0D 112hp": {
                "std_power": 112,
                "tuned_power": 180,
                "std_torque": 270,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C50",
                "Engine_code": "N47C20",
            },
            "1.6T JCW 218hp": {
                "std_power": 218,
                "tuned_power": 230,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2.2 & Bosch MEVD17.2.7",
                "Engine_code": "N18B16",
            },
            "1.6T 184hp": {
                "std_power": 184,
                "tuned_power": 210,
                "std_torque": 240,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2.2 & Bosch MEVD17.2.7 & Bosch MED17.1.1 & Bosch MEVD17.2.27",
                "Engine_code": "N18B16",
            },
            "1.6i 98hp": {
                "std_power": 98,
                "tuned_power": 130,
                "std_torque": 153,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.2.2",
                "Engine_code": "N16B16",
            },
            "1.6i 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.2.2",
                "Engine_code": "N16B16",
            },
            "1.6i 122hp": {
                "std_power": 122,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.2.2",
                "Engine_code": "N16B16",
            },
        },
    },
    "One": {
        "F56 - 2014 - 2018": {
            "1.5D 95hp": {
                "std_power": 95,
                "tuned_power": 150,
                "std_torque": 220,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch EDC17C50 & Bosch MD1CS001",
                "Engine_code": "B37C15A",
            },
            "1.2 Turbo 102hp": {
                "std_power": 102,
                "tuned_power": 150,
                "std_torque": 180,
                "tuned_torque": 250,
                "cc": 1198,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B38A12U0",
            },
            "1.2 Turbo 75hp": {
                "std_power": 75,
                "tuned_power": 150,
                "std_torque": 180,
                "tuned_torque": 250,
                "cc": 1198,
                "Type_ecu": "Bosch MEVD17.2.3",
                "Engine_code": "B38A12U0",
            },
            "1.5 Turbo 102hp": {
                "std_power": 102,
                "tuned_power": 165,
                "std_torque": 190,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Bosch MG1 & Bosch MEVD17.2.3",
                "Engine_code": "B38A15M0",
            },
            "1.5 Turbo 75hp": {
                "std_power": 75,
                "tuned_power": 165,
                "std_torque": 160,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Bosch MG1",
                "Engine_code": "B38A15M0",
            },
            "1.5 Turbo 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Bosch MEVD17.2.3 & Bosch MG1CS201",
                "Engine_code": "B38A15M0",
            },
        },
        "F56 - LCI - 2018 ->": {
            "1.5D 95hp": {
                "std_power": 95,
                "tuned_power": 150,
                "std_torque": 220,
                "tuned_torque": 330,
                "cc": 1496,
                "Type_ecu": "Bosch MD1CS001",
                "Engine_code": "B37C15A",
            },
            "1.2T 75hp": {
                "std_power": 75,
                "tuned_power": 150,
                "std_torque": 180,
                "tuned_torque": 250,
                "cc": 1198,
                "Type_ecu": "Bosch MED17.2.3 & Bosch MG1CS201",
                "Engine_code": "",
            },
            "1.2T 102hp": {
                "std_power": 102,
                "tuned_power": 150,
                "std_torque": 180,
                "tuned_torque": 250,
                "cc": 1198,
                "Type_ecu": "Bosch MG1CS201 & Bosch MED17.2",
                "Engine_code": "",
            },
            "1.5T aut 102hp": {
                "std_power": 102,
                "tuned_power": 165,
                "std_torque": 190,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B38A15A",
            },
            "1.5T 102hp": {
                "std_power": 102,
                "tuned_power": 165,
                "std_torque": 190,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Bosch MG1CS201",
                "Engine_code": "B38A15A",
            },
        },
        "R50 - 2002 - 2007": {
            "1.4D 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 180,
                "tuned_torque": 225,
                "cc": 1364,
                "Type_ecu": "Bosch DDE4.0",
                "Engine_code": "N14B16A",
            },
            "1.4D 88hp": {
                "std_power": 88,
                "tuned_power": 110,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1364,
                "Type_ecu": "Bosch EDC16C35",
                "Engine_code": "N14B16A",
            },
            "1.6i 90hp": {
                "std_power": 90,
                "tuned_power": 125,
                "std_torque": 140,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental EMS2 & Siemens/Continental EMS5150",
                "Engine_code": "W10B16",
            },
            "1.6i 116hp": {
                "std_power": 116,
                "tuned_power": 125,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental EMS2",
                "Engine_code": "W10B16",
            },
        },
        "R56 - 2007 - 2010": {
            "1.6D 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C35",
                "Engine_code": "N47C16K1",
            },
            "1.4i 75hp": {
                "std_power": 75,
                "tuned_power": 105,
                "std_torque": 120,
                "tuned_torque": 150,
                "cc": 1397,
                "Type_ecu": "Bosch MED17.2 & Bosch MEV17.2",
                "Engine_code": "N12B14A",
            },
            "1.4i 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 140,
                "tuned_torque": 150,
                "cc": 1397,
                "Type_ecu": "Bosch MEV17.2",
                "Engine_code": "N12B14A",
            },
            "1.6i 98hp": {
                "std_power": 98,
                "tuned_power": 125,
                "std_torque": 153,
                "tuned_torque": 173,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.2.2 & Bosch EDC16C35",
                "Engine_code": "W10B16A",
            },
        },
        "R56 - 2010 - 2014": {
            "1.5D 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1496,
                "Type_ecu": "Bosch EDC17C50",
                "Engine_code": "B37C15A",
            },
            "1.6D 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 215,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C35 & Bosch EDC17C41 & Bosch EDC17C50",
                "Engine_code": "N47C16K1",
            },
            "1.4i 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 120,
                "tuned_torque": 145,
                "cc": 1397,
                "Type_ecu": "Bosch MED17.2 & Bosch MEV17.2.2",
                "Engine_code": "N12B14A",
            },
            "1.6i 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "N12B16",
            },
            "1.6i 122hp": {
                "std_power": 122,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.2.2",
                "Engine_code": "N12B16",
            },
            "1.6i 75hp": {
                "std_power": 75,
                "tuned_power": 130,
                "std_torque": 140,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.2.2",
                "Engine_code": "N12B16",
            },
            "1.6i 98hp": {
                "std_power": 98,
                "tuned_power": 130,
                "std_torque": 153,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.2.2",
                "Engine_code": "W10B16A",
            },
        },
    },
    "Paceman": {
        "2013 ->": {
            "1.6D 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C41",
                "Engine_code": "N47C16A",
            },
            "2.0D 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 305,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50 & Bosch EDC17C41",
                "Engine_code": "N47",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 305,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C50",
                "Engine_code": "N47",
            },
            "2.0D 112hp": {
                "std_power": 112,
                "tuned_power": 180,
                "std_torque": 270,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C50",
                "Engine_code": "N47",
            },
            "1.6T 184hp": {
                "std_power": 184,
                "tuned_power": 210,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.1.1",
                "Engine_code": "N18B16A",
            },
            "1.6T 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "N18B16A",
            },
            "1.6T 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "N18B16A",
            },
            "1.6i 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "N47C16A",
            },
            "1.6i 122hp": {
                "std_power": 122,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.2.2",
                "Engine_code": "N16B16A",
            },
            "JCW 218hp": {
                "std_power": 218,
                "tuned_power": 230,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2.2 & Bosch MEVD17.2.7",
                "Engine_code": "N18B16C",
            },
        },
    },
    "Roadster": {
        "R58 - 2012 ->": {
            "2.0 SD 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 305,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C41 & Bosch EDC17C50",
                "Engine_code": "N47C20A",
            },
            "1.6T JCW 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.2",
                "Engine_code": "N18B16A",
            },
            "1.6T 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "N18B16A",
            },
            "1.6T 184hp": {
                "std_power": 184,
                "tuned_power": 210,
                "std_torque": 260,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.2.2 & Bosch MEVD17.2.7",
                "Engine_code": "N18B16A",
            },
            "1.6i 75hp": {
                "std_power": 75,
                "tuned_power": 130,
                "std_torque": 122,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "W10B16A",
            },
            "1.6i 98hp": {
                "std_power": 98,
                "tuned_power": 130,
                "std_torque": 153,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "W10B16A",
            },
            "1.6i 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "W10B16A",
            },
            "1.6i 122hp": {
                "std_power": 122,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.2.2",
                "Engine_code": "W10B16A",
            },
        },
    },
},
"Mitsubishi": {
    "ASX": {
        "2010 - 2016": {
            "1.6 DID 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "9HD",
            },
            "1.8 DID 115hp": {
                "std_power": 115,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1798,
                "Type_ecu": "Denso RA6 & Denso RA6 4N13",
                "Engine_code": "4N13",
            },
            "1.8 DID 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1798,
                "Type_ecu": "Denso RA6 & Denso RA6 4N13",
                "Engine_code": "4N13",
            },
            "2.2 DID 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2268,
                "Type_ecu": "Denso RA6 4N13",
                "Engine_code": "4N14",
            },
            "2.2 DID 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2268,
                "Type_ecu": "Denso RA6",
                "Engine_code": "4N14",
            },
        },
        "2016 - 2021": {
            "1.6 DID 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "9HD",
            },
            "2.2 DID 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2268,
                "Type_ecu": "Denso RA6",
                "Engine_code": "4N14",
            },
        },
    },
    "Carisma": {
        "All": {
            "1.9 D 115hp": {
                "std_power": 115,
                "tuned_power": 142,
                "std_torque": 265,
                "tuned_torque": 365,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q1",
            },
            "1.9 D 102hp": {
                "std_power": 102,
                "tuned_power": 126,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q1",
            },
        },
    },
    "Colt": {
        "All": {
            "1.5 Di-D 95hp": {
                "std_power": 95,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 275,
                "cc": 1493,
                "Type_ecu": "Bosch EDC16C33 & Bosch EDC16C31",
                "Engine_code": "OM 639",
            },
            "1.5 Turbo Ralliart 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 210,
                "tuned_torque": 270,
                "cc": 1468,
                "Type_ecu": "Mitsubishi E6T42 & Mitsubishi E6T73 & Mitsubishi E6T42490 & Mitsubishi MH8115F",
                "Engine_code": "4G15",
            },
        },
    },
    "Eclipse Cross": {
        "2017 - 2020": {
            "2.2 DiD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2268,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 T-MIVEC 163hp": {
                "std_power": 163,
                "tuned_power": 176,
                "std_torque": 250,
                "tuned_torque": 270,
                "cc": 1499,
                "Type_ecu": "Mitsubishi MH8601",
                "Engine_code": "",
            },
        },
    },
    "Grandis": {
        "2004 - 2011": {
            "2.0 DiD 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BSY/BSL",
            },
        },
    },
    "L200": {
        "2010 - 2015": {
            "2.5 DiD 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 315,
                "tuned_torque": 360,
                "cc": 2477,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "4D56",
            },
            "2.5 DiD 178hp": {
                "std_power": 178,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2477,
                "Type_ecu": "Denso RHF6 & Denso RA6 & Denso RHF4 & Denso SH7058",
                "Engine_code": "4D56",
            },
        },
        "2015 - 2018": {
            "2.4 DiD 154hp": {
                "std_power": 154,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 500,
                "cc": 2442,
                "Type_ecu": "Denso SH7058 & Denso 4N15",
                "Engine_code": "4N15",
            },
            "2.4 DiD 181hp": {
                "std_power": 181,
                "tuned_power": 205,
                "std_torque": 430,
                "tuned_torque": 500,
                "cc": 2442,
                "Type_ecu": "Denso RA6 & Denso RHF4 & Denso SH7059",
                "Engine_code": "4N15",
            },
        },
        "2019 - 2023": {
            "2.3 DiD 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2268,
                "Type_ecu": "Denso RA6 4N14",
                "Engine_code": "4N14",
            },
            "2.4 DiD 181hp": {
                "std_power": 181,
                "tuned_power": 205,
                "std_torque": 430,
                "tuned_torque": 500,
                "cc": 2442,
                "Type_ecu": "Denso 275722-4810",
                "Engine_code": "4N15",
            },
        },
    },
    "Lancer": {
        "All": {
            "1.8 DiD 116hp": {
                "std_power": 116,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1798,
                "Type_ecu": "Denso RA6 & Mitsubishi E6T78879",
                "Engine_code": "4N13",
            },
            "1.8 DiD 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1798,
                "Type_ecu": "Denso RA6 & Mitsubishi E6T72374",
                "Engine_code": "4N13",
            },
            "2.0 DiD 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BWC",
            },
        },
    },
    "Lancer EVO": {
        "IX": {
            "2.0 Turbo 280hp": {
                "std_power": 280,
                "tuned_power": 320,
                "std_torque": 355,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Mitsubishi E6T469xx & Mitsubishi E6T34874 H",
                "Engine_code": "4G63",
            },
            "2.0 Turbo 295hp": {
                "std_power": 295,
                "tuned_power": 330,
                "std_torque": 366,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "Mitsubishi E6T469xx",
                "Engine_code": "4G63",
            },
        },
        "X": {
            "2.0 Turbo 295hp": {
                "std_power": 295,
                "tuned_power": 330,
                "std_torque": 366,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "Mitsubishi E6T72xxx & Mitsubishi E6T79089 & Mitsubishi E6T71591 & Mitsubishi E6T71500",
                "Engine_code": "4B11T",
            },
        },
    },
    "Montero": {
        "2015 - 2019": {
            "2.4d 181hp": {
                "std_power": 181,
                "tuned_power": 210,
                "std_torque": 430,
                "tuned_torque": 470,
                "cc": 2442,
                "Type_ecu": "",
                "Engine_code": "4N15",
            },
            "2.5 D-ID 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 324,
                "tuned_torque": 360,
                "cc": 2477,
                "Type_ecu": "Denso SH7059",
                "Engine_code": "4D56",
            },
        },
        "2019 ->": {
            "2.4d 181hp": {
                "std_power": 181,
                "tuned_power": 210,
                "std_torque": 430,
                "tuned_torque": 470,
                "cc": 2442,
                "Type_ecu": "Denso SH7059",
                "Engine_code": "4N15",
            },
        },
    },
    "Outlander": {
        "2007 - 2012": {
            "1.8 DiD 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1798,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 DiD 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31 & Denso RA6",
                "Engine_code": "BYS",
            },
            "2.2 DiD 156hp": {
                "std_power": 156,
                "tuned_power": 186,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16CP39",
                "Engine_code": "DW12",
            },
            "2.2 DiD 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2179,
                "Type_ecu": "Denso RA6",
                "Engine_code": "DW12",
            },
            "2.2 DiD 140hp": {
                "std_power": 140,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16C39 & Denso RA6",
                "Engine_code": "DW12",
            },
            "2.0 Turbo 202hp": {
                "std_power": 202,
                "tuned_power": 260,
                "std_torque": 303,
                "tuned_torque": 370,
                "cc": 1997,
                "Type_ecu": "Mitsubishi E6T38xxx",
                "Engine_code": "4G63T",
            },
        },
        "2013 - 2016": {
            "2.2 DiD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2179,
                "Type_ecu": "Denso RA6 & Denso SH7059 & Denso RA6 4N14 & Mitsubishi E6T80778",
                "Engine_code": "DW12",
            },
        },
        "2017 - 2020": {
            "2.2 DiD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2179,
                "Type_ecu": "Denso RA6 4N13 & Denso RA6 4N14",
                "Engine_code": "DW12",
            },
            "2.4 Mivec 224hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Pajero": {
        "All": {
            "2.5 DiD 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 2477,
                "Type_ecu": "Mitsubishi E6T0xx",
                "Engine_code": "4D56",
            },
            "3.2 DiD 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 373,
                "tuned_torque": 440,
                "cc": 3200,
                "Type_ecu": "Denso RA6 & Denso SH7058",
                "Engine_code": "4M41",
            },
            "3.2 DiD 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 441,
                "tuned_torque": 500,
                "cc": 3200,
                "Type_ecu": "Denso RA6 & Denso SH7058 & Denso 4M41",
                "Engine_code": "4M41",
            },
            "3.2 DiD 160hp": {
                "std_power": 160,
                "tuned_power": 195,
                "std_torque": 373,
                "tuned_torque": 440,
                "cc": 3200,
                "Type_ecu": "Mitsubishi E6T0xx & Denso RHF4",
                "Engine_code": "4M41",
            },
            "3.2 DiD 190hp": {
                "std_power": 190,
                "tuned_power": 240,
                "std_torque": 441,
                "tuned_torque": 500,
                "cc": 3200,
                "Type_ecu": "Denso RA6 4N13 & Denso SH7059",
                "Engine_code": "4M41",
            },
            "Sport 2.4 DI-D 181hp": {
                "std_power": 181,
                "tuned_power": 210,
                "std_torque": 430,
                "tuned_torque": 500,
                "cc": 2442,
                "Type_ecu": "Denso Mivec & Denso RA7",
                "Engine_code": "4N15",
            },
        },
    },
    "Spacestar": {
        "All": {
            "1.9 DiD 115hp": {
                "std_power": 115,
                "tuned_power": 142,
                "std_torque": 265,
                "tuned_torque": 365,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q1",
            },
            "1.9 DiD 102hp": {
                "std_power": 102,
                "tuned_power": 129,
                "std_torque": 215,
                "tuned_torque": 275,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q1",
            },
        },
    },
},
"Nissan": {
    "350Z": {
        "All": {
            "3.5 V6 - 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 3498,
                "Type_ecu": "Hitachi MEC100-xxx & Hitachi MEC31-513 & Denso SH7058 & Hitachi MEC33-940",
                "Engine_code": "VQ35",
            },
            "3.5 V6 - 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 3498,
                "Type_ecu": "Hitachi MEC100-xxx & Hitachi MEC60-270 & Denso SH7058",
                "Engine_code": "VQ35",
            },
            "3.5 V6 - 313hp": {
                "std_power": 313,
                "tuned_power": 335,
                "std_torque": 358,
                "tuned_torque": 378,
                "cc": 3498,
                "Type_ecu": "Hitachi MEC100-xxx",
                "Engine_code": "VQ35",
            },
        },
    },
    "370Z": {
        "All": {
            "3.7 V6 Nismo 344hp": {
                "std_power": 344,
                "tuned_power": 360,
                "std_torque": 371,
                "tuned_torque": 386,
                "cc": 3696,
                "Type_ecu": "Hitachi SH7059",
                "Engine_code": "VQ37VHR",
            },
            "3.7 V6 328hp": {
                "std_power": 328,
                "tuned_power": 348,
                "std_torque": 363,
                "tuned_torque": 383,
                "cc": 3696,
                "Type_ecu": "Hitachi MEC107-400 & Hitachi SH7059",
                "Engine_code": "VQ37VHR",
            },
            "3.7 V6 331hp": {
                "std_power": 331,
                "tuned_power": 348,
                "std_torque": 366,
                "tuned_torque": 383,
                "cc": 3696,
                "Type_ecu": "Hitachi SH7059",
                "Engine_code": "VQ37VHR",
            },
        },
    },
    "Almera": {
        "All": {
            "1.5 DCi 82hp": {
                "std_power": 82,
                "tuned_power": 107,
                "std_torque": 185,
                "tuned_torque": 230,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "2.2 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 138,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 2184,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "YD22DDT",
            },
            "2.2 DCi 136hp": {
                "std_power": 136,
                "tuned_power": 163,
                "std_torque": 304,
                "tuned_torque": 360,
                "cc": 2184,
                "Type_ecu": "Denso D3",
                "Engine_code": "YD22DDT",
            },
            "2.2 DCi 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 248,
                "tuned_torque": 300,
                "cc": 2184,
                "Type_ecu": "Denso E1x",
                "Engine_code": "YD22DDT",
            },
            "2.2TD 126hp": {
                "std_power": 126,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 325,
                "cc": 2184,
                "Type_ecu": "",
                "Engine_code": "YD22DDT",
            },
        },
    },
    "Cube": {
        "All": {
            "1.5 dci 85hp": {
                "std_power": 85,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
        },
    },
    "Evalia": {
        "2014 ->": {
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "",
                "Engine_code": "K9K",
            },
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305",
                "Engine_code": "K9K",
            },
        },
    },
    "GT-R": {
        "2009 ->": {
            "3.8 V6 Turbo 485hp": {
                "std_power": 485,
                "tuned_power": 550,
                "std_torque": 588,
                "tuned_torque": 720,
                "cc": 3799,
                "Type_ecu": "Hitachi MEC125-xxx",
                "Engine_code": "VR38DETT",
            },
            "3.8 V6 Turbo 530hp": {
                "std_power": 530,
                "tuned_power": 580,
                "std_torque": 612,
                "tuned_torque": 750,
                "cc": 3799,
                "Type_ecu": "Hitachi MEC125-xxx",
                "Engine_code": "VR38DETT",
            },
            "3.8 V6 Turbo 550hp": {
                "std_power": 550,
                "tuned_power": 600,
                "std_torque": 630,
                "tuned_torque": 770,
                "cc": 3799,
                "Type_ecu": "Hitachi MEC125-xxx & Denso SH7058",
                "Engine_code": "VR38DETT",
            },
            "3.8 V6 Turbo 570hp": {
                "std_power": 570,
                "tuned_power": 600,
                "std_torque": 637,
                "tuned_torque": 770,
                "cc": 3799,
                "Type_ecu": "Hitachi SH7059 & Hitachi SH72533",
                "Engine_code": "VR38DETT",
            },
        },
    },
    "Interstar": {
        "All": {
            "2.5 DCI 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 335,
                "cc": 2463,
                "Type_ecu": "",
                "Engine_code": "9GU",
            },
            "2.5 DCI 120hp": {
                "std_power": 120,
                "tuned_power": 147,
                "std_torque": 300,
                "tuned_torque": 375,
                "cc": 2463,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC16C36",
                "Engine_code": "9GU",
            },
            "2.5 DCI 145hp": {
                "std_power": 145,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 405,
                "cc": 2463,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "9GU",
            },
        },
    },
    "Juke": {
        "2010 - 2019": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 138,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Siemens/Continental SID301 & Siemens/Continental SID310 & Siemens/Continental SID307 & Siemens/Continental SID306 & Siemens/Continental SID309",
                "Engine_code": "K9K",
            },
            "1.2 DIG-T 115hp": {
                "std_power": 115,
                "tuned_power": 155,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "HR12DDT",
            },
            "1.6 DIG-T 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1618,
                "Type_ecu": "Hitachi BED301-000 & Hitachi BED431 & Hitachi BED305 & Denso SH7058",
                "Engine_code": "MR16DDT",
            },
            "1.6 Turbo Nismo RS 218hp": {
                "std_power": 218,
                "tuned_power": 230,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1618,
                "Type_ecu": "Hitachi BED305 & Hitachi BED301-000 & Hitachi BED434",
                "Engine_code": "MR16DDT",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1618,
                "Type_ecu": "Hitachi BED301-000",
                "Engine_code": "MR16DDT",
            },
            "1.6i 117hp": {
                "std_power": 117,
                "tuned_power": 123,
                "std_torque": 158,
                "tuned_torque": 164,
                "cc": 1598,
                "Type_ecu": "Hitachi MEC940-xxx",
                "Engine_code": "HR16DE",
            },
        },
        "2020 ->": {
            "1.0 DIG-T 117hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Kubistar": {
        "All": {
            "1.5 DCi 86hp": {
                "std_power": 86,
                "tuned_power": 112,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 60hp": {
                "std_power": 60,
                "tuned_power": 84,
                "std_torque": 170,
                "tuned_torque": 220,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
        },
    },
    "Micra": {
        "2013 - 2016": {
            "1.5 DCi 86hp": {
                "std_power": 86,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 65hp": {
                "std_power": 65,
                "tuned_power": 85,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 82hp": {
                "std_power": 82,
                "tuned_power": 102,
                "std_torque": 185,
                "tuned_torque": 225,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
        },
        "2016 - 2020": {
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 220,
                "tuned_torque": 260,
                "cc": 161,
                "Type_ecu": "Delphi DCM1.2 & Bosch EDC17C84",
                "Engine_code": "K9K",
            },
            "0.9 TCE 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 140,
                "tuned_torque": 190,
                "cc": 898,
                "Type_ecu": "Siemens/Continental EMS3125",
                "Engine_code": "H4BA400",
            },
            "1.0 DIG-T 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2021 -> ...": {
            "1.0 DIG-T 90hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Murano": {
        "2010 ->": {
            "2.5 DCi 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 450,
                "tuned_torque": 540,
                "cc": 2488,
                "Type_ecu": "Denso SH7059",
                "Engine_code": "YD25DDTi",
            },
        },
    },
    "NV200": {
        "2009 ->": {
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2 & Bosch EDC17C42 & Delphi DCM3.4 & Bosch EDC17C84",
                "Engine_code": "K9K",
            },
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID305 & Siemens/Continental SID310",
                "Engine_code": "K9K",
            },
        },
    },
    "NV300": {
        "2016 - 2019": {
            "1.6 DCi (Euro 6) 95hp": {
                "std_power": 95,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 DCi (Euro 6) 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "",
            },
            "1.6 DCi Bi-Turbo (Euro 6) 125hp": {
                "std_power": 125,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "",
            },
            "1.6 DCi Bi-Turbo (Euro 6) 145hp": {
                "std_power": 145,
                "tuned_power": 175,
                "std_torque": 340,
                "tuned_torque": 390,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "",
            },
        },
        "2020 ->": {
            "1.6 DCi 95hp": {
                "std_power": 95,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 DCi 120hp": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "2.0 DCi 145hp": {
                "std_power": 145,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "2.0 DCi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "NV400": {
        "2010 - 2016": {
            "2.3 DCi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 285,
                "tuned_torque": 345,
                "cc": 2299,
                "Type_ecu": "Bosch EDC17C11",
                "Engine_code": "YS23DDTT",
            },
            "2.3 DCi 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 2299,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42",
                "Engine_code": "YS23DDTT",
            },
            "2.3 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2299,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42 & Siemens/Continental SID310 & Bosch EDC17C45",
                "Engine_code": "YS23DDTT",
            },
        },
        "2016 - 2019": {
            "2.3 DCi (Euro 6) 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 285,
                "tuned_torque": 345,
                "cc": 2299,
                "Type_ecu": "Bosch EDC17C42 & Siemens/Continental SID310",
                "Engine_code": "YS23DDTT",
            },
            "2.3 DCi (Euro 6) 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 340,
                "tuned_torque": 390,
                "cc": 2299,
                "Type_ecu": "Siemens/Continental SID310 & Siemens/Continental SID309",
                "Engine_code": "YS23DDTT",
            },
            "2.3 DCi Bi-Turbo (Euro 6) 145hp": {
                "std_power": 145,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2299,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "YS23DDTT",
            },
            "2.3 DCi Bi-Turbo (Euro 6) 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 2299,
                "Type_ecu": "Siemens/Continental SID310 & Siemens/Continental SID309",
                "Engine_code": "YS23DDTT",
            },
            "2.3 DCi Bi-Turbo (Euro 6) 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 2299,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "YS23DDTT",
            },
        },
        "2020 ->": {
            "2.3 DCi 135hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.3 DCi 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.3 DCi 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
   
    "Navara": {
        "2019 ->": {
            "2.3 DCi Bi-turbo (Euro 6) 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310 & Siemens/Continental SID309",
                "Engine_code": "YS23DDTT",
            },
            "2.3 DCi Bi-turbo (Euro 6) 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 425,
                "tuned_torque": 460,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "",
            },
        },
        "D23 - 2016 - 2019": {
            "2.3 DCi Bi-turbo (Euro 5) 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 403,
                "tuned_torque": 460,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID309 & Siemens/Continental SID310",
                "Engine_code": "YS23DDTT",
            },
            "2.3 DCi Bi-turbo (Euro 5) 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310 & Siemens/Continental SID309",
                "Engine_code": "YS23DDTT",
            },
            "2.3 DCi Bi-turbo (Euro 6) 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 403,
                "tuned_torque": 460,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID309 & Siemens/Continental SID310",
                "Engine_code": "YS23DDTT",
            },
            "2.3 DCi Bi-turbo (Euro 6) 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310 & Siemens/Continental SID309",
                "Engine_code": "YS23DDTT",
            },
        },
        "D40 - 2005 - 2015": {
            "2.5 DCi 174hp": {
                "std_power": 174,
                "tuned_power": 210,
                "std_torque": 403,
                "tuned_torque": 460,
                "cc": 2488,
                "Type_ecu": "Denso D3 & Denso SH7058 & Denso H1",
                "Engine_code": "YD25DDTi",
            },
            "2.5 DCi 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 450,
                "tuned_torque": 540,
                "cc": 2488,
                "Type_ecu": "Denso D3 & Denso SH7059 & Denso H1",
                "Engine_code": "YD25DDTi",
            },
            "2.5 Di 133hp": {
                "std_power": 133,
                "tuned_power": 165,
                "std_torque": 304,
                "tuned_torque": 365,
                "cc": 2488,
                "Type_ecu": "Denso D3",
                "Engine_code": "YD25DDTi",
            },
            "3.0 DCi 231hp": {
                "std_power": 231,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2991,
                "Type_ecu": "Bosch EDC16CP42 & Bosch EDC16CP33",
                "Engine_code": "V9X",
            },
        },
    },
    "Note": {
        "2006 - 2009": {
            "1.5 DCi 106hp": {
                "std_power": 106,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301",
                "Engine_code": "K9K",
            },
            "1.5 DCi 86hp": {
                "std_power": 86,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 68hp": {
                "std_power": 68,
                "tuned_power": 82,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.6i 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 153,
                "tuned_torque": 168,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "HR16DE",
            },
        },
        "2009 - 2013": {
            "1.5 DCi 106hp": {
                "std_power": 106,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.6i 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 153,
                "tuned_torque": 168,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "HR16DE",
            },
        },
        "2013 ->": {
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4 & Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "K9K",
            },
            "1.2 DIG 98hp": {
                "std_power": 98,
                "tuned_power": 108,
                "std_torque": 147,
                "tuned_torque": 162,
                "cc": 1198,
                "Type_ecu": "Hitachi SLT2805J",
                "Engine_code": "HR12DDR",
            },
        },
    },
    "Pathfinder": {
        "2005 - 2013": {
            "2.5 DCi 174hp": {
                "std_power": 174,
                "tuned_power": 210,
                "std_torque": 403,
                "tuned_torque": 460,
                "cc": 2488,
                "Type_ecu": "Denso H1 & Denso SH7058 & Denso D3",
                "Engine_code": "YD25DDTi",
            },
            "2.5 DCi 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2488,
                "Type_ecu": "Denso SH7059",
                "Engine_code": "YD25DDTi",
            },
            "2.5 DCi 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 403,
                "tuned_torque": 460,
                "cc": 2488,
                "Type_ecu": "Denso D3",
                "Engine_code": "YD25DDTi",
            },
            "3.0 DCi 231hp": {
                "std_power": 231,
                "tuned_power": 280,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 2991,
                "Type_ecu": "Bosch EDC16+",
                "Engine_code": "V9X",
            },
            "4.0i V6 266hp": {
                "std_power": 266,
                "tuned_power": 281,
                "std_torque": 385,
                "tuned_torque": 405,
                "cc": 3954,
                "Type_ecu": "Hitachi MEC35-xxx",
                "Engine_code": "VQ40DE",
            },
        },
    },
    "Patrol": {
        "1998 - 2009": {
            "3.0 DI 158hp": {
                "std_power": 158,
                "tuned_power": 188,
                "std_torque": 323,
                "tuned_torque": 400,
                "cc": 2953,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "ZD30ETi",
            },
            "3.0 DI 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 2953,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC16C41",
                "Engine_code": "ZD30ETi",
            },
        },
    },
    "Primastar": {
        "2002 - 2006": {
            "1.9 DCi 82hp": {
                "std_power": 82,
                "tuned_power": 110,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "G9QU760",
            },
            "1.9 DCi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC15C3",
                "Engine_code": "G9QU760",
            },
            "2.0 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 145,
                "std_torque": 240,
                "tuned_torque": 350,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.0 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 290,
                "tuned_torque": 350,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C11",
                "Engine_code": "M9R",
            },
            "2.5 DCi 145hp": {
                "std_power": 145,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U730",
            },
            "2.5 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 149,
                "std_torque": 290,
                "tuned_torque": 370,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC15C2",
                "Engine_code": "G9U730",
            },
        },
        "2007 ->": {
            "2.0 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 350,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33 & Bosch EDC16C36 & Bosch EDC17C42 & Bosch EDC17C11",
                "Engine_code": "M9R",
            },
            "2.0 DCi 114hp": {
                "std_power": 114,
                "tuned_power": 145,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33 & Bosch EDC17C11 & Bosch EDC17C42 & Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.5 DCi 146hp": {
                "std_power": 146,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 405,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U730",
            },
        },
    },
    "Primera": {
        "2002 - 2008": {
            "1.9 DCi 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "B204I",
            },
            "1.9 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 160,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1870,
                "Type_ecu": "",
                "Engine_code": "M9R",
            },
            "2.2 DCi 138hp": {
                "std_power": 138,
                "tuned_power": 170,
                "std_torque": 314,
                "tuned_torque": 374,
                "cc": 2184,
                "Type_ecu": "Denso D3",
                "Engine_code": "YD22DDTi",
            },
            "2.2 DCi 126hp": {
                "std_power": 126,
                "tuned_power": 155,
                "std_torque": 280,
                "tuned_torque": 320,
                "cc": 2184,
                "Type_ecu": "Denso SH7055",
                "Engine_code": "YD22DDTi",
            },
        },
    },
    "Pulsar": {
        "2014 ->": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310 & Siemens/Continental SID309",
                "Engine_code": "K9K",
            },
            "1.2 DIG-T CVT 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 165,
                "tuned_torque": 190,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "H5FT",
            },
            "1.2 DIG-T 115hp": {
                "std_power": 115,
                "tuned_power": 155,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "H5FT",
            },
            "1.6 DIG-T 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1618,
                "Type_ecu": "Hitachi GEN2",
                "Engine_code": "MR16DDT",
            },
        },
    },
    "Qashqai": {
        "2007 - 2013": {
            "1.5 DCi 106hp": {
                "std_power": 106,
                "tuned_power": 125,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301 & Siemens/Continental SID305 & Siemens/Continental SID306 & Siemens/Continental SID303",
                "Engine_code": "K9K",
            },
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301 & Siemens/Continental SID303 & Siemens/Continental SID305 & Siemens/Continental SID306",
                "Engine_code": "K9K",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "R9M",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1994,
                "Type_ecu": "Bosch EDC16CP33 & Denso SH7055",
                "Engine_code": "MR20DE",
            },
        },
        "2014 - 2016": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310 & Siemens/Continental SID309 & Bosch MD1CS006 & Bosch EDC17C84",
                "Engine_code": "K9K",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84 & Bosch EDC17C42",
                "Engine_code": "R9M",
            },
            "1.2 DIG-T CVT 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 165,
                "tuned_torque": 185,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "H5FT",
            },
            "1.2 DIG-T 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "H5FT",
            },
            "1.6 DIG-T 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1618,
                "Type_ecu": "Hitachi SH72533 & Hitachi BED431",
                "Engine_code": "MR16DDT",
            },
        },
        "2017 - 2019": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310 & Bosch MD1CS006 & Siemens/Continental SID305",
                "Engine_code": "K9K 636",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "1.2 DIG-T CVT 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 165,
                "tuned_torque": 185,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "H5FT",
            },
            "1.2 DIG-T 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "HR12DDT",
            },
            "1.6 DIG-T 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1618,
                "Type_ecu": "Hitachi SH72533 & Hitachi BED431",
                "Engine_code": "MR16DDT",
            },
        },
        "2019 - 2020": {
            "1.5 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 285,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "K9K",
            },
            "1.7 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N",
            },
            "1.3 DIG-T 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 DIG-T 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2021 -> ...": {
            "1.3 DIG-T 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 DIG-T 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Sentra": {
        "2016 - 2019": {
            "1.6 Turbo 188hp": {
                "std_power": 188,
                "tuned_power": 220,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1618,
                "Type_ecu": "",
                "Engine_code": "MR16DDT",
            },
        },
    },
    "Terrano 2": {
        "All": {
            "2.7 TD 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 2664,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC15VM+",
                "Engine_code": "TD27Ti",
            },
        },
    },
    "X-Trail": {
        "-> 2014": {
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33",
                "Engine_code": "M9R",
            },
            "2.0 DCi 173hp": {
                "std_power": 173,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33",
                "Engine_code": "M9R",
            },
            "2.2 DCi 136hp": {
                "std_power": 136,
                "tuned_power": 168,
                "std_torque": 314,
                "tuned_torque": 370,
                "cc": 2184,
                "Type_ecu": "Denso SH7055 & Denso D40 & Denso D3",
                "Engine_code": "YD22DDTT",
            },
        },
        "2014 - 2017": {
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "2.0 DCi 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "M9R",
            },
            "1.6 DIG-T 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1618,
                "Type_ecu": "Bosch EDC17C84 & Hitachi BED431",
                "Engine_code": "MR16DDT",
            },
        },
        "2018 ->": {
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "1.7 dCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N",
            },
            "2.0 DCi 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C84 & Bosch EDC16CP33",
                "Engine_code": "M9R",
            },
            "1.3 DIG-T 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 DIG-T 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1618,
                "Type_ecu": "Hitachi BED431",
                "Engine_code": "MR16DDT",
            },
        },
    },
},
"Oldsmobile": {
    "Alero": {
        "1998 - 2004": {
            "2.2i 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 203,
                "tuned_torque": 230,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "Z22SE",
            },
            "2.4i 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 235,
                "cc": 2400,
                "Type_ecu": "",
                "Engine_code": "LD9",
            },
            "3.4 V6 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 271,
                "tuned_torque": 300,
                "cc": 3350,
                "Type_ecu": "",
                "Engine_code": "LA1",
            },
        },
    },
    "Bravada": {
        "1996 - 2001": {
            "4.3 V6 203hp": {
                "std_power": 203,
                "tuned_power": 225,
                "std_torque": 353,
                "tuned_torque": 380,
                "cc": 4300,
                "Type_ecu": "",
                "Engine_code": "L35",
            },
        },
        "2002 - 2004": {
            "4.2i 270hp": {
                "std_power": 270,
                "tuned_power": 290,
                "std_torque": 373,
                "tuned_torque": 400,
                "cc": 4160,
                "Type_ecu": "",
                "Engine_code": "LL8",
            },
        },
    },
    "Silhouette": {
        "1997 - 2004": {
            "3.4 V6 185hp": {
                "std_power": 185,
                "tuned_power": 205,
                "std_torque": 280,
                "tuned_torque": 310,
                "cc": 3350,
                "Type_ecu": "",
                "Engine_code": "LA1",
            },
        },
    },
},

"Opel": {
    "Adam": {
        "2013 ->": {
            "1.0T 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 166,
                "tuned_torque": 210,
                "cc": 999,
                "Type_ecu": "Delco E83 & Delco E80",
                "Engine_code": "B10XFT",
            },
            "1.0T 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 166,
                "tuned_torque": 220,
                "cc": 999,
                "Type_ecu": "Delco E80",
                "Engine_code": "B10XFL",
            },
            "1.4i 16v 87hp": {
                "std_power": 87,
                "tuned_power": 96,
                "std_torque": 130,
                "tuned_torque": 142,
                "cc": 1398,
                "Type_ecu": "Delco E83 & Delco E78",
                "Engine_code": "A14XEL",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1398,
                "Type_ecu": "Delco E83 & Delco E78",
                "Engine_code": "A14XFR",
            },
            "S 1.4 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 178,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14XER",
            },
        },
    },
    "Agila": {
        "2003 - 2008": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 96,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "D13A",
            },
        },
        "2008 ->": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 96,
                "std_torque": 170,
                "tuned_torque": 225,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "D13A",
            },
        },
    },
    "Antara": {
        "2007 - 2011": {
            "2.0 CDTi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "B20DTH",
            },
            "2.0 CDTi 126hp": {
                "std_power": 126,
                "tuned_power": 185,
                "std_torque": 295,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "B20DTH",
            },
            "2.4i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 220,
                "tuned_torque": 235,
                "cc": 2405,
                "Type_ecu": "Bosch ME7.9.9",
                "Engine_code": "Z24XE",
            },
            "3.2i V6 227hp": {
                "std_power": 227,
                "tuned_power": 240,
                "std_torque": 297,
                "tuned_torque": 317,
                "cc": 3195,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "10HM",
            },
        },
        "2011 - 2013": {
            "2.0 CDTi 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1991,
                "Type_ecu": "Delco E98",
                "Engine_code": "B20DTH",
            },
            "2.2 CDTi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 2231,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "A22DM",
            },
            "2.2 CDTi 184hp": {
                "std_power": 184,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 2231,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "A22DM",
            },
        },
    },
    "Astra": {
        "(G) - 1998 - 2004": {
            "1.7 CDTi 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 170,
                "tuned_torque": 230,
                "cc": 1686,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC15C7",
                "Engine_code": "Y17DTH",
            },
            "1.7 DTI 16v 75hp": {
                "std_power": 75,
                "tuned_power": 104,
                "std_torque": 165,
                "tuned_torque": 214,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Y17DTH",
            },
            "2.0 DI 82hp": {
                "std_power": 82,
                "tuned_power": 107,
                "std_torque": 185,
                "tuned_torque": 235,
                "cc": 1995,
                "Type_ecu": "Bosch EDC15M & Bosch PSG16",
                "Engine_code": "XZ20DTL",
            },
            "2.0 DTI 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1995,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "Y20DTH",
            },
            "2.2 DTI 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 320,
                "cc": 2172,
                "Type_ecu": "Bosch PSG16",
                "Engine_code": "Y22DTR",
            },
            "2.0T OPC 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "Bosch ME1.5.5",
                "Engine_code": "Z20LET",
            },
            "2.0i 16v OPC 160hp": {
                "std_power": 160,
                "tuned_power": 170,
                "std_torque": 188,
                "tuned_torque": 203,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIMTEC 70",
                "Engine_code": "X20XEV",
            },
            "2.0i 16v 136hp": {
                "std_power": 136,
                "tuned_power": 146,
                "std_torque": 188,
                "tuned_torque": 203,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIMTEC 70",
                "Engine_code": "X20XEV",
            },
        },
        "(H) - 2004 - 2009": {
            "1.3 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli 602 & Marelli 603",
                "Engine_code": "Z13DTH",
            },
            "1.7 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Denso D0700 & Denso DECE02 & Denso SH7058",
                "Engine_code": "Z17DTR",
            },
            "1.7 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Denso D0700",
                "Engine_code": "Z17DTR",
            },
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 295,
                "cc": 1686,
                "Type_ecu": "Bosch EDC16C9",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 170,
                "tuned_torque": 230,
                "cc": 1686,
                "Type_ecu": "Bosch EDC16C9",
                "Engine_code": "Z17DTH",
            },
            "1.9 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTL",
            },
            "1.9 CDTi 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C9",
                "Engine_code": "Z19DTL",
            },
            "1.9 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTL",
            },
            "1.4i 90hp": {
                "std_power": 90,
                "tuned_power": 100,
                "std_torque": 125,
                "tuned_torque": 140,
                "cc": 1364,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z16XEP",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z16LET",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Delco MT35E",
                "Engine_code": "C16SE",
            },
            "1.6i 16v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental SIMTEC 75.1",
                "Engine_code": "C16SE",
            },
            "1.8i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 175,
                "tuned_torque": 190,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIMTEC 75.1 & Siemens/Continental SIMTEC 71.6",
                "Engine_code": "Z18XER",
            },
            "2.0 Turbo OPC 240hp": {
                "std_power": 240,
                "tuned_power": 275,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z20LET",
            },
            "2.0 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z20LET",
            },
            "2.0 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 262,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z20LET",
            },
        },
        "(J) - 2009 - 2015": {
            "1.3 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "A13DTE",
            },
            "1.6 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.7 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Delco E87 & Denso SH7058 CJ & Denso SH7059 & Denso SH12",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Delco E87 & Denso SH7059 & Denso D0700 & Denso DECE01",
                "Engine_code": "Z17DTH",
            },
            "2.0 CDTi Bi-Turbo 195hp": {
                "std_power": 195,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP42 & Bosch EDC17C47 & Bosch EDC17CP47",
                "Engine_code": "A20DTR",
            },
            "2.0 CDTi 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C18 & Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 165hp": {
                "std_power": 165,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "B14NET",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78 & Delco E83",
                "Engine_code": "A14NET",
            },
            "1.4i 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1398,
                "Type_ecu": "Delco E83",
                "Engine_code": "A14XER",
            },
            "1.4i 87hp": {
                "std_power": 87,
                "tuned_power": 95,
                "std_torque": 130,
                "tuned_torque": 140,
                "cc": 1398,
                "Type_ecu": "Delco E83",
                "Engine_code": "A14XER",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "A16LET",
            },
            "1.6 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delco E39A & Delco E80",
                "Engine_code": "A16LET",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Delco E80 & Delco E39A",
                "Engine_code": "A16SHT",
            },
            "1.6i 16v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "B16XER",
            },
            "2.0 Turbo OPC 280hp": {
                "std_power": 280,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E39A",
                "Engine_code": "A20NFT",
            },
        },
        "(K) - 2015 - 2018": {
            "1.6 CDTI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi Bi-Turbo 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98 & Delco E78",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.0 Turbo 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 220,
                "cc": 999,
                "Type_ecu": "Delco E80",
                "Engine_code": "B10XFT",
            },
            "1.4 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 245,
                "tuned_torque": 290,
                "cc": 1398,
                "Type_ecu": "Delco E80",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 125hp": {
                "std_power": 125,
                "tuned_power": 180,
                "std_torque": 245,
                "tuned_torque": 290,
                "cc": 1398,
                "Type_ecu": "Delco E80 & Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E80",
                "Engine_code": "Z16LET",
            },
        },
        "(K) - 2019 - 2021": {
            "1.5 CDTi 122hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 CDTi 105hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 145hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.4T 145hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "(L) - 2022 -> ...": {
            "1.5 CDTi 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6T Hybrid 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "GSe 1.6T Hybrid 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Cascada": {
        "2013 ->": {
            "2.0 CDTi Bi-Turbo 195hp": {
                "std_power": 195,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP47",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 165hp": {
                "std_power": 165,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delco E39A & Delco E80",
                "Engine_code": "Z16LET",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "Z16LET",
            },
        },
    },
    "Combo": {
        "(C) - 2001 - 2011": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 225,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "Y13DT",
            },
            "1.3 CDTi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1248,
                "Type_ecu": "Marelli MJD603.S3 & Marelli MJD602.C1",
                "Engine_code": "Y13DT",
            },
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 305,
                "cc": 1686,
                "Type_ecu": "Denso DEC30 & Delco Multec S (F)",
                "Engine_code": "Z17DTL",
            },
            "1.7 DI 65hp": {
                "std_power": 65,
                "tuned_power": 85,
                "std_torque": 130,
                "tuned_torque": 180,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Z17DTL",
            },
            "1.7 DTI 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 165,
                "tuned_torque": 215,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Z17DTL",
            },
        },
        "(D) - 2012 - 2018": {
            "1.3 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8F3H & Bosch EDC17C49 & Bosch EDC16C39 & Marelli MJD9DF & Marelli MJD8 & Marelli MJD8F3.D1 & Marelli MJD8F3.D3",
                "Engine_code": "",
            },
            "1.6 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 290,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C60 & Bosch EDC17C49 & Marelli MJD9DF",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 290,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C69 & Bosch EDC17C49 & Bosch MD1CS003 & Bosch EDC17C60",
                "Engine_code": "B16DTH",
            },
            "2.0 CDTi 135hp": {
                "std_power": 135,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C49",
                "Engine_code": "B16DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 206,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78 & Bosch MED17.3.0",
                "Engine_code": "A14NET",
            },
        },
        "(E) - 2019 ->": {
            "1.5 BlueHDI 75hp": {
                "std_power": 75,
                "tuned_power": 125,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003 & Bosch EDC17C60",
                "Engine_code": "D15DTL",
            },
            "1.5 BlueHDI 102hp": {
                "std_power": 102,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C60 & Bosch MD1CS003",
                "Engine_code": "D15DT / F15DT",
            },
            "1.5 BlueHDI 131hp": {
                "std_power": 131,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DTH / F15DTH",
            },
            "1.2T Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "EB2DT",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "EB2DT",
            },
        },
    },
    "Corsa": {
        "(C) - 2000 - 2006": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 225,
                "cc": 1248,
                "Type_ecu": "Marelli MJ6F3 & Marelli MJD6JO.C2",
                "Engine_code": "Z12DT",
            },
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 305,
                "cc": 1686,
                "Type_ecu": "Denso DEC30",
                "Engine_code": "Z17DTH",
            },
            "1.7 DTI 65hp": {
                "std_power": 65,
                "tuned_power": 95,
                "std_torque": 130,
                "tuned_torque": 180,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Y17DTL",
            },
            "1.7 DTI 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 165,
                "tuned_torque": 215,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Y17DT",
            },
            "1.4i 16v 90hp": {
                "std_power": 90,
                "tuned_power": 100,
                "std_torque": 125,
                "tuned_torque": 140,
                "cc": 1389,
                "Type_ecu": "Bosch ME7.6.1 & Delco Multec",
                "Engine_code": "Z14XEP",
            },
        },
        "(D) - 2006 - 2015": {
            "1.3 CDTI 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD603.S3 & Marelli MJD602.M1 & Marelli MJD603.S1",
                "Engine_code": "Z13DTH",
            },
            "1.3 CDTi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 225,
                "cc": 1248,
                "Type_ecu": "Marelli MJD603.S3 & Bosch EDC17C08 & Bosch EDC17C59 & Bosch EDC17C18 & Delco E98 & Marelli 602",
                "Engine_code": "Z13DTH",
            },
            "1.3 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C18 & Bosch EDC17C59 & Delco E98 & Bosch EDC17C19",
                "Engine_code": "Z13DTH",
            },
            "1.3 CDTi 95hp (>2012)": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C18 & Bosch EDC17C59",
                "Engine_code": "Z13DTH",
            },
            "1.7 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Denso D0700",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "Z17DTH",
            },
            "1.0i 12v 60hp": {
                "std_power": 60,
                "tuned_power": 66,
                "std_torque": 88,
                "tuned_torque": 98,
                "cc": 973,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z10XEP",
            },
            "1.2i 16v 85hp": {
                "std_power": 85,
                "tuned_power": 93,
                "std_torque": 115,
                "tuned_torque": 125,
                "cc": 1199,
                "Type_ecu": "Delco E83 & Delco E78 & Mitsubishi E6T90072H5ZE",
                "Engine_code": "A12XER",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 175,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1364,
                "Type_ecu": "Delco E83 & Delco E87 & Delco E78",
                "Engine_code": "A14XER",
            },
            "1.6 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 210,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.3 & Bosch ME7.6.4 & Bosch ME7.6.2",
                "Engine_code": "Z16LEL",
            },
            "1.6 turbo 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.2 & Bosch ME7.6.3 & Delco E83",
                "Engine_code": "Z16LET",
            },
            "OPC - 1.6 Turbo - Nurburgring 210hp": {
                "std_power": 210,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.3 & Bosch ME7.6.4 & Bosch ME7.6.2",
                "Engine_code": "Z16LER",
            },
            "OPC - 1.6 Turbo 192hp": {
                "std_power": 192,
                "tuned_power": 215,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.3 & Bosch ME7.6.4 & Bosch ME7.6.2",
                "Engine_code": "Z16LER",
            },
        },
        "(E) - 2015 - 2019": {
            "1.3 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Delco E98",
                "Engine_code": "B13DTE",
            },
            "1.3 CDTi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Delco E98",
                "Engine_code": "B13DTC",
            },
            "1.0 Turbo 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 215,
                "cc": 999,
                "Type_ecu": "Delco E80",
                "Engine_code": "B10XFT",
            },
            "1.0 Turbo 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 215,
                "cc": 999,
                "Type_ecu": "Delco E80",
                "Engine_code": "B10XFT",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 230,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 100hp": {
                "std_power": 100,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78 & Delco E83",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 270,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "B14NEH",
            },
            "1.4i 16v 90hp": {
                "std_power": 90,
                "tuned_power": 100,
                "std_torque": 130,
                "tuned_torque": 142,
                "cc": 1364,
                "Type_ecu": "Delco E83 & Delco E78",
                "Engine_code": "B14XER",
            },
            "1.6 T OPC 207hp": {
                "std_power": 207,
                "tuned_power": 220,
                "std_torque": 245,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "B16LER",
            },
        },
        "(F) - 2019 ->": {
            "1.5 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "DV5DR",
            },
            "1.2T 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 205,
                "tuned_torque": 240,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1 & Delco E78 & Bosch MG1CS032",
                "Engine_code": "EB2ADTD",
            },
            "1.2T 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "EB2ADTS",
            },
        },
    },
    "Crossland": {
        "2021 -> ...": {
            "1.5 CDTi 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 CDTi 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 Turbo 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 Turbo 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1",
                "Engine_code": "F12XHT",
            },
        },
    },
    "Crossland X": {
        "2017 - 2020": {
            "1.5 CDTi 102hp": {
                "std_power": 102,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DT",
            },
            "1.5 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DH",
            },
            "1.6 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "B16DT",
            },
            "1.6 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "B16DTH",
            },
            "1.2 Turbo 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46 & Valeo VD56.1",
                "Engine_code": "B12XHL",
            },
            "1.2 Turbo 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1 & Valeo VD46.1",
                "Engine_code": "B12XHT",
            },
        },
    },
    "Frontera": {
        "All": {
            "2.2 DTI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 2171,
                "Type_ecu": "Bosch EDC15M & Bosch PSG16",
                "Engine_code": "Z22DTH",
            },
        },
    },
    "GT": {
        "2007 - 2009": {
            "2.0 Turbo 264hp": {
                "std_power": 264,
                "tuned_power": 295,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "Bosch ME9.6 & Bosch ME9.5",
                "Engine_code": "A20NHT",
            },
        },
    },
    "Grandland": {
        "2021 -> ...": {
            "1.5 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "F15DTH",
            },
            "2.0 CDTI 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "D20DTH",
            },
            "1.2 Turbo 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "F12XHT",
            },
            "1.6 Turbo Hybrid 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Turbo Hybrid4 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "D16XHT",
            },
        },
    },
    "Grandland X": {
        "2017 ->": {
            "1.5 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DTH",
            },
            "1.6 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "B16DTH",
            },
            "2.0 CDTi 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "D20DTH",
            },
            "1.2 Turbo 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "B12XHT",
            },
            "1.6 Turbo Hybrid 224hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Turbo Hybrid4 290hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042",
                "Engine_code": "D16XHT / F16XHT",
            },
        },
    },
    "Insignia": {
        "2008 - 2013": {
            "2.0 CDTi Bi-Turbo 195hp": {
                "std_power": 195,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP47",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 190,
                "std_torque": 260,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C18 & Bosch EDC17C59",
                "Engine_code": "A20DTC",
            },
            "2.0 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59 & Bosch EDC17C18",
                "Engine_code": "A20DTJ",
            },
            "2.0 CDTi 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "",
                "Engine_code": "A14NET",
            },
            "1.6i 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "A16XER",
            },
            "1.8i 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 175,
                "tuned_torque": 190,
                "cc": 1796,
                "Type_ecu": "Delco E83",
                "Engine_code": "A18XER",
            },
            "2.0 Turbo 220hp": {
                "std_power": 220,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch MED9.6.1 & Delco E39A",
                "Engine_code": "A20NHT",
            },
            "2.0 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 295,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E39A",
                "Engine_code": "A20NHT",
            },
            "2.8 OPC V6 Turbo 325hp": {
                "std_power": 325,
                "tuned_power": 360,
                "std_torque": 435,
                "tuned_torque": 550,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "A28NET",
            },
            "2.8 V6 Turbo 260hp": {
                "std_power": 260,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6.1 & Bosch ME9.5",
                "Engine_code": "A28NET",
            },
        },
        "2013 - 2015": {
            "2.0 CDTi Bi-Turbo 195hp": {
                "std_power": 195,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP47",
                "Engine_code": "A20DTR",
            },
            "2.0 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "A20DTJ",
            },
            "2.0 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 140hp": {
                "std_power": 140,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP19 & Delco E98",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 190,
                "std_torque": 260,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A20DTJ",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delco E39A & Delco E80",
                "Engine_code": "A16XHT",
            },
            "2.0 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 295,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E39A",
                "Engine_code": "A20NHT",
            },
            "2.8 V6 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "A28NET",
            },
        },
        "2015 - 2017": {
            "1.6 CDTI 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTR",
            },
            "1.6 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "2.0 CDTi 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1956,
                "Type_ecu": "Delco E98",
                "Engine_code": "B20DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 260,
                "tuned_torque": 290,
                "cc": 1364,
                "Type_ecu": "",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Delco E39A & Delco E80",
                "Engine_code": "A16XHT",
            },
            "2.0 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1998,
                "Type_ecu": "Delco E39A",
                "Engine_code": "A20NHT",
            },
            "2.8 Bi-Turbo OPC 325hp": {
                "std_power": 325,
                "tuned_power": 360,
                "std_torque": 435,
                "tuned_torque": 530,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "A28NER",
            },
        },
        "2017 - 2019": {
            "1.6 CDTI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTE",
            },
            "1.6 CDTI 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "2.0 CDTI 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1956,
                "Type_ecu": "Delco E98",
                "Engine_code": "B20DTH",
            },
            "GSi 2.0 CDTI Bi-Turbo 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 480,
                "tuned_torque": 550,
                "cc": 1956,
                "Type_ecu": "Delco E98",
                "Engine_code": "D20DTR",
            },
            "1.5 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1490,
                "Type_ecu": "Delco E80",
                "Engine_code": "B15XFL",
            },
            "1.5 Turbo 165hp": {
                "std_power": 165,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1490,
                "Type_ecu": "Delco E80",
                "Engine_code": "B15XFT",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 300,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "D16SHT",
            },
            "GSi 2.0 Turbo 260hp": {
                "std_power": 260,
                "tuned_power": 270,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "Delco E80",
                "Engine_code": "B20NFT",
            },
        },
        "2020 ->": {
            "1.5d 122hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0d 174hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.4T 145hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0T 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0T 170hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "GSI 2.0T 230hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Meriva": {
        "(A) - 2003 - 2010": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "Z13DTH",
            },
            "1.3 CDTi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD602.M1",
                "Engine_code": "Z13DTH",
            },
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1686,
                "Type_ecu": "Denso DEC30 & Denso D0700 & Delco E87 & Denso DECE01",
                "Engine_code": "Z17DTH",
            },
            "1.7 DTI 75hp": {
                "std_power": 75,
                "tuned_power": 105,
                "std_torque": 165,
                "tuned_torque": 215,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Y17DT",
            },
            "1.4i 16v 90hp": {
                "std_power": 90,
                "tuned_power": 100,
                "std_torque": 125,
                "tuned_torque": 140,
                "cc": 1364,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z14XEP",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "X16SZR",
            },
            "1.6i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Delco Multec S (F)",
                "Engine_code": "X16SZR",
            },
            "OPC - 1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z16LET",
            },
        },
        "(B) - 2010 - 2013": {
            "1.3 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 180,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "Z13DTH",
            },
            "1.3 CDTi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "Z13DTH",
            },
            "1.7 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1686,
                "Type_ecu": "Delco E87 & Denso SH7059",
                "Engine_code": "Z17DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E83",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 175,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E83 & Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1364,
                "Type_ecu": "Delco E83",
                "Engine_code": "A14XER",
            },
        },
        "(B) - 2014 ->": {
            "1.3 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 180,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "Z13DTH",
            },
            "1.6 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 355,
                "cc": 1686,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1686,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.7 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "Z17DTH",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 175,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E83 & Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1364,
                "Type_ecu": "Delco E83",
                "Engine_code": "A14NET",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1364,
                "Type_ecu": "Delco E83 & Delco E78",
                "Engine_code": "B14XER",
            },
        },
    },
    "Mokka": {
        "2012 - 2016": {
            "1.6 CDTI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTI 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.7 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "A17DTS",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1368,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6i 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Delco E78",
                "Engine_code": "B16XER",
            },
        },
        "2016 - 2019": {
            "1.6 CDTI 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1368,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 152hp": {
                "std_power": 152,
                "tuned_power": 180,
                "std_torque": 235,
                "tuned_torque": 290,
                "cc": 1399,
                "Type_ecu": "Delco E80",
                "Engine_code": "B14XFT",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1368,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
        },
        "2020 ->": {
            "1.5 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "F15DT",
            },
            "1.2T 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Bosch MG1CS032",
                "Engine_code": "F12XHT",
            },
        },
    },
    "Mokka X": {
        "2019 ->": {
            "1.6 CDTI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTI 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1368,
                "Type_ecu": "Delco E78 & Delco E87 & Delco E80",
                "Engine_code": "A14NET",
            },
        },
    },
    "Movano": {
        "(A) - 1998 - 2010": {
            "1.9 CDTi 82hp": {
                "std_power": 82,
                "tuned_power": 112,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q",
            },
            "2.0 CDTi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 290,
                "tuned_torque": 380,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "Y20DTL",
            },
            "2.2 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 2174,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "Y22DTR",
            },
            "2.5 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 335,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U A7",
            },
            "2.5 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 147,
                "std_torque": 300,
                "tuned_torque": 375,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC15C2",
                "Engine_code": "G9U B6",
            },
            "2.5 CDTi 145hp": {
                "std_power": 145,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 405,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U 632 / G9U 650",
            },
            "2.5 CDTi 135hp": {
                "std_power": 135,
                "tuned_power": 163,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 2464,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2958,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "ZD3-200",
            },
        },
        "(B) - 2010 - 2014": {
            "2.3 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 155,
                "std_torque": 285,
                "tuned_torque": 385,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42",
                "Engine_code": "M9T",
            },
            "2.3 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 310,
                "tuned_torque": 385,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Siemens/Continental SID309 & Bosch EDC17C42",
                "Engine_code": "M9T",
            },
            "2.3 CDTi 146hp": {
                "std_power": 146,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C11",
                "Engine_code": "M9T",
            },
        },
        "(B) - 2014 - 2016": {
            "2.3 CDTi Bi-turbo 136hp": {
                "std_power": 136,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID309",
                "Engine_code": "M9T B7",
            },
            "2.3 CDTi Bi-turbo 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID309 & Siemens/Continental SID310",
                "Engine_code": "M9T B7",
            },
            "2.3 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 155,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "M9T B8",
            },
            "2.3 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 310,
                "tuned_torque": 350,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42",
                "Engine_code": "M9T B8",
            },
        },
        "(B) - 2016 - 2018": {
            "2.3 DCi (Euro 6) 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 285,
                "tuned_torque": 345,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C42 & Siemens/Continental SID210",
                "Engine_code": "M9T 870",
            },
            "2.3 DCi (Euro 6) 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 340,
                "tuned_torque": 390,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "M9T 702",
            },
            "2.3 DCi (Euro 6) 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "",
            },
            "2.3 DCi Bi-turbo (Euro 6) 145hp": {
                "std_power": 145,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "",
            },
            "2.3 DCi Bi-turbo (Euro 6) 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "M9Y 702",
            },
        },
        "2019 - 2020": {
            "2.3 CDTi 135hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.3 CDTi 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.3 CDTi 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2021 -> ...": {
            "2.2 DTCI 120hp": {
                "std_power": 120,
                "tuned_power": 195,
                "std_torque": 300,
                "tuned_torque": 430,
                "cc": 0,
                "Type_ecu": "Delphi DCM7.1B",
                "Engine_code": "",
            },
            "2.2 DTCI 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 430,
                "cc": 0,
                "Type_ecu": "Delphi DCM7.1B",
                "Engine_code": "",
            },
            "2.2 DTCI 140hp": {
                "std_power": 140,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 0,
                "Type_ecu": "Delphi DCM7.1B",
                "Engine_code": "",
            },
        },
    },
    "Omega": {
        "(B) 1997 - 2003": {
            "2.0 DTI 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 205,
                "tuned_torque": 265,
                "cc": 1994,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "X20DTH",
            },
            "2.2 DTI 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 2171,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "Y22DTH",
            },
            "2.5 DTI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 2498,
                "Type_ecu": "",
                "Engine_code": "Y25DT",
            },
        },
    },
    "Rocks-e": {
        "2022 -> ...": {
            "5.5 kWh 8hp": {
                "std_power": 8,
                "tuned_power": 8,
                "std_torque": 39,
                "tuned_torque": 39,
                "cc": 0,
                "Type_ecu": "EVPT VCU3000",
                "Engine_code": "",
            },
        },
    },
    "Signum": {
        "2003 - 2008": {
            "1.9 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTH",
            },
            "1.9 CDTi 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTH",
            },
            "1.9 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9",
                "Engine_code": "Z19DTH",
            },
            "3.0 CDTi 184hp": {
                "std_power": 184,
                "tuned_power": 219,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2958,
                "Type_ecu": "Denso D0600",
                "Engine_code": "Y30DT",
            },
            "2.0 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 265,
                "tuned_torque": 310,
                "cc": 1998,
                "Type_ecu": "Trionic Trionic 8",
                "Engine_code": "Z20NET",
            },
            "2.2i 16v 155hp": {
                "std_power": 155,
                "tuned_power": 165,
                "std_torque": 220,
                "tuned_torque": 235,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SIMTEC 81.1",
                "Engine_code": "Z22YH",
            },
            "2.8 Turbo 230hp": {
                "std_power": 230,
                "tuned_power": 270,
                "std_torque": 330,
                "tuned_torque": 390,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "Z28NEL",
            },
            "2.8 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "Z28NEL",
            },
        },
    },
    "Speedster": {
        "2001 - 2005": {
            "2.0 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Bosch ME1.5.5",
                "Engine_code": "Z20LET",
            },
            "2.2i 16v 147hp": {
                "std_power": 147,
                "tuned_power": 160,
                "std_torque": 203,
                "tuned_torque": 220,
                "cc": 2198,
                "Type_ecu": "Delco GMPT E-15",
                "Engine_code": "Z22SE",
            },
        },
    },
    "Tigra": {
        "Twintop - 2004 - 2010": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 215,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "Z13DY",
            },
            "1.4i 16v 90hp": {
                "std_power": 90,
                "tuned_power": 100,
                "std_torque": 125,
                "tuned_torque": 140,
                "cc": 1364,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z14XEP",
            },
            "1.8i 16v 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 165,
                "tuned_torque": 180,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIMTEC 71",
                "Engine_code": "Z18XE",
            },
        },
    },
    "Vectra": {
        "(C) - 2002 - 2009": {
            "1.9 CDTi 16v 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DT",
            },
            "1.9 CDTi 8v 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C9",
                "Engine_code": "Z19DT",
            },
            "1.9 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DT",
            },
            "2.0 DI 82hp": {
                "std_power": 82,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1994,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "X20DTL",
            },
            "2.0 DTI 16v 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 205,
                "tuned_torque": 255,
                "cc": 1994,
                "Type_ecu": "Bosch EDC15M & Bosch PSG16",
                "Engine_code": "Y20DTH",
            },
            "2.2 DTI 16v 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 2171,
                "Type_ecu": "Bosch PSG16",
                "Engine_code": "Y22DTR",
            },
            "1.6i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Delco Multec S (F) & Delco MT35E",
                "Engine_code": "Z16XEP",
            },
            "1.8i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 175,
                "tuned_torque": 190,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIMTEC 75.1 & Siemens/Continental SIMTEC 71.6",
                "Engine_code": "Z18XER",
            },
            "2.0 Turbo 175hp": {
                "std_power": 175,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "Trionic Trionic 8",
                "Engine_code": "Z20LET",
            },
            "2.2i 16v 155hp": {
                "std_power": 155,
                "tuned_power": 165,
                "std_torque": 220,
                "tuned_torque": 235,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SIMTEC 81.1 & Bosch PSG16",
                "Engine_code": "Z22YH",
            },
            "2.8T V6 255hp": {
                "std_power": 255,
                "tuned_power": 300,
                "std_torque": 355,
                "tuned_torque": 450,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6 & Bosch ME9.5",
                "Engine_code": "Z28NET",
            },
            "2.8T V6 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 355,
                "tuned_torque": 450,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "Z28NET",
            },
        },
    },
    "Vivaro": {
        "2001 - 2014": {
            "1.9 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC15C3",
                "Engine_code": "F9Q",
            },
            "1.9 DTI 82hp": {
                "std_power": 82,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "F9Q",
            },
            "2.0 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 145,
                "std_torque": 240,
                "tuned_torque": 350,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33 & Bosch EDC17C11 & Bosch EDC17C42 & Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.0 CDTi 114hp": {
                "std_power": 114,
                "tuned_power": 144,
                "std_torque": 290,
                "tuned_torque": 360,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33 & Bosch EDC17C42 & Bosch EDC17C11 & Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.5 CDTi 114hp": {
                "std_power": 114,
                "tuned_power": 149,
                "std_torque": 290,
                "tuned_torque": 370,
                "cc": 2463,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC17C42",
                "Engine_code": "G9U",
            },
            "2.5 CDTi 135hp": {
                "std_power": 135,
                "tuned_power": 163,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 2463,
                "Type_ecu": "Bosch EDC15C3",
                "Engine_code": "G9U",
            },
            "2.5 CDTi 145hp": {
                "std_power": 145,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 405,
                "cc": 2463,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U",
            },
        },
        "2014 - 2016": {
            "1.6 CDTI BiTurbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 CDTI BiTurbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 CDTI BiTurbo 145hp": {
                "std_power": 145,
                "tuned_power": 165,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84 & Bosch EDC17C42",
                "Engine_code": "R9M D4",
            },
            "1.6 CDTI BiTurbo 125hp": {
                "std_power": 125,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84 & Bosch EDC17C42",
                "Engine_code": "R9M D4",
            },
            "1.6 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Bosch EDC17C11",
                "Engine_code": "R9M A4",
            },
            "1.6 CDTi 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C11",
                "Engine_code": "R9M A4",
            },
            "1.6 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84 & Bosch EDC17C42 & Bosch EDC17C11",
                "Engine_code": "R9M A4",
            },
        },
        "2016 - 2018": {
            "1.6 DCi (Euro 6) 95hp": {
                "std_power": 95,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 DCi (Euro 6) 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 DCi Bi-Turbo (Euro 6) 125hp": {
                "std_power": 125,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 DCi Bi-Turbo (Euro 6) 145hp": {
                "std_power": 145,
                "tuned_power": 165,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
        },
        "2019 ->": {
            "1.5d 102hp": {
                "std_power": 102,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DT / DV5RUCD",
            },
            "1.5d 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DTH / DV5RUC",
            },
            "2.0d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 370,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "D20DTH / DW10FDU",
            },
            "2.0d 122hp": {
                "std_power": 122,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "D20DTL / DW10FEU",
            },
            "2.0d 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "",
            },
        },
    },
    "Zafira": {
        "(A) - 1999 - 2005": {
            "2.0 DI 82hp": {
                "std_power": 82,
                "tuned_power": 107,
                "std_torque": 185,
                "tuned_torque": 235,
                "cc": 1995,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "X20DTL",
            },
            "2.0 DTI 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 230,
                "tuned_torque": 290,
                "cc": 1995,
                "Type_ecu": "Bosch EDC15M & Bosch PSG16",
                "Engine_code": "Y20DTH",
            },
            "2.0 Turbo OPC 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Bosch ME1.5.5",
                "Engine_code": "Z20LET",
            },
            "2.0 Turbo OPC 192hp": {
                "std_power": 192,
                "tuned_power": 225,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1998,
                "Type_ecu": "Bosch ME1.5.5",
                "Engine_code": "Z20LET",
            },
        },
        "(B) - 2005 - 2011": {
            "1.7 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Denso D0700 & Denso D0900 & Denso SH7058",
                "Engine_code": "A17DTJ",
            },
            "1.7 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "A17DTR",
            },
            "1.9 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTL",
            },
            "1.9 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DT",
            },
            "1.9 CDTi 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTH",
            },
            "1.6 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 210,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.4",
                "Engine_code": "A16XNT",
            },
            "1.6i 16v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental SIMTEC 75.1 & Ac Delco MT35E",
                "Engine_code": "X16XEL",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Delco Multec S (F)",
                "Engine_code": "X16XEL",
            },
            "1.8i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 155,
                "std_torque": 175,
                "tuned_torque": 190,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIMTEC 75.1",
                "Engine_code": "X18XEP",
            },
            "2.0 Turbo OPC 240hp": {
                "std_power": 240,
                "tuned_power": 275,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z20LEH",
            },
            "2.0 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 262,
                "tuned_torque": 340,
                "cc": 1998,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z20LER",
            },
            "2.2i 16v 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 215,
                "tuned_torque": 230,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SIMTEC 81.1",
                "Engine_code": "Z22SE",
            },
        },
        "(C) - 2011 - 2016": {
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "2.0 CDTi Bi-Turbo 195hp": {
                "std_power": 195,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP47",
                "Engine_code": "YD20DTJ",
            },
            "2.0 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59 & Delco E98",
                "Engine_code": "A20DTJ",
            },
            "2.0 CDTi 165hp": {
                "std_power": 165,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59 & Delco E98",
                "Engine_code": "YD20DTH",
            },
            "2.0 CDTi 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1956,
                "Type_ecu": "Delco E98",
                "Engine_code": "YD20DTJ",
            },
            "2.0 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP47 & Bosch EDC17C59",
                "Engine_code": "A20DTJ",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6 CNG Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 210,
                "tuned_torque": 280,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.4",
                "Engine_code": "A16XNT",
            },
            "1.6 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delco E39A & Delco E80",
                "Engine_code": "A16XNT",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Delco E39 & Delco E80",
                "Engine_code": "A16XNT",
            },
        },
    },
    "Zafira Life": {
        "2019 ->": {
            "1.5d 102hp": {
                "std_power": 102,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5d 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003 & Bosch MD1",
                "Engine_code": "D15DTH / DV5RUC",
            },
            "2.0d 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A & Delphi DCM6.2",
                "Engine_code": "D20DTH / DW10FDU",
            },
            "2.0d 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "D20DTR / DW10FDCU",
            },
        },
    },
},
"Peugeot": {
    "106": {
        "1996 - 2003": {
            "1.1i 8v 60hp": {
                "std_power": 60,
                "tuned_power": 66,
                "std_torque": 88,
                "tuned_torque": 98,
                "cc": 1124,
                "Type_ecu": "Bosch M7.4.4",
                "Engine_code": "TU1M",
            },
            "1.6i 16v 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 145,
                "tuned_torque": 160,
                "cc": 1587,
                "Type_ecu": "Marelli IAW48P2 & Sagem 2000",
                "Engine_code": "TU5J4",
            },
        },
    },
    "107": {
        "All": {
            "1.4 HDi 8V 55hp": {
                "std_power": 55,
                "tuned_power": 85,
                "std_torque": 130,
                "tuned_torque": 170,
                "cc": 1399,
                "Type_ecu": "Siemens/Continental SID806",
                "Engine_code": "DV4TD",
            },
            "1.0i 68hp": {
                "std_power": 68,
                "tuned_power": 75,
                "std_torque": 93,
                "tuned_torque": 110,
                "cc": 998,
                "Type_ecu": "Bosch ME7.9.52",
                "Engine_code": "1KR-FE",
            },
        },
    },
    "108": {
        "2014 ->": {
            "1.0 e-VTi 68hp": {
                "std_power": 68,
                "tuned_power": 78,
                "std_torque": 96,
                "tuned_torque": 110,
                "cc": 998,
                "Type_ecu": "Bosch ME17.9.52 & Bosch MEG17.9.52 & Bosch ME7.9.52",
                "Engine_code": "EB2",
            },
            "1.2 e-VTi 82hp": {
                "std_power": 82,
                "tuned_power": 92,
                "std_torque": 116,
                "tuned_torque": 126,
                "cc": 1199,
                "Type_ecu": "Valeo V46.13",
                "Engine_code": "1KR-FE",
            },
        },
    },
    "206": {
        "All": {
            "1.4 HDi 8V 68hp": {
                "std_power": 68,
                "tuned_power": 90,
                "std_torque": 150,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Bosch EDC16C3 & Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV4TD",
            },
            "1.4 HDiF 8V 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 150,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV4TD",
            },
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6ATED4",
            },
            "1.6 HDiF 16V 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 116,
                "std_torque": 205,
                "tuned_torque": 265,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW10",
            },
            "2.0i 16v GTI 138hp": {
                "std_power": 138,
                "tuned_power": 150,
                "std_torque": 190,
                "tuned_torque": 215,
                "cc": 1997,
                "Type_ecu": "Marelli IAW 6LP & Marelli IAW P48.10 & Marelli IAW48P2",
                "Engine_code": "EW10J4S",
            },
            "2.0i 16v RC 177hp": {
                "std_power": 177,
                "tuned_power": 190,
                "std_torque": 202,
                "tuned_torque": 217,
                "cc": 1997,
                "Type_ecu": "Marelli IAW 6LPA",
                "Engine_code": "EW10J4S",
            },
        },
    },
    "207": {
        "2006 ->": {
            "1.4 HDi 8V 68hp": {
                "std_power": 68,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10 & Siemens/Continental SID805 & Valeo J34P",
                "Engine_code": "DV4TD",
            },
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV6ATED4",
            },
            "1.6 HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "1.6 HDiF 16V 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Siemens/Continental SID807",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDiF 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 265,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Bosch EDC17CP10",
                "Engine_code": "DV6DTED",
            },
            "1.4 VTi 95hp": {
                "std_power": 95,
                "tuned_power": 103,
                "std_torque": 136,
                "tuned_torque": 150,
                "cc": 1397,
                "Type_ecu": "Bosch MEV17.4 & Marelli IAW 6LP & Valeo J34P",
                "Engine_code": "ET3J4",
            },
            "1.6 THP 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6DTS",
            },
            "1.6 THP 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2 & Bosch MED17.4",
                "Engine_code": "EP6DT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6C",
            },
            "1.6i 16v 109hp": {
                "std_power": 109,
                "tuned_power": 120,
                "std_torque": 147,
                "tuned_torque": 162,
                "cc": 1587,
                "Type_ecu": "Bosch ME7.4.5 & Bosch MEV17.4",
                "Engine_code": "TU5JP4",
            },
        },
    },
    "208": {
        "2012 - 2015": {
            "1.4 HDI 8V 68hp": {
                "std_power": 68,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1399,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV4TD",
            },
            "1.4 VTi 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 136,
                "tuned_torque": 150,
                "cc": 1397,
                "Type_ecu": "Bosch MEVD17.4.2",
                "Engine_code": "8F01",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 e-HDi 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "1.0 VTi 68hp": {
                "std_power": 68,
                "tuned_power": 75,
                "std_torque": 95,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Valeo V46.11",
                "Engine_code": "ZM01",
            },
            "1.2 VTi 82hp": {
                "std_power": 82,
                "tuned_power": 92,
                "std_torque": 118,
                "tuned_torque": 130,
                "cc": 1199,
                "Type_ecu": "Valeo V46.11 & Valeo V46.13",
                "Engine_code": "HM01",
            },
            "1.2T Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2",
            },
            "1.6 THP 155hp": {
                "std_power": 155,
                "tuned_power": 190,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "5F02",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "5F01",
            },
            "GTI 1.6 THP 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 275,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.4 & Bosch MEVD17.4.2",
                "Engine_code": "5F03",
            },
            "GTI 1.6 THP 208hp": {
                "std_power": 208,
                "tuned_power": 225,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.4",
                "Engine_code": "5F03",
            },
        },
        "2015 - 2018": {
            "1.5 BlueHDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 254,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "DV5RD",
            },
            "1.6 BlueHDI 8V 75hp": {
                "std_power": 75,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Valeo VD56.1",
                "Engine_code": "DV6FETED",
            },
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.2T Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "EB2",
            },
            "1.6 e-THP 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "",
            },
            "GTI 1.6 e-THP 208hp": {
                "std_power": 208,
                "tuned_power": 225,
                "std_torque": 275,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.4",
                "Engine_code": "5G04",
            },
        },
        "2019 ->": {
            "1.5 BlueHDi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "DV5RD",
            },
            "1.2 Puretech 75hp": {
                "std_power": 75,
                "tuned_power": 90,
                "std_torque": 118,
                "tuned_torque": 130,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "EB2FAD",
            },
            "1.2 Puretech 100hp": {
                "std_power": 100,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1 & Bosch MG1CS032",
                "Engine_code": "EB2ADT",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "EB2ADTS / EB2ADTSM",
            },
        },
    },
    "306": {
        "All": {
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 116,
                "std_torque": 205,
                "tuned_torque": 260,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC16",
                "Engine_code": "",
            },
            "2.0 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 290,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6i 16v 98hp": {
                "std_power": 98,
                "tuned_power": 110,
                "std_torque": 138,
                "tuned_torque": 153,
                "cc": 1587,
                "Type_ecu": "Bosch ME7.4.4",
                "Engine_code": "",
            },
        },
    },
    "307": {
        "All": {
            "1.4 HDi 8V 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "DV4TD",
            },
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C0 & Bosch EDC16C34",
                "Engine_code": "DV6ATED4",
            },
            "1.6 HDiF 16V 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Siemens/Continental SID803A",
                "Engine_code": "DV6TED4",
            },
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 265,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2 & Siemens/Continental SID801",
                "Engine_code": "DW10",
            },
            "2.0 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2 & Siemens/Continental SID803",
                "Engine_code": "DW10",
            },
            "2.0 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "DW10FD",
            },
            "2.0 HDiF 136hp": {
                "std_power": 136,
                "tuned_power": 166,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.4 & Siemens/Continental SID803",
                "Engine_code": "DW10BTED4",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 147,
                "tuned_torque": 162,
                "cc": 1587,
                "Type_ecu": "Bosch M7.4.4 & Bosch ME7.4.5",
                "Engine_code": "EP6C",
            },
            "2.0i 16v 138hp": {
                "std_power": 138,
                "tuned_power": 150,
                "std_torque": 190,
                "tuned_torque": 215,
                "cc": 1997,
                "Type_ecu": "Marelli IAW 6LP",
                "Engine_code": "RFJ",
            },
            "2.0i 16v 143hp": {
                "std_power": 143,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1997,
                "Type_ecu": "Marelli 6LPB.xx",
                "Engine_code": "RFJ",
            },
            "3.0 V6 210hp": {
                "std_power": 210,
                "tuned_power": 225,
                "std_torque": 285,
                "tuned_torque": 305,
                "cc": 2946,
                "Type_ecu": "",
                "Engine_code": "ES9",
            },
            "RC 2.0i 16v 177hp": {
                "std_power": 177,
                "tuned_power": 192,
                "std_torque": 202,
                "tuned_torque": 215,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "EW10J4S",
            },
        },
    },
    "308": {
        "2007 - 2013": {
            "1.6 HDiF 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6ATED4",
            },
            "1.6 HDiF 16V 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Siemens/Continental SID807 & Bosch EDC17C10",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDiF 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.6 e-HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 285,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "DV6FCTED",
            },
            "2.0 HDi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "DV6",
            },
            "2.0 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DV6",
            },
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DV6",
            },
            "1.4 VTi 95hp": {
                "std_power": 95,
                "tuned_power": 105,
                "std_torque": 136,
                "tuned_torque": 150,
                "cc": 1397,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP3C",
            },
            "1.4 VTi 98hp": {
                "std_power": 98,
                "tuned_power": 108,
                "std_torque": 136,
                "tuned_torque": 151,
                "cc": 1397,
                "Type_ecu": "Bosch MEV17.4",
                "Engine_code": "EP3C",
            },
            "1.6 THP 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2 & Bosch MED17.4",
                "Engine_code": "EP6DTS",
            },
            "1.6 THP 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 275,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.2",
                "Engine_code": "EP6DTS",
            },
            "1.6 THP 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6DT",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2 & Bosch MED17.4 & Bosch MEV17.4",
                "Engine_code": "EP6DTS",
            },
            "1.6 THP 140hp": {
                "std_power": 140,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4",
                "Engine_code": "EP6DT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.4.5 & Bosch MED17.4.2 & Bosch MEV17.4",
                "Engine_code": "5F01",
            },
        },
        "2013 - 2017": {
            "1.6 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FC",
            },
            "1.6 BlueHDI 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FC",
            },
            "1.6 HDi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 285,
                "tuned_torque": 325,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Siemens/Continental SID807",
                "Engine_code": "9HR",
            },
            "1.6 HDiF 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "9HR",
            },
            "1.6 e-HDI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Siemens/Continental SID807EVO",
                "Engine_code": "DV6C",
            },
            "2.0 BlueHDi 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "1.2 e-THP 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2",
            },
            "1.2 e-THP 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1",
                "Engine_code": "EB2",
            },
            "1.6 THP 125hp": {
                "std_power": 125,
                "tuned_power": 190,
                "std_torque": 200,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6DT",
            },
            "1.6 THP 155hp": {
                "std_power": 155,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2 & Bosch MED17.4.4",
                "Engine_code": "EP6DTS",
            },
            "GT 1.6 THP 205hp": {
                "std_power": 205,
                "tuned_power": 230,
                "std_torque": 285,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.4",
                "Engine_code": "EP6DTS",
            },
            "GTI 1.6 THP 270hp": {
                "std_power": 270,
                "tuned_power": 295,
                "std_torque": 330,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.4",
                "Engine_code": "EP6DTS",
            },
            "GTI 1.6 THP 250hp": {
                "std_power": 250,
                "tuned_power": 295,
                "std_torque": 330,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.2",
                "Engine_code": "EP6DTS",
            },
        },
        "2017 - 2020": {
            "1.5 BlueHDi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "DC5RC",
            },
            "1.5 BlueHDi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "DV5RD",
            },
            "1.6 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FC",
            },
            "1.6 BlueHDI 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Bosch MD1CS003",
                "Engine_code": "DV6FC",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM7.1A & Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "2.0 BlueHDi 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM7.1A & Delphi DCM6.2A",
                "Engine_code": "DW10FC",
            },
            "1.2 Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "EB2",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1 & Valeo VD56.1",
                "Engine_code": "EB2",
            },
            "1.6 Puretech 225hp": {
                "std_power": 225,
                "tuned_power": 240,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042",
                "Engine_code": "EP6FADTX",
            },
            "1.6 e-THP 205hp": {
                "std_power": 205,
                "tuned_power": 230,
                "std_torque": 285,
                "tuned_torque": 355,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.4",
                "Engine_code": "EP6FDTX",
            },
            "GTI 1.6 e-THP 270hp": {
                "std_power": 270,
                "tuned_power": 295,
                "std_torque": 330,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.4 & Bosch MG1CS042",
                "Engine_code": "EP6DTS",
            },
            "GTI 1.6 e-THP 263hp": {
                "std_power": 263,
                "tuned_power": 295,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042",
                "Engine_code": "EP6DTS",
            },
        },
        "2021 ->": {
            "1.5 BlueHDi 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 260,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "EB2",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "EB2DTS",
            },
            "1.6T Puretech Hybrid 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6T Puretech Hybrid 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "GTI 1.6 e-THP 263hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "406": {
        "All": {
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 116,
                "std_torque": 205,
                "tuned_torque": 260,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2 & Siemens/Continental SID801",
                "Engine_code": "DW10TD",
            },
            "2.0 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 290,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2 & Siemens/Continental SID801",
                "Engine_code": "DW10ATED",
            },
            "2.0 HDi 136hp": {
                "std_power": 136,
                "tuned_power": 166,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.2 HDi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 314,
                "tuned_torque": 400,
                "cc": 2179,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW12TED4",
            },
            "3.0 V6 210hp": {
                "std_power": 210,
                "tuned_power": 225,
                "std_torque": 285,
                "tuned_torque": 305,
                "cc": 2946,
                "Type_ecu": "Bosch ME7.4.4 & Bosch ME7.4.6",
                "Engine_code": "ES9 J4S",
            },
        },
    },
    "407": {
        "2004 - 2011": {
            "1.6 HDiF 16V 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "2.0 HDiF 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental 803A & Delphi DCM3.4",
                "Engine_code": "RHR",
            },
            "2.0 HDiF 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 & Siemens/Continental SID803A",
                "Engine_code": "RHH",
            },
            "2.2 HDiF 170hp": {
                "std_power": 170,
                "tuned_power": 210,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "4HT",
            },
            "2.7 HDiF 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2720,
                "Type_ecu": "Siemens/Continental SID201",
                "Engine_code": "UHZ",
            },
            "3.0 HDiF 240hp": {
                "std_power": 240,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2993,
                "Type_ecu": "Bosch EDC17CP11",
                "Engine_code": "DT20C",
            },
            "2.0i 16v 136hp": {
                "std_power": 136,
                "tuned_power": 146,
                "std_torque": 190,
                "tuned_torque": 205,
                "cc": 1997,
                "Type_ecu": "Marelli IAW48P2 & Marelli IAW6",
                "Engine_code": "RFN",
            },
            "3.0i V6 211hp": {
                "std_power": 211,
                "tuned_power": 225,
                "std_torque": 290,
                "tuned_torque": 305,
                "cc": 2946,
                "Type_ecu": "Bosch ME7.4.7",
                "Engine_code": "XFV",
            },
        },
    },
    "408": {
        "2014 - 2022": {
            "1.2 e-THP 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 260,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "EB2",
            },
            "1.6 THP 163hp": {
                "std_power": 163,
                "tuned_power": 215,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "EP6",
            },
        },
    },
    "508": {
        "Ph1 - 2010 - 2014": {
            "1.6 e-HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807 & Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "2.0 BlueHDi 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM3.4",
                "Engine_code": "AH01",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "AH02",
            },
            "2.0 HDI Hybrid4 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10CTED4",
            },
            "2.0 HDi 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Bosch EDC17CP42 & Delphi DCM3.4",
                "Engine_code": "RH01",
            },
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RH02",
            },
            "2.0 HDi 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5 & Delphi DCM3.4",
                "Engine_code": "RH01",
            },
            "2.2 HDi 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "4H02",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6",
            },
            "1.6 THP 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4 & Delphi DCM3.5",
                "Engine_code": "EP6",
            },
            "1.6 THP 184hp": {
                "std_power": 184,
                "tuned_power": 210,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "EP6",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 156,
                "tuned_torque": 171,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "5F01",
            },
        },
        "Ph2 - 2014 - 2018": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17CP10 & Bosch EDC17C60 & Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "2.0 BlueHDi 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM3.4 & Delphi DCM6.2A",
                "Engine_code": "AH01",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "AH02",
            },
            "2.0 HDI Hybrid4 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "DW10CTED4",
            },
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RHH DW10CTED4",
            },
            "2.2 HDi 204hp": {
                "std_power": 204,
                "tuned_power": 235,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2179,
                "Type_ecu": "Bosch EDC17CP42",
                "Engine_code": "DW12C",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2 & Bosch MED17.4.4",
                "Engine_code": "5F02",
            },
            "1.6 e-THP 165hp": {
                "std_power": 165,
                "tuned_power": 215,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "5G01",
            },
        },
        "Ph3 - 2018 ->": {
            "1.5 BlueHDi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHZ DV5RC",
            },
            "2.0 BlueHDi 160hp": {
                "std_power": 160,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "EHY DW10FCC",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1A",
                "Engine_code": "AHW",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HNS EB2ADTS",
            },
            "1.6 Puretech Hybrid 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042",
                "Engine_code": "5GF EP6FADTXD",
            },
            "1.6 Puretech Hybrid 225hp": {
                "std_power": 225,
                "tuned_power": 240,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042",
                "Engine_code": "",
            },
        },
    },
    "607": {
        "All": {
            "2.0 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW10",
            },
            "2.0 HDi 136hp": {
                "std_power": 136,
                "tuned_power": 166,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A",
                "Engine_code": "DW10",
            },
            "2.2 HDi 170hp": {
                "std_power": 170,
                "tuned_power": 210,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16CP39",
                "Engine_code": "DW12",
            },
            "2.2 HDiF 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 314,
                "tuned_torque": 400,
                "cc": 2179,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW12",
            },
            "2.7 HDiF 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2720,
                "Type_ecu": "Siemens/Continental SID201",
                "Engine_code": "DT17TED4",
            },
            "3.0 HDiF 240hp": {
                "std_power": 240,
                "tuned_power": 290,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2992,
                "Type_ecu": "Bosch ME7.4",
                "Engine_code": "",
            },
            "2.0i 16v 138hp": {
                "std_power": 138,
                "tuned_power": 148,
                "std_torque": 190,
                "tuned_torque": 205,
                "cc": 1997,
                "Type_ecu": "Marelli IAW 6LP",
                "Engine_code": "EW10",
            },
            "3.0i V6 210hp": {
                "std_power": 210,
                "tuned_power": 223,
                "std_torque": 285,
                "tuned_torque": 305,
                "cc": 2946,
                "Type_ecu": "Bosch ME7.4.7",
                "Engine_code": "XFV",
            },
        },
    },
    "806": {
        "All": {
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 260,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "DW10ATED",
            },
            "2.0 HDi 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 270,
                "tuned_torque": 344,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW10ATED",
            },
        },
    },
    "807": {
        "All": {
            "2.0 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 310,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Bosch EDC15C2",
                "Engine_code": "DW10",
            },
            "2.0 HDi 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803",
                "Engine_code": "DW10",
            },
            "2.0 HDiF 136hp": {
                "std_power": 136,
                "tuned_power": 166,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Delphi DCM3.5",
                "Engine_code": "DW10",
            },
            "2.0 HDiF 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "",
            },
            "2.2 HDi 128hp": {
                "std_power": 128,
                "tuned_power": 162,
                "std_torque": 314,
                "tuned_torque": 406,
                "cc": 2179,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW12",
            },
            "2.2 HDiF 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 410,
                "cc": 2179,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW12",
            },
            "2.2 HDiF 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 370,
                "tuned_torque": 410,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "DW12",
            },
            "3.0 V6 210hp": {
                "std_power": 210,
                "tuned_power": 225,
                "std_torque": 285,
                "tuned_torque": 305,
                "cc": 2946,
                "Type_ecu": "Bosch ME7.4.4",
                "Engine_code": "ES9",
            },
        },
    },
    "1007": {
        "2005 - 2009": {
            "1.4 HDi 8V 68hp": {
                "std_power": 68,
                "tuned_power": 95,
                "std_torque": 150,
                "tuned_torque": 208,
                "cc": 1398,
                "Type_ecu": "Siemens/Continental SID804",
                "Engine_code": "DV4 TD",
            },
            "1.6 HDiF 16V 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "1.4i 16v 88hp": {
                "std_power": 88,
                "tuned_power": 98,
                "std_torque": 133,
                "tuned_torque": 145,
                "cc": 1360,
                "Type_ecu": "Marelli IAW 6LP",
                "Engine_code": "ET3J4",
            },
            "1.4i 8v 75hp": {
                "std_power": 75,
                "tuned_power": 82,
                "std_torque": 109,
                "tuned_torque": 120,
                "cc": 1360,
                "Type_ecu": "",
                "Engine_code": "TU3JP",
            },
        },
    },
    "2008": {
        "2013 - 2016": {
            "1.4 e-HDI 68hp": {
                "std_power": 68,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1398,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV4TD",
            },
            "1.6 BlueHDi 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 BlueHDi 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDi 8V 75hp": {
                "std_power": 75,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FETED",
            },
            "1.6 e-HDi 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "1.2T Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1",
                "Engine_code": "EB2",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1",
                "Engine_code": "EB2",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2 & Valeo V46.11",
                "Engine_code": "EP6C",
            },
        },
        "2016 - 2019": {
            "1.5 BlueHDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 205,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "DV5",
            },
            "1.6 BlueHDi 8V 75hp": {
                "std_power": 75,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FETED",
            },
            "1.6 BlueHDi 8V 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 BlueHDi 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "EB2",
            },
            "1.2T Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1",
                "Engine_code": "EB2",
            },
        },
        "2019 ->": {
            "1.5 BlueHDi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "HNN EB2ADTX",
            },
            "1.5 BlueHDi 130hp": {
                "std_power": 130,
                "tuned_power": 155,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHZ DV5RC",
            },
            "1.2 Puretech 100hp": {
                "std_power": 100,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HNK EB2ADT",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HNS EB2ADTS",
            },
            "1.2 Puretech 155hp": {
                "std_power": 155,
                "tuned_power": 165,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "",
            },
        },
    },
    "3008": {
        "2009 - 2013": {
            "1.6 HDi 16V 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Siemens/Continental SID807",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "2.0 HDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RH02",
            },
            "2.0 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RHE",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "5FV",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "5FS",
            },
        },
        "2013 - 2016": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807 & Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "2.0 BlueHDi 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2A",
                "Engine_code": "AH01",
            },
            "2.0 HDI Hybrid4 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RH02",
            },
            "2.0 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "AH01",
            },
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RHH DW10CTED4",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "5G01",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "5F01",
            },
        },
        "2016 - 2020": {
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHZ DV5RC",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 255,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 e-HDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6CTED",
            },
            "2.0 BlueHDi 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1A",
                "Engine_code": "AHX",
            },
            "2.0 BlueHDi 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1A",
                "Engine_code": "AHW",
            },
            "1.6 Puretech Hybrid 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Siemens/Continental V46.1 & Valeo VD56.1",
                "Engine_code": "HNY",
            },
            "1.6 Puretech Hybrid4 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MD1CS042",
                "Engine_code": "5GF EP6FADTXD",
            },
            "1.6 Puretech 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 e-THP 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "5G01",
            },
            "1.6 e-THP 205hp": {
                "std_power": 205,
                "tuned_power": 230,
                "std_torque": 275,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "",
            },
        },
        "2020 -> ...": {
            "1.2T Hybrid e-DCS6 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HNY",
            },
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MED17.7.3.1",
                "Engine_code": "YHZ DV5RC",
            },
            "1.6 Puretech Hybrid 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech Hybrid4 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Puretech 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "5GF EP6FADTXD",
            },
        },
    },
    "4007": {
        "2007 - 2011": {
            "2.2 HDi 156hp": {
                "std_power": 156,
                "tuned_power": 185,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2179,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "DW12",
            },
        },
    },
    "4008": {
        "2012 ->": {
            "1.6 HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "1.8 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1560,
                "Type_ecu": "Denso RA6 & Bosch MED17.4.4",
                "Engine_code": "DV6C",
            },
        },
    },
    "5008": {
        "2009 - 2013": {
            "1.6 HDi 16V 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "1.6 e-HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "1.6 e-HDi 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807 & Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "2.0 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10CTED4",
            },
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10CTED4",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 180,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6DT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6C",
            },
        },
        "2013 - 2016": {
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "1.6 e-HDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Siemens/Continental SID807EVO",
                "Engine_code": "DV6CTED",
            },
            "2.0 BlueHDi 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.1 & Delphi DCM6.2",
                "Engine_code": "RH02",
            },
            "2.0 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10CTED4",
            },
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RHH DW10CTED4",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1",
                "Engine_code": "EB2",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6DT",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "EP6C",
            },
        },
        "2017 - 2020": {
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHZ DV5RC",
            },
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2 & Delphi DCM7.1A",
                "Engine_code": "AHW",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2 & Delphi DCM3.5",
                "Engine_code": "AHX",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "HNY",
            },
            "1.6 Puretech 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042",
                "Engine_code": "5GF EP6FADTXD",
            },
            "1.6 e-THP 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.4",
                "Engine_code": "5G01",
            },
        },
        "2020 -> ...": {
            "1.2T Hybrid e-DCS6 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHZ DV5RC",
            },
            "2.0 BlueHDI 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "AHW",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HNY",
            },
            "1.6 Puretech 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "5GF EP6FADTXD",
            },
        },
    },
    "Bipper": {
        "2007 ->": {
            "1.3 HDi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8DF & Marelli MJD9DF & Marelli MJD8F3.QA",
                "Engine_code": "F13DTE5",
            },
            "1.3 HDi 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 200,
                "tuned_torque": 280,
                "cc": 1248,
                "Type_ecu": "Marelli MJD9DF",
                "Engine_code": "",
            },
            "1.4 HDi 8V 70hp": {
                "std_power": 70,
                "tuned_power": 92,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1399,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV4TD",
            },
            "1.4i 8v 75hp": {
                "std_power": 75,
                "tuned_power": 86,
                "std_torque": 118,
                "tuned_torque": 130,
                "cc": 1360,
                "Type_ecu": "Valeo J34P",
                "Engine_code": "TU3A",
            },
        },
    },
    "Boxer": {
        "2019 ->": {
            "2.2 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 185,
                "std_torque": 310,
                "tuned_torque": 430,
                "cc": 2179,
                "Type_ecu": "Delphi DCM7.1B",
                "Engine_code": "",
            },
            "2.2 BlueHDI 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 2179,
                "Type_ecu": "Delphi DCM7.1B & Delphi DCM6.2C",
                "Engine_code": "",
            },
            "2.2 BlueHDI 165hp": {
                "std_power": 165,
                "tuned_power": 185,
                "std_torque": 370,
                "tuned_torque": 430,
                "cc": 2179,
                "Type_ecu": "Delphi DCM7.1B",
                "Engine_code": "",
            },
        },
        "3th - 2006 - 2012": {
            "2.2 HDi 100hp": {
                "std_power": 100,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 350,
                "cc": 2198,
                "Type_ecu": "Visteon DCU102",
                "Engine_code": "4HV",
            },
            "2.2 HDi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 2198,
                "Type_ecu": "Visteon DCU102",
                "Engine_code": "4HU",
            },
            "2.8 HDi 127hp": {
                "std_power": 127,
                "tuned_power": 152,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 2798,
                "Type_ecu": "Bosch EDC15C7",
                "Engine_code": "8140.43S",
            },
            "3.0 HDi 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2998,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17CP52",
                "Engine_code": "F1CE0481 D",
            },
        },
        "4th - 2012 - 2018": {
            "2.0 BlueHDI 110hp": {
                "std_power": 110,
                "tuned_power": 200,
                "std_torque": 285,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID208 & Delphi DCM6.2",
                "Engine_code": "AHM",
            },
            "2.0 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2C & Delphi DCM7.1B & Delphi DCM6.2A & Siemens/Continental SID208",
                "Engine_code": "AHN",
            },
            "2.0 BlueHDI 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM6.2C & Delphi DCM7.1B",
                "Engine_code": "AHP",
            },
            "2.2 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 400,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "4HG",
            },
            "2.2 HDi 130hp": {
                "std_power": 130,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "4HH",
            },
            "2.2 HDi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SID208",
                "Engine_code": "4HJ",
            },
            "2.2 HDi 160hp": {
                "std_power": 160,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2198,
                "Type_ecu": "Delphi DCM6.2C",
                "Engine_code": "",
            },
            "3.0 HDi 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 2998,
                "Type_ecu": "Bosch EDC17CP52",
                "Engine_code": "F1CE3481",
            },
        },
    },
    "Expert / Traveller": {
        "-> 2016": {
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV6ATED4",
            },
            "2.0 HDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 250,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "",
            },
            "2.0 HDi 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental 803A & Delphi DCM3.5 & Delphi DCM6.2A",
                "Engine_code": "DW10",
            },
            "2.0 HDi 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 315,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A",
                "Engine_code": "DW10",
            },
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10",
            },
            "2.0 HDi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW10",
            },
            "2.0 HDi 128hp (->..2011)": {
                "std_power": 128,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Delphi DCM3.5",
                "Engine_code": "DW10",
            },
            "2.0 HDi 128hp (2011->)": {
                "std_power": 128,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "DW10",
            },
        },
        "2016 - 2019": {
            "1.5 BlueHDi 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003 & Delphi DCM6.1",
                "Engine_code": "",
            },
            "1.6 BlueHDI 8V 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 210,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDI 8V 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "DV6FCTED",
            },
            "2.0 BlueHDI 177hp": {
                "std_power": 177,
                "tuned_power": 211,
                "std_torque": 400,
                "tuned_torque": 472,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1A",
                "Engine_code": "AH01",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1A",
                "Engine_code": "AH01",
            },
            "2.0 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1A",
                "Engine_code": "AH01",
            },
        },
        "2019 ->": {
            "1.5 BlueHDi 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "",
            },
            "1.5 BlueHDi 100hp": {
                "std_power": 100,
                "tuned_power": 160,
                "std_torque": 270,
                "tuned_torque": 350,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "",
            },
            "2.0 BlueHDI 120hp": {
                "std_power": 120,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A & Delphi DCM6.2A",
                "Engine_code": "AH01",
            },
            "2.0 BlueHDI 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A & Delphi DCM6.2A",
                "Engine_code": "AH01",
            },
            "2.0 BlueHDI 145hp": {
                "std_power": 145,
                "tuned_power": 180,
                "std_torque": 379,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "",
            },
            "2.0 BlueHDI 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1A",
                "Engine_code": "",
            },
        },
    },
    "Lantrek": {
        "2020 -> ...": {
            "1.9d 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.4 Turbo 210hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Partner": {
        "1997 - 2002": {
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 250,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "DW10",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 147,
                "tuned_torque": 162,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.4.4",
                "Engine_code": "NFU",
            },
        },
        "2002 - 2008": {
            "1.6 HDi 16V 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 185,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV6BTED4",
            },
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6ATED4",
            },
            "2.0 HDi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 250,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID801",
                "Engine_code": "DW10",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 147,
                "tuned_torque": 162,
                "cc": 1598,
                "Type_ecu": "Bosch M7.4.4",
                "Engine_code": "NFU",
            },
        },
        "2008 - 2012": {
            "1.6 HDi 16V 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 330,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "DV6TED4",
            },
            "1.6 HDi 16V 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV6ATED4",
            },
            "1.6 HDi 16V 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 185,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34 & Bosch EDC17C10",
                "Engine_code": "DV6BTED4",
            },
            "1.6 HDi 8V 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "DV6CTED",
            },
            "1.6 HDi 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6DTED",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 147,
                "tuned_torque": 162,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2",
                "Engine_code": "5FS",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 147,
                "tuned_torque": 162,
                "cc": 1598,
                "Type_ecu": "Bosch M7.4.4",
                "Engine_code": "NFU",
            },
        },
        "2012 - 2015": {
            "1.6 HDi 16V 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 185,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "DV6BTED4",
            },
            "1.6 e-HDi 8V 92hp": {
                "std_power": 92,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10 & Siemens/Continental SID807EVO & Bosch EDC17C60",
                "Engine_code": "DV6DTED",
            },
            "1.6 VTi 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 147,
                "tuned_torque": 162,
                "cc": 1598,
                "Type_ecu": "Bosch MEV17.4.2 & Bosch EDC17C10",
                "Engine_code": "EP6C",
            },
        },
        "2015 - 2018": {
            "1.6 BlueHDI 8V 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Bosch EDC17C10 & Bosch MEV17.4.2",
                "Engine_code": "DV6FDTED",
            },
            "1.6 BlueHDI 8V 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Bosch EDC17C10",
                "Engine_code": "DV6FCTED",
            },
            "1.6 BlueHDI 8V 75hp": {
                "std_power": 75,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60 & Bosch EDC17C10 & Bosch EDC17CP02",
                "Engine_code": "DV6FETED",
            },
            "1.2T Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1",
                "Engine_code": "EB2",
            },
        },
        "2018 ->": {
            "1.5 BlueHDI 75hp": {
                "std_power": 75,
                "tuned_power": 125,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C60 & Bosch MD1CS003",
                "Engine_code": "",
            },
            "1.5 BlueHDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 254,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C60 & Bosch MD1CS003",
                "Engine_code": "",
            },
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHZ DV5RC",
            },
            "1.6 BlueHDI 75hp": {
                "std_power": 75,
                "tuned_power": 120,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "",
            },
            "1.6 BlueHDI 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "",
            },
            "1.2 Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "",
            },
            "1.2 Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "HN05",
            },
        },
    },
    "RCZ": {
        "Ph1 - 2010 - 2013": {
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RHH",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6DT",
            },
            "1.6 THP 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 275,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6CDTX",
            },
        },
        "Ph2 - 2013 ->": {
            "2.0 HDi 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RHH",
            },
            "1.6 THP 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6DT",
            },
            "1.6 THP 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 275,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch MED17.4.2",
                "Engine_code": "EP6CDTX",
            },
            "1.6 THP 270hp": {
                "std_power": 270,
                "tuned_power": 295,
                "std_torque": 330,
                "tuned_torque": 375,
                "cc": 1598,
                "Type_ecu": "Bosch MEVD17.4.2",
                "Engine_code": "EP6CDTR",
            },
        },
    },
    "Rifter": {
        "2018 ->": {
            "1.5 BlueHDI 75hp": {
                "std_power": 75,
                "tuned_power": 125,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YHW DV5RE",
            },
            "1.5 BlueHDI 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YH01",
            },
            "1.5 BlueHDI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "YH01",
            },
            "1.2T Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HN05",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "HN05",
            },
        },
    },
},
"Pontiac": {
    "Bonneville": {
        "1997 - 2005": {
            "3.8 Supercharged V6 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 380,
                "tuned_torque": 400,
                "cc": 3791,
                "Type_ecu": "",
                "Engine_code": "L67 Series 2",
            },
        },
    },
    "Firebird": {
        "1997 - 2002": {
            "3.8 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 305,
                "tuned_torque": 325,
                "cc": 3800,
                "Type_ecu": "",
                "Engine_code": "L36",
            },
            "5.7 V8 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 461,
                "tuned_torque": 485,
                "cc": 5670,
                "Type_ecu": "",
                "Engine_code": "LS1",
            },
        },
    },
    "G5": {
        "2006 - 2010": {
            "2.2i 148hp": {
                "std_power": 148,
                "tuned_power": 165,
                "std_torque": 206,
                "tuned_torque": 230,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "Z22SE",
            },
            "2.2i 155hp": {
                "std_power": 155,
                "tuned_power": 175,
                "std_torque": 203,
                "tuned_torque": 230,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "LAP",
            },
            "2.4i 173hp": {
                "std_power": 173,
                "tuned_power": 195,
                "std_torque": 221,
                "tuned_torque": 245,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
        },
    },
    "G6": {
        "2004 - 2010": {
            "2.4i 167hp": {
                "std_power": 167,
                "tuned_power": 185,
                "std_torque": 220,
                "tuned_torque": 245,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
            "3.5 V6 224hp": {
                "std_power": 224,
                "tuned_power": 245,
                "std_torque": 298,
                "tuned_torque": 325,
                "cc": 3510,
                "Type_ecu": "Delco E38",
                "Engine_code": "LZ4",
            },
            "3.5 V6 211hp": {
                "std_power": 211,
                "tuned_power": 230,
                "std_torque": 293,
                "tuned_torque": 320,
                "cc": 3510,
                "Type_ecu": "Delco E38",
                "Engine_code": "LZE",
            },
            "3.9 V6 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 325,
                "tuned_torque": 345,
                "cc": 3880,
                "Type_ecu": "",
                "Engine_code": "LZ9",
            },
        },
    },
    "G8": {
        "2008 - 2010": {
            "3.6 V6 256hp": {
                "std_power": 256,
                "tuned_power": 275,
                "std_torque": 336,
                "tuned_torque": 360,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LY7",
            },
            "6.0 V8 361hp": {
                "std_power": 361,
                "tuned_power": 380,
                "std_torque": 522,
                "tuned_torque": 545,
                "cc": 5976,
                "Type_ecu": "Delco E38",
                "Engine_code": "L76",
            },
            "6.2 V8 415hp": {
                "std_power": 415,
                "tuned_power": 435,
                "std_torque": 563,
                "tuned_torque": 585,
                "cc": 6162,
                "Type_ecu": "Delco E38",
                "Engine_code": "LS3",
            },
        },
    },
    "GTO": {
        "2004 - 2006": {
            "5.7 V8 350hp": {
                "std_power": 350,
                "tuned_power": 370,
                "std_torque": 495,
                "tuned_torque": 525,
                "cc": 5665,
                "Type_ecu": "",
                "Engine_code": "LS1",
            },
            "6.0 V8 400hp": {
                "std_power": 400,
                "tuned_power": 420,
                "std_torque": 542,
                "tuned_torque": 570,
                "cc": 5967,
                "Type_ecu": "",
                "Engine_code": "LS2",
            },
        },
    },
    "Grand Am": {
        "1997 - 2005": {
            "2.2i 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 220,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "Z22SE",
            },
            "2.4i 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 2400,
                "Type_ecu": "",
                "Engine_code": "LD9",
            },
            "3.1 V6 155hp": {
                "std_power": 155,
                "tuned_power": 175,
                "std_torque": 251,
                "tuned_torque": 270,
                "cc": 3136,
                "Type_ecu": "",
                "Engine_code": "L82",
            },
            "3.4 V6 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 270,
                "tuned_torque": 290,
                "cc": 3350,
                "Type_ecu": "",
                "Engine_code": "LA1",
            },
        },
    },
    "Grand Prix": {
        "1997 - 2003": {
            "3.1 V6 175hp": {
                "std_power": 175,
                "tuned_power": 195,
                "std_torque": 264,
                "tuned_torque": 285,
                "cc": 3136,
                "Type_ecu": "",
                "Engine_code": "LG8",
            },
            "3.8 Supercharged V6 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 380,
                "tuned_torque": 410,
                "cc": 3791,
                "Type_ecu": "",
                "Engine_code": "L67",
            },
            "3.8 V6 195hp": {
                "std_power": 195,
                "tuned_power": 215,
                "std_torque": 325,
                "tuned_torque": 345,
                "cc": 3791,
                "Type_ecu": "",
                "Engine_code": "L36",
            },
        },
        "2004 - 2009": {
            "5.3 V8 303hp": {
                "std_power": 303,
                "tuned_power": 325,
                "std_torque": 438,
                "tuned_torque": 460,
                "cc": 5328,
                "Type_ecu": "",
                "Engine_code": "LS4",
            },
        },
    },
    "Montana": {
        "1997 - 2005": {
            "3.4 V6 185hp": {
                "std_power": 185,
                "tuned_power": 205,
                "std_torque": 280,
                "tuned_torque": 300,
                "cc": 3350,
                "Type_ecu": "",
                "Engine_code": "LA1",
            },
        },
        "2006 - 2009": {
            "3.5 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 298,
                "tuned_torque": 325,
                "cc": 3498,
                "Type_ecu": "",
                "Engine_code": "LX9",
            },
            "3.9 V6 240hp": {
                "std_power": 240,
                "tuned_power": 260,
                "std_torque": 332,
                "tuned_torque": 355,
                "cc": 3880,
                "Type_ecu": "",
                "Engine_code": "LZ9",
            },
        },
    },
    "Solstice": {
        "2006 - 2010": {
            "2.0 Turbo 260hp": {
                "std_power": 260,
                "tuned_power": 290,
                "std_torque": 353,
                "tuned_torque": 395,
                "cc": 1998,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "2.0 LNF",
            },
            "2.4i 173hp": {
                "std_power": 173,
                "tuned_power": 195,
                "std_torque": 222,
                "tuned_torque": 245,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
        },
    },
    "Sunfire": {
        "1997 - 2005": {
            "2.2i 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 203,
                "tuned_torque": 225,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "Z22SE",
            },
            "2.4i 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 210,
                "tuned_torque": 230,
                "cc": 2400,
                "Type_ecu": "",
                "Engine_code": "LD9",
            },
        },
    },
    "Torrent": {
        "2007 - 2009": {
            "3.4 V6 185hp": {
                "std_power": 185,
                "tuned_power": 205,
                "std_torque": 285,
                "tuned_torque": 315,
                "cc": 3350,
                "Type_ecu": "",
                "Engine_code": "LNJ",
            },
        },
    },
},
"Porsche": {
    "911": {
        "1997 - 2006 ( 996 )": {
            "3.4i 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 340,
                "tuned_torque": 360,
                "cc": 3387,
                "Type_ecu": "Bosch M5.2.2 & Bosch ME7.8 & Bosch ME7.2",
                "Engine_code": "M96.01",
            },
            "3.6i GT2 Turbo 462hp": {
                "std_power": 462,
                "tuned_power": 515,
                "std_torque": 620,
                "tuned_torque": 725,
                "cc": 3600,
                "Type_ecu": "Bosch ME7.8",
                "Engine_code": "M96.70S",
            },
            "3.6i GT3 RS 381hp": {
                "std_power": 381,
                "tuned_power": 400,
                "std_torque": 385,
                "tuned_torque": 415,
                "cc": 3600,
                "Type_ecu": "Bosch ME7.8",
                "Engine_code": "M96.79",
            },
            "3.6i GT3 RS 400hp": {
                "std_power": 400,
                "tuned_power": 420,
                "std_torque": 385,
                "tuned_torque": 420,
                "cc": 3600,
                "Type_ecu": "",
                "Engine_code": "M96/70",
            },
            "3.6i GT3 360hp": {
                "std_power": 360,
                "tuned_power": 380,
                "std_torque": 370,
                "tuned_torque": 405,
                "cc": 3600,
                "Type_ecu": "Bosch M5.2.2",
                "Engine_code": "M96/76",
            },
            "3.6i Turbo 420hp": {
                "std_power": 420,
                "tuned_power": 480,
                "std_torque": 560,
                "tuned_torque": 650,
                "cc": 3600,
                "Type_ecu": "Bosch ME7.8",
                "Engine_code": "M96/70",
            },
            "3.6i Turbo-S 450hp": {
                "std_power": 450,
                "tuned_power": 495,
                "std_torque": 620,
                "tuned_torque": 670,
                "cc": 3600,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "M96/70E",
            },
            "3.6i 320hp": {
                "std_power": 320,
                "tuned_power": 338,
                "std_torque": 370,
                "tuned_torque": 385,
                "cc": 3596,
                "Type_ecu": "Bosch ME7.8",
                "Engine_code": "M96/03",
            },
            "3.6i 345hp": {
                "std_power": 345,
                "tuned_power": 355,
                "std_torque": 370,
                "tuned_torque": 385,
                "cc": 3596,
                "Type_ecu": "Bosch ME7.2",
                "Engine_code": "M96/03S",
            },
        },
        "2004 - 2011 ( 997 )": {
            "3.6i Turbo 480hp": {
                "std_power": 480,
                "tuned_power": 520,
                "std_torque": 620,
                "tuned_torque": 720,
                "cc": 3600,
                "Type_ecu": "Bosch ME7.8.2 & Siemens/Continental SDI3 & Siemens/Continental EGS52",
                "Engine_code": "M97/70",
            },
            "3.8i Turbo 500hp": {
                "std_power": 500,
                "tuned_power": 550,
                "std_torque": 650,
                "tuned_torque": 740,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI3 & Bosch ME7.8.2",
                "Engine_code": "MA1.70",
            },
            "3.8i Turbo-S 530hp": {
                "std_power": 530,
                "tuned_power": 580,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI3",
                "Engine_code": "MA1.70",
            },
            "Carrera 3.6i 325hp": {
                "std_power": 325,
                "tuned_power": 340,
                "std_torque": 370,
                "tuned_torque": 395,
                "cc": 3596,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "M96/05",
            },
            "Carrera 3.6i 345hp": {
                "std_power": 345,
                "tuned_power": 360,
                "std_torque": 390,
                "tuned_torque": 410,
                "cc": 3596,
                "Type_ecu": "Siemens/Continental SDI3",
                "Engine_code": "MA1/02",
            },
            "Carrera 3.8i 376hp": {
                "std_power": 376,
                "tuned_power": 390,
                "std_torque": 415,
                "tuned_torque": 435,
                "cc": 3824,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "M97.01",
            },
            "Carrera GTS 3.8i 408hp": {
                "std_power": 408,
                "tuned_power": 425,
                "std_torque": 420,
                "tuned_torque": 445,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI3",
                "Engine_code": "MA1/01S",
            },
            "Carrera S 3.8i 355hp": {
                "std_power": 355,
                "tuned_power": 375,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 3824,
                "Type_ecu": "Bosch ME7.8.1 & Bosch ME7.8.4",
                "Engine_code": "M97.01",
            },
            "Carrera S 3.8i 385hp": {
                "std_power": 385,
                "tuned_power": 405,
                "std_torque": 420,
                "tuned_torque": 440,
                "cc": 3824,
                "Type_ecu": "Siemens/Continental SDI3 & Bosch ME7.8",
                "Engine_code": "MA1.01",
            },
            "GT2 3.6i 530hp": {
                "std_power": 530,
                "tuned_power": 580,
                "std_torque": 680,
                "tuned_torque": 800,
                "cc": 3600,
                "Type_ecu": "Bosch ME7.8.2",
                "Engine_code": "M97/70S",
            },
            "GT2 RS 3.6i 620hp": {
                "std_power": 620,
                "tuned_power": 650,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 3600,
                "Type_ecu": "Bosch ME7.8.2",
                "Engine_code": "M97/70S",
            },
            "GT3 3.6i 415hp": {
                "std_power": 415,
                "tuned_power": 435,
                "std_torque": 405,
                "tuned_torque": 430,
                "cc": 3600,
                "Type_ecu": "Bosch ME7.8.1 & Bosch ME7.8.4",
                "Engine_code": "M97/76",
            },
            "GT3 3.8i 435hp": {
                "std_power": 435,
                "tuned_power": 455,
                "std_torque": 430,
                "tuned_torque": 450,
                "cc": 3797,
                "Type_ecu": "Bosch ME7.8.2",
                "Engine_code": "M97/77",
            },
            "GT3 RS 3.6i 415hp": {
                "std_power": 415,
                "tuned_power": 435,
                "std_torque": 405,
                "tuned_torque": 430,
                "cc": 3600,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "M97/76",
            },
            "GT3 RS 3.8i 435hp": {
                "std_power": 435,
                "tuned_power": 455,
                "std_torque": 430,
                "tuned_torque": 450,
                "cc": 3797,
                "Type_ecu": "Siemens/Continental SDI9",
                "Engine_code": "M97/77",
            },
            "GT3 RS 3.8i 450hp": {
                "std_power": 450,
                "tuned_power": 470,
                "std_torque": 430,
                "tuned_torque": 470,
                "cc": 3797,
                "Type_ecu": "Bosch ME7.8.2",
                "Engine_code": "M97/77R",
            },
            "GT3 RS 4.0i 500hp": {
                "std_power": 500,
                "tuned_power": 520,
                "std_torque": 460,
                "tuned_torque": 480,
                "cc": 3996,
                "Type_ecu": "Bosch ME7.8.2",
                "Engine_code": "M97/74",
            },
        },
        "2011 - 2015 ( 991 )": {
            "3.8i GTS 430hp": {
                "std_power": 430,
                "tuned_power": 450,
                "std_torque": 440,
                "tuned_torque": 460,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI9",
                "Engine_code": "MA1.03",
            },
            "3.8i Turbo S 560hp": {
                "std_power": 560,
                "tuned_power": 600,
                "std_torque": 750,
                "tuned_torque": 800,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI9",
                "Engine_code": "MA1.71",
            },
            "3.8i Turbo 520hp": {
                "std_power": 520,
                "tuned_power": 600,
                "std_torque": 710,
                "tuned_torque": 800,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI9 & Siemens/Continental PDK4000",
                "Engine_code": "MA1.71",
            },
            "4.0 GT3 RS 500hp": {
                "std_power": 500,
                "tuned_power": 525,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 3996,
                "Type_ecu": "Bosch MED17.1.11",
                "Engine_code": "MA1.76",
            },
            "Carrera 3.4i 350hp": {
                "std_power": 350,
                "tuned_power": 370,
                "std_torque": 390,
                "tuned_torque": 410,
                "cc": 3436,
                "Type_ecu": "Siemens/Continental SDI9",
                "Engine_code": "MA1.04",
            },
            "Carrera S 3.8i 400hp": {
                "std_power": 400,
                "tuned_power": 440,
                "std_torque": 440,
                "tuned_torque": 460,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI9 & Siemens/Continental PDK4000",
                "Engine_code": "MA1.03",
            },
            "GT3 3.8i 475hp": {
                "std_power": 475,
                "tuned_power": 500,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 3799,
                "Type_ecu": "Bosch MED17.1.11",
                "Engine_code": "MA1.75",
            },
        },
        "2016 - 2018 (991.2)": {
            "3.0T Carrera / 4 370hp": {
                "std_power": 370,
                "tuned_power": 475,
                "std_torque": 450,
                "tuned_torque": 620,
                "cc": 2981,
                "Type_ecu": "Siemens/Continental SDI21.X & PDK TC240",
                "Engine_code": "MDC.KA",
            },
            "3.0T Carrera GTS / 4 GTS 450hp": {
                "std_power": 450,
                "tuned_power": 520,
                "std_torque": 550,
                "tuned_torque": 660,
                "cc": 2981,
                "Type_ecu": "Siemens/Continental SDI21.X & Siemens/Continental PDK4000 & Bosch MG1CP007",
                "Engine_code": "MDC.JA",
            },
            "3.0T Carrera S / 4S 420hp": {
                "std_power": 420,
                "tuned_power": 475,
                "std_torque": 500,
                "tuned_torque": 620,
                "cc": 2981,
                "Type_ecu": "Siemens/Continental SDI21.X & Siemens/Continental PDK4000",
                "Engine_code": "MDC.HA",
            },
            "3.8 Turbo GT2 RS 700hp": {
                "std_power": 700,
                "tuned_power": 760,
                "std_torque": 750,
                "tuned_torque": 830,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI21",
                "Engine_code": "MDH.NA",
            },
            "4.0 GT3 RS 520hp": {
                "std_power": 520,
                "tuned_power": 540,
                "std_torque": 470,
                "tuned_torque": 490,
                "cc": 3996,
                "Type_ecu": "Bosch MED17.1.11 & Bosch MG1CS047",
                "Engine_code": "MDG.GA",
            },
            "GT3 500hp": {
                "std_power": 500,
                "tuned_power": 525,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 3996,
                "Type_ecu": "Bosch MED17.1.11",
                "Engine_code": "MDG.GA",
            },
            "R 500hp": {
                "std_power": 500,
                "tuned_power": 525,
                "std_torque": 460,
                "tuned_torque": 485,
                "cc": 3996,
                "Type_ecu": "Bosch MED17.1.11",
                "Engine_code": "MA1.76",
            },
            "Turbo S 580hp": {
                "std_power": 580,
                "tuned_power": 640,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI21.3 & Siemens/Continental PDK4000 & Siemens/Continental SDI9",
                "Engine_code": "DBC",
            },
            "Turbo 540hp": {
                "std_power": 540,
                "tuned_power": 640,
                "std_torque": 660,
                "tuned_torque": 820,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI21.3",
                "Engine_code": "DAB",
            },
        },
        "2019 -> ... (992)": {
            "3.0 Carrera 4S 450hp": {
                "std_power": 450,
                "tuned_power": 510,
                "std_torque": 530,
                "tuned_torque": 635,
                "cc": 2981,
                "Type_ecu": "Bosch MG1CP007",
                "Engine_code": "DKKA",
            },
            "3.0 Carrera GTS 480hp": {
                "std_power": 480,
                "tuned_power": 510,
                "std_torque": 570,
                "tuned_torque": 635,
                "cc": 2981,
                "Type_ecu": "Bosch MG1CP007",
                "Engine_code": "DKKA",
            },
            "3.0 Carrera S 450hp": {
                "std_power": 450,
                "tuned_power": 510,
                "std_torque": 530,
                "tuned_torque": 635,
                "cc": 2981,
                "Type_ecu": "Bosch MG1CP007",
                "Engine_code": "DKK",
            },
            "3.0 Carrera 385hp": {
                "std_power": 385,
                "tuned_power": 470,
                "std_torque": 450,
                "tuned_torque": 620,
                "cc": 2981,
                "Type_ecu": "Bosch MG1CP007",
                "Engine_code": "DKCA",
            },
            "3.8 Bi-Turbo S 650hp": {
                "std_power": 650,
                "tuned_power": 740,
                "std_torque": 800,
                "tuned_torque": 890,
                "cc": 3745,
                "Type_ecu": "Bosch MG1CP007",
                "Engine_code": "DKH",
            },
            "3.8 Bi-Turbo 580hp": {
                "std_power": 580,
                "tuned_power": 740,
                "std_torque": 740,
                "tuned_torque": 890,
                "cc": 3745,
                "Type_ecu": "Siemens/Continental SDI9 & Bosch MG1CP007",
                "Engine_code": "DKEA",
            },
            "4.0 GT3 510hp": {
                "std_power": 510,
                "tuned_power": 540,
                "std_torque": 470,
                "tuned_torque": 490,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS047",
                "Engine_code": "DRMA",
            },
        },
    },
    "Boxster": {
        "1996 - 2004 ( 986 )": {
            "2.5i 204hp": {
                "std_power": 204,
                "tuned_power": 220,
                "std_torque": 245,
                "tuned_torque": 280,
                "cc": 2480,
                "Type_ecu": "Bosch M5.2.2",
                "Engine_code": "M96/20",
            },
            "2.7i 211hp": {
                "std_power": 211,
                "tuned_power": 245,
                "std_torque": 260,
                "tuned_torque": 290,
                "cc": 2687,
                "Type_ecu": "Bosch ME9.7 & Bosch ME7.8 & Bosch ME7.2",
                "Engine_code": "",
            },
            "2.7i 220hp": {
                "std_power": 220,
                "tuned_power": 240,
                "std_torque": 260,
                "tuned_torque": 290,
                "cc": 2687,
                "Type_ecu": "Bosch ME7.2",
                "Engine_code": "M96/22",
            },
            "2.7i 228hp": {
                "std_power": 228,
                "tuned_power": 245,
                "std_torque": 260,
                "tuned_torque": 290,
                "cc": 2687,
                "Type_ecu": "Bosch ME7.8",
                "Engine_code": "M96/23",
            },
            "3.2i 252hp": {
                "std_power": 252,
                "tuned_power": 270,
                "std_torque": 305,
                "tuned_torque": 330,
                "cc": 3179,
                "Type_ecu": "Bosch ME7.2",
                "Engine_code": "M96/21",
            },
            "3.2i 260hp": {
                "std_power": 260,
                "tuned_power": 275,
                "std_torque": 310,
                "tuned_torque": 325,
                "cc": 3179,
                "Type_ecu": "Bosch ME7.8",
                "Engine_code": "M96/24",
            },
        },
        "2005 - 2011 ( 987 )": {
            "2.7i 211hp": {
                "std_power": 211,
                "tuned_power": 255,
                "std_torque": 270,
                "tuned_torque": 285,
                "cc": 2687,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "M96.25",
            },
            "2.7i 240hp": {
                "std_power": 240,
                "tuned_power": 255,
                "std_torque": 270,
                "tuned_torque": 285,
                "cc": 2687,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "M97/20",
            },
            "2.9i 211hp": {
                "std_power": 211,
                "tuned_power": 275,
                "std_torque": 290,
                "tuned_torque": 310,
                "cc": 2893,
                "Type_ecu": "Bosch ME7.8.2",
                "Engine_code": "",
            },
            "2.9i 256hp": {
                "std_power": 256,
                "tuned_power": 275,
                "std_torque": 290,
                "tuned_torque": 310,
                "cc": 2893,
                "Type_ecu": "Bosch ME7.8.2",
                "Engine_code": "MA120",
            },
            "S 3.2i 280hp": {
                "std_power": 280,
                "tuned_power": 295,
                "std_torque": 320,
                "tuned_torque": 335,
                "cc": 3179,
                "Type_ecu": "Bosch ME7.8.1 & Bosch ME7.8.4",
                "Engine_code": "M96/26",
            },
            "S 3.4i 295hp": {
                "std_power": 295,
                "tuned_power": 310,
                "std_torque": 340,
                "tuned_torque": 355,
                "cc": 3387,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "M97/21",
            },
            "S 3.4i 310hp": {
                "std_power": 310,
                "tuned_power": 337,
                "std_torque": 360,
                "tuned_torque": 380,
                "cc": 3436,
                "Type_ecu": "Siemens/Continental SDI3",
                "Engine_code": "MA1.21",
            },
            "Spyder 3.4i 320hp": {
                "std_power": 320,
                "tuned_power": 338,
                "std_torque": 360,
                "tuned_torque": 385,
                "cc": 3436,
                "Type_ecu": "Siemens/Continental SDI3",
                "Engine_code": "MA1.21",
            },
        },
        "2011 - 2016 ( 981 )": {
            "2.7i 265hp": {
                "std_power": 265,
                "tuned_power": 290,
                "std_torque": 280,
                "tuned_torque": 300,
                "cc": 2706,
                "Type_ecu": "Siemens/Continental SDI9",
                "Engine_code": "MA1.22",
            },
            "2.7i 211hp": {
                "std_power": 211,
                "tuned_power": 285,
                "std_torque": 290,
                "tuned_torque": 300,
                "cc": 2706,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "MA1.22",
            },
            "3.8i Spyder 375hp": {
                "std_power": 375,
                "tuned_power": 395,
                "std_torque": 420,
                "tuned_torque": 440,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI9",
                "Engine_code": "MDB.XA",
            },
            "GTS 3.4i 330hp": {
                "std_power": 330,
                "tuned_power": 350,
                "std_torque": 360,
                "tuned_torque": 400,
                "cc": 3436,
                "Type_ecu": "Siemens/Continental SDI9",
                "Engine_code": "MA1.23",
            },
            "S 3.4i 315hp": {
                "std_power": 315,
                "tuned_power": 335,
                "std_torque": 360,
                "tuned_torque": 385,
                "cc": 3436,
                "Type_ecu": "Siemens/Continental SDI9 & PDK TC240",
                "Engine_code": "MA1.23",
            },
        },
        "2016 -> (718)": {
            "2.0T 300hp": {
                "std_power": 300,
                "tuned_power": 350,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1988,
                "Type_ecu": "Siemens/Continental SDI21.1 & PDK TC240",
                "Engine_code": "MDD.PB",
            },
            "2.0T 250hp": {
                "std_power": 250,
                "tuned_power": 350,
                "std_torque": 310,
                "tuned_torque": 460,
                "cc": 1988,
                "Type_ecu": "Siemens/Continental SDI21.X",
                "Engine_code": "MDD.PB",
            },
            "GTS 2.5T 365hp": {
                "std_power": 365,
                "tuned_power": 420,
                "std_torque": 430,
                "tuned_torque": 510,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental SDI21.1",
                "Engine_code": "MJD.UA",
            },
            "S 2.5T 350hp": {
                "std_power": 350,
                "tuned_power": 400,
                "std_torque": 420,
                "tuned_torque": 520,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental SDI21.1 & PDK TC240",
                "Engine_code": "MJD.UA",
            },
        },
    },
    "Carrera GT": {
        "980 - 2005 - 2007": {
            "5.7i V10 - 612hp": {
                "std_power": 612,
                "tuned_power": 639,
                "std_torque": 590,
                "tuned_torque": 628,
                "cc": 5733,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "M80/01",
            },
        },
    },
    "Cayenne": {
        "955 - 2002 - 2007": {
            "3.2 V6 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 345,
                "cc": 3189,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "M02.2Y",
            },
            "4.5 V8 Turbo 450hp": {
                "std_power": 450,
                "tuned_power": 500,
                "std_torque": 620,
                "tuned_torque": 750,
                "cc": 4511,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "M48/50",
            },
            "4.5 V8 Turbo 500hp": {
                "std_power": 500,
                "tuned_power": 530,
                "std_torque": 700,
                "tuned_torque": 780,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental VDOSDI4 & Bosch ME7",
                "Engine_code": "M48/50S",
            },
            "S 4.5 Turbo 520hp": {
                "std_power": 520,
                "tuned_power": 560,
                "std_torque": 720,
                "tuned_torque": 800,
                "cc": 4511,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "M48/50",
            },
            "S 4.5 V8 340hp": {
                "std_power": 340,
                "tuned_power": 360,
                "std_torque": 420,
                "tuned_torque": 445,
                "cc": 4511,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "M48.00",
            },
        },
        "957 - 2007 - 2010": {
            "3.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 290,
                "std_torque": 550,
                "tuned_torque": 620,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP14",
                "Engine_code": "M05.9D",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 295,
                "std_torque": 550,
                "tuned_torque": 620,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP14",
                "Engine_code": "M05.9D",
            },
            "3.6 V6 290hp": {
                "std_power": 290,
                "tuned_power": 310,
                "std_torque": 385,
                "tuned_torque": 410,
                "cc": 3598,
                "Type_ecu": "Bosch MED9.1.1",
                "Engine_code": "M55/01",
            },
            "4.8 Turbo 500hp": {
                "std_power": 500,
                "tuned_power": 550,
                "std_torque": 700,
                "tuned_torque": 790,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI4 & Siemens/Continental SDI8",
                "Engine_code": "M48/51",
            },
            "GTS 4.8 V8 405hp": {
                "std_power": 405,
                "tuned_power": 425,
                "std_torque": 500,
                "tuned_torque": 535,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI4",
                "Engine_code": "M48/01G",
            },
            "S 4.8 Turbo 550hp": {
                "std_power": 550,
                "tuned_power": 600,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI4",
                "Engine_code": "M48.51",
            },
            "S 4.8 V8 385hp": {
                "std_power": 385,
                "tuned_power": 405,
                "std_torque": 500,
                "tuned_torque": 535,
                "cc": 4511,
                "Type_ecu": "Siemens/Continental SDI4",
                "Engine_code": "M48/01",
            },
        },
        "958 - 2010 - 2014": {
            "3.0 TDI 245hp": {
                "std_power": 245,
                "tuned_power": 290,
                "std_torque": 550,
                "tuned_torque": 620,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Aisin AL1000",
                "Engine_code": "MCR.CA",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 300,
                "std_torque": 550,
                "tuned_torque": 630,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "MCR.CB",
            },
            "3.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 300,
                "std_torque": 550,
                "tuned_torque": 630,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "M05.9E",
            },
            "S 4.2 TDI 382hp": {
                "std_power": 382,
                "tuned_power": 450,
                "std_torque": 850,
                "tuned_torque": 950,
                "cc": 4134,
                "Type_ecu": "Bosch EDC17CP44 & Aisin AL1000",
                "Engine_code": "MCU.DB / MCU.DC",
            },
            "3.0 S Hybrid 416hp": {
                "std_power": 416,
                "tuned_power": 480,
                "std_torque": 590,
                "tuned_torque": 720,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.1.6 & Siemens/Continental Simos 8.5 & Bosch MED17.1.11",
                "Engine_code": "MCG.E",
            },
            "3.6i 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 425,
                "cc": 3598,
                "Type_ecu": "Bosch MED17.1.6 & Aisin AL1000",
                "Engine_code": "M55/02",
            },
            "4.8 Turbo 500hp": {
                "std_power": 500,
                "tuned_power": 590,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI8 & Siemens/Continental SDI6 & Siemens/Continental SDI7 & Siemens/Continental SDI8 & Aisin AL1000",
                "Engine_code": "M48/52",
            },
            "GTS 4.8 V8 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 515,
                "tuned_torque": 540,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI7 & Siemens/Continental SDI8 & Aisin AL1000",
                "Engine_code": "M48/02",
            },
            "S 4.8 Turbo 550hp": {
                "std_power": 550,
                "tuned_power": 600,
                "std_torque": 750,
                "tuned_torque": 850,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI8",
                "Engine_code": "M48.52",
            },
            "S 4.8 V8 400hp": {
                "std_power": 400,
                "tuned_power": 425,
                "std_torque": 500,
                "tuned_torque": 530,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI7 & Siemens/Continental SDI4 & Siemens/Continental SDI8",
                "Engine_code": "M48/02",
            },
        },
        "958 - 2014 - 2017": {
            "3.0 TDI 262hp": {
                "std_power": 262,
                "tuned_power": 300,
                "std_torque": 580,
                "tuned_torque": 630,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Aisin AL1000",
                "Engine_code": "MCV.VA",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 300,
                "std_torque": 580,
                "tuned_torque": 630,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "MCV.VC",
            },
            "4.2 TDI 385hp": {
                "std_power": 385,
                "tuned_power": 450,
                "std_torque": 850,
                "tuned_torque": 950,
                "cc": 4134,
                "Type_ecu": "Bosch EDC17CP44 & Aisin AL1000",
                "Engine_code": "MCU.DB / MCU.DC",
            },
            "4.8 Turbo 520hp": {
                "std_power": 520,
                "tuned_power": 640,
                "std_torque": 750,
                "tuned_torque": 950,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI8 & Siemens/Continental Simos 8.5 & Siemens/Continental SDI10.4",
                "Engine_code": "MCF.TB",
            },
            "GTS 3.6T 440hp": {
                "std_power": 440,
                "tuned_power": 480,
                "std_torque": 600,
                "tuned_torque": 650,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental SDI10.2 & Siemens/Continental SDI10.3",
                "Engine_code": "MCX.ZA",
            },
            "S 3.0 E-Hybrid 416hp": {
                "std_power": 416,
                "tuned_power": 480,
                "std_torque": 590,
                "tuned_torque": 700,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.1.6 & Siemens/Continental Simos 8.5 & Siemens/Continental Simos 8.4 & Aisin AL1000",
                "Engine_code": "MCG.E",
            },
            "S 3.6T 420hp": {
                "std_power": 420,
                "tuned_power": 460,
                "std_torque": 550,
                "tuned_torque": 620,
                "cc": 3604,
                "Type_ecu": "Bosch MED17.1.6 & Siemens/Continental SDI10.0 & Siemens/Continental SDI10.3",
                "Engine_code": "MCU.RA",
            },
            "S 4.8 Turbo 570hp": {
                "std_power": 570,
                "tuned_power": 640,
                "std_torque": 800,
                "tuned_torque": 950,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI8 & Siemens/Continental SDI10.4",
                "Engine_code": "MCY.XA",
            },
        },
        "E3 - 2018 - 2023": {
            "3.0T E-Hybrid 462hp": {
                "std_power": 462,
                "tuned_power": 520,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & ZF AL552",
                "Engine_code": "M.DCBE",
            },
            "3.0T V6 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 610,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & ZF AL552",
                "Engine_code": "MDC.BE",
            },
            "4.0 Turbo 550hp": {
                "std_power": 550,
                "tuned_power": 620,
                "std_torque": 770,
                "tuned_torque": 870,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "MDC.UA",
            },
            "4.0T GTS 460hp": {
                "std_power": 460,
                "tuned_power": 620,
                "std_torque": 620,
                "tuned_torque": 870,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS002 & Bosch MG1CS008 & ZF AL552",
                "Engine_code": "CVDD",
            },
            "4.0T S E-Hybrid 680hp": {
                "std_power": 680,
                "tuned_power": 750,
                "std_torque": 900,
                "tuned_torque": 1000,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "M.DCUA",
            },
            "S 2.9 Bi-Turbo V6 440hp": {
                "std_power": 440,
                "tuned_power": 510,
                "std_torque": 550,
                "tuned_torque": 780,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "MDC.AB",
            },
        },
    },
    "Cayenne Coupé": {
        "2019 ->": {
            "3.0T E-Hybrid 462hp": {
                "std_power": 462,
                "tuned_power": 520,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & ZF AL552",
                "Engine_code": "M.DCBE",
            },
            "3.0T V6 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 610,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "MCB.BE",
            },
            "4.0 Turbo GT 640hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "4.0 Turbo 550hp": {
                "std_power": 550,
                "tuned_power": 620,
                "std_torque": 770,
                "tuned_torque": 870,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "MDC.UA",
            },
            "4.0T GTS 460hp": {
                "std_power": 460,
                "tuned_power": 620,
                "std_torque": 620,
                "tuned_torque": 870,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CVDD",
            },
            "4.0T S E-Hybrid 680hp": {
                "std_power": 680,
                "tuned_power": 750,
                "std_torque": 900,
                "tuned_torque": 1000,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "M.DCUA",
            },
            "S 2.9 Bi-Turbo V6 440hp": {
                "std_power": 440,
                "tuned_power": 510,
                "std_torque": 550,
                "tuned_torque": 780,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "M.DCAB",
            },
        },
    },
    "Cayman": {
        "2006 - 2013 ( 987 )": {
            "2.7i 211hp": {
                "std_power": 211,
                "tuned_power": 255,
                "std_torque": 273,
                "tuned_torque": 285,
                "cc": 2687,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "",
            },
            "2.7i 245hp": {
                "std_power": 245,
                "tuned_power": 255,
                "std_torque": 273,
                "tuned_torque": 285,
                "cc": 2687,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "M97/20",
            },
            "2.9i 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 2893,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.9i 265hp": {
                "std_power": 265,
                "tuned_power": 280,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 2893,
                "Type_ecu": "Bosch ME7.8.2",
                "Engine_code": "MA1.20",
            },
            "3.4i 295hp": {
                "std_power": 295,
                "tuned_power": 305,
                "std_torque": 340,
                "tuned_torque": 355,
                "cc": 3386,
                "Type_ecu": "Bosch ME7.8.1 & Bosch ME7.8.4",
                "Engine_code": "M97/21",
            },
            "R 3.4i 330hp": {
                "std_power": 330,
                "tuned_power": 345,
                "std_torque": 370,
                "tuned_torque": 390,
                "cc": 3436,
                "Type_ecu": "Siemens/Continental SDI3",
                "Engine_code": "MA1.21",
            },
            "S 3.4i 303hp": {
                "std_power": 303,
                "tuned_power": 318,
                "std_torque": 340,
                "tuned_torque": 360,
                "cc": 3436,
                "Type_ecu": "Bosch ME7.8.1",
                "Engine_code": "M97.22",
            },
            "S 3.4i 320hp": {
                "std_power": 320,
                "tuned_power": 335,
                "std_torque": 370,
                "tuned_torque": 390,
                "cc": 3436,
                "Type_ecu": "Siemens/Continental SDI3 & Siemens/Continental PDK3000",
                "Engine_code": "MA1.21",
            },
        },
        "2013 - 2015 ( 981 )": {
            "2.7i 211hp": {
                "std_power": 211,
                "tuned_power": 285,
                "std_torque": 290,
                "tuned_torque": 310,
                "cc": 2706,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.7i 275hp": {
                "std_power": 275,
                "tuned_power": 285,
                "std_torque": 290,
                "tuned_torque": 310,
                "cc": 2706,
                "Type_ecu": "Siemens/Continental SDI9",
                "Engine_code": "MA1.22",
            },
            "3.4i 325hp": {
                "std_power": 325,
                "tuned_power": 340,
                "std_torque": 370,
                "tuned_torque": 390,
                "cc": 3436,
                "Type_ecu": "Siemens/Continental SDI9 & Siemens/Continental PDK4000",
                "Engine_code": "MA1.23",
            },
            "GT4 3.8i 385hp": {
                "std_power": 385,
                "tuned_power": 405,
                "std_torque": 420,
                "tuned_torque": 440,
                "cc": 3800,
                "Type_ecu": "Siemens/Continental SDI9",
                "Engine_code": "MDB.XA",
            },
            "GTS 3.4i 340hp": {
                "std_power": 340,
                "tuned_power": 355,
                "std_torque": 380,
                "tuned_torque": 400,
                "cc": 3436,
                "Type_ecu": "Siemens/Continental SDI9",
                "Engine_code": "MA1.23",
            },
        },
        "2016 -> ( 718 )": {
            "GT4 RS (4.0) 493hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0T 300hp": {
                "std_power": 300,
                "tuned_power": 350,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1988,
                "Type_ecu": "Siemens/Continental SDI21.1",
                "Engine_code": "MDD.PB",
            },
            "2.0T 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 1988,
                "Type_ecu": "Siemens/Continental DSI21.1 & PDK TC240",
                "Engine_code": "",
            },
            "GT4 (4.0) 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 420,
                "tuned_torque": 440,
                "cc": 3995,
                "Type_ecu": "Bosch MG1CP007",
                "Engine_code": "DKDA",
            },
            "GTS 2.5T 365hp": {
                "std_power": 365,
                "tuned_power": 420,
                "std_torque": 430,
                "tuned_torque": 510,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental SDI21.1",
                "Engine_code": "MJD.UA",
            },
            "GTS 4.0 400hp": {
                "std_power": 400,
                "tuned_power": 440,
                "std_torque": 420,
                "tuned_torque": 440,
                "cc": 3995,
                "Type_ecu": "Bosch MG1CP007",
                "Engine_code": "DKD",
            },
            "S 2.5T 350hp": {
                "std_power": 350,
                "tuned_power": 420,
                "std_torque": 420,
                "tuned_torque": 510,
                "cc": 2497,
                "Type_ecu": "Siemens/Continental SDI21.1",
                "Engine_code": "MDD.NC",
            },
        },
    },
    "Macan": {
        "2014 - 2018": {
            "S Diesel 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 580,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic DL501",
                "Engine_code": "CTB",
            },
            "S Diesel 211hp": {
                "std_power": 211,
                "tuned_power": 310,
                "std_torque": 580,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Temic DL501",
                "Engine_code": "MCT.BB",
            },
            "2.0 TFSI 252hp": {
                "std_power": 252,
                "tuned_power": 300,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DL501",
                "Engine_code": "MCY.NB / MCY.PA",
            },
            "3.6 Bi-Turbo Performance pack 440hp": {
                "std_power": 440,
                "tuned_power": 460,
                "std_torque": 600,
                "tuned_torque": 660,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental SDI10.2 & Temic DL501",
                "Engine_code": "CTL",
            },
            "3.6 Bi-Turbo 400hp": {
                "std_power": 400,
                "tuned_power": 440,
                "std_torque": 550,
                "tuned_torque": 640,
                "cc": 3604,
                "Type_ecu": "Siemens/Continental SDI10.0 & Siemens/Continental SDI10.1 & Temic DL501 Gen2 & Siemens/Continental SDI10.2",
                "Engine_code": "CTL",
            },
            "GTS 3.0 Bi-Turbo 360hp": {
                "std_power": 360,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2997,
                "Type_ecu": "Siemens/Continental SDI10.2 & Temic DL501 Gen2",
                "Engine_code": "MDC.NA",
            },
            "S 3.0 Bi-Turbo 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 460,
                "tuned_torque": 600,
                "cc": 2997,
                "Type_ecu": "Siemens/Continental SDI10.2 & Temic DL501",
                "Engine_code": "MCT.MA",
            },
        },
        "2019 - 2021": {
            "2.0 TFSI 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10",
                "Engine_code": "DMT",
            },
            "2.0 TFSI 252hp": {
                "std_power": 252,
                "tuned_power": 300,
                "std_torque": 370,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Temic DL501",
                "Engine_code": "MCY.NB / MCY.PA",
            },
            "2.9 GTS 380hp": {
                "std_power": 380,
                "tuned_power": 510,
                "std_torque": 520,
                "tuned_torque": 680,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "DGRC",
            },
            "2.9 Turbo 440hp": {
                "std_power": 440,
                "tuned_power": 510,
                "std_torque": 550,
                "tuned_torque": 780,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "DGR",
            },
            "S 3.0T 355hp": {
                "std_power": 355,
                "tuned_power": 400,
                "std_torque": 480,
                "tuned_torque": 600,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & Temic DL501 Gen2",
                "Engine_code": "DLZB",
            },
        },
        "2021 -> ...": {
            "2.0 TFSI 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Siemens/Continental Simos 19.3",
                "Engine_code": "DMT",
            },
            "2.9 GTS 440hp": {
                "std_power": 440,
                "tuned_power": 510,
                "std_torque": 550,
                "tuned_torque": 730,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "DGR",
            },
            "2.9 S 380hp": {
                "std_power": 380,
                "tuned_power": 510,
                "std_torque": 520,
                "tuned_torque": 730,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002 & Temic DL501 Gen2",
                "Engine_code": "DGR",
            },
        },
    },
    "Panamera": {
        "970 - 2009 - 2013": {
            "3.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 300,
                "std_torque": 550,
                "tuned_torque": 630,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "MCR.CC",
            },
            "3.0 TDI 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 550,
                "tuned_torque": 630,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "MCR.CC",
            },
            "3.0 Hybrid 380hp": {
                "std_power": 380,
                "tuned_power": 450,
                "std_torque": 580,
                "tuned_torque": 680,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.1.6",
                "Engine_code": "MCG.EA",
            },
            "3.0 S E-Hybrid 416hp": {
                "std_power": 416,
                "tuned_power": 480,
                "std_torque": 590,
                "tuned_torque": 720,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.1.6",
                "Engine_code": "MCG.EA",
            },
            "3.6i 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 400,
                "tuned_torque": 425,
                "cc": 3605,
                "Type_ecu": "Siemens/Continental SDI7",
                "Engine_code": "M46/20",
            },
            "4.8 Turbo 500hp": {
                "std_power": 500,
                "tuned_power": 590,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI6",
                "Engine_code": "M48/70",
            },
            "4S 4.8i 400hp": {
                "std_power": 400,
                "tuned_power": 420,
                "std_torque": 500,
                "tuned_torque": 525,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI6",
                "Engine_code": "M48/20",
            },
            "GTS 4.8 V8 430hp": {
                "std_power": 430,
                "tuned_power": 450,
                "std_torque": 520,
                "tuned_torque": 540,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI6",
                "Engine_code": "M48/40",
            },
            "S 4.8 Turbo 550hp": {
                "std_power": 550,
                "tuned_power": 590,
                "std_torque": 750,
                "tuned_torque": 800,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI6",
                "Engine_code": "M48/70",
            },
        },
        "970 - 2013 - 2016": {
            "3.0 TDI 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 550,
                "tuned_torque": 620,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "MCR.CC",
            },
            "3.0 V6 Bi TDI 300hp": {
                "std_power": 300,
                "tuned_power": 350,
                "std_torque": 650,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "MCW.JA",
            },
            "3.6i 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 400,
                "tuned_torque": 425,
                "cc": 3605,
                "Type_ecu": "Siemens/Continental SDI7",
                "Engine_code": "MCW.AA",
            },
            "4.8 Turbo 520hp": {
                "std_power": 520,
                "tuned_power": 590,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI8 & Siemens/Continental SDI6 & Siemens/Continental PDK4000",
                "Engine_code": "MCW.BA",
            },
            "4S 4.8i 420hp": {
                "std_power": 420,
                "tuned_power": 440,
                "std_torque": 520,
                "tuned_torque": 540,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI7",
                "Engine_code": "MCW.DA",
            },
            "GTS 4.8 V8 440hp": {
                "std_power": 440,
                "tuned_power": 450,
                "std_torque": 520,
                "tuned_torque": 540,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI6",
                "Engine_code": "MCX.PA",
            },
            "S 3.0 E-Hybrid 416hp": {
                "std_power": 416,
                "tuned_power": 480,
                "std_torque": 590,
                "tuned_torque": 700,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.1.6",
                "Engine_code": "MCG.EA / MCG.FA",
            },
            "S 3.0T 420hp": {
                "std_power": 420,
                "tuned_power": 475,
                "std_torque": 500,
                "tuned_torque": 570,
                "cc": 2997,
                "Type_ecu": "Bosch MED17.1.6 & Siemens/Continental SDI10.0 & Siemens/Continental SDI8 & Siemens/Continental SDI10.1",
                "Engine_code": "M46.60",
            },
            "S 4.8 Turbo 570hp": {
                "std_power": 570,
                "tuned_power": 620,
                "std_torque": 800,
                "tuned_torque": 850,
                "cc": 4806,
                "Type_ecu": "Siemens/Continental SDI8 & Siemens/Continental SDI6",
                "Engine_code": "MCW.CA",
            },
        },
        "971 - 2017 - 2019": {
            "4S Diesel 422hp": {
                "std_power": 422,
                "tuned_power": 480,
                "std_torque": 850,
                "tuned_torque": 1000,
                "cc": 3956,
                "Type_ecu": "Bosch MD1CP014",
                "Engine_code": "DBU",
            },
            "2.9T E-Hybrid 462hp": {
                "std_power": 462,
                "tuned_power": 600,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "MDG.PA",
            },
            "2.9T 440hp": {
                "std_power": 440,
                "tuned_power": 510,
                "std_torque": 550,
                "tuned_torque": 780,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002 & ZF 8HP",
                "Engine_code": "CSZ",
            },
            "3.0T 330hp": {
                "std_power": 330,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 680,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS001 & Bosch MG1CS002 & Bosch MED17.1.11 & Bosch MG1CP007",
                "Engine_code": "MCX.TA",
            },
            "4.0 Turbo 550hp": {
                "std_power": 550,
                "tuned_power": 620,
                "std_torque": 770,
                "tuned_torque": 870,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CVD",
            },
            "4.0T GTS 460hp": {
                "std_power": 460,
                "tuned_power": 620,
                "std_torque": 620,
                "tuned_torque": 870,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CVDD",
            },
            "4.0T S E-Hybrid 680hp": {
                "std_power": 680,
                "tuned_power": 800,
                "std_torque": 850,
                "tuned_torque": 1000,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "MCV.DA",
            },
        },
        "971 - 2020 ->": {
            "2.9T V6 4 E-Hybrid 462hp": {
                "std_power": 462,
                "tuned_power": 600,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "MDG.PA",
            },
            "2.9T V6 4S E-Hybrid 560hp": {
                "std_power": 560,
                "tuned_power": 630,
                "std_torque": 750,
                "tuned_torque": 820,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "",
            },
            "2.9T V6 4S 440hp": {
                "std_power": 440,
                "tuned_power": 510,
                "std_torque": 550,
                "tuned_torque": 780,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "MCS.ZA",
            },
            "2.9T V6 330hp": {
                "std_power": 330,
                "tuned_power": 380,
                "std_torque": 450,
                "tuned_torque": 550,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "MCX.TA",
            },
            "4.0 Turbo S E-Hybrid 700hp": {
                "std_power": 700,
                "tuned_power": 750,
                "std_torque": 850,
                "tuned_torque": 1000,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CVD",
            },
            "4.0 Turbo 630hp": {
                "std_power": 630,
                "tuned_power": 660,
                "std_torque": 820,
                "tuned_torque": 900,
                "cc": 3996,
                "Type_ecu": "",
                "Engine_code": "MCV.DA",
            },
            "4.0T GTS 480hp": {
                "std_power": 480,
                "tuned_power": 620,
                "std_torque": 620,
                "tuned_torque": 880,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CVDD",
            },
        },
    },
    "Panamera Sport Turismo": {
        "2017 - 2019": {
            "2.9T 440hp": {
                "std_power": 440,
                "tuned_power": 530,
                "std_torque": 550,
                "tuned_torque": 660,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS008 & Bosch MG1CS002",
                "Engine_code": "MCS.ZA",
            },
            "4S Diesel 440hp": {
                "std_power": 440,
                "tuned_power": 480,
                "std_torque": 850,
                "tuned_torque": 1000,
                "cc": 3956,
                "Type_ecu": "Bosch MD1CP014",
                "Engine_code": "MDB.UB / MDB.UC",
            },
            "3.0T 330hp": {
                "std_power": 330,
                "tuned_power": 400,
                "std_torque": 450,
                "tuned_torque": 680,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "MCX.TA",
            },
            "4 E-Hybrid 462hp": {
                "std_power": 462,
                "tuned_power": 600,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CP002 & Bosch MG1CS002",
                "Engine_code": "MDG.PA",
            },
            "4.0 Turbo 550hp": {
                "std_power": 550,
                "tuned_power": 620,
                "std_torque": 770,
                "tuned_torque": 870,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "MCV.DA",
            },
            "4.0T GTS 460hp": {
                "std_power": 460,
                "tuned_power": 620,
                "std_torque": 620,
                "tuned_torque": 870,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CVDD",
            },
            "Turbo S E-Hybrid 680hp": {
                "std_power": 680,
                "tuned_power": 800,
                "std_torque": 850,
                "tuned_torque": 1000,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "MCV.DA",
            },
        },
        "2020 ->": {
            "2.9T V6 4S 440hp": {
                "std_power": 440,
                "tuned_power": 510,
                "std_torque": 550,
                "tuned_torque": 780,
                "cc": 2894,
                "Type_ecu": "",
                "Engine_code": "MCS.ZA",
            },
            "2.9T V6 4 E-Hybrid 462hp": {
                "std_power": 462,
                "tuned_power": 600,
                "std_torque": 700,
                "tuned_torque": 800,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "MDG.PA",
            },
            "2.9T V6 4S E-Hybrid 560hp": {
                "std_power": 560,
                "tuned_power": 630,
                "std_torque": 750,
                "tuned_torque": 820,
                "cc": 2894,
                "Type_ecu": "Bosch MG1CS002",
                "Engine_code": "",
            },
            "2.9T V6 330hp": {
                "std_power": 330,
                "tuned_power": 380,
                "std_torque": 450,
                "tuned_torque": 550,
                "cc": 2894,
                "Type_ecu": "",
                "Engine_code": "MCX.TA",
            },
            "4.0 Turbo S E-Hybrid 700hp": {
                "std_power": 700,
                "tuned_power": 750,
                "std_torque": 850,
                "tuned_torque": 1000,
                "cc": 3996,
                "Type_ecu": "",
                "Engine_code": "CVD",
            },
            "4.0 Turbo 630hp": {
                "std_power": 630,
                "tuned_power": 660,
                "std_torque": 820,
                "tuned_torque": 900,
                "cc": 3996,
                "Type_ecu": "",
                "Engine_code": "MCV.DA",
            },
            "4.0T GTS 480hp": {
                "std_power": 480,
                "tuned_power": 620,
                "std_torque": 620,
                "tuned_torque": 880,
                "cc": 3996,
                "Type_ecu": "Bosch MG1CS008",
                "Engine_code": "CVDD",
            },
        },
    },
},
"Renault": {
    "Alaskan": {
        "2017 ->": {
            "2.3 dCi 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 403,
                "tuned_torque": 460,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "M9T",
            },
            "2.3 dCi 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "M9T",
            },
        },
    },
    "Arkana": {
        "2021 -> ...": {
            "1.3 TCe 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.4 TCe 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Austral": {
        "2022 -> ...": {
            "1.2 TCe E-Tech Hybrid 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 TCe E-Tech Hybrid 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 TCe 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Avantime": {
        "2001 - 2003": {
            "2.2 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 375,
                "cc": 2188,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "G9T",
            },
            "2.0 Turbo 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "F4RT",
            },
        },
    },
    "Captur": {
        "2013 - 2018": {
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 220,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "K9K 636",
            },
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Siemens/Continental SID310",
                "Engine_code": "K9K 646",
            },
            "0.9 TCE 90hp": {
                "std_power": 90,
                "tuned_power": 107,
                "std_torque": 135,
                "tuned_torque": 175,
                "cc": 898,
                "Type_ecu": "Valeo V50",
                "Engine_code": "H4B",
            },
            "1.2 TCE 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1196,
                "Type_ecu": "Siemens/Continental EMS3150 & Siemens/Continental EMS3155 & Getrag DC4",
                "Engine_code": "H5F",
            },
            "1.3 TCe S 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2019 ->": {
            "1.3 TCe 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 155hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 BlueDCi 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "K9K 872",
            },
            "1.5 BlueDCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "K9K U8",
            },
            "1.0 TCe 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 155hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Clio": {
        "2 - 2001 - 2005": {
            "1.5 DCi 65hp": {
                "std_power": 65,
                "tuned_power": 85,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 85hp": {
                "std_power": 85,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID304",
                "Engine_code": "K9K",
            },
            "1.5 DCi 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 82hp": {
                "std_power": 82,
                "tuned_power": 105,
                "std_torque": 185,
                "tuned_torque": 235,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.9 DTi 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "F9Q",
            },
            "1.2i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 105,
                "tuned_torque": 120,
                "cc": 1149,
                "Type_ecu": "Marelli IAW5NR & Siemens/Continental SIRIUS 32",
                "Engine_code": "D4F",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental SIRIUS 32 & Siemens/Continental SIRIUS 34",
                "Engine_code": "K4J710",
            },
            "3.0i V6 230hp": {
                "std_power": 230,
                "tuned_power": 245,
                "std_torque": 306,
                "tuned_torque": 325,
                "cc": 2946,
                "Type_ecu": "Bosch ME7.4.6",
                "Engine_code": "L7X",
            },
            "3.0i V6 255hp": {
                "std_power": 255,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 320,
                "cc": 2946,
                "Type_ecu": "",
                "Engine_code": "L7X",
            },
            "RS 2.0i 16v 172hp": {
                "std_power": 172,
                "tuned_power": 185,
                "std_torque": 204,
                "tuned_torque": 218,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "F4R 736",
            },
            "RS 2.0i 16v 182hp": {
                "std_power": 182,
                "tuned_power": 190,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIRIUS 34",
                "Engine_code": "F4R 738",
            },
        },
        "3 - 2005 - 2012": {
            "1.5 DCi 86hp": {
                "std_power": 86,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2 & Delphi DCM3.4 & Siemens/Continental SID304",
                "Engine_code": "K9K",
            },
            "1.5 DCi 106hp": {
                "std_power": 106,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301 & Siemens/Continental SID305",
                "Engine_code": "K9K",
            },
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "",
                "Engine_code": "K9K",
            },
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.2 & Delphi DCM3.4 & Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 180,
                "tuned_torque": 240,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.2 TCE 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 200,
                "cc": 1149,
                "Type_ecu": "Siemens/Continental SIM32",
                "Engine_code": "TCE GT",
            },
            "1.2i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 105,
                "tuned_torque": 117,
                "cc": 1149,
                "Type_ecu": "Siemens/Continental SIM32 & Siemens/Continental EMS3110 & Siemens/Continental EMS3132",
                "Engine_code": "D4F",
            },
            "1.4i 16v 98hp": {
                "std_power": 98,
                "tuned_power": 107,
                "std_torque": 127,
                "tuned_torque": 144,
                "cc": 1390,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "K4J",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "K4M",
            },
            "GT 1.6i 16v 128hp": {
                "std_power": 128,
                "tuned_power": 140,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental SIM32",
                "Engine_code": "K4M",
            },
            "RS 2.0i 16v 197hp": {
                "std_power": 197,
                "tuned_power": 211,
                "std_torque": 215,
                "tuned_torque": 230,
                "cc": 1998,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4R 830",
            },
            "RS 2.0i 16v 201hp": {
                "std_power": 201,
                "tuned_power": 215,
                "std_torque": 215,
                "tuned_torque": 230,
                "cc": 1998,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4R 832",
            },
        },
        "4 - (ph1) - 2012 - 2016": {
            "1.5 DCi 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C84 & Bosch EDC17C42",
                "Engine_code": "K9K 612",
            },
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 220,
                "tuned_torque": 245,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "K9K 608",
            },
            "0.9 TCE 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 135,
                "tuned_torque": 175,
                "cc": 898,
                "Type_ecu": "Valeo V50",
                "Engine_code": "H4BT",
            },
            "1.2 TCE 90hp": {
                "std_power": 90,
                "tuned_power": 105,
                "std_torque": 135,
                "tuned_torque": 160,
                "cc": 1149,
                "Type_ecu": "Valeo V50",
                "Engine_code": "H4B400",
            },
            "1.2 TCE 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1149,
                "Type_ecu": "Siemens/Continental EMS3150 & Siemens/Continental EMS3110 & Siemens/Continental EMS3155",
                "Engine_code": "H5F G4",
            },
            "1.2i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 105,
                "tuned_torque": 117,
                "cc": 1149,
                "Type_ecu": "Siemens/Continental EMS3110 & Siemens/Continental V46.1 & Valeo V42 & Siemens/Continental EMS3125",
                "Engine_code": "D4F 744",
            },
            "RS 1.6T 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1618,
                "Type_ecu": "Siemens/Continental EMS3150 & Getrag DC4",
                "Engine_code": "M5M A4",
            },
            "RS Trophy 1.6T 220hp": {
                "std_power": 220,
                "tuned_power": 245,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1618,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "M5M A4",
            },
        },
        "4 - (ph2) - 2016 - 2018": {
            "0.9 TCE 75hp": {
                "std_power": 75,
                "tuned_power": 110,
                "std_torque": 120,
                "tuned_torque": 175,
                "cc": 898,
                "Type_ecu": "Valeo V50",
                "Engine_code": "H4B 408",
            },
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "K9K",
            },
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 220,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "K9K E6",
            },
            "1.5 DCi 75hp": {
                "std_power": 75,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "K9K 612",
            },
            "0.9 TCE 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 140,
                "tuned_torque": 175,
                "cc": 898,
                "Type_ecu": "Valeo V42 & Valeo V50 & Siemens/Continental EMS3125",
                "Engine_code": "H4BT",
            },
            "1.2 TCE 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 235,
                "cc": 1149,
                "Type_ecu": "Siemens/Continental EMS3155 & Siemens/Continental EMS3125",
                "Engine_code": "H5F G4",
            },
            "RS 1.6T 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1618,
                "Type_ecu": "Siemens/Continental EMS3155 & Getrag DC4 & Siemens/Continental EMS3150",
                "Engine_code": "M5M A4",
            },
            "RS Trophy 1.6T 220hp": {
                "std_power": 220,
                "tuned_power": 245,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1618,
                "Type_ecu": "Siemens/Continental EMS3155 & Getrag DC4",
                "Engine_code": "M5M A4",
            },
        },
        "5 - 2019 ->": {
            "1.5 BlueDCi 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 220,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "RJABD2AD6TA12",
            },
            "1.5 BlueDCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "K9K 872",
            },
            "1.0 TCe GPF 90hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 TCe 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Espace": {
        "Mk4 -> 2014": {
            "1.9 DCi 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 270,
                "tuned_torque": 340,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 155,
                "std_torque": 270,
                "tuned_torque": 340,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q",
            },
            "1.9 DTi 98hp": {
                "std_power": 98,
                "tuned_power": 117,
                "std_torque": 200,
                "tuned_torque": 245,
                "cc": 1870,
                "Type_ecu": "",
                "Engine_code": "F9Q",
            },
            "2.0 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC16CP33",
                "Engine_code": "M9R",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33 & Bosch EDC16C36 & Bosch EDC17CP58",
                "Engine_code": "M9R",
            },
            "2.0 DCi 175hp": {
                "std_power": 175,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC17CP58 & Bosch EDC16CP33 & Bosch EDC17C42",
                "Engine_code": "M9R",
            },
            "2.2 DCi 16v 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2188,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC15C13",
                "Engine_code": "G9T",
            },
            "2.2 DCi 16v 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2188,
                "Type_ecu": "Bosch EDC15C3",
                "Engine_code": "G9T",
            },
            "3.0 DCi 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2958,
                "Type_ecu": "Denso PSOP 4Mbit",
                "Engine_code": "6DE1",
            },
            "2.0 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 250,
                "tuned_torque": 305,
                "cc": 1998,
                "Type_ecu": "Sagem 3000 & Sagem 2000",
                "Engine_code": "F4R 797",
            },
        },
        "Mk5 (II) - 2020 ->": {
            "2.0 Blue dCi 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "M9R",
            },
            "2.0 Blue dCi 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "M9R",
            },
            "1.8 TCe 225hp": {
                "std_power": 225,
                "tuned_power": 260,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1798,
                "Type_ecu": "",
                "Engine_code": "M5P 403",
            },
        },
        "Mk5 - 2015 - 2019": {
            "1.6 DCi Bi-Turbo 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M E4",
            },
            "1.6 TCE 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1618,
                "Type_ecu": "Hitachi BED434",
                "Engine_code": "F3R750",
            },
            "1.8 TCe 225hp": {
                "std_power": 225,
                "tuned_power": 250,
                "std_torque": 300,
                "tuned_torque": 385,
                "cc": 1798,
                "Type_ecu": "Hitachi BED434 & Hitachi BED501",
                "Engine_code": "M5P401",
            },
        },
    },
    "Express": {
        "2021 -> ...": {
            "1.5 dCi 75hp": {
                "std_power": 75,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 320,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "1.5 dCi 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 220,
                "tuned_torque": 320,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "1.3 TCe 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Fluence": {
        "2011 ->": {
            "1.5 DCi 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305",
                "Engine_code": "K9K",
            },
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 152,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Valeo V40",
                "Engine_code": "K4M",
            },
            "2.0 Turbo GT 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental EMS3110",
                "Engine_code": "F4RT",
            },
        },
    },
    "Kadjar": {
        "2015 - 2018": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C84 & Siemens/Continental SID310",
                "Engine_code": "K9K",
            },
            "1.6 DCi Bi-Turbo 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 1618,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M E4",
            },
            "1.2 TCE 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 240,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "H5F",
            },
            "1.6 TCe 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1618,
                "Type_ecu": "Hitachi BED301-000 & Hitachi BED434",
                "Engine_code": "",
            },
        },
        "2019 ->": {
            "1.5 DCi 116hp": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "K9K U8",
            },
            "1.3 TCe 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.7 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N",
            },
        },
    },
    "Kangoo": {
        "2001 - 2013": {
            "1.5 DCi 68hp": {
                "std_power": 68,
                "tuned_power": 85,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301 & Delphi DCM1.2 & Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 85hp": {
                "std_power": 85,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301 & Delphi DCM3.2 & Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301 & Siemens/Continental SID305",
                "Engine_code": "K9K",
            },
            "1.5 DCi 65hp": {
                "std_power": 65,
                "tuned_power": 85,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2 & Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 61hp": {
                "std_power": 61,
                "tuned_power": 81,
                "std_torque": 130,
                "tuned_torque": 170,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2 & Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 82hp": {
                "std_power": 82,
                "tuned_power": 102,
                "std_torque": 185,
                "tuned_torque": 225,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 185,
                "tuned_torque": 225,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 75hp": {
                "std_power": 75,
                "tuned_power": 110,
                "std_torque": 185,
                "tuned_torque": 245,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C84 & Bosch EDC17C42 & Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 245,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.9 DCi 80hp": {
                "std_power": 80,
                "tuned_power": 115,
                "std_torque": 180,
                "tuned_torque": 235,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q",
            },
            "1.9 DTI 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "F9Q",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Sagem 3000 & Siemens/Continental SIRIUS 34 & Siemens/Continental EMS3134 & Siemens/Continental EMS3110",
                "Engine_code": "K4M",
            },
        },
        "2013 - 2020": {
            "1.5 BlueDCi 80hp": {
                "std_power": 80,
                "tuned_power": 140,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "1.5 BlueDCi 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "K9K",
            },
            "1.5 BlueDCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "K9K",
            },
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 220,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Delphi DCM3.4 & Bosch MD1CS006",
                "Engine_code": "K9K",
            },
            "1.5 DCi 75hp": {
                "std_power": 75,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Bosch MD1CS006",
                "Engine_code": "K9K",
            },
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310 & Siemens/Continental SID305",
                "Engine_code": "K9K",
            },
            "1.2 TCE 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3110 & Siemens/Continental EMS3155",
                "Engine_code": "H5F",
            },
        },
        "2021 -> ...": {
            "1.5 dCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "1.5 dCi 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 220,
                "tuned_torque": 320,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006 & Bosch MD1CS016",
                "Engine_code": "",
            },
            "1.5 dCi 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "1.3 TCe 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Koleos": {
        "2008 - 2017": {
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC16CP33",
                "Engine_code": "M9R",
            },
            "2.0 DCi 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "M9R",
            },
        },
        "2017 - 2018": {
            "1.6 dCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1600,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "M9R 830",
            },
            "1.7 Blue dCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N A4",
            },
            "2.0 dCi 175hp": {
                "std_power": 175,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C84 & Bosch MD1CS006",
                "Engine_code": "M9R A8",
            },
        },
        "2019 ->": {
            "1.7 Blue dCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N A4",
            },
            "2.0 Blue dCi 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1995,
                "Type_ecu": "Bosch MD1CS006 & Bosch MD1CS016",
                "Engine_code": "M9R 730",
            },
        },
    },
    "Laguna": {
        "II - 2001 - 2007": {
            "1.9 DCi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 270,
                "tuned_torque": 345,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 230,
                "tuned_torque": 290,
                "cc": 1870,
                "Type_ecu": "",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 155,
                "std_torque": 270,
                "tuned_torque": 345,
                "cc": 1870,
                "Type_ecu": "",
                "Engine_code": "F9Q",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.0 DCi 175hp": {
                "std_power": 175,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.2 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 2188,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC15C3",
                "Engine_code": "G9T",
            },
            "2.0 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1998,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4R",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "K4M D7",
            },
            "2.0 Turbo 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1998,
                "Type_ecu": "Sagem S2000RPM & Sagem 3000",
                "Engine_code": "F4R",
            },
            "2.0i 16v 135hp": {
                "std_power": 135,
                "tuned_power": 145,
                "std_torque": 191,
                "tuned_torque": 206,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIRIUS 32 & Siemens/Continental SIRIUS 34",
                "Engine_code": "F4R 67",
            },
        },
        "III - 2007 ->": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301 & Siemens/Continental SID305",
                "Engine_code": "K9K",
            },
            "2.0 DCi GT 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Sagem 3000 & Bosch EDC17CP58 & Bosch EDC16C33",
                "Engine_code": "M9R",
            },
            "2.0 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP58 & Bosch EDC16C33",
                "Engine_code": "M9R",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP58 & Bosch EDC16CP33 & Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.0 DCi 175hp": {
                "std_power": 175,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC17C58",
                "Engine_code": "M9R",
            },
            "3.0 DCi 235hp": {
                "std_power": 235,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2958,
                "Type_ecu": "Bosch EDC16CP42",
                "Engine_code": "M9T",
            },
            "1.4 TCE 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1397,
                "Type_ecu": "",
                "Engine_code": "H4J A 700",
            },
            "2.0 Turbo GT 205hp": {
                "std_power": 205,
                "tuned_power": 235,
                "std_torque": 300,
                "tuned_torque": 375,
                "cc": 1997,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4R",
            },
            "2.0 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1997,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4R",
            },
            "2.0i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 191,
                "tuned_torque": 206,
                "cc": 1997,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "M4R",
            },
        },
    },
    "Laguna Coupé": {
        "All": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301",
                "Engine_code": "M9R",
            },
            "2.0 DCi GT 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC16CP33",
                "Engine_code": "M9R",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP58 & Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.0 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP58 & Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.0 DCi 175hp": {
                "std_power": 175,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 445,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "M9R",
            },
            "3.0 DCi 235hp": {
                "std_power": 235,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2958,
                "Type_ecu": "Bosch EDC16CP42",
                "Engine_code": "M9T",
            },
            "2.0 Turbo GT 205hp": {
                "std_power": 205,
                "tuned_power": 235,
                "std_torque": 300,
                "tuned_torque": 370,
                "cc": 1997,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4R",
            },
            "2.0 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1997,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4R",
            },
        },
    },
    "Latitude": {
        "2011 ->": {
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33",
                "Engine_code": "M9R",
            },
            "2.0 DCi 175hp": {
                "std_power": 175,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33",
                "Engine_code": "M9R",
            },
            "3.0 DCi 235hp": {
                "std_power": 235,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 2958,
                "Type_ecu": "Bosch EDC16CP42",
                "Engine_code": "M9T",
            },
            "2.0 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "F4R",
            },
        },
    },
    "Mascott": {
        "All": {
            "2.5 DCi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 2464,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "",
            },
            "2.8 DCi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 2800,
                "Type_ecu": "Bosch MS6.3",
                "Engine_code": "",
            },
            "2.8 DCi 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 2800,
                "Type_ecu": "Bosch EDC16C3 & Bosch MS6.3",
                "Engine_code": "",
            },
            "2.8 DCi 145hp": {
                "std_power": 145,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2800,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.0 DCi 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2953,
                "Type_ecu": "Bosch EDC16C41",
                "Engine_code": "",
            },
            "3.0 DCi 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2953,
                "Type_ecu": "Bosch EDC16C3 & Bosch EDC16C41 & Bosch EDC17C45",
                "Engine_code": "",
            },
            "3.0 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 2953,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "",
            },
        },
    },
    "Master": {
        "Mk2 - 1998 - 2010": {
            "1.9 DCi 82hp": {
                "std_power": 82,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q",
            },
            "2.0 DCi 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "",
            },
            "2.2 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 2188,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "G9T",
            },
            "2.2 DCi 114hp": {
                "std_power": 114,
                "tuned_power": 145,
                "std_torque": 290,
                "tuned_torque": 355,
                "cc": 2188,
                "Type_ecu": "Bosch EDC16C3 & Bosch EDC15C3",
                "Engine_code": "G9U",
            },
            "2.5 DCi 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 290,
                "tuned_torque": 350,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC15C3",
                "Engine_code": "G9U 650",
            },
            "2.5 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC16CP33",
                "Engine_code": "G9U 632",
            },
            "2.5 DCi 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC15 & Bosch EDC16C33 & Bosch EDC16C3",
                "Engine_code": "G9U 650",
            },
            "3.0 DCi 136hp": {
                "std_power": 136,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2953,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "ZD30",
            },
            "3.0 DCi 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2953,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "ZD30",
            },
        },
        "Mk3 - 2010 - 2014": {
            "2.3 DCI 100hp": {
                "std_power": 100,
                "tuned_power": 155,
                "std_torque": 285,
                "tuned_torque": 430,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42",
                "Engine_code": "M9T",
            },
            "2.3 DCI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 310,
                "tuned_torque": 430,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42 & Siemens/Continental SID310",
                "Engine_code": "M9T",
            },
            "2.3 DCI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C42 & Siemens/Continental SID309 & Bosch EDC17C11",
                "Engine_code": "M9T",
            },
            "2.3 DCI 146hp": {
                "std_power": 146,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Siemens/Continental SID310",
                "Engine_code": "M9T",
            },
        },
        "Mk4 - 2014 - 2016": {
            "2.3 DCI BiTurbo 135hp": {
                "std_power": 135,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID309 & Siemens/Continental SID310",
                "Engine_code": "M9T",
            },
            "2.3 DCI BiTurbo 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID309",
                "Engine_code": "M9T",
            },
            "2.3 DCI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 285,
                "tuned_torque": 345,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42",
                "Engine_code": "M9T",
            },
            "2.3 DCI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42",
                "Engine_code": "M9T",
            },
            "2.3 DCI 145hp": {
                "std_power": 145,
                "tuned_power": 175,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42",
                "Engine_code": "M9T",
            },
        },
        "Mk4 - 2016 - 2019": {
            "2.3 DCI (Euro 6) 110hp": {
                "std_power": 110,
                "tuned_power": 160,
                "std_torque": 285,
                "tuned_torque": 390,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310 & Bosch EDC17C42",
                "Engine_code": "M9T",
            },
            "2.3 DCI (Euro 6) 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 340,
                "tuned_torque": 390,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "M9T",
            },
            "2.3 DCi Bi-turbo (Euro 6) 145hp": {
                "std_power": 145,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310 & Siemens/Continental SID309",
                "Engine_code": "M9T",
            },
            "2.3 DCi Bi-turbo (Euro 6) 165hp": {
                "std_power": 165,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "M9T",
            },
            "2.3 DCi Bi-turbo (Euro 6) 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "M9T",
            },
        },
        "Mk5 - 2019 ->": {
            "2.3 DCi 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.3 DCi 135hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.3 DCi 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.3 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 180,
                "std_torque": 330,
                "tuned_torque": 420,
                "cc": 0,
                "Type_ecu": "Bosch EDC17C42 & Bosch MD1",
                "Engine_code": "",
            },
            "2.3 DCi 145hp": {
                "std_power": 145,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 0,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "",
            },
            "2.3 DCi 165hp": {
                "std_power": 165,
                "tuned_power": 210,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 0,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "",
            },
        },
    },
    "Maxity": {
        "-> 2015": {
            "2.5 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 270,
                "tuned_torque": 340,
                "cc": 0,
                "Type_ecu": "Denso D3 & Denso SH7058",
                "Engine_code": "",
            },
            "2.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 160,
                "std_torque": 340,
                "tuned_torque": 340,
                "cc": 2488,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "",
            },
            "2.5 DCi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 270,
                "tuned_torque": 340,
                "cc": 0,
                "Type_ecu": "Denso D3",
                "Engine_code": "",
            },
            "3.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 0,
                "Type_ecu": "Denso D3 & Bosch EDC17C45 & Bosch EDC16C41",
                "Engine_code": "",
            },
        },
        "2016 ->": {
            "2.5 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 340,
                "cc": 2488,
                "Type_ecu": "Bosch EDC17C45",
                "Engine_code": "",
            },
            "3.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 0,
                "Type_ecu": "Bosch EDC17C45",
                "Engine_code": "",
            },
            "3.0 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 410,
                "cc": 0,
                "Type_ecu": "Bosch EDC17C45",
                "Engine_code": "",
            },
        },
    },
    "Megane": {
        "2 - 2002 - 2008": {
            "1.5 DCi 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 185,
                "tuned_torque": 235,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 85hp": {
                "std_power": 85,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2 & Siemens/Continental SID301",
                "Engine_code": "K9K",
            },
            "1.5 DCi 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301",
                "Engine_code": "K9K",
            },
            "1.9 DCi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 365,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC16CP33 & Bosch EDC16C3",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 102hp": {
                "std_power": 102,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "F9Q",
            },
            "1.9 DTI 100hp": {
                "std_power": 100,
                "tuned_power": 122,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1870,
                "Type_ecu": "",
                "Engine_code": "F9Q",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC16CP33",
                "Engine_code": "M9R",
            },
            "2.0 DCi 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC16CP33",
                "Engine_code": "M9R",
            },
            "1.4i 16v 98hp": {
                "std_power": 98,
                "tuned_power": 105,
                "std_torque": 127,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Sagem 3000 & Siemens/Continental SIRIUS 34",
                "Engine_code": "E7J",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "K7M",
            },
            "1.6i 16v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 152,
                "tuned_torque": 168,
                "cc": 1598,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "K7M",
            },
            "2.0 Turbo 165hp": {
                "std_power": 165,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1998,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4RT",
            },
            "2.0i 16v 136hp": {
                "std_power": 136,
                "tuned_power": 146,
                "std_torque": 191,
                "tuned_torque": 206,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIRIUS 34 & Sagem 3000",
                "Engine_code": "F4R",
            },
            "RS 2.0 Turbo R26R 230hp": {
                "std_power": 230,
                "tuned_power": 255,
                "std_torque": 310,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4R 27",
            },
            "RS 2.0 Turbo 225hp": {
                "std_power": 225,
                "tuned_power": 255,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4RT",
            },
        },
        "3 - (ph1) - 2008 - 2012": {
            "1.5 DCi 85hp": {
                "std_power": 85,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.5 DCi 106hp": {
                "std_power": 106,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Siemens/Continental SID304",
                "Engine_code": "K9K",
            },
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Siemens/Continental SID307 & Siemens/Continental SID306 & Siemens/Continental SID301",
                "Engine_code": "K9K",
            },
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "R9M",
            },
            "1.9 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 365,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "F9Q",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.0 DCi 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC17CP19",
                "Engine_code": "M9R",
            },
            "1.4 TCE 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1397,
                "Type_ecu": "Valeo V40",
                "Engine_code": "H4J",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Valeo V40",
                "Engine_code": "K4M",
            },
            "1.6i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Sagem 3000 & Valeo V40",
                "Engine_code": "K4M",
            },
            "2.0 TCE 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental EMS3110",
                "Engine_code": "F4RT",
            },
            "2.0i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 195,
                "tuned_torque": 215,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SIRIUS 32 & Siemens/Continental EMS3126 & Siemens/Continental EMS3110",
                "Engine_code": "M4R",
            },
            "GT 2.0T 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental EMS3110",
                "Engine_code": "F4RT",
            },
            "RS 2.0T 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental EMS3150 & Siemens/Continental EMS3110 & Siemens/Continental EMS3125",
                "Engine_code": "F4RT",
            },
            "RS 2.0T 265hp": {
                "std_power": 265,
                "tuned_power": 300,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental EMS3150 & Siemens/Continental EMS3110",
                "Engine_code": "F4RT",
            },
        },
        "3 - (ph2) - 2012 - 2013": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Siemens/Continental SID307",
                "Engine_code": "K9K",
            },
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "R9M",
            },
            "1.2 TCE 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3150",
                "Engine_code": "H5FT",
            },
            "1.4 TCE 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1397,
                "Type_ecu": "Valeo V40",
                "Engine_code": "H4J",
            },
            "1.6i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "K4M",
            },
            "2.0i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 195,
                "tuned_torque": 215,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "M4R",
            },
            "GT 2.0 TCE 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental EMS3110",
                "Engine_code": "F4RT",
            },
            "GT 2.0 TCE 190hp": {
                "std_power": 190,
                "tuned_power": 210,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4RT",
            },
            "GT 2.0T 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Sagem 3000 & Siemens/Continental EMS3110",
                "Engine_code": "F4RT",
            },
            "RS 2.0T 265hp": {
                "std_power": 265,
                "tuned_power": 290,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental EMS3110",
                "Engine_code": "F4R874",
            },
        },
        "3 - (ph3) - 2013 - 2015": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID305 & Siemens/Continental SID310 & Siemens/Continental SID807",
                "Engine_code": "K9K",
            },
            "1.5 DCi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4 & Siemens/Continental SID305 & Siemens/Continental SID310",
                "Engine_code": "K9K",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "2.0 DCi 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17CP19",
                "Engine_code": "M9R",
            },
            "1.2 TCE 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 240,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3150 & Siemens/Continental EMS3155",
                "Engine_code": "H5FT",
            },
            "1.2 TCE 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3150 & Siemens/Continental EMS3155",
                "Engine_code": "H5FT",
            },
            "2.0 Turbo 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental EMS3110",
                "Engine_code": "F4RT",
            },
            "RS 2.0T 265hp": {
                "std_power": 265,
                "tuned_power": 290,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental EMS3110",
                "Engine_code": "F4R874",
            },
            "RS 2.0T 275hp": {
                "std_power": 275,
                "tuned_power": 300,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental EMS3110 & Siemens/Continental EMS3125",
                "Engine_code": "F4R874",
            },
        },
        "4 - (ph1) - 2016 - 2020": {
            "1.5 dCi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310",
                "Engine_code": "K9K",
            },
            "1.5 dCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 220,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID310",
                "Engine_code": "K9K",
            },
            "1.5 dCi 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS003 & Bosch MD1CS006 & Bosch MD1CS016",
                "Engine_code": "K9K",
            },
            "1.5 dCi 95hp": {
                "std_power": 95,
                "tuned_power": 110,
                "std_torque": 240,
                "tuned_torque": 260,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310 & Bosch MD1CS006",
                "Engine_code": "K9K",
            },
            "1.6 dCi 130hp": {
                "std_power": 130,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "1.6 dCi 165hp": {
                "std_power": 165,
                "tuned_power": 180,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "1.7 Blue dCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N",
            },
            "1.6 TCE 165hp": {
                "std_power": 165,
                "tuned_power": 225,
                "std_torque": 240,
                "tuned_torque": 330,
                "cc": 1618,
                "Type_ecu": "Hitachi BED434",
                "Engine_code": "",
            },
            "1.2 TCE 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3150 & Siemens/Continental EMS3155",
                "Engine_code": "H5FT",
            },
            "1.2 TCE 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 240,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "H5FT",
            },
            "1.3 TCe 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 115hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 TCE GT 205hp": {
                "std_power": 205,
                "tuned_power": 225,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1618,
                "Type_ecu": "Hitachi BED434",
                "Engine_code": "M5MT",
            },
            "RS 1.8 Turbo 280hp": {
                "std_power": 280,
                "tuned_power": 310,
                "std_torque": 390,
                "tuned_torque": 440,
                "cc": 1798,
                "Type_ecu": "Hitachi BED501",
                "Engine_code": "B9M6310",
            },
            "RS Trophy 1.8 Turbo 300hp": {
                "std_power": 300,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1798,
                "Type_ecu": "Hitachi RH850 & Hitachi BED501 & Hitachi BED506",
                "Engine_code": "B9M6310",
            },
        },
        "4 - (ph2) - 2021 ->": {
            "1.5 dCi 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 1461,
                "Type_ecu": "Bosch MD1CS006 & Bosch MD1CS016",
                "Engine_code": "K9K U8",
            },
            "RS Trophy 1.8 Turbo 300hp": {
                "std_power": 300,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1798,
                "Type_ecu": "Hitachi BED506",
                "Engine_code": "B9M6310",
            },
            "1.3 TCe 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCe 115hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Modus": {
        "All": {
            "1.5 DCi 68hp": {
                "std_power": 68,
                "tuned_power": 85,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301",
                "Engine_code": "K9K",
            },
            "1.5 DCi 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 185,
                "tuned_torque": 240,
                "cc": 1461,
                "Type_ecu": "",
                "Engine_code": "K9K",
            },
            "1.5 DCi 86hp": {
                "std_power": 86,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301",
                "Engine_code": "K9K",
            },
            "1.5 DCi 106hp": {
                "std_power": 106,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301",
                "Engine_code": "K9K",
            },
            "1.5 DCi 65hp": {
                "std_power": 65,
                "tuned_power": 85,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.5 DCi 75hp": {
                "std_power": 75,
                "tuned_power": 110,
                "std_torque": 180,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.2 TCE 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 145,
                "tuned_torque": 200,
                "cc": 1149,
                "Type_ecu": "Siemens/Continental SIM32",
                "Engine_code": "D4FH7",
            },
            "1.2i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 105,
                "tuned_torque": 120,
                "cc": 1149,
                "Type_ecu": "Siemens/Continental SIM32 & Siemens/Continental EMS3110",
                "Engine_code": "D4FH7",
            },
            "1.4i 16v 98hp": {
                "std_power": 98,
                "tuned_power": 105,
                "std_torque": 127,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "K4M",
            },
        },
    },
    "Scenic": {
        "I - 1999 - 2003": {
            "1.9 DCi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC15C3",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1870,
                "Type_ecu": "",
                "Engine_code": "F9Q",
            },
            "1.9 DTI 102hp": {
                "std_power": 102,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 280,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C3",
                "Engine_code": "F9Q",
            },
            "1.9 DTI 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "F9Q",
            },
        },
        "II - 2003 - 2006": {
            "1.5 DCi 80hp": {
                "std_power": 80,
                "tuned_power": 107,
                "std_torque": 185,
                "tuned_torque": 225,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 100hp": {
                "std_power": 100,
                "tuned_power": 124,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DCi 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301",
                "Engine_code": "K9K",
            },
            "1.5 DCi 85hp": {
                "std_power": 85,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.9 DCi 120hp": {
                "std_power": 120,
                "tuned_power": 155,
                "std_torque": 270,
                "tuned_torque": 340,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 370,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC16C3",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 163,
                "std_torque": 270,
                "tuned_torque": 340,
                "cc": 1870,
                "Type_ecu": "",
                "Engine_code": "F9Q",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "1.4i 16v 98hp": {
                "std_power": 98,
                "tuned_power": 108,
                "std_torque": 127,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "K4J",
            },
            "1.6i 16v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 152,
                "tuned_torque": 168,
                "cc": 1598,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "K4M",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "K4M",
            },
            "2.0 Turbo 165hp": {
                "std_power": 165,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1998,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4R 37",
            },
            "2.0i 16v 136hp": {
                "std_power": 136,
                "tuned_power": 146,
                "std_torque": 191,
                "tuned_torque": 206,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "F4R",
            },
        },
        "II - 2006 - 2009": {
            "1.5 DCi 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301",
                "Engine_code": "K9K",
            },
            "1.5 DCi 85hp": {
                "std_power": 85,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2 & Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.9 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 370,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "F9Q",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "1.4i 16v 98hp": {
                "std_power": 98,
                "tuned_power": 108,
                "std_torque": 127,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "K4J",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 152,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "K4M",
            },
            "2.0 Turbo 165hp": {
                "std_power": 165,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1998,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "F4R 37",
            },
            "2.0i 16v 136hp": {
                "std_power": 136,
                "tuned_power": 146,
                "std_torque": 191,
                "tuned_torque": 206,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIRIUS 32 & Sagem 3000",
                "Engine_code": "F4R",
            },
        },
        "III - 2009 - 2012": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID307 & Siemens/Continental SID305 & Siemens/Continental SID301",
                "Engine_code": "K9K",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Siemens/Continental SID307",
                "Engine_code": "R9M",
            },
            "1.9 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 370,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "F9Q",
            },
            "2.0 DCi 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC17CP19",
                "Engine_code": "M9R",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC17CP19",
                "Engine_code": "M9R",
            },
            "1.5 DCi 105hp": {
                "std_power": 105,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID301 & Siemens/Continental SID305",
                "Engine_code": "K9K",
            },
            "1.4 TCE 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1397,
                "Type_ecu": "Valeo V40 & Sagem 3000",
                "Engine_code": "H4J700",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 152,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Sagem 3000",
                "Engine_code": "K4M",
            },
            "2.0i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 195,
                "tuned_torque": 210,
                "cc": 1997,
                "Type_ecu": "Sagem 3000 & Siemens/Continental EMS3110",
                "Engine_code": "F4R",
            },
        },
        "III - 2012 - 2016": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID305 & Siemens/Continental SID307 & Siemens/Continental SID310 & Siemens/Continental SID306",
                "Engine_code": "K9K",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "2.0 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "M9R",
            },
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC17CP19",
                "Engine_code": "M9R",
            },
            "2.0 DCi 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "1.2 TCE 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3110",
                "Engine_code": "H5F400",
            },
            "1.2 TCE 130hp": {
                "std_power": 130,
                "tuned_power": 140,
                "std_torque": 205,
                "tuned_torque": 240,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3125 & Siemens/Continental EMS3150",
                "Engine_code": "H5F",
            },
            "1.2 TCE 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3155 & Siemens/Continental EMS3150",
                "Engine_code": "H5F",
            },
            "1.4 TCE 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1397,
                "Type_ecu": "Valeo V40",
                "Engine_code": "H4J700",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 152,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Sagem 3000 & Valeo V40",
                "Engine_code": "K4M",
            },
        },
        "IV - 2016 - 2018": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "K9K G6",
            },
            "1.5 DCi 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "K9K G6",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 155,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M E4",
            },
            "1.6 DCi 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M E4",
            },
            "1.2 TCE 130hp": {
                "std_power": 130,
                "tuned_power": 140,
                "std_torque": 205,
                "tuned_torque": 240,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3155",
                "Engine_code": "H5F F4",
            },
            "1.2 TCE 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental EMS3150",
                "Engine_code": "H5F F4",
            },
        },
        "IV - 2018 ->": {
            "1.5 DCI 110hp - Hybrid Assist": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "K9K 500",
            },
            "1.7 Blue dCi 120hp": {
                "std_power": 120,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N",
            },
            "1.7 Blue dCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N",
            },
            "1.3 TCE 115hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCE 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCE 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Talisman": {
        "2016 - 2019": {
            "1.5 DCi 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1461,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "K9K",
            },
            "1.6 DCi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "M9R",
            },
            "1.6 DCi 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "M9R",
            },
            "1.7 Blue dCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N",
            },
            "1.7 Blue dCi 120hp": {
                "std_power": 120,
                "tuned_power": 180,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "2.0 Blue dCi 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "M9R",
            },
            "2.0 Blue dCi 160hp": {
                "std_power": 160,
                "tuned_power": 240,
                "std_torque": 360,
                "tuned_torque": 480,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "M9R",
            },
            "1.6 TCe 150hp": {
                "std_power": 150,
                "tuned_power": 225,
                "std_torque": 220,
                "tuned_torque": 350,
                "cc": 1618,
                "Type_ecu": "Siemens/Continental EMS3155 & Hitachi BED434",
                "Engine_code": "M5M",
            },
            "1.6 TCe 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 260,
                "tuned_torque": 350,
                "cc": 1618,
                "Type_ecu": "Siemens/Continental EMS3155 & Hitachi BED434",
                "Engine_code": "M5M",
            },
            "1.8 TCe 225hp": {
                "std_power": 225,
                "tuned_power": 260,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1798,
                "Type_ecu": "Hitachi BED501",
                "Engine_code": "M5P",
            },
        },
        "2020 ->": {
            "1.7 BlueDCI 120hp": {
                "std_power": 120,
                "tuned_power": 170,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N",
            },
            "1.7 BlueDCI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 1749,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "R9N",
            },
            "2.0 BlueDCI 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "M9R",
            },
            "2.0 BlueDCI 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "M9R",
            },
            "1.3 TCE 160hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.3 TCE 140hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.8 TCE 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Trafic": {
        "2001 - 2005": {
            "1.9 DCi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC15C3",
                "Engine_code": "F9Q",
            },
            "1.9 DCi 82hp": {
                "std_power": 82,
                "tuned_power": 110,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC15C3",
                "Engine_code": "F9Q",
            },
            "2.5 DCi 135hp": {
                "std_power": 135,
                "tuned_power": 165,
                "std_torque": 310,
                "tuned_torque": 360,
                "cc": 2464,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC16C3 & Bosch EDC16C36 & Bosch EDC15C3",
                "Engine_code": "G9U",
            },
        },
        "2006 - 2014": {
            "2.0 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 145,
                "std_torque": 240,
                "tuned_torque": 350,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33 & Bosch EDC16C36 & Bosch EDC17C11 & Bosch EDC17C42",
                "Engine_code": "M9R",
            },
            "2.0 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 290,
                "tuned_torque": 350,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33 & Bosch EDC17C42 & Bosch EDC16C36 & Bosch EDC17C11",
                "Engine_code": "M9R",
            },
            "2.5 DCi 145hp": {
                "std_power": 145,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 405,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U",
            },
            "2.5 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U",
            },
            "2.5 DCi 114hp": {
                "std_power": 114,
                "tuned_power": 149,
                "std_torque": 290,
                "tuned_torque": 370,
                "cc": 2500,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC15C3",
                "Engine_code": "G9U",
            },
        },
        "2014 - 2016": {
            "1.6 DCi BiTurbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M 450",
            },
            "1.6 DCi BiTurbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "1.6 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "1.6 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "R9M",
            },
        },
        "2016 - 2019": {
            "1.6 DCi BiTurbo 125hp": {
                "std_power": 125,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "1.6 DCi BiTurbo 145hp": {
                "std_power": 145,
                "tuned_power": 165,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M 450",
            },
            "1.6 DCi 95hp": {
                "std_power": 95,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M",
            },
            "1.6 DCi 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M",
            },
        },
        "2019 - 2021": {
            "1.6 DCi 95hp": {
                "std_power": 95,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "",
            },
            "2.0 DCi 120hp": {
                "std_power": 120,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "2.0 DCi 145hp": {
                "std_power": 145,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
            "2.0 DCi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 1997,
                "Type_ecu": "Bosch MD1CS006",
                "Engine_code": "",
            },
        },
        "2022 -> ...": {
            "2.0 dCi 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 dCi 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 dCi 170hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 dCi 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Twingo": {
        "II - 2007 - 2014": {
            "1.5 DCi 90hp": {
                "std_power": 90,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.5 DCi 86hp": {
                "std_power": 86,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4 & Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 65hp": {
                "std_power": 65,
                "tuned_power": 85,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5 DCi 75hp": {
                "std_power": 75,
                "tuned_power": 105,
                "std_torque": 180,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM3.4",
                "Engine_code": "K9K",
            },
            "1.2 TCE 100hp": {
                "std_power": 100,
                "tuned_power": 122,
                "std_torque": 145,
                "tuned_torque": 195,
                "cc": 1149,
                "Type_ecu": "Siemens/Continental SIM32",
                "Engine_code": "D4F",
            },
            "1.2i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 105,
                "tuned_torque": 125,
                "cc": 1149,
                "Type_ecu": "Siemens/Continental SIM32",
                "Engine_code": "D4FA",
            },
            "1.6i RS 133hp": {
                "std_power": 133,
                "tuned_power": 145,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental SIM32",
                "Engine_code": "K4M",
            },
        },
        "III - 2014 - 2019": {
            "0.9 TCE GT 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 170,
                "tuned_torque": 190,
                "cc": 989,
                "Type_ecu": "Bosch ME17.9.20",
                "Engine_code": "H4B C4",
            },
            "0.9 TCE 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 135,
                "tuned_torque": 175,
                "cc": 998,
                "Type_ecu": "Bosch ME17.9.20",
                "Engine_code": "H4B",
            },
        },
        "III - 2019 ->": {
            "0.9 TCe 92hp": {
                "std_power": 92,
                "tuned_power": 110,
                "std_torque": 135,
                "tuned_torque": 160,
                "cc": 999,
                "Type_ecu": "Bosch ME17.9.20",
                "Engine_code": "H4B 401",
            },
        },
    },
    "Vel Satis": {
        "All": {
            "2.0 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.0 DCi 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.2 DCi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 375,
                "cc": 2188,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "G9T",
            },
            "2.2 DCi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 2188,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "G9T",
            },
            "3.0 DCi 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 2958,
                "Type_ecu": "Denso DH5",
                "Engine_code": "P9X",
            },
            "2.0 Turbo 165hp": {
                "std_power": 165,
                "tuned_power": 200,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "Sagem S2000RPM",
                "Engine_code": "F4R",
            },
        },
    },
    "Wind": {
        "2010 ->": {
            "1.2 TCE 100hp": {
                "std_power": 100,
                "tuned_power": 122,
                "std_torque": 152,
                "tuned_torque": 195,
                "cc": 1149,
                "Type_ecu": "Siemens/Continental SIM32",
                "Engine_code": "H5F",
            },
            "1.6i 133hp": {
                "std_power": 133,
                "tuned_power": 145,
                "std_torque": 160,
                "tuned_torque": 180,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "K4M",
            },
        },
    },
},
"Rolls Royce": {
    "Cullinan": {
        "2018 ->": {
            "6.75 V12 571hp": {
                "std_power": 571,
                "tuned_power": 620,
                "std_torque": 850,
                "tuned_torque": 950,
                "cc": 6749,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS024",
                "Engine_code": "N74 B68",
            },
        },
    },
    "Dawn": {
        "All": {
            "6.6 V12 571hp": {
                "std_power": 571,
                "tuned_power": 700,
                "std_torque": 820,
                "tuned_torque": 950,
                "cc": 6592,
                "Type_ecu": "",
                "Engine_code": "N74 B66 A",
            },
        },
    },
    "Ghost": {
        "All": {
            "6.6 V12 570hp": {
                "std_power": 570,
                "tuned_power": 700,
                "std_torque": 820,
                "tuned_torque": 950,
                "cc": 6592,
                "Type_ecu": "Siemens/Continental MSD87",
                "Engine_code": "N74 B66 A",
            },
            "6.6 V12 612hp": {
                "std_power": 612,
                "tuned_power": 700,
                "std_torque": 840,
                "tuned_torque": 950,
                "cc": 6592,
                "Type_ecu": "",
                "Engine_code": "N74 B66 A",
            },
        },
    },
    "Wraith": {
        "All": {
            "6.6 V12 633hp": {
                "std_power": 633,
                "tuned_power": 700,
                "std_torque": 795,
                "tuned_torque": 950,
                "cc": 6592,
                "Type_ecu": "Siemens/Continental MSD87 & ZF 8HP",
                "Engine_code": "N74 B66 A",
            },
        },
    },
},
"Rover": {
    "75": {
        "All": {
            "2.0 CDTI 115hp": {
                "std_power": 115,
                "tuned_power": 142,
                "std_torque": 260,
                "tuned_torque": 302,
                "cc": 1951,
                "Type_ecu": "Bosch DDE4.0",
                "Engine_code": "",
            },
            "2.0 CDTI 131hp": {
                "std_power": 131,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1951,
                "Type_ecu": "Bosch DDE4.0",
                "Engine_code": "",
            },
        },
    },
},
"Saab": {
    "9-3": {
        "1998 - 2002": {
            "2.2 TiD 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 2171,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "D223L",
            },
            "2.0 Turbo 154hp": {
                "std_power": 154,
                "tuned_power": 194,
                "std_torque": 219,
                "tuned_torque": 290,
                "cc": 1985,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B204E",
            },
            "2.0 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1985,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B205E",
            },
            "2.0 Turbo 185hp": {
                "std_power": 185,
                "tuned_power": 215,
                "std_torque": 263,
                "tuned_torque": 315,
                "cc": 1985,
                "Type_ecu": "Trionic Trionic 7 & Trionic Trionic 8",
                "Engine_code": "YS3D",
            },
            "2.0 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 283,
                "tuned_torque": 350,
                "cc": 1985,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B204L / B205E / B205L",
            },
        },
        "2002 - 2007": {
            "1.9 TiD 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C9",
                "Engine_code": "Z19DTH",
            },
            "1.9 TiD 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C9",
                "Engine_code": "Z19DT",
            },
            "1.8 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1988,
                "Type_ecu": "Trionic Trionic 8",
                "Engine_code": "B207E",
            },
            "2.0 Turbo 185hp": {
                "std_power": 185,
                "tuned_power": 215,
                "std_torque": 263,
                "tuned_torque": 315,
                "cc": 1988,
                "Type_ecu": "Trionic Trionic 8",
                "Engine_code": "B207L",
            },
            "2.0 Turbo 210hp": {
                "std_power": 210,
                "tuned_power": 235,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1988,
                "Type_ecu": "Trionic Trionic 8",
                "Engine_code": "B207R",
            },
            "2.0 Turbo 175hp": {
                "std_power": 175,
                "tuned_power": 215,
                "std_torque": 265,
                "tuned_torque": 320,
                "cc": 1988,
                "Type_ecu": "Trionic Trionic 8 & Trionic 8 HFQ & Trionic 8 LFQ",
                "Engine_code": "B207L",
            },
            "2.8 V6 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "LP9",
            },
        },
        "2007 - 2010": {
            "1.9 TTiD 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z19DTR",
            },
            "1.9 TiD 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z19DT",
            },
            "1.9 TiD 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C9",
                "Engine_code": "Z19DTH",
            },
            "1.8 Turbo 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 265,
                "tuned_torque": 310,
                "cc": 1988,
                "Type_ecu": "Trionic Trionic 8",
                "Engine_code": "B207L",
            },
            "1.8 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1988,
                "Type_ecu": "Saab Trionic T7 & Trionic Trionic 8",
                "Engine_code": "B207E",
            },
            "2.0 Turbo 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 265,
                "tuned_torque": 320,
                "cc": 1988,
                "Type_ecu": "Trionic Trionic 7 & Trionic Trionic 8",
                "Engine_code": "B207L",
            },
            "2.0 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1988,
                "Type_ecu": "Saab Trionic T8",
                "Engine_code": "B207M",
            },
            "2.0 Turbo 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1988,
                "Type_ecu": "Trionic Trionic 8",
                "Engine_code": "B207R",
            },
            "2.8 V6 Turbo 280hp": {
                "std_power": 280,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "B248R",
            },
        },
        "2010 ->": {
            "1.9 TTiD 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16",
                "Engine_code": "A19DTR",
            },
            "1.9 TTiD 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 1910,
                "Type_ecu": "",
                "Engine_code": "A19DTR",
            },
            "1.9 TTiD 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z19DTR",
            },
            "1.8 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "Trionic 8 HFQ",
                "Engine_code": "B207E",
            },
            "2.0 Turbo 175hp": {
                "std_power": 175,
                "tuned_power": 205,
                "std_torque": 265,
                "tuned_torque": 320,
                "cc": 1998,
                "Type_ecu": "Saab Trionic T8",
                "Engine_code": "B207L",
            },
            "2.0 Turbo 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1998,
                "Type_ecu": "Trionic Trionic 8",
                "Engine_code": "B207R",
            },
            "2.8 V6 Turbo 280hp": {
                "std_power": 280,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "B248R",
            },
        },
    },
    "9-4 X": {
        "2011 ->": {
            "2.8 V6 Turbo 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "9-5": {
        "1997 - 2001": {
            "2.0 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 182,
                "std_torque": 240,
                "tuned_torque": 295,
                "cc": 1985,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B205E",
            },
            "2.3 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 280,
                "tuned_torque": 325,
                "cc": 2290,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B235E",
            },
            "2.3 Turbo 185hp": {
                "std_power": 185,
                "tuned_power": 220,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 2290,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B235E",
            },
            "2.3 Turbo 230hp": {
                "std_power": 230,
                "tuned_power": 265,
                "std_torque": 350,
                "tuned_torque": 405,
                "cc": 2290,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B235R",
            },
            "3.0 V6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 245,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 2962,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B308E",
            },
        },
        "2001 - 2005": {
            "3.0 TiD 176hp": {
                "std_power": 176,
                "tuned_power": 208,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2959,
                "Type_ecu": "",
                "Engine_code": "D308L",
            },
            "2.0 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 182,
                "std_torque": 240,
                "tuned_torque": 295,
                "cc": 1985,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B205E",
            },
            "2.3 Turbo 220hp": {
                "std_power": 220,
                "tuned_power": 255,
                "std_torque": 310,
                "tuned_torque": 365,
                "cc": 2290,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B235L",
            },
            "2.3 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2290,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B235R",
            },
            "2.3 Turbo 185hp": {
                "std_power": 185,
                "tuned_power": 220,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 2290,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B205L",
            },
            "3.0 V6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 245,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 2962,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B308E",
            },
        },
        "2005 - 2010": {
            "1.9 TiD 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "Z19DTH",
            },
            "2.0 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 310,
                "cc": 1985,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B205E",
            },
            "2.3 Turbo 185hp": {
                "std_power": 185,
                "tuned_power": 225,
                "std_torque": 280,
                "tuned_torque": 365,
                "cc": 2290,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B205L",
            },
            "2.3 Turbo 220hp": {
                "std_power": 220,
                "tuned_power": 255,
                "std_torque": 310,
                "tuned_torque": 365,
                "cc": 2290,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B235L",
            },
            "2.3 Turbo 260hp": {
                "std_power": 260,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2290,
                "Type_ecu": "Trionic Trionic 7",
                "Engine_code": "B235R",
            },
        },
        "2010 - 2011": {
            "2.0 TTiD 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP21",
                "Engine_code": "A20DTR",
            },
            "2.0 TiD 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19",
                "Engine_code": "A20DHT",
            },
            "3.0 TID V6 177hp": {
                "std_power": 177,
                "tuned_power": 208,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2959,
                "Type_ecu": "",
                "Engine_code": "DMAX",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.4",
                "Engine_code": "A16LET",
            },
            "2.0 Turbo 220hp": {
                "std_power": 220,
                "tuned_power": 295,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1998,
                "Type_ecu": "Delco E39 & Bosch MED9.6.1",
                "Engine_code": "A20NHT",
            },
            "2.8 Turbo 300hp": {
                "std_power": 300,
                "tuned_power": 340,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "A20NER",
            },
            "3.0 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 245,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 2962,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "900 Cabriolet": {
        "All": {
            "2.0 Turbo 185hp": {
                "std_power": 185,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 290,
                "cc": 1985,
                "Type_ecu": "",
                "Engine_code": "B204L",
            },
        },
    },
},
"Saturn": {
    "Aura": {
        "2007 - 2009": {
            "2.4i 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 220,
                "tuned_torque": 240,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
            "3.5 V6 224hp": {
                "std_power": 224,
                "tuned_power": 245,
                "std_torque": 298,
                "tuned_torque": 330,
                "cc": 3510,
                "Type_ecu": "Delco E38",
                "Engine_code": "LZ4",
            },
            "3.6 V6 252hp": {
                "std_power": 252,
                "tuned_power": 275,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 3564,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "LY7",
            },
        },
    },
    "Ion": {
        "2003 - 2007": {
            "2.0 Supercharged 205hp": {
                "std_power": 205,
                "tuned_power": 240,
                "std_torque": 271,
                "tuned_torque": 310,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "LSJ",
            },
            "2.2i 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 197,
                "tuned_torque": 225,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "Z22SE",
            },
            "2.4i 175hp": {
                "std_power": 175,
                "tuned_power": 195,
                "std_torque": 222,
                "tuned_torque": 245,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
        },
    },
    "L-Series": {
        "2000 - 2004": {
            "2.2i 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 203,
                "tuned_torque": 230,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "Z22SE",
            },
        },
    },
    "Outlook": {
        "2007 - 2010": {
            "3.6 V6 275hp": {
                "std_power": 275,
                "tuned_power": 295,
                "std_torque": 340,
                "tuned_torque": 360,
                "cc": 3564,
                "Type_ecu": "",
                "Engine_code": "LY7",
            },
        },
    },
    "Relay": {
        "2004 - 2007": {
            "3.5 V6 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 300,
                "tuned_torque": 330,
                "cc": 3498,
                "Type_ecu": "",
                "Engine_code": "LX9",
            },
        },
    },
    "Sky": {
        "2007 - 2010": {
            "2.0 Turbo 260hp": {
                "std_power": 260,
                "tuned_power": 290,
                "std_torque": 353,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "2.0 LNF",
            },
            "2.4i 177hp": {
                "std_power": 177,
                "tuned_power": 195,
                "std_torque": 235,
                "tuned_torque": 260,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
        },
    },
    "Vue": {
        "2002 - 2007": {
            "2.4i - 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 220,
                "tuned_torque": 250,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
            "3.5 V6 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 328,
                "tuned_torque": 360,
                "cc": 3471,
                "Type_ecu": "",
                "Engine_code": "J35A3",
            },
        },
        "2008 - 2010": {
            "2.4i - 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 220,
                "tuned_torque": 250,
                "cc": 2384,
                "Type_ecu": "",
                "Engine_code": "LE5",
            },
        },
    },
},
"Seat": {
    "Alhambra": {
        "1996 - 2000": {
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 202,
                "tuned_torque": 270,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AHU",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AFN",
            },
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "AJH",
            },
        },
        "2000 - 2010": {
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ANU",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AUY",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 310,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC17C46",
                "Engine_code": "BRT",
            },
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "AJH",
            },
            "2.0i 8v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.X 4Mbit",
                "Engine_code": "ATM",
            },
        },
        "2010 ->": {
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFF",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFG",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17C64 & Temic DQ200",
                "Engine_code": "DLUB",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CUV",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "DFM",
            },
            "2.0 TDI CR 115hp": {
                "std_power": 115,
                "tuned_power": 185,
                "std_torque": 280,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFFE",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFF",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.25 & Bosch MED17.1.27",
                "Engine_code": "CZE",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2",
                "Engine_code": "CCZ",
            },
            "2.0 TSI 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CCZ",
            },
        },
    },
    "Altea / XL": {
        "2004 - 2009": {
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC16U1",
                "Engine_code": "BJB",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2 & Bosch EDC17CP14",
                "Engine_code": "BMN",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U31 & Bosch EDC16U34 & Temic DQ250Cxx",
                "Engine_code": "BKD",
            },
            "1.2 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1390,
                "Type_ecu": "",
                "Engine_code": "CBZB",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20",
                "Engine_code": "CAX",
            },
            "1.6i 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 7.1",
                "Engine_code": "BSE",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5",
                "Engine_code": "BYT",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLR",
            },
            "FR 2.0 TFSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1968,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BWA",
            },
        },
        "2009 ->": {
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Bosch EDC17C64 & Temic DQ200 & Delphi DCM6.2",
                "Engine_code": "CAY",
            },
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYB",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "BKD",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46",
                "Engine_code": "BMN",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 150,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10",
                "Engine_code": "CBZ",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20",
                "Engine_code": "CAX",
            },
            "1.6i 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 7.1",
                "Engine_code": "BGU",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5",
                "Engine_code": "BYT",
            },
            "2.0 TSI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "",
                "Engine_code": "CCZB",
            },
        },
    },
    "Altea FreeTrack": {
        "2007 - 2009": {
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U31",
                "Engine_code": "BKD",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2",
                "Engine_code": "BMN",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BWA",
            },
        },
        "2009 - 2013": {
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAY",
            },
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYB",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U31",
                "Engine_code": "BKD",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "BMN",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20",
                "Engine_code": "CAX",
            },
            "2.0 TSI 211hp": {
                "std_power": 211,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5 & Temic DQ250",
                "Engine_code": "BWA",
            },
        },
    },
    "Arona": {
        "2017 - 2020": {
            "1.6 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DGTA",
            },
            "1.6 TDI 95hp": {
                "std_power": 95,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ200",
                "Engine_code": "DGTD",
            },
            "1.0 TGI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 160,
                "tuned_torque": 205,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27",
                "Engine_code": "DBYA",
            },
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.1.27",
                "Engine_code": "CHZL",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200 Gen2 & Bosch MED17.1.27",
                "Engine_code": "CHZJ",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DADA",
            },
        },
        "2021 -> ...": {
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "CHZL",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "CHZJ",
            },
        },
    },
    "Arosa": {
        "1997 - 2001": {
            "1.4 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 101,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AMF",
            },
            "1.7 SDI 60hp": {
                "std_power": 60,
                "tuned_power": 70,
                "std_torque": 115,
                "tuned_torque": 135,
                "cc": 1716,
                "Type_ecu": "Bosch EDC15VM",
                "Engine_code": "AKU",
            },
            "1.0i 8v 50hp": {
                "std_power": 50,
                "tuned_power": 56,
                "std_torque": 86,
                "tuned_torque": 96,
                "cc": 999,
                "Type_ecu": "Bosch ME7.5.10",
                "Engine_code": "AER",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 126,
                "tuned_torque": 141,
                "cc": 1390,
                "Type_ecu": "Marelli 4AV",
                "Engine_code": "AFK",
            },
            "1.4i 8v 60hp": {
                "std_power": 60,
                "tuned_power": 67,
                "std_torque": 116,
                "tuned_torque": 126,
                "cc": 1390,
                "Type_ecu": "Bosch ME7.5.10",
                "Engine_code": "ANV",
            },
        },
        "2001 - 2004": {
            "1.4 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 101,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AMF",
            },
            "1.7 SDI 60hp": {
                "std_power": 60,
                "tuned_power": 70,
                "std_torque": 115,
                "tuned_torque": 135,
                "cc": 1716,
                "Type_ecu": "Bosch EDC15VM",
                "Engine_code": "AKU",
            },
            "1.0i 8v 50hp": {
                "std_power": 50,
                "tuned_power": 56,
                "std_torque": 86,
                "tuned_torque": 96,
                "cc": 999,
                "Type_ecu": "Bosch ME7.5.10",
                "Engine_code": "AUC",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 126,
                "tuned_torque": 141,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4MV",
                "Engine_code": "AUB",
            },
            "1.4i 8v 60hp": {
                "std_power": 60,
                "tuned_power": 67,
                "std_torque": 116,
                "tuned_torque": 126,
                "cc": 1390,
                "Type_ecu": "Bosch ME7.5.10",
                "Engine_code": "AUD",
            },
        },
    },
    "Ateca": {
        "2016 - 2019": {
            "1.6 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DDY",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ381",
                "Engine_code": "DFF",
            },
            "FR 2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ381",
                "Engine_code": "DFHA",
            },
            "1.0 EcoTSI 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHZ",
            },
            "1.4 EcoTSI 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200 Gen2",
                "Engine_code": "CZEA",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 300,
                "cc": 1395,
                "Type_ecu": "",
                "Engine_code": "CZE",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200",
                "Engine_code": "DADA",
            },
            "FR 2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ380 & Bosch MG1CS111 & Siemens/Continental Simos 18 & Bosch MG1CS001 & Temic DQ381",
                "Engine_code": "CZPB",
            },
        },
        "2020 ->": {
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74",
                "Engine_code": "DFHA",
            },
            "2.0 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 200,
                "std_torque": 300,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DTR",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP74 & Bosch MD1CS004",
                "Engine_code": "DFFA",
            },
            "1.0 TSI 116hp": {
                "std_power": 116,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DKRA / DKRF",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CZEA",
            },
            "1.5 TGI 130hp": {
                "std_power": 130,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1498,
                "Type_ecu": "",
                "Engine_code": "DHF",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DPCA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10",
                "Engine_code": "CZPB",
            },
        },
    },
    "Cordoba": {
        "6K - 1999 - 2003": {
            "1.9 SDI 68hp": {
                "std_power": 68,
                "tuned_power": 80,
                "std_torque": 133,
                "tuned_torque": 153,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15VM+ & Bosch EDC15V",
                "Engine_code": "AQM",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 270,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AGR",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 295,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "ASK",
            },
            "1.4i 8v 60hp": {
                "std_power": 60,
                "tuned_power": 66,
                "std_torque": 116,
                "tuned_torque": 126,
                "cc": 1391,
                "Type_ecu": "Bosch ME7.5.10",
                "Engine_code": "ABD",
            },
            "1.6i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 145,
                "tuned_torque": 160,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental Simos 3 & Siemens/Continental Simos 7.1",
                "Engine_code": "AKS",
            },
            "1.8 T 20v 156hp": {
                "std_power": 156,
                "tuned_power": 200,
                "std_torque": 210,
                "tuned_torque": 300,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.X 4Mbit",
                "Engine_code": "AYP",
            },
        },
        "6L - 2003 - 2009": {
            "1.4 TDI 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 155,
                "tuned_torque": 200,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "BNM",
            },
            "1.4 TDI 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+ & Bosch EDC17U05",
                "Engine_code": "BMS",
            },
            "1.4 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AMF",
            },
            "1.9 SDI 64hp": {
                "std_power": 64,
                "tuned_power": 80,
                "std_torque": 125,
                "tuned_torque": 153,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "ASY",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ATD",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "1.2i 12v 70hp": {
                "std_power": 70,
                "tuned_power": 77,
                "std_torque": 112,
                "tuned_torque": 122,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental Simos 9.1",
                "Engine_code": "BXV",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4MV",
                "Engine_code": "BBY",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4MV",
                "Engine_code": "BBZ",
            },
            "1.4i 16v 85hp": {
                "std_power": 85,
                "tuned_power": 95,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4HV",
                "Engine_code": "BXW",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 153,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.5.20",
                "Engine_code": "BTS",
            },
        },
    },
    "Exeo": {
        "2009 ->": {
            "2.0 TDI CR 120hp": {
                "std_power": 120,
                "tuned_power": 180,
                "std_torque": 290,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP04 & Bosch EDC17CP14",
                "Engine_code": "CAGC",
            },
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14",
                "Engine_code": "CAGA",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46",
                "Engine_code": "CAHA",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CGLB",
            },
            "1.4 TFSI 125hp": {
                "std_power": 125,
                "tuned_power": 152,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.8 TSI 120hp": {
                "std_power": 120,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 340,
                "cc": 1781,
                "Type_ecu": "Bosch MED17.5",
                "Engine_code": "CDH",
            },
            "1.8 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 220,
                "tuned_torque": 320,
                "cc": 1781,
                "Type_ecu": "Bosch MED17.X CAN & Bosch ME7.5",
                "Engine_code": "CDH",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1781,
                "Type_ecu": "Bosch MED17.5",
                "Engine_code": "CDH",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1.5",
                "Engine_code": "BWE",
            },
            "2.0 TSI 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1",
                "Engine_code": "CDN",
            },
        },
    },
    "Ibiza": {
        "1999 - 2002 ( 6K )": {
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "ASK",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 270,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AGR",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1391,
                "Type_ecu": "Marelli IAW4LV & Marelli IAW 4TVJG",
                "Engine_code": "APE",
            },
            "1.6i 8v 100hp": {
                "std_power": 100,
                "tuned_power": 107,
                "std_torque": 145,
                "tuned_torque": 155,
                "cc": 1595,
                "Type_ecu": "Bosch ME7.5.10",
                "Engine_code": "AKL",
            },
            "1.8T 20v 156hp": {
                "std_power": 156,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.X 4Mbit",
                "Engine_code": "AQX",
            },
            "1.8T 20v 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 235,
                "tuned_torque": 340,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "AYP",
            },
        },
        "2002 - 2008 ( 6L )": {
            "1.4 TDI 70hp": {
                "std_power": 70,
                "tuned_power": 100,
                "std_torque": 155,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "BNM",
            },
            "1.4 TDI 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+ & Bosch EDC17U01",
                "Engine_code": "BNV",
            },
            "1.9 SDI 64hp": {
                "std_power": 64,
                "tuned_power": 75,
                "std_torque": 125,
                "tuned_torque": 150,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "ASY",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ATD",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "Cupra 1.9 TDI 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 330,
                "tuned_torque": 400,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "BPX",
            },
            "1.4i 16v 85hp": {
                "std_power": 85,
                "tuned_power": 95,
                "std_torque": 132,
                "tuned_torque": 151,
                "cc": 1391,
                "Type_ecu": "Marelli IAW4HV",
                "Engine_code": "BXW",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1391,
                "Type_ecu": "Marelli IAW4MV & Marelli IAW4TV",
                "Engine_code": "AUA",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1391,
                "Type_ecu": "Marelli IAW4MV & Siemens/Continental Simos 3",
                "Engine_code": "AUB",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 153,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.5.20",
                "Engine_code": "BTS",
            },
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 220,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "BJX",
            },
            "1.8T 20v 180hp": {
                "std_power": 180,
                "tuned_power": 192,
                "std_torque": 245,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "BBU",
            },
            "2.0i 8v 115hp": {
                "std_power": 115,
                "tuned_power": 122,
                "std_torque": 170,
                "tuned_torque": 180,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "AZL",
            },
        },
        "2008 - 2015 ( 6J )": {
            "1.2 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 105,
                "std_torque": 180,
                "tuned_torque": 240,
                "cc": 1199,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "CFWA",
            },
            "1.4 TDI 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC17U05 & Bosch EDC17U1 & Delphi DCM3.7",
                "Engine_code": "BMS",
            },
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYB",
            },
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYC",
            },
            "1.6 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 135,
                "std_torque": 195,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC17U1",
                "Engine_code": "BLS",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 210,
                "tuned_torque": 340,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC17U01",
                "Engine_code": "BLS",
            },
            "2.0 TDI 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CBAB",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10",
                "Engine_code": "CBZB",
            },
            "1.2 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10 & Siemens/Continental Simos 11.1 & Bosch MED17.5.21",
                "Engine_code": "CJZC",
            },
            "1.2i 60hp": {
                "std_power": 60,
                "tuned_power": 67,
                "std_torque": 108,
                "tuned_torque": 118,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental Simos 9.1 & Siemens/Continental Simos 11.1",
                "Engine_code": "BKV",
            },
            "1.2i 70hp": {
                "std_power": 70,
                "tuned_power": 77,
                "std_torque": 112,
                "tuned_torque": 122,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental Simos 11.1",
                "Engine_code": "BZG",
            },
            "1.4 TSI 140hp": {
                "std_power": 140,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CAVF",
            },
            "1.4 TSI 180hp (CTHE-CTJC)": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Temic DQ200",
                "Engine_code": "CTHE",
            },
            "1.4 TSI 150hp (CTHF-CTJC)": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5",
                "Engine_code": "CTHF",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 153,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.5.20",
                "Engine_code": "BTS",
            },
        },
        "2015 - 2017 ( 6J )": {
            "1.4 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CUSB",
            },
            "1.4 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CUTA",
            },
            "1.4 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 210,
                "tuned_torque": 280,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CUSA",
            },
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHZ",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHZ",
            },
            "1.0 TSI 75hp": {
                "std_power": 75,
                "tuned_power": 120,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 999,
                "Type_ecu": "Bosch ME17.5.2X",
                "Engine_code": "CHYB",
            },
            "1.2 TSI 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 230,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Siemens/Continental Simos 10.22",
                "Engine_code": "CJZC",
            },
            "1.2 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 175,
                "tuned_torque": 230,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CJZD",
            },
            "1.4 EcoTSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CAVF",
            },
            "1.8 TSI Cupra 192hp": {
                "std_power": 192,
                "tuned_power": 240,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 18",
                "Engine_code": "DAJ",
            },
        },
        "2017 -> ( A0 )": {
            "1.6 TDI 80hp": {
                "std_power": 80,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DGTC",
            },
            "1.6 TDI 95hp": {
                "std_power": 95,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2V",
                "Engine_code": "DGTD",
            },
            "1.6 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DGTA",
            },
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.27 & Bosch MED17.1.27",
                "Engine_code": "CHZL",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Temic DQ200-MQB & Bosch MED17.5.21",
                "Engine_code": "CHZJ /DKJA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DADA",
            },
        },
        "2021 -> ...": {
            "1.0 TGI 90hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 TSI 115hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "CHZJ /DKJA",
            },
            "1.5 TSI 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Leon": {
        "1M - 2000 - 2006": {
            "1.9 SDI 68hp": {
                "std_power": 68,
                "tuned_power": 80,
                "std_torque": 133,
                "tuned_torque": 155,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AQM",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 295,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AHF",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "1.9 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ARL",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 280,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AGR",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 128,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4AV & Marelli IAW4LV & Bosch ME7.5.10",
                "Engine_code": "AHW",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 148,
                "tuned_torque": 160,
                "cc": 1597,
                "Type_ecu": "Bosch ME7.5.10 & Marelli IAW4MV",
                "Engine_code": "AUS",
            },
            "1.6i 8v 100hp": {
                "std_power": 100,
                "tuned_power": 107,
                "std_torque": 145,
                "tuned_torque": 155,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 3 & Siemens/Continental Simos 7.1",
                "Engine_code": "BFQ",
            },
            "1.8T 20v 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 235,
                "tuned_torque": 330,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5 & Bosch ME7.5.5",
                "Engine_code": "AJQ",
            },
            "1.8T 20v 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 270,
                "tuned_torque": 340,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "AMK",
            },
            "1.8T 20v 225hp": {
                "std_power": 225,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 400,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "BAM",
            },
            "1.8i 20v 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "AGN",
            },
        },
        "1P - 2005 - 2012": {
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYC",
            },
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYB",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 210,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC15P+ & Bosch EDC17U01 & Bosch ME17.9.20",
                "Engine_code": "BXF",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC16U1",
                "Engine_code": "BKC",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC16U31 & Siemens/Continental PPD1.2 & Bosch EDC16U1 & Bosch EDC16U34 & Temic DQ250",
                "Engine_code": "CBDB",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Siemens/Continental PPD1.2 & Bosch EDC17CP14",
                "Engine_code": "BMN",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10 & Temic DQ200",
                "Engine_code": "CBZ",
            },
            "1.2 TSI 86hp": {
                "std_power": 86,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "",
                "Engine_code": "CJZB",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20 & Bosch MED17.5.5",
                "Engine_code": "CAXC",
            },
            "1.4i 16v 85hp": {
                "std_power": 85,
                "tuned_power": 95,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4HV & Marelli 7GVE",
                "Engine_code": "BXW",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 7.1",
                "Engine_code": "BSE",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.X CAN & Bosch MED17.5 & Temic DQ200",
                "Engine_code": "BZB",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLR",
            },
            "2.0 TFSI FR 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Bosch MED17.5",
                "Engine_code": "CCZB",
            },
            "2.0 TFSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Bosch ME17",
                "Engine_code": "BWA",
            },
            "2.0 TFSI 185hp": {
                "std_power": 185,
                "tuned_power": 250,
                "std_torque": 270,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BWA",
            },
            "2.0 TFSI 240hp": {
                "std_power": 240,
                "tuned_power": 310,
                "std_torque": 300,
                "tuned_torque": 410,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BWJ",
            },
            "2.0 TFSI 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BWJ",
            },
            "2.0 TFSI 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "CDLA",
            },
        },
        "5F MK1 - 2012 - 2017": {
            "1.6 TDI CR 90hp": {
                "std_power": 90,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CXXA",
            },
            "1.6 TDI CR 105hp": {
                "std_power": 105,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2 & Temic DQ200",
                "Engine_code": "CAY",
            },
            "1.6 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2 & Temic DQ200 Gen2 & Bosch EDC17C74",
                "Engine_code": "CXXB",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250",
                "Engine_code": "CRL",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250-6A/6F/6V-MQB",
                "Engine_code": "CUN",
            },
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHZD",
            },
            "1.2 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.25",
                "Engine_code": "CJZ",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CBZ",
            },
            "1.2 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.25",
                "Engine_code": "CYV",
            },
            "1.4 TGI 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CPWA",
            },
            "1.4 TSI 140hp (CHPA)": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHPA",
            },
            "1.4 TSI 122hp (CMBA-CXSA)": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CMBA",
            },
            "1.4 TSI 125hp (CZCA)": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Bosch MED17.5.21",
                "Engine_code": "CZCA",
            },
            "1.4 TSI 150hp FR": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200-MQB & Temic DQ200 Gen2",
                "Engine_code": "CZE",
            },
            "1.8 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Siemens/Continental Simos 12 & Temic DQ200",
                "Engine_code": "CJS",
            },
            "2.0 TFSI 280hp": {
                "std_power": 280,
                "tuned_power": 365,
                "std_torque": 350,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CJX",
            },
            "2.0 TFSI 265hp": {
                "std_power": 265,
                "tuned_power": 365,
                "std_torque": 350,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CDL",
            },
            "2.0 TFSI 290hp": {
                "std_power": 290,
                "tuned_power": 365,
                "std_torque": 350,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Temic DQ250",
                "Engine_code": "CJX",
            },
        },
        "5F MK2 - 2017 - 2019": {
            "1.6 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ200 Gen2",
                "Engine_code": "DDYA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ381 & Bosch MD1CS004 & Temic DQ250",
                "Engine_code": "CFKC / CRMB",
            },
            "2.0 TDI 184hp": {
                "std_power": 184,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ250",
                "Engine_code": "CUNA /CUPA",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHZD",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Bosch MED17.5.21 & Temic DQ200 Gen2",
                "Engine_code": "CZEA",
            },
            "1.5 TGI 130hp": {
                "std_power": 130,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DHFA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200 Gen2",
                "Engine_code": "DADA / DPCA",
            },
            "1.5 TSI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 290,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DACA / DPBA",
            },
            "1.8 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ200 & Siemens/Continental Simos 12",
                "Engine_code": "CJSA",
            },
            "2.0 TSI Cupra 300hp": {
                "std_power": 300,
                "tuned_power": 365,
                "std_torque": 380,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Temic DQ381 & Temic DQ250-6A/6F/6V-MQB & Siemens/Continental Simos 18.2",
                "Engine_code": "CJXC",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS111 & Temic DQ381",
                "Engine_code": "DKZA",
            },
        },
        "KL - 2020 ->": {
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DLAA",
            },
            "1.4 e-Hybrid 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 195,
                "std_torque": 300,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "DSUD",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DSTB",
            },
            "1.0 TSI 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DLAB",
            },
            "1.0 e-TSI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DLAA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS001 & Temic DQ200 Gen2 & Bosch MG1CS011",
                "Engine_code": "DFYA",
            },
            "1.5 TSI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 280,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DPBA",
            },
            "1.5 e-TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DFYA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1",
                "Engine_code": "DNN",
            },
        },
    },
    "Mii": {
        "2012 ->": {
            "1.0i 60hp": {
                "std_power": 60,
                "tuned_power": 85,
                "std_torque": 92,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch ME17.5.20 & Bosch ME17.5.24",
                "Engine_code": "CHY",
            },
            "1.0i 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 95,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch ME17.5.20",
                "Engine_code": "CHY",
            },
        },
    },
    "Tarraco": {
        "2019 ->": {
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ200 Gen2",
                "Engine_code": "DFGA",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ500",
                "Engine_code": "DFHA",
            },
            "2.0 TDI 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DFHA",
            },
            "2.0 TSI 245hp": {
                "std_power": 245,
                "tuned_power": 327,
                "std_torque": 370,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.3",
                "Engine_code": "DNPA",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CZDA",
            },
            "1.4 e-TSI 245hp": {
                "std_power": 245,
                "tuned_power": 285,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "",
                "Engine_code": "DGEA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS001 & Bosch MG1CS011 & Temic DQ381",
                "Engine_code": "DPCA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS111 & Temic DQ381",
                "Engine_code": "CZPB",
            },
        },
    },
    "Toledo": {
        "1999 - 2004": {
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 290,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AGR",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 295,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AHF",
            },
            "1.9 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ARL",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1597,
                "Type_ecu": "Bosch ME7.5.10",
                "Engine_code": "AUS",
            },
            "1.8T 20v 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 235,
                "tuned_torque": 330,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "AUQ",
            },
            "1.8i 20v 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "AGN",
            },
            "2.3 V5 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 225,
                "tuned_torque": 240,
                "cc": 2324,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "AQN",
            },
            "2.3 V5 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 210,
                "tuned_torque": 225,
                "cc": 2324,
                "Type_ecu": "Bosch M3.8.3",
                "Engine_code": "AGZ",
            },
        },
        "2004 - 2009": {
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U34",
                "Engine_code": "BJB",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1986,
                "Type_ecu": "Bosch EDC16U34",
                "Engine_code": "BKD",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 7.1",
                "Engine_code": "BGU",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5",
                "Engine_code": "BZB",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLR",
            },
        },
        "2013 ->": {
            "1.4 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CUSB",
            },
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Siemens/Continental PCR 2.1",
                "Engine_code": "CAY",
            },
            "1.6 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C64",
                "Engine_code": "CXMA",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "",
            },
            "1.2 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10.22 & Bosch MED17.5.25 & Siemens/Continental Simos 10.X",
                "Engine_code": "CBZ",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10 & Bosch MED17.5.25",
                "Engine_code": "CBZ",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.25",
                "Engine_code": "CAX",
            },
            "1.4 TSI 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CHP",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1390,
                "Type_ecu": "Temic DQ200",
                "Engine_code": "CAX",
            },
        },
    },
},
"Skoda": {
    "Citigo": {
        "2012 ->": {
            "1.0 TSi 90hp": {
                "std_power": 90,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0i 60hp": {
                "std_power": 60,
                "tuned_power": 85,
                "std_torque": 95,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch ME17.5.20 & Bosch ME17.5.24",
                "Engine_code": "CHYA",
            },
            "1.0i 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 95,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch ME17.5.20 & Bosch ME17.5.24",
                "Engine_code": "CHYB",
            },
        },
    },
    "Fabia": {
        "2000 - 2007": {
            "1.4 TDI 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC17U05 & Bosch EDC15P+",
                "Engine_code": "BNV",
            },
            "1.4 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AMF",
            },
            "1.4 TDI 70hp": {
                "std_power": 70,
                "tuned_power": 100,
                "std_torque": 155,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "BNM",
            },
            "1.9 SDI 64hp": {
                "std_power": 64,
                "tuned_power": 75,
                "std_torque": 125,
                "tuned_torque": 145,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "ASY",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ATD",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4HV & Siemens/Continental Simos 3 & Marelli IAW4TV",
                "Engine_code": "APE",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4MV",
                "Engine_code": "AUB",
            },
            "2.0i 8v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "AZL",
            },
        },
        "2007 - 2014": {
            "1.2 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 105,
                "std_torque": 180,
                "tuned_torque": 240,
                "cc": 1199,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "CFWA",
            },
            "1.4 TDI 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC17U05 & Bosch EDC15P+ & Bosch EDC17U01",
                "Engine_code": "BNV",
            },
            "1.4 TDI 70hp": {
                "std_power": 70,
                "tuned_power": 100,
                "std_torque": 155,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "BNM",
            },
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYC",
            },
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYB",
            },
            "1.6 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 140,
                "std_torque": 195,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYA",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC15P+ & Bosch EDC17U1",
                "Engine_code": "BSW",
            },
            "1.2 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10",
                "Engine_code": "CBZA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10 & Temic DQ200",
                "Engine_code": "CBZB",
            },
            "1.2i 60hp": {
                "std_power": 60,
                "tuned_power": 67,
                "std_torque": 108,
                "tuned_torque": 118,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental Simos 9.1",
                "Engine_code": "EA111",
            },
            "1.2i 70hp": {
                "std_power": 70,
                "tuned_power": 77,
                "std_torque": 112,
                "tuned_torque": 122,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental Simos 9.1 & Siemens/Continental Simos 11.1",
                "Engine_code": "BZG",
            },
            "1.4 TSI RS 180hp (CTHE)": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Temic DQ200",
                "Engine_code": "CTHE",
            },
            "1.4i 16v 85hp": {
                "std_power": 85,
                "tuned_power": 95,
                "std_torque": 132,
                "tuned_torque": 145,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4HV & Marelli 7GVE",
                "Engine_code": "BXW",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 153,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.5.20",
                "Engine_code": "BTS",
            },
        },
        "2015 - 2020": {
            "1.4 TDI CR 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CUSA",
            },
            "1.4 TDI CR 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CUTA",
            },
            "1.4 TDI CR 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 210,
                "tuned_torque": 280,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CUS",
            },
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.27 & Bosch MED17.1.27",
                "Engine_code": "CHZB",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.1.27 & Temic DQ200",
                "Engine_code": "CHZC",
            },
            "1.0i 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 95,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.24 & Bosch MED17.5.26",
                "Engine_code": "CHYB",
            },
            "1.0i 60hp": {
                "std_power": 60,
                "tuned_power": 85,
                "std_torque": 95,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.26",
                "Engine_code": "CHYA",
            },
            "1.2 TSI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 230,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CJZC",
            },
            "1.2 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 230,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200",
                "Engine_code": "CJZD",
            },
        },
        "2021 -> ...": {
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27",
                "Engine_code": "DLA",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27",
                "Engine_code": "DLA",
            },
        },
    },
    "Kamiq": {
        "2019 ->": {
            "1.6 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2V",
                "Engine_code": "DGTA",
            },
            "1.0 TSi 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "DKLA",
            },
            "1.0 TSi 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.27",
                "Engine_code": "DKRF",
            },
            "1.5 TSi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200 Gen2",
                "Engine_code": "DADA / DPCA",
            },
        },
    },
    "Karoq": {
        "2017 - 2020": {
            "1.6 TDI 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ200 Gen2",
                "Engine_code": "DDYA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch MD1CS004",
                "Engine_code": "DFFA",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74",
                "Engine_code": "DFHA",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHZJ",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MED17.5.27 & Bosch MG1CS011 & Temic DQ381 & Temic DQ200 Gen2",
                "Engine_code": "DADA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Temic DQ381 & Bosch MG1CS111",
                "Engine_code": "DKZA",
            },
        },
        "2021 -> ...": {
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "DFFA",
            },
            "2.0 TDI 116hp": {
                "std_power": 116,
                "tuned_power": 195,
                "std_torque": 250,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004 & Temic DQ200 Gen2",
                "Engine_code": "DFFA",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "CHZJ",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "",
                "Engine_code": "DADA",
            },
        },
    },
    "Kodiaq": {
        "2017 - 2020": {
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ500 & Bosch MD1CS004 & Temic DQ381",
                "Engine_code": "DBGA / DBGC / DFGA",
            },
            "2.0 TDI CR 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch MD1CS004 & Temic DQ500",
                "Engine_code": "DFHA",
            },
            "RS 2.0 Bi-Turbo TDI 240hp": {
                "std_power": 240,
                "tuned_power": 300,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP74 & Temic DQ500",
                "Engine_code": "CUAA",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CZCA",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ250",
                "Engine_code": "CZDA / CZEA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ381 & Temic DQ200 Gen2",
                "Engine_code": "DADA / DPCA",
            },
            "2.0 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS111 & Bosch MG1CS001 & Temic DQ500",
                "Engine_code": "CZPA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS111 & Temic DQ381 & Temic DQ500 & Siemens/Continental Simos 18.10",
                "Engine_code": "CZPA",
            },
        },
        "2021 -> ...": {
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DBGA / DBGC / DFGA",
            },
            "2.0 TDI CR 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DFHA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DPCA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.3 & Bosch MG1CS111",
                "Engine_code": "CZPA",
            },
            "RS 2.0 TFSI 245hp": {
                "std_power": 245,
                "tuned_power": 320,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.3 & Temic DQ381",
                "Engine_code": "DNP",
            },
        },
    },
    "Octavia": {
        "1997 - 2004": {
            "1.9 SDI 68hp": {
                "std_power": 68,
                "tuned_power": 80,
                "std_torque": 130,
                "tuned_torque": 150,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V & Bosch EDC15VM+",
                "Engine_code": "AGP",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 280,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AGR",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 310,
                "tuned_torque": 365,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ATD",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "AGR",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Bosch ME7.5.10",
                "Engine_code": "AXP",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 3",
                "Engine_code": "AVU",
            },
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5 & Bosch ME7.5",
                "Engine_code": "AUM",
            },
            "1.8T 20v 180hp": {
                "std_power": 180,
                "tuned_power": 192,
                "std_torque": 235,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "AUQ",
            },
            "1.8i 20v 125hp": {
                "std_power": 125,
                "tuned_power": 137,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1781,
                "Type_ecu": "Bosch M3.8.3",
                "Engine_code": "AGN",
            },
        },
        "2004 - 2012": {
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Temic DQ200",
                "Engine_code": "CAYC",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC16U1 & Temic DQ250 & Temic DQ200 & Bosch EDC16U31",
                "Engine_code": "BJB",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2 & Bosch EDC17CP14 & Bosch EDC17C46 & Temic DQ250Exx",
                "Engine_code": "CEGA",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC17C46",
                "Engine_code": "BKD",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC17C46 & Bosch EDC16U1 & Bosch EDC16U31",
                "Engine_code": "BMM",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2 & Bosch EDC17CP14",
                "Engine_code": "BMN",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10 & Temic DQ200",
                "Engine_code": "CBZB",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20 & Bosch MED17.5.5 & Temic DQ200",
                "Engine_code": "CAXA",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Bosch ME7.5.10 & Marelli IAW4TV",
                "Engine_code": "BCA",
            },
            "1.6 FSI 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch MED9.5.10 & Bosch MED17.5.20",
                "Engine_code": "BLF",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.1 & Bosch MED17.5 & Temic DQ200",
                "Engine_code": "BZB",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLR",
            },
            "2.0 TFSI RS 200hp": {
                "std_power": 200,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Bosch MED17.5 & Temic DQ250",
                "Engine_code": "BWA",
            },
        },
        "2013 - 2016": {
            "1.6 TDI CR 105hp": {
                "std_power": 105,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Siemens/Continental PCR 2.1 & Temic DQ200-MQB",
                "Engine_code": "CLHA",
            },
            "1.6 TDI CR 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C64",
                "Engine_code": "CXXA",
            },
            "1.6 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2 & Bosch EDC17C74 & Temic DQ200",
                "Engine_code": "CXXB",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Bosch EDC17C46 & Temic DQ250",
                "Engine_code": "CKFC",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250 & Bosch EDC17C74",
                "Engine_code": "CUPA",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHZ",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 175,
                "tuned_torque": 230,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.25",
                "Engine_code": "CJZA",
            },
            "1.2 TSI 86hp": {
                "std_power": 86,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 230,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.25",
                "Engine_code": "CJZB",
            },
            "1.2 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 175,
                "tuned_torque": 230,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "",
            },
            "1.4 TSI G-TEC 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CPWA",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200 Gen2",
                "Engine_code": "CZD",
            },
            "1.4 TSI 140hp (CHPA)": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200-MQB",
                "Engine_code": "CHPA",
            },
            "1.8 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 370,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 12.1 & Siemens/Continental Simos 18 & Temic DQ200-MQB & Temic DQ200 Gen2 & Temic DQ250-6A/6F/6V-MQB",
                "Engine_code": "CJSA",
            },
            "2.0 TSI RS Edition 230hp": {
                "std_power": 230,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CHHB",
            },
            "2.0 TSI RS 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250-6A/6F/6V-MQB",
                "Engine_code": "CHHB",
            },
        },
        "2017 - 2019": {
            "1.6 TDI CR 90hp": {
                "std_power": 90,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2",
                "Engine_code": "CLHB",
            },
            "1.6 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2V & Temic DQ200 Gen2",
                "Engine_code": "CXXB",
            },
            "1.6 TDI CR 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2V & Temic DQ200 Gen2",
                "Engine_code": "DDYA",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250 & Bosch EDC17C74",
                "Engine_code": "CKFC",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ381",
                "Engine_code": "CUPA",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200 Gen2 & Bosch MED17.1.27",
                "Engine_code": "CHZD",
            },
            "1.2 TSI 86hp": {
                "std_power": 86,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.25",
                "Engine_code": "CJZB",
            },
            "1.4 TSI G-tec 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CPWA",
            },
            "1.4 TSI 140hp (CHPA)": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHPA",
            },
            "1.4 TSi 150hp (CHPB)": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Bosch MG1CS011 & Temic DQ200 Gen2",
                "Engine_code": "CHPB",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200 Gen2",
                "Engine_code": "DADA / DPCA",
            },
            "1.5 TSI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 290,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DHFA",
            },
            "1.8 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 12.1 & Siemens/Continental Simos 18 & Temic DQ200",
                "Engine_code": "CJSA",
            },
            "2.0 TSI RS 245hp": {
                "std_power": 245,
                "tuned_power": 310,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ381 & Siemens/Continental Simos 19.6",
                "Engine_code": "DLBA",
            },
            "2.0 TSI RS 230hp": {
                "std_power": 230,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250-6A/6F/6V-MQB",
                "Engine_code": "DLBA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS111 & Temic DQ381",
                "Engine_code": "DKZA",
            },
        },
        "2020 ->": {
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DLAA",
            },
            "vRS 2.0 TSI 245hp": {
                "std_power": 245,
                "tuned_power": 327,
                "std_torque": 370,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.6",
                "Engine_code": "DNPA",
            },
            "2.0 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 195,
                "std_torque": 300,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DSUD / DTR",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DSTB / DTT",
            },
            "2.0 TDI 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DTUA",
            },
            "1.0 e-TSI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DLAA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200 Gen2",
                "Engine_code": "DPCA",
            },
            "1.5 TSI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 280,
                "cc": 1498,
                "Type_ecu": "Temic DQ200 Gen2",
                "Engine_code": "DHFA",
            },
            "1.5 e-TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1498,
                "Type_ecu": "Bosch MDG1 & Bosch MG1CS011",
                "Engine_code": "DFY",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.6 & Bosch MG1CS031",
                "Engine_code": "DKZA",
            },
            "RS iV 1.4 TSI 245hp": {
                "std_power": 245,
                "tuned_power": 285,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "Siemens/Continental Simos 19.6",
                "Engine_code": "DGEA",
            },
            "iV 1.4 TSI 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Rapid": {
        "2012 ->": {
            "1.4 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 210,
                "tuned_torque": 280,
                "cc": 1422,
                "Type_ecu": "",
                "Engine_code": "CUSB",
            },
            "1.4 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ200",
                "Engine_code": "CUSB",
            },
            "1.4 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1422,
                "Type_ecu": "",
                "Engine_code": "CAY",
            },
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Bosch EDC17C64 & Siemens/Continental DQ200 Gen2",
                "Engine_code": "CAYC",
            },
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C64 & Siemens/Continental PCR 2.1",
                "Engine_code": "CAY",
            },
            "1.6 TDI 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C64",
                "Engine_code": "CXMA",
            },
            "1.0 TSi 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Bosch MED17.1.21 & Bosch MED17.5.21",
                "Engine_code": "CHZB / DKLD",
            },
            "1.0 TSi 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Bosch MED17.5.25",
                "Engine_code": "CHZC / DKRC",
            },
            "1.2 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10.22",
                "Engine_code": "CBZA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10",
                "Engine_code": "CBZB",
            },
            "1.2 TSI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10 & Bosch MED17.5.21 & Bosch MED17.5.25",
                "Engine_code": "CBZA",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Temic DQ200",
                "Engine_code": "CAXA",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5",
                "Engine_code": "CAXA",
            },
        },
    },
    "Roomster": {
        "2006 - 2010": {
            "1.4 TDI 70hp": {
                "std_power": 70,
                "tuned_power": 105,
                "std_torque": 155,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "BNM",
            },
            "1.4 TDI 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC17U05 & Bosch EDC17U01",
                "Engine_code": "BNV",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC15P+ & Bosch EDC7U1 & Bosch EDC17U01",
                "Engine_code": "BSW",
            },
            "1.2i 12v 65hp": {
                "std_power": 65,
                "tuned_power": 70,
                "std_torque": 112,
                "tuned_torque": 120,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental Simos 9.1",
                "Engine_code": "BME",
            },
            "1.4i 16v 86hp": {
                "std_power": 86,
                "tuned_power": 96,
                "std_torque": 132,
                "tuned_torque": 145,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4HV & Marelli 7GVE",
                "Engine_code": "BXW",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 153,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.5.20",
                "Engine_code": "BTS",
            },
        },
        "2010 ->": {
            "1.2 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 105,
                "std_torque": 180,
                "tuned_torque": 240,
                "cc": 1199,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "CFWA",
            },
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYB",
            },
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Bosch EDC17C64",
                "Engine_code": "CAYC",
            },
            "1.2 TSI 86hp": {
                "std_power": 86,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10",
                "Engine_code": "CBZA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10",
                "Engine_code": "CBZB",
            },
            "1.2i 70hp": {
                "std_power": 70,
                "tuned_power": 77,
                "std_torque": 112,
                "tuned_torque": 122,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental Simos 11.1",
                "Engine_code": "CGP",
            },
        },
    },
    "Scala": {
        "2019 ->": {
            "1.6 TDi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DGTA",
            },
            "1.0 TSi 116hp": {
                "std_power": 116,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Temic DQ200",
                "Engine_code": "DKRF",
            },
            "1.0 TSi 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DKLA",
            },
            "1.5 TSI Edition S 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 TSi 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200",
                "Engine_code": "DADA / DPCA",
            },
        },
    },
    "Superb": {
        "2002 - 2008": {
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AVB",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16",
                "Engine_code": "BLS",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 285,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AWX",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+ & Bosch EDC16U31",
                "Engine_code": "BPZ",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16",
                "Engine_code": "BKD",
            },
            "2.0 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "BKD",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "BMN",
            },
            "2.5 TDI V6 155hp": {
                "std_power": 155,
                "tuned_power": 190,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+2",
                "Engine_code": "BSS",
            },
            "2.5 TDI V6 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 390,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "BDG",
            },
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "AWT",
            },
            "2.0i 8v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 172,
                "tuned_torque": 185,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "AZM",
            },
            "2.8 V6 193hp": {
                "std_power": 193,
                "tuned_power": 208,
                "std_torque": 280,
                "tuned_torque": 300,
                "cc": 2771,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "ACK",
            },
        },
        "2008 - 2015": {
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Bosch EDC17C64",
                "Engine_code": "CAYC",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16U34",
                "Engine_code": "BLS",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Temic DQ250Fxx & Bosch EDC17CP14",
                "Engine_code": "CFJA",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CRL",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CUP",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Temic DQ250 & Bosch EDC17CP14",
                "Engine_code": "CFG",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC16U34 & Temic DQ250 & Bosch EDC17CP14",
                "Engine_code": "CFF",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.20",
                "Engine_code": "CAXC",
            },
            "1.4 TSI 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CZDA / CZEA",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5",
                "Engine_code": "CDAA",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5 & Bosch MED17.5.2 & Temic DQ250 & Siemens/Continental Simos 18.6",
                "Engine_code": "CCZ",
            },
            "3.6 FSI 260hp": {
                "std_power": 260,
                "tuned_power": 280,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 3580,
                "Type_ecu": "Bosch MED9.1 & Bosch MED17.6.1",
                "Engine_code": "CDV",
            },
        },
        "2015 - 2019": {
            "1.6 TDI CR 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C74 & Siemens/Continental DQ200 Gen2",
                "Engine_code": "CAYC",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ250-6A/6F/6V-MQB & Temic DQ381",
                "Engine_code": "CRLB",
            },
            "2.0 TDI CR 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ250 & Temic DQ380 & Temic DQ381",
                "Engine_code": "DDAA",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CAXC",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200 Gen2",
                "Engine_code": "CZE",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DADA / DPCA",
            },
            "1.8 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 240,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 12.1 & Temic DQ200 & Siemens/Continental Simos 18",
                "Engine_code": "CJSC",
            },
            "2.0 TSI 280hp": {
                "std_power": 280,
                "tuned_power": 365,
                "std_torque": 350,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ200 & Temic DQ250",
                "Engine_code": "CJX",
            },
            "2.0 TSI 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Temic DQ200 & Siemens/Continental Simos 18.6 & Temic DQ250",
                "Engine_code": "CHHB",
            },
        },
        "2019 ->": {
            "1.6 TDI 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17CP74",
                "Engine_code": "DCXA / DCZA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Temic DQ380 & Bosch MD1CS004 & Bosch EDC17C74",
                "Engine_code": "DSRA",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74",
                "Engine_code": "DDAA / DFCA / DFHA",
            },
            "2.0 TDI 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch MD1",
                "Engine_code": "DTUA",
            },
            "2.0 TSI 280hp": {
                "std_power": 280,
                "tuned_power": 350,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.3 & Temic DQ380 & Temic DQ381",
                "Engine_code": "CJXA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200 Gen2",
                "Engine_code": "DPCA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Bosch MG1CS111 & Temic DQ381",
                "Engine_code": "DKZA",
            },
            "2.0 TSI 272hp": {
                "std_power": 272,
                "tuned_power": 350,
                "std_torque": 350,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ380",
                "Engine_code": "DNUA",
            },
            "iV 1.4 TSI 218hp": {
                "std_power": 218,
                "tuned_power": 273,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.21 & Bosch MED17.1.27 & Temic DQ400E",
                "Engine_code": "DGEB",
            },
        },
    },
    "Yeti": {
        "2009 - 2014": {
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYC",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFHF",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14",
                "Engine_code": "CFHC",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46",
                "Engine_code": "CFJA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10 & Temic DQ200",
                "Engine_code": "CBZB",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20 & Bosch MED17.5.5",
                "Engine_code": "CAXA",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5 & Temic DQ250Fxx",
                "Engine_code": "CDAA",
            },
        },
        "2014 ->": {
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CAYC",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFHC",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17CP46 & Bosch EDC17C64",
                "Engine_code": "CFJA / CEGA",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250",
                "Engine_code": "CUUB",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch EDC17C64 & Bosch EDC17C46",
                "Engine_code": "CUUA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10.22 & Temic DQ200",
                "Engine_code": "CBZB",
            },
            "1.2 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200",
                "Engine_code": "CBZB",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5",
                "Engine_code": "CAXA",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CZDA",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CAXA",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5 & Temic DQ250",
                "Engine_code": "CDAA",
            },
        },
    },
},
"Smart": {
    "City": {
        "2002 - 2004": {
            "0.8 CDI 45hp": {
                "std_power": 45,
                "tuned_power": 65,
                "std_torque": 110,
                "tuned_torque": 150,
                "cc": 799,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "OM660 DE 8LA",
            },
            "1.0 Turbo Brabus 98hp": {
                "std_power": 98,
                "tuned_power": 115,
                "std_torque": 140,
                "tuned_torque": 165,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "M451.333",
            },
            "1.0 Turbo 84hp": {
                "std_power": 84,
                "tuned_power": 110,
                "std_torque": 120,
                "tuned_torque": 160,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "M132.930",
            },
        },
    },
    "Crossblade": {
        "2002 - 2003": {
            "0.6 Turbo 70hp": {
                "std_power": 70,
                "tuned_power": 82,
                "std_torque": 108,
                "tuned_torque": 125,
                "cc": 599,
                "Type_ecu": "Bosch MEG1.0",
                "Engine_code": "M160.910",
            },
        },
    },
    "ForFour": {
        "2004 - 2014": {
            "1.5 CDI 68hp": {
                "std_power": 68,
                "tuned_power": 92,
                "std_torque": 160,
                "tuned_torque": 210,
                "cc": 1493,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "M639.939",
            },
            "1.5 DCI 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 210,
                "tuned_torque": 260,
                "cc": 1493,
                "Type_ecu": "Bosch EDC16C33",
                "Engine_code": "M639.939",
            },
            "1.5 Turbo 177hp": {
                "std_power": 177,
                "tuned_power": 180,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1468,
                "Type_ecu": "Mitsubishi MH8304F & Mitsubishi E6T42",
                "Engine_code": "M122.950",
            },
        },
        "2014 ->": {
            "0.9 Turbo 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 135,
                "tuned_torque": 155,
                "cc": 989,
                "Type_ecu": "Bosch ME17.9.20",
                "Engine_code": "M281.910",
            },
            "1.0i 61hp": {
                "std_power": 61,
                "tuned_power": 69,
                "std_torque": 91,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch ME7.7.0",
                "Engine_code": "M281.920",
            },
            "1.0i 71hp": {
                "std_power": 71,
                "tuned_power": 79,
                "std_torque": 91,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch ME17.9.20",
                "Engine_code": "M281.920",
            },
        },
    },
    "ForTwo": {
        "-> 2014": {
            "0.8 CDI 41hp": {
                "std_power": 41,
                "tuned_power": 61,
                "std_torque": 100,
                "tuned_torque": 135,
                "cc": 799,
                "Type_ecu": "Bosch EDC15C5",
                "Engine_code": "M660.950",
            },
            "0.8 CDI 45hp": {
                "std_power": 45,
                "tuned_power": 65,
                "std_torque": 110,
                "tuned_torque": 155,
                "cc": 799,
                "Type_ecu": "Bosch EDC16CP32 & Bosch EDC16C31 & Bosch EDC16C32",
                "Engine_code": "M660.950",
            },
            "0.8 CDI 54hp": {
                "std_power": 54,
                "tuned_power": 73,
                "std_torque": 130,
                "tuned_torque": 170,
                "cc": 799,
                "Type_ecu": "Bosch EDC16CP32 & Bosch EDC16C32",
                "Engine_code": "M660.951",
            },
            "0.9 Turbo 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 135,
                "tuned_torque": 175,
                "cc": 999,
                "Type_ecu": "Bosch ME17.9.20 & Bosch ME17.7.20",
                "Engine_code": "M281.910",
            },
            "1.0 Turbo Brabus 98hp": {
                "std_power": 98,
                "tuned_power": 115,
                "std_torque": 140,
                "tuned_torque": 165,
                "cc": 999,
                "Type_ecu": "Bosch ME7.7.0",
                "Engine_code": "M132.930",
            },
            "1.0 Turbo Brabus 102hp": {
                "std_power": 102,
                "tuned_power": 115,
                "std_torque": 147,
                "tuned_torque": 165,
                "cc": 999,
                "Type_ecu": "Bosch ME7.7.0 & Bosch ME17.9.20",
                "Engine_code": "M132.930",
            },
            "1.0 Turbo 84hp": {
                "std_power": 84,
                "tuned_power": 115,
                "std_torque": 120,
                "tuned_torque": 165,
                "cc": 999,
                "Type_ecu": "Bosch ME7.7.0",
                "Engine_code": "M132.930",
            },
            "1.0i 61hp": {
                "std_power": 61,
                "tuned_power": 67,
                "std_torque": 92,
                "tuned_torque": 102,
                "cc": 999,
                "Type_ecu": "Bosch ME7.7.0",
                "Engine_code": "3B21",
            },
            "1.0i 71hp": {
                "std_power": 71,
                "tuned_power": 79,
                "std_torque": 89,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch ME7.7.0",
                "Engine_code": "M132.910",
            },
            "Ultimate 112 - 112hp": {
                "std_power": 112,
                "tuned_power": 120,
                "std_torque": 150,
                "tuned_torque": 170,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2014 ->": {
            "0.8 CDI 54hp": {
                "std_power": 54,
                "tuned_power": 73,
                "std_torque": 130,
                "tuned_torque": 170,
                "cc": 799,
                "Type_ecu": "Bosch EDC16C32",
                "Engine_code": "M660.951",
            },
            "0.9 Turbo Brabus 109hp": {
                "std_power": 109,
                "tuned_power": 120,
                "std_torque": 170,
                "tuned_torque": 190,
                "cc": 999,
                "Type_ecu": "Bosch ME17.9.20",
                "Engine_code": "M128.910",
            },
            "0.9 Turbo 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 135,
                "tuned_torque": 175,
                "cc": 999,
                "Type_ecu": "Bosch ME17.9.20 & Bosch ME7.7.0 & Bosch ME17.7.20",
                "Engine_code": "M281.910",
            },
            "1.0i 61hp": {
                "std_power": 61,
                "tuned_power": 67,
                "std_torque": 92,
                "tuned_torque": 102,
                "cc": 999,
                "Type_ecu": "Bosch ME7.7.0",
                "Engine_code": "3B21",
            },
            "1.0i 71hp": {
                "std_power": 71,
                "tuned_power": 79,
                "std_torque": 89,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch ME17.9.20",
                "Engine_code": "M132.910",
            },
        },
    },
    "Roadster": {
        "2003 - 2006": {
            "0.7 Turbo Brabus 101hp": {
                "std_power": 101,
                "tuned_power": 121,
                "std_torque": 130,
                "tuned_torque": 160,
                "cc": 698,
                "Type_ecu": "Bosch MEG1.1",
                "Engine_code": "M160.923",
            },
            "0.7 Turbo 62hp": {
                "std_power": 62,
                "tuned_power": 78,
                "std_torque": 95,
                "tuned_torque": 125,
                "cc": 698,
                "Type_ecu": "Bosch MEG1.1",
                "Engine_code": "M160.921",
            },
            "0.7 Turbo 82hp": {
                "std_power": 82,
                "tuned_power": 102,
                "std_torque": 110,
                "tuned_torque": 135,
                "cc": 698,
                "Type_ecu": "Bosch MEG1.1",
                "Engine_code": "M160.922",
            },
        },
    },
},
"SsangYong": {
    "Actyon": {
        "All": {
            "2.0 Xdi 155hp": {
                "std_power": 155,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D20DTR",
            },
            "2.0 Xdi 141hp": {
                "std_power": 141,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.2",
                "Engine_code": "D20DT",
            },
            "2.0 Xdi 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.7 & Delphi DCM3.2",
                "Engine_code": "D20DT",
            },
            "2.2 e-XDI 178hp": {
                "std_power": 178,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2157,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "D22DTF",
            },
        },
    },
    "Korando": {
        "2011 - 2018": {
            "2.0 XDi 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D20DT",
            },
            "2.0 XDi 175hp": {
                "std_power": 175,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D20DT",
            },
            "2.2 e-XDI 178hp": {
                "std_power": 178,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2157,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "D22DTF",
            },
        },
        "2019 ->": {
            "1.6 e-XDI 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 T-GDI 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Kyron": {
        "All": {
            "200 XDi 141hp": {
                "std_power": 141,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.2 & Delphi DCM3.1",
                "Engine_code": "D20DT",
            },
            "270 XDi 165hp": {
                "std_power": 165,
                "tuned_power": 185,
                "std_torque": 345,
                "tuned_torque": 420,
                "cc": 2696,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D27DT",
            },
        },
    },
    "Musso": {
        "2018 - 2020": {
            "2.2 e-XDi 181hp": {
                "std_power": 181,
                "tuned_power": 220,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2157,
                "Type_ecu": "Delphi DCM6.2AP",
                "Engine_code": "",
            },
        },
    },
    "Rexton": {
        "-> 2016": {
            "2.0 XDi 155hp": {
                "std_power": 155,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D20DT",
            },
            "2.2 e-XDI 178hp": {
                "std_power": 178,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2157,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "D22DTF",
            },
            "270 XDi 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 345,
                "tuned_torque": 420,
                "cc": 2696,
                "Type_ecu": "Delphi DCM3.2 & Delphi DCM3.7 & Delphi DCM3.1",
                "Engine_code": "OM662LA",
            },
            "270 XDi 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 402,
                "tuned_torque": 460,
                "cc": 2696,
                "Type_ecu": "Delphi DCM3.2",
                "Engine_code": "OM662LA",
            },
        },
        "2017 ->": {
            "2.2 e-XDI 181hp": {
                "std_power": 181,
                "tuned_power": 220,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2157,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM3.7 & Delphi DCM6.2AP",
                "Engine_code": "D22DTF",
            },
            "2.0 e-XGDi 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Rodius": {
        "All": {
            "2.0 XDi 155hp": {
                "std_power": 155,
                "tuned_power": 200,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 1998,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "D20DT",
            },
            "2.2 e-XDI 178hp": {
                "std_power": 178,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2157,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "D22DTF",
            },
            "270 XDi 165hp": {
                "std_power": 165,
                "tuned_power": 220,
                "std_torque": 345,
                "tuned_torque": 460,
                "cc": 2696,
                "Type_ecu": "Delphi DCM3.7 & Delphi DCM3.2",
                "Engine_code": "OM662LA",
            },
        },
    },
    "Tivoli": {
        "2015 - 2019": {
            "1.6 e-XDI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1597,
                "Type_ecu": "Delphi DCM6.2 & Delphi DCM6.2AP",
                "Engine_code": "",
            },
        },
        "2020 ->": {
            "1.6 e-XDI 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 T-GDI 128hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 T-GDI 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "XLV": {
        "2016 ->": {
            "1.6 e-XDI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1597,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "",
            },
        },
    },
},
"Subaru": {
    "Ascent": {
        "2018 -> ...": {
            "2.4 Turbo 260hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "BRZ": {
        "2012 - 2016": {
            "2.0i 200hp": {
                "std_power": 200,
                "tuned_power": 215,
                "std_torque": 205,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso SH72531",
                "Engine_code": "FA20",
            },
        },
        "2017 - 2020": {
            "2.0i - 205hp": {
                "std_power": 205,
                "tuned_power": 215,
                "std_torque": 211,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso SH72531",
                "Engine_code": "FA20",
            },
        },
    },
    "Forester": {
        "-> 2008": {
            "2.0 XT 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 245,
                "tuned_torque": 283,
                "cc": 1994,
                "Type_ecu": "Denso 112300-0430 & Denso SH7058 & Denso SH7055",
                "Engine_code": "EJ205",
            },
            "2.5 Turbo 230hp": {
                "std_power": 230,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2457,
                "Type_ecu": "Denso SH7058 & Denso GW & Denso 22611AK710 112300-1520",
                "Engine_code": "EJ25S",
            },
            "2.5 Turbo 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 320,
                "tuned_torque": 360,
                "cc": 2457,
                "Type_ecu": "",
                "Engine_code": "EJ25",
            },
        },
        "2008 - 2017": {
            "2.0 D boxer 147hp": {
                "std_power": 147,
                "tuned_power": 177,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Denso KS & Denso DT & Denso SH7058 & Denso SH7059 & Denso SH72543 & Denso 275700-0101",
                "Engine_code": "EE20",
            },
            "2.5 Turbo 230hp": {
                "std_power": 230,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2457,
                "Type_ecu": "Denso GW",
                "Engine_code": "EJ25S",
            },
        },
        "2018 - 2020": {
            "2.0 D boxer 148hp": {
                "std_power": 148,
                "tuned_power": 177,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Denso 275700 & Denso SH72543",
                "Engine_code": "EE20",
            },
            "1.8 Turbo 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Impreza": {
        "2003 - 2005": {
            "2.0T WRX STi 265hp": {
                "std_power": 265,
                "tuned_power": 295,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1994,
                "Type_ecu": "Denso 68HC16",
                "Engine_code": "EJ20",
            },
            "2.0T WRX 225hp": {
                "std_power": 225,
                "tuned_power": 260,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1994,
                "Type_ecu": "Denso 68HC16 & Denso SH7055",
                "Engine_code": "EJ20",
            },
        },
        "2008 ->": {
            "2.0 D Boxer 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Denso KS & Denso DT & Denso SH7059 & Denso 25NA",
                "Engine_code": "EE20",
            },
            "2.0T 268hp": {
                "std_power": 268,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Denso SH7059 & Hitachi DEF003",
                "Engine_code": "",
            },
            "2.5T WRX STI 300hp": {
                "std_power": 300,
                "tuned_power": 330,
                "std_torque": 407,
                "tuned_torque": 467,
                "cc": 2457,
                "Type_ecu": "Denso SH7058 & Denso SH72543",
                "Engine_code": "EJ25T",
            },
            "2.5T WRX 230hp": {
                "std_power": 230,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2457,
                "Type_ecu": "Denso SH7058 CJ & Denso SH7058 GW & Denso SH72543 & Denso SH7055",
                "Engine_code": "EJ25T",
            },
        },
    },
    "Legacy": {
        "2008 - 2014": {
            "2.0 D Boxer 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Denso KS & Denso DT & Denso SH7059",
                "Engine_code": "EE20",
            },
            "2.0i 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 196,
                "tuned_torque": 218,
                "cc": 1994,
                "Type_ecu": "Denso SH7058 DM",
                "Engine_code": "EE20Z",
            },
            "2.5 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 230,
                "tuned_torque": 290,
                "cc": 2457,
                "Type_ecu": "Denso SH7058 DM",
                "Engine_code": "EJ253",
            },
            "2.5T GT 265hp": {
                "std_power": 265,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 2457,
                "Type_ecu": "Denso SH7055 & Denso SH7058 GW",
                "Engine_code": "EJ255",
            },
        },
        "2015 - 2018": {
            "2.0 D Boxer 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 1998,
                "Type_ecu": "Denso DT",
                "Engine_code": "EE20",
            },
            "3.6i 256hp": {
                "std_power": 256,
                "tuned_power": 273,
                "std_torque": 335,
                "tuned_torque": 353,
                "cc": 3630,
                "Type_ecu": "Denso SH7058 DM",
                "Engine_code": "EZ36D",
            },
        },
        "2019 ->": {
            "2.4 Turbo 260hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Levorg": {
        "2015 - 2019": {
            "1.6T GT 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1600,
                "Type_ecu": "Hitachi SH72543 & Hitachi DEF004 & Hitachi DEF003",
                "Engine_code": "FB16",
            },
            "2.0 GT-S 268hp": {
                "std_power": 268,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Denso SH7059 & Hitachi SH72543",
                "Engine_code": "FA20",
            },
        },
        "2020 ->": {
            "1.8 Turbo 174hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Liberty": {
        "2014 ->": {
            "2.0GT-S 295hp": {
                "std_power": 295,
                "tuned_power": 325,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1998,
                "Type_ecu": "Hitachi DEF003",
                "Engine_code": "FA20",
            },
            "3.6VCT 260hp": {
                "std_power": 260,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 3630,
                "Type_ecu": "Denso SH7058 DM",
                "Engine_code": "EZ36D",
            },
        },
    },
    "Outback": {
        "All": {
            "2.0 D Boxer 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Denso KS & Denso DT & Denso SH72543",
                "Engine_code": "EE20",
            },
            "2.4 Turbo 260hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Trezia": {
        "2011 ->": {
            "1.4D 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 260,
                "cc": 1364,
                "Type_ecu": "Bosch EDC17CP17 & Bosch EDC17CP07 & Bosch EDC17CP37",
                "Engine_code": "1ND-TV",
            },
        },
    },
    "Tribeca": {
        "2008 - 2010": {
            "3.6i 258hp": {
                "std_power": 258,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 3630,
                "Type_ecu": "Denso DM",
                "Engine_code": "EZ36D",
            },
        },
    },
    "WRX": {
        "2007 - 2014": {
            "2.0L 304hp": {
                "std_power": 304,
                "tuned_power": 334,
                "std_torque": 422,
                "tuned_torque": 482,
                "cc": 1994,
                "Type_ecu": "Denso SH7058 GW",
                "Engine_code": "EJ207",
            },
        },
        "2015 - 2017": {
            "2.0T 286hp": {
                "std_power": 286,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Hitachi DEF003",
                "Engine_code": "FA20F",
            },
            "2.5 STi 300hp": {
                "std_power": 300,
                "tuned_power": 330,
                "std_torque": 407,
                "tuned_torque": 467,
                "cc": 2457,
                "Type_ecu": "Denso SH7058 GW & Hitachi SH72543",
                "Engine_code": "EJ25",
            },
        },
        "2018 - 2020": {
            "2.5T 275hp": {
                "std_power": 275,
                "tuned_power": 330,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 2457,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "",
            },
        },
    },
    "XV": {
        "2012 - 2016": {
            "2.0 D Boxer 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Denso DT & Denso SH72543",
                "Engine_code": "EE20",
            },
            "2.0i 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 197,
                "tuned_torque": 216,
                "cc": 1994,
                "Type_ecu": "Denso SH7058 DM",
                "Engine_code": "FB20",
            },
        },
        "2017 ->": {
            "1.6i 114hp": {
                "std_power": 114,
                "tuned_power": 128,
                "std_torque": 150,
                "tuned_torque": 174,
                "cc": 1600,
                "Type_ecu": "Denso DZ",
                "Engine_code": "FB16",
            },
            "2.0i 156hp": {
                "std_power": 156,
                "tuned_power": 168,
                "std_torque": 196,
                "tuned_torque": 248,
                "cc": 1995,
                "Type_ecu": "Denso SH7058 DM",
                "Engine_code": "FB20B",
            },
        },
    },
},
"Suzuki": {
    "Baleno": {
        "2017 ->": {
            "1.0 Boosterjet 112hp": {
                "std_power": 112,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Bosch MED17.9.63",
                "Engine_code": "K10C",
            },
            "RS 1.0 Turbo 100hp": {
                "std_power": 100,
                "tuned_power": 120,
                "std_torque": 150,
                "tuned_torque": 190,
                "cc": 998,
                "Type_ecu": "Bosch MED17.9.63",
                "Engine_code": "K10C",
            },
        },
    },
    "Grand Vitara": {
        "1999 - 2005": {
            "2.0 TD 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "DW10",
            },
            "2.0 TD 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 260,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SID801",
                "Engine_code": "RF",
            },
            "2.0 TDI 109hp": {
                "std_power": 109,
                "tuned_power": 136,
                "std_torque": 270,
                "tuned_torque": 330,
                "cc": 1997,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "RHW",
            },
        },
        "2005 - 2012": {
            "1.9 DDiS 129hp": {
                "std_power": 129,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 370,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC16C3",
                "Engine_code": "F9Q",
            },
            "2.4i 169hp": {
                "std_power": 169,
                "tuned_power": 180,
                "std_torque": 227,
                "tuned_torque": 240,
                "cc": 2393,
                "Type_ecu": "Denso 078K0",
                "Engine_code": "J24B",
            },
        },
        "2012 ->": {
            "1.9 DDiS 129hp": {
                "std_power": 129,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 365,
                "cc": 1870,
                "Type_ecu": "Bosch EDC16C3",
                "Engine_code": "F9Q",
            },
            "2.4i 169hp": {
                "std_power": 169,
                "tuned_power": 180,
                "std_torque": 227,
                "tuned_torque": 240,
                "cc": 2393,
                "Type_ecu": "Denso 078K0",
                "Engine_code": "J24B",
            },
        },
    },
    "Ignis": {
        "All": {
            "1.3 DDiS 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD602.04",
                "Engine_code": "Z13DT",
            },
        },
    },
    "Jimny": {
        "All": {
            "1.5 D 65hp": {
                "std_power": 65,
                "tuned_power": 85,
                "std_torque": 160,
                "tuned_torque": 200,
                "cc": 1461,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "K9K",
            },
            "1.5 DDiS 86hp": {
                "std_power": 86,
                "tuned_power": 105,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1461,
                "Type_ecu": "Delphi DCM1.2",
                "Engine_code": "K9K",
            },
            "1.5i 102hp": {
                "std_power": 102,
                "tuned_power": 115,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1462,
                "Type_ecu": "Bosch ME17.9.64",
                "Engine_code": "K15B",
            },
        },
    },
    "Kizashi": {
        "2010 ->": {
            "2.4i 178hp": {
                "std_power": 178,
                "tuned_power": 190,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 2393,
                "Type_ecu": "Denso 057L1",
                "Engine_code": "J24B",
            },
        },
    },
    "Liana": {
        "All": {
            "1.4 DDiS 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 1398,
                "Type_ecu": "Delphi DDCR",
                "Engine_code": "DLD-414",
            },
        },
    },
    "SX4": {
        "All": {
            "1.6 DDiS 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 215,
                "tuned_torque": 260,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C34",
                "Engine_code": "D16AA",
            },
            "1.6 DDiS 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch ME17.8.XX & Bosch EDC17C69",
                "Engine_code": "D16AA",
            },
            "1.9 DDiS 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D1.9AA",
            },
            "2.0 DDiS 134hp": {
                "std_power": 134,
                "tuned_power": 170,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "D20AA",
            },
            "1.0 Boosterjet 112hp": {
                "std_power": 112,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 215,
                "cc": 998,
                "Type_ecu": "Bosch MED17.9.63",
                "Engine_code": "",
            },
            "1.4 Boosterjet 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1373,
                "Type_ecu": "Bosch MED17.9.63",
                "Engine_code": "",
            },
            "1.6i 107hp": {
                "std_power": 107,
                "tuned_power": 117,
                "std_torque": 145,
                "tuned_torque": 160,
                "cc": 1586,
                "Type_ecu": "Bosch ME17.8.XX & Bosch ME17.9.51 & Denso SH7058",
                "Engine_code": "M16A",
            },
            "1.6i 120hp": {
                "std_power": 120,
                "tuned_power": 130,
                "std_torque": 156,
                "tuned_torque": 170,
                "cc": 1586,
                "Type_ecu": "Bosch ME17.8.XX & Bosch ME17.9.61 & Denso SH7058",
                "Engine_code": "M16A",
            },
        },
    },
    "SX4 S-Cross": {
        "2013 - 2021": {
            "1.6 DDiS 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C49 & Bosch EDC17C69",
                "Engine_code": "D16AA",
            },
            "1.0 Boosterjet 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 215,
                "cc": 998,
                "Type_ecu": "Bosch MED17.9.63 & Bosch MED17.9.66",
                "Engine_code": "K10C",
            },
            "1.4 Boosterjet 140hp": {
                "std_power": 140,
                "tuned_power": 155,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1373,
                "Type_ecu": "Bosch MED17.9.63 & Bosch ME17.9.61",
                "Engine_code": "K14C",
            },
            "1.6i 120hp": {
                "std_power": 120,
                "tuned_power": 134,
                "std_torque": 156,
                "tuned_torque": 170,
                "cc": 1586,
                "Type_ecu": "Bosch ME17.9.61",
                "Engine_code": "M16A",
            },
        },
        "2022 -> ...": {
            "1.4 Boosterjet 129hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Splash": {
        "All": {
            "1.3 DDiS 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD602.03D",
                "Engine_code": "D13A",
            },
            "1.2i 86hp": {
                "std_power": 86,
                "tuned_power": 92,
                "std_torque": 114,
                "tuned_torque": 124,
                "cc": 1242,
                "Type_ecu": "Mitsubishi E6T900 M0 & Bosch ME17.9.64",
                "Engine_code": "K12B",
            },
            "1.2i 94hp": {
                "std_power": 94,
                "tuned_power": 100,
                "std_torque": 114,
                "tuned_torque": 124,
                "cc": 1242,
                "Type_ecu": "Bosch MED17.8.X",
                "Engine_code": "K12B",
            },
        },
    },
    "Swift": {
        "2005 - 2010": {
            "1.3 DDiS 69hp": {
                "std_power": 69,
                "tuned_power": 90,
                "std_torque": 170,
                "tuned_torque": 210,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DS.P1 & Marelli MJD602",
                "Engine_code": "D13A",
            },
            "1.6 Sport 125hp": {
                "std_power": 125,
                "tuned_power": 140,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1586,
                "Type_ecu": "Denso 79J2 & Denso SH7058",
                "Engine_code": "M16A",
            },
        },
        "2011 - 2016": {
            "1.3 DDiS 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Marelli MJD8DS.P1 & Marelli MJD602.03D & Bosch ME17.8.XX",
                "Engine_code": "D13A",
            },
            "1.6 VVT S 136hp": {
                "std_power": 136,
                "tuned_power": 145,
                "std_torque": 160,
                "tuned_torque": 170,
                "cc": 1586,
                "Type_ecu": "Bosch ME17.9.51",
                "Engine_code": "M16A",
            },
        },
        "2017 ->": {
            "1.0 Boosterjet 112hp": {
                "std_power": 112,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 215,
                "cc": 998,
                "Type_ecu": "Bosch MED17.9.63",
                "Engine_code": "K10C",
            },
            "1.2 90hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.4 Boosterjet 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1373,
                "Type_ecu": "Bosch MED17.9.63",
                "Engine_code": "K14C",
            },
            "1.4 Boosterjet 129hp": {
                "std_power": 129,
                "tuned_power": 165,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1373,
                "Type_ecu": "Bosch MG1CS026",
                "Engine_code": "K14D",
            },
        },
    },
    "Vitara": {
        "2015 - 2017": {
            "1.6 DDIS 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C69 & Bosch ME17.9.61",
                "Engine_code": "D16AA",
            },
            "1.4 Boosterjet 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1373,
                "Type_ecu": "Bosch MED17.9.63",
                "Engine_code": "K14C",
            },
        },
        "2018 ->": {
            "1.0 Boosterjet 112hp": {
                "std_power": 112,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 230,
                "cc": 998,
                "Type_ecu": "Bosch MED17.9.63 & Bosch MED17.9.66",
                "Engine_code": "K10C",
            },
            "1.4 Boosterjet 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1373,
                "Type_ecu": "Bosch MED17.9.63",
                "Engine_code": "K14C",
            },
            "1.4 Boosterjet 129hp": {
                "std_power": 129,
                "tuned_power": 155,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1373,
                "Type_ecu": "Bosch MG1CS026",
                "Engine_code": "K14D",
            },
        },
    },
    "Wagon R": {
        "All": {
            "1.3 DDiS 69hp": {
                "std_power": 69,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 215,
                "cc": 1248,
                "Type_ecu": "Marelli MJD602.04 & Denso SH72531",
                "Engine_code": "G13BB",
            },
        },
    },
    "XL7": {
        "2007 - 2009": {
            "3.6 V6 252hp": {
                "std_power": 252,
                "tuned_power": 270,
                "std_torque": 329,
                "tuned_torque": 350,
                "cc": 3564,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "LY7",
            },
        },
    },
},
"Toyota": {
    "4Runner": {
        "N280 - 2009 ->": {
            "4.0-V6 245hp": {
                "std_power": 245,
                "tuned_power": 275,
                "std_torque": 380,
                "tuned_torque": 440,
                "cc": 3955,
                "Type_ecu": "Denso 275035-5920 (CAN) & Denso 76F00XX",
                "Engine_code": "1GR-FE",
            },
        },
    },
    "Auris": {
        "2006 - 2012": {
            "1.4 D4-D 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Bosch EDC17CP17 & Bosch EDC16C31 & Bosch EDC17CP37 & Bosch EDC17CP07 & Bosch EDC16C10",
                "Engine_code": "1ND-TV",
            },
            "2.0 D4-D 128hp": {
                "std_power": 128,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1998,
                "Type_ecu": "Denso 896xx",
                "Engine_code": "1AD-FTV",
            },
            "2.0 D4-D 126hp": {
                "std_power": 126,
                "tuned_power": 150,
                "std_torque": 310,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Denso 175822-762 (CAN) & Denso 76F00XX",
                "Engine_code": "1AD-FTV",
            },
            "2.2 D4-D 177hp": {
                "std_power": 177,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 175822-733 (CAN)",
                "Engine_code": "2AD-FHV",
            },
        },
        "2012 - 2015": {
            "1.4 D4-D 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Bosch EDC17CP17 & Bosch EDC17CP37 & Bosch EDC17C47",
                "Engine_code": "1ND-TV",
            },
            "1.6 D4-D 112hp": {
                "std_power": 112,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C73",
                "Engine_code": "N47C16A",
            },
            "2.0 D4-D 124hp": {
                "std_power": 124,
                "tuned_power": 150,
                "std_torque": 310,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Denso 896xx",
                "Engine_code": "1AD-FTV",
            },
        },
        "2015 - 2018": {
            "1.6 D4D 112hp": {
                "std_power": 112,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C73",
                "Engine_code": "1WW",
            },
            "1.2T 8v 116hp": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 185,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Denso 896xx",
                "Engine_code": "8NR-FTS",
            },
        },
    },
    "Avensis": {
        "2003 - 2009": {
            "2.0 D-4D 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "1AD-FTV",
            },
            "2.0 D-4D 126hp": {
                "std_power": 126,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "Denso 175822-762 (K-Line) & Denso 896xx & Denso 175800-9501",
                "Engine_code": "1AD-FTV",
            },
            "2.2 D-4D dcat 177hp": {
                "std_power": 177,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 175822-762 (K-Line)",
                "Engine_code": "2AD-FHV",
            },
            "2.2 D-4D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 310,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 175822-762 (K-Line) & Denso 175800-9410 & Denso 896xx & Denso 275922-022 (CAN)",
                "Engine_code": "2AD-FHV",
            },
        },
        "2009 - 2015": {
            "2.0 D-4D 126hp": {
                "std_power": 126,
                "tuned_power": 150,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "Denso 896xx",
                "Engine_code": "1AD-FTV",
            },
            "2.0 D-4D 124hp": {
                "std_power": 124,
                "tuned_power": 150,
                "std_torque": 310,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Denso 275922-022 (CAN)",
                "Engine_code": "1AD-FTV",
            },
            "2.2 D-4D dcat 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 896xx",
                "Engine_code": "2AD-FHV",
            },
            "2.2 D-4D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 896xx",
                "Engine_code": "2AD-FHV",
            },
        },
        "2015 ->": {
            "1.6 D-4D 112hp": {
                "std_power": 112,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C73",
                "Engine_code": "N47C16A",
            },
            "2.0 D-4D 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C73",
                "Engine_code": "2WW",
            },
        },
    },
    "Aygo": {
        "All": {
            "1.4 D-4D 55hp": {
                "std_power": 55,
                "tuned_power": 80,
                "std_torque": 130,
                "tuned_torque": 180,
                "cc": 1389,
                "Type_ecu": "Siemens/Continental SID804 & Siemens/Continental SID805 & Bosch EDC17CP07",
                "Engine_code": "1ND-TV",
            },
            "1.0i 68hp": {
                "std_power": 68,
                "tuned_power": 76,
                "std_torque": 93,
                "tuned_torque": 107,
                "cc": 998,
                "Type_ecu": "Bosch ME7.9.52 & Bosch ME17.9 & Bosch ME17.9.53 & Bosch M7.9.5 & Bosch ME7.9.51",
                "Engine_code": "1KR-FE",
            },
        },
    },
    "C-HR": {
        "2016 ->": {
            "1.2T 116hp": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 185,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Denso 896xx",
                "Engine_code": "8NR",
            },
        },
    },
    "Corolla": {
        "2002 - 2007": {
            "1.4 D-4D 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC17CP07 & Bosch EDC16C10",
                "Engine_code": "1ND-TV",
            },
            "2.0 D-4D 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "1AD-FTV",
            },
            "2.0 D-4D 126hp": {
                "std_power": 126,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1998,
                "Type_ecu": "Denso 175822-762 (CAN)",
                "Engine_code": "1AD-FTV",
            },
            "2.2 D-4D dcat 177hp": {
                "std_power": 177,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 175822-762",
                "Engine_code": "2AD-FHV",
            },
            "2.2 D-4D 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 310,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 175822-762",
                "Engine_code": "2AD-FTV",
            },
            "2.2 D-4D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 89663-0F091",
                "Engine_code": "2AD-FHV",
            },
        },
        "2007 - 2011": {
            "1.4 D-4D 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Bosch EDC17CP07",
                "Engine_code": "1ND-TV",
            },
            "2.0 D-4D 126hp": {
                "std_power": 126,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 370,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "1AD-FTV",
            },
        },
        "2019 ->": {
            "1.2 Turbo 116hp": {
                "std_power": 116,
                "tuned_power": 140,
                "std_torque": 185,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Denso NEC76F00xx",
                "Engine_code": "8NR-FTS",
            },
            "1.8 Hybrid 122hp": {
                "std_power": 122,
                "tuned_power": 128,
                "std_torque": 142,
                "tuned_torque": 160,
                "cc": 1798,
                "Type_ecu": "Denso 057L1 & Denso P5 & Denso D76F019XXX",
                "Engine_code": "2ZR-FXE",
            },
            "2.0 Hybrid 180hp": {
                "std_power": 180,
                "tuned_power": 195,
                "std_torque": 190,
                "tuned_torque": 210,
                "cc": 1987,
                "Type_ecu": "Denso R7F701202",
                "Engine_code": "M20-FXS",
            },
            "GR 1.6T 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 370,
                "tuned_torque": 400,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "G16-GTS",
            },
        },
    },
    "Fortuner": {
        "2015 - 2019": {
            "2.4d 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2393,
                "Type_ecu": "Denso NEC76F00xx",
                "Engine_code": "2GDFTV",
            },
            "2.8d 177hp": {
                "std_power": 177,
                "tuned_power": 207,
                "std_torque": 420,
                "tuned_torque": 520,
                "cc": 2755,
                "Type_ecu": "Denso NEC76F00xx & Denso R7F701202",
                "Engine_code": "1GD-FTV",
            },
        },
        "2020 ->": {
            "2.8d 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "GT86": {
        "2012 - 2016": {
            "2.0i 200hp": {
                "std_power": 200,
                "tuned_power": 215,
                "std_torque": 205,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso SH72531",
                "Engine_code": "4U-GSE  /  FA20",
            },
        },
        "2016 - 2020": {
            "2.0i - 205hp": {
                "std_power": 205,
                "tuned_power": 220,
                "std_torque": 205,
                "tuned_torque": 220,
                "cc": 1998,
                "Type_ecu": "Denso SH72531",
                "Engine_code": "4U-GSE  /  FA20",
            },
        },
        "2021 -> ...": {
            "2.4i 234hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Hiace": {
        "2001 - 2006": {
        },
        "2006 - 2011": {
            "3.0 D-4D 170hp": {
                "std_power": 170,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 480,
                "cc": 2982,
                "Type_ecu": "Denso 275922-532 & Denso 275900-3091",
                "Engine_code": "1KD-FTV",
            },
            "3.0 D-4D 125hp": {
                "std_power": 125,
                "tuned_power": 160,
                "std_torque": 315,
                "tuned_torque": 350,
                "cc": 2982,
                "Type_ecu": "Denso 89661-26E80 & Denso 89661-26E70",
                "Engine_code": "2RZ-E",
            },
            "2.7 VVT-I 160hp": {
                "std_power": 160,
                "tuned_power": 180,
                "std_torque": 240,
                "tuned_torque": 260,
                "cc": 2693,
                "Type_ecu": "Denso 2751361910",
                "Engine_code": "2TR-FE",
            },
        },
        "2019 -> ...": {
            "2.8 D-4D 154hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.8 D-4D 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.8 D-4D 177hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Hilux": {
        "2005 - 2015": {
            "2.5 D-4D 102hp": {
                "std_power": 102,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 2494,
                "Type_ecu": "Denso 175822-666 (K-Line) & Denso 175822-887 & Denso 175800-4330",
                "Engine_code": "2KD-FTV",
            },
            "2.5 D-4D 144hp": {
                "std_power": 144,
                "tuned_power": 175,
                "std_torque": 343,
                "tuned_torque": 410,
                "cc": 2494,
                "Type_ecu": "Denso 275922-022 (CAN) & Denso 175822-887 & Denso 275900-3054 & Denso 76F00XX",
                "Engine_code": "2KD-FTV",
            },
            "2.5 D-4D 120hp": {
                "std_power": 120,
                "tuned_power": 175,
                "std_torque": 325,
                "tuned_torque": 410,
                "cc": 2494,
                "Type_ecu": "Denso 175822-887 (CAN) & Denso 175822-666 & Denso 17500-9792",
                "Engine_code": "2KD-FTV",
            },
            "3.0 D-4D 171hp": {
                "std_power": 171,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 460,
                "cc": 2982,
                "Type_ecu": "Denso 175822-666 (K-Line) & Denso 175822-887 (CAN) & Denso 175822-887 (K-Line) & Denso 275922-022 & Denso 89661-0KN40 & Denso 9663-0KR50 & Denso 76F00XX & Denso 275922-532 & Denso 175800-8772 & Denso 275900-3681",
                "Engine_code": "1KD-FTV",
            },
            "3.0 D-4D 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 343,
                "tuned_torque": 440,
                "cc": 2982,
                "Type_ecu": "Denso 275922-532 & Denso RX703200",
                "Engine_code": "1KD-FTV",
            },
        },
        "2015 - 2020": {
            "2.4 D-4D 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2393,
                "Type_ecu": "Denso 896xx & Denso P5 & Denso 76F00XX & Denso D76F019XXX",
                "Engine_code": "2GD-FTV",
            },
            "2.5 D-4D 144hp": {
                "std_power": 144,
                "tuned_power": 175,
                "std_torque": 343,
                "tuned_torque": 410,
                "cc": 2494,
                "Type_ecu": "Denso 275922-022 (CAN) & Denso 896xx & Denso 276100-0771",
                "Engine_code": "2KD-FTV",
            },
            "2.8 D-4D 177hp": {
                "std_power": 177,
                "tuned_power": 207,
                "std_torque": 420,
                "tuned_torque": 520,
                "cc": 2755,
                "Type_ecu": "Denso NEC76F00xx & Denso R7F701202 & Denso D76F019XXX & Denso 89666-0kx10",
                "Engine_code": "1GD-FTV",
            },
            "3.0 D-4D 171hp": {
                "std_power": 171,
                "tuned_power": 210,
                "std_torque": 360,
                "tuned_torque": 460,
                "cc": 2982,
                "Type_ecu": "Denso NEC76F00xx & Denso 175822-887 & Denso 275922-022",
                "Engine_code": "1KD-FTV",
            },
            "4.0-VVTi 280hp": {
                "std_power": 280,
                "tuned_power": 310,
                "std_torque": 375,
                "tuned_torque": 435,
                "cc": 3956,
                "Type_ecu": "Denso 275035-5920 (CAN)",
                "Engine_code": "1GR-FE",
            },
        },
        "2020 ->": {
            "2.4d 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2393,
                "Type_ecu": "Denso R7F701202 & Denso P5",
                "Engine_code": "2GD-FTV",
            },
            "2.8d 204hp": {
                "std_power": 204,
                "tuned_power": 225,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 2755,
                "Type_ecu": "Denso RF7X & Denso NEC D76F0xxx & Denso 89661-FA650",
                "Engine_code": "1GD-FTV",
            },
        },
    },
    "IQ": {
        "2009 ->": {
            "1.4 D-4D 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Bosch EDC17CP17 & Bosch EDC17CP07 & Bosch EDC17CP37",
                "Engine_code": "1ND-TV",
            },
            "1.0 VVT-i 68hp": {
                "std_power": 68,
                "tuned_power": 80,
                "std_torque": 91,
                "tuned_torque": 104,
                "cc": 998,
                "Type_ecu": "Denso 89663-7406 89663-74062",
                "Engine_code": "1KR-FE",
            },
            "1.3 VVT-I 98hp": {
                "std_power": 98,
                "tuned_power": 105,
                "std_torque": 125,
                "tuned_torque": 135,
                "cc": 1329,
                "Type_ecu": "Denso 275036-5280 (CAN)",
                "Engine_code": "1NR-FE",
            },
        },
    },
    "Landcruiser": {
        "2002 - 2010": {
            "3.0 D-4D 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 343,
                "tuned_torque": 405,
                "cc": 2982,
                "Type_ecu": "Denso 175822-666 & Denso 175800-7243",
                "Engine_code": "1KD-FTV",
            },
            "3.0 D-4D 166hp": {
                "std_power": 166,
                "tuned_power": 196,
                "std_torque": 410,
                "tuned_torque": 470,
                "cc": 2982,
                "Type_ecu": "Denso 175822-887 (CAN) & Denso 175822-666 & Denso 175800-7244",
                "Engine_code": "1KD-FTV",
            },
            "3.0 D-4D 173hp": {
                "std_power": 173,
                "tuned_power": 205,
                "std_torque": 410,
                "tuned_torque": 485,
                "cc": 2982,
                "Type_ecu": "Denso 896xx & Denso 175822-887 & Denso 76F00XX",
                "Engine_code": "1KD-FTV",
            },
            "4.5 D4-D 286hp": {
                "std_power": 286,
                "tuned_power": 330,
                "std_torque": 650,
                "tuned_torque": 750,
                "cc": 4461,
                "Type_ecu": "Denso 275922-001 (CAN) & Denso RX703200 & Denso 76F00XX",
                "Engine_code": "1VD-FTV",
            },
        },
        "2010 - 2013": {
            "3.0 D-4D 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2982,
                "Type_ecu": "Denso 275922-022 (CAN) & Denso 76F00XX",
                "Engine_code": "1KD-FTV",
            },
            "4.0 V6 280hp": {
                "std_power": 280,
                "tuned_power": 320,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 3956,
                "Type_ecu": "Denso 2751361910 & Denso 76F00XX & Denso 275036-0360",
                "Engine_code": "1GR-FE",
            },
        },
        "2013 - 2020": {
            "2.8 D-4D 177hp": {
                "std_power": 177,
                "tuned_power": 210,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 2755,
                "Type_ecu": "Denso 899XX & Denso 896xx & Denso D76F019XXX",
                "Engine_code": "1GD-FTV",
            },
            "3.0 D-4D 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 420,
                "tuned_torque": 510,
                "cc": 2982,
                "Type_ecu": "Denso 275922-022 (CAN)",
                "Engine_code": "1KD-FTV",
            },
            "4.5 D-4D V8 272hp": {
                "std_power": 272,
                "tuned_power": 320,
                "std_torque": 650,
                "tuned_torque": 750,
                "cc": 3361,
                "Type_ecu": "Denso 275922-001 (CAN) & Denso 275922-092 (CAN) & Denso 76F00XX & Denso 896xx",
                "Engine_code": "1VD-FTV",
            },
            "2.8 D-4D 204hp": {
                "std_power": 204,
                "tuned_power": 225,
                "std_torque": 420,
                "tuned_torque": 550,
                "cc": 2755,
                "Type_ecu": "Denso 896xx",
                "Engine_code": "",
            },
            "4.6 VVTi 320hp": {
                "std_power": 320,
                "tuned_power": 360,
                "std_torque": 460,
                "tuned_torque": 520,
                "cc": 4608,
                "Type_ecu": "Denso 276800-580 & Denso NEC76F00xx",
                "Engine_code": "1GR-FE",
            },
            "5.7 VVTi 380hp": {
                "std_power": 380,
                "tuned_power": 410,
                "std_torque": 545,
                "tuned_torque": 605,
                "cc": 5663,
                "Type_ecu": "Denso 275036-5920",
                "Engine_code": "3UR-FE",
            },
        },
        "2021 -> ...": {
            "3.3 D-4D V6 309hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5 Twin Turbo V6 415hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Previa": {
        "All": {
            "2.0 D-4D 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "1CD-FTV",
            },
        },
    },
    "ProAce": {
        "2014 - 2016": {
            "1.6 D-4D 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 180,
                "tuned_torque": 260,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C10",
                "Engine_code": "9H07",
            },
            "2.0 D-4D 128hp": {
                "std_power": 128,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM3.5",
                "Engine_code": "RH02",
            },
            "2.0 D-4D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 370,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A",
                "Engine_code": "4WZ-FTV",
            },
        },
        "2016 - 2019": {
            "1.5 D-4D 102hp Euro6": {
                "std_power": 102,
                "tuned_power": 160,
                "std_torque": 270,
                "tuned_torque": 350,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C60 & Bosch MD1CS003",
                "Engine_code": "",
            },
            "1.6 D-4D 115hp Euro6": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "3WZ-HV",
            },
            "1.6 D-4D 95hp Euro6": {
                "std_power": 95,
                "tuned_power": 125,
                "std_torque": 210,
                "tuned_torque": 270,
                "cc": 1560,
                "Type_ecu": "Delphi DCM6.2A & Bosch EDC17C60",
                "Engine_code": "3WZ-TV",
            },
            "2.0 D-4D 120hp Euro6": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1A",
                "Engine_code": "4WZ-FTV",
            },
            "2.0 D-4D 150hp Euro6": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 370,
                "tuned_torque": 430,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1A",
                "Engine_code": "4WZ-FTV",
            },
            "2.0 D-4D 180hp Euro6": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM6.2A & Delphi DCM7.1A",
                "Engine_code": "4WZ-FHV",
            },
        },
        "2020 ->": {
            "1.5 D-4D 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "",
            },
            "1.5 D-4D 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "",
            },
            "2.0 D-4D 122hp": {
                "std_power": 122,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "",
            },
            "2.0 D-4D 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 370,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "",
            },
            "2.0 D-4D 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "",
            },
        },
    },
    "Proace City": {
        "2019 ->": {
            "1.5 D-4D 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 D-4D 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "",
            },
            "1.5 D-4D 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1197,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1197,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "",
            },
        },
    },
    "Rav4": {
        "2005 - 2013": {
            "2.2 D-4D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 275922-022 & Denso 275900-2601 & Denso 76F00XX & Denso 275900-0342 & Denso 175822-773 & Denso 275900-5200",
                "Engine_code": "2AD-FTV",
            },
            "2.2 D-4D 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 310,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 175822-733 (CAN) & Denso 175800-4030",
                "Engine_code": "2AD-FTV",
            },
            "2.2 D-Cat 177hp": {
                "std_power": 177,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 896xx & Denso 76F00XX & Denso 175822-773",
                "Engine_code": "2AD-FTV",
            },
        },
        "2013 - 2015": {
            "2.0 D-4D 124hp": {
                "std_power": 124,
                "tuned_power": 150,
                "std_torque": 310,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Denso 275922-022 & Denso 275922-500",
                "Engine_code": "1AD-FTV",
            },
            "2.2 D-4D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 275922-022 & Denso 76F00XX",
                "Engine_code": "2AD-FTV",
            },
        },
        "2016 ->": {
            "2.0 D4D 143hp": {
                "std_power": 143,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1995,
                "Type_ecu": "Bosch EDC17C43 & Bosch EDC17C73",
                "Engine_code": "2WW",
            },
            "2.0 VVT-i 152hp": {
                "std_power": 152,
                "tuned_power": 167,
                "std_torque": 195,
                "tuned_torque": 221,
                "cc": 1987,
                "Type_ecu": "Denso R7F701202",
                "Engine_code": "3ZR-FAE",
            },
        },
    },
    "Supra": {
        "2019 ->": {
            "2.0T 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1998,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS003",
                "Engine_code": "",
            },
            "3.0T 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 2998,
                "Type_ecu": "Bosch MG1CS201 & Bosch MG1CS024",
                "Engine_code": "B58B30",
            },
        },
    },
    "Tundra": {
        "2015 - 2020": {
            "4.6L V8 310hp": {
                "std_power": 310,
                "tuned_power": 340,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 4608,
                "Type_ecu": "",
                "Engine_code": "1UR-FE",
            },
            "5.7L V8 380hp": {
                "std_power": 380,
                "tuned_power": 410,
                "std_torque": 540,
                "tuned_torque": 600,
                "cc": 5663,
                "Type_ecu": "Denso 275036-3560 & Denso 76F00XX",
                "Engine_code": "3UR-FE",
            },
        },
        "2021 -> ...": {
            "3.5 V6 Bi-Turbo Hybrid 443hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "3.5 V6 Bi-Turbo 395hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Urban Cruiser": {
        "All": {
            "1.4 D-4D 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Bosch EDC17CP17 & Bosch EDC17CP07 & Bosch EDC17CP37",
                "Engine_code": "1ND-TV",
            },
        },
    },
    "Verso": {
        "2007 - 2009": {
            "2.2 D-4D dcat 177hp": {
                "std_power": 177,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 896xx",
                "Engine_code": "2AD-FHV",
            },
            "2.2 D-4D 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 310,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso NEC76F00xx",
                "Engine_code": "2AD-FTV",
            },
        },
        "2009 - 2013": {
            "2.0 D-4D 126hp": {
                "std_power": 126,
                "tuned_power": 150,
                "std_torque": 310,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Denso 275922-022 & Denso 89663-0F140",
                "Engine_code": "1AD-FTV",
            },
            "2.2 D-4D dcat 177hp": {
                "std_power": 177,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 275922-022",
                "Engine_code": "2AD-FHV",
            },
            "2.2 D-4D 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "Denso 275922-022 & Denso 896xx",
                "Engine_code": "2AD-FTV",
            },
            "2.2 D-4D 136hp": {
                "std_power": 136,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 440,
                "cc": 2231,
                "Type_ecu": "",
                "Engine_code": "2AD-FTV",
            },
        },
        "2014 ->": {
            "1.6 D-4D 112hp": {
                "std_power": 112,
                "tuned_power": 150,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C73",
                "Engine_code": "1WW",
            },
            "2.0 D4-D 126hp": {
                "std_power": 126,
                "tuned_power": 150,
                "std_torque": 310,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Denso 275922-022",
                "Engine_code": "1AD-FTV",
            },
        },
    },
    "Verso S": {
        "2011 ->": {
            "1.4 D-4D 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 205,
                "tuned_torque": 260,
                "cc": 1364,
                "Type_ecu": "Bosch EDC17CP17",
                "Engine_code": "1ND-TV",
            },
        },
    },
    "Yaris": {
        "All": {
            "1.4 D-4D 55hp": {
                "std_power": 55,
                "tuned_power": 80,
                "std_torque": 130,
                "tuned_torque": 180,
                "cc": 1364,
                "Type_ecu": "Bosch EDC15C9",
                "Engine_code": "1ND-TV",
            },
            "1.4 D-4D 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Bosch EDC17C47 & Bosch EDC17CP07 & Bosch EDC17CP37 & Bosch EDC16C31 & Bosch EDC17CP09 & Bosch EDC16C10",
                "Engine_code": "1ND-TV",
            },
            "1.4 D-4D 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 180,
                "tuned_torque": 240,
                "cc": 1364,
                "Type_ecu": "Bosch EDC15C9",
                "Engine_code": "1ND-TV",
            },
            "1.0i 68hp": {
                "std_power": 68,
                "tuned_power": 76,
                "std_torque": 90,
                "tuned_torque": 105,
                "cc": 998,
                "Type_ecu": "Bosch ME7.9.52 & Bosch ME7.9.51 & Denso 89561-b1060",
                "Engine_code": "1KR-FE",
            },
            "GR 1.6T": {
                "std_power": 261,
                "tuned_power": 281,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 1618,
                "Type_ecu": "Denso R7F701216",
                "Engine_code": "G16E-GTS",
            },
        },
    },
},
"Vauxhall": {
    "Adam": {
        "2013 ->": {
            "1.0T 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 166,
                "tuned_torque": 220,
                "cc": 999,
                "Type_ecu": "Delco E80",
                "Engine_code": "B10XFL",
            },
            "1.0T 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 166,
                "tuned_torque": 220,
                "cc": 999,
                "Type_ecu": "Delco E83 & Delco E80",
                "Engine_code": "B10XFT",
            },
            "S 1.4 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 178,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1364,
                "Type_ecu": "",
                "Engine_code": "A14XER",
            },
        },
    },
    "Agila": {
        "2003 - 2008": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 96,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "D13A",
            },
        },
    },
    "Antara": {
        "2007 - 2011": {
            "2.0 CDTI 126hp": {
                "std_power": 126,
                "tuned_power": 185,
                "std_torque": 295,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "B20DTH",
            },
            "2.0 CDTI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1991,
                "Type_ecu": "Bosch EDC16C39",
                "Engine_code": "B20DTH",
            },
            "2.4i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 220,
                "tuned_torque": 235,
                "cc": 2405,
                "Type_ecu": "Bosch ME7.9.9",
                "Engine_code": "Z24XE",
            },
            "3.2i V6 227hp": {
                "std_power": 227,
                "tuned_power": 240,
                "std_torque": 297,
                "tuned_torque": 317,
                "cc": 3195,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "10HM",
            },
        },
        "2011 - 2013": {
            "2.0 CDTi 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1991,
                "Type_ecu": "",
                "Engine_code": "B20DTH",
            },
            "2.2 CDTi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 2231,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "A22DM",
            },
            "2.2 CDTi 184hp": {
                "std_power": 184,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 2231,
                "Type_ecu": "",
                "Engine_code": "A22DM",
            },
        },
    },
    "Astra": {
        "(G) - 1998 - 2004": {
            "1.7 DTI 16v 75hp": {
                "std_power": 75,
                "tuned_power": 104,
                "std_torque": 165,
                "tuned_torque": 214,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Y17DTH",
            },
            "2.0 DI 82hp": {
                "std_power": 82,
                "tuned_power": 107,
                "std_torque": 185,
                "tuned_torque": 235,
                "cc": 1995,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "XZ20DTL",
            },
            "2.0 DTI 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1995,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "Y20DTH",
            },
            "2.2 DTI 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 320,
                "cc": 2172,
                "Type_ecu": "Bosch PSG16",
                "Engine_code": "Y22DTR",
            },
            "1.7 CDTi 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 170,
                "tuned_torque": 230,
                "cc": 1686,
                "Type_ecu": "Bosch EDC16C9",
                "Engine_code": "Y17DTH",
            },
            "2.0T OPC 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "Bosch ME1.5.5",
                "Engine_code": "Z20LET",
            },
            "2.0i 16v 136hp": {
                "std_power": 136,
                "tuned_power": 146,
                "std_torque": 188,
                "tuned_torque": 203,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SIMTEC 70",
                "Engine_code": "X20XEV",
            },
        },
        "(H) - 2004 - 2009": {
            "1.3 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli 602 & Marelli 603",
                "Engine_code": "Z13DTH",
            },
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 295,
                "cc": 1686,
                "Type_ecu": "Bosch EDC16C9",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Denso D0700",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Denso D0700",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 80hp": {
                "std_power": 80,
                "tuned_power": 105,
                "std_torque": 170,
                "tuned_torque": 230,
                "cc": 1686,
                "Type_ecu": "Bosch EDC16C9",
                "Engine_code": "Z17DTH",
            },
            "1.9 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTL",
            },
            "1.9 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTL",
            },
            "1.9 CDTi 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTL",
            },
            "1.4i 90hp": {
                "std_power": 90,
                "tuned_power": 100,
                "std_torque": 125,
                "tuned_torque": 140,
                "cc": 1364,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z16XEP",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z16LET",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Delco MT35E",
                "Engine_code": "C16SE",
            },
            "1.6i 16v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental SIMTEC 75.1",
                "Engine_code": "C16SE",
            },
            "2.0 Turbo OPC 240hp": {
                "std_power": 240,
                "tuned_power": 275,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "Z20LET",
            },
            "2.0 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z20LET",
            },
            "2.0 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 262,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z20LET",
            },
        },
        "(J) - 2009 - 2015": {
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "",
                "Engine_code": "B14NET",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "B14NET",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Delco E39",
                "Engine_code": "A16SHT",
            },
        },
        "(K) - 2015 - 2018": {
            "1.6 CDTi Bi-Turbo 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.0 Turbo 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 220,
                "cc": 999,
                "Type_ecu": "Delco E80",
                "Engine_code": "B10XFT",
            },
            "1.4 Turbo 125hp": {
                "std_power": 125,
                "tuned_power": 180,
                "std_torque": 245,
                "tuned_torque": 290,
                "cc": 1398,
                "Type_ecu": "Delco E80 & Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 245,
                "tuned_torque": 290,
                "cc": 1398,
                "Type_ecu": "Delco E80",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E80",
                "Engine_code": "Z16LET",
            },
        },
        "(K) - 2019 - 2021": {
            "1.5 CDTi 105hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 CDTi 122hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 195,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "F12SHL",
            },
            "1.2T 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 225,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "F12SHT",
            },
            "1.2T 145hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.4T 145hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "(L) - 2022 -> ...": {
            "1.5 CDTi 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6T Hybrid 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6T Hybrid 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Cascada": {
        "2013 ->": {
            "2.0 CDTi Bi-Turbo 195hp": {
                "std_power": 195,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP47",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 165hp": {
                "std_power": 165,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delco E39A & Delco E80",
                "Engine_code": "Z16LET",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "Z16LET",
            },
        },
    },
    "Combo": {
        "(C) - 2001 - 2011": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 225,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "Y13DT",
            },
            "1.3 CDTi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1248,
                "Type_ecu": "Marelli MJD603.S3 & Marelli MJD602.C1",
                "Engine_code": "Y13DT",
            },
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 305,
                "cc": 1686,
                "Type_ecu": "Denso DEC30",
                "Engine_code": "Z17DTL",
            },
            "1.7 DI 65hp": {
                "std_power": 65,
                "tuned_power": 85,
                "std_torque": 130,
                "tuned_torque": 180,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Z17DTL",
            },
            "1.7 DTI 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 165,
                "tuned_torque": 215,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Z17DTL",
            },
        },
        "(D) - 2012 - 2018": {
            "1.3 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJ8F3H & Bosch EDC17C49 & Bosch EDC16C39 & Marelli MJD9DF & Marelli MJD8 & Marelli MJD8F3.D1",
                "Engine_code": "",
            },
            "1.6 CDTi 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 290,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C69 & Bosch EDC17C49 & Bosch MD1CS003",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 290,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C60 & Bosch EDC17C49",
                "Engine_code": "B16DTH",
            },
            "2.0 CDTi 135hp": {
                "std_power": 135,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC17C49",
                "Engine_code": "B16DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 206,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78 & Bosch MED17.3.0",
                "Engine_code": "A14NET",
            },
        },
        "(E) - 2019 ->": {
            "1.5 BlueHDI 102hp": {
                "std_power": 102,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1499,
                "Type_ecu": "Bosch EDC17C60 & Bosch MD1CS003",
                "Engine_code": "D15DT / F15DT",
            },
            "1.5 BlueHDI 131hp": {
                "std_power": 131,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DTH / F15DTH",
            },
            "1.5 BlueHDI 75hp": {
                "std_power": 75,
                "tuned_power": 125,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T Puretech 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "EB2DT",
            },
            "1.2T Puretech 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "EB2DT",
            },
        },
    },
    "Corsa": {
        "(C) - 2000 - 2006": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 225,
                "cc": 1248,
                "Type_ecu": "Marelli MJ6F3",
                "Engine_code": "Z12DT",
            },
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 305,
                "cc": 1686,
                "Type_ecu": "Denso DEC30",
                "Engine_code": "Z17DTH",
            },
            "1.7 DTI 65hp": {
                "std_power": 65,
                "tuned_power": 95,
                "std_torque": 130,
                "tuned_torque": 180,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Y17DTL",
            },
            "1.7 DTI 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 165,
                "tuned_torque": 215,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Y17DT",
            },
        },
        "(D) - 2006 - 2015": {
            "1.3 CDTI 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1248,
                "Type_ecu": "Marelli MJD603.S3 & Marelli MJD602.M1 & Marelli MJD603.S1",
                "Engine_code": "Z13DTH",
            },
            "1.3 CDTi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 225,
                "cc": 1248,
                "Type_ecu": "Marelli MJD603.S3 & Bosch EDC17C08 & Bosch EDC17C59 & Bosch EDC17C18 & Delco E98",
                "Engine_code": "Z13DTH",
            },
            "1.3 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C18 & Bosch EDC17C59 & Delco E98 & Bosch EDC17C19",
                "Engine_code": "Z13DTH",
            },
            "1.3 CDTi 95hp (>2012)": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C18 & Bosch EDC17C59",
                "Engine_code": "Z13DTH",
            },
            "1.7 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Denso D0700",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 165,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "Z17DTH",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 175,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 210,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.3 & Bosch ME7.6.4",
                "Engine_code": "Z16LEL",
            },
            "1.6 turbo 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.2 & Bosch ME7.6.3",
                "Engine_code": "Z16LET",
            },
            "OPC - 1.6 Turbo - Nurburgring 210hp": {
                "std_power": 210,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.3 & Bosch ME7.6.4 & Bosch ME7.6.2",
                "Engine_code": "Z16LER",
            },
            "OPC - 1.6 Turbo 192hp": {
                "std_power": 192,
                "tuned_power": 215,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "Z16LER",
            },
        },
        "(E) - 2015 - 2019": {
            "1.3 CDTi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 190,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Delco E98",
                "Engine_code": "B13DTC",
            },
            "1.3 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Delco E98",
                "Engine_code": "B13DTE",
            },
            "1.0 Turbo 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 215,
                "cc": 999,
                "Type_ecu": "Delco E80",
                "Engine_code": "B10XFT",
            },
            "1.0 Turbo 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 170,
                "tuned_torque": 215,
                "cc": 999,
                "Type_ecu": "Delco E80",
                "Engine_code": "B10XFT",
            },
            "1.4 Turbo 100hp": {
                "std_power": 100,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78 & Delco E83",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 230,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 270,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "B14NEH",
            },
            "1.6 T OPC 207hp": {
                "std_power": 207,
                "tuned_power": 220,
                "std_torque": 245,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "B16LER",
            },
        },
        "(F) - 2019 ->": {
            "1.5 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "DV5DR",
            },
            "1.2T 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 205,
                "tuned_torque": 240,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1 & Delco E78 & Bosch MG1CS032",
                "Engine_code": "EB2ADTD",
            },
            "1.2T 130hp": {
                "std_power": 130,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 250,
                "cc": 1199,
                "Type_ecu": "Valeo VD56.1",
                "Engine_code": "EB2ADTS",
            },
        },
    },
    "Crossland": {
        "2021 -> ...": {
            "1.5 CDTi 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 CDTi 120hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2 Turbo 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "F12XHL",
            },
            "1.2 Turbo 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "F12XHT",
            },
        },
    },
    "Crossland X": {
        "2017 - 2020": {
            "1.5 CDTi 102hp": {
                "std_power": 102,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DT",
            },
            "1.5 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DH",
            },
            "1.6 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 254,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "B16DT",
            },
            "1.6 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "",
                "Engine_code": "B16DTH",
            },
            "1.2 Turbo 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 205,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46 & Valeo VD56.1",
                "Engine_code": "B12XHL",
            },
            "1.2 Turbo 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "B12XHL",
            },
        },
    },
    "Frontera": {
        "All": {
            "2.2 DTI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 2171,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "Z22DTH",
            },
        },
    },
    "GT": {
        "2007-2009": {
            "2.0 Turbo 264hp": {
                "std_power": 264,
                "tuned_power": 295,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "Bosch ME9.6 & Bosch ME9.5",
                "Engine_code": "A20NHT",
            },
        },
    },
    "Grandland": {
        "2021 -> ...": {
            "1.5 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "",
                "Engine_code": "F15DTH",
            },
            "2.0 CDTI 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1997,
                "Type_ecu": "",
                "Engine_code": "D20DTH",
            },
            "1.2 Turbo 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "",
                "Engine_code": "F12XHT",
            },
            "1.6 Turbo Hybrid 225hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Turbo Hybrid4 300hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "D16XHT",
            },
        },
    },
    "Grandland X": {
        "2017 -> ...": {
            "1.5 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DTH",
            },
            "1.6 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1560,
                "Type_ecu": "Bosch EDC17C60",
                "Engine_code": "B16DTH",
            },
            "2.0 CDTi 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "D20DTH",
            },
            "1.2 Turbo 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 230,
                "tuned_torque": 270,
                "cc": 1199,
                "Type_ecu": "Valeo VD46.1 & Valeo VD56.1",
                "Engine_code": "B12XHT",
            },
            "1.6 Turbo Hybrid 224hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.6 Turbo Hybrid4 290hp": {
                "std_power": 290,
                "tuned_power": 310,
                "std_torque": 520,
                "tuned_torque": 570,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "D16XHT",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch MG1CS042",
                "Engine_code": "",
            },
        },
    },
    "Insignia": {
        "2008 - 2013": {
            "2.0 CDTI 110hp": {
                "std_power": 110,
                "tuned_power": 190,
                "std_torque": 260,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C18 & Bosch EDC17C59",
                "Engine_code": "A20DTC",
            },
            "2.0 CDTi Bi-Turbo 195hp": {
                "std_power": 195,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP47",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59 & Bosch EDC17C18",
                "Engine_code": "A20DTJ",
            },
            "2.0 CDTi 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 230,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.4",
                "Engine_code": "A16LET",
            },
            "1.6i 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Delco E83",
                "Engine_code": "A16XER",
            },
            "1.8i 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 175,
                "tuned_torque": 190,
                "cc": 1796,
                "Type_ecu": "Delco E83",
                "Engine_code": "A18XER",
            },
            "2.0 Turbo 220hp": {
                "std_power": 220,
                "tuned_power": 295,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E39 & Bosch MED9.6.1",
                "Engine_code": "A20NHT",
            },
            "2.0 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 295,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E39",
                "Engine_code": "A20NHT",
            },
            "2.8 OPC V6 Turbo 325hp": {
                "std_power": 325,
                "tuned_power": 360,
                "std_torque": 435,
                "tuned_torque": 550,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6.1",
                "Engine_code": "A28NET",
            },
            "2.8 V6 Turbo 260hp": {
                "std_power": 260,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6.1 & Bosch ME9.5",
                "Engine_code": "A28NET",
            },
        },
        "2013 - 2015": {
            "2.0 CDTi Bi-Turbo 195hp": {
                "std_power": 195,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP47",
                "Engine_code": "A20DTR",
            },
            "2.0 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 190,
                "std_torque": 260,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A20DTJ",
            },
            "2.0 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "A20DTJ",
            },
            "2.0 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 140hp": {
                "std_power": 140,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17CP19 & Delco E98",
                "Engine_code": "A20DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delco E39A & Delco E80",
                "Engine_code": "A16XHT",
            },
            "2.0 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 295,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1998,
                "Type_ecu": "Delco E39A",
                "Engine_code": "A20NHT",
            },
            "2.8 V6 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2792,
                "Type_ecu": "",
                "Engine_code": "A28NET",
            },
        },
        "2015 - 2017": {
            "1.6 CDTI 160hp": {
                "std_power": 160,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "B16DTR",
            },
            "1.6 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "2.0 CDTi 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1956,
                "Type_ecu": "Delco E98",
                "Engine_code": "B20DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 180,
                "std_torque": 260,
                "tuned_torque": 290,
                "cc": 1364,
                "Type_ecu": "",
                "Engine_code": "A14NET",
            },
            "1.6 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 190,
                "std_torque": 260,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Delco E80 & Delco E39A",
                "Engine_code": "A16XHT",
            },
            "2.0 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "A20NHT",
            },
            "2.8 Bi-Turbo OPC 325hp": {
                "std_power": 325,
                "tuned_power": 360,
                "std_torque": 435,
                "tuned_torque": 530,
                "cc": 2792,
                "Type_ecu": "",
                "Engine_code": "A28NER",
            },
        },
        "2017 - 2019": {
            "1.6 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTE",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "2.0 CDTI 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1956,
                "Type_ecu": "Delco E98",
                "Engine_code": "B20DTH",
            },
            "1.5 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1490,
                "Type_ecu": "Delco E80",
                "Engine_code": "B15XFL",
            },
            "1.5 Turbo 165hp": {
                "std_power": 165,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1490,
                "Type_ecu": "Delco E80",
                "Engine_code": "B15XFT",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 300,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "D16SHT",
            },
            "GSi 2.0 CDTI Bi-Turbo 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 480,
                "tuned_torque": 550,
                "cc": 1956,
                "Type_ecu": "Delco E98",
                "Engine_code": "D20DTR",
            },
            "GSi 2.0 Turbo 260hp": {
                "std_power": 260,
                "tuned_power": 270,
                "std_torque": 400,
                "tuned_torque": 430,
                "cc": 1998,
                "Type_ecu": "Delco E80",
                "Engine_code": "B20NFT",
            },
        },
        "2020 -> ...": {
            "1.5d 122hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0d 174hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.4T 145hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0T 170hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0T 200hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "GSI 2.0T 230hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Meriva": {
        "(A) - 2003 - 2010": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "Z13DTH",
            },
            "1.3 CDTi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Marelli MJD602.M1",
                "Engine_code": "Z13DTH",
            },
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1686,
                "Type_ecu": "Denso DEC30 & Denso D0700 & Delco E87",
                "Engine_code": "Z17DTH",
            },
            "1.7 DTI 75hp": {
                "std_power": 75,
                "tuned_power": 105,
                "std_torque": 165,
                "tuned_torque": 210,
                "cc": 1686,
                "Type_ecu": "Delphi DTI17",
                "Engine_code": "Y17DT",
            },
            "1.8i 16v 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 165,
                "tuned_torque": 180,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIMTEC 71.5 & Siemens/Continental SIMTEC 71.1",
                "Engine_code": "Z18XE",
            },
            "OPC - 1.6 Turbo 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z16LET",
            },
        },
        "(B) - 2010 - 2013": {
            "1.3 CDTi 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 240,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "Z13DTH",
            },
            "1.3 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 180,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "Z13DTH",
            },
            "1.7 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1686,
                "Type_ecu": "Delco E87 & Denso SH7059",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "Z17DTH",
            },
            "1.7 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "Z17DTH",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 175,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E83 & Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E83",
                "Engine_code": "A14NET",
            },
        },
        "(B) - 2014 ->": {
            "1.3 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 180,
                "tuned_torque": 230,
                "cc": 1248,
                "Type_ecu": "Bosch EDC17C19 & Bosch EDC17C59",
                "Engine_code": "Z13DTH",
            },
            "1.6 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1686,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1686,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.7 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 160,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "Z17DTH",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 175,
                "tuned_torque": 265,
                "cc": 1364,
                "Type_ecu": "Delco E83 & Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1364,
                "Type_ecu": "Delco E83",
                "Engine_code": "A14NET",
            },
        },
    },
    "Mokka": {
        "2012 - 2016": {
            "1.6 CDTI 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.7 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1686,
                "Type_ecu": "Delco E87",
                "Engine_code": "A17DTS",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1368,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6i 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Delco E78",
                "Engine_code": "B16XER",
            },
        },
        "2016 - 2019": {
            "1.6 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1368,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1368,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 152hp": {
                "std_power": 152,
                "tuned_power": 180,
                "std_torque": 235,
                "tuned_torque": 290,
                "cc": 1399,
                "Type_ecu": "Delco E80",
                "Engine_code": "B14XFT",
            },
            "1.6 Ecoflex 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 155,
                "tuned_torque": 205,
                "cc": 1598,
                "Type_ecu": "Delco E78",
                "Engine_code": "A16XER",
            },
        },
        "2020 -> ...": {
            "1.5 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MG1CS003",
                "Engine_code": "",
            },
            "1.2T 100hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.2T 130hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Mokka X": {
        "2019 ->": {
            "1.6 CDTI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTI 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1368,
                "Type_ecu": "Delco E78 & Delco E87",
                "Engine_code": "A14NET",
            },
            "1.6 Ecoflex 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 155,
                "tuned_torque": 205,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "A16XER",
            },
        },
    },
    "Movano": {
        "(A) - 1998 - 2010": {
            "1.9 CDTi 82hp": {
                "std_power": 82,
                "tuned_power": 112,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "F9Q",
            },
            "2.0 CDTi 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 290,
                "tuned_torque": 380,
                "cc": 1995,
                "Type_ecu": "",
                "Engine_code": "Y20DTL",
            },
            "2.2 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 260,
                "tuned_torque": 310,
                "cc": 2174,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "Y22DTR",
            },
            "2.5 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 260,
                "tuned_torque": 335,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U A7",
            },
            "2.5 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 147,
                "std_torque": 300,
                "tuned_torque": 375,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36 & Bosch EDC15C2",
                "Engine_code": "G9U B6",
            },
            "2.5 CDTi 135hp": {
                "std_power": 135,
                "tuned_power": 163,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 2464,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.5 CDTi 145hp": {
                "std_power": 145,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2464,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U 632 / G9U 650",
            },
            "3.0 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 2958,
                "Type_ecu": "",
                "Engine_code": "ZD3-200",
            },
        },
        "(B) - 2010 - 2014": {
            "2.3 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 155,
                "std_torque": 285,
                "tuned_torque": 385,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42",
                "Engine_code": "M9T",
            },
            "2.3 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 310,
                "tuned_torque": 385,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Siemens/Continental SID309 & Bosch EDC17C42",
                "Engine_code": "M9T",
            },
            "2.3 CDTi 146hp": {
                "std_power": 146,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 2298,
                "Type_ecu": "",
                "Engine_code": "M9T",
            },
        },
        "(B) - 2014 - 2016": {
            "2.3 CDTi Bi-turbo 136hp": {
                "std_power": 136,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID309",
                "Engine_code": "M9T B7",
            },
            "2.3 CDTi Bi-turbo 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID309 & Siemens/Continental SID310",
                "Engine_code": "M9T B7",
            },
            "2.3 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 155,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C42",
                "Engine_code": "M9T B8",
            },
            "2.3 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 310,
                "tuned_torque": 350,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C11 & Bosch EDC17C42",
                "Engine_code": "M9T B8",
            },
        },
        "(B) - 2016 - 2018": {
            "2.3 DCi (Euro 6) 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 285,
                "tuned_torque": 345,
                "cc": 2298,
                "Type_ecu": "Bosch EDC17C42 & Siemens/Continental SID210",
                "Engine_code": "M9T 870",
            },
            "2.3 DCi (Euro 6) 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 340,
                "tuned_torque": 390,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "M9T 702",
            },
            "2.3 DCi (Euro 6) 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "",
            },
            "2.3 DCi Bi-turbo (Euro 6) 145hp": {
                "std_power": 145,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "",
            },
            "2.3 DCi Bi-turbo (Euro 6) 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 380,
                "tuned_torque": 420,
                "cc": 2298,
                "Type_ecu": "Siemens/Continental SID310",
                "Engine_code": "M9Y 702",
            },
        },
        "2019 ->": {
            "2.3 CDTi 135hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.3 CDTi 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.3 CDTi 180hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Omega": {
        "(B) 1997 - 2003": {
            "2.0 DTI 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 205,
                "tuned_torque": 265,
                "cc": 1994,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "X20DTH",
            },
            "2.2 DTI 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 2171,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "Y22DTH",
            },
            "2.5 DTI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 2498,
                "Type_ecu": "",
                "Engine_code": "Y25DT",
            },
        },
    },
    "Signum": {
        "2003 - 2008": {
            "1.9 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9",
                "Engine_code": "Z19DTH",
            },
            "1.9 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTH",
            },
            "1.9 CDTi 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTH",
            },
            "3.0 CDTi 184hp": {
                "std_power": 184,
                "tuned_power": 219,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2958,
                "Type_ecu": "Denso D0600",
                "Engine_code": "Y30DT",
            },
            "2.0 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 265,
                "tuned_torque": 310,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "Z20NET",
            },
            "2.8 Turbo 230hp": {
                "std_power": 230,
                "tuned_power": 270,
                "std_torque": 330,
                "tuned_torque": 390,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "Z28NEL",
            },
            "2.8 Turbo 250hp": {
                "std_power": 250,
                "tuned_power": 290,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "Z28NEL",
            },
        },
    },
    "Speedster": {
        "2001 - 2005": {
            "2.0 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Bosch ME1.5.5",
                "Engine_code": "Z20LET",
            },
            "2.2i 16v 147hp": {
                "std_power": 147,
                "tuned_power": 160,
                "std_torque": 203,
                "tuned_torque": 220,
                "cc": 2198,
                "Type_ecu": "Delco GMPT E-15",
                "Engine_code": "Z22SE",
            },
        },
    },
    "Tigra": {
        "Twintop - 2004 - 2010": {
            "1.3 CDTi 70hp": {
                "std_power": 70,
                "tuned_power": 95,
                "std_torque": 170,
                "tuned_torque": 215,
                "cc": 1248,
                "Type_ecu": "Marelli MJD6JF",
                "Engine_code": "Z13DY",
            },
            "1.4i 16v 90hp": {
                "std_power": 90,
                "tuned_power": 100,
                "std_torque": 125,
                "tuned_torque": 140,
                "cc": 1364,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z14XEP",
            },
            "1.8i 16v 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 165,
                "tuned_torque": 180,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIMTEC 71",
                "Engine_code": "Z18XE",
            },
        },
    },
    "Vectra": {
        "(C) - 2002 - 2009": {
            "1.9 CDTi 16v 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DT",
            },
            "1.9 CDTi 8v 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C9",
                "Engine_code": "Z19DT",
            },
            "1.9 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9",
                "Engine_code": "Z19DT",
            },
            "2.0 DI 82hp": {
                "std_power": 82,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1994,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "X20DTL",
            },
            "2.0 DTI 16v 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 205,
                "tuned_torque": 255,
                "cc": 1994,
                "Type_ecu": "Bosch EDC15M & Bosch PSG16",
                "Engine_code": "Y20DTH",
            },
            "2.2 DTI 16v 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 2171,
                "Type_ecu": "Bosch PSG16",
                "Engine_code": "",
            },
            "3.0 CDTi 177hp": {
                "std_power": 177,
                "tuned_power": 202,
                "std_torque": 370,
                "tuned_torque": 430,
                "cc": 2958,
                "Type_ecu": "Denso D0100",
                "Engine_code": "Z30DT",
            },
            "3.0 CDTi 184hp": {
                "std_power": 184,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2958,
                "Type_ecu": "Denso D0100",
                "Engine_code": "Z30DT",
            },
            "1.8i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 150,
                "std_torque": 175,
                "tuned_torque": 190,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIMTEC 75.1 & Siemens/Continental SIMTEC 71.6",
                "Engine_code": "Z18XER",
            },
            "2.0 Turbo 175hp": {
                "std_power": 175,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1998,
                "Type_ecu": "",
                "Engine_code": "Z20LET",
            },
            "2.2i 16v 155hp": {
                "std_power": 155,
                "tuned_power": 165,
                "std_torque": 220,
                "tuned_torque": 235,
                "cc": 2198,
                "Type_ecu": "Siemens/Continental SIMTEC 81.1 & Bosch PSG16",
                "Engine_code": "Z22YH",
            },
            "2.8T V6 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2792,
                "Type_ecu": "",
                "Engine_code": "Z28NET",
            },
            "2.8T V6 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2792,
                "Type_ecu": "Bosch ME9.6",
                "Engine_code": "Z28NET",
            },
        },
    },
    "Vivaro": {
        "2001 - 2014": {
            "1.9 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 130,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC15C3",
                "Engine_code": "F9Q",
            },
            "1.9 DTI 82hp": {
                "std_power": 82,
                "tuned_power": 110,
                "std_torque": 200,
                "tuned_torque": 260,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "F9Q",
            },
            "2.0 CDTi 114hp": {
                "std_power": 114,
                "tuned_power": 144,
                "std_torque": 290,
                "tuned_torque": 360,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33 & Bosch EDC17C42 & Bosch EDC17C11 & Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.0 CDTi 90hp": {
                "std_power": 90,
                "tuned_power": 145,
                "std_torque": 240,
                "tuned_torque": 350,
                "cc": 1995,
                "Type_ecu": "Bosch EDC16CP33 & Bosch EDC17C11 & Bosch EDC17C42 & Bosch EDC16C36",
                "Engine_code": "M9R",
            },
            "2.5 CDTi 114hp": {
                "std_power": 114,
                "tuned_power": 149,
                "std_torque": 290,
                "tuned_torque": 370,
                "cc": 2463,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U",
            },
            "2.5 CDTi 135hp": {
                "std_power": 135,
                "tuned_power": 163,
                "std_torque": 310,
                "tuned_torque": 370,
                "cc": 2463,
                "Type_ecu": "Bosch EDC15C3",
                "Engine_code": "G9U",
            },
            "2.5 CDTi 145hp": {
                "std_power": 145,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 405,
                "cc": 2463,
                "Type_ecu": "Bosch EDC16C36",
                "Engine_code": "G9U",
            },
        },
        "2014 - 2016": {
            "1.6 CDTI BiTurbo 120hp": {
                "std_power": 120,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 CDTI BiTurbo 125hp": {
                "std_power": 125,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84 & Bosch EDC17C42",
                "Engine_code": "R9M D4",
            },
            "1.6 CDTI BiTurbo 140hp": {
                "std_power": 140,
                "tuned_power": 165,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 CDTI BiTurbo 145hp": {
                "std_power": 145,
                "tuned_power": 165,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84 & Bosch EDC17C42",
                "Engine_code": "R9M D4",
            },
            "1.6 CDTI 90hp": {
                "std_power": 90,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84 & Bosch EDC17C11",
                "Engine_code": "R9M A4",
            },
            "1.6 CDTi 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C11",
                "Engine_code": "R9M A4",
            },
            "1.6 CDTi 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 260,
                "tuned_torque": 350,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84 & Bosch EDC17C42 & Bosch EDC17C11",
                "Engine_code": "R9M A4",
            },
        },
        "2016 - 2018": {
            "1.6 DCi (Euro 6) 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 DCi (Euro 6) 95hp": {
                "std_power": 95,
                "tuned_power": 145,
                "std_torque": 260,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 DCi Bi-Turbo (Euro 6) 125hp": {
                "std_power": 125,
                "tuned_power": 165,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C42 & Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
            "1.6 DCi Bi-Turbo (Euro 6) 145hp": {
                "std_power": 145,
                "tuned_power": 165,
                "std_torque": 340,
                "tuned_torque": 370,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C84",
                "Engine_code": "R9M D4",
            },
        },
        "2019 ->": {
            "1.5d 102hp": {
                "std_power": 102,
                "tuned_power": 145,
                "std_torque": 270,
                "tuned_torque": 300,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DT / DV5RUCD",
            },
            "1.5d 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 360,
                "cc": 1499,
                "Type_ecu": "Bosch MD1CS003",
                "Engine_code": "D15DTH / DV5RUC",
            },
            "2.0d 122hp": {
                "std_power": 122,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "",
            },
            "2.0d 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 370,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "D20DTH / DW10FDU",
            },
            "2.0d 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1997,
                "Type_ecu": "Delphi DCM7.1A",
                "Engine_code": "DW10FDCU",
            },
        },
    },
    "Zafira": {
        "(A) - 1999 - 2005": {
            "2.0 DI 82hp": {
                "std_power": 82,
                "tuned_power": 107,
                "std_torque": 185,
                "tuned_torque": 235,
                "cc": 1995,
                "Type_ecu": "Bosch EDC15M",
                "Engine_code": "X20DTL",
            },
            "2.0 DTi 100hp": {
                "std_power": 100,
                "tuned_power": 125,
                "std_torque": 230,
                "tuned_torque": 290,
                "cc": 1995,
                "Type_ecu": "Bosch PSG16",
                "Engine_code": "Y20DTH",
            },
            "2.0 Turbo OPC 192hp": {
                "std_power": 192,
                "tuned_power": 225,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1998,
                "Type_ecu": "Bosch ME1.5.5",
                "Engine_code": "Z20LET",
            },
            "2.0 Turbo OPC 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 1998,
                "Type_ecu": "Bosch ME1.5.5",
                "Engine_code": "Z20LET",
            },
        },
        "(B) - 2005 - 2011": {
            "1.7 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "A17DTJ",
            },
            "1.7 CDTi 125hp": {
                "std_power": 125,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 330,
                "cc": 1686,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "A17DTR",
            },
            "1.9 CDTi 100hp": {
                "std_power": 100,
                "tuned_power": 150,
                "std_torque": 260,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9",
                "Engine_code": "Z19DTL",
            },
            "1.9 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C39 & Bosch EDC16C9",
                "Engine_code": "Z19DT",
            },
            "1.9 CDTi 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1910,
                "Type_ecu": "Bosch EDC16C9 & Bosch EDC16C39",
                "Engine_code": "Z19DTH",
            },
            "1.6 Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 205,
                "std_torque": 210,
                "tuned_torque": 300,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "A16XNT",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 150,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "X16XEL",
            },
            "1.6i 16v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Delco MT35",
                "Engine_code": "X16XEL",
            },
            "1.8i 16v 140hp": {
                "std_power": 140,
                "tuned_power": 155,
                "std_torque": 175,
                "tuned_torque": 190,
                "cc": 1796,
                "Type_ecu": "Siemens/Continental SIMTEC 81.1",
                "Engine_code": "X18XEP",
            },
            "2.0 Turbo OPC 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 262,
                "tuned_torque": 340,
                "cc": 1998,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z20LER",
            },
            "2.0 Turbo OPC 240hp": {
                "std_power": 240,
                "tuned_power": 275,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "Z20LEH",
            },
            "2.2i 16v 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 215,
                "tuned_torque": 230,
                "cc": 2198,
                "Type_ecu": "",
                "Engine_code": "Z22SE",
            },
        },
        "(C) - 2011 - 2016": {
            "1.6 CDTi 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "B16DTH",
            },
            "1.6 CDTi 136hp": {
                "std_power": 136,
                "tuned_power": 160,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1598,
                "Type_ecu": "Delco E98",
                "Engine_code": "B16DTH",
            },
            "2.0 CDTi Bi-Turbo 195hp": {
                "std_power": 195,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1956,
                "Type_ecu": "",
                "Engine_code": "YD20DTJ",
            },
            "2.0 CDTi 110hp": {
                "std_power": 110,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A20DTJ",
            },
            "2.0 CDTi 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1956,
                "Type_ecu": "Delco E98",
                "Engine_code": "A20DTJ",
            },
            "2.0 CDTi 165hp": {
                "std_power": 165,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1956,
                "Type_ecu": "Bosch EDC17C59",
                "Engine_code": "A20DTH",
            },
            "2.0 CDTi 170hp": {
                "std_power": 170,
                "tuned_power": 195,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1956,
                "Type_ecu": "Delco E98",
                "Engine_code": "YD20DTJ",
            },
            "1.4 Turbo 120hp": {
                "std_power": 120,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.4 Turbo 140hp": {
                "std_power": 140,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1364,
                "Type_ecu": "Delco E78",
                "Engine_code": "A14NET",
            },
            "1.6 CNG Turbo 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 210,
                "tuned_torque": 280,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.6.2",
                "Engine_code": "A16XNT",
            },
            "1.6 Turbo 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 260,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "",
                "Engine_code": "A16XNT",
            },
            "1.6 Turbo 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 280,
                "tuned_torque": 340,
                "cc": 1598,
                "Type_ecu": "Delco E39",
                "Engine_code": "A16XNT",
            },
        },
    },
},
"Volkswagen": {
    "Amarok": {
        "2011 - 2016": {
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 400,
                "tuned_torque": 440,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20",
                "Engine_code": "CDCA",
            },
            "2.0 TDI CR 122hp": {
                "std_power": 122,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Bosch EDC17C54",
                "Engine_code": "CNFA",
            },
            "2.0 TDI CR 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 420,
                "tuned_torque": 480,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Bosch EDC17C54 & ZF AL551 & ZF AL450",
                "Engine_code": "CNEA",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Bosch EDC17C54",
                "Engine_code": "CNFB",
            },
        },
        "2016 - 2022": {
            "3.0 V6 TDI 204hp": {
                "std_power": 204,
                "tuned_power": 310,
                "std_torque": 500,
                "tuned_torque": 680,
                "cc": 2970,
                "Type_ecu": "Bosch EDC17CP54 & ZF AL450",
                "Engine_code": "DDXB",
            },
            "3.0 V6 TDI 224hp": {
                "std_power": 224,
                "tuned_power": 310,
                "std_torque": 550,
                "tuned_torque": 680,
                "cc": 2970,
                "Type_ecu": "Bosch EDC17CP54 & ZF 8HP & ZF AL450",
                "Engine_code": "DDXC",
            },
            "3.0 V6 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 310,
                "std_torque": 500,
                "tuned_torque": 680,
                "cc": 2970,
                "Type_ecu": "Bosch EDC17CP54",
                "Engine_code": "DDXA",
            },
            "3.0 V6 TDI 258hp": {
                "std_power": 258,
                "tuned_power": 310,
                "std_torque": 580,
                "tuned_torque": 680,
                "cc": 2970,
                "Type_ecu": "Bosch EDC17CP54 & ZF AL551 & ZF AL451",
                "Engine_code": "DDXC",
            },
        },
    },
    "Arteon": {
        "2017 - 2019": {
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch MD1CS004 & Temic DQ381",
                "Engine_code": "DFGA",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ381",
                "Engine_code": "DFHA",
            },
            "2.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 305,
                "std_torque": 500,
                "tuned_torque": 630,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP74 & Temic DQ500",
                "Engine_code": "CUAA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ381",
                "Engine_code": "DADA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Bosch MG1CS111 & Temic DQ381",
                "Engine_code": "CZPB",
            },
            "2.0 TSI 280hp": {
                "std_power": 280,
                "tuned_power": 365,
                "std_torque": 350,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ381",
                "Engine_code": "DJHC",
            },
            "2.0 TSI 272hp": {
                "std_power": 272,
                "tuned_power": 365,
                "std_torque": 350,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ381",
                "Engine_code": "DNUA",
            },
        },
        "2020 ->": {
            "1.4 TSI eHybrid 218hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDI 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DFHA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DFGA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Temic DQ381 & Bosch MG1CS111",
                "Engine_code": "CZPB",
            },
            "2.0 TSI 280hp": {
                "std_power": 280,
                "tuned_power": 365,
                "std_torque": 350,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Siemens/Continental Simos 19.3",
                "Engine_code": "DNFE",
            },
            "R 2.0 TSI 320hp": {
                "std_power": 320,
                "tuned_power": 380,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.3",
                "Engine_code": "EA888 EVO4",
            },
        },
    },
    "Atlas": {
        "2017 - 2019": {
            "2.0 TSI 238hp": {
                "std_power": 238,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "",
                "Engine_code": "DCGA",
            },
            "3.6 V6 280hp": {
                "std_power": 280,
                "tuned_power": 299,
                "std_torque": 370,
                "tuned_torque": 399,
                "cc": 3597,
                "Type_ecu": "Bosch MED17.1.6",
                "Engine_code": "CDVC",
            },
        },
        "2020 ->": {
            "2.0 TFSI 235hp": {
                "std_power": 235,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "",
                "Engine_code": "DCGA",
            },
            "3.6 V6 276hp": {
                "std_power": 276,
                "tuned_power": 299,
                "std_torque": 361,
                "tuned_torque": 385,
                "cc": 3598,
                "Type_ecu": "Bosch MED17.1.62",
                "Engine_code": "CDVC",
            },
        },
    },
    "Atlas Cross Sport": {
    },
    "Bora": {
        "1998 - 2005": {
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 280,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15VM+ & Bosch EDC15V",
                "Engine_code": "AGR",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ATD",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15VM+ & Bosch EDC15V",
                "Engine_code": "ASV",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 143,
                "std_torque": 285,
                "tuned_torque": 360,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AJM",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "1.9 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ARL",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 82,
                "std_torque": 128,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4AV & Marelli IAW4LV & Bosch ME7.5.10",
                "Engine_code": "AHW",
            },
            "1.6 FSI 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch MED7.5.11",
                "Engine_code": "BAD",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 148,
                "tuned_torque": 160,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.5.10",
                "Engine_code": "BCB",
            },
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5 & Bosch ME7.5",
                "Engine_code": "AGU",
            },
            "1.8T 20v 180hp": {
                "std_power": 180,
                "tuned_power": 192,
                "std_torque": 235,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "AGU",
            },
            "2.0i 8v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.5 & Bosch M5.92 & Bosch ME7.5.20",
                "Engine_code": "APK",
            },
            "2.3 V5 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 205,
                "tuned_torque": 225,
                "cc": 2324,
                "Type_ecu": "Bosch M3.8.3",
                "Engine_code": "AGZ",
            },
            "2.3 V5 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 220,
                "tuned_torque": 240,
                "cc": 2324,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "AQN",
            },
            "2.8 V6 204hp": {
                "std_power": 204,
                "tuned_power": 220,
                "std_torque": 270,
                "tuned_torque": 290,
                "cc": 2792,
                "Type_ecu": "Bosch ME7.1 & Bosch ME7.1.1",
                "Engine_code": "AQP",
            },
        },
    },
    "Caddy": {
        "Mk2 - 1995 - 2004": {
            "1.9 SDI 68hp": {
                "std_power": 68,
                "tuned_power": 80,
                "std_torque": 140,
                "tuned_torque": 160,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AGP",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V & Bosch EDC15P+",
                "Engine_code": "ALH",
            },
        },
        "Mk3 - 2004 - 2010": {
            "1.9 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 140,
                "std_torque": 210,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U31 & Bosch EDC16U34",
                "Engine_code": "BSU",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U31 & Bosch EDC16U34 & Temic DQ250 & Bosch EDC16U2.1 M&S",
                "Engine_code": "BLS",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "ALH",
            },
            "2.0 SDI 69hp": {
                "std_power": 69,
                "tuned_power": 82,
                "std_torque": 140,
                "tuned_torque": 160,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U1",
                "Engine_code": "BDJ",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U34",
                "Engine_code": "CFHC",
            },
            "1.4i 16v 80hp": {
                "std_power": 80,
                "tuned_power": 90,
                "std_torque": 132,
                "tuned_torque": 145,
                "cc": 1390,
                "Type_ecu": "Bosch ME7.5.5 & Marelli IAW4MV & Marelli IAW4HV",
                "Engine_code": "BUD",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 127,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Bosch ME7.5.10 & Marelli IAW4MV & Marelli 4HV",
                "Engine_code": "AUA",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental Simos 7.1",
                "Engine_code": "BSE",
            },
        },
        "Mk3 - 2010 - 2015": {
            "1.6 TDI CR 102hp": {
                "std_power": 102,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Bosch EDC17C64 & Temic DQ200",
                "Engine_code": "CAYD",
            },
            "1.6 TDI CR 75hp": {
                "std_power": 75,
                "tuned_power": 145,
                "std_torque": 225,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Bosch EDC17C64",
                "Engine_code": "CAYE",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 180,
                "std_torque": 280,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17C64",
                "Engine_code": "CFHF",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Temic DQ250",
                "Engine_code": "BMM",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFJA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10.13",
                "Engine_code": "CBZ",
            },
            "1.2 TSI 86hp": {
                "std_power": 86,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10.14",
                "Engine_code": "CBZA",
            },
        },
        "Mk4 - 2015 - 2016": {
            "1.6 TDI CR 75hp": {
                "std_power": 75,
                "tuned_power": 135,
                "std_torque": 225,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Siemens/Continental PCR 2.1",
                "Engine_code": "CAYE",
            },
            "1.6 TDI CR 102hp": {
                "std_power": 102,
                "tuned_power": 135,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Siemens/Continental PCR 2.1 & Bosch EDC17C74 & Temic DQ200",
                "Engine_code": "CAYD",
            },
            "2.0 TDI CR 75hp": {
                "std_power": 75,
                "tuned_power": 185,
                "std_torque": 225,
                "tuned_torque": 375,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "DFSC",
            },
            "2.0 TDI CR 102hp": {
                "std_power": 102,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 375,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250Fxx",
                "Engine_code": "CUUD",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 180,
                "std_torque": 280,
                "tuned_torque": 375,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFHF",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CUUB",
            },
            "2.0 TDI CR 122hp": {
                "std_power": 122,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CUUE",
            },
            "1.0 TSI 102hp": {
                "std_power": 102,
                "tuned_power": 120,
                "std_torque": 175,
                "tuned_torque": 210,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHZG",
            },
            "1.2 TSI 84hp": {
                "std_power": 84,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 215,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CYV",
            },
            "1.4 TGI 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CBZ",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 220,
                "tuned_torque": 260,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200 Gen2",
                "Engine_code": "CZCB",
            },
        },
        "Mk4 - 2016 - 2019": {
            "2.0 TDI CR 75hp": {
                "std_power": 75,
                "tuned_power": 180,
                "std_torque": 225,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250",
                "Engine_code": "DFSF",
            },
            "2.0 TDI CR 102hp": {
                "std_power": 102,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250",
                "Engine_code": "DFSD",
            },
            "2.0 TDI CR 122hp": {
                "std_power": 122,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "DFSE",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250",
                "Engine_code": "DFSB",
            },
            "1.0 TSI 102hp": {
                "std_power": 102,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "CHZG",
            },
            "1.2 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CYVC",
            },
            "1.4 TGI 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.2",
                "Engine_code": "CBZ",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200 & Bosch MED17.1.27",
                "Engine_code": "CZCB",
            },
        },
        "Mk5 - 2020 ->": {
            "2.0 TDI CR 102hp": {
                "std_power": 102,
                "tuned_power": 180,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250Fxx & Bosch MD1CS004",
                "Engine_code": "EA288 Evo",
            },
            "2.0 TDI CR 75hp": {
                "std_power": 75,
                "tuned_power": 180,
                "std_torque": 225,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch MD1CS004",
                "Engine_code": "DFSF",
            },
            "2.0 TDI CR 122hp": {
                "std_power": 122,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004 & Temic DQ381 & Bosch EDC17C64",
                "Engine_code": "EA288 Evo",
            },
            "1.0 TSI 116hp": {
                "std_power": 116,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 TSI 114hp": {
                "std_power": 114,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DPBC",
            },
        },
    },
    "Crafter": {
        "2002 - 2011": {
            "2.5 TDI 88hp": {
                "std_power": 88,
                "tuned_power": 115,
                "std_torque": 220,
                "tuned_torque": 290,
                "cc": 2461,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC17CP20",
                "Engine_code": "BJJ",
            },
            "2.5 TDI 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 2461,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC17CP20",
                "Engine_code": "BJK",
            },
            "2.5 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 166,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 2461,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC17CP20",
                "Engine_code": "BJL",
            },
            "2.5 TDI 164hp": {
                "std_power": 164,
                "tuned_power": 193,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2461,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC17CP20",
                "Engine_code": "BJM",
            },
        },
        "2011 - 2016": {
            "2.0 TDI CR (EUR 6) 102hp": {
                "std_power": 102,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DAUB",
            },
            "2.0 TDI CR (EUR 6) 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C64",
                "Engine_code": "DAUA",
            },
            "2.0 TDI CR (EUR 6) 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C64",
                "Engine_code": "DAVA",
            },
            "2.0 TDI CR 109hp": {
                "std_power": 109,
                "tuned_power": 185,
                "std_torque": 300,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C54 & Bosch EDC17CP20 & Bosch EDC17C64 & Bosch EDC16CP34",
                "Engine_code": "CKTB",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C54 & Bosch EDC17C46 & Bosch EDC17CP20 & Bosch EDC17CP14",
                "Engine_code": "CKTC",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 485,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C54 & Bosch EDC17CP20",
                "Engine_code": "CKUB",
            },
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C54",
                "Engine_code": "CKUC",
            },
        },
        "2017 ->": {
            "2.0 TDI CR (EUR 6) 102hp": {
                "std_power": 102,
                "tuned_power": 160,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2V & Bosch EDC17C64 & Delphi DCM6.2",
                "Engine_code": "DAUB",
            },
            "2.0 TDI CR (EUR 6) 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C64 & Aisin AQ8",
                "Engine_code": "DAUA",
            },
            "2.0 TDI CR (EUR 6) 177hp": {
                "std_power": 177,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2V",
                "Engine_code": "DAVA",
            },
        },
    },
    "Eos": {
        "2006 - 2011": {
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC16U34",
                "Engine_code": "BMM",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20",
                "Engine_code": "CAX",
            },
            "1.4 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5",
                "Engine_code": "CTHD",
            },
            "1.6 FSI 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLF",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BVY",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 350,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Bosch MED17.5 & Bosch MED17.5.2 & Temic DQ250Cxx & Bosch MED17.1",
                "Engine_code": "BWA",
            },
            "2.0 TSI 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.5 & Temic DQ250Fxx",
                "Engine_code": "BPY",
            },
            "3.2 V6 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 330,
                "tuned_torque": 355,
                "cc": 3189,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BUB",
            },
        },
        "2011 - 2013": {
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Temic DQ250",
                "Engine_code": "CCF",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20 & Bosch MED17.5.5",
                "Engine_code": "CAX",
            },
            "1.4 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.5_HW2",
                "Engine_code": "CTHD",
            },
            "2.0 TSI 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Temic DQ250",
                "Engine_code": "BPY",
            },
        },
    },
    "Fox": {
        "2005 - 2011": {
            "1.4 TDI 70hp": {
                "std_power": 70,
                "tuned_power": 90,
                "std_torque": 155,
                "tuned_torque": 200,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "BNM",
            },
            "1.4i 8v 75hp": {
                "std_power": 75,
                "tuned_power": 82,
                "std_torque": 124,
                "tuned_torque": 134,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4TV",
                "Engine_code": "BKR",
            },
        },
    },
    "Golf": {
        "Golf 4 - 1997 - 2003": {
            "1.9 SDI 68hp": {
                "std_power": 68,
                "tuned_power": 80,
                "std_torque": 133,
                "tuned_torque": 150,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V & Bosch EDC15VM+",
                "Engine_code": "AGP",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 270,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V & Bosch EDC15VM+",
                "Engine_code": "AGR",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AXR",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AFN",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 285,
                "tuned_torque": 360,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AJM",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ALH",
            },
            "1.9 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ARL",
            },
            "1.6 FSI 110hp": {
                "std_power": 110,
                "tuned_power": 120,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch MED7.5.11 & Marelli IAW4MV",
                "Engine_code": "BAD",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.5.10 & Marelli IAW4MV & Marelli IAW4LV & Siemens/Continental Simos 3",
                "Engine_code": "AZD",
            },
            "1.8T 20v GTI 180hp": {
                "std_power": 180,
                "tuned_power": 192,
                "std_torque": 235,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "AGU",
            },
            "1.8T 20v GTI 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch M3.8.3 & Bosch ME7.5",
                "Engine_code": "AGU",
            },
            "1.8i 20v 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.1 & Bosch M3.8.3 DAMOS 37R",
                "Engine_code": "AGN",
            },
            "2.0i 8v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.5 & Bosch ME5.9.2",
                "Engine_code": "AZI",
            },
            "2.3 VR5 GTI 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 225,
                "tuned_torque": 245,
                "cc": 2324,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "AQN",
            },
            "2.3 VR5 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 205,
                "tuned_torque": 225,
                "cc": 2324,
                "Type_ecu": "Bosch M3.8.3",
                "Engine_code": "AGZ",
            },
            "2.8 VR6 204hp": {
                "std_power": 204,
                "tuned_power": 218,
                "std_torque": 270,
                "tuned_torque": 290,
                "cc": 2792,
                "Type_ecu": "Bosch ME7.1 & Bosch ME7.1.1",
                "Engine_code": "AUE",
            },
            "R32 VR6 242hp": {
                "std_power": 242,
                "tuned_power": 262,
                "std_torque": 320,
                "tuned_torque": 345,
                "cc": 3189,
                "Type_ecu": "Bosch ME7.5.5 & Bosch ME7.1.1",
                "Engine_code": "BFH",
            },
        },
        "Golf 5 - 2003 - 2008": {
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U31 & Bosch EDC16U34",
                "Engine_code": "BKC",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 149,
                "std_torque": 210,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U31 & Bosch EDC16U34",
                "Engine_code": "BRU",
            },
            "2.0 SDI 75hp": {
                "std_power": 75,
                "tuned_power": 90,
                "std_torque": 140,
                "tuned_torque": 160,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U1",
                "Engine_code": "BDK",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U34 & Temic DQ250 & Bosch EDC16U31",
                "Engine_code": "BKD",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2 & Temic DQ250",
                "Engine_code": "BMN",
            },
            "1.4 FSI 90hp": {
                "std_power": 90,
                "tuned_power": 100,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1390,
                "Type_ecu": "Bosch MED9.5.10 & Marelli IAW4HV",
                "Engine_code": "BKG",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20 & Bosch MED17.5.5 & Temic DQ200 & Bosch MED17.5.1",
                "Engine_code": "CAXA",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 82,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Bosch ME7.5.10",
                "Engine_code": "BLN",
            },
            "1.6 FSI 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch MED9.1 & Bosch MED9.5.10",
                "Engine_code": "BLF",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental Simos 7.1 & Marelli IAW4MV",
                "Engine_code": "BSF",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 165,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLX",
            },
            "2.0 TFSI GTI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Bosch MED17.5 & Temic DQ250 & Bosch MED17.1",
                "Engine_code": "CCTA",
            },
            "2.0 TFSI GTI 230hp": {
                "std_power": 230,
                "tuned_power": 300,
                "std_torque": 300,
                "tuned_torque": 410,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Temic DQ250",
                "Engine_code": "BYD",
            },
            "2.0 TFSI GTI 240hp": {
                "std_power": 240,
                "tuned_power": 300,
                "std_torque": 330,
                "tuned_torque": 410,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "CCTA",
            },
            "R32 250hp": {
                "std_power": 250,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 345,
                "cc": 3189,
                "Type_ecu": "Bosch ME7.1.1 & Bosch ME7.5.5 & Temic DQ250",
                "Engine_code": "BUB",
            },
        },
        "Golf 6 - 2009 - 2012": {
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Temic DQ200",
                "Engine_code": "CAY",
            },
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Temic DQ200",
                "Engine_code": "CAY",
            },
            "2.0 TDI CR GTD 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Temic DQ250",
                "Engine_code": "CBBB",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46 & Bosch EDC17CP04 & Temic DQ250",
                "Engine_code": "CBDC",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Temic DQ250",
                "Engine_code": "CBAA",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46",
                "Engine_code": "",
            },
            "1.2 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10 & Bosch MED17.5.21",
                "Engine_code": "CBZA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10 & Temic DQ200",
                "Engine_code": "CBZB",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.20 & Temic DQ200",
                "Engine_code": "CAXA",
            },
            "1.4i 16v 80hp": {
                "std_power": 80,
                "tuned_power": 90,
                "std_torque": 132,
                "tuned_torque": 145,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4HV",
                "Engine_code": "CGGA",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental Simos 7.1 & Bosch ME7.5.20 & Temic DQ200",
                "Engine_code": "BSE",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5 & Temic DQ200",
                "Engine_code": "CDAA",
            },
            "2.0 TSI GTI Edition 35 - 235hp": {
                "std_power": 235,
                "tuned_power": 310,
                "std_torque": 300,
                "tuned_torque": 410,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Temic DQ250",
                "Engine_code": "CDL",
            },
            "2.0 TSI GTI 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5 & Bosch MED17.1 & Temic DQ250",
                "Engine_code": "CCZA",
            },
            "2.0 TSI R 270hp": {
                "std_power": 270,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Temic DQ250",
                "Engine_code": "CDL",
            },
        },
        "Golf 7 - 2012 - 2016": {
            "1.6 TDI CR 105hp": {
                "std_power": 105,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Siemens/Continental PCR 2.1 & Temic DQ200 & Delphi DCM6.2",
                "Engine_code": "CAYC",
            },
            "1.6 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2 & Temic DQ200",
                "Engine_code": "CXXB",
            },
            "1.6 TDI CR 90hp": {
                "std_power": 90,
                "tuned_power": 150,
                "std_torque": 210,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C64",
                "Engine_code": "CXXA",
            },
            "1.6 TDI CR 110hp (2016 ->)": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CRKB",
            },
            "2.0 TDI CR GTD 184hp": {
                "std_power": 184,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250",
                "Engine_code": "CUNA",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250 & Bosch EDC17C54",
                "Engine_code": "CRCB",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17CP14 & Temic DQ250",
                "Engine_code": "",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.1 & Bosch MED17.5.21",
                "Engine_code": "CHZ",
            },
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 250,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.21",
                "Engine_code": "CHZ",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.1.27 & Temic DQ200 Gen2",
                "Engine_code": "CHZ",
            },
            "1.2 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.25 & Siemens/Continental Simos 10 & Temic DQ200",
                "Engine_code": "CBZA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200-MQB & Siemens/Continental Simos 10 & Bosch MED17.5.25",
                "Engine_code": "CBZB",
            },
            "1.2 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200",
                "Engine_code": "CYVB",
            },
            "1.4 TGI 110hp": {
                "std_power": 110,
                "tuned_power": 145,
                "std_torque": 200,
                "tuned_torque": 250,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200",
                "Engine_code": "CPWA / CZBA",
            },
            "1.4 TSI GTE 204hp": {
                "std_power": 204,
                "tuned_power": 285,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.21 & Temic DQ400E",
                "Engine_code": "CUK",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200 & Bosch MED17.5.25 & Bosch MED17.5.5 & Bosch MED17.1.21",
                "Engine_code": "CMBA",
            },
            "1.4 TSI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200 & Bosch MED17.5.25 & Bosch MED17.5.1",
                "Engine_code": "CHPA",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200 & Bosch MED17.1.27 & Bosch MED17.1.21 & Bosch MED17.5.21",
                "Engine_code": "CZE",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200-MQB & Bosch MED17.5.21",
                "Engine_code": "CAXC",
            },
            "1.6i 16v 110hp": {
                "std_power": 110,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 175,
                "cc": 1598,
                "Type_ecu": "Bosch ME17.5.22 & Siemens/Continental Simos 7.1 & Bosch ME17.5.26 & Siemens/Continental Simos 15.2",
                "Engine_code": "CWVA",
            },
            "1.8 TFSI 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "",
            },
            "2.0 TSI GTI Clubsport 265hp": {
                "std_power": 265,
                "tuned_power": 365,
                "std_torque": 350,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CJXE",
            },
            "2.0 TSI GTI Performance 230hp": {
                "std_power": 230,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250-6A/6F/6V-MQB",
                "Engine_code": "CCZA",
            },
            "2.0 TSI GTI 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250 & Bosch MED17.1",
                "Engine_code": "CHHB / CXDA / CNTC",
            },
            "2.0 TSI R 300hp": {
                "std_power": 300,
                "tuned_power": 365,
                "std_torque": 380,
                "tuned_torque": 500,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250 & Temic DQ381",
                "Engine_code": "CDL",
            },
        },
        "Golf 7 - 2017 - 2019": {
            "1.6 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C74 & Temic DQ200 Gen2",
                "Engine_code": "DGTE",
            },
            "2.0 TDI CR GTD 184hp": {
                "std_power": 184,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250-6A/6F/6V & Bosch EDC17C74 & Temic DQ380 & Temic DQ250 & Temic DQ381",
                "Engine_code": "DGCA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C74 & Bosch EDC17C64 & Temic DQ381",
                "Engine_code": "DCYA",
            },
            "1.0 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 135,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Bosch MED17.5.21",
                "Engine_code": "CHZK",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.1.27 & Temic DQ200",
                "Engine_code": "CHZC",
            },
            "1.0 TSI 116hp": {
                "std_power": 116,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Bosch MED17.5.21",
                "Engine_code": "DKRA, DKRF",
            },
            "1.4 TSI GTE 204hp": {
                "std_power": 204,
                "tuned_power": 285,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.21 & Temic DQ400E & Bosch MED17.1.27",
                "Engine_code": "CUK",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Bosch MG1CS011 & Bosch MED17.1.27 & Temic DQ200 Gen2",
                "Engine_code": "CZCA",
            },
            "1.5 TSI Bluemotion 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 290,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Siemens/Continental DQ200 Gen2",
                "Engine_code": "DACA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200-MQB & Bosch MED17.5.25 & Bosch MED17.1.27 & Temic DQ200 Gen2",
                "Engine_code": "DADA",
            },
            "1.8 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CSJB",
            },
            "2.0 TSI - R 310hp": {
                "std_power": 310,
                "tuned_power": 365,
                "std_torque": 380,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ381 & Temic DQ250",
                "Engine_code": "CJXG",
            },
            "2.0 TSI GTI Performance 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 360,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Temic DQ381",
                "Engine_code": "DLBA",
            },
            "2.0 TSI GTI TCR 290hp": {
                "std_power": 290,
                "tuned_power": 350,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Temic DQ381",
                "Engine_code": "DLBA",
            },
            "2.0 TSI GTI 230hp": {
                "std_power": 230,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 440,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250-6A/6F/6V-MQB",
                "Engine_code": "CHHA",
            },
        },
        "Golf 8 - 2020 ->": {
            "2.0 TDI GTD 200hp": {
                "std_power": 200,
                "tuned_power": 245,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DTUA",
            },
            "2.0 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 195,
                "std_torque": 300,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DTRD",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004 & Temic DQ381",
                "Engine_code": "DTSB",
            },
            "1.0 TSI 90hp": {
                "std_power": 90,
                "tuned_power": 130,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "EA211",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21",
                "Engine_code": "EA211",
            },
            "1.4 TSI GTE 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.4 TSI GTE 242hp": {
                "std_power": 242,
                "tuned_power": 285,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "",
                "Engine_code": "DGEA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Temic DQ200 & Bosch MG1CS011",
                "Engine_code": "DPCA",
            },
            "1.5 TSI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 275,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DPBA",
            },
            "1.5 e-TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS111 & Bosch MG1CS011 & Temic DQ200 Gen2",
                "Engine_code": "EA211 Evo",
            },
            "1.5 e-TSI 130hp": {
                "std_power": 130,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 280,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DFYA",
            },
            "2.0 TSI GTI 245hp": {
                "std_power": 245,
                "tuned_power": 327,
                "std_torque": 370,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.6 & Temic DQ381",
                "Engine_code": "DNPA",
            },
            "R 2.0 TFSI 320hp": {
                "std_power": 320,
                "tuned_power": 390,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 1984,
                "Type_ecu": "Temic DQ381 & Siemens/Continental Simos 19.6 & Siemens/Continental Simos 18.1",
                "Engine_code": "EA888",
            },
            "R Performance 2.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 390,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.6",
                "Engine_code": "EA888",
            },
        },
    },
    "Golf GTI": {
        "Golf 4 - 1997 - 2003": {
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5 & Bosch M3.8.1 & Bosch M3.8.3",
                "Engine_code": "AGU",
            },
            "1.8T 20v 180hp": {
                "std_power": 180,
                "tuned_power": 192,
                "std_torque": 235,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5 & Bosch ME7.1.1",
                "Engine_code": "AUQ",
            },
            "2.3 VR5 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 225,
                "tuned_torque": 245,
                "cc": 2324,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "AQN",
            },
        },
        "Golf 5 - 2003 - 2008": {
            "2.0 TFSI Edition 30 - 230hp": {
                "std_power": 230,
                "tuned_power": 310,
                "std_torque": 300,
                "tuned_torque": 410,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Temic DQ250",
                "Engine_code": "BYD",
            },
            "2.0 TFSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Bosch MED17.5 & Temic DQ250",
                "Engine_code": "CCTA",
            },
        },
        "Golf 6 - 2009 - 2012": {
            "2.0 TSI Edition 35 - 235hp": {
                "std_power": 235,
                "tuned_power": 310,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Temic DQ250",
                "Engine_code": "CDL",
            },
            "2.0 TSI R 270hp": {
                "std_power": 270,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "CDL",
            },
            "2.0 TSI 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5 & Temic DQ250 & Bosch MED17.1",
                "Engine_code": "CZAA",
            },
        },
        "Golf 7 - 2012 - 2019": {
            "2.0 TSI Clubsport 265hp": {
                "std_power": 265,
                "tuned_power": 365,
                "std_torque": 350,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Temic DQ250 & Siemens/Continental Simos 18 & Bosch MED17.5 & Bosch EDC17C64",
                "Engine_code": "CCZA",
            },
            "2.0 TSI GTI TCR 290hp": {
                "std_power": 290,
                "tuned_power": 350,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ381",
                "Engine_code": "DLBA",
            },
            "2.0 TSI Performance 230hp": {
                "std_power": 230,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250-6A/6F/6V",
                "Engine_code": "CCZA",
            },
            "2.0 TSI Performance 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 360,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Temic DQ381",
                "Engine_code": "CCZA",
            },
            "2.0 TSI R 300hp": {
                "std_power": 300,
                "tuned_power": 360,
                "std_torque": 380,
                "tuned_torque": 500,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ381 & Temic DQ250",
                "Engine_code": "CDL",
            },
            "2.0 TSI 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250 & Temic DQ380",
                "Engine_code": "CHHB / CXDA / CNTC",
            },
        },
        "Golf 8 ->": {
            "2.0 TFSI GTI Clubsport 300hp": {
                "std_power": 300,
                "tuned_power": 385,
                "std_torque": 400,
                "tuned_torque": 510,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 19.6 & Temic DQ381",
                "Engine_code": "EA888",
            },
            "2.0 TFSI GTI 245hp": {
                "std_power": 245,
                "tuned_power": 327,
                "std_torque": 370,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Temic DQ381 & Siemens/Continental Simos 19.6",
                "Engine_code": "EA888",
            },
        },
    },
    "Golf Sportsvan": {
        "2014 ->": {
            "1.6 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2 & Bosch EDC17C74",
                "Engine_code": "CXXB",
            },
            "1.6 TDI CR 90hp": {
                "std_power": 90,
                "tuned_power": 150,
                "std_torque": 210,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CXXA",
            },
            "1.6 TDI CR 115hp": {
                "std_power": 115,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ200",
                "Engine_code": "DDYA",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2 & Bosch EDC17C74",
                "Engine_code": "DCYA",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.1.27 & Temic DQ200 Gen2",
                "Engine_code": "CHZ",
            },
            "1.2 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CBZA",
            },
            "1.2 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200 Gen2",
                "Engine_code": "CJSA",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200",
                "Engine_code": "CZCA",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CZEA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Temic DQ200 & Bosch MG1CS011",
                "Engine_code": "DADA",
            },
            "1.5 TSI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 290,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DACA",
            },
        },
    },
    "Jetta": {
        "2005 - 2011": {
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CBBB",
            },
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U34 & Siemens/Continental PCR 2.1",
                "Engine_code": "CAYC",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC16U1",
                "Engine_code": "BKC",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "ALH",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 395,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U & Bosch EDC16U34 & Bosch EDC17CP14",
                "Engine_code": "BKD",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2 & Bosch EDC17CP14",
                "Engine_code": "BMR",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20",
                "Engine_code": "CAXA",
            },
            "1.6 FSI 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLF",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 7.1",
                "Engine_code": "BSE",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10 & Bosch MED7.5.10 & Bosch ME7.1.1",
                "Engine_code": "BVY",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Bosch MED17.5 & Bosch MED17.5.2 & Temic DQ250 & Bosch MED17.1",
                "Engine_code": "CCZA",
            },
        },
        "2011 - 2014": {
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Temic DQ200",
                "Engine_code": "CAYC",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Bosch EDC17CP54 & Temic DQ250Fxx",
                "Engine_code": "CFF",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "DELA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10.13 & Temic DQ200",
                "Engine_code": "CYVD",
            },
            "1.4 TSI Hybride 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.5_HW2 & Bosch MED17.1.21 & Temic DQ200 & Bosch MED17.1.6",
                "Engine_code": "CNLA",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.20",
                "Engine_code": "CAXA",
            },
            "1.4 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.5_HW2 & Bosch MED17.1.21",
                "Engine_code": "CTHD",
            },
            "1.8 TSI 170hp": {
                "std_power": 170,
                "tuned_power": 220,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5.2 & Siemens/Continental Simos 18.1 & Siemens/Continental Simos 12",
                "Engine_code": "CPKA",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 275,
                "std_torque": 280,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Siemens/Continental Simos 12 & Temic DQ250 & Siemens/Continental Simos 18",
                "Engine_code": "CCZA",
            },
        },
        "2014 - 2018": {
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250 & Bosch EDC17C54 & Bosch EDC17CP14",
                "Engine_code": "DELA",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CLCA / CUAA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10.13 & Bosch MED17.5.25 & Bosch MED17.1.27",
                "Engine_code": "CYVD",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.20 & Bosch MED17.5.25",
                "Engine_code": "CAXA",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Bosch MED17.1.27 & Temic DQ200 & Bosch MED17.1.21",
                "Engine_code": "CTHA",
            },
            "1.8 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 18",
                "Engine_code": "",
            },
        },
        "2019 ->": {
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.2 & Bosch MED17.1.21 & Bosch MED17.1.27",
                "Engine_code": "DGXA / DJXA",
            },
        },
    },
    "LT": {
        "All": {
            "2.5 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 220,
                "tuned_torque": 278,
                "cc": 2461,
                "Type_ecu": "Bosch EDC15VM",
                "Engine_code": "APA",
            },
            "2.5 TDI 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 2461,
                "Type_ecu": "Bosch EDC15VM & Bosch EDC15V",
                "Engine_code": "ANJ / AVR",
            },
            "2.5 TDI 102hp": {
                "std_power": 102,
                "tuned_power": 132,
                "std_torque": 210,
                "tuned_torque": 260,
                "cc": 2461,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AHD",
            },
            "2.8 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 150,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 2799,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ATA",
            },
            "2.8 TDI 158hp": {
                "std_power": 158,
                "tuned_power": 178,
                "std_torque": 330,
                "tuned_torque": 385,
                "cc": 2799,
                "Type_ecu": "Bosch EDC15C4",
                "Engine_code": "AUH",
            },
        },
    },
    "Lupo": {
        "1997 - 2005": {
            "1.2 TDI 61hp": {
                "std_power": 61,
                "tuned_power": 85,
                "std_torque": 140,
                "tuned_torque": 200,
                "cc": 1191,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AYZ",
            },
            "1.4 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "BAY",
            },
            "1.7 SDI 60hp": {
                "std_power": 60,
                "tuned_power": 75,
                "std_torque": 115,
                "tuned_torque": 130,
                "cc": 1716,
                "Type_ecu": "Bosch EDC15VM",
                "Engine_code": "AKU",
            },
            "1.4 FSI 105hp": {
                "std_power": 105,
                "tuned_power": 115,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1390,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "ARR",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4MV",
                "Engine_code": "AUA",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4AV & Marelli IAW4LV & Marelli IAW4MV & Marelli IAW4CV.V7",
                "Engine_code": "AHW",
            },
            "1.6i 16v GTI 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 152,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.5.10 & Siemens/Continental Simos 3PC",
                "Engine_code": "AJV",
            },
        },
    },
    "New Beetle": {
        "1998 - 2011": {
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 270,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "AGR",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ATD",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC15P",
                "Engine_code": "BJB",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1596,
                "Type_ecu": "Siemens/Continental 5WP4 Simos33",
                "Engine_code": "BFS",
            },
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "AGU",
            },
            "2.3 V5 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 220,
                "tuned_torque": 240,
                "cc": 2324,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "AQN",
            },
            "3.2i V6 224hp": {
                "std_power": 224,
                "tuned_power": 258,
                "std_torque": 320,
                "tuned_torque": 340,
                "cc": 3189,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "AXJ",
            },
        },
        "2011 - 2016": {
            "1.4 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5",
                "Engine_code": "",
            },
            "2.0 TSI 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 12.2 & Siemens/Continental Simos 18.1",
                "Engine_code": "",
            },
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAY",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14",
                "Engine_code": "CJAA",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CUU",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "CJAA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Siemens/Continental Simos 10 & Temic DQ200Dxx",
                "Engine_code": "CBZ",
            },
            "1.2 TSI 85hp": {
                "std_power": 85,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10",
                "Engine_code": "CBZB",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.5_HW2 & Bosch MED17.5.2 & Temic DQ200",
                "Engine_code": "CAVD",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 18",
                "Engine_code": "CPKA",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Siemens/Continental Simos 12.2 & Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CCZ",
            },
        },
        "2016 ->": {
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "DELA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 175,
                "tuned_torque": 215,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200",
                "Engine_code": "CYVD",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CZD",
            },
            "2.0 TSI 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.2 & Temic DQ250 & Bosch MG1CS001",
                "Engine_code": "CULC",
            },
        },
    },
    "Passat / Magotan": {
        "B5 - 1996 - 2005": {
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 210,
                "tuned_torque": 270,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AHU",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AVB",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 285,
                "tuned_torque": 360,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AJM",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AWX",
            },
            "2.0 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 335,
                "tuned_torque": 390,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31",
                "Engine_code": "BGW",
            },
            "2.5 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+ & Bosch EDC15M",
                "Engine_code": "AKN",
            },
            "2.5 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 390,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "BDG",
            },
            "2.5 TDI 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 370,
                "tuned_torque": 420,
                "cc": 2496,
                "Type_ecu": "Bosch EDC15VM+2",
                "Engine_code": "BDH",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental 5WP4 Simos33",
                "Engine_code": "ALZ",
            },
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5 & Bosch M3.8.2",
                "Engine_code": "AWT",
            },
            "1.8i 20v 125hp": {
                "std_power": 125,
                "tuned_power": 135,
                "std_torque": 173,
                "tuned_torque": 188,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "ADR",
            },
            "2.0i 8v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 172,
                "tuned_torque": 187,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.5.5 & Siemens/Continental Simos 3",
                "Engine_code": "AZM",
            },
            "2.3 V5 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 205,
                "tuned_torque": 220,
                "cc": 2327,
                "Type_ecu": "Bosch M3.8.3",
                "Engine_code": "AGZ",
            },
            "2.3 V5 170hp": {
                "std_power": 170,
                "tuned_power": 185,
                "std_torque": 220,
                "tuned_torque": 240,
                "cc": 2327,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "AZX",
            },
            "2.8 V6 193hp": {
                "std_power": 193,
                "tuned_power": 208,
                "std_torque": 280,
                "tuned_torque": 300,
                "cc": 2771,
                "Type_ecu": "Bosch ME7.1",
                "Engine_code": "AMX",
            },
            "4.0 W8 275hp": {
                "std_power": 275,
                "tuned_power": 295,
                "std_torque": 370,
                "tuned_torque": 395,
                "cc": 3999,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "BDN",
            },
        },
        "B6 - 2005 - 2010": {
            "1.6 TDI CR 105hp": {
                "std_power": 105,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYC",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC16U1 & Bosch EDC16U31",
                "Engine_code": "BKC",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 360,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17CP20 & Bosch EDC17CP20",
                "Engine_code": "CBD",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Temic DQ250 & Bosch EDC17CP04 & Bosch EDC16U34 & Bosch EDC17CP20 & Bosch EDC17C46",
                "Engine_code": "CBAB",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2 & Bosch EDC17CP14 & Temic DQ250",
                "Engine_code": "CBBB",
            },
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Bosch EDC17CP14",
                "Engine_code": "CBAC",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16U34 & Siemens/Continental PPD1.2 & Bosch EDC17CP14 & Bosch EDC17CP04 & Bosch EDC16U1 & Bosch EDC17CP20 & Siemens/Continental PPD1.x & Siemens/Continental PPD1.5",
                "Engine_code": "BKP",
            },
            "2.0 TDI 120hp": {
                "std_power": 120,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U34 & Siemens/Continental PPD1.5",
                "Engine_code": "BWV",
            },
            "2.0 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2 & Bosch EDC17CP14 & Temic DQ250Exx",
                "Engine_code": "BMR",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.20 & Temic DQ200 & Bosch MED17.1",
                "Engine_code": "CAXA",
            },
            "1.6 FSI 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLF",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental Simos 7.1 & Siemens/Continental Simos 3",
                "Engine_code": "BSE",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5 & Temic DQ200Dxx & Bosch MED17.2",
                "Engine_code": "CDAA",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLR",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Bosch MED17.5 & Bosch MED17.1 & Temic DQ250",
                "Engine_code": "CAWB",
            },
            "3.2 FSI 250hp": {
                "std_power": 250,
                "tuned_power": 265,
                "std_torque": 320,
                "tuned_torque": 340,
                "cc": 3189,
                "Type_ecu": "Bosch MED9.1.1 & Temic DQ250",
                "Engine_code": "AXZ",
            },
            "3.6 FSI 280hp": {
                "std_power": 280,
                "tuned_power": 295,
                "std_torque": 360,
                "tuned_torque": 380,
                "cc": 3598,
                "Type_ecu": "Bosch MED9.1.1",
                "Engine_code": "BLV",
            },
            "R36 - 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 350,
                "tuned_torque": 370,
                "cc": 3598,
                "Type_ecu": "Bosch MED9.1 & Temic DQ250Fxx",
                "Engine_code": "BWS",
            },
        },
        "B7 - 2010 - 2014": {
            "1.6 TDI CR 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYC",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46 & Temic DQ250 & Bosch EDC17C54",
                "Engine_code": "CLLA",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Temic DQ250 & Bosch EDC17CP20 & Bosch EDC17C54",
                "Engine_code": "CBAB",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFFA",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFGC",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 220,
                "tuned_torque": 280,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5_HW2 & Bosch MED17.1.1",
                "Engine_code": "CZE",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20 & Bosch MED17.5.5",
                "Engine_code": "CAXA",
            },
            "1.4 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5_HW2",
                "Engine_code": "CAVD",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5.2 & Siemens/Continental Simos 12.1 & Temic DQ200 & Siemens/Continental Simos 18.2",
                "Engine_code": "CDA",
            },
            "2.0 TSI 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Temic DQ250",
                "Engine_code": "CAVG",
            },
            "3.6 V6 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 350,
                "tuned_torque": 380,
                "cc": 3600,
                "Type_ecu": "Bosch MED17.1.6 & Temic DQ250Fxx",
                "Engine_code": "CDVB",
            },
        },
        "B8 - 2014 - 2019": {
            "1.6 TDI CR 120hp": {
                "std_power": 120,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ200",
                "Engine_code": "DCX",
            },
            "2.0 TDI Bi-Turbo 240hp": {
                "std_power": 240,
                "tuned_power": 305,
                "std_torque": 500,
                "tuned_torque": 630,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP74 & Temic DQ500",
                "Engine_code": "CUA",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250 & Temic DQ381",
                "Engine_code": "CRL",
            },
            "2.0 TDI CR 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250-6A/6F/6V-MQB & Temic DQ381",
                "Engine_code": "DFC",
            },
            "1.4 TSI GTE 218hp": {
                "std_power": 218,
                "tuned_power": 273,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.21 & Temic DQ400E & Bosch MED17.1.27",
                "Engine_code": "CUKC",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 300,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CZCA",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200 & Bosch MED17.1.27",
                "Engine_code": "CZE",
            },
            "1.8 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 12.1 & Siemens/Continental Simos 18 & Temic DQ200 & Siemens/Continental Simos 18.1",
                "Engine_code": "CJS",
            },
            "2.0 TSI - R 280hp": {
                "std_power": 280,
                "tuned_power": 365,
                "std_torque": 350,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CJX",
            },
            "2.0 TSI 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Siemens/Continental Simos 18.2 & Bosch MG1CS002 & Temic DQ250 & Temic DQ500 & Siemens/Continental Simos 18.6",
                "Engine_code": "CHH",
            },
        },
        "B8 - 2019 ->": {
            "1.4 TSI GTE 218hp": {
                "std_power": 218,
                "tuned_power": 273,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1.27",
                "Engine_code": "CUKC",
            },
            "1.6 TDI 120hp": {
                "std_power": 120,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C74",
                "Engine_code": "DCZA",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP74 & Bosch MD1CS004 & Bosch EDC17C74 & Bosch MG1CS111",
                "Engine_code": "DDAA / DFCA / DFHA",
            },
            "2.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP74 & Temic DQ500",
                "Engine_code": "CUA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004 & Temic DQ381",
                "Engine_code": "DBGA / DCYA / DFEA",
            },
            "2.0 TDI 122hp": {
                "std_power": 122,
                "tuned_power": 195,
                "std_torque": 250,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004 & Temic DQ200",
                "Engine_code": "DTRA",
            },
            "2.0 TDI 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DDAA / DFCA / DFHA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200 Gen2 & Bosch ME17.5.6",
                "Engine_code": "DPCA",
            },
            "2.0 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 245,
                "std_torque": 270,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Temic DQ381 & Bosch MG1CS111 & Siemens/Continental Simos 18.1",
                "Engine_code": "CZPB / DKZA",
            },
            "2.0 TSI 272hp": {
                "std_power": 272,
                "tuned_power": 350,
                "std_torque": 350,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Temic DQ381",
                "Engine_code": "DNUA",
            },
        },
    },
    "Passat CC": {
        "All": {
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP20 & Bosch EDC17CP14 & Bosch EDC17C54 & Bosch MED17.1.27 & Temic DQ250Exx",
                "Engine_code": "CBAB",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14 & Temic DQ250",
                "Engine_code": "CBBB",
            },
            "2.0 TDI CR 143hp": {
                "std_power": 143,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP20",
                "Engine_code": "CBAC",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CBBA",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17C64",
                "Engine_code": "CFGC",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Temic DQ250 & Bosch EDC17C64",
                "Engine_code": "CFGC",
            },
            "1.4 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5_HW2 & Bosch MED17.1",
                "Engine_code": "CTHD",
            },
            "1.8 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 210,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1798,
                "Type_ecu": "Bosch MED17.5 & Bosch MED17.5.2 & Temic DQ200",
                "Engine_code": "BZB",
            },
            "2.0 TSI 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Siemens/Continental Simos 18.6 & Siemens/Continental Simos 18.10 & Temic DQ250",
                "Engine_code": "CCZ",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Bosch MED17.5.2 & Bosch MG1CS001 & Temic DQ250 & Siemens/Continental Simos 18.10",
                "Engine_code": "CAWB",
            },
            "3.6 FSI 300hp": {
                "std_power": 300,
                "tuned_power": 320,
                "std_torque": 360,
                "tuned_torque": 380,
                "cc": 3598,
                "Type_ecu": "Bosch MED9.1 & Bosch MED9.1.1 & Bosch MED17.1.6 & Temic DQ250",
                "Engine_code": "BWS",
            },
        },
    },
    "Phaeton": {
        "All": {
            "3.0 TDI 224hp": {
                "std_power": 224,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34",
                "Engine_code": "BMK",
            },
            "3.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 300,
                "std_torque": 500,
                "tuned_torque": 625,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP04",
                "Engine_code": "BMK",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16U3",
                "Engine_code": "BMK",
            },
            "3.0 TDI 233hp": {
                "std_power": 233,
                "tuned_power": 280,
                "std_torque": 450,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP04",
                "Engine_code": "BMK",
            },
            "3.0 TDI 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 500,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP04",
                "Engine_code": "BMK",
            },
            "5.0 TDI V10 313hp": {
                "std_power": 313,
                "tuned_power": 365,
                "std_torque": 750,
                "tuned_torque": 900,
                "cc": 4921,
                "Type_ecu": "Bosch EDC16U1 M&S",
                "Engine_code": "AJS",
            },
            "3.2 V6 241hp": {
                "std_power": 241,
                "tuned_power": 256,
                "std_torque": 315,
                "tuned_torque": 340,
                "cc": 3189,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "AYT",
            },
            "3.6 V6 280hp": {
                "std_power": 280,
                "tuned_power": 295,
                "std_torque": 370,
                "tuned_torque": 390,
                "cc": 3597,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "BKL",
            },
            "4.2 V8 335hp": {
                "std_power": 335,
                "tuned_power": 355,
                "std_torque": 430,
                "tuned_torque": 455,
                "cc": 4172,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BGH",
            },
            "6.0 W12 420hp": {
                "std_power": 420,
                "tuned_power": 445,
                "std_torque": 550,
                "tuned_torque": 600,
                "cc": 5998,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BAN",
            },
            "6.0 W12 450hp": {
                "std_power": 450,
                "tuned_power": 475,
                "std_torque": 560,
                "tuned_torque": 610,
                "cc": 5998,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BRT",
            },
        },
    },
    "Polo": {
        "1999 - 2001 ( 6N2 )": {
            "1.4 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 95,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AMF",
            },
            "1.9 SDI 64hp": {
                "std_power": 64,
                "tuned_power": 75,
                "std_torque": 125,
                "tuned_torque": 145,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "ASY",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4MV",
                "Engine_code": "AFK",
            },
            "1.6i 16v GTI 125hp": {
                "std_power": 125,
                "tuned_power": 138,
                "std_torque": 152,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.5.10 & Siemens/Continental Simos 3",
                "Engine_code": "AVY",
            },
        },
        "2001 - 2005 ( 9N )": {
            "1.4 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 100,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+ & Marelli IAW4MV",
                "Engine_code": "AMF",
            },
            "1.9 SDI 64hp": {
                "std_power": 64,
                "tuned_power": 75,
                "std_torque": 125,
                "tuned_torque": 145,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "ASY",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ATD",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "1.4i 16v 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4MV & Marelli IAW4LV & Marelli IAW4TV.GQ & Bosch ME7 & Bosch MED7.5.11",
                "Engine_code": "BBY",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4MV",
                "Engine_code": "BBZ",
            },
        },
        "2005 - 2009 ( 9N3 )": {
            "1.4 TDI Bluemotion 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 190,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC17U05 & Bosch EDC17U01",
                "Engine_code": "BNV",
            },
            "1.4 TDI 70hp": {
                "std_power": 70,
                "tuned_power": 100,
                "std_torque": 155,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+ & Bosch EDC17U1 & Bosch EDC15VM",
                "Engine_code": "BNM",
            },
            "1.4 TDI 80hp": {
                "std_power": 80,
                "tuned_power": 100,
                "std_torque": 195,
                "tuned_torque": 245,
                "cc": 1422,
                "Type_ecu": "Bosch EDC15P+ & Bosch EDC17U05",
                "Engine_code": "BNV",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+ & Bosch EDC17U1",
                "Engine_code": "ATD",
            },
            "1.4 FSI 85hp": {
                "std_power": 85,
                "tuned_power": 95,
                "std_torque": 130,
                "tuned_torque": 145,
                "cc": 1390,
                "Type_ecu": "Bosch MED7.5.11 & Bosch ME7.5.10",
                "Engine_code": "AXU",
            },
            "1.4i 16v 80hp": {
                "std_power": 80,
                "tuned_power": 90,
                "std_torque": 132,
                "tuned_torque": 148,
                "cc": 1390,
                "Type_ecu": "Siemens/Continental Simos 3 & Marelli IAW4HV & Bosch ME7.5.20 & Marelli IAW4TV",
                "Engine_code": "BUD",
            },
            "1.4i 16v 100hp": {
                "std_power": 100,
                "tuned_power": 110,
                "std_torque": 126,
                "tuned_torque": 140,
                "cc": 1390,
                "Type_ecu": "Marelli IAW4MV",
                "Engine_code": "AUB",
            },
            "1.6i 16v 105hp": {
                "std_power": 105,
                "tuned_power": 120,
                "std_torque": 148,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch ME7.5.20 & Marelli IAW7GV",
                "Engine_code": "BTS",
            },
            "1.8 T GTI Cup 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 235,
                "tuned_torque": 340,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "BBU",
            },
            "1.8 T GTI 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 220,
                "tuned_torque": 320,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5",
                "Engine_code": "BJX",
            },
        },
        "2009 - 2014 ( 6R )": {
            "1.2 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 105,
                "std_torque": 180,
                "tuned_torque": 240,
                "cc": 1199,
                "Type_ecu": "Delphi DCM3.7",
                "Engine_code": "CFWA",
            },
            "1.6 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Temic DQ200",
                "Engine_code": "CAYB",
            },
            "1.6 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Temic DQ200",
                "Engine_code": "CAYC",
            },
            "1.6 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 130,
                "std_torque": 195,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYA",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10 & Siemens/Continental Simos 11.1 & Temic DQ200 & Bosch MED17.5.25",
                "Engine_code": "CBZ",
            },
            "1.2 TSI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 225,
                "cc": 1197,
                "Type_ecu": "Siemens/Continental Simos 10.X & Bosch MED17.5.25 & Siemens/Continental Simos 11.1 & Temic DQ200",
                "Engine_code": "CBZ",
            },
            "1.2i 12v 60hp": {
                "std_power": 60,
                "tuned_power": 67,
                "std_torque": 108,
                "tuned_torque": 118,
                "cc": 1198,
                "Type_ecu": "Bosch ME17.5.20 & Siemens/Continental Simos 9.1 & Siemens/Continental Simos 11.1",
                "Engine_code": "APF",
            },
            "1.2i 12v 70hp": {
                "std_power": 70,
                "tuned_power": 77,
                "std_torque": 112,
                "tuned_torque": 122,
                "cc": 1198,
                "Type_ecu": "Siemens/Continental Simos 9.1 & Siemens/Continental Simos 11.1 & Siemens/Continental Simos 10.X",
                "Engine_code": "CGPA",
            },
            "1.4 TSI Blue GT 140hp": {
                "std_power": 140,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.5.25 & Temic DQ200Dxx",
                "Engine_code": "CPT",
            },
            "2.0 TSI 220hp": {
                "std_power": 220,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1",
                "Engine_code": "CDL",
            },
        },
        "2014 - 2017 ( 6C )": {
            "1.4 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 130,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CUTA",
            },
            "1.4 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 115,
                "std_torque": 230,
                "tuned_torque": 280,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ200",
                "Engine_code": "CUSB",
            },
            "1.4 TDI 75hp": {
                "std_power": 75,
                "tuned_power": 115,
                "std_torque": 210,
                "tuned_torque": 280,
                "cc": 1422,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CUSA",
            },
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200-MQB",
                "Engine_code": "CHZB",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Temic DQ200-MQB",
                "Engine_code": "CHZC",
            },
            "1.0i 60hp": {
                "std_power": 60,
                "tuned_power": 80,
                "std_torque": 95,
                "tuned_torque": 100,
                "cc": 999,
                "Type_ecu": "Bosch ME17.5.20 & Bosch ME17.5.26",
                "Engine_code": "CHY",
            },
            "1.2 TSI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25 & Siemens/Continental Simos 10.22 & Bosch MED17.5.21 & Temic DQ200-MQB & Temic DQ200 Gen2",
                "Engine_code": "CBZC",
            },
            "1.2 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CBZB",
            },
            "1.4 TSI Blue GT 140hp": {
                "std_power": 140,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200",
                "Engine_code": "CZE",
            },
            "1.4 TSI Blue GT 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ200",
                "Engine_code": "CZE",
            },
            "1.8 TSI GTI 192hp": {
                "std_power": 192,
                "tuned_power": 220,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1798,
                "Type_ecu": "Temic DQ200 & Siemens/Continental Simos 18 & Temic DQ250 & Temic DQ200 Gen2",
                "Engine_code": "DAJA",
            },
        },
        "2017 - 2020": {
            "1.6 TDI 95hp": {
                "std_power": 95,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ200 Gen2",
                "Engine_code": "DGTD",
            },
            "1.6 TDI 80hp": {
                "std_power": 80,
                "tuned_power": 145,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DGTD",
            },
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.26 & Bosch MED17.5.27 & Bosch MED17.5.21 & Bosch MED17.1.27 & Temic DQ250 & Temic DQ200 Gen2 & Bosch MED17.5.25",
                "Engine_code": "CHZL",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Bosch MED17.5.21 & Siemens/Continental DQ200 Gen2",
                "Engine_code": "CHZJ / DKJA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200 Gen2",
                "Engine_code": "DADA",
            },
            "2.0 TSI GTI 200hp": {
                "std_power": 200,
                "tuned_power": 250,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1984,
                "Type_ecu": "Temic DQ250-6A/6F/6V-MQB & Bosch MG1CS011 & Bosch MG1CS111 & Bosch MG1CS001",
                "Engine_code": "CZPC",
            },
        },
        "2021 -> ...": {
            "1.0 TGI 90hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27",
                "Engine_code": "CHZL",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Temic DQ200 Gen2 & Bosch MED17.1.27",
                "Engine_code": "CHZJ / DKJA",
            },
        },
    },
    "Scirocco": {
        "2008 - 2014": {
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14",
                "Engine_code": "CFHC",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46",
                "Engine_code": "CBBB",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFHB",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Temic DQ250",
                "Engine_code": "CFGC",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20 & Bosch MED17.5.5",
                "Engine_code": "CAXA",
            },
            "1.4 TSI 160hp (CTHD-CTKA)": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Siemens/Continental DQ200 Gen2",
                "Engine_code": "CTHD",
            },
            "2.0 TSI R 265hp": {
                "std_power": 265,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.1 & Temic DQ250",
                "Engine_code": "CDLA",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Bosch MED9.1 & Temic DQ250",
                "Engine_code": "CCZ",
            },
            "2.0 TSI 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5 & Temic DQ250",
                "Engine_code": "CCZB",
            },
        },
        "2014 ->": {
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250Fxx & Bosch EDC17C50",
                "Engine_code": "CUUB",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CUWA",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Bosch MED17.5.5",
                "Engine_code": "CXSB",
            },
            "2.0 TSI R 280hp": {
                "std_power": 280,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Temic DQ250 & Bosch MED9.1",
                "Engine_code": "CDLK",
            },
            "2.0 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 275,
                "std_torque": 320,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ250",
                "Engine_code": "CULA",
            },
            "2.0 TSI 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Temic DQ250 & Siemens/Continental Simos 18.1",
                "Engine_code": "CULC",
            },
        },
    },
    "Sharan": {
        "2000 - 2010": {
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ANU",
            },
            "1.9 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "AUY",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 300,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15V",
                "Engine_code": "AFN",
            },
            "1.9 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+",
                "Engine_code": "ASZ",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 310,
                "tuned_torque": 390,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC16U31",
                "Engine_code": "BRT",
            },
            "1.8T 20v 150hp": {
                "std_power": 150,
                "tuned_power": 192,
                "std_torque": 210,
                "tuned_torque": 310,
                "cc": 1781,
                "Type_ecu": "Bosch ME7.5.5 & Bosch M3.8.3",
                "Engine_code": "AWC",
            },
            "2.0i 8v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 166,
                "tuned_torque": 183,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.X 4Mbit",
                "Engine_code": "ATM",
            },
            "2.8 V6 204hp": {
                "std_power": 204,
                "tuned_power": 220,
                "std_torque": 265,
                "tuned_torque": 285,
                "cc": 2792,
                "Type_ecu": "Bosch ME7.5.5",
                "Engine_code": "AYL",
            },
        },
        "2010 - 2015": {
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CBBB",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Temic DQ250",
                "Engine_code": "BRT",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFGC",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC16U",
                "Engine_code": "BVH",
            },
            "2.0 TDI CR 115hp": {
                "std_power": 115,
                "tuned_power": 180,
                "std_torque": 280,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "BVH",
            },
            "1.4 TSI 150hp (CTHA)": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5",
                "Engine_code": "CTHA",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2",
                "Engine_code": "CCZA",
            },
        },
        "2016 ->": {
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ250",
                "Engine_code": "DFL",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ500",
                "Engine_code": "DFM",
            },
            "2.0 TDI CR 115hp": {
                "std_power": 115,
                "tuned_power": 195,
                "std_torque": 280,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CUVA / DFLD / DLTC",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "DLUB",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ250Fxx",
                "Engine_code": "ZCDA",
            },
            "2.0 TSI 200hp": {
                "std_power": 200,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Temic DQ250",
                "Engine_code": "CCZA",
            },
            "2.0 TSI 220hp": {
                "std_power": 220,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Temic DQ250",
                "Engine_code": "DEDA",
            },
        },
    },
    "T-Cross": {
        "2019 ->": {
            "1.6 TDI 95hp": {
                "std_power": 95,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DGTD",
            },
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 130,
                "std_torque": 175,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Bosch MED17.5.21",
                "Engine_code": "DKLA",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 130,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Siemens/Continental DQ200 Gen2",
                "Engine_code": "DKJA / DKRA / DKRF",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200",
                "Engine_code": "DPCA",
            },
        },
    },
    "T-Roc": {
        "2017 - 2021": {
            "1.6 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "DGTE",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Bosch MD1CS004 & Temic DQ381",
                "Engine_code": "DFFA",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "DFHA",
            },
            "1.0 TSI 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.26 & Bosch MED17.1.27 & Bosch MED17.5.21 & Bosch MED17.1.21",
                "Engine_code": "CHZJ",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200 Gen2 & Bosch MED17.5.27",
                "Engine_code": "DADA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Temic DQ381 & Bosch MG1CS111 & Siemens/Continental Simos 18",
                "Engine_code": "CZPB",
            },
            "R 2.0 TSI 300hp": {
                "std_power": 300,
                "tuned_power": 350,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ381 & Siemens/Continental Simos 19.3",
                "Engine_code": "DNUE",
            },
        },
        "2022 -> ...": {
            "1.0 TSI 110hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 TSI 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Taigo": {
        "2021 -> ...": {
            "1.0 TSI 95hp": {
                "std_power": 95,
                "tuned_power": 140,
                "std_torque": 160,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DLAC",
            },
            "1.0 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 240,
                "cc": 999,
                "Type_ecu": "",
                "Engine_code": "DLAA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "",
                "Engine_code": "DPCA",
            },
        },
    },
    "Tiguan": {
        "2020 -> ...": {
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004 & Bosch MDG1 & Temic DQ381",
                "Engine_code": "DFG",
            },
            "2.0 TDI CR 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Temic DQ381 & Bosch MD1CS004",
                "Engine_code": "DFG",
            },
            "2.0 TDI CR 122hp": {
                "std_power": 122,
                "tuned_power": 200,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DTR",
            },
            "1.4 e-Hybrid 245hp": {
                "std_power": 245,
                "tuned_power": 285,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1395,
                "Type_ecu": "",
                "Engine_code": "EA211",
            },
            "1.5 TSI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 290,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ382",
                "Engine_code": "DPBE",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ381",
                "Engine_code": "DPCA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Temic DQ381 & Bosch MG1CS001 & Bosch MG1CS111",
                "Engine_code": "",
            },
            "R 2.0 TFSI 320hp": {
                "std_power": 320,
                "tuned_power": 390,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 1984,
                "Type_ecu": "Temic DQ500 & Siemens/Continental Simos 19.3",
                "Engine_code": "EA888 EVO4",
            },
        },
        "NZ - 2007 - 2011": {
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46",
                "Engine_code": "CFFB",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC17C46",
                "Engine_code": "CFGB",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 185,
                "std_torque": 280,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFFD",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17CP14",
                "Engine_code": "CFFA",
            },
            "2.0 TDI CR 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP14",
                "Engine_code": "CBBA",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.20 & Bosch MED17.5.5",
                "Engine_code": "CAXA",
            },
            "2.0 TFSI 170hp": {
                "std_power": 170,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.X CAN & Bosch MED17.5 & Bosch MED17.5.2",
                "Engine_code": "CCTB",
            },
            "2.0 TFSI 200hp": {
                "std_power": 200,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.X CAN & Bosch MED17.5 & Bosch MED17.5.2",
                "Engine_code": "CCTA",
            },
        },
        "NZ - 2011 - 2015": {
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 185,
                "std_torque": 280,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17C64",
                "Engine_code": "CFFD",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFFA",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Temic DQ500 & Bosch EDC17C74",
                "Engine_code": "CFFB",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46 & Bosch EDC17C64 & Bosch EDC17C74",
                "Engine_code": "CFGB",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CFCG",
            },
            "1.4 TSI 122hp": {
                "std_power": 122,
                "tuned_power": 150,
                "std_torque": 200,
                "tuned_torque": 265,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5 & Bosch MED17.5.20",
                "Engine_code": "CAXA",
            },
            "1.4 TSI 160hp": {
                "std_power": 160,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5",
                "Engine_code": "CTHD",
            },
            "1.4 TSI 150hp (CTHA)": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5_HW2 & Temic DQ250",
                "Engine_code": "CTHA",
            },
            "2.0 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.X CAN & Bosch MED17.5.2 & Temic DQ250 & Temic DQ500",
                "Engine_code": "CCZD",
            },
            "2.0 TSI 210hp": {
                "std_power": 210,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Temic DQ500",
                "Engine_code": "CCZB",
            },
        },
        "NZ - 2016 - 2020": {
            "2.0 TDI CR Bi-Turbo 240hp": {
                "std_power": 240,
                "tuned_power": 300,
                "std_torque": 500,
                "tuned_torque": 550,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Bosch EDC17CP74 & Temic DQ500",
                "Engine_code": "CUAA",
            },
            "2.0 TDI CR 110hp": {
                "std_power": 110,
                "tuned_power": 190,
                "std_torque": 280,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64",
                "Engine_code": "CUV",
            },
            "2.0 TDI CR 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ500 & Temic DQ381",
                "Engine_code": "DFG",
            },
            "2.0 TDI CR 184hp": {
                "std_power": 184,
                "tuned_power": 220,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74",
                "Engine_code": "",
            },
            "2.0 TDI CR 115hp": {
                "std_power": 115,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74",
                "Engine_code": "DFGC",
            },
            "2.0 TDI CR 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP74 & Temic DQ500 & Bosch MD1CP004 & Bosch EDC17C74",
                "Engine_code": "DDMA / DFHA",
            },
            "1.4 TSI 125hp": {
                "std_power": 125,
                "tuned_power": 155,
                "std_torque": 200,
                "tuned_torque": 270,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25",
                "Engine_code": "CZDB",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Bosch MED17.1.27 & Temic DQ250-6A/6F/6V-MQB",
                "Engine_code": "CZE",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ381",
                "Engine_code": "DADA / DPCA",
            },
            "1.5 TSI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 200,
                "tuned_torque": 290,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011",
                "Engine_code": "DACB",
            },
            "2.0 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Temic DQ500 & Bosch MG1CS111 & Siemens/Continental Simos 18.3",
                "Engine_code": "CZP",
            },
            "2.0 TSI 220hp": {
                "std_power": 220,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18 & Temic DQ380 & Temic DQ500",
                "Engine_code": "CHHB",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MG1CS001 & Bosch MED17.5.2 & Bosch MG1CS111 & Temic DQ500 & Temic DQ381",
                "Engine_code": "DNLA",
            },
            "2.0 TSI 230hp": {
                "std_power": 230,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.10 & Bosch MG1CS111 & Temic DQ500",
                "Engine_code": "DKTA",
            },
        },
    },
    "Tiguan Allspace": {
        "2017 - 2020": {
            "2.0 TDI Bi-Turbo 240hp": {
                "std_power": 240,
                "tuned_power": 300,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Temic DQ500 & Bosch EDC17C74",
                "Engine_code": "CUAA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74",
                "Engine_code": "DBGC  -  DFGA",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ500",
                "Engine_code": "DFHA",
            },
            "2.0 TDI 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004",
                "Engine_code": "DTUA",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.5.25 & Temic DQ250",
                "Engine_code": "CZDA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ381",
                "Engine_code": "DADA / DPCA",
            },
            "2.0 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 245,
                "std_torque": 280,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.5.2 & Bosch MG1CS001 & Temic DQ500 & Bosch MG1CS111",
                "Engine_code": "DGVA",
            },
            "2.0 TSI 220hp": {
                "std_power": 220,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental Simos 18.1 & Temic DQ500",
                "Engine_code": "CXDA / CHHB",
            },
        },
        "2021 -> ...": {
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 195,
                "std_torque": 360,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "DTSA / DTSB",
            },
            "2.0 TDI 200hp": {
                "std_power": 200,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "DTUA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "",
                "Engine_code": "DADA / DPCA",
            },
            "2.0 TSI 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1984,
                "Type_ecu": "Temic DQ381",
                "Engine_code": "DNLA",
            },
            "2.0 TSI 245hp": {
                "std_power": 245,
                "tuned_power": 327,
                "std_torque": 360,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "",
                "Engine_code": "DNPA",
            },
        },
    },
    "Touareg": {
        "2002 - 2007": {
            "2.5 TDI 174hp": {
                "std_power": 174,
                "tuned_power": 204,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2461,
                "Type_ecu": "Bosch EDC16U & Bosch EDC16U34",
                "Engine_code": "BAC",
            },
            "2.5 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 204,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2461,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC16U1",
                "Engine_code": "BLK",
            },
            "3.0 TDI 225hp": {
                "std_power": 225,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC17CP14 & Bosch EDC16U3",
                "Engine_code": "BKS",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 280,
                "std_torque": 500,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC16CP34 & Bosch EDC17CP14",
                "Engine_code": "BUN",
            },
            "5.0 TDI V10 313hp": {
                "std_power": 313,
                "tuned_power": 370,
                "std_torque": 750,
                "tuned_torque": 900,
                "cc": 4921,
                "Type_ecu": "Bosch EDC16U2.1 M&S & Bosch EDC16U31 & Bosch EDC16U1",
                "Engine_code": "BLE",
            },
            "3.2i V6 241hp": {
                "std_power": 241,
                "tuned_power": 256,
                "std_torque": 315,
                "tuned_torque": 335,
                "cc": 3189,
                "Type_ecu": "Bosch ME7.5.5 & Bosch ME7.1.1",
                "Engine_code": "BAA",
            },
            "3.2i V6 220hp": {
                "std_power": 220,
                "tuned_power": 245,
                "std_torque": 305,
                "tuned_torque": 340,
                "cc": 3189,
                "Type_ecu": "Bosch ME7.5.5 & Bosch ME7.1.1",
                "Engine_code": "AZZ",
            },
            "3.6i V6 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 360,
                "tuned_torque": 380,
                "cc": 3580,
                "Type_ecu": "Bosch MED9.1 & Bosch MED9.1.1",
                "Engine_code": "BHL",
            },
            "4.2i V8 310hp": {
                "std_power": 310,
                "tuned_power": 325,
                "std_torque": 410,
                "tuned_torque": 430,
                "cc": 4172,
                "Type_ecu": "Bosch ME7.5.5 & Bosch ME9.1 & Bosch ME7.1.1",
                "Engine_code": "BCY",
            },
            "6.0i W12 450hp": {
                "std_power": 450,
                "tuned_power": 475,
                "std_torque": 600,
                "tuned_torque": 620,
                "cc": 5998,
                "Type_ecu": "Bosch ME7.1.1",
                "Engine_code": "BJN",
            },
        },
        "2007 - 2010": {
            "2.5 TDI 163hp": {
                "std_power": 163,
                "tuned_power": 204,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2461,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC16U31",
                "Engine_code": "BKS",
            },
            "2.5 TDI 174hp": {
                "std_power": 174,
                "tuned_power": 204,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2461,
                "Type_ecu": "Bosch EDC16U34",
                "Engine_code": "BPE",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 275,
                "std_torque": 500,
                "tuned_torque": 575,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP14",
                "Engine_code": "CASB",
            },
            "3.0 TDI 225hp": {
                "std_power": 225,
                "tuned_power": 275,
                "std_torque": 500,
                "tuned_torque": 575,
                "cc": 3967,
                "Type_ecu": "Bosch EDC17CP14 & Bosch EDC16CP34 & Bosch EDC17CP24",
                "Engine_code": "CATA",
            },
            "3.0 TDI 240hp": {
                "std_power": 240,
                "tuned_power": 290,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 3967,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP14",
                "Engine_code": "CASC",
            },
            "5.0 TDI V10 R50 350hp": {
                "std_power": 350,
                "tuned_power": 400,
                "std_torque": 850,
                "tuned_torque": 970,
                "cc": 4921,
                "Type_ecu": "Bosch EDC16U2.1 M&S",
                "Engine_code": "BWF",
            },
            "5.0 TDI V10 313hp": {
                "std_power": 313,
                "tuned_power": 370,
                "std_torque": 750,
                "tuned_torque": 900,
                "cc": 4921,
                "Type_ecu": "Bosch EDC16U2.1 M&S",
                "Engine_code": "BWF",
            },
            "3.2i V6 241hp": {
                "std_power": 241,
                "tuned_power": 260,
                "std_torque": 315,
                "tuned_torque": 350,
                "cc": 3189,
                "Type_ecu": "Bosch EDC17CP04 & Bosch EDC17CP14",
                "Engine_code": "BAA",
            },
            "3.6i V6 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 360,
                "tuned_torque": 390,
                "cc": 3597,
                "Type_ecu": "Bosch ME9.1.1",
                "Engine_code": "CGRA",
            },
        },
        "2010 - 2014": {
            "3.0 TDI 245hp": {
                "std_power": 245,
                "tuned_power": 300,
                "std_torque": 550,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP14 & Aisin AL1000",
                "Engine_code": "CRCA",
            },
            "3.0 TDI 204hp": {
                "std_power": 204,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Bosch EDC17CP14 & Aisin AL1000",
                "Engine_code": "CVWA",
            },
            "3.0 TDI 262hp": {
                "std_power": 262,
                "tuned_power": 300,
                "std_torque": 580,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "CDTB",
            },
            "3.0 TDI 211hp": {
                "std_power": 211,
                "tuned_power": 290,
                "std_torque": 550,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44",
                "Engine_code": "",
            },
            "4.2 V8 TDI 340hp": {
                "std_power": 340,
                "tuned_power": 400,
                "std_torque": 800,
                "tuned_torque": 950,
                "cc": 4134,
                "Type_ecu": "Bosch EDC17CP44 & Aisin AL1000 & Bosch MED9.1.1",
                "Engine_code": "AXQ",
            },
            "3.0 TFSI 333hp": {
                "std_power": 333,
                "tuned_power": 400,
                "std_torque": 440,
                "tuned_torque": 520,
                "cc": 2995,
                "Type_ecu": "Bosch MED17.1.6 & Siemens/Continental Simos 8.5",
                "Engine_code": "CGEA",
            },
            "3.6i FSI 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 360,
                "tuned_torque": 380,
                "cc": 3597,
                "Type_ecu": "Bosch MED17.1.6 & Aisin AL1000",
                "Engine_code": "BHK",
            },
        },
        "2015 - 2018": {
            "3.0 TDI 204hp": {
                "std_power": 204,
                "tuned_power": 270,
                "std_torque": 450,
                "tuned_torque": 600,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Aisin AL1000",
                "Engine_code": "CJMA",
            },
            "3.0 TDI 262hp": {
                "std_power": 262,
                "tuned_power": 300,
                "std_torque": 580,
                "tuned_torque": 650,
                "cc": 2967,
                "Type_ecu": "Bosch EDC17CP44 & Aisin AL1000",
                "Engine_code": "CDTB",
            },
            "3.0 TSI 380hp": {
                "std_power": 380,
                "tuned_power": 430,
                "std_torque": 580,
                "tuned_torque": 690,
                "cc": 2995,
                "Type_ecu": "Siemens/Continental Simos 8.4 & Siemens/Continental Simos 18.4 & Bosch MG1CS002",
                "Engine_code": "CGEA / CGFA",
            },
        },
        "2018 ->": {
            "3.0 V6 TDI 231hp": {
                "std_power": 231,
                "tuned_power": 320,
                "std_torque": 500,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP014 & Bosch MD1CP004 & Temic AL552",
                "Engine_code": "DEND",
            },
            "3.0 V6 TDI 286hp": {
                "std_power": 286,
                "tuned_power": 350,
                "std_torque": 600,
                "tuned_torque": 720,
                "cc": 2967,
                "Type_ecu": "Bosch MD1CP004 & ZF AL552",
                "Engine_code": "DENA",
            },
            "4.0 V8 TDI 422hp": {
                "std_power": 422,
                "tuned_power": 480,
                "std_torque": 900,
                "tuned_torque": 1000,
                "cc": 3956,
                "Type_ecu": "ZF AL552 & Bosch MD1CP014",
                "Engine_code": "DMV",
            },
            "3.0 V6 TSI 340hp": {
                "std_power": 340,
                "tuned_power": 390,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2995,
                "Type_ecu": "Bosch MG1CS002 & ZF AL552",
                "Engine_code": "CZSE",
            },
            "R Plug-in V6 462hp": {
                "std_power": 462,
                "tuned_power": 520,
                "std_torque": 700,
                "tuned_torque": 820,
                "cc": 2995,
                "Type_ecu": "Temic AL552 & Bosch MG1CS002",
                "Engine_code": "EA839",
            },
        },
    },
    "Touran": {
        "2003 - 2010": {
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 210,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U34",
                "Engine_code": "BRU",
            },
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U34",
                "Engine_code": "BKC",
            },
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC15P+ & Bosch EDC16U1",
                "Engine_code": "AVQ",
            },
            "2.0 TDI 136hp": {
                "std_power": 136,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U & Temic DQ250Cxx",
                "Engine_code": "AZV",
            },
            "2.0 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 1968,
                "Type_ecu": "Bosch EDC16U34 & Bosch EDC17C46 & Siemens/Continental PPD1.2 & Bosch EDC16U1 & Bosch EDC16U31",
                "Engine_code": "BKD",
            },
            "2.0 TDI 170hp": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Siemens/Continental PPD1.2 & Bosch EDC17C46 & Temic DQ250Cxx",
                "Engine_code": "BMN",
            },
            "1.6 FSI 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 155,
                "tuned_torque": 170,
                "cc": 1598,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BAG",
            },
            "1.6i 8v 102hp": {
                "std_power": 102,
                "tuned_power": 112,
                "std_torque": 148,
                "tuned_torque": 165,
                "cc": 1595,
                "Type_ecu": "Siemens/Continental Simos 7.1",
                "Engine_code": "BGU",
            },
            "2.0 FSI 150hp": {
                "std_power": 150,
                "tuned_power": 160,
                "std_torque": 200,
                "tuned_torque": 215,
                "cc": 1984,
                "Type_ecu": "Bosch MED9.5.10",
                "Engine_code": "BLR",
            },
        },
        "2010 - 2015": {
            "1.6 TDI CR 90hp": {
                "std_power": 90,
                "tuned_power": 140,
                "std_torque": 230,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1",
                "Engine_code": "CAYB",
            },
            "1.6 TDI CR 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1598,
                "Type_ecu": "Siemens/Continental PCR 2.1 & Temic DQ200",
                "Engine_code": "CAYC",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CBAB",
            },
            "2.0 TDI CR 170hp": {
                "std_power": 170,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "BMN",
            },
            "2.0 TDI CR 177hp": {
                "std_power": 177,
                "tuned_power": 215,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP46",
                "Engine_code": "CFJB",
            },
            "2.0 TDI CR 136hp": {
                "std_power": 136,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C46",
                "Engine_code": "CBAB",
            },
            "1.2 TSI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 225,
                "cc": 1390,
                "Type_ecu": "Siemens/Continental Simos 10.13",
                "Engine_code": "CBZB",
            },
            "1.4 TSI 140hp (CAVC)": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.X CAN & Temic DQ200",
                "Engine_code": "CAVC",
            },
            "1.4 TSI 170hp (CTHB)": {
                "std_power": 170,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.X CAN",
                "Engine_code": "CTHB",
            },
            "1.4 TSI 140hp (CTHC)": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1390,
                "Type_ecu": "Bosch MED17.5.5",
                "Engine_code": "CTHC",
            },
        },
        "2015 ->": {
            "1.6 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Delphi DCM6.2 & Bosch EDC17C74 & Temic DQ250 & Temic DQ200",
                "Engine_code": "CRK",
            },
            "1.6 TDI 116hp": {
                "std_power": 116,
                "tuned_power": 150,
                "std_torque": 250,
                "tuned_torque": 330,
                "cc": 1598,
                "Type_ecu": "Bosch EDC17C74 & Siemens/Continental DQ200 Gen2",
                "Engine_code": "DGDA",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250 & Bosch MD1CS004 & Bosch MD1CS003 & Temic DQ381",
                "Engine_code": "DFE",
            },
            "2.0 TDI 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Bosch EDC17C74 & Temic DQ250",
                "Engine_code": "DFHA",
            },
            "2.0 TDI 122hp": {
                "std_power": 122,
                "tuned_power": 190,
                "std_torque": 270,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch MD1CS004 & Bosch EDC17C74",
                "Engine_code": "DFE",
            },
            "2.0 TDI 115hp": {
                "std_power": 115,
                "tuned_power": 195,
                "std_torque": 320,
                "tuned_torque": 430,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C74 & Temic DQ381",
                "Engine_code": "CYKB / DFEB / DFGB / DFZA / CRVA",
            },
            "1.2 TSI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 175,
                "tuned_torque": 235,
                "cc": 1197,
                "Type_ecu": "Bosch MG1CS011 & Siemens/Continental Simos 20.1.20",
                "Engine_code": "CYVB",
            },
            "1.4 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 170,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1395,
                "Type_ecu": "Bosch MED17.1 & Bosch MED17.5.5 & Bosch MED17.5.25 & Temic DQ200 Gen2 & Bosch MG1CS011",
                "Engine_code": "CDGA",
            },
            "1.5 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 250,
                "tuned_torque": 335,
                "cc": 1498,
                "Type_ecu": "Bosch MG1CS011 & Temic DQ200",
                "Engine_code": "DADA",
            },
            "1.8 TSI 180hp": {
                "std_power": 180,
                "tuned_power": 240,
                "std_torque": 250,
                "tuned_torque": 380,
                "cc": 1798,
                "Type_ecu": "Siemens/Continental Simos 12.1 & Siemens/Continental Simos 18.1 & Siemens/Continental DQ200 Gen2",
                "Engine_code": "CJSA",
            },
        },
    },
    "Transporter / Multivan": {
        "T4 - 1999 - 2004": {
            "2.5 TDI 88hp": {
                "std_power": 88,
                "tuned_power": 115,
                "std_torque": 195,
                "tuned_torque": 240,
                "cc": 2461,
                "Type_ecu": "Bosch EDC15VM+",
                "Engine_code": "AJT",
            },
            "2.5 TDI 102hp": {
                "std_power": 102,
                "tuned_power": 133,
                "std_torque": 250,
                "tuned_torque": 310,
                "cc": 2460,
                "Type_ecu": "Bosch EDC15VM+ & Bosch MSA15 & Bosch MSA12",
                "Engine_code": "ACV",
            },
            "2.5 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 295,
                "tuned_torque": 350,
                "cc": 2461,
                "Type_ecu": "Bosch EDC15VM+ & Bosch MSA15.5",
                "Engine_code": "AHY",
            },
        },
        "T5 - 2003 - 2009": {
            "1.9 TDI 105hp": {
                "std_power": 105,
                "tuned_power": 140,
                "std_torque": 250,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U1 & Bosch EDC16U31 & Bosch EDC16U34 & Bosch EDC17CP20",
                "Engine_code": "BKC",
            },
            "1.9 TDI 86hp": {
                "std_power": 86,
                "tuned_power": 140,
                "std_torque": 200,
                "tuned_torque": 320,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16U1 & Bosch EDC16U34",
                "Engine_code": "AXC",
            },
            "2.5 TDI 130hp": {
                "std_power": 130,
                "tuned_power": 170,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 2460,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16U1 & Bosch EDC16U34",
                "Engine_code": "AXD",
            },
            "2.5 TDI 174hp": {
                "std_power": 174,
                "tuned_power": 204,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2460,
                "Type_ecu": "Bosch EDC16U31 & Bosch EDC16U1 & Bosch EDC16U34",
                "Engine_code": "AXE",
            },
        },
        "T5 - 2009 - 2015": {
            "2.0 TDI CR 84hp": {
                "std_power": 84,
                "tuned_power": 175,
                "std_torque": 220,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20",
                "Engine_code": "CAAA",
            },
            "2.0 TDI CR 102hp": {
                "std_power": 102,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Temic DQ500",
                "Engine_code": "CAAB",
            },
            "2.0 TDI CR 114hp": {
                "std_power": 114,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20",
                "Engine_code": "CAA",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 180,
                "std_torque": 340,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Temic DQ500",
                "Engine_code": "CAAC",
            },
            "2.0 TDI CR 180hp": {
                "std_power": 180,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 485,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Temic DQ500 & Bosch MED17.1",
                "Engine_code": "CFCA",
            },
            "2.0 TSI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Temic DQ500",
                "Engine_code": "CJKA",
            },
            "2.0 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 240,
                "std_torque": 280,
                "tuned_torque": 360,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.1.1 & Bosch MED17.1",
                "Engine_code": "CJKB",
            },
        },
        "T6 - 2015 - 2018": {
            "2.0 TDI CR (EUR 6) 84hp": {
                "std_power": 84,
                "tuned_power": 175,
                "std_torque": 220,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Delphi DCM6.2",
                "Engine_code": "CXGA",
            },
            "2.0 TDI CR (EUR 6) 102hp": {
                "std_power": 102,
                "tuned_power": 175,
                "std_torque": 250,
                "tuned_torque": 350,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Delphi DCM6.2",
                "Engine_code": "CXGB",
            },
            "2.0 TDI CR (EUR 6) 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17C64 & Delphi DCM6.2 & Bosch EDC17CP20 & Temic DQ500 & Delphi DCM6.2V",
                "Engine_code": "CXFA",
            },
            "2.0 TDI CR (EUR 6) 204hp": {
                "std_power": 204,
                "tuned_power": 230,
                "std_torque": 450,
                "tuned_torque": 500,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ500",
                "Engine_code": "CXEB",
            },
            "2.0 TDI CR (EUR 6) 114hp": {
                "std_power": 114,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "CXFB",
            },
            "2.0 TDI CR 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 340,
                "tuned_torque": 400,
                "cc": 1968,
                "Type_ecu": "Bosch EDC17CP20 & Temic DQ500 & Aisin AQ450 & Delphi DCM6.2",
                "Engine_code": "CAAC",
            },
            "2.0 TSI 204hp": {
                "std_power": 204,
                "tuned_power": 260,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1 & Temic DQ500 & Delphi DCM6.2",
                "Engine_code": "CJKA",
            },
            "2.0 TSI 150hp": {
                "std_power": 150,
                "tuned_power": 260,
                "std_torque": 280,
                "tuned_torque": 420,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.1",
                "Engine_code": "CJKB",
            },
        },
        "T6 - 2019 - 2021": {
            "2.0 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 150,
                "std_torque": 220,
                "tuned_torque": 330,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "",
            },
            "2.0 TDI 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 340,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ500 & Delphi DCM6.2V",
                "Engine_code": "",
            },
            "2.0 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 185,
                "std_torque": 250,
                "tuned_torque": 410,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2",
                "Engine_code": "",
            },
            "2.0 TDI 198hp": {
                "std_power": 198,
                "tuned_power": 240,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 1968,
                "Type_ecu": "Delphi DCM6.2 & Temic DQ500",
                "Engine_code": "",
            },
            "2.0 TDI 204hp": {
                "std_power": 204,
                "tuned_power": 240,
                "std_torque": 450,
                "tuned_torque": 520,
                "cc": 1968,
                "Type_ecu": "Temic DQ500 & Delphi DCM6.2 & Bosch MD1CP104",
                "Engine_code": "",
            },
        },
        "T7 - 2021 -> ...": {
            "2.0 TDI 150hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.4 TSI eHybrid 218hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 TSI 136hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 TSI 204hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "Up": {
        "2012 ->": {
            "1.0 TSI GTI 115hp": {
                "std_power": 115,
                "tuned_power": 135,
                "std_torque": 200,
                "tuned_torque": 230,
                "cc": 999,
                "Type_ecu": "Bosch MED17.1.27 & Bosch MED17.5.21",
                "Engine_code": "DKRA / DKRF",
            },
            "1.0 TSI 90hp": {
                "std_power": 90,
                "tuned_power": 135,
                "std_torque": 160,
                "tuned_torque": 230,
                "cc": 999,
                "Type_ecu": "Bosch MED17.5.21 & Bosch MED17.1.27",
                "Engine_code": "CHZA",
            },
            "1.0i 60hp": {
                "std_power": 60,
                "tuned_power": 85,
                "std_torque": 95,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch ME17.5.20 & Bosch ME17.5.24 & Bosch MG1CS811 & Bosch MG1CA811",
                "Engine_code": "CHYA",
            },
            "1.0i 75hp": {
                "std_power": 75,
                "tuned_power": 85,
                "std_torque": 95,
                "tuned_torque": 105,
                "cc": 999,
                "Type_ecu": "Bosch ME17.5.24",
                "Engine_code": "CHYB",
            },
        },
    },
    "Vento": {
        "1992 - 1998": {
            "1.9 TDI 100hp": {
                "std_power": 100,
                "tuned_power": 133,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1896,
                "Type_ecu": "Bosch EDC16U34",
                "Engine_code": "BLS / BXE",
            },
            "1.9 TDI 110hp": {
                "std_power": 110,
                "tuned_power": 140,
                "std_torque": 235,
                "tuned_torque": 295,
                "cc": 1896,
                "Type_ecu": "",
                "Engine_code": "AFN",
            },
            "1.9 TDI 90hp": {
                "std_power": 90,
                "tuned_power": 120,
                "std_torque": 202,
                "tuned_torque": 265,
                "cc": 1896,
                "Type_ecu": "",
                "Engine_code": "AHU",
            },
            "2.0i 8v 115hp": {
                "std_power": 115,
                "tuned_power": 125,
                "std_torque": 170,
                "tuned_torque": 185,
                "cc": 1984,
                "Type_ecu": "",
                "Engine_code": "ADY",
            },
        },
    },
},
"Volvo": {
    "C30": {
        "2006 - 2008": {
            "1.6D 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 295,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "D4164T",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803 & Siemens/Continental SID206",
                "Engine_code": "D4204T",
            },
            "2.4 D5 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T8",
            },
            "2.4 D5 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 2400,
                "Type_ecu": "",
                "Engine_code": "D5244T9",
            },
            "2.4 D5 180hp aut": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "2.5 T5 220hp": {
                "std_power": 220,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 450,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254T3",
            },
            "2.5 T5 230hp": {
                "std_power": 230,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 450,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254T7",
            },
        },
        "2009 - 2013": {
            "1.6 D2 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "D4162T",
            },
            "1.6D 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 295,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "D4164T",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5204T5",
            },
            "2.0 D4 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental SID206 & Bosch EDC17CP22",
                "Engine_code": "D5204T",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803 & Siemens/Continental SID206",
                "Engine_code": "D4204T",
            },
            "2.4 D5 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC17C22",
                "Engine_code": "D5244T8",
            },
            "2.5 T5 230hp": {
                "std_power": 230,
                "tuned_power": 250,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254T7",
            },
        },
    },
    "C70": {
        "1999 - 2005": {
            "2.0T 163hp": {
                "std_power": 163,
                "tuned_power": 210,
                "std_torque": 230,
                "tuned_torque": 300,
                "cc": 1984,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5204T4",
            },
            "2.4T 193hp": {
                "std_power": 193,
                "tuned_power": 230,
                "std_torque": 270,
                "tuned_torque": 350,
                "cc": 2435,
                "Type_ecu": "Bosch EDC16C31 & Bosch ME7",
                "Engine_code": "B5244T",
            },
            "T5 240hp": {
                "std_power": 240,
                "tuned_power": 310,
                "std_torque": 330,
                "tuned_torque": 420,
                "cc": 2319,
                "Type_ecu": "Bosch ME7.0",
                "Engine_code": "B5234T3",
            },
        },
        "2006 - 2009": {
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Siemens/Continental SID206",
                "Engine_code": "D4204T",
            },
            "2.4 D5 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "2.4 D5 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "B5244T5",
            },
            "2.4 D5 180hp aut": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T8",
            },
            "2.4i 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 235,
                "cc": 2435,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5244S2",
            },
            "T5 230hp": {
                "std_power": 230,
                "tuned_power": 250,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254TX",
            },
            "T5 220hp": {
                "std_power": 220,
                "tuned_power": 250,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254TX",
            },
        },
        "2009 ->": {
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5205T5",
            },
            "2.0 D4 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental SID206 & Bosch EDC17CP22",
                "Engine_code": "D5205T",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Siemens/Continental SID206",
                "Engine_code": "D4204T",
            },
            "2.4 D5 180hp": {
                "std_power": 180,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "2.4 D5 180hp aut": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T8",
            },
            "2.4i 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 235,
                "cc": 2435,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5244S2",
            },
            "2.5 T5 230hp": {
                "std_power": 230,
                "tuned_power": 250,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254T7",
            },
            "2.5 T5 220hp": {
                "std_power": 220,
                "tuned_power": 250,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254TX",
            },
        },
    },
    "S40": {
        "2000 - 2004": {
            "1.9D 102hp": {
                "std_power": 102,
                "tuned_power": 130,
                "std_torque": 215,
                "tuned_torque": 265,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2 & Bosch MSA15.5",
                "Engine_code": "D4192T3",
            },
            "1.9D 115hp": {
                "std_power": 115,
                "tuned_power": 160,
                "std_torque": 265,
                "tuned_torque": 330,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "D4192T3",
            },
            "2.0T 165hp": {
                "std_power": 165,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 295,
                "cc": 1855,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "B4204T3",
            },
            "T4 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 300,
                "tuned_torque": 345,
                "cc": 1948,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "B4194T",
            },
        },
        "2004 - 2007": {
            "1.6D 109hp": {
                "std_power": 109,
                "tuned_power": 134,
                "std_torque": 240,
                "tuned_torque": 295,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "D4164T",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SID803A & Siemens/Continental SID206",
                "Engine_code": "D4204T",
            },
            "2.4 D5 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T",
            },
            "2.4 D5 180hp": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "2.4 D5 180hp aut": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "T5 220hp": {
                "std_power": 220,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254T3",
            },
        },
        "2007 - 2012": {
            "1.6 D2 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "D412T3",
            },
            "1.6D 109hp": {
                "std_power": 109,
                "tuned_power": 134,
                "std_torque": 240,
                "tuned_torque": 295,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "D4164T",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T5",
            },
            "2.0 D4 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental SID206",
                "Engine_code": "D4204T2",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1998,
                "Type_ecu": "Siemens/Continental SID803A & Siemens/Continental SID206",
                "Engine_code": "D4204T",
            },
            "2.4 D5 180hp": {
                "std_power": 180,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "2.4 D5 163hp": {
                "std_power": 163,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T",
            },
            "2.4 D5 180hp aut": {
                "std_power": 180,
                "tuned_power": 205,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "2.4i 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 245,
                "cc": 2435,
                "Type_ecu": "Denso MB279700-9xxx",
                "Engine_code": "B5244S5",
            },
            "T5 230hp": {
                "std_power": 230,
                "tuned_power": 250,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254T3",
            },
            "T5 220hp": {
                "std_power": 220,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254T3",
            },
        },
    },
    "S60": {
        "2000 - 2004": {
            "2.4 D5 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC15C11",
                "Engine_code": "D5244T",
            },
            "2.4D 130hp": {
                "std_power": 130,
                "tuned_power": 195,
                "std_torque": 280,
                "tuned_torque": 420,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC15C11",
                "Engine_code": "D5244T7",
            },
            "2.0T 180hp": {
                "std_power": 180,
                "tuned_power": 225,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1984,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5204T5",
            },
            "2.4T 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 2435,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5244T3",
            },
            "2.5T 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 320,
                "tuned_torque": 390,
                "cc": 2521,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5254T2",
            },
            "R 2.5T": {
                "std_power": 300,
                "tuned_power": 335,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2521,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5254T4",
            },
            "T5 250hp": {
                "std_power": 250,
                "tuned_power": 310,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 2319,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5234T3",
            },
        },
        "2004 - 2009": {
            "2.4 D5 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC15C11",
                "Engine_code": "D5244T",
            },
            "2.4 D5 185hp": {
                "std_power": 185,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T4",
            },
            "2.4D 130hp": {
                "std_power": 130,
                "tuned_power": 195,
                "std_torque": 280,
                "tuned_torque": 420,
                "cc": 2401,
                "Type_ecu": "Bosch EDC15C11",
                "Engine_code": "D5244T2",
            },
            "2.4D 126hp": {
                "std_power": 126,
                "tuned_power": 185,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T7",
            },
            "2.0T 180hp": {
                "std_power": 180,
                "tuned_power": 210,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1984,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5204T5",
            },
            "2.4T 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 2435,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5244T3",
            },
            "2.5T 210hp": {
                "std_power": 210,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 385,
                "cc": 2521,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5254T2",
            },
            "R 2.5T 300hp": {
                "std_power": 300,
                "tuned_power": 335,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2521,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5254T4",
            },
            "T5 260hp": {
                "std_power": 260,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 2319,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5234T5",
            },
        },
        "2010 - 2014": {
            "1.6 D2 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "D4204T",
            },
            "2.0 D3 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP22 & Bosch EDC17CP48",
                "Engine_code": "D5204T",
            },
            "2.0 D3 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T",
            },
            "2.0 D4 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 170,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "",
                "Engine_code": "D5204T7",
            },
            "2.4 D5 205hp": {
                "std_power": 205,
                "tuned_power": 225,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T",
            },
            "2.4 D5 215hp": {
                "std_power": 215,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22 & Bosch EDC17CP48",
                "Engine_code": "D5244T",
            },
            "2.4 D5 215hp aut": {
                "std_power": 215,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T",
            },
            "1.6 T3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2 & Bosch MED17.0",
                "Engine_code": "B4164T3",
            },
            "1.6 T4 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2",
                "Engine_code": "B4164T",
            },
            "2.0 T5 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17 & Denso 279700-9500",
                "Engine_code": "B5254T12",
            },
            "2.0T 203hp": {
                "std_power": 203,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "B4204T6",
            },
            "3.0 T6 304hp": {
                "std_power": 304,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 495,
                "cc": 2953,
                "Type_ecu": "Denso 279700-9500",
                "Engine_code": "B6304T4",
            },
        },
        "2015 - 2017": {
            "1.6 D2 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "D4204T",
            },
            "2.0 D2 120hp": {
                "std_power": 120,
                "tuned_power": 170,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1969,
                "Type_ecu": "Denso VEA & Denso MB279700-96xx",
                "Engine_code": "D4204T8",
            },
            "2.0 D3 Polestar 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5204T",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 385,
                "cc": 1969,
                "Type_ecu": "Bosch EDC17CP49 & Denso VEA SH72546 & Denso MB279700-9621",
                "Engine_code": "D4204T9",
            },
            "2.0 D4 Polestar 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso 279700-9611",
                "Engine_code": "D4204T5",
            },
            "2.0 D4 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T",
            },
            "2.0 D4 181hp": {
                "std_power": 181,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-96xx & Denso VEA SH72546",
                "Engine_code": "D4204T5",
            },
            "2.0 D5 225hp": {
                "std_power": 225,
                "tuned_power": 260,
                "std_torque": 470,
                "tuned_torque": 530,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D4204T11",
            },
            "2.4 D5 215hp": {
                "std_power": 215,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T11",
            },
            "2.4 D5 215hp aut": {
                "std_power": 215,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T15",
            },
            "2.4 D6 Hybrid 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 640,
                "tuned_torque": 700,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D82PHEV",
            },
            "1.5 T3 152hp": {
                "std_power": 152,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4154T4",
            },
            "1.6 T3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2",
                "Engine_code": "B4164T3",
            },
            "1.6 T4 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 305,
                "cc": 1969,
                "Type_ecu": "Bosch MED17.2",
                "Engine_code": "D4162T",
            },
            "2.0 T3 152hp": {
                "std_power": 152,
                "tuned_power": 195,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T37",
            },
            "2.0 T4 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1969,
                "Type_ecu": "Denso VEA & Denso MB279700-96xx",
                "Engine_code": "B4204T19",
            },
            "2.0 T5 Polestar 253hp": {
                "std_power": 253,
                "tuned_power": 275,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "",
                "Engine_code": "B5254T12",
            },
            "2.0 T5 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "B5254T12",
            },
            "2.0 T5 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "B5254T12",
            },
            "2.0 T6 Polestar 310hp": {
                "std_power": 310,
                "tuned_power": 340,
                "std_torque": 430,
                "tuned_torque": 500,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "B4204T9",
            },
            "2.0 T6 Polestar 367hp": {
                "std_power": 367,
                "tuned_power": 380,
                "std_torque": 470,
                "tuned_torque": 530,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T43",
            },
            "2.0 T6 306hp": {
                "std_power": 306,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 490,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546 & Denso 279700-9603",
                "Engine_code": "B4204T9",
            },
        },
        "2018 ->": {
            "2.0 B3 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B4 197hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B5 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B6 299hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 178,
                "std_torque": 320,
                "tuned_torque": 385,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D4204T9",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-9621",
                "Engine_code": "D4204T14",
            },
            "2.0 T4 197hp": {
                "std_power": 197,
                "tuned_power": 225,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1969,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "B4204T31",
            },
            "2.0 T5 245hp": {
                "std_power": 245,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T23",
            },
            "2.0 T6 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-9621",
                "Engine_code": "B4204T9",
            },
        },
    },
    "S70": {
        "1997 - 2000": {
            "2.5 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 290,
                "tuned_torque": 330,
                "cc": 2461,
                "Type_ecu": "",
                "Engine_code": "D5252T",
            },
            "2.5D 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 290,
                "tuned_torque": 350,
                "cc": 2461,
                "Type_ecu": "",
                "Engine_code": "D5252T",
            },
            "2.5T 193hp": {
                "std_power": 193,
                "tuned_power": 230,
                "std_torque": 270,
                "tuned_torque": 370,
                "cc": 2435,
                "Type_ecu": "",
                "Engine_code": "B5244T3",
            },
            "R 250hp": {
                "std_power": 250,
                "tuned_power": 310,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 2319,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5234T4",
            },
            "R 240hp aut": {
                "std_power": 240,
                "tuned_power": 310,
                "std_torque": 330,
                "tuned_torque": 420,
                "cc": 2319,
                "Type_ecu": "",
                "Engine_code": "B5234T3",
            },
            "T5 241hp": {
                "std_power": 241,
                "tuned_power": 310,
                "std_torque": 330,
                "tuned_torque": 420,
                "cc": 2319,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5234S",
            },
        },
    },
    "S80": {
        "1998 - 2006": {
            "2.4 D5 163hp": {
                "std_power": 163,
                "tuned_power": 195,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 2401,
                "Type_ecu": "Bosch EDC15C11",
                "Engine_code": "D5244T",
            },
            "2.4D 130hp": {
                "std_power": 130,
                "tuned_power": 195,
                "std_torque": 280,
                "tuned_torque": 420,
                "cc": 2401,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "D5244T4",
            },
            "2.5D 140hp": {
                "std_power": 140,
                "tuned_power": 175,
                "std_torque": 290,
                "tuned_torque": 330,
                "cc": 2460,
                "Type_ecu": "Bosch EDC15C3",
                "Engine_code": "B5244T3",
            },
            "2.4T 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 285,
                "tuned_torque": 350,
                "cc": 2435,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5244S",
            },
            "2.5T 210hp": {
                "std_power": 210,
                "tuned_power": 240,
                "std_torque": 320,
                "tuned_torque": 385,
                "cc": 2521,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5254T2",
            },
            "T6 272hp": {
                "std_power": 272,
                "tuned_power": 320,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 2922,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B6294T",
            },
        },
        "2006 - 2011": {
            "1.6D 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 295,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16CP31",
                "Engine_code": "D4164T",
            },
            "2.0 D3 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP22",
                "Engine_code": "D5204T4",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803",
                "Engine_code": "B6324S",
            },
            "2.4 D5 185hp": {
                "std_power": 185,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC16C31",
                "Engine_code": "D5244T2",
            },
            "2.4 D5 205hp": {
                "std_power": 205,
                "tuned_power": 225,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T4",
            },
            "2.4D 175hp": {
                "std_power": 175,
                "tuned_power": 200,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T5",
            },
            "2.4D 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC17CP22",
                "Engine_code": "D5244T",
            },
            "4.4 V8 315hp": {
                "std_power": 315,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 460,
                "cc": 4414,
                "Type_ecu": "",
                "Engine_code": "B8444S",
            },
            "2.0 T4 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "",
                "Engine_code": "B4164T",
            },
            "2.0 T5 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "",
                "Engine_code": "B4204T7",
            },
            "2.0T 203hp": {
                "std_power": 203,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "",
                "Engine_code": "D4204T",
            },
            "2.5T 200hp": {
                "std_power": 200,
                "tuned_power": 245,
                "std_torque": 300,
                "tuned_torque": 385,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "D5252T",
            },
            "2.5T 231hp": {
                "std_power": 231,
                "tuned_power": 260,
                "std_torque": 340,
                "tuned_torque": 385,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254T2",
            },
            "T6 285hp": {
                "std_power": 285,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2953,
                "Type_ecu": "Denso SH7058",
                "Engine_code": "B6294T",
            },
            "T6 304hp": {
                "std_power": 304,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 495,
                "cc": 2953,
                "Type_ecu": "",
                "Engine_code": "B6304T2",
            },
        },
        "2012 ->": {
            "1.6 D2 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "D4162T",
            },
            "2.0 D3 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T2",
            },
            "2.0 D3 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T7",
            },
            "2.0 D4 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T3",
            },
            "2.0 D4 181hp": {
                "std_power": 181,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Denso MB279700-9621",
                "Engine_code": "D5204T5",
            },
            "2.4 D5 215hp": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5244T10",
            },
            "2.4 D5 215hp aut": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP68",
                "Engine_code": "D5244T4",
            },
            "2.0 T4 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "",
                "Engine_code": "B4164T",
            },
            "2.0 T5 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17 & Denso MB279700",
                "Engine_code": "B4204T7",
            },
            "T6 304hp": {
                "std_power": 304,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 495,
                "cc": 1953,
                "Type_ecu": "Denso MB279700-9500",
                "Engine_code": "B6304T4",
            },
        },
    },
    "S90": {
        "2016 ->": {
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546 & Denso MB279700-96xx",
                "Engine_code": "D4204T9",
            },
            "2.0 D4 Polestar 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "D4204T14",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "D4204T14",
            },
            "2.0 D4 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "D4204T14",
            },
            "2.0 D5 Polestar 240hp": {
                "std_power": 240,
                "tuned_power": 286,
                "std_torque": 500,
                "tuned_torque": 545,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D4204T23",
            },
            "2.0 D5 235hp": {
                "std_power": 235,
                "tuned_power": 286,
                "std_torque": 480,
                "tuned_torque": 545,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "D4204T23",
            },
            "2.0 T4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "B4204T31",
            },
            "2.0 T5 Polestar 261hp": {
                "std_power": 261,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T5 254hp": {
                "std_power": 254,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T23",
            },
            "2.0 T6 Polestar 334hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T6 320hp": {
                "std_power": 320,
                "tuned_power": 340,
                "std_torque": 400,
                "tuned_torque": 475,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T27",
            },
            "2.0 T8 407hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "V40": {
        "1998 - 2004": {
            "1.9 TD 90hp": {
                "std_power": 90,
                "tuned_power": 110,
                "std_torque": 176,
                "tuned_torque": 220,
                "cc": 1870,
                "Type_ecu": "",
                "Engine_code": "D4192T",
            },
            "1.9D 115hp": {
                "std_power": 115,
                "tuned_power": 145,
                "std_torque": 265,
                "tuned_torque": 320,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "D4192T3",
            },
            "1.9D 95hp": {
                "std_power": 95,
                "tuned_power": 115,
                "std_torque": 190,
                "tuned_torque": 240,
                "cc": 1870,
                "Type_ecu": "",
                "Engine_code": "D4192T2",
            },
            "1.9D 102hp": {
                "std_power": 102,
                "tuned_power": 127,
                "std_torque": 215,
                "tuned_torque": 290,
                "cc": 1870,
                "Type_ecu": "Bosch EDC15C2",
                "Engine_code": "D4192T4",
            },
            "2.0 T 160hp": {
                "std_power": 160,
                "tuned_power": 195,
                "std_torque": 230,
                "tuned_torque": 290,
                "cc": 1948,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "B4204T2",
            },
            "2.0 T 165hp": {
                "std_power": 165,
                "tuned_power": 195,
                "std_torque": 240,
                "tuned_torque": 290,
                "cc": 1948,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "B4204T3",
            },
            "T4 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1855,
                "Type_ecu": "Siemens/Continental SIRIUS 32",
                "Engine_code": "B4194T2/3",
            },
        },
        "2012 - 2015": {
            "1.6 D2 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807 & Siemens/Continental SID807EVO",
                "Engine_code": "D4162T",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "Bosch EDC17CP48 & Denso VEA SH72546",
                "Engine_code": "D5204T6",
            },
            "2.0 D4 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental SID206 & Bosch EDC17CP48",
                "Engine_code": "D5204T4",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Denso MB279700 & Denso VEA",
                "Engine_code": "D4204T14",
            },
            "2.0 D4 181hp": {
                "std_power": 181,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Denso MB279700-96xx",
                "Engine_code": "D4204T14",
            },
            "1.6 T2 120hp": {
                "std_power": 120,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "B4164T4",
            },
            "1.6 T3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.0 & Denso VEA",
                "Engine_code": "B4164T3",
            },
            "1.6 T4 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "B4164T",
            },
            "2.0 T4 180hp": {
                "std_power": 180,
                "tuned_power": 235,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1984,
                "Type_ecu": "Denso MB279700-94xx",
                "Engine_code": "B5204T8",
            },
            "2.0 T5 213hp": {
                "std_power": 213,
                "tuned_power": 235,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-9500",
                "Engine_code": "B5204T9",
            },
            "2.5 T5 254hp": {
                "std_power": 254,
                "tuned_power": 285,
                "std_torque": 360,
                "tuned_torque": 405,
                "cc": 2497,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "B5254T12",
            },
        },
        "2015 ->": {
            "2.0 D2 120hp": {
                "std_power": 120,
                "tuned_power": 170,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1969,
                "Type_ecu": "Denso VEA & Siemens/Continental SID807 & Denso MB279700-96xx",
                "Engine_code": "D4204T8",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 385,
                "cc": 1969,
                "Type_ecu": "Bosch EDC17CP48 & Denso VEA SH72546",
                "Engine_code": "D4204T9",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso MB279700 & Bosch EDC17CP48 & Denso SH72546",
                "Engine_code": "D5244T21",
            },
            "1.5T T2 122hp": {
                "std_power": 122,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4154T5",
            },
            "1.5T T3 152hp": {
                "std_power": 152,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Denso VEA SH72546 & Bosch MG1CS080",
                "Engine_code": "B4154T4",
            },
            "2.0 T2 122hp": {
                "std_power": 122,
                "tuned_power": 190,
                "std_torque": 240,
                "tuned_torque": 340,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "B4204T38",
            },
            "2.0 T3 152hp": {
                "std_power": 152,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "B4204T37",
            },
            "2.0 T4 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 300,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546 & Denso MB279700",
                "Engine_code": "B4204T19",
            },
            "2.0 T5 245hp": {
                "std_power": 245,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-96xx & Denso VEA SH72546",
                "Engine_code": "B4204T11",
            },
        },
    },
    "V40 Cross Country": {
        "2013 ->": {
            "1.6 D2 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "D4162T",
            },
            "2.0 D2 120hp": {
                "std_power": 120,
                "tuned_power": 180,
                "std_torque": 280,
                "tuned_torque": 370,
                "cc": 1969,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "D4204T8",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "Bosch EDC17CP48 & Denso MB279700-96xx",
                "Engine_code": "D4204T9",
            },
            "2.0 D4 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Denso VEA & Bosch EDC17CP48",
                "Engine_code": "D5204T4",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 230,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "D5244T21",
            },
            "1.6 T3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 300,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.0 & Denso VEA",
                "Engine_code": "B4164T3",
            },
            "1.6 T4 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 270,
                "tuned_torque": 300,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.2",
                "Engine_code": "B4164T",
            },
            "2.0 T3 152hp": {
                "std_power": 152,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 340,
                "cc": 1968,
                "Type_ecu": "",
                "Engine_code": "B4204T37",
            },
            "2.0 T4 180hp": {
                "std_power": 180,
                "tuned_power": 235,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1984,
                "Type_ecu": "Bosch MED17.0 & Denso MB279700-9560 & Denso MB279700-9500",
                "Engine_code": "B5204T8",
            },
            "2.0 T5 213hp": {
                "std_power": 213,
                "tuned_power": 235,
                "std_torque": 300,
                "tuned_torque": 350,
                "cc": 1969,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "B5204T9",
            },
            "2.0 T5 245hp": {
                "std_power": 245,
                "tuned_power": 265,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-96xx",
                "Engine_code": "B4204T11",
            },
            "2.5 T5 254hp": {
                "std_power": 254,
                "tuned_power": 275,
                "std_torque": 360,
                "tuned_torque": 420,
                "cc": 2497,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "B5254T12",
            },
        },
    },
   
    "V50": {
        "2004 - 2007": {
            "1.6D 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "D4164T",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental SID803A",
                "Engine_code": "D4204T2",
            },
            "2.4 D5 180hp": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "2.4 D5 180hp aut": {
                "std_power": 180,
                "tuned_power": 215,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "2.5 T5 230hp": {
                "std_power": 230,
                "tuned_power": 250,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "B5254T3",
            },
            "2.5 T5 220hp": {
                "std_power": 220,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2435,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "B5244S5",
            },
        },
        "2007 - 2012": {
            "1.6 D2 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807",
                "Engine_code": "D4192T3",
            },
            "1.6D 109hp": {
                "std_power": 109,
                "tuned_power": 140,
                "std_torque": 240,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C34",
                "Engine_code": "D4164T",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5204T5",
            },
            "2.0 D4 177hp": {
                "std_power": 177,
                "tuned_power": 200,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental SID206 & Bosch EDC17CP22",
                "Engine_code": "D5204T",
            },
            "2.0 D4 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental SID803A",
                "Engine_code": "",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1984,
                "Type_ecu": "Siemens/Continental SID803A & Siemens/Continental SID206",
                "Engine_code": "D4204T2",
            },
            "2.4 D5 180hp": {
                "std_power": 180,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "2.4 D5 163hp": {
                "std_power": 163,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T",
            },
            "2.4 D5 180hp aut": {
                "std_power": 180,
                "tuned_power": 225,
                "std_torque": 350,
                "tuned_torque": 480,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T13",
            },
            "2.5 T5 230hp": {
                "std_power": 230,
                "tuned_power": 250,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "B5254T3",
            },
            "2.5 T5 220hp": {
                "std_power": 220,
                "tuned_power": 260,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9.0",
                "Engine_code": "B5254T3",
            },
        },
    },
    "V60": {
        "2010 - 2014": {
            "1.6 D2 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807 & Siemens/Continental SID807EVO",
                "Engine_code": "D4162T",
            },
            "2.0 D3 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP22 & Bosch EDC17CP48",
                "Engine_code": "D5204T3",
            },
            "2.0 D3 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T7",
            },
            "2.0 D4 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T3",
            },
            "2.0 D4 181hp": {
                "std_power": 181,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Denso VEA SH72546 & Bosch EDC17CP48 & Denso MB279700-96xx",
                "Engine_code": "D4204T5",
            },
            "2.4 D4 163hp aut": {
                "std_power": 163,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T2",
            },
            "2.4 D5 205hp": {
                "std_power": 205,
                "tuned_power": 225,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22 & Bosch EDC17CP48",
                "Engine_code": "D5244T10",
            },
            "2.4 D5 215hp": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5244T11",
            },
            "2.4 D5 215hp aut": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5244T11",
            },
            "2.4 D6 Hybrid 285hp": {
                "std_power": 285,
                "tuned_power": 315,
                "std_torque": 620,
                "tuned_torque": 680,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D82PHEV",
            },
            "1.6 T3 150hp": {
                "std_power": 150,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.1.9 & Bosch MED17.0",
                "Engine_code": "B4164T3",
            },
            "1.6 T4 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1596,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "B4164T",
            },
            "2.0 T5 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Bosch MED17 & Denso SH72543",
                "Engine_code": "B4204T41",
            },
            "2.0T 203hp": {
                "std_power": 203,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 390,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "B4204T6",
            },
            "T6 304hp": {
                "std_power": 304,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 495,
                "cc": 2953,
                "Type_ecu": "Denso MB279700-9500",
                "Engine_code": "B6304T4",
            },
            "T6 350hp": {
                "std_power": 350,
                "tuned_power": 400,
                "std_torque": 500,
                "tuned_torque": 600,
                "cc": 2953,
                "Type_ecu": "Denso MB279700-9500",
                "Engine_code": "B6304T5",
            },
        },
        "2015 - 2017": {
            "2.0 D2 120hp": {
                "std_power": 120,
                "tuned_power": 170,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1969,
                "Type_ecu": "Denso VEA & Denso MB279700-96xx",
                "Engine_code": "D4204T8",
            },
            "2.0 D3 Polestar 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 1969,
                "Type_ecu": "Denso MB279700 & Bosch EDC17CP48",
                "Engine_code": "D4204T9",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 178,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1969,
                "Type_ecu": "Denso VEA & Denso MB279700-96xx",
                "Engine_code": "D4204T9",
            },
            "2.0 D4 Polestar 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D4204T14",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso MB279700 & Bosch EDC17C68",
                "Engine_code": "D4204T14",
            },
            "2.0 D4 181hp": {
                "std_power": 181,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-9621 & Denso MB279700-96xx",
                "Engine_code": "D4204T14",
            },
            "2.0 D5 225hp": {
                "std_power": 225,
                "tuned_power": 260,
                "std_torque": 470,
                "tuned_torque": 530,
                "cc": 1969,
                "Type_ecu": "Bosch EDC17CP48 & Denso VEA",
                "Engine_code": "D4204T11",
            },
            "2.4 D5 Twin Engine 231hp": {
                "std_power": 231,
                "tuned_power": 275,
                "std_torque": 620,
                "tuned_torque": 695,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP68 & Bosch EDC17CP48",
                "Engine_code": "D87PHEV",
            },
            "2.4 D6 Hybrid 280hp": {
                "std_power": 280,
                "tuned_power": 300,
                "std_torque": 640,
                "tuned_torque": 700,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D82PHEV",
            },
            "2.4 D6 290hp": {
                "std_power": 290,
                "tuned_power": 315,
                "std_torque": 640,
                "tuned_torque": 700,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D97PHEV",
            },
            "1.5T T2 122hp": {
                "std_power": 122,
                "tuned_power": 190,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4154T5",
            },
            "1.5T T3 152hp": {
                "std_power": 152,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "B4154T4",
            },
            "2.0 T3 152hp": {
                "std_power": 152,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1968,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T37",
            },
            "2.0 T4 190hp": {
                "std_power": 190,
                "tuned_power": 225,
                "std_torque": 300,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "Denso SH72531 & Denso SH72546 & Denso VEA & Denso MB279700-96xx",
                "Engine_code": "B4204T19",
            },
            "2.0 T5 Polestar 253hp": {
                "std_power": 253,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T11",
            },
            "2.0 T5 245hp": {
                "std_power": 245,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T11",
            },
            "2.0 T6 Polestar 310hp": {
                "std_power": 310,
                "tuned_power": 340,
                "std_torque": 430,
                "tuned_torque": 500,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-9500",
                "Engine_code": "B4204T9",
            },
            "2.0 T6 Polestar 367hp": {
                "std_power": 367,
                "tuned_power": 380,
                "std_torque": 470,
                "tuned_torque": 530,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "B4204T43",
            },
            "2.0 T6 306hp": {
                "std_power": 306,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 490,
                "cc": 1969,
                "Type_ecu": "Denso SH72546",
                "Engine_code": "B4204T9",
            },
        },
        "2018 ->": {
            "2.0 B3 163hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B4 197hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B6 299hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 178,
                "std_torque": 320,
                "tuned_torque": 385,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-96xx",
                "Engine_code": "D4204T9",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 235,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "D4204T14",
            },
            "2.0 B5 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T4 197hp": {
                "std_power": 197,
                "tuned_power": 225,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1969,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "B4204T31",
            },
            "2.0 T5 254hp": {
                "std_power": 254,
                "tuned_power": 285,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T23",
            },
            "2.0 T6 Polestar 367hp": {
                "std_power": 367,
                "tuned_power": 400,
                "std_torque": 470,
                "tuned_torque": 530,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "B4204T52",
            },
            "2.0 T6 310hp": {
                "std_power": 310,
                "tuned_power": 330,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T9",
            },
        },
    },
    "V60 Cross Country": {
        "2015 ->": {
            "2.0 D2 120hp": {
                "std_power": 120,
                "tuned_power": 170,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D4204T8",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 178,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "D4204T9",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D4204T14",
            },
            "2.0 D5 225hp": {
                "std_power": 225,
                "tuned_power": 260,
                "std_torque": 470,
                "tuned_torque": 530,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "D4204T11",
            },
            "2.4 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 480,
                "cc": 1969,
                "Type_ecu": "Bosch EDC17CP68",
                "Engine_code": "D4204T14",
            },
            "1.5T T2 122hp": {
                "std_power": 122,
                "tuned_power": 190,
                "std_torque": 220,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "",
                "Engine_code": "B4154T5",
            },
            "1.5T T3 152hp": {
                "std_power": 152,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1498,
                "Type_ecu": "",
                "Engine_code": "B4154T4",
            },
            "2.0 T3 152hp": {
                "std_power": 152,
                "tuned_power": 190,
                "std_torque": 250,
                "tuned_torque": 300,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T37",
            },
            "2.0 T4 190hp": {
                "std_power": 190,
                "tuned_power": 290,
                "std_torque": 300,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T19",
            },
            "2.0 T5 245hp": {
                "std_power": 245,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T41",
            },
        },
    },
    "V70": {
        "1997 - 2000": {
            "2.5 TDI 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 290,
                "tuned_torque": 350,
                "cc": 2461,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "D5252T",
            },
            "2.5D 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 290,
                "tuned_torque": 350,
                "cc": 2461,
                "Type_ecu": "Bosch EDC15 & Bosch msa15.8",
                "Engine_code": "D5252T",
            },
            "2.0 T5 225hp": {
                "std_power": 225,
                "tuned_power": 275,
                "std_torque": 310,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Bosch ME7.0",
                "Engine_code": "B5204T3",
            },
            "2.3 R 250hp": {
                "std_power": 250,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 410,
                "cc": 2319,
                "Type_ecu": "Bosch ME7.0",
                "Engine_code": "B5234T4",
            },
            "2.3 R 240hp": {
                "std_power": 240,
                "tuned_power": 320,
                "std_torque": 330,
                "tuned_torque": 410,
                "cc": 2319,
                "Type_ecu": "Bosch M4.3 & Bosch ME7",
                "Engine_code": "B5234T4",
            },
            "2.3 R 265hp": {
                "std_power": 265,
                "tuned_power": 330,
                "std_torque": 350,
                "tuned_torque": 430,
                "cc": 2319,
                "Type_ecu": "Bosch ME7.0",
                "Engine_code": "",
            },
            "2.3 T5 240hp": {
                "std_power": 240,
                "tuned_power": 280,
                "std_torque": 330,
                "tuned_torque": 410,
                "cc": 2319,
                "Type_ecu": "Bosch ME7.0",
                "Engine_code": "B5234T3",
            },
            "2.4T 193hp": {
                "std_power": 193,
                "tuned_power": 230,
                "std_torque": 270,
                "tuned_torque": 370,
                "cc": 2435,
                "Type_ecu": "Bosch ME7.0",
                "Engine_code": "B5244T",
            },
            "2.5T 193hp": {
                "std_power": 193,
                "tuned_power": 230,
                "std_torque": 270,
                "tuned_torque": 370,
                "cc": 2435,
                "Type_ecu": "Bosch ME7.0",
                "Engine_code": "",
            },
        },
        "2000 - 2008": {
            "2.4 D5 163hp": {
                "std_power": 163,
                "tuned_power": 215,
                "std_torque": 340,
                "tuned_torque": 460,
                "cc": 2401,
                "Type_ecu": "Bosch EDC15C11 & Bosch EDC16C31",
                "Engine_code": "D5244T",
            },
            "2.4D 130hp": {
                "std_power": 130,
                "tuned_power": 190,
                "std_torque": 280,
                "tuned_torque": 420,
                "cc": 2401,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC15C11",
                "Engine_code": "D5244T2",
            },
            "2.4D 126hp": {
                "std_power": 126,
                "tuned_power": 185,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T7",
            },
            "2.4D 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 2401,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC16 & Bosch EDC16C31 & Bosch EDC15C11",
                "Engine_code": "D5244T5",
            },
            "2.5D 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 290,
                "tuned_torque": 350,
                "cc": 2461,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "D5252T",
            },
            "D5 185hp": {
                "std_power": 185,
                "tuned_power": 215,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T2",
            },
            "2.0T 180hp": {
                "std_power": 180,
                "tuned_power": 225,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1984,
                "Type_ecu": "Bosch M4.3 & Bosch ME7.0",
                "Engine_code": "B5204T5",
            },
            "2.3 T5 250hp": {
                "std_power": 250,
                "tuned_power": 310,
                "std_torque": 330,
                "tuned_torque": 430,
                "cc": 2319,
                "Type_ecu": "Bosch ME7.0",
                "Engine_code": "B5234T3",
            },
            "2.3 T5 260hp": {
                "std_power": 260,
                "tuned_power": 300,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 2319,
                "Type_ecu": "Bosch ME7.01",
                "Engine_code": "B5234T5",
            },
            "2.4T 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 285,
                "tuned_torque": 380,
                "cc": 2435,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5244T3",
            },
            "2.4i 140hp": {
                "std_power": 140,
                "tuned_power": 170,
                "std_torque": 220,
                "tuned_torque": 245,
                "cc": 2435,
                "Type_ecu": "Bosch ME7 & Denso LG.2",
                "Engine_code": "B5244S2",
            },
            "2.5 R 300hp": {
                "std_power": 300,
                "tuned_power": 335,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2521,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5254T4",
            },
            "2.5T 210hp": {
                "std_power": 210,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 385,
                "cc": 2521,
                "Type_ecu": "Bosch ME7.01",
                "Engine_code": "B5254T2",
            },
        },
        "2007 - 2011": {
            "1.6D 109hp": {
                "std_power": 109,
                "tuned_power": 135,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1560,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D4164T",
            },
            "2.0 D3 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP22 & Siemens/Continental SID206",
                "Engine_code": "D5204T2",
            },
            "2.0D 136hp": {
                "std_power": 136,
                "tuned_power": 172,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1997,
                "Type_ecu": "Siemens/Continental SID803A & Siemens/Continental SID206",
                "Engine_code": "D4204T",
            },
            "2.4D 175hp": {
                "std_power": 175,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T14",
            },
            "2.4D 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC17CP48",
                "Engine_code": "D5244T5",
            },
            "D5 205hp": {
                "std_power": 205,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T10",
            },
            "D5 185hp": {
                "std_power": 185,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T4",
            },
            "1.6 T4 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "B4164T",
            },
            "2.0 T5 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "",
                "Engine_code": "B4204T11",
            },
            "2.0T 203hp": {
                "std_power": 203,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "B4204T6",
            },
            "2.5T 231hp": {
                "std_power": 231,
                "tuned_power": 260,
                "std_torque": 340,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "B5254T11",
            },
            "2.5T 200hp": {
                "std_power": 200,
                "tuned_power": 240,
                "std_torque": 300,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME9",
                "Engine_code": "B5254T8",
            },
            "T6 285hp": {
                "std_power": 285,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2953,
                "Type_ecu": "Denso MB279700-94xx",
                "Engine_code": "B6304T2",
            },
            "T6 304hp": {
                "std_power": 304,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 495,
                "cc": 2953,
                "Type_ecu": "Denso MB279700-9500",
                "Engine_code": "B6304T4",
            },
        },
        "2011 ->": {
            "1.6 D2 115hp": {
                "std_power": 115,
                "tuned_power": 140,
                "std_torque": 270,
                "tuned_torque": 320,
                "cc": 1560,
                "Type_ecu": "Siemens/Continental SID807EVO",
                "Engine_code": "D4162T",
            },
            "2.0 D2 120hp": {
                "std_power": 120,
                "tuned_power": 170,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 D2 120hp": {
                "std_power": 120,
                "tuned_power": 170,
                "std_torque": 280,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Denso VEA",
                "Engine_code": "",
            },
            "2.0 D3 Polestar 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "D4204T9",
            },
            "2.0 D3 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP22",
                "Engine_code": "D5204T2",
            },
            "2.0 D3 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T7",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 350,
                "tuned_torque": 380,
                "cc": 1984,
                "Type_ecu": "Denso 279700-9163 & Denso MB279700-9621 & Denso SH72546",
                "Engine_code": "D5204T2",
            },
            "2.0 D4 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T3",
            },
            "2.0 D4 181hp": {
                "std_power": 181,
                "tuned_power": 220,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1984,
                "Type_ecu": "Denso MB279700 & Denso VEA",
                "Engine_code": "D4204T5",
            },
            "2.4 D4 181hp 5 cyl": {
                "std_power": 181,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP68 & Bosch EDC17CP48",
                "Engine_code": "D4204T5",
            },
            "2.4 D4 163hp aut": {
                "std_power": 163,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC16C31",
                "Engine_code": "D5244T17",
            },
            "2.4 D5 215hp": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5244T10",
            },
            "2.4 D5 215hp aut": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5244T10",
            },
            "1.6 T4 180hp": {
                "std_power": 180,
                "tuned_power": 200,
                "std_torque": 240,
                "tuned_torque": 300,
                "cc": 1595,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "B4164T",
            },
            "2.0 T4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T19",
            },
            "2.0 T5 Polestar 253hp": {
                "std_power": 253,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T11",
            },
            "2.0 T5 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "B4204T11",
            },
            "2.0 T5 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T11",
            },
            "T6 304hp": {
                "std_power": 304,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 495,
                "cc": 2953,
                "Type_ecu": "",
                "Engine_code": "B6304T4",
            },
        },
    },
    "V90": {
        "2016 ->": {
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 180,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546 & Denso MB279700-9xxx",
                "Engine_code": "D4204T9",
            },
            "2.0 D4 Polestar 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-9xxx",
                "Engine_code": "D4204T14",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D4204T14",
            },
            "2.0 D5 Polestar 240hp": {
                "std_power": 240,
                "tuned_power": 286,
                "std_torque": 500,
                "tuned_torque": 545,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D4204T23",
            },
            "2.0 D5 235hp": {
                "std_power": 235,
                "tuned_power": 286,
                "std_torque": 480,
                "tuned_torque": 545,
                "cc": 1969,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "D4204T23",
            },
            "2.0 D5 240hp": {
                "std_power": 240,
                "tuned_power": 286,
                "std_torque": 500,
                "tuned_torque": 545,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "D4204T23",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 190,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso 279700-9811",
                "Engine_code": "D4204T4",
            },
            "2.0 T4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T31",
            },
            "2.0 T5 Polestar 261hp": {
                "std_power": 261,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T5 254hp": {
                "std_power": 254,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T23",
            },
            "2.0 T6 Polestar 334hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T6 320hp": {
                "std_power": 320,
                "tuned_power": 340,
                "std_torque": 400,
                "tuned_torque": 475,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T27",
            },
            "2.0 T8 407hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "XC40": {
        "2017 ->": {
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 178,
                "std_torque": 320,
                "tuned_torque": 370,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "D4204T9",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D4204T12",
            },
            "1.5 T2 129hp": {
                "std_power": 129,
                "tuned_power": 190,
                "std_torque": 245,
                "tuned_torque": 305,
                "cc": 1477,
                "Type_ecu": "Bosch MG1CS080",
                "Engine_code": "B3154T",
            },
            "1.5 T3 156hp": {
                "std_power": 156,
                "tuned_power": 190,
                "std_torque": 265,
                "tuned_torque": 305,
                "cc": 1477,
                "Type_ecu": "Bosch MG1CS080",
                "Engine_code": "B3154T",
            },
            "1.5 T3 163hp": {
                "std_power": 163,
                "tuned_power": 190,
                "std_torque": 265,
                "tuned_torque": 305,
                "cc": 1477,
                "Type_ecu": "Bosch MG1CS080",
                "Engine_code": "B3154T",
            },
            "1.5 T4 211hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "1.5 T5 262hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B4 197hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B5 211hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 300,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "Bosch MG1CS080",
                "Engine_code": "B4204T47",
            },
            "2.0 T5 247hp": {
                "std_power": 247,
                "tuned_power": 290,
                "std_torque": 350,
                "tuned_torque": 400,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T14",
            },
        },
    },
    "XC60": {
        "2008 - 2013": {
            "2.0 D3 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP22",
                "Engine_code": "D5204T2",
            },
            "2.0 D3 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T7",
            },
            "2.0 D4 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP22 & Bosch EDC17CP48",
                "Engine_code": "D5244T17",
            },
            "2.4 D5 185hp": {
                "std_power": 185,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T4",
            },
            "2.4 D5 205hp": {
                "std_power": 205,
                "tuned_power": 225,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP22",
                "Engine_code": "D5244T10",
            },
            "2.4 D5 215hp": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5244T15",
            },
            "2.4 D5 215hp aut": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP22",
                "Engine_code": "D5244T15",
            },
            "2.4D 175hp": {
                "std_power": 175,
                "tuned_power": 240,
                "std_torque": 420,
                "tuned_torque": 480,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T14",
            },
            "2.4D 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC17CP22",
                "Engine_code": "D5244T5",
            },
            "2.4D 163hp >2011": {
                "std_power": 163,
                "tuned_power": 225,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC17CP48 & Bosch EDC17CP22",
                "Engine_code": "D5244T5",
            },
            "2.0 T5 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "B4204T7",
            },
            "2.0T 203hp": {
                "std_power": 203,
                "tuned_power": 270,
                "std_torque": 300,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17",
                "Engine_code": "B4204T6",
            },
            "T6 285hp": {
                "std_power": 285,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2953,
                "Type_ecu": "Denso SH7058 & Denso 279700-9163 & Denso 279700-9480",
                "Engine_code": "B6304T2",
            },
            "T6 304hp": {
                "std_power": 304,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 495,
                "cc": 2953,
                "Type_ecu": "Denso MB279700-9500",
                "Engine_code": "B6304T4",
            },
        },
        "2013 - 2017": {
            "2.0 D3 Polestar 163hp": {
                "std_power": 163,
                "tuned_power": 225,
                "std_torque": 380,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP22 & Bosch EDC17CP48",
                "Engine_code": "D5204T7",
            },
            "2.0 D3 136hp": {
                "std_power": 136,
                "tuned_power": 185,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48 & Denso VEA",
                "Engine_code": "D5204T7",
            },
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 210,
                "std_torque": 350,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D4204T4",
            },
            "2.0 D4 Polestar 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 470,
                "cc": 1984,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D5204T5",
            },
            "2.0 D4 181hp": {
                "std_power": 181,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48 & Denso VEA & Denso MB279700-96xx",
                "Engine_code": "D5204T5",
            },
            "2.0 D4 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP68 & Denso VEA",
                "Engine_code": "D5204T3",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP68 & Denso VEA & Bosch EDC17CP48",
                "Engine_code": "D5204T5",
            },
            "2.4 D3 163hp": {
                "std_power": 163,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP68 & Bosch EDC17CP48",
                "Engine_code": "D5244T17",
            },
            "2.4 D4 190hp": {
                "std_power": 190,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP68 & Bosch EDC17CP48 & Denso MB279700",
                "Engine_code": "D5244T21",
            },
            "2.4 D4 181hp 5 cyl": {
                "std_power": 181,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP68",
                "Engine_code": "D5244T12",
            },
            "2.4 D5 Polestar 230hp": {
                "std_power": 230,
                "tuned_power": 245,
                "std_torque": 470,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP68",
                "Engine_code": "D5244T11",
            },
            "2.4 D5 215hp": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5244T11",
            },
            "2.4 D5 220hp": {
                "std_power": 220,
                "tuned_power": 245,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP68 & Bosch EDC17CP48",
                "Engine_code": "D5244T11",
            },
            "2.4 D5 215hp aut": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP68",
                "Engine_code": "D5244T15",
            },
            "2.0 T5 Polestar 261hp": {
                "std_power": 261,
                "tuned_power": 275,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "",
                "Engine_code": "B4204T",
            },
            "2.0 T5 240hp": {
                "std_power": 240,
                "tuned_power": 270,
                "std_torque": 320,
                "tuned_torque": 400,
                "cc": 1999,
                "Type_ecu": "Bosch MED17.0",
                "Engine_code": "B4204T7",
            },
            "2.0 T5 245hp": {
                "std_power": 245,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1999,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T",
            },
            "T6 Polestar 310hp": {
                "std_power": 310,
                "tuned_power": 345,
                "std_torque": 430,
                "tuned_torque": 490,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "B4204T9",
            },
            "T6 306hp": {
                "std_power": 306,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 490,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-9500",
                "Engine_code": "B4204T9",
            },
        },
        "2017 - 2020": {
            "2.0 D3 150hp": {
                "std_power": 150,
                "tuned_power": 225,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D4204T4",
            },
            "2.0 D4 Polestar 200hp": {
                "std_power": 200,
                "tuned_power": 230,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "D4204T14",
            },
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 500,
                "cc": 1969,
                "Type_ecu": "Denso VEA SH72546",
                "Engine_code": "D4204T14",
            },
            "2.0 D5 235hp": {
                "std_power": 235,
                "tuned_power": 286,
                "std_torque": 480,
                "tuned_torque": 545,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "D4204T23",
            },
            "2.0 B4 197hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B5 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B6 299hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T4 190hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T5 Polestar 261hp": {
                "std_power": 261,
                "tuned_power": 290,
                "std_torque": 400,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso 057L1",
                "Engine_code": "B4204T23",
            },
            "2.0 T5 250hp": {
                "std_power": 250,
                "tuned_power": 265,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T26",
            },
            "2.0 T5 245hp": {
                "std_power": 245,
                "tuned_power": 265,
                "std_torque": 350,
                "tuned_torque": 450,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B4204T11",
            },
            "2.0 T6 320hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "T8 Twin Engine 407hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
        "2021 -> ...": {
            "2.0d B4 197hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0d B5 235hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B4 197hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B5 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B6 299hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T6 341hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T8 Polestar 405hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T8 Polestar 455hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T8 392hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    "XC70": {
        "2002 - 2007": {
            "2.4 D5 185hp": {
                "std_power": 185,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T4",
            },
            "2.4 D5 163hp": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 2401,
                "Type_ecu": "Bosch EDC15C2 & Bosch EDC15C11",
                "Engine_code": "D5244T5",
            },
            "2.4T 200hp": {
                "std_power": 200,
                "tuned_power": 235,
                "std_torque": 285,
                "tuned_torque": 380,
                "cc": 2435,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5244T3",
            },
            "2.5T 210hp": {
                "std_power": 210,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 385,
                "cc": 2521,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5254T2",
            },
            "2.5T 193hp": {
                "std_power": 193,
                "tuned_power": 245,
                "std_torque": 270,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "",
                "Engine_code": "B5254T2",
            },
        },
        "2007 - 2011": {
            "2.0 D3 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP22 & Bosch EDC16C31",
                "Engine_code": "D5204T2",
            },
            "2.4 D3 163hp": {
                "std_power": 163,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC16+ & Bosch EDC17CP22",
                "Engine_code": "D5204T2",
            },
            "2.4 D5 205hp": {
                "std_power": 205,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T10",
            },
            "2.4 D5 185hp": {
                "std_power": 185,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T4",
            },
            "2.4 D5 215hp": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC16C31 & Bosch EDC17CP68",
                "Engine_code": "D5244T11",
            },
            "2.4D 175hp": {
                "std_power": 175,
                "tuned_power": 195,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T14",
            },
            "2.4D 163hp": {
                "std_power": 163,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22",
                "Engine_code": "D5244T5",
            },
            "3.2 - 238hp": {
                "std_power": 238,
                "tuned_power": 265,
                "std_torque": 320,
                "tuned_torque": 355,
                "cc": 3192,
                "Type_ecu": "Denso MB279700-9391",
                "Engine_code": "B6324S",
            },
            "3.2 - 243hp": {
                "std_power": 243,
                "tuned_power": 266,
                "std_torque": 320,
                "tuned_torque": 355,
                "cc": 3192,
                "Type_ecu": "",
                "Engine_code": "B6324S",
            },
            "T6 285hp": {
                "std_power": 285,
                "tuned_power": 310,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2953,
                "Type_ecu": "",
                "Engine_code": "B6304T2",
            },
            "T6 304hp": {
                "std_power": 304,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 495,
                "cc": 2953,
                "Type_ecu": "Denso 279700-9480",
                "Engine_code": "B6304T4",
            },
        },
        "2012 ->": {
            "2.0 D3 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T2",
            },
            "2.0 D4 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Bosch EDC17CP48",
                "Engine_code": "D5204T3",
            },
            "2.0 D4 181hp": {
                "std_power": 181,
                "tuned_power": 210,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 1984,
                "Type_ecu": "Denso MB279700-96xx & Denso VEA & Bosch EDC17CP68",
                "Engine_code": "D4204T5",
            },
            "2.4 D3/D4 Polestar 215hp": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP68",
                "Engine_code": "D5422T17",
            },
            "2.4 D3/D4 163hp": {
                "std_power": 163,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22 & Bosch EDC17CP48",
                "Engine_code": "D5422T17",
            },
            "2.4 D3/D4 163hp aut": {
                "std_power": 163,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP22 & Bosch EDC17CP48 & Bosch EDC17CP68",
                "Engine_code": "D5422T17",
            },
            "2.4 D4 181hp": {
                "std_power": 181,
                "tuned_power": 230,
                "std_torque": 420,
                "tuned_torque": 470,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP68 & Bosch EDC17CP48",
                "Engine_code": "D5422T17",
            },
            "2.4 D5 Polestar 230hp": {
                "std_power": 230,
                "tuned_power": 245,
                "std_torque": 470,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "",
                "Engine_code": "D5244T11",
            },
            "2.4 D5 215hp": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP68",
                "Engine_code": "D5244T11",
            },
            "2.4 D5 185hp": {
                "std_power": 185,
                "tuned_power": 205,
                "std_torque": 400,
                "tuned_torque": 450,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T4",
            },
            "2.4 D5 215hp aut": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 440,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC17CP68",
                "Engine_code": "D5244T11",
            },
            "2.0 T5 245hp": {
                "std_power": 245,
                "tuned_power": 270,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "B4204T11",
            },
            "T6 Polestar 329hp": {
                "std_power": 329,
                "tuned_power": 340,
                "std_torque": 480,
                "tuned_torque": 500,
                "cc": 2953,
                "Type_ecu": "",
                "Engine_code": "B6304T4",
            },
            "T6 304hp": {
                "std_power": 304,
                "tuned_power": 330,
                "std_torque": 440,
                "tuned_torque": 495,
                "cc": 2953,
                "Type_ecu": "Denso MB279700-9500",
                "Engine_code": "B6304T4",
            },
        },
    },
    "XC90": {
        "2002 - 2014": {
            "2.0 D4 163hp": {
                "std_power": 163,
                "tuned_power": 185,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1984,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5204T3",
            },
            "2.4 D3 163hp": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31 & Bosch EDC15C11",
                "Engine_code": "D5244T17",
            },
            "2.4 D4 163hp": {
                "std_power": 163,
                "tuned_power": 225,
                "std_torque": 340,
                "tuned_torque": 480,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T17",
            },
            "2.4 D5 200hp": {
                "std_power": 200,
                "tuned_power": 225,
                "std_torque": 420,
                "tuned_torque": 460,
                "cc": 2401,
                "Type_ecu": "Bosch EDC17CP48 & Bosch EDC16C31",
                "Engine_code": "D5244T18",
            },
            "2.4 D5 205hp": {
                "std_power": 205,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T10",
            },
            "2.4 D5 215hp": {
                "std_power": 215,
                "tuned_power": 245,
                "std_torque": 420,
                "tuned_torque": 500,
                "cc": 2400,
                "Type_ecu": "",
                "Engine_code": "D5244T11",
            },
            "2.4 D5 185hp": {
                "std_power": 185,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T4",
            },
            "2.4D 163hp eur4": {
                "std_power": 163,
                "tuned_power": 205,
                "std_torque": 340,
                "tuned_torque": 450,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T5",
            },
            "D5 163hp eur3": {
                "std_power": 163,
                "tuned_power": 200,
                "std_torque": 340,
                "tuned_torque": 420,
                "cc": 2401,
                "Type_ecu": "Bosch EDC15",
                "Engine_code": "D5244T",
            },
            "D5 185hp eur4": {
                "std_power": 185,
                "tuned_power": 225,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 2401,
                "Type_ecu": "Bosch EDC16C31",
                "Engine_code": "D5244T4",
            },
            "2.5T 210hp": {
                "std_power": 210,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 380,
                "cc": 2521,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B5254T2",
            },
            "3.2 - 238hp": {
                "std_power": 238,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 350,
                "cc": 3192,
                "Type_ecu": "Denso MB279700-94xx",
                "Engine_code": "B6324S",
            },
            "3.2 - 243hp": {
                "std_power": 243,
                "tuned_power": 245,
                "std_torque": 320,
                "tuned_torque": 350,
                "cc": 3192,
                "Type_ecu": "Denso 279700-9500",
                "Engine_code": "B6324S",
            },
            "T6 285hp": {
                "std_power": 285,
                "tuned_power": 305,
                "std_torque": 400,
                "tuned_torque": 470,
                "cc": 2922,
                "Type_ecu": "",
                "Engine_code": "B6304T2",
            },
            "T6 272hp": {
                "std_power": 272,
                "tuned_power": 325,
                "std_torque": 380,
                "tuned_torque": 430,
                "cc": 2922,
                "Type_ecu": "Bosch ME7",
                "Engine_code": "B6294T",
            },
            "V8 315hp": {
                "std_power": 315,
                "tuned_power": 345,
                "std_torque": 440,
                "tuned_torque": 480,
                "cc": 4414,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "B8444S",
            },
        },
        "2014 - 2019": {
            "2.0 D4 190hp": {
                "std_power": 190,
                "tuned_power": 230,
                "std_torque": 400,
                "tuned_torque": 460,
                "cc": 1969,
                "Type_ecu": "Denso MB279700-96xx & Denso VEA SH72546",
                "Engine_code": "D4204T14",
            },
            "2.0 D5 Polestar 240hp": {
                "std_power": 240,
                "tuned_power": 290,
                "std_torque": 500,
                "tuned_torque": 580,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D4204T23",
            },
            "2.0 D5 225hp": {
                "std_power": 225,
                "tuned_power": 260,
                "std_torque": 470,
                "tuned_torque": 530,
                "cc": 1969,
                "Type_ecu": "Denso 279700-9642 & Denso VEA SH72546",
                "Engine_code": "D4204T23",
            },
            "2.0 D5 235hp": {
                "std_power": 235,
                "tuned_power": 290,
                "std_torque": 480,
                "tuned_torque": 580,
                "cc": 1969,
                "Type_ecu": "Denso 279700-9811 & Denso VEA SH72546 & Denso 279700-9642",
                "Engine_code": "D4204T23",
            },
            "2.0 T5 254hp": {
                "std_power": 254,
                "tuned_power": 285,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T23",
            },
            "2.0 T6 320hp": {
                "std_power": 320,
                "tuned_power": 355,
                "std_torque": 400,
                "tuned_torque": 480,
                "cc": 1969,
                "Type_ecu": "Denso MB279700",
                "Engine_code": "B4204T27",
            },
            "2.0 T8 TE AWD Hybrid 407hp": {
                "std_power": 407,
                "tuned_power": 467,
                "std_torque": 640,
                "tuned_torque": 740,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "B4204T35",
            },
        },
        "2019 -> ...": {
            "2.0 B5 235hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 D5 235hp": {
                "std_power": 235,
                "tuned_power": 280,
                "std_torque": 480,
                "tuned_torque": 540,
                "cc": 1969,
                "Type_ecu": "Denso VEA",
                "Engine_code": "D420T2",
            },
            "2.0 B5 250hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 B6 299hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T5 250hp": {
                "std_power": 250,
                "tuned_power": 275,
                "std_torque": 350,
                "tuned_torque": 420,
                "cc": 1969,
                "Type_ecu": "",
                "Engine_code": "B420T6",
            },
            "2.0 T6 310hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T8 391hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
            "2.0 T8 455hp": {
                "std_power": 0,
                "tuned_power": 0,
                "std_torque": 0,
                "tuned_torque": 0,
                "cc": 0,
                "Type_ecu": "",
                "Engine_code": "",
            },
        },
    },
    },
}
