import "./buttonTop.css";
import React from 'react';

const scrollToTop = () => {

};

const ButtonTop = () => {
    return (
        <a className="button_scroll" href="#start">
            <button className="arr up">
            </button>
        </a>
       
    );
};

export default ButtonTop;